import { CancelOutlined, Check, CloseSharp } from "@mui/icons-material";
import { Dialog, DialogContent, Grid, IconButton, styled } from "@mui/material";
import { Button, Text, Title } from "../../components";
import { ControlledTextField } from "../../components/inputs";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    width: 100%;
  }
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;
const FiliaisDialog = ({
  handleClose,
  show,
  submitting,
  data,
  formProps,
  handleSubmit,
}) => {
  return (
    <StyledDialog
      onClose={() => handleClose()}
      disableEscapeKeyDown
      maxWidth="xs"
      open={show}
    >
      {show ? (
        <DialogContent>
          <CloseIconButton onClick={handleClose}>
            <CloseSharp />
          </CloseIconButton>
          <Title as={"h2"} _margin_={"0 0 1rem 0"}>
            {data.action === "update"
              ? "Atualizar filial"
              : data.action === "delete"
              ? "Excluir filial"
              : "Adicionar filial"}
          </Title>
          {data.action === "delete" ? (
            <>
              <Text>Esta ação não pode ser desfeita, deseja continuar?</Text>
              <Grid container marginTop={1} spacing={2}>
                <Grid item xs={6}>
                  <Button
                    loading={submitting}
                    fullWidth
                    onClick={handleClose}
                    variant={"text"}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button loading={submitting} onClick={handleSubmit} fullWidth>
                    CONFIRMAR
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ControlledTextField
                    formProps={formProps}
                    label={"Nome"}
                    name={"nome"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button loading={submitting} fullWidth onClick={handleSubmit}>
                    CONFIRMAR
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
      ) : null}
    </StyledDialog>
  );
};

export default FiliaisDialog;
