import {
  AppBar,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
  Toolbar,
  Tooltip,
} from "@mui/material";
import logo from "../../assets/logo-white.png";
import {
  AccountCircle,
  ExitToApp,
  KeyboardDoubleArrowRight,
  Menu,
} from "@mui/icons-material";
import useResponsive from "../../hooks/useResponsive";

const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.colors.header};
  height: 64px;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`;
const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  min-height: 64px;
  align-items: center;
`;
const SmallLogo = styled("img")`
  height: 32px;
  width: auto;

  ${({ theme }) => `${theme.breakpoints.up("sm")} {
    height: 40px;
  }`}
`;
const StyledDrawer = styled(Drawer)`
  flex-shrink: 0;
  transition: width ease-in 150ms;

  & .MuiDrawer-paper {
    box-sizing: border-box;
    transition: width ease-in 150ms;
  }
`;

const Paper = styled("div")`
  width: ${(props) => (props.isOpen ? "200px" : "54px")};
  height: 100%;
  transition: width ease-in 150ms;
  overflow: hidden;
`;
const StyledList = styled(List)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const StyledListItem = styled(ListItem)`
  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: ${({ theme }) => theme.colors.primary._100};
  }

  &.Mui-selected .MuiTypography-root,
  &.Mui-selected:hover .MuiTypography-root,
  &.Mui-selected .MuiSvgIcon-root,
  &.Mui-selected:hover .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.primary._600};
    font-weight: 700;
  }

  & .MuiTypography-root,
  & .MuiSvgIcon-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-weight: 500;
    color: ${({ theme }) => theme.colors.grey._500};
    white-space: nowrap;
  }

  & .MuiTypography-root span {
    flex-wrap: nowrap;
  }

  &:hover .MuiTypography-root,
  &:hover .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.grey._700};
  }
`;
const StyledSectionItem = styled(ListItem)`
  margin-top: auto;

  & .MuiTypography-root {
    font-family: ${({ theme }) => theme.fonts.family.title};
    font-weight: 800;
    color: ${({ theme }) => theme.colors.secondary._800};
  }
`;
const StyledButton = styled("span")`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;
const StyledIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.fixed.white};
`;

const StyledDrawerButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.primary._600};
  padding: 4px;
  background-color: ${({ theme }) => theme.colors.fixed.white};
  border-radius: 5px;
  margin-left: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.fixed.white};
    color: ${({ theme }) => theme.colors.primary._500};
  }
`;

const MenuIcon = styled(Menu)`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.headerText};
`;
const UserIcon = styled(AccountCircle)`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.headerText};
`;
const ExitIcon = styled(ExitToApp)`
  color: ${({ theme }) => theme.colors.headerText};
`;
const UserName = styled("span")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.headerText};
  margin-left: 0.5em;
`;

const Header = ({
  items,
  selected,
  logged,
  nome,
  urlLogo,
  handleLogout,
  isMenuOpen,
  handleDrawerToggle,
  handleClickUsuario,
}) => {
  const currentSize = useResponsive();

  return (
    <>
      <StyledAppBar position="fixed" elevation={1}>
        <StyledToolbar>
          <Grid display={"flex"} alignItems={"center"} gap={"0.5rem"}>
            {/*{currentSize === "xs" && logged && (*/}
            {currentSize === "xs" && (
              <StyledIconButton onClick={handleDrawerToggle}>
                <MenuIcon />
              </StyledIconButton>
            )}
            <SmallLogo src={!!urlLogo ? urlLogo : logo} />
            {currentSize !== "xs" && logged && (
              <StyledDrawerButton
                variant="contained"
                onClick={handleDrawerToggle}
              >
                <KeyboardDoubleArrowRight
                  style={{
                    transform: isMenuOpen ? "rotate(180deg)" : "",
                    transition: "transform 150ms ease-in",
                  }}
                />
              </StyledDrawerButton>
            )}
          </Grid>
          {logged && (
            <Grid display={"flex"}>
              {currentSize !== "xs" && (
                <StyledButton color="inherit" onClick={handleClickUsuario}>
                  <UserIcon />
                  <UserName className="user-text mr-2">{nome}</UserName>
                </StyledButton>
              )}
              <Tooltip arrow title={"Sair"}>
                <IconButton color={"inherit"} onClick={handleLogout}>
                  <ExitIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </StyledToolbar>
      </StyledAppBar>
      {logged && (
        <StyledDrawer
          variant={currentSize === "xs" ? "temporary" : "permanent"}
          open={isMenuOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{ width: isMenuOpen ? "200px" : "54px" }}
        >
          <Toolbar />
          <Paper
            sx={{ paddingTop: currentSize === "xs" ? "8px" : "0px" }}
            isOpen={isMenuOpen}
          >
            <StyledList disablePadding>
              {items.map((option) =>
                !!option.section ? (
                  <StyledSectionItem key={option.id}>
                    {isMenuOpen && (
                      <ListItemText>{option.section}</ListItemText>
                    )}
                  </StyledSectionItem>
                ) : (
                  <StyledListItem
                    button
                    key={option.id}
                    onClick={option.clickEvent}
                    selected={option.id === selected}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: isMenuOpen ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      {isMenuOpen || currentSize === "xs" ? (
                        <ListItemText
                          style={{
                            display: isMenuOpen ? "block" : "none",
                            opacity: isMenuOpen ? 1 : 0,
                            transition: "opacity ease-in 150ms 150ms",
                          }}
                        >
                          {option.label}
                        </ListItemText>
                      ) : option.icon ? (
                        option.icon
                      ) : (
                        <></>
                      )}
                    </ListItemButton>
                  </StyledListItem>
                )
              )}
            </StyledList>
          </Paper>
        </StyledDrawer>
      )}
    </>
  );
};

export default Header;
