import {
  Badge,
  ButtonBase as MButtonBase,
  Chip,
  Grid,
  InputAdornment,
  styled,
  Tabs,
} from "@mui/material";
import { logos } from "../../constants/generic.constants";
import ptBR from "date-fns/locale/pt-BR";
import formatters from "../../utils/formatters";
import {
  Cancel,
  CheckCircle,
  Edit,
  EmailRounded,
  Help,
  House,
  SettingsSuggest,
  Share,
  SwapHoriz,
  WhatsApp,
} from "@mui/icons-material";
import Stepper from "../../business/operacao/preenchimento/Stepper";
import { Card, IconButton, Link, Tooltip } from "../index";
import React, { useState } from "react";
import EtapasAndamentoConnected from "../workflow/EtapasAndamento.connected";
import { capitalizeFirstLetter } from "../../utils/basic";
import useResponsive from "../../hooks/useResponsive";
import { actions } from "../../actions/workflow.actions";
import { DatePicker } from "@mui/x-date-pickers";
import AlterarInputPropostaDialogConnected from "../../business/workflow/AlterarInputPropostaDialog.connected";
import { transientOptions } from "../../styles/theme";
import DetalhesOperacaoBlockConnected from "../../business/operacao/blocks/DetalhesOperacaoBlock.connected";

const Logo = styled("img")`
  width: 64px;
  height: 64px;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.grey._100};
  object-fit: contain;
  display: block;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    width: 140px;
		height: 140px;
		border-radius: ${({ theme }) => theme.borderRadiuses.imageHolders};
  }`}
`;
const SituacaoParceiroContainer = styled("span")`
  padding: 0.3rem 0.5rem;
  display: inline-block;
  font-size: ${({ theme }) => theme.fonts.size.parceiro.situacao};
  font-weight: 700;
  color: ${({ theme, _situacao_, _color_ }) =>
    !!_color_
      ? _color_
      : _situacao_ === "T"
      ? theme.colors.success._600
      : _situacao_ === "C"
      ? theme.colors.error._600
      : theme.colors.secondary._600};
  background-color: ${({ theme, _situacao_, _color_ }) =>
    !!_color_
      ? `${_color_}20`
      : _situacao_ === "T"
      ? `${theme.colors.success._600}20`
      : _situacao_ === "C"
      ? `${theme.colors.error._600}20`
      : `${theme.colors.secondary._600}20`};
  border-radius: 6px;
`;
const NomeProduto = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.operacao.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.grey._700};
`;
const NomeCliente = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.operacao.title};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.grey._700};
  margin-top: 0.5rem;
  align-self: flex-start;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
const DescricaoSituacao = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.grey._400};
  text-align: center;
  margin-top: 1rem;
`;
const CpfCnpj = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.operacao.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grey._300};
`;

const NumeroProposta = styled("div", transientOptions)`
  font-size: ${({ theme }) => theme.fonts.size.operacao.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.grey._300};
  margin-left: ${({ _marginLeft_ }) => _marginLeft_ ?? "1rem"};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const NomeParceiro = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.operacao.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secondary._600};
`;
const StyledIcon = styled(WhatsApp)`
  margin-right: 0.25rem;
  height: 24px;
  width: 24px;
`;
const LinkTelefone = styled(Link)`
  font-size: ${({ theme }) => theme.fonts.size.operacao.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey._200};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary._300};
    font-weight: 600;
    text-decoration: underline;
  }
`;
const ButtonBase = styled(MButtonBase)`
  padding: 5px 10px 5px 10px;
  border-radius: 15px;
  transition: 0.2s;

  &:hover {
    transition: 0.2s;
    background-color: ${({ theme }) => theme.colors.grey._50};
  }
`;
const Email = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.operacao.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey._200};
`;
const TabContainer = styled(Tabs)`
  display: flex;
  margin: 1.5rem 0 -1.5rem;
  border-top: solid 2px ${({ theme }) => theme.colors.grey._50};
  font-size: ${({ theme }) => theme.fonts.size.operacao.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};

  & .MuiTab-root {
    letter-spacing: 0;
  }

  & .MuiTabs-indicator {
    height: 3px;
    background-color: ${({ theme }) => theme.colors.primary._400};
  }

  & .MuiTabs-scrollButtons.Mui-disabled {
    width: 0;
  }
`;
const Block = styled("div")`
  margin: 1rem 0 0;
  border-top: solid 2px ${({ theme }) => theme.colors.grey._50};
  padding-top: 0.75rem;
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 0.5rem;
  padding: 0;
  margin-top: -0.25rem;
  color: ${({ theme }) => theme.colors.secondary._500};

  &.Mui-disabled {
    background-color: ${({ theme }) => theme.colors.grey._100};
  }
  & .MuiSvgIcon-root {
    font-size: 1rem;
  }
`;

const PrevisaoPagamentoChip = styled(Chip, transientOptions)`
  background-color: ${({ theme, _situacaoPagamento_ }) =>
    _situacaoPagamento_ === "NI"
      ? theme.colors.info._600
      : _situacaoPagamento_ === "BP"
      ? theme.colors.warning._500
      : theme.colors.success._600};
  height: 22px;
  border-radius: 6px;

  &:hover {
    background-color: ${({ theme, _situacaoPagamento_ }) =>
      _situacaoPagamento_ === "NI"
        ? theme.colors.info._700
        : _situacaoPagamento_ === "BP"
        ? theme.colors.warning._600
        : theme.colors.success._600};

    & .MuiChip-deleteIcon {
      color: ${({ theme }) => theme.colors.fixed.white};
    }
  }

  & .MuiChip-label {
    border-radius: inherit;
    color: ${({ theme }) => theme.colors.fixed.white};
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.small};
    font-weight: 800;
    padding-left: 0.5rem;
  }
  & .MuiChip-deleteIcon {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.fixed.white};
    margin: 0 0.3rem 0 -6px;
  }
`;
const ActionContainer = styled(IconButton, transientOptions)`
  width: 64px;
  height: 64px;
  border-radius: 1rem;
  background-color: ${({ theme, _selected_ }) =>
    _selected_ ? theme.colors.primary._500 : theme.colors.grey._50};
  color: ${({ theme, _selected_ }) =>
    _selected_ ? theme.colors.primary._50 : theme.colors.grey._400};

  &:hover {
    background-color: ${({ theme, _selected_ }) =>
      _selected_ ? theme.colors.primary._500 : theme.colors.grey._50};
    color: ${({ theme, _selected_ }) =>
      _selected_ ? theme.colors.primary._50 : theme.colors.grey._400};
  }
`;
const IconImovelDefinido = styled(CheckCircle, transientOptions)`
  font-size: 1rem;
  color: ${({ theme, _color_ }) =>
    _color_ === "success"
      ? theme.colors.success._500
      : _color_ === "error"
      ? theme.colors.error._500
      : _color_ === "info"
      ? theme.colors.info._500
      : theme.colors.grey._300};
  margin-top: 0.25rem;
  margin-right: 0.25rem;
`;
const IconAutomacaoPermitida = styled(CheckCircle, transientOptions)`
  font-size: 1rem;
  color: ${({ theme, _color_ }) =>
    _color_ === "success"
      ? theme.colors.success._500
      : _color_ === "error"
      ? theme.colors.error._500
      : _color_ === "info"
      ? theme.colors.info._500
      : theme.colors.grey._300};
  margin-top: 0.25rem;
  margin-right: 0.25rem;
`;

const PrevisaoPagamentoComponent = (props) => {
  const {
    setOpenPrevisao,
    setPrevisao,
    situacaoPagamento,
    onChange,
    disabled,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;
  const value = capitalizeFirstLetter(
    !!props.value
      ? formatters.dates.simple(props.value, "MMMM yy", { locale: ptBR })
      : null
  );
  return (
    <PrevisaoPagamentoChip
      ref={ref}
      aria-label={ariaLabel}
      onClick={disabled ? null : () => setOpenPrevisao?.((prev) => !prev)}
      _situacaoPagamento_={situacaoPagamento}
      label={value ?? "Sem previsão"}
      onDelete={
        !disabled && situacaoPagamento === "NI"
          ? () => onChange(null, setPrevisao)
          : null
      }
    />
  );
};

const OperationHeader = ({
  data,
  timeline = false,
  etapas = false,
  detalhes = false,
  tab,
  perfil,
  contextoAcao,
  handleAcaoCallback,
  handleTabChange,
  handlePhoneClick,
  handleNameClick,
  children,
  compartilharOperacaoClick,
  handleAtribuirResponsavel,
  usuario,
  numeroProposta,
  previsao,
  setPrevisao,
  handleCloseNumeroProposta,
  handleOpenNumeroProposta,
  handleCopyNumeroProposta,
  handleCopyNumeroContrato,
  handleChangePrevisaoPagamento,
  handleChangeImovelDefinido,
  handleChangePermiteAutomacao,
}) => {
  const currentSize = useResponsive();
  const isMobile = currentSize === "xs" || currentSize === "sm";
  const [openPrevisao, setOpenPrevisao] = useState(false);

  return (
    <Card>
      <AlterarInputPropostaDialogConnected
        dados={numeroProposta}
        handleClose={handleCloseNumeroProposta}
      />
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item>
          {data.tipoProduto === "L" ? (
            data.lead.tipoProduto === "I" ? (
              <Logo src={logos["lead-aquisicao"]} />
            ) : data.lead.tipoProduto === "C" ? (
              <Logo src={logos["lead-consorcio"]} />
            ) : data.lead.tipoProduto === "H" ? (
              <Logo src={logos["lead-cgi"]} />
            ) : (
              <Logo src={logos.lead} />
            )
          ) : (
            <Logo src={logos[data.codigoInstituicao]} />
          )}
        </Grid>
        <Grid item xs={true}>
          <Grid display={"flex"} flexDirection={"column"}>
            <Grid
              display={"flex"}
              gap={isMobile ? "0.5rem" : "1rem"}
              flexDirection={isMobile ? "column" : "row"}
              alignItems={isMobile ? "flex-start" : "center"}
            >
              <NomeProduto>{data.nomeProduto}</NomeProduto>
              <SituacaoParceiroContainer
                _situacao_={data.tipoSituacao}
                _color_={data.corBadge}
              >
                {data.situacao}
              </SituacaoParceiroContainer>
              {perfil === "OPERACAO" && data.tipoProduto !== "L" && (
                <DatePicker
                  label={""}
                  format={"MMMM yy"}
                  disabled={
                    !!data.dataHoraEncerramento ||
                    (data.detalhe?.situacaoPagamento !== "NI" &&
                      data.detalhe?.situacaoPagamento !== "BP")
                  }
                  views={["month", "year"]}
                  slots={{
                    field: PrevisaoPagamentoComponent,
                  }}
                  value={previsao}
                  onChange={(v) =>
                    handleChangePrevisaoPagamento(v, setPrevisao)
                  }
                  slotProps={{
                    field: {
                      setOpenPrevisao,
                      setPrevisao,
                      situacaoPagamento: data.detalhe?.situacaoPagamento,
                    },
                  }}
                  open={openPrevisao}
                  onClose={() => setOpenPrevisao(false)}
                  onOpen={() => setOpenPrevisao(true)}
                />
              )}
            </Grid>
            <NomeCliente onClick={handleNameClick}>
              {data.nomeCliente}
            </NomeCliente>
            <CpfCnpj>{formatters.strings.cpfCnpj(data.cpfCnpjCliente)}</CpfCnpj>
            {data.tipoProduto === "L" && (
              <Grid container columnSpacing={2}>
                <Grid item display={"flex"} alignItems={"center"}>
                  <LinkTelefone
                    onClick={() => handlePhoneClick(data.lead.telefone)}
                  >
                    <StyledIcon as={WhatsApp} /> {data.lead.telefone}
                  </LinkTelefone>
                </Grid>
                <Grid item display={"flex"} alignItems={"center"}>
                  <Email>
                    <StyledIcon as={EmailRounded} />
                    {data.lead.email}
                  </Email>
                </Grid>
              </Grid>
            )}

            <Grid container columnSpacing={2}>
              <Grid item display={"flex"} alignItems={"center"}>
                <NomeParceiro>{data.nomeParceiro}</NomeParceiro>
              </Grid>
              <Grid item display={"flex"} alignItems={"center"}>
                <LinkTelefone
                  onClick={() => handlePhoneClick(data.telefoneParceiro, "")}
                >
                  <StyledIcon as={WhatsApp} />
                  {data.telefoneParceiro}
                </LinkTelefone>
              </Grid>
              <Grid item display={"flex"} alignItems={"center"}>
                {usuario.perfil === "OPERACIONAL" ||
                usuario.perfil === "OPERACAO" ||
                usuario.perfil === "PARCEIRO_MASTER" ? (
                  <ButtonBase
                    variant="text"
                    onClick={handleAtribuirResponsavel}
                  >
                    <Email>
                      {data.nomeContatoParceiro ?? "Sem responsável"}
                    </Email>
                    <InputAdornment position={"end"} sx={{ marginLeft: 0.5 }}>
                      <SwapHoriz color="brand" />
                    </InputAdornment>
                  </ButtonBase>
                ) : (
                  <Email>{data.nomeContatoParceiro}</Email>
                )}
              </Grid>
            </Grid>
            {!!data.detalhe && (
              <Grid container columnSpacing={2}>
                <Tooltip arrow title={"Copiar código da proposta"}>
                  <NumeroProposta onClick={handleCopyNumeroProposta}>
                    {data.detalhe?.numeroProposta || "-"}
                  </NumeroProposta>
                </Tooltip>
                {!data.dataHoraEncerramento && perfil === "OPERACAO" && (
                  <Tooltip arrow title={"Alterar código da proposta"}>
                    <StyledIconButton
                      loading={false}
                      size={"small"}
                      loaderColor={"grey"}
                      onClick={() =>
                        handleOpenNumeroProposta({
                          action: actions.atualizarNumeroProposta.request,
                          title: "Alterar o número da proposta",
                          label: "Número da Proposta",
                          type: "string",
                          contexto: {
                            id_operacao: data.id,
                            input: data.detalhe?.numeroProposta || "",
                          },
                          callback: () => {
                            window.location.reload();
                          },
                        })
                      }
                    >
                      <Edit />
                    </StyledIconButton>
                  </Tooltip>
                )}
                <Tooltip arrow title={"Copiar número do contrato"}>
                  <NumeroProposta
                    _marginLeft_={"0.5rem"}
                    onClick={handleCopyNumeroContrato}
                  >
                    {data.detalhe?.numeroContrato || "-"}
                  </NumeroProposta>
                </Tooltip>
                {!data.dataHoraEncerramento && perfil === "OPERACAO" && (
                  <Tooltip arrow title={"Alterar número do contrato"}>
                    <StyledIconButton
                      loading={false}
                      size={"small"}
                      loaderColor={"grey"}
                      onClick={() =>
                        handleOpenNumeroProposta({
                          action: actions.atualizarNumeroContrato.request,
                          title: "Alterar o número do contrato",
                          label: "Número do Contrato",
                          type: "string",
                          contexto: {
                            id_operacao: data.id,
                            input: data.detalhe?.numeroContrato || "",
                          },
                          callback: () => {
                            window.location.reload();
                          },
                        })
                      }
                    >
                      <Edit />
                    </StyledIconButton>
                  </Tooltip>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={"auto"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-end"}
          gap={1}
          flexWrap={"wrap-reverse"}
          height={"200px"}
        >
          <Grid>
            {data.tipoProduto !== "L" && (
              <Tooltip
                arrow
                title={
                  data.detalhe?.imovelDefinido === true
                    ? "Imóvel Definido"
                    : data.detalhe?.imovelDefinido === false
                    ? "Imóvel Não Definido"
                    : "Não Respondido"
                }
              >
                <ActionContainer onClick={handleChangeImovelDefinido}>
                  <Badge
                    badgeContent={
                      <IconImovelDefinido
                        as={
                          data.detalhe?.imovelDefinido === true
                            ? CheckCircle
                            : data.detalhe?.imovelDefinido === false
                            ? Cancel
                            : Help
                        }
                        _color_={
                          data.detalhe?.imovelDefinido === true
                            ? "success"
                            : data.detalhe?.imovelDefinido === false
                            ? "error"
                            : "info"
                        }
                      />
                    }
                  >
                    <House />
                  </Badge>
                </ActionContainer>
              </Tooltip>
            )}
          </Grid>
          {perfil !== "EXTERNO" && data.tipoProduto !== "L" ? (
            <Grid>
              <Tooltip arrow title={"Compartilhar"}>
                <ActionContainer
                  onClick={() => {
                    compartilharOperacaoClick(data.id);
                  }}
                >
                  <Share />
                </ActionContainer>
              </Tooltip>
            </Grid>
          ) : null}
          {perfil === "OPERACAO" && (
            <Grid>
              <Tooltip
                arrow
                title={
                  data.permiteAutomacao === true
                    ? "Automações Habilitadas"
                    : data.permiteAutomacao === false &&
                      "Automações Desativadas"
                }
              >
                <ActionContainer onClick={handleChangePermiteAutomacao}>
                  <Badge
                    badgeContent={
                      <IconAutomacaoPermitida
                        as={
                          data.permiteAutomacao === true
                            ? CheckCircle
                            : data.permiteAutomacao === false
                            ? Cancel
                            : Help
                        }
                        _color_={
                          data.permiteAutomacao === true
                            ? "success"
                            : data.permiteAutomacao === false
                            ? "error"
                            : "info"
                        }
                      />
                    }
                  >
                    <SettingsSuggest />
                  </Badge>
                </ActionContainer>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
      {timeline && data.timeline && (
        <Block>
          <Stepper
            backgroundColor={"white"}
            current={data.timeline
              .map((t) => t.tipo)
              .indexOf(data.tipoSituacao)}
            cor={data.corSituacao}
            situacao={data.tipoSituacao}
            fluxo={data.timeline}
            shouldSupress={true}
          />
          {data.descricaoSituacao && (
            <DescricaoSituacao>{data.descricaoSituacao}</DescricaoSituacao>
          )}
        </Block>
      )}
      {detalhes && (
        <Block>
          <DetalhesOperacaoBlockConnected />
        </Block>
      )}
      {etapas && (
        <EtapasAndamentoConnected
          idOperacao={data.id}
          contexto={contextoAcao}
          callback={handleAcaoCallback}
        />
      )}
      {!!tab && !!children && (
        <TabContainer
          value={tab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {children}
        </TabContainer>
      )}
    </Card>
  );
};

export default OperationHeader;
