import { useDispatch, useSelector } from "react-redux";
import {
  getDadosBancariosOperacao,
  isCarregandoDadosBancariosOperacao,
  isEnviandoDadosBancariosOperacao,
} from "../../../selectors/operacao.selectors";
import DadosBancariosOperacaoPage from "./DadosBancariosOperacaoPage";
import { useCallback, useEffect } from "react";
import {
  actions as routeActions,
  types as routes,
} from "../../../actions/rotas.actions";
import useCompleteForm from "../../../hooks/useCompleteForm";
import validators from "../../../utils/validators";
import { actions } from "../../../actions/operacao.actions";

const DadosBancariosOperacaoPagConnected = () => {
  const dispatch = useDispatch();
  const loading = useSelector(isCarregandoDadosBancariosOperacao);
  const sending = useSelector(isEnviandoDadosBancariosOperacao);
  const data = useSelector(getDadosBancariosOperacao);

  const operacao = data?.operacao;
  const bancos = data?.dominios?.banco;

  const handleGoBack = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.DETALHES_OPERACAO, {
        id: operacao?.id,
      })
    );
  }, [dispatch, operacao]);

  const compradorPrincipal = operacao?.participantes?.find((p) => p.principal);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      bancoConta: validators.object({
        required: true,
      }),
      agenciaConta: validators.string({
        required: true,
      }),
      numeroConta: validators.string({
        required: true,
      }),
    }),
    initialValues: useCallback(
      () => ({
        bancoConta: null,
        agenciaConta: "",
        numeroConta: "",
        autorizaTarifaAvaliacao: false,
        autorizaLimite: false,
        autorizaAtraso: false,
      }),
      []
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.enviarDadosBancarios.request({
            instituicao: operacao?.codigoInstituicao,
            passo: "dados-bancarios",
            participante: {
              id: compradorPrincipal?.id,
              idBancoConta: values.bancoConta?.id,
              agenciaConta: values.agenciaConta,
              numeroConta: values.numeroConta,
              autorizaDebitoTarifaAvaliacao: values.autorizaTarifaAvaliacao,
              autorizaUtilizacaoLimiteConta: values.autorizaLimite,
              autorizaDebitoParcelasAtrasadas: values.autorizaAtraso,
            },
            operacao: {
              id: operacao?.id,
            },
          })
        );
      },
      [dispatch, operacao, compradorPrincipal]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      bancoConta: null,
      agenciaConta: "",
      numeroConta: "",
      autorizaTarifaAvaliacao: false,
      autorizaLimite: false,
      autorizaAtraso: false,
      ...compradorPrincipal,
    });
  }, [compradorPrincipal, reset]);

  return (
    <DadosBancariosOperacaoPage
      loading={loading}
      operacao={operacao}
      bancos={bancos}
      formProps={formProps}
      sending={sending}
      handleGoBack={handleGoBack}
      handleSubmit={handleSubmit}
    />
  );
};

export default DadosBancariosOperacaoPagConnected;
