import "core-js-bundle";
import React from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import "@fontsource/nunito-sans";
import App from "./App";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ptBR from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import reportWebVitals from "./reportWebVitals";
import theme from "./styles/theme";
import store from "./store";
import { ToastContainer } from "./components";
import FaviconConnected from "./business/FavIcon.connected";
import DocumentDialogs from "./business/documento/DocumentDialogs";
import DocumentosPendentesDialogConnected from "./business/workflow/DocumentosPendentesDialog.connected";
import UltComentarioDialogConnected from "./business/operacao/UltComentarioDialog.connected";
import CompartilhamentoDialogConnected from "./business/operacao/CompartilhamentoDialog.connected";
import CompartilhamentoCalculadoraDialogConnected from "./business/usuarios/CompartilhamentoCalculadoraDialog.connected";
import AtribuirResponsavelDialogConnected from "./business/operacao/AtribuirResponsavelDialog.connected";
import ComissionamentoParceiroDialogConnected from "./business/parceiro/ComissionamentoParceiroDialog.connected";
import FiliaisDialogConnected from "./business/parceiro/FiliaisDialog.connected";
import EditarComentarioDialogConnected from "./business/workflow/EditarComentarioDialog.connected";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        /^https:\/\/imobiliario\\.desenvolvimento\\.taya\\.com\\.br/,
        /^https:\/\/imobiliario\\.taya\\.com\\.br/,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  allowUrls: [
    /^https:\/\/imobiliario\\.desenvolvimento\\.taya\\.com\\.br/,
    /^https:\/\/imobiliario\\.taya\\.com\\.br/,
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <FaviconConnected />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <App />
        <DocumentosPendentesDialogConnected />
        <DocumentDialogs />
        <CompartilhamentoDialogConnected />
        <UltComentarioDialogConnected />
        <CompartilhamentoCalculadoraDialogConnected />
        <AtribuirResponsavelDialogConnected />
        <ComissionamentoParceiroDialogConnected />
        <FiliaisDialogConnected />
        <EditarComentarioDialogConnected/>
      </LocalizationProvider>
      <ToastContainer
        position="bottom-right"
        hideProgressBar={true}
        theme="colored"
        icon={false}
      />
    </ThemeProvider>
  </Provider>
);

reportWebVitals(console.log);
