import React, { useCallback, useMemo } from "react";
import OutrasInformacoesImovelC6Form from "./OutrasInformacoesImovelC6Form";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import {
  TIPO_PROPRIETARIO_IMOVEL,
  TIPO_RESIDENTE_IMOVEL,
} from "../../../../../constants/generic.constants";

const OutrasInformacoesImovelC6FormConnected = ({
  makeGoTo,
  eventsImovel,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    validacao,
    dominios: { subTipoImovel: subTiposImovel },
  } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const imovel = useMemo(
    () => ({
      ...operacao.imovel,
      fluxo: validacao.completa.imovel,
    }),
    [operacao, validacao]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      subTipoImovel: validators.object({ required: true }),
      areaConstruida: validators.number({ required: true }),
      quantidadeVagas: validators.number({ required: true }),
      vagaAutonoma: validators.string({
        required: false,
        custom: {
          requiredIfVagas: (v, f) => {
            const { quantidadeVagas } = f;

            if (quantidadeVagas > 0) {
              return (
                (v !== undefined && v !== null && v !== "") ||
                "Campo obrigatório"
              );
            }

            return true;
          },
        },
      }),
      imovelProprio: validators.string({ required: true }),
      tipoProprietario: validators.string({
        required: false,
        custom: {
          requiredIfNotProprio: (v, f) => {
            const { imovelProprio } = f;

            if (imovelProprio + "" === "false") {
              return !!v || "Campo obrigatório";
            }

            return true;
          },
        },
      }),
      tipoResidente: validators.string({ required: true }),
      matricula: validators.string({ required: true }),
      cartorio: {
        numero: validators.string({ required: true }),
        municipio: validators.string({ required: true }),
        uf: validators.string({ required: true }),
      },
      imovelAverbado: validators.string({ required: true }),
      imovelFinanciado: validators.string({ required: true }),
      bancoFinanciamento: validators.string({
        required: false,
        custom: {
          rrequiredIfFinanciado: (v, f) => {
            const { imovelFinanciado } = f;

            if (imovelFinanciado + "" === "true") {
              return !!v || "Campo obrigatório";
            }

            return true;
          },
        },
      }),
      saldoDevedorFinanciamento: validators.number({
        required: false,
        custom: {
          rrequiredIfFinanciado: (v, f) => {
            const { imovelFinanciado } = f;

            if (imovelFinanciado + "" === "true") {
              return !!v || "Campo obrigatório";
            }

            return true;
          },
        },
      }),
    }),
    initialValues: useCallback(
      () => ({
        subTipoImovel: null,
        areaConstruida: "",
        quantidadeVagas: "",
        vagaAutonoma: "",
        imovelProprio: "",
        tipoProprietario: "",
        tipoResidente: "",
        matricula: "",
        imovelAverbado: "",
        imovelFinanciado: "",
        bancoFinanciamento: "",
        saldoDevedorFinanciamento: "",
        ...imovel,
        cartorio: {
          numero: "",
          municipio: "",
          uf: "",
          ...imovel.cartorio,
        },
      }),
      [imovel]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback: makeGoTo(0),
            instituicao: operacao.codigoInstituicao,
            passo: "outras-informacoes-imovel",
            operacao: {
              id: operacao.id,
              imovel: {
                idSubTipoImovel: values.subTipoImovel.id,
                areaConstruida: values.areaConstruida,
                quantidadeVagas: values.quantidadeVagas,
                possuiDepositoVagaAutonoma: values.vagaAutonoma,
                imovelProprio: values.imovelProprio,
                tipoProprietario:
                  values.imovelProprio + "" === "false"
                    ? TIPO_PROPRIETARIO_IMOVEL[values.tipoProprietario].enum
                    : null,
                tipoResidente: TIPO_RESIDENTE_IMOVEL[values.tipoResidente].enum,
                matricula: values.matricula,
                imovelAverbado: values.imovelAverbado,
                imovelFinanciado: values.imovelFinanciado,
                bancoFinanciamento:
                  values.imovelFinanciado + "" === "true"
                    ? values.bancoFinanciamento
                    : null,
                saldoDevedorFinanciamento:
                  values.imovelFinanciado + "" === "true"
                    ? values.saldoDevedorFinanciamento
                    : null,
                cartorio: {
                  numero: values.cartorio.numero,
                  municipio: values.cartorio.municipio,
                  uf: values.cartorio.uf,
                },
              },
            },
          })
        );
      },
      [dispatch, operacao, makeGoTo]
    ),
  });

  return (
    <OutrasInformacoesImovelC6Form
      {...otherProps}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={imovel.fluxo}
      makeGoTo={makeGoTo}
      events={eventsImovel}
      subTiposImovel={subTiposImovel}
    />
  );
};

export default OutrasInformacoesImovelC6FormConnected;
