import ResultadoSimulacaoSection from "./ResultadoSimulacaoSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getSimuladoresDisponiveis,
  getSimuladorSelecionado,
  getResultadoSimulador,
  getDadosCliente,
  isValidandoRegrasSelecionadas,
  getRegrasSelecionadas,
} from "../../selectors/simulador.selectors";
import { useCallback, useState } from "react";
import { actions } from "../../actions/simulador.actions";
import formatters from "../../utils/formatters";
import {getMenuDisposition} from "../../selectors/tema.selectors";

const ResultadoSimulacaoSectionConnected = () => {
  const dispatch = useDispatch();

  const {
    resultado: data,
    downloading,
    recalculating,
  } = useSelector(getResultadoSimulador);
  const dadosCliente = useSelector(getDadosCliente);
  const selecionada = useSelector(getSimuladorSelecionado);
  const { data: disponiveis } = useSelector(getSimuladoresDisponiveis);
  const validating = useSelector(isValidandoRegrasSelecionadas);
  const { entrada: stateSelecionados } = useSelector(getRegrasSelecionadas);
  const isMenuOpen = useSelector(getMenuDisposition);

  const produto = disponiveis.find(
    (p) => !!p.regras.find((g) => g.id === selecionada.id)
  );

  const handleGoBack = useCallback(() => {
    dispatch(actions.navegar(2));
  }, [dispatch]);
  const handleNext = useCallback(() => {
    dispatch(actions.navegar(4));
  }, [dispatch]);

  const [selecionados, setSelecionados] = useState(stateSelecionados);

  const addToSelecionados = useCallback(
    (item) => {
      setSelecionados([...selecionados, item]);
    },
    [setSelecionados, selecionados]
  );
  const removeFromSelecionados = useCallback(
    (item) => {
      setSelecionados([...selecionados.filter((i) => i.id !== item.id)]);
    },
    [setSelecionados, selecionados]
  );

  const handleValidarRegras = useCallback(() => {
    dispatch(
      actions.validarRegrasSelecionadas.request({
        selecionados,
        callback: handleNext,
      })
    );
  }, [dispatch, selecionados, handleNext]);

  const handleRecalculate = useCallback(
    (regra, opcao) => {
      dispatch(
        actions.recalcular.request({
          regraComercial: regra.id,
          tipoAmortizacao: regra.entrada.tipoAmortizacao,
          seguradora: regra.entrada.seguradora,
          oferta: regra.entrada.oferta,
          carencia: regra.entrada.carencia,
          ...opcao,
        })
      );
    },
    [dispatch]
  );
  const handleDownload = useCallback(
    (regra) => {
      dispatch(
        actions.extratoSimulacao.request({
          regraComercial: regra.id,
          tipoAmortizacao: regra.entrada.tipoAmortizacao,
          seguradora: regra.entrada.seguradora,
          oferta: regra.entrada.oferta,
          carencia: regra.entrada.carencia,
        })
      );
    },
    [dispatch]
  );

  const [dadosWhatsApp, setDadosWhatsApp] = useState({
    open: false,
  });
  const handleCloseWhatsApp = useCallback(() => {
    setDadosWhatsApp({
      open: false,
    });
  }, []);
  const handleOpenWhatsApp = useCallback((values) => {
    setDadosWhatsApp({
      open: true,
      ...values,
    });
  }, []);
  const handleWhatsApp = useCallback(
    (regra) => {
      const mensagem = `Valor do crédito R$ ${formatters.numbers.currency(
        regra.entrada.valorTotal
      )}, primeira parcela de R$ ${formatters.numbers.currency(
        regra.primeiraParcela
      )} com uma taxa de juros de ${formatters.numbers.currency(
        regra.entrada.taxaJurosAnual
      )}% a.a.`;

      handleOpenWhatsApp({
        mensagem,
        telefone: dadosCliente.telefone,
      });
    },
    [handleOpenWhatsApp, dadosCliente]
  );

  return (
    <ResultadoSimulacaoSection
      data={data}
      isMenuOpen={isMenuOpen}
      condicao={selecionada}
      produto={produto}
      handleGoBack={handleGoBack}
      downloading={downloading}
      recalculating={recalculating}
      handleDownload={handleDownload}
      handleRecalculate={handleRecalculate}
      handleWhatsApp={handleWhatsApp}
      handleCloseWhatsApp={handleCloseWhatsApp}
      dadosWhatsApp={dadosWhatsApp}
      addToSelecionados={addToSelecionados}
      removeFromSelecionados={removeFromSelecionados}
      selecionados={selecionados}
      handleValidarRegras={handleValidarRegras}
      validating={validating}
    />
  );
};

export default ResultadoSimulacaoSectionConnected;
