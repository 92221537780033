import DadosCadastraisParceiroTab from "./DadosCadastraisParceiroTab";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetalhesParceiro,
  getDialogosDisponiveis,
  getDominioBancos,
  isAtualizandoDetalhesParceiro,
} from "../../selectors/parceiro.selectors";
import useCompleteForm from "../../hooks/useCompleteForm";
import { useCallback, useEffect } from "react";
import {
  removeNonDigitsFromString,
  replaceNullishsWithEmpties,
} from "../../utils/basic";
import { actions } from "../../actions/parceiro.actions";
import { formValues as formValuesDadosEmpresa } from "./DadosEmpresaForm";
import { formValues as formValuesDadosBancarios } from "./DadosBancariosForm";
import { formValues as formValuesDadosEndereco } from "./DadosEnderecoForm";
import { formValues as formValuesDadosAdicionais } from "./DadosAdicionaisForm";
import { formValues as formValuesDadosRepresentante } from "./DadosRepresentanteForm";
import { formValues as formValuesDadosAgendamento } from "./DadosAgendamentoForm";
import { getMenuDisposition } from "../../selectors/tema.selectors";

const DadosCadastraisParceiroTabConnected = () => {
  const dispatch = useDispatch();
  const data = useSelector(getDetalhesParceiro);
  const submitting = useSelector(isAtualizandoDetalhesParceiro);
  const isMenuOpen = useSelector(getMenuDisposition);

  const dialogosDisponiveis = useSelector(getDialogosDisponiveis);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: (form) => ({
      ...formValuesDadosRepresentante.rules(
        form,
        dialogosDisponiveis.DADOS_REPRESENTANTE
      ),
      ...formValuesDadosBancarios.rules(
        form,
        dialogosDisponiveis.DADOS_BANCARIOS
      ),
      ...formValuesDadosEndereco.rules(
        form,
        dialogosDisponiveis.ENDERECO_EMPRESA
      ),
      ...formValuesDadosAdicionais.rules(
        form,
        dialogosDisponiveis.DADOS_EMPRESA
      ),
      ...formValuesDadosAgendamento.rules(
        form,
        dialogosDisponiveis.DADOS_AGENDAMENTO
      ),
      ...formValuesDadosEmpresa.rules(form, dialogosDisponiveis.DADOS_EMPRESA),
    }),
    initialValues: () => ({
      ...formValuesDadosRepresentante.defaultValues(),
      ...formValuesDadosEmpresa.defaultValues(),
      ...formValuesDadosEndereco.defaultValues(),
      ...formValuesDadosAdicionais.defaultValues(),
      ...formValuesDadosBancarios.defaultValues(),
      ...formValuesDadosAgendamento.defaultValues(),
      ...data,
      certificados: {
        aneps: false,
        ca300: false,
        ca600: false,
        ancord: false,
        creci: false,
        cpa10: false,
        cpa20: false,
        cea: false,
        outros: "",
        ...data.certificados,
      },
      fluxoTrabalho: undefined,
    }),
    handleSubmit: useCallback(
      (values) => {
        dispatch(actions.atualizarParceiro.request(values));
      },
      [dispatch]
    ),
  });

  const { setValue, reset, defaultValues } = formProps;

  const successCallback = useCallback(
    ({ data }) => {
      setValue("logradouro", data?.logradouro || "", { shouldValidate: true });
      setValue("bairro", data?.bairro || "", { shouldValidate: true });
      setValue("uf", data?.uf || "", { shouldValidate: true });
      setValue("municipio", data?.localidade || "", { shouldValidate: true });
    },
    [setValue]
  );
  const handleZipCodeBlurred = useCallback(
    (value) => {
      if (removeNonDigitsFromString(value).length === 8) {
        dispatch(
          actions.pesquisarCep.request({
            value,
            successCallback,
          })
        );
      }
    },
    [dispatch, successCallback]
  );
  const domainBancos = useSelector(getDominioBancos);

  useEffect(() => {
    if (!!data) {
      reset({
        ...defaultValues,
        ...replaceNullishsWithEmpties({
          value: data,
          blackList: ["banco"],
        }),
      });
    }
  }, [reset, defaultValues, data]);

  const values = Object.values(dialogosDisponiveis);

  const editable =
    values.length > 0 &&
    values.filter((v) => v === "E" || v === "O").length > 0;

  return (
    <DadosCadastraisParceiroTab
      formProps={formProps}
      handleSubmit={handleSubmit}
      isMenuOpen={isMenuOpen}
      handleZipCodeBlurred={handleZipCodeBlurred}
      domainBancos={domainBancos}
      submitting={submitting}
      editable={editable}
      dialogosDisponiveis={dialogosDisponiveis}
    />
  );
};

export default DadosCadastraisParceiroTabConnected;
