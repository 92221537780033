import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import validators from "../../utils/validators";
import { actions } from "../../actions/autenticacao.actions";
import { isLoginSubmitting } from "../../selectors/autenticacao.selectors";
import useCompleteForm from "../../hooks/useCompleteForm";
import { getRouteArguments } from "../../selectors/routes.selectors";
import LoginExternoPage from "./LoginExternoPage";

const LoginExternoPageConnected = () => {
  const dispatch = useDispatch();
  const submitting = useSelector(isLoginSubmitting);
  const routeArguments = useSelector(getRouteArguments);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      cpfCnpj: validators.cpfCnpj({ required: true }),
      dataNascimento: validators.date({ required: true }),
      codigoOperacaoExterno: validators.string({ required: true, length: 11 }),
    }),
    initialValues: () => ({
      cpfCnpj: "",
      dataNascimento: "",
      codigoOperacaoExterno: routeArguments.id,
    }),
    handleSubmit: useCallback((values) => {
      dispatch(actions.loginExterno.request({
        ...values,
        codigoOperacaoExterno: routeArguments.id
      }));
    }, [dispatch]),
  });

  return (
    <LoginExternoPage
      submitting={submitting}
      formProps={formProps}
      handleSubmit={handleSubmit}
    />
  );
};

export default LoginExternoPageConnected;
