import { Grid, IconButton, styled } from "@mui/material";
import React from "react";
import { Card, SizedCard, Title } from "../../components";
import DadosClienteForm from "./DadosClienteForm";
import { ArrowBack } from "@mui/icons-material";

const GoBackButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.grey._300};
  & .MuiSvgIcon-root {
    font-size: ${({ theme }) => theme.fonts.size.large};
  }

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    & .MuiSvgIcon-root {
			font-size: ${theme.fonts.size.xlarge};
		}
  }`}
`;
const TitleText = styled("span")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.grey._300};
  font-weight: 700;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    font-size: ${theme.fonts.size.large};
  }`}
`;

const DadosClienteSection = (props) => {
  return (
    <Grid container justifyContent={"center"}>
      <Grid item container spacing={3} direction={"column"} maxWidth={704}>
        <Grid item container alignItems={"center"}>
          <Grid item mr={1}>
            <GoBackButton onClick={props.handleGoBack}>
              <ArrowBack />
            </GoBackButton>
          </Grid>
          <Grid item flex={1}>
            <TitleText>Vamos começar conhecendo seu cliente ;)</TitleText>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Card>
              <DadosClienteForm {...props} />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosClienteSection;
