import React from "react";
import Switch from "./Switch";
import { Controller } from "react-hook-form";
import { getValueFromObject } from "../../utils/basic";

const ControlledSwitch = ({
  formProps,
  name,
  validationKey,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    rules,
  } = formProps;
  const isError = getValueFromObject(errors, name) !== undefined;

  return (
    <Controller
      name={name}
      control={control}
      rules={getValueFromObject(rules, validationKey ?? name)}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <Switch
            {...otherProps}
            value={value}
            error={isError}
            helperText={getValueFromObject(errors, name)?.message}
            onChange={(v) => {
              onChange(v.target.checked);
              !!otherProps.onChange && otherProps.onChange(v);
            }}
            onBlur={onBlur}
          />
        );
      }}
    />
  );
};

export default ControlledSwitch;
