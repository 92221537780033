import { types, actions } from "../actions/documentos.actions";

const initialState = {
  ia: {
    show: false,
    data: null,
    validating: false,
    result: null,
    submitting: false,
  },
  validadeCredito: {
    show: false,
    data: null,
    submitting: false,
  },
  validacaoDocumento: {
    loading: false,
    submitting: false,
    documento:null,
    operacao:null,
    erros:null
  },
  condicoesComerciais: {
    show: false,
    data: null,
    submitting: false,
  },
  laudoAvaliacao: {
    show: false,
    data: null,
    submitting: false,
  },
  dominios: {
    operacao: {
      loading: false,
      data: null,
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.dominioOperacao.REQUEST:
    case actions.dominioOperacao.SUCCESS:
    case actions.dominioOperacao.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          operacao: {
            ...state.dominios.operacao,
            loading: action.type === actions.dominioOperacao.REQUEST,
            data:
              action.type === actions.dominioOperacao.SUCCESS
                ? action.payload.response.data
                : null,
          },
        },
      };
    case actions.exibirValidacaoDocumento.REQUEST:
    case actions.exibirValidacaoDocumento.SUCCESS:
    case actions.exibirValidacaoDocumento.FAILURE:
      return {
        ...state,
        validacaoDocumento: {
          ...state.validacaoDocumento,
          documento: action.type === actions.exibirValidacaoDocumento.SUCCESS ? action.payload.response.data.documento : null,
          operacao: action.type === actions.exibirValidacaoDocumento.SUCCESS ? action.payload.response.data.operacao : null,
          loading: action.type === actions.exibirValidacaoDocumento.REQUEST,
        },
      };
    case actions.gravarValidacaoDocumento.REQUEST:
    case actions.gravarValidacaoDocumento.SUCCESS:
    case actions.gravarValidacaoDocumento.FAILURE:
      return {
        ...state,
        validacaoDocumento: {
          ...state.validacaoDocumento,
          erros: action.type === actions.gravarValidacaoDocumento.SUCCESS ? action.payload.response.data.erros : state.validacaoDocumento.erros,
          documento: action.type === actions.exibirValidacaoDocumento.SUCCESS ? action.payload.response.data.documento :  state.validacaoDocumento.documento,
          submitting: action.type === actions.gravarValidacaoDocumento.REQUEST,
        },
      };
    case actions.confirmarValidacaoDocumento.REQUEST:
    case actions.confirmarValidacaoDocumento.SUCCESS:
    case actions.confirmarValidacaoDocumento.FAILURE:
      return {
        ...state,
        validacaoDocumento: {
          ...state.validacaoDocumento,
          documento: action.type === actions.gravarValidacaoDocumento.SUCCESS ? action.payload.response.data : state.validacaoDocumento.documento,
          submitting: action.type === actions.gravarValidacaoDocumento.REQUEST,
        },
      };
    case types.EXIBIR_VALIDADE_CREDITO:
      return {
        ...state,
        validadeCredito: {
          ...state.validadeCredito,
          show: action.payload.show,
          data: action.payload.data,
        },
      };
    case actions.gravarValidadeCredito.REQUEST:
    case actions.gravarValidadeCredito.SUCCESS:
    case actions.gravarValidadeCredito.FAILURE:
      return {
        ...state,
        validadeCredito: {
          ...state.validadeCredito,
          submitting: action.type === actions.gravarValidadeCredito.REQUEST,
        },
      };

    case types.EXIBIR_CONDICOES_COMERCIAIS:
      return {
        ...state,
        condicoesComerciais: {
          ...state.condicoesComerciais,
          show: action.payload.show,
          data: action.payload.data,
        },
      };
    case actions.permiteEnviarDocumentos.REQUEST:
    case actions.permiteEnviarDocumentos.SUCCESS:
    case actions.permiteEnviarDocumentos.FAILURE:
    case actions.permiteEnviarDocumento.REQUEST:
    case actions.permiteEnviarDocumento.SUCCESS:
    case actions.permiteEnviarDocumento.FAILURE:
      return {
        ...state
      };
    case actions.gravarCondicoesComerciais.REQUEST:
    case actions.gravarCondicoesComerciais.SUCCESS:
    case actions.gravarCondicoesComerciais.FAILURE:
      return {
        ...state,
        condicoesComerciais: {
          ...state.condicoesComerciais,
          submitting: action.type === actions.gravarCondicoesComerciais.REQUEST,
        },
      };

    case types.EXIBIR_LAUDO_AVALIACAO:
      return {
        ...state,
        laudoAvaliacao: {
          ...state.laudoAvaliacao,
          show: action.payload.show,
          data: action.payload.data,
        },
      };
    case actions.gravarLaudoAvaliacao.REQUEST:
    case actions.gravarLaudoAvaliacao.SUCCESS:
    case actions.gravarLaudoAvaliacao.FAILURE:
      return {
        ...state,
        laudoAvaliacao: {
          ...state.laudoAvaliacao,
          submitting: action.type === actions.gravarLaudoAvaliacao.REQUEST,
        },
      };

    case types.EXIBIR_IA:
      return {
        ...state,
        ia: {
          ...state.ia,
          show: action.payload.show,
          validating: true,
          data: action.payload.data,
        },
      };
    case actions.validarIA.REQUEST:
    case actions.validarIA.SUCCESS:
    case actions.validarIA.FAILURE:
      return {
        ...state,
        ia: {
          ...state.ia,
          validating: action.type === actions.validarIA.REQUEST,
          result:
            action.type === actions.validarIA.SUCCESS
              ? action.payload.response.data
              : null,
        },
      };
    case actions.gravarIA.REQUEST:
    case actions.gravarIA.SUCCESS:
    case actions.gravarIA.FAILURE:
      return {
        ...state,
        ia: {
          ...state.ia,
          submitting: action.type === actions.gravarIA.REQUEST,
        },
      };
    default:
      return state;
  }
};

export default reducer;
