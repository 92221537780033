import { CloseSharp, ContentCopy } from "@mui/icons-material";
import {
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    InputAdornment as MInputAdornment,
    Skeleton,
    styled,
} from "@mui/material";
import { Title, Tooltip } from "../../components";
import { TextField } from "../../components/inputs";

const InputAdornment = styled(MInputAdornment)`
  cursor: pointer;
`;
const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    width: 100%;
  }
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;
const CompartilhamentoCalculadoraDialog = ({ handleClose, open, loading, data, handleCopyText, ...props }) => {
    return (
        <StyledDialog
            onClose={() => handleClose()}
            disableEscapeKeyDown
            maxWidth="xs"
            open={open}
        >
            {open ?
                <DialogContent>
                    <CloseIconButton onClick={handleClose}>
                        <CloseSharp />
                    </CloseIconButton>
                    <Title as={"h2"} _margin_={"0 0 1rem 0"}>
                        Compartilhar
                    </Title>
                    <TextField
                        value={window.location.origin + "/calculadora/" + data}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" onClick={() => handleCopyText(window.location.origin + "/calculadora/" + data)}>
                                    <ContentCopy />
                                </InputAdornment>
                            ),
                        }}
                    />
                </DialogContent>
                : null}
        </StyledDialog>
    );
};

export default CompartilhamentoCalculadoraDialog;