import {
  parse as parseDate,
  parseISO,
  format as formatDate,
  formatISO,
} from "date-fns";

export const capitalizeFirstLetter = (string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const fileToBase64 = (file, complete = false) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(
        complete
          ? reader.result
          : reader.result.substring(reader.result.indexOf(",") + 1)
      );
    reader.onerror = reject;
  });
export const getFileNameFromHeader = (headers, headerName) => {
  const disposition = headers[headerName];
  if (disposition && disposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, "");
    }
  }
  return null;
};
export const removeNonDigitsFromString = (s) => {
  if (!s) return s;
  return s.replace(/\D/g, "");
};

export const joinDateTime = (d, h) => {
  if (!d) {
    return "";
  }
  const hour = !!h ? h : "00:00";
  return d + " " + hour;
};

export const transformToDateTime = (s) => {
  if (!s || !s.trim()) {
    return "";
  }
  const date = parseDateUsingFormat(s, "dd/MM/yyyy HH:mm");
  return formatISO(date, { format: "extended", representation: "complete" });
};
export const transformFromDateTime = (s) => {
  if (!s) {
    return "";
  }
  const date = parseISO(s);
  return formatDate(date, "dd/MM/yyyy HH:mm");
};
export const parseDateUsingFormat = (value, format = "dd/MM/yyyy") => {
  try {
    return parseDate(value, format, new Date());
  } catch (err) {
    return null;
  }
};
export const transformToDate = (s) => {
  if (!s) {
    return "";
  }
  const date = parseDateUsingFormat(s, "dd/MM/yyyy");
  return formatDate(date, "yyyy-MM-dd");
};
export const transformFromDate = (s) => {
  if (!s) {
    return "";
  }
  const date = parseISO(s);
  return formatDate(date, "dd/MM/yyyy");
};

export const getValueFromObject = (obj, name, defaultValue) => {
  if (!!obj && !!name) {
    const paths = name.split(/[,[\].]+?/);
    let p = 0;
    while (obj && p < paths.length) {
      obj = obj[paths[p++]];
    }
    return obj === undefined ? defaultValue : obj;
  }

  return undefined;
};

export const replaceNullishsWithEmpties = ({
  value,
  fields = [],
  blackList = [],
}) => {
  const result = {};
  Object.keys(value).forEach((k) => {
    if (
      value.hasOwnProperty(k) &&
      (fields.length === 0 || fields.indexOf(k) >= 0) &&
      blackList.indexOf(k) === -1
    ) {
      result[k] = value[k] ?? "";
    }
  });
  return result;
};

const pSBC = (p, c0, c1, l) => {
  let r,
    g,
    b,
    P,
    f,
    t,
    h,
    m = Math.round,
    a = typeof c1 == "string";
  if (
    typeof p != "number" ||
    p < -1 ||
    p > 1 ||
    typeof c0 != "string" ||
    (c0[0] != "r" && c0[0] != "#") ||
    (c1 && !a)
  )
    return null;
  // eslint-disable-next-line no-unused-expressions
  (h = c0.length > 9),
    (h = a ? (c1.length > 9 ? true : c1 == "c" ? !h : false) : h),
    (f = pSBC.pSBCr(c0)),
    (P = p < 0),
    (t =
      c1 && c1 != "c"
        ? pSBC.pSBCr(c1)
        : P
        ? { r: 0, g: 0, b: 0, a: -1 }
        : { r: 255, g: 255, b: 255, a: -1 }),
    (p = P ? p * -1 : p),
    (P = 1 - p);
  if (!f || !t) return null;
  if (l)
    // eslint-disable-next-line no-unused-expressions
    (r = m(P * f.r + p * t.r)),
      (g = m(P * f.g + p * t.g)),
      (b = m(P * f.b + p * t.b));
  else {
    // eslint-disable-next-line no-unused-expressions
    (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
      (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
      (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
  }
  // eslint-disable-next-line no-unused-expressions
  (a = f.a),
    (t = t.a),
    (f = a >= 0 || t >= 0),
    (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
  if (h)
    return (
      "rgb" +
      (f ? "a(" : "(") +
      r +
      "," +
      g +
      "," +
      b +
      (f ? "," + m(a * 1000) / 1000 : "") +
      ")"
    );
  else
    return (
      "#" +
      (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
        .toString(16)
        .slice(1, f ? undefined : -2)
    );
};

pSBC.pSBCr = (d) => {
  const i = parseInt;
  let n = d.length,
    x = {};
  if (n > 9) {
    const [r, g, b, a] = (d = d.split(","));
    n = d.length;
    if (n < 3 || n > 4) return null;
    // eslint-disable-next-line no-unused-expressions
    (x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4))),
      (x.g = i(g)),
      (x.b = i(b)),
      (x.a = a ? parseFloat(a) : -1);
  } else {
    if (n == 8 || n == 6 || n < 4) return null;
    if (n < 6)
      d =
        "#" +
        d[1] +
        d[1] +
        d[2] +
        d[2] +
        d[3] +
        d[3] +
        (n > 4 ? d[4] + d[4] : "");
    d = i(d.slice(1), 16);
    if (n == 9 || n == 5)
      // eslint-disable-next-line no-unused-expressions
      (x.r = (d >> 24) & 255),
        (x.g = (d >> 16) & 255),
        (x.b = (d >> 8) & 255),
        (x.a = Math.round((d & 255) / 0.255) / 1000);
    // eslint-disable-next-line no-unused-expressions
    else (x.r = d >> 16), (x.g = (d >> 8) & 255), (x.b = d & 255), (x.a = -1);
  }
  return x;
};
export { pSBC };

export const urlSafeString = (options) => {
  const _opts = {
    maxLen: 100, // truncates beyond maxLen
    lowercaseOnly: true,
    regexRemovePattern: /((?!([a-z0-9])).)/gi, // matches opposite of [a-z0-9]
    joinString: "-", // e.g. - may be: '-', '_', '#'
    trimWhitespace: true,
  };

  // Vanilla shallow merge to avoid dependencies
  if (options)
    for (const prop in options)
      if (Object.prototype.hasOwnProperty.call(options, prop))
        _opts[prop] = options[prop];

  return {
    generate: function generate() {
      const reJoinString = new RegExp(_opts.joinString + "+", "g"),
        args = Array.prototype.slice.call(arguments);

      let tag;

      if (!args || args.length === 0)
        throw new Error("generate method must be passed at least one argument");

      // Validate, trim all arguments:
      for (let i = 0; i < args.length; i++) {
        if (typeof args[i] !== "string")
          throw new Error("all supplied arguments must be Strings");

        if (_opts.trimWhitespace) args[i] = args[i].trim();
      }

      // Join strings and convert whitespace between words to join string
      tag = args.join(_opts.joinString);
      tag = tag.replace(/\s/g, _opts.joinString);
      if (_opts.lowercaseOnly) tag = tag.toLowerCase();
      // Regex away anything "unsafe", but ignore the join string!
      tag = tag.replace(_opts.regexRemovePattern, function (match) {
        if (match === _opts.joinString) return match;

        return "";
      });

      // Truncate in excess of maxLen
      if (tag.length > _opts.maxLen) tag = tag.substring(0, _opts.maxLen);

      // Remove any duplicates of the join string using this pattern: /<join string>+/g
      tag = tag.replace(reJoinString, _opts.joinString);

      return tag;
    },
  };
};

export const minutesToHoursOrDays = (minutes) => {
  if (minutes < 60) {
    return minutes + " minutos";
  } else if (minutes < 1440) {
    const hours = Math.floor(minutes / 60);
    return hours === 1 ? "1 hora" : hours + " horas";
  } else {
    const days = Math.floor(minutes / 1440);
    return days === 1 ? "1 dia" : days + " dias";
  }
};
