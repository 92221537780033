import React from "react";
import {
  styled,
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MSelect,
  IconButton,
} from "@mui/material";
import { Tooltip } from "../index";
import { HelpOutline } from "@mui/icons-material";

const StyledFormControl = styled(FormControl)`
  margin-top: ${({ label }) => (!!label ? "2rem" : "0rem")};

  & legend {
    width: 0;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  & .MuiFormLabel-root {
    margin: -1rem 0 0 4px;
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme, size = "normal" }) =>
    theme.fonts.size.inputs[size].label};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.grey._300};
    transform: translateY(-0.75rem) scale(1);
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.grey._800};
  }
  & .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.colors.error._500};
  }

  & .MuiInputBase-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme, size = "normal" }) =>
    theme.fonts.size.inputs[size].content};
    color: ${({ theme }) => theme.colors.grey._900};
    font-weight: 400;
    background-color: ${({ theme }) => theme.colors.grey._50};
    border-radius: ${({ theme }) => theme.borderRadiuses.inputs};
  }
  & .MuiInputBase-root.Mui-error {
    color: ${({ theme }) => theme.colors.error._500};
    background-color: ${({ theme }) => theme.colors.error._50};
  }

  & .MuiFormHelperText-root {
    margin: 4px 0 0 4px;
  }
  & .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.colors.error._500};
  }

  & .MuiInputAdornment-root .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.grey._900};
  }
  & .MuiInputBase-root.Mui-error .MuiInputAdornment-root .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.error._500};
  }
`;

const HelpButton = styled(IconButton)`
  padding: 0;
  margin: -3px 0 0 4px;
  color: ${({ theme }) => theme.colors.grey._300};
  & .MuiSvgIcon-root {
    font-size: ${({ theme }) => theme.fonts.size.default};
  }
`;

const Select = ({
  id,
  name,
  variant,
  value,
  fullWidth = true,
  margin = "none",
  disabled,
  label,
  tooltip,
  helperText,
  MenuProps,
  children,
  ...otherValues
}) => {
  if (!!tooltip) {
    label = (
      <>
        {label}
        <Tooltip arrow title={tooltip}>
          <HelpButton>
            <HelpOutline />
          </HelpButton>
        </Tooltip>
      </>
    );
  }
  return (
    <StyledFormControl label={label} margin={margin} fullWidth={fullWidth} disabled={disabled}>
      {!!label && (
        <InputLabel htmlFor={name} shrink={true} error={otherValues.error}>
          {label}
        </InputLabel>
      )}
      <MSelect
        {...otherValues}
        label={label}
        margin={margin}
        variant={variant}
        MenuProps={MenuProps}
        inputProps={{
          name: name,
          id: id,
          value: value,
          disabled: disabled,
        }}
      >
        {children}
      </MSelect>
      {Boolean(helperText) && (
        <FormHelperText error={Boolean(otherValues.error)}>
          {helperText}
        </FormHelperText>
      )}
    </StyledFormControl>
  )
};

export default Select;
