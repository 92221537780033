import { actions, types as authTypes } from "../actions/autenticacao.actions";
import { types as routes } from "../actions/rotas.actions";

const initialState = {
  login: {
    submitting: false,
    codigo: null,
  },
  "esqueci-senha": {
    submitting: false,
  },
  "trocar-senha": {
    token: null,
    loading: false,
    valid: false,
    submitting: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case routes.LOGIN:
      return {
        ...initialState
      }
    case authTypes.LOGOUT:
      return {
        ...initialState,
        login: {
          ...initialState.login,
          codigo: state.login.codigo
        }
      };
    case actions.login.REQUEST:
    case actions.login.SUCCESS:
    case actions.login.FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          submitting: action.type === actions.login.REQUEST,
        },
      };
    case actions.loginExterno.REQUEST:
    case actions.loginExterno.SUCCESS:
    case actions.loginExterno.FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          submitting: action.type === actions.loginExterno.REQUEST,
        },
      };
    case actions.obterOperacoesDoUsuario.SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          submitting: false,
          codigo: action.payload.original
        }
      }

    case actions.esqueciSenha.REQUEST:
    case actions.esqueciSenha.SUCCESS:
    case actions.esqueciSenha.FAILURE:
      return {
        ...state,
        "esqueci-senha": {
          ...state["esqueci-senha"],
          submitting: action.type === actions.esqueciSenha.REQUEST,
        },
      };
    case actions.trocarSenha.REQUEST:
    case actions.trocarSenha.SUCCESS:
    case actions.trocarSenha.FAILURE:
      return {
        ...state,
        "trocar-senha": {
          ...state["trocar-senha"],
          submitting: action.type === actions.trocarSenha.REQUEST,
        },
      };
    case actions.validarTokenSenha.REQUEST:
    case actions.validarTokenSenha.SUCCESS:
    case actions.validarTokenSenha.FAILURE:
      return {
        ...state,
        "trocar-senha": {
          ...state["trocar-senha"],
          loading: action.type === actions.validarTokenSenha.REQUEST,
          valid: action.type === actions.validarTokenSenha.SUCCESS,
        },
      };
    case routes.TROCAR_SENHA:
    case routes.PRIMEIRO_ACESSO_PARCEIRO:
      return {
        ...state,
        "trocar-senha": {
          ...state["trocar-senha"],
          token: action.payload.token,
        },
      };
    default:
      return state;
  }
};

export default reducer;
