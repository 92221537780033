import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isBuscandoPreenchimentoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../../hooks/useCompleteForm";
import validators from "../../../../../../utils/validators";
import { actions } from "../../../../../../actions/operacao.actions";
import {
  removeNonDigitsFromString,
  transformToDate,
} from "../../../../../../utils/basic";
import formatters from "../../../../../../utils/formatters";
import DadosBasicosSociosSantanderForm from "./DadosBasicosSociosSantanderForm";

const DadosBasicosSociosSantanderFormConnected = ({
  setStep,
  eventsSociosPf,
  eventsSociosPj,
  participante,
  setParticipante,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: {
      tipoEmpresa: tiposEmpresa,
      nacionalidade: nacionalidades,
      profissao: profissoes,
      documentoPessoal: documentosPessoais,
    },
  } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);
  const searching = useSelector(isBuscandoPreenchimentoOperacao);
  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find(
        (p) => p.id === participante.id
      );
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      setStep(61);
    },
    [setStep, setParticipante, participante]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      cpfCnpj: validators.cpfCnpj({
        required: false,
        custom: {
          obrigatorio: (value) => {
            const c = operacao.participantes.find(
              (p) => p.id === participante.id
            );
            if (!!c.cpfCnpj) return true;
            if (value !== undefined && value !== null && value !== "") {
              return true;
            }
            return "Campo obrigatório";
          },
        },
      }),
      nome: validators.string({
        required: true,
        maxLength: 100,
        custom: {
          nomeCompleto: (value) =>
            !value ||
            (value.indexOf(" ") > 0 &&
              value.substring(value.indexOf(" ") + 1).length > 0) ||
            "Nome informado deve ser completo",
        },
      }),
      dataNascimento: validators.date({
        required: participante.tipoPessoa === "F",
        min: "01/01/1900",
        max: formatters.dates.short(new Date()),
      }),
      email: validators.email({ required: true }),
      telefone: validators.phone({ required: true }),
      tipoEmpresa: validators.object({
        required: participante.tipoPessoa === "J",
      }),
      nacionalidade: validators.object({ required: true }),
      documentoPessoal: validators.object({ required: true }),
      numeroDocumentoPessoal: validators.string({
        required: true,
        maxLength: 100,
      }),
      orgaoDocumentoPessoal: validators.string({
        required: true,
        maxLength: 100,
      }),
      ufOrgaoDocumentoPessoal: validators.string({
        required: true,
      }),
      dataExpedicaoDocumentoPessoal: validators.date({
        required: true,
        min: "01/01/1900",
        max: formatters.dates.short(new Date()),
      }),
      dadosProfissionais: {
        cargo: validators.string({
          required: true,
        }),
      },
    }),
    initialValues: useCallback(
      () => ({
        cpfCnpj: "",
        nome: "",
        dataNascimento: "",
        email: "",
        telefone: "",
        tipoEmpresa: null,
        numeroDocumentoPessoal: "",
        orgaoDocumentoPessoal: "",
        ufOrgaoDocumentoPessoal: "",
        dataExpedicaoDocumentoPessoal: "",
        nacionalidade: null,
        dadosProfissionais: {
          cargo: null,
        },
        ...participante,
      }),
      [participante]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-pessoais-socios-vendedor-pj",
            participante: {
              id: participante?.id,
              cpfCnpj: removeNonDigitsFromString(values.cpfCnpj),
              nomeParticipante: values.nome,
              dataNascimento:
                participante.tipoPessoa === "F"
                  ? transformToDate(values.dataNascimento)
                  : null,
              email: values.email,
              telefone: values.telefone,
              idTipoEmpresa:
                participante.tipoPessoa === "J" ? values.tipoEmpresa?.id : null,
              idNacionalidade: values.nacionalidade?.id,
              idDocumentoPessoal: values.documentoPessoal?.id,
              numeroDocumentoPessoal: values.numeroDocumentoPessoal,
              orgaoDocumentoPessoal: values.orgaoDocumentoPessoal,
              ufOrgaoDocumentoPessoal: values.documentoPessoal?.descricao === "RNE" ? null : values.ufOrgaoDocumentoPessoal,
              dataExpedicaoDocumentoPessoal: transformToDate(
                values.dataExpedicaoDocumentoPessoal
              ),
              dadosProfissionais: {
                cargo: values.dadosProfissionais.cargo,
              },
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, participante, operacao, callback]
    ),
  });

  const { reset } = formProps;

  const successCallback = useCallback(
    (data) => {
      if (!!data) {
        const c = data.operacao.participantes.find(
          (p) => p.id === participante.id
        );
        if (!!c) {
          setParticipante({
            ...c,
            fluxo: data.validacao.completa.participantes.find(
              (f) => f.id === c?.id
            )?.etapas,
          });
          reset({
            cpfCnpj: "",
            nome: "",
            dataNascimento: "",
            email: "",
            telefone: "",
            tipoEmpresa: null,
            numeroDocumentoPessoal: "",
            orgaoDocumentoPessoal: "",
            ufOrgaoDocumentoPessoal: "",
            dataExpedicaoDocumentoPessoal: "",
            nacionalidade: null,
            documentoPessoal: null,
            dadosProfissionais: {
              cargo: null,
            },
            ...c,
          });
        }
      }
    },
    [setParticipante, participante, reset]
  );

  const handleCpfCnpjChange = useCallback(
    (v) => {
      if (!!v) {
        dispatch(
          actions.atualizarParticipantePreenchimento.request({
            id: participante.id,
            cpfCnpj: removeNonDigitsFromString(v),
            tipo: "preenchimento",
            callback: successCallback,
          })
        );
      }
    },
    [dispatch, participante, successCallback]
  );

  const isEstrangeiro = formProps.watch("documentoPessoal")?.id === 4;

  return (
    <DadosBasicosSociosSantanderForm
      {...otherProps}
      tipoPessoa={participante.tipoPessoa}
      tiposEmpresa={tiposEmpresa}
      profissoes={profissoes}
      documentosPessoais={documentosPessoais}
      nacionalidades={nacionalidades}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={participante.fluxo}
      handleCpfCnpjChange={handleCpfCnpjChange}
      searching={searching}
      events={participante.tipoPessoa === "F" ? eventsSociosPf : eventsSociosPj}
      isEstrangeiro={isEstrangeiro}
    />
  );
};

export default DadosBasicosSociosSantanderFormConnected;
