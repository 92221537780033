import React from "react";
import DadosBasicosCompradorC6FormConnected from "./comprador/DadosBasicosCompradorC6Form.connected";
import HubC6Connected from "./HubC6.connected";
import DadosEnderecoImovelC6FormConnected from "./imovel/DadosEnderecoImovelC6Form.connected";
import OutrasInformacoesImovelC6FormConnected from "./imovel/OutrasInformacoesImovelC6Form.connected";
import DadosProfissionaisC6FormConnected from "./comprador/DadosProfissionaisC6Form.connected";
import DadosEstadoCivilC6FormConnected from "./comprador/DadosEstadoCivilC6Form.connected";
import ReenquadrarOperacaoFormConnected from "../ReenquadrarOperacaoForm.connected";
import ConfirmarNovoCalculoConnected from "../ConfirmarNovoCalculo.connected";

const FluxoC6 = (props) => {
  return (
    <>
      {props.step === 0 && <HubC6Connected {...props} />}
      {props.step === 1 && <DadosBasicosCompradorC6FormConnected {...props} />}
      {props.step === 2 && <DadosProfissionaisC6FormConnected {...props} />}
      {props.step === 3 && <DadosEstadoCivilC6FormConnected {...props} />}

      {props.step === 100 && <DadosEnderecoImovelC6FormConnected {...props} />}
      {props.step === 101 && (
        <OutrasInformacoesImovelC6FormConnected {...props} />
      )}
      {props.step === 995 && <ReenquadrarOperacaoFormConnected {...props} />}
      {props.step === 996 && <ConfirmarNovoCalculoConnected {...props} />}
    </>
  );
};

export default FluxoC6;
