import ListaClientesPage from "./ListaClientesPage";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {
  getConteudoClientes,
  getDominioInstituicoesFinanceiras,
  getDominioParceiros,
  getDominioProdutos,
  getListaPaging,
  getResumo,
  isCarregandoClientes,
  isCarregandoResumo,
} from "../../selectors/clientes.selectors";
import { actions } from "../../actions/clientes.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";

const ListaClientesPageConnected = () => {
  const dispatch = useDispatch();
  const loadingResumo = useSelector(isCarregandoResumo);
  const resumo = useSelector(getResumo);
  const paging = useSelector(getListaPaging);
  const { data: parceiros } = useSelector(getDominioParceiros);
  const { data: produtos } = useSelector(getDominioProdutos);
  const { data: instituicoesFinanceiras } = useSelector(
    getDominioInstituicoesFinanceiras
  );

  const loading = useSelector(isCarregandoClientes);
  const data = useSelector(getConteudoClientes);
  const [filters, setFilters] = useState({
    dirty: false,
    resumo: false,
    etapa: "total",
    page: 0,
    pesquisar: "",
    produto: "",
    parceiro: null,
    imovelDefinido: null,
    instituicaoFinanceira: "",
  });

  const handleClienteClick = useCallback(
    (id) => {
      dispatch(routeActions.redirectTo(routes.DETALHES_CLIENTE, { id }));
    },
    [dispatch]
  );
  const handleOperacaoClick = useCallback(
    (id) => {
      dispatch(routeActions.redirectTo(routes.DETALHES_OPERACAO, { id }));
    },
    [dispatch]
  );

  const handlePaginationClick = useCallback(
    (page) => {
      setFilters({ ...filters, page, dirty: true, resumo: false });
    },
    [filters, setFilters]
  );

  useEffect(() => {
    if (filters.dirty) {
      dispatch(
        actions.listarClientes.request({
          pagina: filters.page,
          etapa: filters.etapa,
          pesquisa: filters.pesquisar,
          parceiro: filters.parceiro?.id,
          produto: filters.produto?.id,
          imovelDefinido: filters.imovelDefinido,
          instituicaoFinanceira: filters.instituicaoFinanceira?.id,
        })
      );

      if (!!filters.resumo) {
        dispatch(
          actions.carregarResumo.request({
            pesquisa: filters.pesquisar,
            parceiro: filters.parceiro?.id,
            produto: filters.produto?.id,
            imovelDefinido: filters.imovelDefinido,
            instituicaoFinanceira: filters.instituicaoFinanceira?.id,
          })
        );
      }
    }
  }, [dispatch, filters]);

  return (
    <ListaClientesPage
      loading={loading}
      loadingResumo={loadingResumo}
      resumo={resumo}
      filters={filters}
      setFilters={setFilters}
      data={data}
      exibeParceiro={true}
      handlePaginationClick={handlePaginationClick}
      handleClienteClick={handleClienteClick}
      handleOperacaoClick={handleOperacaoClick}
      page={filters.page}
      paging={paging}
      parceiros={parceiros}
      produtos={produtos}
      instituicoesFinanceiras={instituicoesFinanceiras}
    />
  );
};

export default ListaClientesPageConnected;
