import React, { useCallback, useEffect } from "react";
import InformarMotivoAcaoDialog from "./InformarMotivoAcaoDialog";
import { useDispatch, useSelector } from "react-redux";
import validators from "../../utils/validators";
import useCompleteForm from "../../hooks/useCompleteForm";
import { isAtuandoFluxoTrabalho } from "../../selectors/workflow.selectors";

const InformarMotivoAcaoDialogConnected = ({
  handleClose,
  dados: { open, action, initial, ...otherData },
}) => {
  const dispatch = useDispatch();
  const submitting = useSelector(isAtuandoFluxoTrabalho);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      motivo: validators.string({
        required: true,
      }),
    }),
    initialValues: () => ({
      motivo: initial,
    }),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          action({
            ...otherData,
            ...values,
            handleClose,
          })
        );
      },
      [dispatch, otherData, action, handleClose]
    ),
  });

  const { setValue } = formProps;

  useEffect(() => {
    setValue("motivo", initial);
  }, [initial, setValue]);

  return (
    <InformarMotivoAcaoDialog
      handleSubmit={handleSubmit}
      open={open}
      dados={otherData}
      formProps={formProps}
      handleClose={handleClose}
      submitting={submitting}
    />
  );
};

export default InformarMotivoAcaoDialogConnected;
