import React, { useCallback, useEffect } from "react";
import DadosDpsDefaultForm from "./DadosDpsDefaultForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";

const avaliarCondicao = (questao, values) => {
  if (!questao.condicao) {
    return true;
  }

  // eslint-disable-next-line no-new-func
  const check = new Function("questao", "values", questao.condicao);
  return check(questao, values);
};

const DadosDpsDefaultFormConnected = ({
  handleGoTo0,
  events,
  participante: comprador,
  setParticipante,
  makeGoTo,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { operacao } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);
  const questoes = comprador.dps?.questoes;

  const [visible, setVisible] = React.useState([]);

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      const next = operacao.tipoProduto === "H" ? makeGoTo(0) : makeGoTo(7);
      next();
    },
    [setParticipante, comprador, operacao, makeGoTo]
  );

  const isCompleta = useCallback(
    (values) => {
      let result = true;

      const questoesVisiveis = questoes.filter(
        (q, i) => visible[i] && ["TI", "ST", "DS"].indexOf(q.tipoCampo) === -1
      );

      for (const q of questoesVisiveis) {
        result &= !!values[q.codigo];
      }

      return result;
    },
    [questoes, visible]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      pisPasep: validators.number({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        ...comprador.dps.questoes.reduce(
          (r, q) => ({
            ...r,
            [q.codigo]: q.resposta ?? q.valorDefault ?? null,
          }),
          {}
        ),
      }),
      [comprador]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-dps",
            participante: {
              id: comprador?.id,
              dps: {
                id: comprador?.dps?.id,
                situacao: isCompleta(values) ? "FINALIZADA" : "PENDENTE",
                respostas: comprador?.dps?.questoes.map((q, i) => ({
                  idFormularioItem: q.id,
                  resposta: visible[i] ? values[q.codigo] : null,
                })),
              },
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, comprador, operacao, callback, isCompleta, visible]
    ),
  });

  const currentIndex =
    operacao.tipoProduto === "H" ? events.length - 1 : events.length - 2;
  const handleGoBack =
    operacao.tipoProduto === "H"
      ? makeGoTo(events.length - 2)
      : makeGoTo(events.length - 3);

  const updateVisible = useCallback(
    (values) => {
      setVisible(questoes.map((q) => avaliarCondicao(q, values)));
    },
    [questoes, setVisible]
  );

  useEffect(() => {
    updateVisible(formProps.getValues());
  }, [updateVisible, formProps]);

  return (
    <DadosDpsDefaultForm
      {...otherProps}
      formProps={formProps}
      values={formProps.getValues()}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={comprador.fluxo}
      events={events}
      questoes={questoes}
      currentIndex={currentIndex}
      handleGoBack={handleGoBack}
      visible={visible}
      updateVisible={updateVisible}
    />
  );
};

export default DadosDpsDefaultFormConnected;
