import React from "react";
import { NumberFormatBase } from "react-number-format";
import { removeNonDigitsFromString } from "../../utils/basic";

const formatAccount = (value) => {
	const stripped = removeNonDigitsFromString(value);
	const size = !!stripped ? stripped.length : 0;

	if (size > 1) {
		return stripped.slice(0, size - 1) + "-" + stripped.slice(size - 1);
	} else {
		return stripped;
	}
};

const AccountTextField = React.forwardRef(({ onChange, ...other }, ref) => {
	return (
		<NumberFormatBase
			{...other}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						value: values.formattedValue,
					},
				});
			}}
			format={formatAccount}
		/>
	);
});

export default AccountTextField;
