import { Grid } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  GoBack,
  MenuItem,
  Title,
} from "../../../../../components";
import ControlledAutocomplete from "../../../../../components/inputs/ControlledAutocomplete";
import {
  ControlledSwitch,
  ControlledTextField,
  DateTextField,
} from "../../../../../components/inputs";
import Stepper from "../../../preenchimento/Stepper";
import UpperCaseTextField from "../../../../../components/inputs/UpperCaseTextField";
import { estados } from "../../../../../constants/generic.constants";

const DadosProfissionaisCompradorItauForm = ({
  perfisRenda,
  profissoes,
  fluxo,
  events,
  formProps,
  handleSubmit,
  submitting,
  handleGoTo2,
  isEmpresarioAutonomoOuProdutorRural,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} direction={"column"} maxWidth={704}>
        <GoBack
          handleClick={handleGoTo2}
          text={"Preencha as informações do comprador"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={2} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Dados Profissionais</Title>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <ControlledAutocomplete
                      label="Perfil de Renda"
                      name={"perfilRenda"}
                      domain={perfisRenda}
                      idField={"id"}
                      labelField={"descricao"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <ControlledTextField
                      label="Cargo ou Profissão"
                      name={"dadosProfissionais.cargo"}
                      inputProps={{ maxLength: 100 }}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: UpperCaseTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ControlledTextField
                      label="Nome da Empresa"
                      name={"dadosProfissionais.nomeEmpresa"}
                      inputProps={{ maxLength: 100 }}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: UpperCaseTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label={"Data de Início"}
                      name={"dadosProfissionais.dataAdmissao"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: DateTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ControlledTextField
                      label={"Município"}
                      tooltip={
                        "Município onde você exerce sua ocupação principal"
                      }
                      name={"dadosProfissionais.municipio"}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label={"UF"}
                      tooltip={"UF onde você exerce sua ocupação principal"}
                      name={"dadosProfissionais.uf"}
                      select
                      formProps={formProps}
                    >
                      {estados.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </ControlledTextField>
                  </Grid>
                  {isEmpresarioAutonomoOuProdutorRural && (
                    <Grid item xs={12}>
                      <ControlledSwitch
                        name={"dadosProfissionais.possuiFuncionarios"}
                        label={"Possui funcionários registrados?"}
                        formProps={formProps}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    justifyContent={"center"}
                    mt={1}
                    spacing={2}
                  >
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosProfissionaisCompradorItauForm;
