import React, { useCallback } from "react";
import { NumberFormatBase } from "react-number-format";
import { removeNonDigitsFromString } from "../../utils/basic";

const PisPasepTextField = React.forwardRef(
	({ onChange, ...other }, ref) => {
		const formatPisPasep = (value) => {
			const removeNonDigitsFromString = (str) => str.replace(/\D/g, '');
			const stripped = removeNonDigitsFromString(value);
			const size = stripped.length;

			if (size === 0) return "";
			let finalValue = stripped.slice(0, 3);
			if (size > 3) {
				finalValue += "." + stripped.slice(3, 8);
			}
			if (size > 8) {
				finalValue += "." + stripped.slice(8, 10);
			}
			if (size > 10) {
				finalValue += "-" + stripped.slice(10);
			}

			return finalValue;
		};


		return (
			<NumberFormatBase
				{...other}
				getInputRef={ref}
				maxLength={13}
				onValueChange={(values) => {
					onChange({
						target: {
							value: values.formattedValue,
						},
					});
				}}
				format={formatPisPasep}
			/>
		);
	}
);

export default PisPasepTextField;
