import { Grid, styled } from "@mui/material";
import { useWindowSize } from "../hooks/useWindowSize";
import { transientOptions } from "../styles/theme";

const Base = styled("div")`
  background-color: ${({ theme }) => theme.colors.fixed.offWhite};
`;
const FullHeightContainer = styled(Grid)`
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 100%;
`;
const Container = styled(Grid, transientOptions)`
  min-height: ${({ _minHeight_ }) => _minHeight_}px;
`;
const ContentGrid = styled(Grid)`
  padding: 1.5rem 0;
`;
const CoverPhotoGrid = styled(Grid, transientOptions)`
  min-height: ${({ _fullHeight_ }) => _fullHeight_}px;
  background-image: url("${({ _photo_ }) => _photo_}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
`;

const SplitPanePage = ({ photo, children, md = 6, lg = 4, xl = 3 }) => {
  const [innerWidth, innerHeight] = useWindowSize();
  return (
    <Base>
      <FullHeightContainer>
        <Container _minHeight_={innerHeight} container>
          <CoverPhotoGrid
            item
            _photo_={photo}
            xs={false}
            md={12 - md}
            lg={12 - lg}
            xl={12 - xl}
            _fullHeight_={innerHeight}
            _fullWidth_={innerWidth}
          />
          <ContentGrid
            item
            xs={12}
            md={md}
            lg={lg}
            xl={xl}
            display={"flex"}
            alignItems={"center"}
          >
            <Grid container>
              <Grid item xs={12}>
                {children}
              </Grid>
            </Grid>
          </ContentGrid>
        </Container>
      </FullHeightContainer>
    </Base>
  );
};

export default SplitPanePage;
