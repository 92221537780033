import { Grid } from "@mui/material";
import React from "react";
import { Button, Card, Form, GoBack } from "../../../../../components";
import ControlledAutocomplete from "../../../../../components/inputs/ControlledAutocomplete";
import {
  ControlledSwitch,
  ControlledTextField,
  CpfCnpjTextField,
  DateTextField,
  PercentTextField,
  PhoneTextField,
} from "../../../../../components/inputs";
import Stepper from "../../../preenchimento/Stepper";

const DadosEstadoCivilSociosFintechsForm = ({
  estadosCivis,
  qualificacoes,
  regimesComunhao,
  events,
  fluxo,
  formProps,
  handleEstadoCivilChange,
  handleSubmit,
  exibeConjuge,
  exibeUniaoEstavel,
  handleUniaoEstavelChange,
  submitting,
  makeGoTo,
}) => {
  const compoeQuadroSocietario = formProps.watch(
    "conjuge.compoeQuadroSocietario"
  );

  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} direction={"column"} maxWidth={704}>
        <GoBack
          handleClick={makeGoTo(51)}
          text={"Preencha as informações do proponente"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={3} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item width={1}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item container spacing={2}>
                    <Grid item xs={12} md={5}>
                      <ControlledAutocomplete
                        label="Estado Civil"
                        name={"estadoCivil"}
                        domain={estadosCivis}
                        idField={"id"}
                        labelField={"descricao"}
                        formProps={formProps}
                        autocompleteProps={{
                          clearText: "Limpar",
                          onChange: handleEstadoCivilChange,
                          loadingText: "Carregando opções",
                          noOptionsText: "Nenhuma opção disponível",
                        }}
                      />
                    </Grid>
                    {!!exibeUniaoEstavel && (
                      <Grid item xs={12} md={7}>
                        <ControlledSwitch
                          label="Convive em união estável?"
                          name={"uniaoEstavel"}
                          onChange={handleUniaoEstavelChange}
                          formProps={formProps}
                        />
                      </Grid>
                    )}
                    {!!exibeConjuge && (
                      <Grid item xs={12} md={8}>
                        <ControlledAutocomplete
                          label="Regime de Comunhão"
                          name={"regimeComunhao"}
                          domain={regimesComunhao}
                          idField={"id"}
                          labelField={"descricao"}
                          formProps={formProps}
                          autocompleteProps={{
                            clearText: "Limpar",
                            loadingText: "Carregando opções",
                            noOptionsText: "Nenhuma opção disponível",
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {!!exibeConjuge && (
                    <>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label={"CPF do Cônjuge"}
                          name={"conjuge.cpfCnpj"}
                          formProps={formProps}
                          type={"cpf"}
                          InputProps={{
                            inputComponent: CpfCnpjTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <ControlledTextField
                          label="Nome Completo do Cônjuge"
                          name={"conjuge.nome"}
                          inputProps={{ maxLength: 100 }}
                          formProps={formProps}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label={"Data de Nascimento"}
                          name={"conjuge.dataNascimento"}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: DateTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <ControlledTextField
                          label={"E-mail"}
                          name={"conjuge.email"}
                          formProps={formProps}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label="Celular"
                          name="conjuge.telefone"
                          formProps={formProps}
                          InputProps={{
                            inputComponent: PhoneTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <ControlledAutocomplete
                          label="Tipo de Proponente"
                          name={"conjuge.qualificacao"}
                          domain={qualificacoes}
                          idField={"id"}
                          labelField={"descricao"}
                          formProps={formProps}
                          autocompleteProps={{
                            clearText: "Limpar",
                            loadingText: "Carregando opções",
                            noOptionsText: "Nenhuma opção disponível",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ControlledSwitch
                          label="Compoe Quadro Societário?"
                          name="conjuge.compoeQuadroSocietario"
                          formProps={formProps}
                        />
                      </Grid>
                      {!!compoeQuadroSocietario && (
                        <Grid item xs={12} md={4}>
                          <ControlledTextField
                            name={"conjuge.participacaoSocietaria"}
                            label={"Participação Societária"}
                            formProps={formProps}
                            InputProps={{
                              inputComponent: PercentTextField,
                            }}
                            inputProps={{
                              decimalScale: 0,
                              maxLength: 5,
                              allowNegative: false,
                            }}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosEstadoCivilSociosFintechsForm;
