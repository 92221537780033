import { useForm } from "react-hook-form";

export default function useCompleteForm({
  rules,
  initialValues,
  handleSubmitWithForm,
  handleSubmit,
  handleInvalid,
}) {
  const form = useForm({
    defaultValues: initialValues(),
  });

  const formProps = {
    ...form,
    rules: rules(form),
  };

  const handleSubmitResult = !!handleSubmitWithForm
    ? handleSubmitWithForm(formProps)
    : handleSubmit;

  return [formProps, formProps.handleSubmit(handleSubmitResult, handleInvalid)];
}
