import React from "react";
import { IconButton, styled, TextField as MTextField } from "@mui/material";
import { transientOptions } from "../../styles/theme";
import { HelpOutline } from "@mui/icons-material";
import { Tooltip } from "../index";

const StyledTextField = styled(MTextField, transientOptions)`
  margin-top: ${({ label }) => (!!label ? "2rem" : "0")};

  &:has(input[readonly]) {
    & .MuiInputBase-input {
      opacity: 1;
      color: rgba(0, 0, 0, 0.38);
    }
  }

  & legend {
    width: 0;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  & .MuiFormLabel-root {
    margin: -1rem 0 -5px 4px;
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme, size = "normal" }) =>
      theme.fonts.size.inputs[size].label};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.grey._300};
    transform: translateY(-0.75rem) scale(1);
    white-space: unset;
    position: relative;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.grey._800};
  }
  & .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.colors.error._500};
  }

  & .MuiInputBase-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme, size = "normal" }) =>
      theme.fonts.size.inputs[size].content};
    color: ${({ theme }) => theme.colors.grey._900};
    font-weight: 400;
    background-color: ${({ theme }) => theme.colors.grey._50};
    border-radius: ${({ theme }) => theme.borderRadiuses.inputs};
  }
  & .MuiInputBase-root.Mui-error {
    color: ${({ theme }) => theme.colors.error._500};
    background-color: ${({ theme }) => theme.colors.error._50};
  }

  & .MuiFormHelperText-root {
    margin: 4px 0 0 4px;
  }
  & .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.colors.error._500};
  }

  & .MuiInputAdornment-root .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.grey._900};
  }
  & .MuiInputBase-root.Mui-error .MuiInputAdornment-root .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.error._500};
  }
`;

const HelpButton = styled(IconButton)`
  padding: 0;
  margin: -3px 0 0 4px;
  color: ${({ theme }) => theme.colors.grey._300};
  & .MuiSvgIcon-root {
    font-size: ${({ theme }) => theme.fonts.size.default};
  }
`;

const TextField = ({
  shrinked = true,
  fullWidth = true,
  label,
  tooltip,
  InputLabelProps,
  ...other
}) => {
  if (!!tooltip) {
    label = (
      <>
        {label}
        <Tooltip arrow title={tooltip} tabIndex={-1}>
          <HelpButton>
            <HelpOutline />
          </HelpButton>
        </Tooltip>
      </>
    );
  }

  return (
    <StyledTextField
      InputLabelProps={{
        shrink: shrinked,
        ...InputLabelProps,
      }}
      fullWidth={fullWidth}
      label={label}
      {...other}
    />
  );
};

export default TextField;
