import React, { useCallback } from "react";
import ComentariosCard from "./ComentariosCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getComentarios,
  getFluxoTrabalho,
} from "../../selectors/workflow.selectors";
import useCompleteForm from "../../hooks/useCompleteForm";
import validators from "../../utils/validators";
import { actions } from "../../actions/workflow.actions";
import { getUsuarioLogado } from "../../selectors/usuario.selectors";

const ComentariosCardConnected = () => {
  const dispatch = useDispatch();
  const { loading, data, submitting } = useSelector(getComentarios);
  const fluxoTrabalho = useSelector(getFluxoTrabalho);
  const usuario = useSelector(getUsuarioLogado);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: useCallback(
      () => ({
        comentario: validators.string({ required: true }),
      }),
      []
    ),
    initialValues: useCallback(
      () => ({
        comentario: "",
        confidencial: false,
      }),
      []
    ),
    handleSubmitWithForm: useCallback(
      (form) => {
        return (values) => {
          dispatch(
            actions.gravarComentario.request({
              ...values,
              callback: form.setValue("comentario", ""),
            })
          );
        };
      },
      [dispatch]
    ),
  });

  const handleEditarComentario = useCallback(
    (comentario) => {
      dispatch(actions.exibirEditarComentario(true, comentario));
    },
    [dispatch]
  );

  const handleExcluirComentario = useCallback(
    (comentario) => {
      dispatch(actions.excluirComentario.request(comentario.id));
    },
    [dispatch]
  );

  const handleDestacar = useCallback(
    (comentario) => {
      dispatch(actions.destacarComentario.request(comentario));
    },
    [dispatch]
  );

  return (
    <ComentariosCard
      loading={loading}
      comentarios={data}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      handleEditarComentario={handleEditarComentario}
      handleExcluirComentario={handleExcluirComentario}
      handleDestacar={handleDestacar}
      fluxoTrabalho={fluxoTrabalho}
      usuario={usuario}
    />
  );
};

export default ComentariosCardConnected;
