import { useSelector } from "react-redux";
import Favicon from "react-favicon";
import favIconDefault from "../assets/favicon.png";
import { getTema } from "../selectors/tema.selectors";

const FavIconConnected = () => {
  const { urlLogo } = useSelector(getTema);

  return <Favicon url={[urlLogo ?? favIconDefault]} />;
};

export default FavIconConnected;
