import React, { useCallback, useEffect } from "react";
import DadosBasicosVendedorPjItauForm from "./DadosBasicosVendedorPjItauForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import { removeNonDigitsFromString, transformToDate } from "../../../../../utils/basic";

const DadosBasicosVendedorPjItauFormConnected = ({
  handleGoTo21,
  eventsVendedorPj,
  participante: vendedor,
  setParticipante,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { tipoEmpresa: tiposEmpresa },
  } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === vendedor.id);
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      handleGoTo21();
    },
    [handleGoTo21, setParticipante, vendedor]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      cpfCnpj: validators.cnpj({ required: true }),
      nome: validators.string({
        required: true,
        maxLength: 100,
        custom: {
          nomeCompleto: (value) =>
            !value ||
            (value.indexOf(" ") > 0 &&
              value.substring(value.indexOf(" ") + 1).length > 0) ||
            "Razão social informada deve ser completa",
        },
      }),
      email: validators.email({ required: true }),
      tipoEmpresa: validators.object({ required: false }),
      telefone: validators.phone({ required: true }),
      dataAlteracaoContratual: validators.date({ required: true }),
      nomeProcurador: validators.string({ required: true }),
      cpfProcurador: validators.cpf({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        cpfCnpj: "",
        nome: "",
        tipoEmpresa: null,
        email: "",
        telefone: "",
        possuiProcuracao: false,
        nomeProcurador: "",
        cpfProcurador: "",
        ...vendedor,
      }),
      [vendedor]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-pessoais-vendedor-pj",
            participante: {
              id: vendedor?.id,
              cpfCnpj: removeNonDigitsFromString(values.cpfCnpj),
              nomeParticipante: values.nome,
              idTipoEmpresa: values.tipoEmpresa?.id,
              possuiProcuracao: values.possuiProcuracao,
              email: values.email,
              telefone: values.telefone,
              dataAlteracaoContratual: transformToDate(values.dataAlteracaoContratual),
              nomeProcurador: values.possuiProcuracao ? values.nomeProcurador : null,
              cpfProcurador: values.possuiProcuracao ? removeNonDigitsFromString(values.cpfProcurador) : null,
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, vendedor, operacao, callback]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      cpfCnpj: "",
      nome: "",
      tipoEmpresa: null,
      email: "",
      telefone: "",
      possuiProcuracao: false,
      nomeProcurador: "",
      cpfProcurador: "",
      dataAlteracaoContratual: "",
      ...vendedor,
    });
  }, [vendedor, reset]);

  const possuiProcuracao = formProps.watch("possuiProcuracao");

  return (
    <DadosBasicosVendedorPjItauForm
      {...otherProps}
      tiposEmpresa={tiposEmpresa}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={vendedor.fluxo}
      events={eventsVendedorPj}
      possuiProcuracao={possuiProcuracao}
    />
  );
};

export default DadosBasicosVendedorPjItauFormConnected;
