import React from "react";
import FluxoQualificacoesItauConnected from "./itau/FluxoQualificacoes.connected";
import FluxoQualificacoesSantanderConnected from "./santander/FluxoQualificacoes.connected";
import CommonPage from "../../CommonPage";
import { Grid, styled } from "@mui/material";
import { Skeleton } from "../../../components";
import OperationHeaderConnected from "../../../components/presentation/OperationHeader.connected";

const SkeletonCard = styled(Skeleton)`
  width: 100%;
  height: 239px;
  border-radius: 1rem;
`;

const escolherFluxoConfirmacao = (instituicao, props) => {
  switch (instituicao) {
    case "itau":
      return <FluxoQualificacoesItauConnected {...props} />
    case "santander":
      return <FluxoQualificacoesSantanderConnected {...props} />
  }
};

const ConfirmacaoValoresPage = (props) => {
  return (
    <CommonPage selected={"clientes"}>
      {props.loading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
        </Grid>
      )}
      {!props.loading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OperationHeaderConnected
              data={props.data.operacaoConfirmacaoValores.operacao}
              timeline={false}
              detalhes={false}
            />
          </Grid>
          {escolherFluxoConfirmacao(
            props.data.operacaoConfirmacaoValores.operacao.codigoInstituicao,
            props
          )}
        </Grid>
      )}
    </CommonPage>
  )
};

export default ConfirmacaoValoresPage;
