import { Grid } from "@mui/material";
import React from "react";
import { Button, Card, Form, GoBack } from "../../../../components";
import ControlledAutocomplete from "../../../../components/inputs/ControlledAutocomplete";
import { ControlledSwitch } from "../../../../components/inputs";
import Stepper from "../Stepper";

const DadosEstadoCivilBradescoForm = ({
  estadosCivis,
  regimesComunhao,
  events,
  fluxo,
  formProps,
  handleEstadoCivilChange,
  handleSubmit,
  exibeConjuge,
  exibeUniaoEstavel,
  handleUniaoEstavelChange,
  submitting,
  handleGoTo3,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} direction={"column"} maxWidth={704}>
        <GoBack
          handleClick={handleGoTo3}
          text={"Preencha as informações do comprador"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={3} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item width={1}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item container spacing={2}>
                    <Grid item xs={12} md={5}>
                      <ControlledAutocomplete
                        label="Estado Civil"
                        name={"estadoCivil"}
                        domain={estadosCivis}
                        idField={"id"}
                        labelField={"descricao"}
                        formProps={formProps}
                        autocompleteProps={{
                          clearText: "Limpar",
                          onChange: handleEstadoCivilChange,
                          loadingText: "Carregando opções",
                          noOptionsText: "Nenhuma opção disponível",
                        }}
                      />
                    </Grid>
                    {!!exibeUniaoEstavel && (
                      <Grid item xs={12} md={7}>
                        <ControlledSwitch
                          label="Convive em união estável?"
                          name={"uniaoEstavel"}
                          onChange={handleUniaoEstavelChange}
                          formProps={formProps}
                        />
                      </Grid>
                    )}
                    {!!exibeConjuge && (
                      <Grid item xs={12} md={8}>
                        <ControlledAutocomplete
                          label="Regime de Comunhão"
                          name={"regimeComunhao"}
                          domain={regimesComunhao}
                          idField={"id"}
                          labelField={"descricao"}
                          formProps={formProps}
                          autocompleteProps={{
                            clearText: "Limpar",
                            loadingText: "Carregando opções",
                            noOptionsText: "Nenhuma opção disponível",
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosEstadoCivilBradescoForm;
