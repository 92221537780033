import React from "react";
import {
  ControlledSwitch,
  ControlledTextField,
  CpfCnpjTextField,
  CurrencyTextField,
  DateTextField,
  IntegerTextField,
  PhoneTextField,
} from "../../components/inputs";
import { Grid, InputAdornment } from "@mui/material";
import { Button, Form } from "../../components";
import ControlledAutocomplete from "../../components/inputs/ControlledAutocomplete";
import { HorizontalLoader } from "../../components/loaders";
import UpperCaseTextField from "../../components/inputs/UpperCaseTextField";

const DadosClienteForm = ({
  formProps,
  exibeParceiro,
  domainParceiros,
  tipoPessoa,
  searching,
  handleSubmit,
  handleChangeCliente,
  handleChangeConjuge,
  submitting,
  outroParticipante,
  setOutroParticipante,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {exibeParceiro && (
          <Grid item container>
            <Grid item xs={12} md={8}>
              <ControlledAutocomplete
                label="Parceiro"
                domain={domainParceiros}
                idField={"id"}
                labelField={"nome"}
                name={"identificadorParceiro"}
                formProps={formProps}
                autocompleteProps={{
                  clearText: "Limpar",
                  onChange: handleChangeCliente,
                  loadingText: "Carregando opções",
                  noOptionsText: "Nenhuma opção disponível",
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <ControlledTextField
            label={tipoPessoa === "J" ? "CNPJ" : "CPF"}
            name={"cpfCnpj"}
            formProps={formProps}
            tooltip={"É aquele com maior renda informada."}
            onBlur={handleChangeCliente}
            type={tipoPessoa === "J" ? "cnpj" : "cpf"}
            InputProps={{
              inputComponent: CpfCnpjTextField,
              endAdornment: !!searching && (
                <InputAdornment position="end">
                  <HorizontalLoader size={28} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <ControlledTextField
            label="Nome Completo"
            name={"nomeCliente"}
            inputProps={{ maxLength: 100 }}
            formProps={formProps}
            InputProps={{
              inputComponent: UpperCaseTextField,
            }}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              name={"dataNascimento"}
              label={"Data de Nascimento"}
              formProps={formProps}
              tooltip={
                "A idade mínima para contratação do financiamento é de 16 anos e a máxima será definida de acordo com a idade do proponente e o prazo da operação, cuja soma não poderá superar 80 anos e 6 meses.\n" +
                "Esta limitação decorre da idade máxima elegível para fins de indenização do seguro obrigatório para as operações contratadas no Sistema Financeiro da Habitação, conforme normas publicadas pela SUSEP.\n" +
                "A idade dos compradores influencia no valor dos seguros habitacionais obrigatórios. Se a compra for feita combinando a renda de duas pessoas, será considerada a idade do comprador mais velho no cálculo."
              }
              InputProps={{
                inputComponent: DateTextField,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              name={"renda"}
              label={"Renda Mensal"}
              formProps={formProps}
              tooltip={
                "Renda bruta trata-se do rendimento médio mensal antes dos descontos e impostos.\n" +
                "A parcela do financiamento ou empréstimo não é recomendado ultrapassar 30 a 35% do comprometimento mensal de renda dos compradores dependendo a instuição que irá analisar o crédito."
              }
              InputProps={{
                inputComponent: CurrencyTextField,
              }}
              inputProps={{
                allowNegative: false,
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <ControlledTextField
            label="Celular para contato"
            name="telefone"
            formProps={formProps}
            tooltip={
              "Informação do celular atualizado é importante para efeito de acompanhamento do processo do cliente, facilitando a nossa comunicação com os clientes através do envio de alertas de SMS e ligações."
            }
            InputProps={{
              inputComponent: PhoneTextField,
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <ControlledTextField
            label="E-mail"
            name="email"
            tooltip={
              "Informação do E-mail atualizado é importante para efeito de acompanhamento do processo do cliente, facilitando a nossa comunicação através com os clientes através dos envios de mensagens por E-mail de forma automática e contato pontuais."
            }
            formProps={formProps}
          />
        </Grid>
        {tipoPessoa === "F" && (
          <Grid item xs={12}>
            <ControlledSwitch
              label="Deseja incluir a renda de outro participante?"
              name={"outroParticipante"}
              formProps={formProps}
              labelPlacement={"end"}
              tooltip={
                "O cliente pode compor renda com mais uma pessoa, podendo ser o cônjuge. Você pode somar sua renda com a de seu cônjuge para pagamento do crédito.\n" +
                "Importante! O cônjuge também precisa ser proprietário do imóvel para que seja possível a composição de renda."
              }
              onChange={(v) => setOutroParticipante(v.target.checked)}
            />
          </Grid>
        )}

        {!!outroParticipante && (
          <>
            <Grid item xs={12} md={4}>
              <ControlledTextField
                label={tipoPessoa === "J" ? "CNPJ" : "CPF"}
                name={"cpfCnpj2"}
                formProps={formProps}
                onBlur={handleChangeConjuge}
                type={tipoPessoa === "J" ? "cnpj" : "cpf"}
                InputProps={{
                  inputComponent: CpfCnpjTextField,
                  endAdornment: !!searching && (
                    <InputAdornment position="end">
                      <HorizontalLoader size={28} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <ControlledTextField
                label="Nome Completo"
                name={"nomeCliente2"}
                inputProps={{ maxLength: 100 }}
                formProps={formProps}
                InputProps={{
                  inputComponent: UpperCaseTextField,
                }}
              />
            </Grid>
            {tipoPessoa === "F" && (
              <Grid item xs={12} md={4}>
                <ControlledTextField
                  name={"dataNascimento2"}
                  label={"Data de Nascimento"}
                  formProps={formProps}
                  InputProps={{
                    inputComponent: DateTextField,
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={4}>
              <ControlledTextField
                name={"renda2"}
                label={"Renda Mensal"}
                tooltip={
                  "Renda bruta trata-se do rendimento médio mensal antes dos descontos e impostos.\n" +
                  "A parcela do financiamento ou empréstimo não é recomendado ultrapassar 30 a 35% do comprometimento mensal de renda dos compradores dependendo a instuição que irá analisar o crédito."
                }
                formProps={formProps}
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
                inputProps={{
                  allowNegative: false,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ControlledTextField
                label="Celular para contato"
                name="telefone2"
                tooltip={
                  "Informação do celular atualizado é importante para efeito de acompanhamento do processo do cliente, facilitando a nossa comunicação com os clientes através do envio de alertas de SMS e ligações."
                }
                formProps={formProps}
                InputProps={{
                  inputComponent: PhoneTextField,
                }}
              />
            </Grid>
          </>
        )}
        <Grid item container justifyContent={"center"} mt={1}>
          <Grid item xs={12} md={4}>
            <Button type={"submit"} fullWidth loading={submitting}>
              CONTINUAR
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default DadosClienteForm;
