import { Grid } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  GoBack,
  MenuItem,
  Title,
} from "../../../../../components";
import ControlledAutocomplete from "../../../../../components/inputs/ControlledAutocomplete";
import {
  ControlledSwitch,
  ControlledTextField,
  CpfCnpjTextField,
  DateTextField,
  PhoneTextField,
} from "../../../../../components/inputs";
import { estados } from "../../../../../constants/generic.constants";
import Stepper from "../../../preenchimento/Stepper";
import UpperCaseTextField from "../../../../../components/inputs/UpperCaseTextField";

const DadosBasicosVendedorPfDefaultForm = ({
  fluxo,
  tipoPessoa,
  profissoes,
  documentosPessoais,
  generos,
  formProps,
  handleSubmit,
  submitting,
  events,
  handleGoTo0,
  nacionalidades,
  isEmpresarioAutonomoOuProdutorRural,
  perfisRenda,
  possuiProcuracao,
  isEstrangeiro,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={handleGoTo0}
          text={"Preencha as informações do vendedor"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={0} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Dados pessoais</Title>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label={tipoPessoa === "J" ? "CNPJ" : "CPF"}
                      name={"cpfCnpj"}
                      formProps={formProps}
                      type={tipoPessoa === "J" ? "cnpj" : "cpf"}
                      InputProps={{
                        inputComponent: CpfCnpjTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ControlledTextField
                      label="Nome Completo"
                      name={"nome"}
                      inputProps={{ maxLength: 100 }}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: UpperCaseTextField,
                      }}
                    />
                  </Grid>
                  {tipoPessoa === "F" && (
                    <Grid item xs={12} md={4}>
                      <ControlledTextField
                        name={"dataNascimento"}
                        label={"Data de Nascimento"}
                        formProps={formProps}
                        InputProps={{
                          inputComponent: DateTextField,
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    <ControlledAutocomplete
                      label="Gênero"
                      domain={generos}
                      idField={"id"}
                      labelField={"descricao"}
                      name={"genero"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledAutocomplete
                      label="Nacionalidade"
                      domain={nacionalidades}
                      idField={"id"}
                      labelField={"descricao"}
                      name={"nacionalidade"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Documento</Title>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledAutocomplete
                      label="Documento"
                      domain={documentosPessoais}
                      idField={"id"}
                      labelField={"descricao"}
                      name={"documentoPessoal"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="Número do Documento"
                      name={"numeroDocumentoPessoal"}
                      inputProps={{ maxLength: 40 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label="Órgão Expedidor"
                      name={"orgaoDocumentoPessoal"}
                      inputProps={{ maxLength: 40 }}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: UpperCaseTextField,
                      }}
                    />
                  </Grid>
                  {!isEstrangeiro && (
                    <Grid item xs={12} md={4}>
                      <ControlledTextField
                        label="Estado da Expedição"
                        name={"ufOrgaoDocumentoPessoal"}
                        select
                        formProps={formProps}
                      >
                        {estados.map((state) => (
                          <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </ControlledTextField>
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      name={"dataExpedicaoDocumentoPessoal"}
                      label={"Data de Expedição"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: DateTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Informações de contato</Title>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label="Celular"
                      name="telefone"
                      formProps={formProps}
                      InputProps={{
                        inputComponent: PhoneTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ControlledTextField
                      label="E-mail"
                      name="email"
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Outras Informações</Title>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <ControlledTextField
                      label="Cargo ou Profissão"
                      name={"dadosProfissionais.cargo"}
                      inputProps={{ maxLength: 100 }}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: UpperCaseTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledAutocomplete
                      label="Perfil de Renda"
                      name={"perfilRenda"}
                      domain={perfisRenda}
                      idField={"id"}
                      labelField={"descricao"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  {isEmpresarioAutonomoOuProdutorRural && (
                    <Grid item xs={12}>
                      <ControlledSwitch
                        name={"dadosProfissionais.possuiFuncionarios"}
                        label={"Possui funcionários registrados?"}
                        formProps={formProps}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <ControlledSwitch
                      name={"possuiProcuracao"}
                      label={"Possui Procuração?"}
                      formProps={formProps}
                      tooltip={
                        "Em situações que exijam um procurador, é necessário apresentar uma cópia da procuração, com poderes específicos para o ato, com traslado atualizado que terá validade de até 90 dias, a contar da sua emissão pelo cartório. Deverão constar na procuração poderes específicos, para adquirir o imóvel (identificar o imóvel, se possível com a descrição estabelecida na matrícula), inclusive, para contratação de financiamento imobiliário para pagamento do preço, constituição de ônus, alienação fiduciária e assinando todos os instrumentos e documentos para seu fiel desempenho dos poderes outorgados."
                      }
                    />
                  </Grid>
                  {possuiProcuracao && (
                    <>
                      <Grid item xs={12}>
                        <Title as={"h5"}>Procurador</Title>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ControlledTextField
                          label={"CPF do Procurador"}
                          name={"cpfProcurador"}
                          formProps={formProps}
                          type={"cpf"}
                          InputProps={{
                            inputComponent: CpfCnpjTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ControlledTextField
                          label="Nome do Procurador"
                          name={"nomeProcurador"}
                          inputProps={{ maxLength: 100 }}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: UpperCaseTextField,
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosBasicosVendedorPfDefaultForm;
