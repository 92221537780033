import ValidadeCreditoDialogConnected from "./ValidadeCreditoDialog.connected";
import InteligenciaArtificialDocumentoDialogConnected from "./InteligenciaArtificialDocumentoDialog.connected";
import LaudoAvaliacaoDialogConnected from "./LaudoAvaliacaoDialog.connected";
import CondicoesComerciaisDialogConnected from "./CondicoesComerciaisDialog.connected";

const DocumentDialogs = () => {
  return (
    <>
      <ValidadeCreditoDialogConnected />
      <CondicoesComerciaisDialogConnected />
      <LaudoAvaliacaoDialogConnected />
      <InteligenciaArtificialDocumentoDialogConnected />
    </>
  );
};

export default DocumentDialogs;
