import CommonPage from "../CommonPage";
import React from "react";
import SelecionarProdutoSectionConnected from "./SelecionarProdutoSection.connected";
import ParametrosSimulacaoSectionConnected from "./ParametrosSimulacaoSection.connected";
import ResultadoSimulacaoSectionConnected from "./ResultadoSimulacaoSection.connected";
import DadosLeadSectionConnected from "./DadosLeadSection.connected";

const CalculadoraExternaPage = ({ atual, loading }) => {
  return (
    <CommonPage showHeader={!loading} selected={"calculadora"} display={"flex"}>
      {atual === 0 && <SelecionarProdutoSectionConnected />}
      {atual === 1 && <ParametrosSimulacaoSectionConnected />}
      {atual === 2 && <ResultadoSimulacaoSectionConnected />}
      {atual === 3 && <DadosLeadSectionConnected />}
    </CommonPage>
  );
};

export default CalculadoraExternaPage;
