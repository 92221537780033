import React from "react";
import DadosBasicosCompradorDefaultFormConnected from "./comprador/DadosBasicosCompradorDefaultForm.connected";
import HubDefaultConnected from "./HubDefault.connected";
import DadosProfissionaisCompradorDefaultFormConnected from "./comprador/DadosProfissionaisCompradorDefaultForm.connected";
import DadosEstadoCivilCompradorDefaultFormConnected from "./comprador/DadosEstadoCivilCompradorDefaultForm.connected";
import DadosEnderecoCompradorDefaultFormConnected from "./comprador/DadosEnderecoCompradorDefaultForm.connected";
import DadosBasicosVendedorPfDefaultFormConnected from "./vendedor/DadosBasicosVendedorPfDefaultForm.connected";
import DadosEstadoCivilVendedorDefaultFormConnected from "./vendedor/DadosEstadoCivilVendedorDefaultForm.connected";
import DadosEnderecoVendedorPfDefaultFormConnected from "./vendedor/DadosEnderecoVendedorPfDefaultForm.connected";
import DadosBasicosVendedorPjDefaultFormConnected from "./vendedor/DadosBasicosVendedorPjDefaultForm.connected";
import DadosEnderecoVendedorPjDefaultFormConnected from "./vendedor/DadosEnderecoVendedorPjDefaultForm.connected";
import DadosEnderecoImovelDefaultFormConnected from "./imovel/DadosEnderecoImovelDefaultForm.connected";
import DocumentosTabConnected from "./DocumentosTab.connected";
import OutrasInformacoesImovelDefaultFormConnected from "./imovel/OutrasInformacoesImovelDefaultForm.connected";
import DadosContatoImovelDefaultFormConnected from "./imovel/DadosContatoImovelDefaultForm.connected";
import DadosOutrasInformacoesCompradorDefaultFormConnected from "./comprador/DadosOutrasInformacoesCompradorDefaultForm.connected";
import ContasLiberacaoVendedorDefaultFormConnected from "./ContasLiberacaoVendedorDefaultForm.connected";
import DadosFgtsDefaultFormConnected from "./comprador/DadosFgtsDefaultForm.connected";
import DadosDpsDefaultFormConnected from "./comprador/DadosDpsDefaultForm.connected";

import DadosSociosPjVendedorDefaultFormConnected from "./vendedor/DadosSociosPjVendedorDefaultForm.connected";
import DadosBasicosSociosDefaultFormConnected from "./vendedor/socios/DadosBasicosSociosDefaultForm.connected";

const FluxoAquisicaoDefault = (props) => {
  return (
    <>
      {props.step === 0 && <HubDefaultConnected {...props} />}
      {props.step === 1 && (
        <DadosBasicosCompradorDefaultFormConnected {...props} />
      )}
      {props.step === 2 && (
        <DadosEnderecoCompradorDefaultFormConnected {...props} />
      )}
      {props.step === 3 && (
        <DadosProfissionaisCompradorDefaultFormConnected {...props} />
      )}
      {props.step === 4 && (
        <DadosEstadoCivilCompradorDefaultFormConnected {...props} />
      )}
      {props.step === 5 && (
        <DadosOutrasInformacoesCompradorDefaultFormConnected {...props} />
      )}
      {props.step === 6 && (
        <DadosSociosPjVendedorDefaultFormConnected {...props} />
      )}
      {props.step === 7 && <DadosFgtsDefaultFormConnected {...props} />}
      {props.step === 9 && <DadosDpsDefaultFormConnected {...props} />}
      {props.step === 10 && (
        <DadosBasicosVendedorPfDefaultFormConnected {...props} />
      )}
      {props.step === 11 && (
        <DadosEnderecoVendedorPfDefaultFormConnected {...props} />
      )}
      {props.step === 12 && (
        <DadosEstadoCivilVendedorDefaultFormConnected {...props} />
      )}
      {props.step === 20 && (
        <DadosBasicosVendedorPjDefaultFormConnected {...props} />
      )}
      {props.step === 21 && (
        <DadosEnderecoVendedorPjDefaultFormConnected {...props} />
      )}
      {props.step === 60 && (
        <DadosBasicosSociosDefaultFormConnected {...props} />
      )}
      {props.step === 100 && (
        <DadosEnderecoImovelDefaultFormConnected {...props} />
      )}
      {props.step === 101 && (
        <OutrasInformacoesImovelDefaultFormConnected {...props} />
      )}
      {props.step === 102 && (
        <DadosContatoImovelDefaultFormConnected {...props} />
      )}
      {props.step === 998 && (
        <ContasLiberacaoVendedorDefaultFormConnected {...props} />
      )}
      {props.step === 999 && <DocumentosTabConnected {...props} />}
    </>
  );
};

export default FluxoAquisicaoDefault;
