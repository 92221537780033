import {
  Button,
  Card,
  Form,
  IconButton,
  Text,
  Title,
  Tooltip,
} from "../../components";
import { Badge, Divider, Grid, Skeleton, styled } from "@mui/material";
import React, { Fragment } from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import {
  Comment,
  DeleteForever,
  Edit,
  Forum,
  GradeRounded,
  SendOutlined,
  SettingsSuggest,
  StarBorderRounded,
} from "@mui/icons-material";
import formatters from "../../utils/formatters";
import { ControlledSwitch, ControlledTextField } from "../../components/inputs";
import { transientOptions } from "../../styles/theme";

const CommentSection = styled(Timeline)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  padding: 0.5rem 1rem;
  max-height: 600px;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.colors.grey._50};
  border-radius: 1rem;

  & .MuiTimelineItem-root {
    min-height: max-content;
  }
  & .MuiTimelineItem-missingOppositeContent:before {
    flex: 0;
    padding: 0;
  }
  & .MuiTimelineDot-root {
    background-color: ${({ theme }) => theme.colors.grey._100};
    padding: 6px;
    box-shadow: none;
  }
  & .MuiTimelineConnector-root {
    background-color: transparent;
    border-right: 1px dashed ${({ theme }) => theme.colors.grey._200};
  }
  & .MuiTimelineContent-root {
  }
`;
const SinceText = styled("span")`
  color: ${({ theme }) => theme.colors.grey._300};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 500;
`;
const MessageIcon = styled(Comment, transientOptions)`
  color: ${({ theme, _tipo_ = "OP", _confidencial_ }) =>
    _confidencial_
      ? theme.colors.error._700
      : _tipo_ === "OP"
      ? theme.colors.secondary._700
      : _tipo_ === "SI"
      ? theme.colors.primary._50
      : _tipo_ === "PA"
      ? theme.colors.primary._500
      : _tipo_ === "CL"
      ? theme.colors.grey._700
      : theme.colors.info._700};
  font-size: 1.2rem;
`;
const DateTimeText = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme }) => theme.colors.grey._300};
  margin: 0.25rem 0 0;
`;
const MessageReason = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme }) => theme.colors.primary._700};
  margin: 0 0 0.25rem;
`;
const MessageText = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.grey._500};
  margin: 0.25rem 0;
  white-space: pre-wrap;
  ${({ theme }) => `${theme.breakpoints.down("sm")} {
    hyphens: auto;
    word-wrap: break-word;
  }`}
`;
const MessageAuthor = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme }) => theme.colors.grey._200};
  margin: 0;
`;

const getTooltipComentario = ({ tipoUsuario }) => {
  switch (tipoUsuario) {
    case "OP":
      return "Comentário feito pela equipe de operações Taya";
    case "SI":
      return "Comentário feito pela plataforma Taya";
    case "PA":
      return "Comentário feito por um usuário parceiro";
    case "CL":
      return "Comentário feito por um usuário cliente";
    default:
      return "";
  }
};

const getIconComentario = ({ tipoUsuario }) => {
  switch (tipoUsuario) {
    case "SI":
      return SettingsSuggest;
    case "OP":
    case "PA":
    case "CL":
    default:
      return Comment;
  }
};

const EmptyComments = () => (
  <Grid
    container
    spacing={1}
    display={"flex"}
    pt={2}
    pb={2}
    flexDirection={"column"}
    alignItems={"center"}
    justifyContent={"center"}
  >
    <Grid item xs={12}>
      <Badge badgeContent={"0"} color="primary">
        <Forum color="primary" sx={{ fontSize: 40 }} />
      </Badge>
    </Grid>
    <Grid item xs={12}>
      <Text>Nenhum comentário ainda</Text>
    </Grid>
  </Grid>
);

const SkeletonComentary = () => {
  return (
    <Grid container spacing={2} mt={0.5}>
      <Grid item>
        <Skeleton variant={"circular"} width={36} height={36} />
      </Grid>
      <Grid item>
        <Grid container direction={"column"}>
          <Grid item>
            <Skeleton variant={"text"} width={140} height={20} />
          </Grid>
          <Grid item>
            <Skeleton variant={"text"} width={220} height={20} />
          </Grid>
          <Grid item>
            <Skeleton variant={"text"} width={320} height={40} />
          </Grid>
          <Grid item>
            <Skeleton variant={"text"} width={200} height={20} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const getHoursFromDate = (date) => {
  const diferenca = new Date().getTime() - new Date(date).getTime();
  return Math.floor(diferenca / (1000 * 60 * 60));
};
const getData = (date) => {
  const diferenca = new Date().getTime() - new Date(date).getTime();
  const dias = Math.floor(diferenca / (1000 * 60 * 60 * 24));
  const horas = Math.floor(
    (diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutos = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));

  return dias > 0
    ? `${dias} dias atrás`
    : horas > 0
    ? `${horas} horas atrás`
    : `${minutos} minutos atrás`;
};

const podeEditar = (usuario, comentario) => {
  if (comentario.tipoUsuario === "SI") {
    return false;
  }
  if (usuario.perfil === "OPERACAO") {
    return true;
  }
  if (getHoursFromDate(comentario.dataHoraCriacao) >= 24) {
    return false;
  }
  if (
    usuario.perfil === "CADASTRO_MASTER" ||
    usuario.perfil === "PARCEIRO_MASTER" ||
    usuario.perfil === "OPERACIONAL"
  ) {
    return comentario.tipoUsuario === "PA";
  }
  return usuario.id === comentario.idUsuario;
};

const ComentariosCard = ({
  comentarios,
  formProps,
  handleSubmit,
  loading,
  submitting,
  handleEditarComentario,
  handleExcluirComentario,
  handleDestacar,
  fluxoTrabalho,
  usuario,
}) => {
  return (
    <Card>
      <Grid container marginBottom={1}>
        <Grid item xs={12}>
          <Title as={"h3"}>Comentários</Title>
        </Grid>
      </Grid>
      <Form onSubmit={handleSubmit}>
        <Grid container alignItems={"flex-start"} spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              name={"comentario"}
              formProps={formProps}
              multiline
              minRows={2}
              maxRows={5}
              placeholder="Digite um comentário..."
            />
          </Grid>
          <Grid item xs={12} sm={8} md={10} lg={8} xl={8}>
            {usuario.perfil === "OPERACAO" && (
              <ControlledSwitch
                formProps={formProps}
                name="confidencial"
                label="Confidencial"
                labelPlacement="end"
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={4}
            xl={4}
            display={"flex"}
            justifyContent={"end"}
          >
            <Tooltip arrow title={"Gravar comentário"}>
              <Button
                loading={submitting}
                type={"submit"}
                fullWidth
                startIcon={<SendOutlined />}
              >
                Enviar
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Form>
      <Grid container my={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {!!loading && (
        <CommentSection>
          <SkeletonComentary />
          <SkeletonComentary />
          <SkeletonComentary />
        </CommentSection>
      )}
      {!loading && comentarios.length === 0 && <EmptyComments />}
      {!loading && comentarios.length > 0 && (
        <CommentSection>
          {comentarios.map((comentario, index) => (
            <TimelineItem key={comentario.id}>
              <TimelineSeparator>
                <Tooltip arrow title={getTooltipComentario(comentario)}>
                  <TimelineDot>
                    <MessageIcon
                      as={getIconComentario(comentario)}
                      _tipo_={comentario.tipoUsuario}
                      _confidencial_={comentario.confidencial}
                    />
                  </TimelineDot>
                </Tooltip>
                {index < comentarios.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    <Grid container>
                      <Grid item>
                        <DateTimeText>
                          {formatters.dates.simple(
                            new Date(comentario.dataHoraCriacao),
                            "dd/MM/yyyy HH:mm:ss"
                          )}
                          <SinceText>
                            {" - " + getData(comentario.dataHoraCriacao)}
                          </SinceText>
                        </DateTimeText>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      xs={true}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"end"}
                    >
                      <Grid
                        item
                        xs={true}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"end"}
                      >
                        {podeEditar(usuario, comentario) && (
                          <>
                            <Tooltip arrow title={"Editar"}>
                              <IconButton
                                onClick={() =>
                                  handleEditarComentario(comentario)
                                }
                              >
                                <Edit sx={{ fontSize: 20 }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip arrow title={"Excluir"}>
                              <IconButton
                                onClick={() =>
                                  handleExcluirComentario(comentario)
                                }
                              >
                                <DeleteForever sx={{ fontSize: 20 }} />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                        {usuario.perfil === "OPERACAO" && (
                          <Tooltip arrow title={"Destacar"}>
                            <IconButton
                              onClick={() => handleDestacar(comentario.id)}
                            >
                              {fluxoTrabalho?.idComentarioEspecial ===
                              comentario.id ? (
                                <GradeRounded
                                  color="primary"
                                  sx={{ fontSize: 20 }}
                                />
                              ) : (
                                <StarBorderRounded sx={{ fontSize: 20 }} />
                              )}
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {!!comentario.descricao && (
                      <MessageReason>{comentario.descricao}</MessageReason>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <MessageText>{comentario.texto}</MessageText>
                  </Grid>
                  <Grid item xs={12}>
                    <MessageAuthor>
                      {comentario.nomeUsuario +
                        (!!comentario.emailUsuario
                          ? ` (${comentario.emailUsuario})`
                          : "")}
                    </MessageAuthor>
                  </Grid>
                </Grid>
              </TimelineContent>
            </TimelineItem>
          ))}
        </CommentSection>
      )}
    </Card>
  );
};

export default ComentariosCard;
