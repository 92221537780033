import { createSyncAction } from "../utils/actionCreators";

export const types = {
  ATUALIZAR_USUARIO: "@@tema/ATUALIZAR_USUARIO",
  APLICAR: "@@tema/APLICAR",
  RESET: "@@tema/RESET",
  SET_MENU: "@@tema/OBTER_MENU",
};

export const actions = {
  atualizar: () =>
    createSyncAction(types.ATUALIZAR_USUARIO, {}, { meta: { silent: true } }),
  aplicarTema: (payload) =>
    createSyncAction(types.APLICAR, payload, { meta: { silent: false } }),
  reiniciarTema: (payload) => createSyncAction(types.RESET, payload),
  atualizarMenu: (payload) =>
    createSyncAction(types.SET_MENU, payload, { meta: { silent: true } }),
};
