import React, { useCallback, useEffect } from "react";
import DadosEnderecoOutrosProponentesFintechsForm from "./DadosEnderecoOutrosProponentesFintechsForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import { removeNonDigitsFromString } from "../../../../../utils/basic";
import { actions as parceiroActions } from "../../../../../actions/parceiro.actions";

const DadosEnderecoOutrosProponentesFintechsFormConnected = ({
  setStep,
  eventsOutrosPf,
  eventsOutrosPj,
  eventsSociosPf,
  eventsSociosPj,
  setParticipante,
  participante,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { operacao } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find(
        (p) => p.id === participante.id
      );
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      if (
        !!participante.participanteOrigem &&
        participante.tipoPessoa === "J"
      ) {
        setStep(0);
      } else {
        setStep(participante.tipoPessoa === "F" ? 52 : 53);
      }
    },
    [setStep, setParticipante, participante]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      endereco: {
        cep: validators.string({ required: true }),
        logradouro: validators.string({ required: true, maxLength: 100 }),
        numero: validators.string({ required: true, maxLength: 7 }),
        bairro: validators.string({ required: true, maxLength: 40 }),
        municipio: validators.string({ required: true, maxLength: 60 }),
        uf: validators.string({ required: true }),
      },
    }),
    initialValues: useCallback(
      () => ({
        endereco: {
          cep: "",
          logradouro: "",
          numero: "",
          complemento: "",
          bairro: "",
          municipio: "",
          uf: "",
          ...participante?.endereco,
        },
      }),
      [participante]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-endereco",
            participante: {
              id: participante?.id,
              endereco: {
                cep: removeNonDigitsFromString(values.endereco?.cep),
                logradouro: values.endereco.logradouro,
                numeroLogradouro: values.endereco.numero,
                complementoLogradouro: values.endereco.complemento,
                bairro: values.endereco.bairro,
                municipio: values.endereco.municipio,
                uf: values.endereco.uf,
              },
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, participante, operacao, callback]
    ),
  });
  const { setValue } = formProps;

  const successZipCodeCallback = useCallback(
    ({ data }) => {
      setValue("endereco.logradouro", data?.logradouro || "", {
        shouldValidate: true,
      });
      setValue("endereco.bairro", data?.bairro || "", { shouldValidate: true });
      setValue("endereco.uf", data?.uf || "", { shouldValidate: true });
      setValue("endereco.municipio", data?.localidade || "", {
        shouldValidate: true,
      });
    },
    [setValue]
  );
  const handleZipCodeBlurred = useCallback(
    (value) => {
      if (removeNonDigitsFromString(value).length === 8) {
        dispatch(
          parceiroActions.pesquisarCep.request({
            value,
            successCallback: successZipCodeCallback,
          })
        );
      }
    },
    [dispatch, successZipCodeCallback]
  );

  const { reset } = formProps;

  useEffect(() => {
    reset({
      endereco: {
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        municipio: "",
        uf: "",
        ...participante?.endereco,
      },
    });
  }, [participante, reset]);

  return (
    <DadosEnderecoOutrosProponentesFintechsForm
      {...otherProps}
      formProps={formProps}
      handleZipCodeBlurred={handleZipCodeBlurred}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={participante.fluxo}
      events={getEvents(
        participante,
        eventsOutrosPf,
        eventsOutrosPj,
        eventsSociosPf,
        eventsSociosPj
      )}
    />
  );
};

const getEvents = (
  p,
  eventsOutrosPf,
  eventsOutrosPj,
  eventsSociosPf,
  eventsSociosPj
) => {
  if (p.tipoPessoa === "F") {
    return !!p.participanteOrigem ? eventsSociosPf : eventsOutrosPf;
  }
  return !!p.participanteOrigem ? eventsSociosPj : eventsOutrosPj;
};

export default DadosEnderecoOutrosProponentesFintechsFormConnected;
