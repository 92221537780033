import { useDispatch, useSelector } from "react-redux";
import {
  getDetalhesParceiro,
} from "../../selectors/parceiro.selectors";
import { useCallback, useState } from "react";
import FiliaisTab from "./FiliaisTab";
import { actions } from "../../actions/parceiro.actions";

const FiliaisTabConnected = () => {
  const dispatch = useDispatch();
  const data = useSelector(getDetalhesParceiro);

  const handleDialog = useCallback((action, data) => {
    dispatch(actions.exibirDialogFilial(true, { action, ...data }))
  }, [dispatch])

  return (
    <FiliaisTab
      data={data}
      handleDialog={handleDialog}
    />
  );
};

export default FiliaisTabConnected;
