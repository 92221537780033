import { createSelector } from "reselect";

export const getLogin = (state) => state.autenticacao.login;
export const getEsqueciSenha = (state) => state.autenticacao["esqueci-senha"];
export const getTrocarSenha = (state) => state.autenticacao["trocar-senha"];

export const getCodigoOperacaoExterno = createSelector(
  getLogin,
  (state) => state.codigo
);

export const isLoginSubmitting = createSelector(
  getLogin,
  (state) => state.submitting
);

export const isEsqueciSenhaSubmitting = createSelector(
  getEsqueciSenha,
  (state) => state.submitting
);

export const isTrocarSenhaLoading = createSelector(
  getTrocarSenha,
  (state) => state.loading
);
export const isTrocarSenhaSubmitting = createSelector(
  getTrocarSenha,
  (state) => state.submitting
);
export const isTrocarSenhaTokenValid = createSelector(
  getTrocarSenha,
  (state) => state.valid
);
export const getTokenValidacao = createSelector(
  getTrocarSenha,
  (state) => state.token
);
