const data = {};
const localStorageAvailable = () => {
  try {
    const mod = "a";
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
};

const make = (key, isJson) => {
  return {
    save: (value) => {
      if (isJson) {
        save(key, JSON.stringify(value));
      } else {
        save(key, value);
      }
    },
    get: () => {
      if (isJson) {
        return JSON.parse(load(key));
      } else {
        return load(key);
      }
    },
    clear: () => {
      clean(key);
    },
  };
};

export const jwt = make("taya_jwt", true);
export const refreshToken = make("taya_refresh", true);
export const user = make("taya_user", true);
export const partner = make("taya_partner", true);
export const state = make("taya_state", false);

export const clean = (key) => {
  if (localStorageAvailable()) {
    localStorage.removeItem(key);
  } else {
    delete data[key];
  }
};
export const load = (key) => {
  if (localStorageAvailable()) {
    return localStorage.getItem(key);
  } else {
    return data[key];
  }
};
export const save = (key, value) => {
  if (localStorageAvailable()) {
    localStorage.setItem(key, value);
  } else {
    data[key] = value;
  }
};
