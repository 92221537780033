import CommonPage from "../../CommonPage";
import { Grid, Skeleton, styled } from "@mui/material";
import React from "react";
import FluxoAquisicaoDefaultConnected from "./default/FluxoAquisicaoDefault.connected";
import OperationHeaderConnected from "../../../components/presentation/OperationHeader.connected";
import FluxoAquisicaoItauConnected from "./itau/FluxoAquisicaoItau.connected";
import FluxoAquisicaoSantanderConnected from "./santander/FluxoAquisicaoSantander.connected";
import FluxoAquisicaoBradescoConnected from "./bradesco/FluxoAquisicaoBradesco.connected";

const SkeletonCard = styled(Skeleton)`
  width: 100%;
  height: 239px;
  border-radius: 1rem;
`;

const EscolherFluxoPreenchimento = ({
  instituicao,
  tipoProduto,
  correntista,
}) => {
  switch (instituicao) {
    case "itau":
      return <FluxoAquisicaoItauConnected />;
    case "santander":
      return <FluxoAquisicaoSantanderConnected />;
    case "bradesco":
      return <FluxoAquisicaoBradescoConnected />;
    default:
      return <FluxoAquisicaoDefaultConnected />;
  }
};

const ComplementoOperacaoPage = ({ loading, data }) => {
  const isLoading = !!loading || !data;

  return (
    <CommonPage selected={"clientes"}>
      {isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OperationHeaderConnected
              data={data.operacao}
              timeline={true}
              detalhes={true}
            />
          </Grid>
          <EscolherFluxoPreenchimento
            instituicao={data.operacao.codigoInstituicao}
            tipoProduto={data.operacao.tipoProduto}
            correntista={data.operacao.detalhe?.correntista}
          />
        </Grid>
      )}
    </CommonPage>
  );
};

export default ComplementoOperacaoPage;
