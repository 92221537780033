import React, { useCallback, useEffect } from "react";
import DadosEnderecoVendedorPjDefaultForm from "./DadosEnderecoVendedorPjDefaultForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import { removeNonDigitsFromString } from "../../../../../utils/basic";
import { actions as parceiroActions } from "../../../../../actions/parceiro.actions";

const DadosEnderecoVendedorPjDefaultFormConnected = ({
  handleGoTo0,
  events,
  setParticipante,
  participante: vendedor,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { tipoResidencia: tiposResidencia },
  } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === vendedor.id);
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      handleGoTo0();
    },
    [handleGoTo0, setParticipante, vendedor]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      endereco: {
        cep: validators.string({ required: true }),
        logradouro: validators.string({ required: true, maxLength: 40 }),
        numero: validators.string({ required: true, maxLength: 40 }),
        bairro: validators.string({ required: true, maxLength: 40 }),
        municipio: validators.string({ required: true, maxLength: 60 }),
        uf: validators.string({ required: true }),
      },
    }),
    initialValues: useCallback(
      () => ({
        endereco: {
          cep: "",
          logradouro: "",
          numero: "",
          complemento: "",
          bairro: "",
          municipio: "",
          uf: "",
          ...vendedor?.endereco,
        },
      }),
      [vendedor]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-endereco",
            participante: {
              id: vendedor?.id,
              endereco: {
                cep: removeNonDigitsFromString(values.endereco?.cep),
                logradouro: values.endereco.logradouro,
                numeroLogradouro: values.endereco.numero,
                complementoLogradouro: values.endereco.complemento,
                bairro: values.endereco.bairro,
                municipio: values.endereco.municipio,
                uf: values.endereco.uf,
              },
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, vendedor, operacao, callback]
    ),
  });
  const { setValue } = formProps;

  const successZipCodeCallback = useCallback(
    ({ data }) => {
      setValue("endereco.logradouro", data?.logradouro || "", {
        shouldValidate: true,
      });
      setValue("endereco.bairro", data?.bairro || "", { shouldValidate: true });
      setValue("endereco.uf", data?.uf || "", { shouldValidate: true });
      setValue("endereco.municipio", data?.localidade || "", {
        shouldValidate: true,
      });
    },
    [setValue]
  );
  const handleZipCodeBlurred = useCallback(
    (value) => {
      if (removeNonDigitsFromString(value).length === 8) {
        dispatch(
          parceiroActions.pesquisarCep.request({
            value,
            successCallback: successZipCodeCallback,
          })
        );
      }
    },
    [dispatch, successZipCodeCallback]
  );

  const { reset } = formProps;

  useEffect(() => {
    reset({
      endereco: {
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        municipio: "",
        uf: "",
        ...vendedor?.endereco,
      },
    });
  }, [vendedor, reset]);

  return (
    <DadosEnderecoVendedorPjDefaultForm
      {...otherProps}
      tipoPessoa={operacao.detalhe.tipoPessoa}
      formProps={formProps}
      handleZipCodeBlurred={handleZipCodeBlurred}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={vendedor.fluxo}
      events={events}
    />
  );
};

export default DadosEnderecoVendedorPjDefaultFormConnected;
