import basicFlow from "./asyncHandler";
import { put } from "redux-saga/effects";
import { actions } from "../actions/apoio.actions";
import { authenticatedRequest, destinies } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../actions/rotas.actions";

const obterMaterialApoio = basicFlow({
  actionGenerator: actions.obterMaterialApoio,
  api: () => {
    return authenticatedRequest({
      url: "/material-apoio",
      isResourceService: true,
      destiny: destinies.DOCUMENTO,
      method: "get",
    });
  },
});

function* materialApoioRouteWatcher() {
  yield routeWatcher([routes.MATERIAL_APOIO], function* () {
    yield put(actions.obterMaterialApoio.request());
  });
}

export const sagas = [
  obterMaterialApoio.watcher(),
  materialApoioRouteWatcher(),
];
