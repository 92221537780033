import CondicoesComerciaisTab from "./CondicoesComerciaisTab";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetalhesParceiro,
  getDialogosDisponiveis,
  getDominioParceiros,
  getDominioPromotores,
  isAtualizandoDetalhesParceiro,
  isValidandoIdentificador,
} from "../../selectors/parceiro.selectors";
import { useCallback, useState } from "react";
import useCompleteForm from "../../hooks/useCompleteForm";
import { actions } from "../../actions/parceiro.actions";
import validators from "../../utils/validators";
import { toast } from "react-toastify";
import { actionToPath } from "redux-first-router";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";
import { routePaths } from "../../routes";
import queryString from "query-string";
import { getUsuarioLogado } from "../../selectors/usuario.selectors";
import { getMenuDisposition } from "../../selectors/tema.selectors";

const CondicoesComerciaisTabConnected = () => {
  const dispatch = useDispatch();
  const submitting = useSelector(isAtualizandoDetalhesParceiro);
  const validating = useSelector(isValidandoIdentificador);
  const data = useSelector(getDetalhesParceiro);
  const parceiros = useSelector(getDominioParceiros);
  const promotores = useSelector(getDominioPromotores);
  const usuario = useSelector(getUsuarioLogado);
  const dialogosDisponiveis = useSelector(getDialogosDisponiveis);
  const isMenuOpen = useSelector(getMenuDisposition);

  const [validated, setValidated] = useState(true);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: (form) => ({
      modeloComercial: validators.string({ required: true }),
      parceiroIndicacao: validators.object({ required: false }),
    }),
    initialValues: () => ({
      modeloComercial: "",
      parceiroIndicacao: null,
      ...data,
    }),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.atualizarCondicaoComercial.request({
            ...values,
            parceiroIndicacao: values.parceiroIndicacao?.codigo,
          })
        );
      },
      [dispatch]
    ),
  });

  const isOperacao = usuario.perfil === "OPERACAO";
  const editable =
    (dialogosDisponiveis["DADOS_COMERCIAIS"] === "E" ||
      dialogosDisponiveis["DADOS_COMERCIAIS"] === "O") &&
    usuario.perfil === "OPERACAO" &&
    isOperacao;

  return (
    <CondicoesComerciaisTab
      submitting={submitting}
      validated={validated}
      formProps={formProps}
      handleSubmit={handleSubmit}
      editable={editable}
      isMenuOpen={isMenuOpen}
      isOperacao={isOperacao}
      parceiros={parceiros}
      data={data}
    />
  );
};

export default CondicoesComerciaisTabConnected;
