import React from "react";
import { Grid, styled } from "@mui/material";
import CommonPage from "../CommonPage";
import FooterConnected from "../addons/Footer.connected";
import { FullHeightContainer } from "../../components";

const Title = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.primary._500};
  font-size: ${({ theme }) => theme.fonts.size.xlarge};
  font-weight: 800;
  margin: 2rem 0 1rem;
  text-transform: uppercase;
`;
const Content = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: ${({ theme }) => theme.colors.grey._800};
  font-size: ${({ theme }) => theme.fonts.size.default};
  margin-bottom: 2rem;
  white-space: pre-wrap;
`;
const Section = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: ${({ theme }) => theme.colors.grey._800};
  font-size: ${({ theme }) => theme.fonts.size.default};
  margin-top: 1rem;
  white-space: pre-wrap;
`;
const SectionTitle = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.grey._800};
  font-weight: 800;
  font-size: ${({ theme }) => theme.fonts.size.default};
  margin: 0 0 1rem;
  white-space: pre-wrap;
`;

const TermosUsoPage = () => {
  return (
    <FullHeightContainer maxWidth={false} _direction_={"column"}>
      <Grid container>
        <Title>Política de Privacidade</Title>
        <Content>
          <Section>
            <SectionTitle>VISÃO GERAL</SectionTitle>
            {"TAYA TECH INTERMEDIACAO LTDA, denominada simplesmente como “TAYA - Plataforma CI | Plataforma Digital de Crédito” nesta Política de Privacidade, ora esclarece a você, usuário, as seguintes condições:\n" +
              "===\n" +
              "Bem-vindo(a) a TAYA - Plataforma CI | Plataforma Digital de Crédito. Agradecemos por utilizar o nosso site!\n" +
              "Nós atuamos como correspondente bancário devidamente certificado para intermediar a contratação de empréstimos (“Serviços”), por meio de nossa plataforma de crédito digital disponível em https://taya.com.br  (“Plataforma”).\n" +
              "Quando você utiliza a TAYA - Plataforma CI | Plataforma Digital de Crédito, você nos confia seus dados pessoais. Nos comprometemos a zelar por esta confiança, portanto, nossa Política de Privacidade “Política” demonstra claramente e acessivelmente como os seus dados serão coletados, armazenados, utilizados e compartilhados por meio de nossas ferramentas e sistemas.\n" +
              "A aceitação desta Política ocorre quando você, o usuário, consentir, com o uso dos seus dados pessoais para cada uma das finalidades descritas aqui, de acordo com o Art 7º, inciso I da Lei nº 13.709, de 14 de Agosto de 2018.\n" +
              "Caso não concorde com esta Política de Privacidade, por favor, não continue interagindo com esta Plataforma. \n" +
              "E caso possa nos ajudar a melhorar essa plataforma, por favor, nos envie um e-mail e nos conte o motivo da sua discordância. \n" +
              "Este documento deve ser lido juntamente com os nossos Termos de Uso, documento que complementa a forma de uso desta Plataforma. \n" +
              "Caso tenha dúvidas ou precise tratar de qualquer assunto relacionado a esta Política, entre em contato conosco através do contato@taya.com.br.\n" +
              "Das definições:\n" +
              "●\tBiometria Facial: tecnologia de reconhecimento facial a partir da análise de fotos, para identificar uma pessoa. Esta técnica é aplicada no processo de formalização digital, em que um link com as condições contratuais estarão expostos para análise e consentimento do usuário, sendo concluído por meio de selfie, de qualquer dispositivo com câmera.\n" +
              "●\tParceiros: Instituições financeiras, bancos, financeiras, fintechs, seguradoras, corretoras de seguros, entre outros.\n" +
              "●\tConsentimento: autorização para coleta e utilização dos seus dados para as finalidades apresentadas neste documento. Significa o ato do usuário realizar ações no site seguidas do aviso de “Li e aceito os Termos de Uso e Política de Privacidade” disposta nesta plataforma. Tal ato implica consentimento prévio, expresso e informado do Usuário em relação a todo o disposto em tais documentos, de acordo com a Lei nº 13.709, de 14 de Agosto de 2018.\n" +
              "●\tCookie: palavra em inglês que significa arquivo armazenado nos navegadores para facilitar a identificação destes. Utilizado por esta plataforma para personalizar rotinas para cada usuário.\n" +
              "●\tConteúdo: significa toda e qualquer informação disponibilizada pela ou por meio desta plataforma, tais como textos, dados, software, imagens, vídeos, áudios, recursos interativos, etc., incluindo-se os códigos fontes empregados para exibição desses conteúdos, como aqueles em linguagem HTML, CSS, PHP, entre outros.\n" +
              "●\tDados Cadastrais: dados pessoais de interesse público que identificam e qualificam a pessoa e que não são sigilosos, como por exemplo, nome, RG, CPF, endereço, estado civil, profissão, data de nascimento, nome da mãe etc. Todas as suas informações pessoais recolhidas, serão usadas para o ajudar a tornar a sua visita no nosso site o mais produtiva e agradável possível.\n" +
              "●\tDados Pessoais: quaisquer dados que possam identificar uma pessoa. A garantia da confidencialidade dos dados pessoais dos utilizadores do nosso site é importante para a TAYA - Plataforma CI | Plataforma Digital de Crédito. A informação pessoal recolhida pode incluir o seu nome, CPF, e-mail, número de telefone e/ou WhatsApp, endereço, data de nascimento e/ou outros.\n" +
              "●\tDispositivos: são computadores, notebooks, tablets, smartphones e quaisquer outros aparelhos utilizados pelo usuário para acessar a internet.\n"}
          </Section>
          <Section>
            <SectionTitle></SectionTitle>
            {""}
          </Section>
        </Content>
      </Grid>
      <FooterConnected />
    </FullHeightContainer>
  );
};

export default TermosUsoPage;
