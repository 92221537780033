import React, { useCallback, useEffect } from "react";
import DadosEstadoCivilCompradorSantanderForm from "./DadosEstadoCivilCompradorSantanderForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import {
  removeNonDigitsFromString,
  transformToDate,
} from "../../../../../utils/basic";
import formatters from "../../../../../utils/formatters";

const DadosEstadoCivilCompradorSantanderFormConnected = ({
  handleGoTo5,
  handleGoTo0,
  handleGoTo7,
  handleGoTo9,
  events,
  setParticipante,
  participante: comprador,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: {
      estadoCivil: estadosCivis,
      regimeComunhao: regimesComunhao,
      documentoPessoal: documentosPessoais,
      genero: generos,
      nacionalidade: nacionalidades,
    },
  } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);

  const estadoCivilInicial =
    estadosCivis.find((e) => e.id === comprador?.estadoCivil?.id) ?? null;
  const estadoCivilConjugeInicial =
    estadosCivis.find((e) => e.id === comprador?.conjuge?.estadoCivil?.id) ??
    null;

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }

      if (c.principal) {
        handleGoTo5();
      } else {
        if (data.operacao.tipoProduto === "I") {
          if (!!c.dps) {
            handleGoTo9();
          } else {
            handleGoTo7();
          }
        } else {
          if (!!c.dps) {
            handleGoTo9();
          } else {
            handleGoTo0();
          }
        }
      }
    },
    [
      handleGoTo5,
      setParticipante,
      comprador,
      handleGoTo0,
      handleGoTo7,
      handleGoTo9,
    ]
  );

  const requiredIfContainsSpouse = (value, values) => {
    const exibeConjuge = values.estadoCivil?.conjuge || values.uniaoEstavel;

    if (!exibeConjuge) {
      return true;
    }

    if (value !== undefined && value !== null && value !== "" && value !== {})
      return true;
    return "Campo obrigatório";
  };
  const requiredIfContainsSpouseNotMarried = (value, values) => {
    const exibeConjuge = values.estadoCivil?.conjuge || values.uniaoEstavel;
    const exibeExtra = exibeConjuge && values.isUniaoEstavel;

    if (!exibeExtra) {
      return true;
    }

    if (value !== undefined && value !== null && value !== "" && value !== {})
      return true;
    return "Campo obrigatório";
  };

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      estadoCivil: validators.object({ required: true }),
      dataCasamento: validators.date({
        custom: {
          requiredIfContainsSpouse,
        },
      }),
      regimeComunhao: validators.object({
        custom: {
          requiredIfContainsSpouse,
        },
      }),
      conjuge: {
        nome: validators.string({
          custom: {
            requiredIfContainsSpouse,
            nomeCompleto: (value) =>
              !value ||
              (value.indexOf(" ") > 0 &&
                value.substring(value.indexOf(" ") + 1).length > 0) ||
              "Nome informado deve ser completo",
          },
        }),
        cpfCnpj: validators.cpf({
          custom: {
            requiredIfContainsSpouse,
          },
        }),
        dataNascimento: validators.date({
          custom: {
            requiredIfContainsSpouse,
          },
        }),
        genero: validators.object({
          custom: {
            requiredIfContainsSpouse,
          },
        }),
        nacionalidade: validators.object({
          custom: {
            requiredIfContainsSpouse,
          },
        }),
        cargo: validators.string({
          custom: {
            requiredIfContainsSpouse,
          },
        }),
        documentoPessoal: validators.object({
          custom: {
            requiredIfContainsSpouse,
            rgRne: (v) => {
              const doc = documentosPessoais.find((d) => d.id === v?.id);

              if (doc?.tipo === "RG" || doc?.tipo === "RE") {
                return true;
              }

              return "O Santander exige que seja utilizado RG ou RNE";
            },
          },
        }),
        numeroDocumentoPessoal: validators.string({
          custom: {
            requiredIfContainsSpouse,
          },
          maxLength: 100,
        }),
        orgaoDocumentoPessoal: validators.string({
          custom: {
            requiredIfContainsSpouse,
          },
          maxLength: 100,
        }),
        ufOrgaoDocumentoPessoal: validators.string({
          custom: {
            requiredIfContainsSpouse,
          },
        }),
        dataExpedicaoDocumentoPessoal: validators.date({
          custom: {
            requiredIfContainsSpouse,
          },
          min: "01/01/1900",
          max: formatters.dates.short(new Date()),
        }),
        email: validators.email({
          custom: {
            requiredIfContainsSpouse,
          },
        }),
        estadoCivil: validators.object({
          custom: { requiredIfContainsSpouseNotMarried },
        }),
      },
    }),
    handleSubmit: useCallback(
      (values) => {
        const exibeUniaoEstavel = values.estadoCivil?.uniaoEstavel;
        const exibeConjuge = values.estadoCivil?.conjuge || values.uniaoEstavel;

        dispatch(
          actions.gravarComplemento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-estado-civil",
            participante: {
              id: comprador?.id,
              idEstadoCivil: values.estadoCivil?.id,
              uniaoEstavel: exibeUniaoEstavel && values.uniaoEstavel,
              idNacionalidade: values.conjuge.nacionalidade?.id,
              dataCasamento: !!exibeConjuge
                ? transformToDate(values.dataCasamento)
                : null,
              idRegimeComunhao: !!exibeConjuge
                ? values.regimeComunhao?.id
                : null,
              conjuge: !!exibeConjuge
                ? {
                    nome: values.conjuge.nome,
                    cpfCnpj: removeNonDigitsFromString(values.conjuge.cpfCnpj),
                    dataNascimento: transformToDate(
                      values.conjuge.dataNascimento
                    ),
                    idGenero: values.conjuge.genero?.id,
                    idDocumentoPessoal: values.conjuge.documentoPessoal?.id,
                    cargo: values.conjuge.cargo,
                    numeroDocumentoPessoal:
                      values.conjuge.numeroDocumentoPessoal,
                    orgaoDocumentoPessoal: values.conjuge.orgaoDocumentoPessoal,
                    ufOrgaoDocumentoPessoal:
                      values.conjuge.documentoPessoal?.descricao === "RNE"
                        ? null
                        : values.conjuge.ufOrgaoDocumentoPessoal,
                    dataExpedicaoDocumentoPessoal: transformToDate(
                      values.conjuge.dataExpedicaoDocumentoPessoal
                    ),
                    idNacionalidade: values.conjuge.nacionalidade?.id,
                    email: values.conjuge.email,
                    idEstadoCivil:
                      !!exibeUniaoEstavel && values.uniaoEstavel
                        ? values.conjuge?.estadoCivil?.id
                        : null,
                  }
                : null,
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, comprador, callback, operacao]
    ),
    initialValues: useCallback(
      () => ({
        uniaoEstavel: false,
        regimeComunhao: null,
        dataCasamento: "",
        ...comprador,
        estadoCivil: estadoCivilInicial,
        conjuge: {
          nome: "",
          cpfCnpj: "",
          dataNascimento: "",
          email: "",
          documentoPessoal: null,
          cargo: "",
          numeroDocumentoPessoal: "",
          orgaoDocumentoPessoal: "",
          ufOrgaoDocumentoPessoal: "",
          dataExpedicaoDocumentoPessoal: "",
          genero: null,
          nacionalidade: null,
          ...comprador?.conjuge,
          estadoCivil: estadoCivilConjugeInicial,
        },
      }),
      [comprador, estadoCivilInicial, estadoCivilConjugeInicial]
    ),
  });

  const { setValue, watch } = formProps;

  const estadoCivil = watch("estadoCivil");
  const isUniaoEstavel = watch("uniaoEstavel");
  const documentoPessoal = watch("conjuge.documentoPessoal");
  const isEstrangeiro = documentoPessoal?.id === 4;

  const exibeUniaoEstavel = !!estadoCivil?.uniaoEstavel;
  const exibeConjuge = !!estadoCivil?.conjuge || isUniaoEstavel;

  useEffect(() => {
    if (!!estadoCivil?.conjuge) {
      setValue("uniaoEstavel", false);
    }
  }, [estadoCivil, setValue, isUniaoEstavel]);

  return (
    <DadosEstadoCivilCompradorSantanderForm
      {...otherProps}
      estadosCivis={estadosCivis}
      regimesComunhao={regimesComunhao}
      generos={generos}
      nacionalidades={nacionalidades}
      events={events}
      fluxo={comprador.fluxo}
      formProps={formProps}
      handleSubmit={handleSubmit}
      exibeConjuge={exibeConjuge}
      exibeUniaoEstavel={exibeUniaoEstavel}
      documentosPessoais={documentosPessoais}
      isUniaoEstavel={isUniaoEstavel}
      submitting={submitting}
      isEstrangeiro={isEstrangeiro}
    />
  );
};

export default DadosEstadoCivilCompradorSantanderFormConnected;
