import React, { useCallback, useEffect } from "react";
import EditarComentarioDialog from "./EditarComentarioDialog";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../actions/workflow.actions";
import { getDialogEditarComentario } from "../../selectors/workflow.selectors";
import useCompleteForm from "../../hooks/useCompleteForm";
import validators from "../../utils/validators";
import { getUsuarioLogado } from "../../selectors/usuario.selectors";
import { useForm } from "react-hook-form";

const EditarComentarioDialogConnected = () => {
  const dispatch = useDispatch();
  const { show, data, submitting } = useSelector(getDialogEditarComentario);
  const usuario = useSelector(getUsuarioLogado);

  const handleClose = useCallback(() => {
    dispatch(actions.exibirEditarComentario(false, null));
  }, [dispatch]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      comentario: validators.string({ required: true }),
    }),
    initialValues: () => ({
      comentario: data?.texto ?? "",
      confidencial: data?.confidencial ?? false,
    }),
    handleSubmitWithForm: useCallback(
      (form) => {
        return (values) => {
          dispatch(
            actions.atualizarComentario.request({
              ...values,
              id: data.id,
              callback: handleClose,
            })
          );
        };
      },
      [dispatch, handleClose, data]
    ),
  });

  const { setValue, reset } = formProps;

  useEffect(() => {
    if (!!data) {
      setValue("comentario", data.texto);
      setValue("confidencial", data.confidencial);
    } else {
      reset();
    }
  }, [data, setValue, reset]);

  return (
    <EditarComentarioDialog
      open={show}
      submitting={submitting}
      handleClose={handleClose}
      formProps={formProps}
      handleSubmit={handleSubmit}
      usuario={usuario}
    />
  );
};

export default EditarComentarioDialogConnected;
