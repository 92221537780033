import { Grid } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  GoBack,
  Text,
  Title,
} from "../../../../../components";
import {
  ControlledRadioGroup,
  ControlledSwitch,
  ControlledTextField,
  CurrencyTextField,
  DateTextField,
  Radio,
} from "../../../../../components/inputs";
import Stepper from "../../../preenchimento/Stepper";

const DadosDpsDefaultForm = ({
  fluxo,
  formProps,
  handleSubmit,
  submitting,
  events,
  questoes,
  currentIndex,
  handleGoBack,
  visible,
  updateVisible,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={handleGoBack}
          text={"Preencha as informações do comprador"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={currentIndex} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item width={1}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {questoes.map(
                    (questao, i) =>
                      visible[i] && (
                        <Grid
                          key={questao.id}
                          item
                          xs={questao.tamanhoXs}
                          sm={questao.tamanhoSm}
                          md={questao.tamanhoMd}
                          lg={questao.tamanhoLg}
                          xl={questao.tamanhoXl}
                        >
                          <Conteudo
                            formProps={formProps}
                            questao={questao}
                            updateVisible={updateVisible}
                          />
                        </Grid>
                      )
                  )}
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Conteudo = ({ questao, formProps, updateVisible, ...otherProps }) => {
  switch (questao.tipoCampo) {
    case "TI":
      return <Title as={"h2"}>{questao.label}</Title>;
    case "ST":
      return <Title as={"h5"}>{questao.label}</Title>;
    case "DS":
      return <Text as={"p"}>{questao.label}</Text>;
    case "TX":
      switch (questao.tipoDominio) {
        case "$$":
          return (
            <ControlledTextField
              {...otherProps}
              label={questao.label}
              name={questao.codigo}
              tooltip={questao.tooltip}
              formProps={formProps}
              InputProps={{
                inputComponent: CurrencyTextField,
              }}
              onChange={() => updateVisible(formProps.getValues())}
            />
          );
        case "DT":
          return (
            <ControlledTextField
              {...otherProps}
              label={questao.label}
              name={questao.codigo}
              tooltip={questao.tooltip}
              formProps={formProps}
              InputProps={{
                inputComponent: DateTextField,
              }}
              onChange={() => updateVisible(formProps.getValues())}
            />
          );
        default:
          return (
            <ControlledTextField
              {...otherProps}
              label={questao.label}
              name={questao.codigo}
              tooltip={questao.tooltip}
              formProps={formProps}
              onChange={() => updateVisible(formProps.getValues())}
            />
          );
      }
    case "CX":
      return (
        <ControlledSwitch
          {...otherProps}
          label={questao.label}
          name={questao.codigo}
          tooltip={questao.tooltip}
          formProps={formProps}
          onChange={() => updateVisible(formProps.getValues())}
        />
      );
    case "RB":
      return (
        <ControlledRadioGroup
          {...otherProps}
          label={questao.label}
          name={questao.codigo}
          row
          tooltip={questao.tooltip}
          formProps={formProps}
          onChange={() => updateVisible(formProps.getValues())}
        >
          {questao.opcoes.map((o) => (
            <Radio key={o.id} value={o.codigo} label={o.descricao} />
          ))}
        </ControlledRadioGroup>
      );
    case "AC":
    case "SE":
    default:
      return null;
  }
};

export default DadosDpsDefaultForm;
