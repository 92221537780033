import { TableRow, styled } from "@mui/material";

const BodyTableRow = styled(TableRow)`
  background-color: ${({ theme, index }) =>
    index % 2 === 0 ? theme.colors.fixed.white : theme.colors.fixed.white};

  & .MuiTableCell-root {
    border-bottom: dashed 1px ${({ theme }) => theme.colors.grey._100};
    font-family: ${({ theme }) => theme.fonts.family.default};
    color: ${({ theme }) => theme.colors.grey._700};
    font-size: ${({ theme }) => theme.fonts.size.tables.content};
    font-weight: 700;
    padding: 0.75rem 0.5rem 0.75rem 0.5rem;
  }
  ${({ onClick, theme }) => onClick && `&:hover {
    cursor: pointer;
    background-color: ${theme.colors.grey._50}
  }`}

`;

export default BodyTableRow;
