import React, { useCallback, useState } from "react";
import DadosEstadoCivilInterForm from "./DadosEstadoCivilInterForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isCarregandoConjuge,
  isGravandoPreenchimentoOperacao,
} from "../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import validators from "../../../../utils/validators";
import { actions } from "../../../../actions/operacao.actions";
import {
  removeNonDigitsFromString,
  transformFromDate,
  transformToDate,
} from "../../../../utils/basic";
import formatters from "../../../../utils/formatters";

const DadosEstadoCivilInterFormConnected = ({
  handleGoTo0,
  events,
  setComprador,
  comprador,
  ...props
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: {
      estadoCivil: estadosCivis,
      genero: generos,
      escolaridade: escolaridades,
      regimeComunhao: regimesComunhao,
      profissao: profissoes,
      perfilRenda: perfisRenda,
    },
  } = useSelector(getPreenchimentoOperacao);
  const loadingConjuge = useSelector(isCarregandoConjuge);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const { conjuge, uniaoEstavel } =
    estadosCivis.find((e) => e.id === comprador?.estadoCivil?.id) ?? {};
  const [exibeConjuge, setExibeConjuge] = useState(
    (comprador?.uniaoEstavel || conjuge) ?? false
  );
  const [exibeUniaoEstavel, setExibeUniaoEstavel] = useState(
    uniaoEstavel ?? false
  );

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setComprador({
          ...c,
          fluxo: data.validacao[
            data.operacao.detalhe.correntista ? "correntista" : "completa"
          ]?.participantes?.find((f) => f.id === c?.id)?.etapas,
        });
      }
      handleGoTo0();
    },
    [handleGoTo0, setComprador, comprador]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      estadoCivil: validators.object({ required: true }),
      regimeComunhao: validators.object({
        custom: {
          requiredIf: (value, f) => {
            const uniaoEstavel = f["uniaoEstavel"];

            if (!exibeConjuge && !uniaoEstavel) {
              return true;
            }

            if (value !== undefined && value !== null && value !== {})
              return true;
            return "Campo obrigatório";
          },
        },
      }),
      conjuge: {
        nome: validators.string({
          custom: {
            requiredIf: (value, f) => {
              const uniaoEstavel = f["uniaoEstavel"];

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== "")
                return true;
              return "Campo obrigatório";
            },
            nomeCompleto: (value) =>
              !value ||
              (value.indexOf(" ") > 0 &&
                value.substring(value.indexOf(" ") + 1).length > 0) ||
              "Nome informado deve ser completo",
          },
        }),
        cpfCnpj: validators.cpf({
          custom: {
            requiredIf: (value, f) => {
              const uniaoEstavel = f["uniaoEstavel"];

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== "")
                return true;
              return "Campo obrigatório";
            },
          },
        }),
        dataNascimento: validators.date({
          min: "01/01/1900",
          max: formatters.dates.short(new Date()),
          custom: {
            requiredIf: (value, f) => {
              const uniaoEstavel = f["uniaoEstavel"];

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== "")
                return true;
              return "Campo obrigatório";
            },
          },
        }),
        genero: validators.object({
          custom: {
            requiredIf: (value, f) => {
              const uniaoEstavel = f["uniaoEstavel"];

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== {})
                return true;
              return "Campo obrigatório";
            },
          },
        }),
        email: validators.email({
          custom: {
            requiredIf: (value, f) => {
              const uniaoEstavel = f["uniaoEstavel"];

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== "")
                return true;
              return "Campo obrigatório";
            },
          },
        }),
        naturalidade: validators.string({
          maxLength: 100,
          custom: {
            requiredIf: (value, f) => {
              const uniaoEstavel = f["uniaoEstavel"];

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== "")
                return true;
              return "Campo obrigatório";
            },
            validTextSlashText: (value) =>
              !value ||
              /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+ \/ [A-Za-zÀ-ÖØ-öø-ÿ\s]+$/.test(value) ||
              'É necessário inserir a cidade e UF no formato: "Cidade de Nascimento / UF".',
          },
        }),
        telefone: validators.phone({
          custom: {
            requiredIf: (value, f) => {
              const uniaoEstavel = f["uniaoEstavel"];

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== "")
                return true;
              return "Campo obrigatório";
            },
          },
        }),
        profissao: validators.object({
          custom: {
            requiredIf: (value, f) => {
              const uniaoEstavel = f["uniaoEstavel"];

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== {})
                return true;
              return "Campo obrigatório";
            },
          },
        }),
        escolaridade: validators.object({
          custom: {
            requiredIf: (value, f) => {
              const uniaoEstavel = f["uniaoEstavel"];

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== {})
                return true;
              return "Campo obrigatório";
            },
          },
        }),
        perfilRenda: validators.object({
          custom: {
            requiredIf: (value, f) => {
              const uniaoEstavel = f["uniaoEstavel"];

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== {})
                return true;
              return "Campo obrigatório";
            },
          },
        }),
        renda: validators.number({
          custom: {
            requiredIf: (value, f) => {
              const { uniaoEstavel } = f;

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== "")
                return true;
              return "Campo obrigatório";
            },
          },
        }),
      },
    }),
    initialValues: useCallback(
      () => ({
        estadoCivil: null,
        uniaoEstavel: false,
        regimeComunhao: null,
        ...comprador,
        conjuge: {
          nome: "",
          cpfCnpj: "",
          dataNascimento: "",
          renda: "",
          telefone: "",
          email: "",
          naturalidade: "",
          profissao: null,
          escolaridade: null,
          perfilRenda: null,
          genero: null,
          ...comprador?.conjuge,
        },
      }),
      [comprador]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-estado-civil",
            participante: {
              id: comprador?.id,
              idEstadoCivil: values.estadoCivil?.id,
              uniaoEstavel: exibeUniaoEstavel && values.uniaoEstavel,
              idRegimeComunhao: !!exibeConjuge
                ? values.regimeComunhao?.id
                : null,
              conjuge: !!exibeConjuge
                ? {
                    nome: values.conjuge.nome,
                    cpfCnpj: removeNonDigitsFromString(values.conjuge.cpfCnpj),
                    dataNascimento: transformToDate(
                      values.conjuge.dataNascimento
                    ),
                    idGenero: values.conjuge.genero?.id,
                    idEscolaridade: values.conjuge.escolaridade?.id,
                    idProfissao: values.conjuge.profissao?.id,
                    idPerfilRenda: values.conjuge.perfilRenda?.id,
                    telefone: values.conjuge.telefone,
                    naturalidade: values.conjuge.naturalidade,
                    email: values.conjuge.email,
                    renda: values.conjuge.renda,
                  }
                : null,
            },
            operacao: {
              id: operacao.id,
              idParceiro: operacao.idParceiro,
            },
          })
        );
      },
      [dispatch, comprador, callback, operacao, exibeConjuge, exibeUniaoEstavel]
    ),
  });
  const { setValue } = formProps;

  const handleEstadoCivilChange = useCallback(
    (v) => {
      const vaiMudar =
        !exibeConjuge && !exibeUniaoEstavel
          ? true
          : exibeUniaoEstavel ^ (v?.uniaoEstavel ?? false);

      if (vaiMudar) {
        setValue("uniaoEstavel", false);
        setExibeUniaoEstavel(v?.uniaoEstavel ?? false);
        setExibeConjuge(v?.conjuge ?? false);
      }
    },
    [
      setExibeConjuge,
      setExibeUniaoEstavel,
      exibeUniaoEstavel,
      exibeConjuge,
      setValue,
    ]
  );
  const handleUniaoEstavelChange = useCallback(
    (e) => {
      setExibeConjuge(e.target.checked);
    },
    [setExibeConjuge]
  );

  const successCallback = useCallback(
    (data) => {
      if (!!data) {
        setValue("conjuge.nome", data.nome);
        setValue(
          "conjuge.dataNascimento",
          transformFromDate(data.dataNascimento)
        );
        setValue("conjuge.telefone", data.telefone);
        setValue("conjuge.email", data.email);
        setValue("conjuge.naturalidade", data.naturalidade);
        setValue("conjuge.renda", data.renda);
        if (!!data.genero) {
          setValue("conjuge.genero", { id: data.genero });
        } else {
          setValue("conjuge.genero", null);
        }
        if (!!data.escolaridade) {
          setValue("conjuge.escolaridade", { id: data.escolaridade });
        } else {
          setValue("conjuge.escolaridade", null);
        }
        if (!!data.perfilRenda) {
          setValue("conjuge.perfilRenda", { id: data.perfilRenda });
        } else {
          setValue("conjuge.perfilRenda", null);
        }
        if (!!data.dadosProfissionais.profissao) {
          setValue("conjuge.profissao", {
            id: data.dadosProfissionais.profissao,
          });
        } else {
          setValue("conjuge.profissao", null);
        }
      }
    },
    [setValue]
  );
  const handleCpfCnpjChange = useCallback(
    (v) => {
      if (!!v) {
        dispatch(
          actions.obterConjuge.request({
            cpfCnpj: removeNonDigitsFromString(v),
            parceiro: operacao.idParceiro,
            callback: successCallback,
          })
        );
      }
    },
    [dispatch, operacao, successCallback]
  );

  return (
    <DadosEstadoCivilInterForm
      {...props}
      estadosCivis={estadosCivis}
      perfisRenda={perfisRenda}
      generos={generos}
      regimesComunhao={regimesComunhao}
      escolaridades={escolaridades}
      profissoes={profissoes}
      events={events}
      fluxo={comprador.fluxo}
      formProps={formProps}
      handleEstadoCivilChange={handleEstadoCivilChange}
      handleSubmit={handleSubmit}
      exibeConjuge={exibeConjuge}
      exibeUniaoEstavel={exibeUniaoEstavel}
      handleUniaoEstavelChange={handleUniaoEstavelChange}
      handleCpfCnpjChange={handleCpfCnpjChange}
      loadingConjuge={loadingConjuge}
      submitting={submitting}
    />
  );
};

export default DadosEstadoCivilInterFormConnected;
