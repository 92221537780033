import { createSelector } from "reselect";
import jwtDecode from "jwt-decode";
import { jwt } from "../utils/localStorage";

export const getUsuario = (state) => state.usuario;
export const getUsuarios = createSelector(
  getUsuario,
  (state) => state.lista.data
);

export const isCarregandoUsuarios = createSelector(
  getUsuario,
  (state) => state.lista.loading
);

export const getDialogCompartilhamento = createSelector(
  getUsuario,
  (state) => state.dialogCompartilhamento
);

export const isCarregandoDetalhesUsuario = createSelector(
  getUsuario,
  (state) => state.detalhes.loadingUsuario
);

export const isCarregandoDetalhesParceiro = createSelector(
  getUsuario,
  (state) => state.detalhes.loadingParceiro
);

export const getDetalhesUsuario = createSelector(
  getUsuario,
  (state) => state.detalhes.data
);

export const getDetalhesParceiro = createSelector(
  getUsuario,
  (state) => state.detalhes.parceiro
);

export const getDominios = createSelector(
  getUsuario,
  (state) => state.dominios
);

export const isAtualizandoUsuario = createSelector(
  getUsuario,
  (state) => state.detalhes.submitting
);

export const isCriandoUsuario = createSelector(
  getUsuario,
  (state) => state.cadastro.submitting
);

export const isCarregandoListaParceiros = createSelector(
  getUsuario,
  (state) => state.cadastro.loading
);

export const getListaParceiros = createSelector(
  getUsuario,
  (state) => state.cadastro.listaParceiros
);
export const getListaParceirosNomeFantasia = createSelector(
  getUsuario,
  (state) =>
    state.cadastro.listaParceiros.toSorted((a, b) =>
      a.nomeFantasia.localeCompare(b.nomeFantasia)
    )
);

export const getUsuarioLogado = () => {
  const jwtToken = jwt.get();
  if (!jwtToken) {
    return null;
  }
  return jwtDecode(jwtToken);
};

export const isUsuarioLogado = () => {
  const jwtToken = jwt.get();
  if (!jwtToken) {
    return false;
  }
  return !!jwtDecode(jwtToken);
};

export const getPerfil = createSelector(getUsuarioLogado, (state) =>
  state.codigoOperacaoExterno ? "EXTERNO" : state.perfil
);
