import { Grid, IconButton, styled } from "@mui/material";
import React, { useState } from "react";
import { Button, Card, Form, Link, Title } from "../../components";
import {
  ControlledTextField,
  CpfCnpjTextField,
  PhoneTextField,
} from "../../components/inputs";
import ControlledAutocomplete from "../../components/inputs/ControlledAutocomplete";
import { ArrowBack } from "@mui/icons-material";
import Switch from "../../components/inputs/Switch";
import { FORMA_CONTATO } from "../../constants/generic.constants";
import UpperCaseTextField from "../../components/inputs/UpperCaseTextField";

const GoBackButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.grey._300};
  & .MuiSvgIcon-root {
    font-size: ${({ theme }) => theme.fonts.size.large};
  }

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    & .MuiSvgIcon-root {
			font-size: ${theme.fonts.size.xlarge};
		}
  }`}
`;
const TitleText = styled("span")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.grey._300};
  font-weight: 700;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    font-size: ${theme.fonts.size.large};
  }`}
`;
const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary._600};
`;

const DadosLeadSection = ({
  condicao,
  formProps,
  handleSubmit,
  submitting,
  handleGoBack,
  domainParceiros,
  exibeParceiro,
  handleUserTermsClick,
  handlePrivacyPolicyClick,
}) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  return (
    <Grid container justifyContent={"center"}>
      <Grid item container spacing={3} direction={"column"} maxWidth={704}>
        <Grid item container alignItems={"center"}>
          <Grid item mr={1}>
            <GoBackButton onClick={handleGoBack}>
              <ArrowBack />
            </GoBackButton>
          </Grid>
          <Grid item flex={1}>
            <TitleText>Informe os dados abaixo</TitleText>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <Form onSubmit={handleSubmit}>
              <Card>
                <Grid container spacing={2}>
                  {exibeParceiro && (
                    <Grid item xs={12} md={8}>
                      <ControlledAutocomplete
                        label="Parceiro"
                        domain={domainParceiros}
                        idField={"id"}
                        labelField={"nome"}
                        name={"identificadorParceiro"}
                        formProps={formProps}
                        autocompleteProps={{
                          clearText: "Limpar",
                          loadingText: "Carregando opções",
                          noOptionsText: "Nenhuma opção disponível",
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="Nome"
                      name={"nomeCliente"}
                      inputProps={{ maxLength: 100 }}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: UpperCaseTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label={condicao.tipoPessoa === "J" ? "CNPJ" : "CPF"}
                      name={"cpfCnpj"}
                      formProps={formProps}
                      type={condicao.tipoPessoa === "J" ? "cnpj" : "cpf"}
                      InputProps={{
                        inputComponent: CpfCnpjTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="Celular para contato"
                      name="telefone"
                      formProps={formProps}
                      InputProps={{
                        inputComponent: PhoneTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="E-mail"
                      name="email"
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledAutocomplete
                      label={"Qual maneira o cliente deseja ser contactado?"}
                      name={"formaContato"}
                      formProps={formProps}
                      domain={Object.values(FORMA_CONTATO)}
                      idField={"id"}
                      labelField={"descricao"}
                      autocompleteProps={{
                        multiple: true,
                        disableCloseOnSelect: true,
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <ControlledTextField
                      label="Cliente indicado por"
                      name={"indicadoPor"}
                      inputProps={{ maxLength: 100 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Switch
                      value={acceptedTerms}
                      onChange={(v) => {
                        setAcceptedTerms(!acceptedTerms);
                      }}
                      label={
                        <>
                          Li e concordo com os{" "}
                          <StyledLink onClick={handleUserTermsClick}>
                            Termos de Uso
                          </StyledLink>{" "}
                          e{" "}
                          <StyledLink onClick={handlePrivacyPolicyClick}>
                            Política de Privacidade
                          </StyledLink>{" "}
                          e autorizo a utilização dos dados informados para
                          contato
                        </>
                      }
                      labelPlacement={"end"}
                      margin={"none"}
                    />
                  </Grid>
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button
                        type={"submit"}
                        disabled={!acceptedTerms}
                        loading={submitting}
                        fullWidth
                      >
                        ENVIAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosLeadSection;
