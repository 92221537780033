import React, { useCallback, useEffect } from "react";
import FiliaisDialog from "./FiliaisDialog";
import { useDispatch, useSelector } from "react-redux";
import { getDialogFilial } from "../../selectors/parceiro.selectors";
import { getRouteArguments } from "../../selectors/routes.selectors";
import { actions } from "../../actions/parceiro.actions";
import useCompleteForm from "../../hooks/useCompleteForm";
import validators from "../../utils/validators";

const FiliaisDialogConnected = () => {
  const dispatch = useDispatch();
  const { show, data, submitting } = useSelector(getDialogFilial)
  const { id } = useSelector(getRouteArguments);

  const handleClose = useCallback(() => {
    dispatch(actions.exibirDialogFilial(false, null));
  }, [dispatch]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      nome: validators.string({ required: true }),
    }),
    initialValues: () => ({
      nome: data?.nome ?? "",
    }),
    handleSubmit: useCallback((values) => {
      if (data.action === 'update') {
        dispatch(actions.atualizarTime.request({
          id: data.id,
          idParceiro: id,
          nome: values.nome,
          callback: handleClose
        }));
      } else if (data.action === 'delete') {
        dispatch(actions.excluirTime.request({
          id: data.id,
          idParceiro: id,
          nome: values.nome,
          callback: handleClose
        }));
      } else if (data.action === 'create') {
        dispatch(actions.gravarTime.request({
          idParceiro: id,
          nome: values.nome,
          callback: handleClose
        }))
      }
    }, [dispatch, data]),
  });

  useEffect(() => {
    formProps.setValue("nome", data?.nome)
  }, [data])

  return (
    <FiliaisDialog
      show={show}
      data={data}
      handleClose={handleClose}
      submitting={submitting}
      formProps={formProps}
      handleSubmit={handleSubmit}
    />
  );
};

export default FiliaisDialogConnected;