import React from "react";
import CalculadoraExternaPage from "./CalculadoraExternaPage";
import { useSelector } from "react-redux";
import {
  getCalculadorasDisponiveis,
  getPaginaAtual,
} from "../../selectors/calculadora.selectors";

const CalculadoraExternaPageConnected = () => {
  const atual = useSelector(getPaginaAtual);
  const { loading } = useSelector(getCalculadorasDisponiveis);

  return <CalculadoraExternaPage atual={atual} loading={loading} />;
};

export default CalculadoraExternaPageConnected;
