import ListaParceirosPage from "./ListaParceirosPage";
import { useDispatch, useSelector } from "react-redux";
import {
  getParceiros,
  isCarregandoParceiros,
} from "../../selectors/parceiro.selectors";
import { useCallback, useEffect, useState } from "react";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";

const ListaParceirosPageConnected = () => {
  const dispatch = useDispatch();
  const loading = useSelector(isCarregandoParceiros);
  const data = useSelector(getParceiros);
  const [filtered, setFiltered] = useState(data ?? []);
  const [total, setTotal] = useState(data ?? []);
  const [filters, setFilters] = useState({
    dirty: false,
    situacao: "X",
    pesquisar: "",
  });
  const [currentPage, setCurrentPage] = useState(0);

  const handleClick = useCallback(
    (parceiro) => {
      dispatch(
        routeActions.redirectTo(routes.DETALHES_PARCEIRO, { id: parceiro.id })
      );
    },
    [dispatch]
  );
  const handlePaginationClick = useCallback(
    (page) => {
      setCurrentPage(page);
    },
    [setCurrentPage]
  );

  useEffect(() => {
    const f1 = data.filter((v) => {
      const { situacao, pesquisar } = filters;

      if (situacao !== "X" && situacao !== v.situacao) {
        return false;
      }
      return !(
        !!pesquisar &&
        !v.nomeFantasia.toUpperCase().includes(pesquisar.toUpperCase())
      );
    });

    const f2 = f1.filter((v, i) => {
      const startPage = currentPage * 10;
      const endPage = (currentPage + 1) * 10;

      return i >= startPage && i < endPage;
    });
    setTotal(f1);
    setFiltered(f2);
  }, [filters, currentPage, data, setFiltered, setTotal]);

  return (
    <ListaParceirosPage
      loading={loading}
      handlePaginationClick={handlePaginationClick}
      filters={filters}
      setFilters={setFilters}
      filtered={filtered}
      total={total}
      handleClick={handleClick}
      page={currentPage}
    />
  );
};

export default ListaParceirosPageConnected;
