import { createSelector } from "reselect";

export const getClientes = (state) => state.clientes;

export const getListaClientes = createSelector(
  getClientes,
  (state) => state.lista
);
export const isCarregandoClientes = createSelector(
  getListaClientes,
  (state) => state.loading
);
export const getConteudoClientes = createSelector(
  getListaClientes,
  (state) => state.conteudo
);

export const isCarregandoResumo = createSelector(
  getClientes,
  (state) => state.resumo.loading
);
export const getResumo = createSelector(
  getClientes,
  (state) => state.resumo.data
);
export const getListaPaging = createSelector(getListaClientes, (state) => ({
  total: state.totalElementos,
  tamanho: state.tamanho,
}));

export const isCarregandoDetalhesCliente = createSelector(
  getClientes,
  (state) => state.detalhes.loading
);
export const getDetalhesCliente = createSelector(
  getClientes,
  (state) => state.detalhes.data
);
export const isIniciandoRelacionada = createSelector(
  getClientes,
  (state) => state.detalhes.initing
);
export const getDominioParceiros = createSelector(
  getClientes,
  (state) => state.dominios.parceiros
);

export const getDominioProdutos = createSelector(
  getClientes,
  (state) => state.dominios.produtos
);

export const getDominioInstituicoesFinanceiras = createSelector(
  getClientes,
  (state) => state.dominios.instituicoesFinanceiras
);
