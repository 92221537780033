import ParametrosSimulacaoSection from "./ParametrosSimulacaoSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getCalculadorasDisponiveis,
  getCalculadoraSelecionada,
  getResultadoCalculo,
} from "../../selectors/calculadora.selectors";
import { useCallback, useEffect, useState } from "react";
import { actions } from "../../actions/calculadora.actions";
import useCompleteForm from "../../hooks/useCompleteForm";
import validators from "../../utils/validators";
import formatters from "../../utils/formatters";
import { parseDateUsingFormat } from "../../utils/basic";
import { differenceInYears, differenceInMonths, addMonths } from "date-fns";

const ParametrosSimulacaoSectionConnected = () => {
  const dispatch = useDispatch();
  const [mensagemFinanciamento, setMensagemFinanciamento] = useState("");
  const [mensagemPrazo, setMensagemPrazo] = useState("");
  const data = useSelector(getCalculadoraSelecionada);
  const { data: disponiveis } = useSelector(getCalculadorasDisponiveis);
  const { submitting, valores } = useSelector(getResultadoCalculo);

  const successCallback = useCallback(() => {
    dispatch(actions.navegar(2));
  }, [dispatch]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: (form) => ({
      tipoImovel: validators.string({ required: true }),
      dataNascimento: validators.date({
        required: true,
        custom: {
          minimo18: (value) => {
            if (!value) return true;
            const date = parseDateUsingFormat(value, "dd/MM/yyyy");
            if (!!date) {
              const anos = differenceInYears(new Date(), date);
              return (
                anos >= 18 || "Calculadora indisponível para menores de 18 anos"
              );
            }
            return true;
          },
        },
      }),
      valorImovel: validators.number({
        required: true,
        custom: {
          valorMaximo: (value) => {
            if (!!data && data.valorMaximoImovel < value) {
              return `Valor do Imóvel não pode ultrapassar R$ ${formatters.numbers.currency(
                data.valorMaximoImovel
              )}`;
            }
            return true;
          },
        },
      }),
      valorFinanciamento: validators.number({
        required: true,
        custom: {
          valorMinimo: (value) => {
            if (!!data && data.valorFinanciamentoMinimo > value) {
              return `Valor deve ser no mínimo R$ ${formatters.numbers.currency(
                data.valorFinanciamentoMinimo
              )}`;
            }
            return true;
          },
          valorMaximo: (value) => {
            const valorImovel = form.getValues("valorImovel");
            if (!valorImovel || valorImovel === 0) {
              return true;
            }
            const ltv = data.percentualMaximoLtv;
            const financiaCustas = form.getValues("financiaCustas");

            const valorFinanciamentoTotal = financiaCustas
              ? (value + valorImovel * 0.05).toFixed(2)
              : value;

            const valorMaximo = financiaCustas
              ? (valorImovel * (ltv - 5)) / 100
              : (valorImovel * ltv) / 100;

            if ((valorFinanciamentoTotal * 100) / valorImovel > ltv) {
              if (financiaCustas) {
                return `Valor do Financiamento + Custas não pode ultrapassar ${formatters.numbers.nFixed(
                  ltv,
                  1
                )}% do Valor do Imóvel. Reduza o valor do financiamento para até R$ ${formatters.numbers.currency(
                  valorMaximo
                )}.`;
              } else {
                return `Valor do Financiamento não pode ultrapassar ${formatters.numbers.nFixed(
                  ltv,
                  1
                )}% do Valor do Imóvel. Reduza o valor do financiamento para até R$ ${formatters.numbers.currency(
                  valorMaximo
                )}.`;
              }
            }
            return true;
          },
        },
      }),
      prazoDesejado: validators.number({
        required: true,
        custom: {
          valorMinimo: (value) => {
            if (!!data && data.prazoMinimo > value) {
              return `Prazo desejado deve ser no mínimo ${data.prazoMinimo}`;
            }
            return true;
          },
          valorMaximo: (value) => {
            if (!!data && data.prazoMaximo < value) {
              return `Prazo desejado não pode ultrapassar ${data.prazoMaximo}`;
            }
            return true;
          },
          prazoMaximoSeguro: (value) => {
            const dataNascimentoCampo = form.getValues("dataNascimento");
            if (
              !!value &&
              !!data &&
              data.tipoPessoa === "F" &&
              !!dataNascimentoCampo
            ) {
              const dataNascimento = parseDateUsingFormat(
                dataNascimentoCampo,
                "dd/MM/yyyy"
              );
              const dataFinal = addMonths(dataNascimento, 80 * 12);
              const mesesRestantes = differenceInMonths(dataFinal, new Date());
              return (
                value <= mesesRestantes ||
                (mesesRestantes < data.prazoMinimo &&
                  tipoProduto === "H" &&
                  `Idade do cliente não permite empréstimo`) ||
                (mesesRestantes < data.prazoMinimo &&
                  tipoProduto === "I" &&
                  `Idade do cliente não permite financiamento`) ||
                `Prazo desejado no pode ultrapassar ${mesesRestantes}, pois o prazo + idade informada não podem ultrapassar 80 anos.`
              );
            }
            return true;
          },
        },
      }),
    }),
    initialValues: useCallback(
      () => ({
        tipoImovel:
          valores?.tipoImovel ?? data.tiposImovel.length === 1
            ? data.tiposImovel[0].id
            : "",
        dataNascimento: valores?.dataNascimento ?? "",
        valorImovel: valores?.valorImovel ?? "",
        valorFinanciamento: valores?.valorFinanciamento ?? "",
        prazoDesejado: valores?.prazoDesejado ?? "",
        financiaCustas: valores?.financiaCustas ?? false,
        financiaIof: valores?.financiaIof ?? true,
        financiaTarifaEmissao: valores?.financiaTarifaEmissao ?? true,
        financiaTarifaRegistro: valores?.financiaTarifaRegistro ?? true,
      }),
      [data, valores]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.calcular.request({ ...values, callback: successCallback })
        );
      },
      [dispatch, successCallback]
    ),
  });

  const tipoProduto = disponiveis.find(
    (p) => !!p.regras.find((g) => g.id === data.id)
  )?.tipo;

  const handleGoBack = useCallback(() => {
    dispatch(actions.navegar(0));
  }, [dispatch]);

  const {
    watch,
    formState: { submitCount },
    trigger,
  } = formProps;

  const valorImovel = watch("valorImovel");
  const financiaCustas = watch("financiaCustas");
  const dataNascimento = watch("dataNascimento");

  const handlePrazoMessage = useCallback(() => {
    if (!!data) {
      if (!dataNascimento || dataNascimento.length !== 10) {
        setMensagemPrazo(null);
        return;
      }
      const { prazoMaximo, prazoMinimo } = data;

      const dataNascimentoDate = parseDateUsingFormat(
        dataNascimento,
        "dd/MM/yyyy"
      );
      const dataFinal = addMonths(dataNascimentoDate, 80 * 12);
      const mesesRestantes = differenceInMonths(dataFinal, new Date());

      if (mesesRestantes < prazoMinimo) {
        if (tipoProduto === "H") {
          setMensagemPrazo(`Idade do cliente não permite empréstimo`);
        } else {
          setMensagemPrazo(`Idade do cliente não permite financiamento`);
        }
      } else {
        setMensagemPrazo(
          `Prazo máximo: ${Math.min(mesesRestantes, prazoMaximo)} meses`
        );
      }

      return;
    }
    setMensagemPrazo(null);
  }, [dataNascimento, setMensagemPrazo, data, tipoProduto]);

  const handleFinanciamentoMessage = useCallback(() => {
    if (!!data) {
      if (!valorImovel || valorImovel === 0) {
        setMensagemFinanciamento(null);
        return;
      }
      const ltv = data.percentualMaximoLtv;
      const valorMaximo = financiaCustas
        ? (valorImovel * (ltv - 5)) / 100
        : (valorImovel * ltv) / 100;

      if (tipoProduto === "H") {
        setMensagemFinanciamento(
          `Valor máximo de empréstimo: R$ ${formatters.numbers.currency(
            valorMaximo
          )}.`
        );
      } else if (tipoProduto === "C") {
        setMensagemFinanciamento(
          `Valor máximo de consórcio: R$ ${formatters.numbers.currency(
            valorMaximo
          )}.`
        );
      } else {
        setMensagemFinanciamento(
          `Valor máximo de financiamento: R$ ${formatters.numbers.currency(
            valorMaximo
          )}.`
        );
      }
      return;
    }
    setMensagemFinanciamento(null);
  }, [
    valorImovel,
    financiaCustas,
    setMensagemFinanciamento,
    data,
    tipoProduto,
  ]);

  useEffect(() => {
    handlePrazoMessage();
    handleFinanciamentoMessage();
  }, [handlePrazoMessage, handleFinanciamentoMessage]);

  const handleChangePrazo = useCallback(() => {
    if (submitCount > 0) {
      trigger("prazoDesejado");
    }
  }, [trigger, submitCount]);

  return (
    <ParametrosSimulacaoSection
      condicao={data}
      formProps={formProps}
      handleGoBack={handleGoBack}
      tipoProduto={tipoProduto}
      handleSubmit={handleSubmit}
      submitting={submitting}
      mensagemFinanciamento={mensagemFinanciamento}
      mensagemPrazo={mensagemPrazo}
      handleChangePrazo={handleChangePrazo}
    />
  );
};

export default ParametrosSimulacaoSectionConnected;
