import DadosLeadSection from "./DadosLeadSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getCalculadoraSelecionada,
  getDominioParceiros,
  getLead,
  getParceiroExterno,
} from "../../selectors/calculadora.selectors";
import { useCallback } from "react";
import useCompleteForm from "../../hooks/useCompleteForm";
import validators from "../../utils/validators";
import { actions } from "../../actions/calculadora.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";
import { getRouteQueryParams } from "../../selectors/routes.selectors";
import { getUsuarioLogado } from "../../selectors/usuario.selectors";

const DadosLeadSectionConnected = () => {
  const dispatch = useDispatch();
  const selecionada = useSelector(getCalculadoraSelecionada);
  const { submitting } = useSelector(getLead);
  const { data: parceiros } = useSelector(getDominioParceiros);
  const parceiroExterno = useSelector(getParceiroExterno);
  const routeParams = useSelector(getRouteQueryParams);
  const usuario = useSelector(getUsuarioLogado);

  const exibeParceiro = !parceiroExterno && !usuario.idParceiro;

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      identificadorParceiro: validators.object({ required: exibeParceiro }),
      cpfCnpj: validators.cpfCnpj({ required: true }),
      nomeCliente: validators.string({ required: true, maxLength: 100 }),
      telefone: validators.phone({ required: true }),
      email: validators.email({ required: true }),
      formaContato: validators.array({ required: true }),
      indicadoPor: validators.string({ required: true }),
    }),
    initialValues: () => ({
      identificadorParceiro: null,
      cpfCnpj: "",
      nomeCliente: "",
      telefone: "",
      email: "",
      formaContato: [],
      indicadoPor: "",
    }),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarLead.request({
            ...values,
            comercialResponsavel: routeParams?.comercial,
          })
        );
      },
      [dispatch]
    ),
  });

  const handleGoBack = useCallback(() => {
    dispatch(actions.navegar(2));
  }, [dispatch]);
  const handleUserTermsClick = useCallback(() => {
    dispatch(routeActions.openNewWindowWith(routes.TERMOS_USO));
  }, [dispatch]);
  const handlePrivacyPolicyClick = useCallback(() => {
    dispatch(routeActions.openNewWindowWith(routes.POLITICA_PRIVACIDADE));
  }, [dispatch]);

  return (
    <DadosLeadSection
      condicao={selecionada}
      handleGoBack={handleGoBack}
      formProps={formProps}
      submitting={submitting}
      handleSubmit={handleSubmit}
      domainParceiros={parceiros}
      exibeParceiro={exibeParceiro}
      handleUserTermsClick={handleUserTermsClick}
      handlePrivacyPolicyClick={handlePrivacyPolicyClick}
    />
  );
};

export default DadosLeadSectionConnected;
