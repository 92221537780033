import { actions } from "../actions/apoio.actions";
import { types as routes } from "../actions/rotas.actions";

const initialState = {
  loading: true,
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterMaterialApoio.REQUEST:
    case actions.obterMaterialApoio.SUCCESS:
    case actions.obterMaterialApoio.FAILURE:
      return {
        ...state,
        loading: action.type === actions.obterMaterialApoio.REQUEST,
        data:
          action.type === actions.obterMaterialApoio.SUCCESS
            ? action.payload.response.data
            : [],
      };
    case routes.MATERIAL_APOIO:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
