import { ControlledTextField } from "../../components/inputs";
import { Button, Form } from "../../components";
import { PersonOutline } from "@mui/icons-material";
import React from "react";
import { Grid, InputAdornment } from "@mui/material";

const EsqueciSenhaForm = ({
  internal,
  submitting,
  formProps,
  handleSubmit,
  handleClickBack,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Grid item xs={12} md={12}>
        <ControlledTextField
          label="E-mail"
          name={"email"}
          formProps={formProps}
          margin={"none"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutline />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Button loading={submitting} type={"submit"} fullWidth>
          ENVIAR
        </Button>
      </Grid>

      {!internal && (
        <Grid item xs={12} md={12}>
          <Button
            variant={"text"}
            _color_={"grey"}
            label={"VOLTAR"}
            fullWidth
            onClick={handleClickBack}
          >
            VOLTAR
          </Button>
        </Grid>
      )}
    </Form>
  );
};

export default EsqueciSenhaForm;
