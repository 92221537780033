import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  CRIAR: "@@parceiro/CRIAR",
  VALIDAR: "@@parceiro/VALIDAR",
  OBTER: "@@parceiro/OBTER",
  OBTER_PARCEIROS_SUBSTABELECIDOS: "@@parceiro/OBTER_PARCEIROS_SUBSTABELECIDOS",
  OBTER_PROMOTORES: "@@parceiro/OBTER_PROMOTORES",
  LISTAR: "@@parceiro/LISTAR",
  ATUALIZAR: "@@parceiro/ATUALIZAR",
  VALIDAR_IDENTIFICADOR: "@@parceiro/VALIDAR_IDENTIFICADOR",
  ATUALIZAR_CONDICAO_COMERCIAL: "@@parceiro/ATUALIZAR_CONDICAO_COMERCIAL",
  CEP: "@@parceiro/CEP",
  BANCOS: "@@parceiro/BANCOS",
  PARCEIROS: "@@parceiro/PARCEIROS",
  REFRESH: "@@parceiro/REFRESH",

  OBTER_DOCUMENTOS: "@@parceiro/OBTER_DOCUMENTOS",
  UPLOAD_DOCUMENTO: "@@parceiro/UPLOAD_DOCUMENTO",
  DOWNLOAD_DOCUMENTO: "@@parceiro/DOWNLOAD_DOCUMENTO",
  DOWNLOAD_TEMPLATE: "@@parceiro/DOWNLOAD_TEMPLATE",
  REMOVER_ARQUIVO: "@@parceiro/REMOVER_ARQUIVO",
  EXIBIR_DIALOG_COMISSIONAMENTO_PARCEIRO:
    "@@parceiro/EXIBIR_DIALOG_COMISSIONAMENTO_PARCEIRO",

  OBTER_TIMES: "@@parceiro/OBTER_TIMES",
  ATUALIZAR_TIME: "@@parceiro/ATUALIZAR_TIME",
  GRAVAR_TIME: "@@parceiro/GRAVAR_TIME",
  EXCLUIR_TIME: "@@parceiro/EXCLUIR_TIME",
  EXIBIR_DIALOG_FILIAL: "@@parceiro/EXIBIR_DIALOG_FILIAL",
};

export const actions = {
  criarParceiro: createAsyncAction(types.CRIAR),
  obterParceiro: createAsyncAction(types.OBTER),
  obterParceirosSubstabelecidos: createAsyncAction(
    types.OBTER_PARCEIROS_SUBSTABELECIDOS
  ),
  obterPromotores: createAsyncAction(types.OBTER_PROMOTORES),
  validar: createAsyncAction(types.VALIDAR),
  atualizarParceiro: createAsyncAction(types.ATUALIZAR),
  atualizarCondicaoComercial: createAsyncAction(
    types.ATUALIZAR_CONDICAO_COMERCIAL
  ),
  validarIdentificador: createAsyncAction(types.VALIDAR_IDENTIFICADOR),
  listarParceiros: createAsyncAction(types.LISTAR),
  pesquisarCep: createAsyncAction(types.CEP),
  obterBancos: createAsyncAction(types.BANCOS),
  obterDominioParceiros: createAsyncAction(types.PARCEIROS),

  obterDocumentos: createAsyncAction(types.OBTER_DOCUMENTOS),
  uploadDocumento: createAsyncAction(types.UPLOAD_DOCUMENTO),
  downloadDocumento: createAsyncAction(types.DOWNLOAD_DOCUMENTO),
  downloadTemplate: createAsyncAction(types.DOWNLOAD_TEMPLATE),
  removerArquivo: createAsyncAction(types.REMOVER_ARQUIVO),

  obterTimes: createAsyncAction(types.OBTER_TIMES),
  atualizarTime: createAsyncAction(types.ATUALIZAR_TIME),
  gravarTime: createAsyncAction(types.GRAVAR_TIME),
  excluirTime: createAsyncAction(types.EXCLUIR_TIME),
  exibirDialogFilial: (show, data) =>
    createSyncAction(types.EXIBIR_DIALOG_FILIAL, { show, data }),

  refresh: createAsyncAction(types.REFRESH),

  exibirDialogComissionamentoParceiro: (show, data) =>
    createSyncAction(types.EXIBIR_DIALOG_COMISSIONAMENTO_PARCEIRO, {
      show,
      data,
    }),
};
