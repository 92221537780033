import ReenquadrarOperacaoForm from "./ReenquadrarOperacaoForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getNovoCalculo,
  getPreenchimentoOperacao,
} from "../../../selectors/operacao.selectors";
import useCompleteForm from "../../../hooks/useCompleteForm";
import { useCallback, useEffect, useMemo, useState } from "react";
import { actions } from "../../../actions/operacao.actions";
import validators from "../../../utils/validators";
import { addMonths, differenceInMonths } from "date-fns";
import { parseDateUsingFormat } from "../../../utils/basic";
import formatters from "../../../utils/formatters";

const ReenquadrarOperacaoFormConnected = ({
  props,
  handleGoTo996,
  handleGoTo0,
}) => {
  const dispatch = useDispatch();
  const { operacao } = useSelector(getPreenchimentoOperacao);

  const { loading: recalculando, regraBase } = useSelector(getNovoCalculo);
  const [mensagemFinanciamento, setMensagemFinanciamento] = useState("");
  const [mensagemPrazo, setMensagemPrazo] = useState("");

  const detalhe = useMemo(() => {
    return operacao?.detalhe;
  }, [operacao]);

  const participantes = useMemo(() => {
    return operacao?.participantes;
  }, [operacao]);

  const callback = useCallback(() => {
    handleGoTo996();
  }, [handleGoTo996]);

  const menorData = useMemo(() => {
    return participantes
      .filter((p) => p.tipoParticipante === "CO" && !!p.dataNascimento)
      .sort(
        (a, b) => new Date(a._dataNascimento) - new Date(b._dataNascimento)
      )[0].dataNascimento;
  }, [participantes]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      valorImovel: validators.number({ required: true }),
      valorFinanciamento: validators.number({
        required: true,
        custom: {
          valorMaximo: (value, f) => {
            const valorImovel = f["valorImovel"];
            if (!valorImovel || valorImovel === 0) {
              return true;
            }
            const ltv = regraBase.percentualMaximoLtv;
            const financiaCustas = f["financiaCustas"];

            const valorFinanciamentoTotal = financiaCustas
              ? (value + valorImovel * 0.05).toFixed(2)
              : value;

            const valorMaximo = financiaCustas
              ? (valorImovel * (ltv - 5)) / 100
              : (valorImovel * ltv) / 100;

            if ((valorFinanciamentoTotal * 100) / valorImovel > ltv) {
              if (financiaCustas) {
                return `Valor do Financiamento + Custas não pode ultrapassar ${formatters.numbers.nFixed(
                  ltv,
                  1
                )}% do Valor do Imóvel. Reduza o valor do financiamento para até R$ ${formatters.numbers.currency(
                  valorMaximo
                )}.`;
              } else {
                return `Valor do Financiamento não pode ultrapassar ${formatters.numbers.nFixed(
                  ltv,
                  1
                )}% do Valor do Imóvel. Reduza o valor do financiamento para até R$ ${formatters.numbers.currency(
                  valorMaximo
                )}.`;
              }
            }
            return true;
          },
        },
      }),
      prazoDesejado: validators.number({
        required: true,
        custom: {
          valorMaximo: (value) => {
            if (!!regraBase && regraBase.prazoMaximo < value) {
              return `Prazo desejado não pode ultrapassar ${regraBase.prazoMaximo}`;
            }
            return true;
          },
          prazoMaximoSeguro: (value) => {
            if (!!value && !!menorData) {
              const dataNascimento = parseDateUsingFormat(
                menorData,
                "dd/MM/yyyy"
              );
              const dataFinal = addMonths(dataNascimento, 80 * 12);
              const mesesRestantes = differenceInMonths(dataFinal, new Date());

              return (
                value <= mesesRestantes ||
                `Prazo desejadp no pode ultrapassar ${mesesRestantes}, pois o prazo + idade informada não podem ultrapassar 80 anos.`
              );
            }
            return true;
          },
        },
      }),
    }),
    initialValues: useCallback(
      () => ({
        valorImovel: detalhe.valorImovel,
        valorFinanciamento: detalhe.valorFinanciamento,
        prazoDesejado: detalhe.prazoDesejado,
        financiaCustas: !!detalhe.financiaCustas,
        financiaIof: !!detalhe.financiaIof,
        financiaTarifaEmissao: !!detalhe.financiaTarifaEmissao,
        financiaTarifaRegistro: !!detalhe.financiaTarifaRegistro,
      }),
      [detalhe]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.reenquadrarOperacao.request({
            ...values,
            id: operacao.id,
            callback,
          })
        );
      },
      [dispatch, operacao, callback]
    ),
  });

  const valorImovel = formProps.watch("valorImovel");
  const financiaCustas = formProps.watch("financiaCustas");

  const handlePrazoMessage = useCallback(() => {
    const dataNascimentoDate = parseDateUsingFormat(menorData, "dd/MM/yyyy");
    const dataFinal = addMonths(dataNascimentoDate, 80 * 12);
    const mesesRestantes = differenceInMonths(dataFinal, new Date());

    setMensagemPrazo(
      `Prazo máximo: ${Math.min(mesesRestantes, regraBase.prazoMaximo)} meses`
    );
  }, [menorData, setMensagemPrazo]);

  const tipoProduto = operacao.tipoProduto;

  const handleFinanciamentoMessage = useCallback(() => {
    if (!!regraBase) {
      if (!valorImovel || valorImovel === 0) {
        setMensagemFinanciamento(null);
        return;
      }
      const ltv = regraBase.percentualMaximoLtv;
      const valorMaximo = financiaCustas
        ? (valorImovel * (ltv - 5)) / 100
        : (valorImovel * ltv) / 100;

      if (tipoProduto === "H") {
        setMensagemFinanciamento(
          `Valor máximo de empréstimo: R$ ${formatters.numbers.currency(
            valorMaximo
          )}.`
        );
      } else {
        setMensagemFinanciamento(
          `Valor máximo de financiamento: R$ ${formatters.numbers.currency(
            valorMaximo
          )}.`
        );
      }
      return;
    }
    setMensagemFinanciamento(null);
  }, [
    valorImovel,
    financiaCustas,
    setMensagemFinanciamento,
    regraBase,
    tipoProduto,
  ]);

  useEffect(() => {
    handleFinanciamentoMessage();
    handlePrazoMessage();
  }, [handleFinanciamentoMessage, handlePrazoMessage]);

  return (
    <ReenquadrarOperacaoForm
      detalhes={detalhe}
      formProps={formProps}
      handleSubmit={handleSubmit}
      recalculando={recalculando}
      handleGoTo0={handleGoTo0}
      mensagemPrazo={mensagemPrazo}
      mensagemFinanciamento={mensagemFinanciamento}
      {...props}
    />
  );
};

export default ReenquadrarOperacaoFormConnected;
