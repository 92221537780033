import React, { useCallback } from "react";
import HubSantander from "./HubSantander";
import { actions } from "../../../../actions/operacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../../actions/rotas.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isAdicionandoParticipanteComplemento,
  isAtualizandoComplemento,
  isEnviandoComplementoOperacao,
  isRemovendoParticipanteComplemento,
} from "../../../../selectors/operacao.selectors";

const HubSantanderConnected = ({
  setParticipante,
  setEvents,
  eventsCompradorPrincipalDps,
  eventsCompradorPrincipal,
  eventsCompradorDps,
  eventsComprador,
  eventsVendedorPf,
  eventsVendedorPj,
  eventsSociosPf,
  eventsSociosPj,
  handleGoTo1,
  handleGoTo10,
  handleGoTo20,
  handleGoTo60,
  handleGoTo100,
  handleGoTo998,
  compradores,
  vendedores,
  socios,
}) => {
  const dispatch = useDispatch();
  const { operacao, validacao, dominios } = useSelector(getComplementoOperacao);
  const refreshing = useSelector(isAtualizandoComplemento);
  const adding = useSelector(isAdicionandoParticipanteComplemento);
  const deleting = useSelector(isRemovendoParticipanteComplemento);
  const submitting = useSelector(isEnviandoComplementoOperacao);

  const imovel = {
    ...operacao.imovel,
    fluxo: validacao.completa.imovel,
  };

  const acessarParticipante = useCallback(
    (p) => {
      setParticipante(p);
      if (p.tipoParticipante === "CO") {
        if (!!p.principal) {
          if (!!p.dps) {
            setEvents(eventsCompradorPrincipalDps);
          } else {
            setEvents(eventsCompradorPrincipal);
          }
        } else {
          if (!!p.dps) {
            setEvents(eventsCompradorDps);
          } else {
            setEvents(eventsComprador);
          }
        }
        handleGoTo1();
      } else if (p.tipoParticipante === "VD") {
        if (p.tipoPessoa === "F"){
          setEvents(eventsVendedorPf);
          handleGoTo10();
        } else {
          setEvents(eventsVendedorPj);
          handleGoTo20();
        }
      } else if (p.tipoParticipante === "RL") {
        if (p.tipoPessoa === "F"){
          setEvents(eventsSociosPf);
        } else {
          setEvents(eventsSociosPj);
        }
        handleGoTo60();
      }
    },
    [
      setParticipante,
      handleGoTo1,
      handleGoTo10,
      handleGoTo20,
      setEvents,
      eventsComprador,
      eventsCompradorPrincipal,
      eventsVendedorPf,
      eventsVendedorPj,
    ]
  );
  const handleAdicionarParticipante = useCallback(
    (tipoParticipante, tipoPessoa) => {
      dispatch(
        actions.adicionarParticipanteComplemento.request({
          id: operacao.id,
          tipoParticipante,
          tipoPessoa,
        })
      );
    },
    [dispatch, operacao]
  );
  const handleRemoverParticipante = useCallback(
    (e, participante) => {
      e.stopPropagation();
      dispatch(
        actions.removerParticipanteComplemento.request({
          id: operacao.id,
          participante,
        })
      );
    },
    [dispatch, operacao]
  );

  const handleAcessarParticipante = useCallback(
    (e, c) => {
      e.stopPropagation();
      acessarParticipante(c);
    },
    [acessarParticipante]
  );
  const handleAcessarImovel = useCallback(
    (e) => {
      e.stopPropagation();
      handleGoTo100();
    },
    [handleGoTo100]
  );

  const possuiPendencias =
    validacao.completa.resumo.situacao === "PARCIAL" ||
    validacao.completa.resumo.situacao === "PENDENTE" ||
    imovel.fluxo.filter(
      (f) => f.situacao === "PARCIAL" || f.situacao === "PENDENTE"
    ).length > 0 ||
    compradores.reduce(
      (result, c) =>
        result ||
        c.fluxo.filter(
          (f) => f.situacao === "PARCIAL" || f.situacao === "PENDENTE"
        ).length > 0,
      false
    ) ||
    vendedores.reduce(
      (result, c) =>
        result ||
        c.fluxo.filter(
          (f) => f.situacao === "PARCIAL" || f.situacao === "PENDENTE"
        ).length > 0,
      false
    ) ||
    socios.reduce(
      (result, c) =>
        result ||
        c.fluxo.filter(
          (f) => f.situacao === "PARCIAL" || f.situacao === "PENDENTE"
        ).length > 0,
      false
    ) ||
    (operacao.tipoProduto === "I" &&
      vendedores.filter((f) => f.contaLiberacao).length === 0);

  const handleGoBack = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.DETALHES_OPERACAO, { id: operacao.id })
    );
  }, [dispatch, operacao]);

  const handleAcessarContasLiberacao = useCallback(() => {
    handleGoTo998();
  }, [handleGoTo998]);

  const handleProximaTela = useCallback(() => {
    dispatch(
      actions.enviarComplemento.request({
        id: operacao.id,
      })
    );
  }, [dispatch, operacao]);

  return (
    <HubSantander
      compradores={compradores}
      vendedores={vendedores}
      socios={socios}
      imovel={imovel}
      handleAcessarImovel={handleAcessarImovel}
      handleAcessarContasLiberacao={handleAcessarContasLiberacao}
      handleAcessarParticipante={handleAcessarParticipante}
      handleProximaTela={handleProximaTela}
      possuiPendencias={possuiPendencias}
      sending={submitting}
      operacao={operacao}
      handleAdicionarParticipante={handleAdicionarParticipante}
      handleRemoverParticipante={handleRemoverParticipante}
      refreshing={refreshing}
      adding={adding}
      deleting={deleting}
      dominios={dominios}
      handleGoBack={handleGoBack}
    />
  );
};

export default HubSantanderConnected;
