import React from "react";
import { NumberFormatBase } from "react-number-format";

const formatFunction = (value) => {
  if (!value) {
    return value;
  }
  return value.toString().toUpperCase();
};
const identity = (v) => v;

const getCaretBoundary = (formattedValue) => {
  return Array(formattedValue.length + 1).fill(true);
};

const UpperCaseTextField = React.forwardRef(({ onChange, ...other }, ref) => {
  return (
    <NumberFormatBase
      {...other}
      getInputRef={ref}
      removeFormatting={identity}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.formattedValue,
          },
        });
      }}
      format={formatFunction}
      getCaretBoundary={getCaretBoundary}
    />
  );
});

export default UpperCaseTextField;
