import React from "react";
import {
  ControlledSelect,
  ControlledTextField,
  CpfCnpjTextField,
  PhoneTextField,
} from "../../components/inputs";
import { Grid, MenuItem } from "@mui/material";
import validators from "../../utils/validators";
import { PERFIL, SITUACAO } from "../../constants/usuario.constants";

export const formValues = {
  rules: (_, tipoEdicao) => ({
    nome: validators.string({ required: tipoEdicao === "O" }),
    email: validators.string({ required: tipoEdicao === "O" }),
    situacao: validators.string({ required: tipoEdicao === "O" }),
    cpfCnpj: validators.cpfCnpj({ required: tipoEdicao === "O" }),
    telefone: validators.phone({ required: tipoEdicao === "O" }),
    perfil: validators.string({ required: tipoEdicao === "O" }),
  }),
  defaultValues: (data = {}) => ({
    nome: "",
    email: "",
    situacao: "",
    perfil: "",
    cpfCnpj: "",
    telefone: "",
    timeParceiro: "",
    parceirosSubstabelecidos: [],
    ...data,
  }),
};

const DadosUsuarioForm = ({
  isOperacao,
  times,
  formProps,
  submitting,
  dominios,
  perfil,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ControlledTextField
          label="Nome"
          name={"nome"}
          formProps={formProps}
          inputProps={{ maxLength: 100, readOnly: submitting }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="E-mail"
          name={"email"}
          inputProps={{ maxLength: 100, readOnly: true }}
          formProps={formProps}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="CPF/CNPJ"
          name={"cpfCnpj"}
          formProps={formProps}
          type={"both"}
          inputProps={{ readOnly: submitting }}
          InputProps={{
            inputComponent: CpfCnpjTextField,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="Celular"
          name={"telefone"}
          inputProps={{ readOnly: submitting }}
          formProps={formProps}
          InputProps={{
            inputComponent: PhoneTextField,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          select
          label="Situação"
          name={"situacao"}
          inputProps={{ readOnly: submitting }}
          formProps={formProps}
        >
          <MenuItem value={"A"}>{SITUACAO.A}</MenuItem>
          <MenuItem value={"B"}>{SITUACAO.B}</MenuItem>
          <MenuItem value={"C"}>{SITUACAO.C}</MenuItem>
        </ControlledTextField>
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          select
          label="Perfil"
          name={"perfil"}
          formProps={formProps}
          inputProps={{ readOnly: submitting }}
        >
          {isOperacao && (
            <MenuItem value={"OPERACAO"}>{PERFIL.OPERACAO}</MenuItem>
          )}
          {!isOperacao && (
            <MenuItem value={"PARCEIRO_MASTER"}>
              {PERFIL.PARCEIRO_MASTER}
            </MenuItem>
          )}
          {!isOperacao && (
            <MenuItem value={"FINANCEIRO"}>{PERFIL.FINANCEIRO}</MenuItem>
          )}
          {!isOperacao && (
            <MenuItem value={"OPERACIONAL"}>{PERFIL.OPERACIONAL}</MenuItem>
          )}
          {!isOperacao && <MenuItem value={"LIDER"}>{PERFIL.LIDER}</MenuItem>}
          {!isOperacao && (
            <MenuItem value={"PROMOTOR"}>{PERFIL.PROMOTOR}</MenuItem>
          )}
        </ControlledTextField>
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          select
          label="Filial"
          name={"timeParceiro"}
          formProps={formProps}
          inputProps={{
            readOnly: isOperacao || times.length === 0 || submitting,
          }}
        >
          {times.map((time) => (
            <MenuItem key={time.id} value={time.id}>
              {time.nome}
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
      {perfil === "OPERACIONAL" && (
        <Grid item xs={12}>
          <ControlledSelect
            name={"parceirosSubstabelecidos"}
            label={"Parceiros Substabelecidos"}
            multiple
            formProps={formProps}
          >
            {dominios?.parceirosSubstabelecidos.data.map((p) => (
              <MenuItem key={p.id} value={p.id}>
                {p.nomeFantasia}
              </MenuItem>
            ))}
          </ControlledSelect>
        </Grid>
      )}
    </Grid>
  );
};

export default DadosUsuarioForm;
