import CommonPage from "./CommonPage";
import { Grid, styled } from "@mui/material";
import { Card, Text, Title } from "../components";
import underConstruction from "../assets/under-construction.png";

const Image = styled("img")`
  width: 100%;
`;
const SoonPage = ({ selected }) => {
  return (
    <CommonPage selected={selected} display={"flex"}>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} lg={8}>
          <Card>
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item xs={12} md={6}>
                <Title as={"h3"} _margin_={"0 0 1rem"} _align_={"center"}>
                  Estamos Trabalhando para Melhorar!
                </Title>
                <Text _align_={"center"}>
                  🚧 Esta página está atualmente em construção. 🚧
                  <br />
                  <br />
                  Estamos empenhados em trazer a você uma experiência mais rica
                  e intuitiva. Nossa equipe de Produtos e TI está trabalhando
                  arduamente para desenvolver novas funcionalidades e melhorias
                  que irão enriquecer sua interação conosco.
                  <br />
                  <br />
                  Durante esse período de transformação, pedimos a sua paciência
                  e compreensão. Nosso objetivo é oferecer um serviço que não
                  apenas atenda, mas supere suas expectativas.
                  <br />
                  <br />
                  Fique atento(a) para atualizações e melhorias emocionantes!
                  Agradecemos seu apoio contínuo e estamos ansiosos para
                  apresentar a você todas as novidades que estão por vir.
                  <br />
                  <br />
                  Para mais informações ou dúvidas, fique à vontade para nos
                  contatar.
                  <br />
                  <br />
                  🛠️ Equipe Taya 🛠️
                </Text>
              </Grid>
              <Grid item xs={12} md={6}>
                <Image src={underConstruction} />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </CommonPage>
  );
};

export default SoonPage;
