import ResultadoSimulacaoSection from "./ResultadoSimulacaoSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getCalculadorasDisponiveis,
  getCalculadoraSelecionada,
  getResultadoCalculo,
} from "../../selectors/calculadora.selectors";
import { useCallback } from "react";
import { actions } from "../../actions/calculadora.actions";

const ResultadoSimulacaoSectionConnected = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(getResultadoCalculo);
  const selecionada = useSelector(getCalculadoraSelecionada);
  const { data: disponiveis } = useSelector(getCalculadorasDisponiveis);

  const produto = disponiveis.find(
    (p) => !!p.regras.find((g) => g.id === selecionada.id)
  );

  const handleGoBack = useCallback(() => {
    dispatch(actions.navegar(1));
  }, [dispatch]);

  const handleNext = useCallback(() => {
    dispatch(actions.navegar(3));
  }, [dispatch]);

  return (
    <ResultadoSimulacaoSection
      data={data}
      condicao={selecionada}
      produto={produto}
      handleNext={handleNext}
      handleGoBack={handleGoBack}
    />
  );
};

export default ResultadoSimulacaoSectionConnected;
