import { createSyncAction } from "../utils/actionCreators";
import { NOT_FOUND } from "redux-first-router";

export const types = {
  NOT_FOUND: NOT_FOUND,
  OPEN_WINDOW: "@@route/open-window",
  LOGIN: "@@route/login",
  LOGIN_EXTERNO: "@@route/login-externo",
  ESQUECI_SENHA: "@@route/esqueci-senha",
  TROCAR_SENHA: "@@route/trocar-senha",
  PRIMEIRO_ACESSO_PARCEIRO: "@@route/primeiro-acesso-parceiro",
  DASHBOARD: "@@route/dashboard",
  CADASTRO_PARCEIRO: "@@route/cadastro-parceiro",
  PARCEIROS: "@@route/parceiros",
  DETALHES_PARCEIRO: "@@route/detalhes-parceiro",
  CALCULADORA_INTERNA: "@@route/calculadora-interna",
  CALCULADORA_EXTERNA: "@@route/calculadora-externa",
  CLIENTES: "@@route/clientes",
  DETALHES_CLIENTE: "@@route/detalhes-cliente",
  OPERACOES: "@@route/operacoes",
  DETALHES_OPERACAO: "@@route/detalhes-operacao",
  APROVACAO_MENOR: "@@route/aprovacao-menor",
  PREENCHIMENTO_OPERACAO: "@@route/preenchimento-operacao",
  REPREENCHIMENTO_OPERACAO: "@@route/repreenchimento-operacao",
  COMPLEMENTO_OPERACAO: "@@route/complemento-operacao",
  VALIDACAO_DOCUMENTO: "@@route/validacao-documento",
  DADOS_BANCARIOS: "@@route/dados-bancarios",
  RECOMPLEMENTO_OPERACAO: "@@route/recomplemento-operacao",
  CONFIRMACAO_VALORES: "@@route/confirmacao-valores",
  SIMULADOR: "@@route/simulador",
  SIMULADOR_CONTINUACAO: "@@route/simulador-continuacao",
  CONTROLE_FINANCEIRO: "@@route/controle-financeiro",
  USUARIOS: "@@route/usuarios",
  DETALHES_USUARIO: "@@route/detalhes-usuario",
  CADASTRO_USUARIO: "@@route/cadastro-usuario",
  MATERIAL_APOIO: "@@route/material-apoio",
  ACADEMY: "@@route/academy",

  TERMOS_USO: "@@route/termos-uso",
  POLITICA_PRIVACIDADE: "@@route/politica-privacidade",
};

export const actions = {
  redirectTo: (route, params = {}, other = {}) =>
    createSyncAction(route, params, other),
  openNewWindowWith: (route, params = {}) =>
    createSyncAction(types.OPEN_WINDOW, {
      route,
      params,
    }),
  rejectTo: (route, params = {}) =>
    createSyncAction(route, params, {
      meta: {
        location: {
          kind: "redirect",
        },
      },
    }),
};
