import { useDispatch, useSelector } from "react-redux";
import AprovacaoMenorPage from "./AprovacaoMenorPage";
import { getOperacaoAprovacaoMenor } from "../../selectors/operacao.selectors";
import { useCallback, useState } from "react";
import { getRouteArguments } from "../../selectors/routes.selectors";
import { actions as workflowActions } from "../../actions/workflow.actions";
import { actions as operacaoActions } from "../../actions/operacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";
import { isAtuandoFluxoTrabalho } from "../../selectors/workflow.selectors";

const AprovacaoMenorPageConnected = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(getOperacaoAprovacaoMenor);
  const { id } = useSelector(getRouteArguments);
  const [dadosMotivo, setDadosMotivo] = useState(false);
  const acting = useSelector(isAtuandoFluxoTrabalho);

  const handleAcaoCallback = useCallback(
    (p) => {
      dispatch(operacaoActions.refresh.request({ ...p, id: data.id }));
    },
    [dispatch, data]
  );

  const handleCloseMotivoAcao = useCallback(() => {
    setDadosMotivo({
      open: false,
    });
  }, []);

  const handleOpenMotivoAcao = useCallback((values) => {
    setDadosMotivo({
      open: true,
      ...values,
    });
  }, []);

  const handleAceitarCondicoes = useCallback(() => {
    dispatch(
      workflowActions.atuarFluxoTrabalho.request({
        etapa: data.fluxoTrabalho.etapas.find((etapa) => etapa.padrao == "AM"),
        acao: data.fluxoTrabalho.etapas
          .find((etapa) => etapa.padrao == "AM")
          .acoes.find((acao) => acao.padraoAcao == "A"),
        callback: () => handleAtualizarOperacaoAprovacaoMenor("AC"),
        contexto: { id_operacao: parseInt(id) },
      })
    );
  }, [dispatch, data]);

  const handleAnaliseManual = useCallback(() => {
    handleOpenMotivoAcao({
      etapa: data.fluxoTrabalho.etapas.find((etapa) => etapa.padrao == "AM"),
      acao: data.fluxoTrabalho.etapas
        .find((etapa) => etapa.padrao == "AM")
        .acoes.find((acao) => acao.padraoAcao == "A"),
      action: workflowActions.atuarFluxoTrabalho.request,
      callback: () => handleAtualizarOperacaoAprovacaoMenor("RE"),
      initial:
        data.fluxoTrabalho.etapas
          .find((etapa) => etapa.padrao == "AM")
          .acoes.find((acao) => acao.padraoAcao == "A")
          .comentarioPadraoOperacao ?? "",
      contexto: { id_operacao: parseInt(id) },
    });
  }, [dispatch, data]);

  const handleBack = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.DETALHES_OPERACAO, { id: parseInt(id) })
    );
  });
  const handleAtualizarOperacaoAprovacaoMenor = useCallback(
    (decisao) => {
      dispatch(
        operacaoActions.atualizarOperacaoAprovacaoMenor.request({
          id: parseInt(id),
          decisao: decisao,
        })
      );
      handleAcaoCallback();
    },
    [dispatch, data]
  );

  return (
    <AprovacaoMenorPage
      loading={loading}
      data={data}
      acting={acting}
      handleAceitarCondicoes={handleAceitarCondicoes}
      handleAnaliseManual={handleAnaliseManual}
      handleBack={handleBack}
      dadosMotivo={dadosMotivo}
      handleCloseMotivoAcao={handleCloseMotivoAcao}
    />
  );
};

export default AprovacaoMenorPageConnected;
