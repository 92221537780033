import { actions, types } from "../actions/usuarios.actions";
import { types as routes } from "../actions/rotas.actions";

const initialState = {
  resumo: {
    loading: true,
    data: null,
  },
  lista: {
    loading: false,
    data: [],
  },
  detalhes: {
    loadingUsuario: false,
    loadingParceiro: false,
    submitting: false,
    data: null,
    parceiro: null,
    initing: false,
  },
  cadastro: {
    loading: false,
    submitting: false,
    listaParceiros: [],
    data: null,
  },
  dialogCompartilhamento: {
    show: false,
    data: null,
    loading: false,
  },
  dominios: {
    parceiros: {
      loading: false,
      data: [],
    },
    parceirosSubstabelecidos: {
      data: [],
    }
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarUsuarios.REQUEST:
      return {
        ...state,
        lista: {
          ...state.lista,
          loading: true,
        },
      };
    case actions.listarUsuarios.FAILURE:
      return {
        ...state,
        lista: {
          ...state.lista,
          loading: false,
        },
      };
    case actions.listarUsuarios.SUCCESS:
      return {
        ...state,
        lista: {
          ...state.lista,
          data: action.payload.response.data,
          loading: false,
        },
      };
    case types.EXIBIR_DIALOG_COMPARTILHAMENTO:
      return {
        ...state,
        dialogCompartilhamento: {
          ...state.dialogCompartilhamento,
          show: action.payload.show,
          data: action.payload.data,
          loading: false,
        },
      };
    case actions.listarParceiros.REQUEST:
      return {
        ...state,
        cadastro: {
          ...state.cadastro,
          loading: true,
        },
      };
    case actions.listarParceiros.FAILURE:
      return {
        ...state,
        cadastro: {
          ...state.cadastro,
          loading: false,
        },
      };
    case actions.listarParceiros.SUCCESS:
      return {
        ...state,
        cadastro: {
          ...state.cadastro,
          listaParceiros: action.payload.response.data.toSorted((a, b) =>
            a.nome.localeCompare(b.nome)
          ),
          loading: false,
        },
      };
    case actions.obterUsuario.REQUEST:
      return {
        ...state,
        detalhes: {
          ...initialState.detalhes,
          loadingUsuario: true,
          loadingParceiro: state.detalhes.loadingParceiro,
        },
      };
    case actions.obterUsuario.SUCCESS:
    case actions.obterUsuario.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          loadingUsuario: action.type === actions.obterUsuario.REQUEST,
          data:
            action.type === actions.obterUsuario.SUCCESS
              ? action.payload.response.data
              : null,
        },
      };
    case actions.obterParceirosSubstabelecidosPorUsuario.SUCCESS:
      return {
        ...state,
        dominios: {
         ...state.dominios,
          parceirosSubstabelecidos: {
            data: action.payload.response.data,
          },
        },
      }
    case actions.obterParceiro.REQUEST:
      return {
        ...state,
        detalhes: {
          ...initialState.detalhes,
          loadingUsuario: state.detalhes.loadingUsuario,
          loadingParceiro: true,
        },
      };
    case actions.obterParceiro.SUCCESS:
    case actions.obterParceiro.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          loadingParceiro: action.type === actions.obterParceiro.REQUEST,
          parceiro:
            action.type === actions.obterParceiro.SUCCESS
              ? action.payload.response.data
              : null,
        },
      };
    case actions.criarUsuario.REQUEST:
      return {
        ...state,
        cadastro: {
          ...state.cadastro,
          submitting: true,
        },
      };
    case actions.criarUsuario.SUCCESS:
    case actions.criarUsuario.FAILURE:
      return {
        ...state,
        cadastro: {
          ...state.cadastro,
          submitting: action.type === actions.criarUsuario.REQUEST,
          data:
            action.type === actions.criarUsuario.SUCCESS
              ? action.payload.response.data
              : null,
        },
      };
    case actions.atualizarUsuario.REQUEST:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          submitting: true,
        },
      };
    case actions.atualizarUsuario.SUCCESS:
    case actions.atualizarUsuario.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          submitting: action.type === actions.atualizarUsuario.REQUEST,
          data:
            action.type === actions.atualizarUsuario.SUCCESS
              ? action.payload.response.data
              : state.detalhes.data,
        },
      };
    case routes.USUARIOS:
      return {
        ...state,
        lista: {
          ...initialState.lista,
        },
      };
    // case routes.DETALHES_USUARIO:
    //   return {
    //     ...state,
    //     detalhes: {
    //       ...state.detalhes,
    //       loading: true,
    //     },
    //   };
    default:
      return state;
  }
};

export default reducer;
