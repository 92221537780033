import React, { useCallback, useState } from "react";
import DadosEstadoCivilSociosFintechsForm from "./DadosEstadoCivilSociosFintechsForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import {
  removeNonDigitsFromString,
  transformToDate,
} from "../../../../../utils/basic";

const DadosEstadoCivilSociosFintechsFormConnected = ({
  setStep,
  eventsOutrosPf,
  setParticipante,
  participante,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: {
      qualificacao: qualificacoes,
      estadoCivil: estadosCivis,
      regimeComunhao: regimesComunhao,
    },
  } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const { conjuge, uniaoEstavel } =
    estadosCivis.find((e) => e.id === participante?.estadoCivil?.id) ?? {};
  const [exibeConjuge, setExibeConjuge] = useState(
    (participante?.uniaoEstavel || conjuge) ?? false
  );
  const [exibeUniaoEstavel, setExibeUniaoEstavel] = useState(
    uniaoEstavel ?? false
  );

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find(
        (p) => p.id === participante.id
      );
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      setStep(0);
    },
    [setStep, setParticipante, participante]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: (form) => ({
      estadoCivil: validators.object({ required: true }),
      conjuge: {
        nome: validators.string({
          custom: {
            requiredIf: (value) => {
              const uniaoEstavel = form.getValues("uniaoEstavel");

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== "")
                return true;
              return "Campo obrigatório";
            },
            nomeCompleto: (value) =>
              !value ||
              (value.indexOf(" ") > 0 &&
                value.substring(value.indexOf(" ") + 1).length > 0) ||
              "Nome informado deve ser completo",
          },
        }),
        cpfCnpj: validators.cpf({
          custom: {
            requiredIf: (value) => {
              const uniaoEstavel = form.getValues("uniaoEstavel");

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== "")
                return true;
              return "Campo obrigatório";
            },
          },
        }),
        dataNascimento: validators.date({
          custom: {
            requiredIf: (value) => {
              const uniaoEstavel = form.getValues("uniaoEstavel");

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== "")
                return true;
              return "Campo obrigatório";
            },
          },
        }),
      },
    }),
    initialValues: useCallback(
      () => ({
        estadoCivil: null,
        uniaoEstavel: false,
        regimeComunhao: null,
        ...participante,
        conjuge: {
          nome: "",
          cpfCnpj: "",
          dataNascimento: "",
          email: "",
          telefone: "",
          qualificacao: null,
          compoeQuadroSocietario: false,
          participacaoSocietaria: "",
          ...participante?.conjuge,
        },
      }),
      [participante]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-estado-civil-socios",
            participante: {
              id: participante?.id,
              idEstadoCivil: values.estadoCivil?.id,
              uniaoEstavel: exibeUniaoEstavel && values.uniaoEstavel,
              idRegimeComunhao: !!exibeConjuge
                ? values.regimeComunhao?.id
                : null,
              conjuge: !!exibeConjuge
                ? {
                    nome: values.conjuge.nome,
                    cpfCnpj: removeNonDigitsFromString(values.conjuge.cpfCnpj),
                    dataNascimento: transformToDate(
                      values.conjuge.dataNascimento
                    ),
                    telefone: values.conjuge.telefone,
                    email: values.conjuge.email,
                    idQualificacao: values.conjuge.qualificacao?.id,
                    compoeQuadroSocietario:
                      values.conjuge.compoeQuadroSocietario,
                    participacaoSocietaria: values.conjuge
                      .compoeQuadroSocietario
                      ? values.conjuge.participacaoSocietaria
                      : null,
                  }
                : null,
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [
        dispatch,
        participante,
        callback,
        operacao,
        exibeConjuge,
        exibeUniaoEstavel,
      ]
    ),
  });
  const { setValue } = formProps;

  const handleEstadoCivilChange = useCallback(
    (v) => {
      const vaiMudar =
        !exibeConjuge && !exibeUniaoEstavel
          ? true
          : exibeUniaoEstavel ^ (v?.uniaoEstavel ?? false);

      if (vaiMudar) {
        setValue("uniaoEstavel", false);
        setExibeUniaoEstavel(v?.uniaoEstavel ?? false);
        setExibeConjuge(v?.conjuge ?? false);
      }
    },
    [
      setExibeConjuge,
      setExibeUniaoEstavel,
      exibeUniaoEstavel,
      exibeConjuge,
      setValue,
    ]
  );
  const handleUniaoEstavelChange = useCallback(
    (e) => {
      setExibeConjuge(e.target.checked);
    },
    [setExibeConjuge]
  );

  return (
    <DadosEstadoCivilSociosFintechsForm
      {...otherProps}
      estadosCivis={estadosCivis}
      qualificacoes={qualificacoes}
      events={eventsOutrosPf}
      fluxo={participante.fluxo}
      formProps={formProps}
      handleEstadoCivilChange={handleEstadoCivilChange}
      handleSubmit={handleSubmit}
      exibeConjuge={exibeConjuge}
      exibeUniaoEstavel={exibeUniaoEstavel}
      handleUniaoEstavelChange={handleUniaoEstavelChange}
      regimesComunhao={regimesComunhao}
      submitting={submitting}
    />
  );
};

export default DadosEstadoCivilSociosFintechsFormConnected;
