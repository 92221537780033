import React from "react";
import DadosBasicosCompradorGalleriaFormConnected from "./comprador/DadosBasicosCompradorGalleriaForm.connected";
import HubGalleriaConnected from "./HubGalleria.connected";
import DadosEstadoCivilCompradorGalleriaFormConnected from "./comprador/DadosEstadoCivilCompradorGalleriaForm.connected";
import DadosEnderecoImovelGalleriaFormConnected from "./imovel/DadosEnderecoImovelGalleriaForm.connected";
import OutrasInformacoesImovelGalleriaFormConnected from "./imovel/OutrasInformacoesImovelGalleriaForm.connected";
import DocumentosFormConnected from "./documentos/DocumentosForm.connected";

const FluxoAquisicaoGalleria = (props) => {
  return (
    <>
      {props.step === 0 && <HubGalleriaConnected {...props} />}
      {props.step === 1 && (
        <DadosBasicosCompradorGalleriaFormConnected {...props} />
      )}
      {props.step === 2 && (
        <DadosEstadoCivilCompradorGalleriaFormConnected {...props} />
      )}
      {props.step === 100 && (
        <DadosEnderecoImovelGalleriaFormConnected {...props} />
      )}
      {props.step === 101 && (
        <OutrasInformacoesImovelGalleriaFormConnected {...props} />
      )}
      {props.step === 999 && <DocumentosFormConnected {...props} />}
    </>
  );
};

export default FluxoAquisicaoGalleria;
