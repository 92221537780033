import { Grid, InputAdornment, styled } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  GoBack,
  MenuItem,
  Title,
} from "../../../../components";
import ControlledAutocomplete from "../../../../components/inputs/ControlledAutocomplete";
import {
  ControlledTextField,
  CpfCnpjTextField,
  DateTextField,
  PhoneTextField,
} from "../../../../components/inputs";
import { estados } from "../../../../constants/generic.constants";
import Stepper from "../Stepper";
import { HorizontalLoader } from "../../../../components/loaders";
import UpperCaseTextField from "../../../../components/inputs/UpperCaseTextField";
import { InfoOutlined } from "@mui/icons-material";

const Disclaimer = styled(Title)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.25rem 0 0 0;
  color: ${({ theme }) => theme.colors.error._500};
`;

const DadosBasicosBradescoForm = ({
  fluxo,
  tipoPessoa,
  nacionalidades,
  generos,
  documentosPessoais,
  formProps,
  handleSubmit,
  cpfCnpjDisabled,
  handleCpfCnpjChange,
  searching,
  submitting,
  events,
  handleGoTo0,
  isEstrangeiro,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={handleGoTo0}
          text={"Preencha as informações do comprador"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={0} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Dados pessoais</Title>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label={tipoPessoa === "J" ? "CNPJ" : "CPF"}
                      name={"cpfCnpj"}
                      disabled={cpfCnpjDisabled}
                      formProps={formProps}
                      onBlur={handleCpfCnpjChange}
                      type={tipoPessoa === "F" ? "cpf" : "cnpj"}
                      InputProps={{
                        inputComponent: CpfCnpjTextField,
                        endAdornment: !!searching && (
                          <InputAdornment position="end">
                            <HorizontalLoader size={28} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ControlledTextField
                      label="Nome Completo"
                      name={"nome"}
                      inputProps={{ maxLength: 100 }}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: UpperCaseTextField,
                      }}
                    />
                  </Grid>
                  {tipoPessoa === "F" && (
                    <Grid item xs={12} md={4}>
                      <ControlledTextField
                        name={"dataNascimento"}
                        label={"Data de Nascimento"}
                        formProps={formProps}
                        InputProps={{
                          inputComponent: DateTextField,
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={8}>
                    <ControlledAutocomplete
                      label="Nacionalidade"
                      domain={nacionalidades}
                      idField={"id"}
                      labelField={"descricao"}
                      name={"nacionalidade"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledAutocomplete
                      label="Gênero"
                      domain={generos}
                      idField={"id"}
                      labelField={"descricao"}
                      name={"genero"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Documento</Title>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledAutocomplete
                      label="Documento"
                      domain={documentosPessoais}
                      idField={"id"}
                      labelField={"descricao"}
                      name={"documentoPessoal"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="Número do Documento"
                      name={"numeroDocumentoPessoal"}
                      inputProps={{ maxLength: 40 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label="Órgão Expedidor"
                      name={"orgaoDocumentoPessoal"}
                      inputProps={{ maxLength: 40 }}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: UpperCaseTextField,
                      }}
                    />
                  </Grid>
                  {!isEstrangeiro && (
                    <Grid item xs={12} md={4}>
                      <ControlledTextField
                        label="Estado da Expedição"
                        name={"ufOrgaoDocumentoPessoal"}
                        select
                        formProps={formProps}
                      >
                        {estados.map((state) => (
                          <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </ControlledTextField>
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      name={"dataExpedicaoDocumentoPessoal"}
                      label={"Data de Expedição"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: DateTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ControlledTextField
                      name={"nomeMae"}
                      label={"Nome da Mãe"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: UpperCaseTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Informações de contato</Title>
                    <Disclaimer as={"h5"}>
                      <InfoOutlined />
                      Preencher as informações de contato reais de seu cliente
                      aumentam as chances de ele ter o crédito aprovado. Algumas
                      instituições (Inter e C6, no momento) estão reprovando
                      clientes com informações de contato incorretas.
                    </Disclaimer>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label="Celular"
                      name="telefone"
                      formProps={formProps}
                      InputProps={{
                        inputComponent: PhoneTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label="Telefone Residencial"
                      name="telefoneResidencial"
                      formProps={formProps}
                      InputProps={{
                        inputComponent: PhoneTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledTextField
                      label="E-mail"
                      name="email"
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosBasicosBradescoForm;
