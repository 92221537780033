import FluxoAquisicaoSantander from "./FluxoAquisicaoSantander";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getPreenchimentoOperacao } from "../../../../selectors/operacao.selectors";

const FluxoAquisicaoSantanderConnected = () => {
  const [step, setStep] = useState(0);
  const [comprador, setComprador] = useState(null);

  const {
    operacao: { participantes, detalhe },
    validacao,
  } = useSelector(getPreenchimentoOperacao);

  const [correntista, setCorrentista] = useState(detalhe?.correntista);

  const compradores = useMemo(
    () =>
      participantes
        .filter((p) => p.tipoParticipante === "CO")
        .map((c) => ({
          ...c,
          fluxo: validacao?.[
            correntista ? "correntista" : "completa"
          ]?.participantes?.find((f) => f.id === c?.id)?.etapas,
        }))
        .sort((c1, c2) =>
          c1.principal
            ? -1
            : c2.principal
            ? 1
            : (c1.nome || "").localeCompare(c2.nome || "")
        ),
    [participantes, validacao, correntista]
  );

  const handleGoTo0 = useCallback(() => {
    setStep(0);
  }, [setStep]);
  const handleGoTo1 = useCallback(() => {
    setStep(1);
  }, [setStep]);
  const handleGoTo2 = useCallback(() => {
    setStep(2);
  }, [setStep]);
  const handleGoTo3 = useCallback(() => {
    setStep(3);
  }, [setStep]);
  const handleGoTo4 = useCallback(() => {
    setStep(4);
  }, [setStep]);
  const handleGoTo10 = useCallback(() => {
    setStep(10);
  }, [setStep]);
  const handleGoTo995 = useCallback(() => {
    setStep(995);
  }, [setStep]);
  const handleGoTo996= useCallback(() => {
    setStep(996);
  }, [setStep]);
  const handleGoTo999 = useCallback(() => {
    setStep(999);
  }, [setStep]);
  const eventsCorrentista = [handleGoTo1, handleGoTo10];
  const eventsNaoCorrentista = [
    handleGoTo1,
    handleGoTo2,
    handleGoTo3,
    handleGoTo4,
  ];

  return (
    <FluxoAquisicaoSantander
      step={step}
      setStep={setStep}
      comprador={comprador}
      setComprador={setComprador}
      correntista={correntista}
      setCorrentista={setCorrentista}
      compradores={compradores}
      eventsCorrentista={eventsCorrentista}
      eventsNaoCorrentista={eventsNaoCorrentista}
      handleGoTo0={handleGoTo0}
      handleGoTo1={handleGoTo1}
      handleGoTo2={handleGoTo2}
      handleGoTo3={handleGoTo3}
      handleGoTo4={handleGoTo4}
      handleGoTo10={handleGoTo10}
      handleGoTo995={handleGoTo995}
      handleGoTo996={handleGoTo996}
      handleGoTo999={handleGoTo999}
    />
  );
};

export default FluxoAquisicaoSantanderConnected;
