import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  BUSCAR: "@@simulador/BUSCAR",
  PARCEIROS: "@@simulador/PARCEIROS",
  SELECIONAR: "@@simulador/SELECIONAR",
  RECUPERAR_CLIENTE: "@@simulador/RECUPERAR_CLIENTE",
  DADOS_CLIENTE: "@@simulador/DADOS_CLIENTE",
  ATUALIZAR_CLIENTE:"@@simulador/ATUALIZAR_CLIENTE",
  RECUPERAR_LEAD: "@@simulador/RECUPERAR_LEAD",
  OBTER_LEAD: "@@simulador/OBTER_LEAD",
  RESTAURAR_LEAD: "@@simulador/RESTAURAR_LEAD",
  NAVEGAR: "@@simulador/NAVEGAR",
  VALIDAR_PARCEIRO: "@@simulador/VALIDAR_PARCEIRO",
  VALIDAR_SELECIONADAS: "@@simulador/VALIDAR_SELECIONADAS",
  CALCULAR: "@@simulador/CALCULAR",
  RECALCULAR: "@@simulador/RECALCULAR",
  COMECAR_PROPOSTA: "@@simulador/COMECAR_PROPOSTA",
  EXTRATO: "@@simulador/EXTRATO",
};

export const actions = {
  navegar: (payload) => createSyncAction(types.NAVEGAR, payload),
  buscarSimuladores: createAsyncAction(types.BUSCAR),
  validarParceiro: createAsyncAction(types.VALIDAR_PARCEIRO),
  recuperarCliente: createAsyncAction(types.RECUPERAR_CLIENTE),
  selecionarSimulador: (payload) => createSyncAction(types.SELECIONAR, payload),
  armazenarDadosCliente: (payload) =>
    createSyncAction(types.DADOS_CLIENTE, payload),
  atualizarDadosCliente: createAsyncAction(types.ATUALIZAR_CLIENTE),
  calcular: createAsyncAction(types.CALCULAR),
  recuperarLead: createAsyncAction(types.RECUPERAR_LEAD),
  recalcular: createAsyncAction(types.RECALCULAR),
  validarRegrasSelecionadas: createAsyncAction(types.VALIDAR_SELECIONADAS),
  extratoSimulacao: createAsyncAction(types.EXTRATO),
  comecarProposta: createAsyncAction(types.COMECAR_PROPOSTA),
  obterParceiros: createAsyncAction(types.PARCEIROS),
  obterLead: createAsyncAction(types.OBTER_LEAD),
  restaurarLead: (payload) => createSyncAction(types.RESTAURAR_LEAD, payload),
};
