import { Grid, styled } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  GoBack,
  IconButton,
  Title,
  Tooltip,
} from "../../../../components";
import { Add, Apartment, Home, Person } from "@mui/icons-material";
import formatters from "../../../../utils/formatters";
import { Field as OtherField } from "../../../../components/presentation";
import ItemOperacao from "./ItemOperacao";

const MAX_COMPRADORES = 2;

const CompradorContainer = styled("div")`
  width: calc(100% - 1rem);
  padding: 1rem 0.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.grey._50};
`;
const Disclaimer = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  text-align: center;
  display: block;
  color: ${({ theme }) => theme.colors.grey._500};
`;
const StyledIconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.grey._100};
  color: ${({ theme }) => theme.colors.grey._700};

  &.Mui-disabled {
    background-color: ${({ theme }) => theme.colors.grey._100};
  }
  & .MuiSvgIcon-root {
    font-size: 1rem;
  }

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    & .MuiSvgIcon-root {
			font-size: 1.5rem;
		}
  }`}
`;

const AlertText = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.error._600};
  text-align: center;
`;

const ResumoComprador = ({ data }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Dados Pessoais
            </Title>
          </Grid>
          <Grid item xs={6} md={3}>
            <OtherField
              label={"Data de Nascimento"}
              value={data.dataNascimento}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <OtherField label={"Renda"} value={data.renda} type={"currency"} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Informações de Contato
            </Title>
          </Grid>
          <Grid item xs={6} md={3}>
            <OtherField label={"Celular"} value={data.telefone} />
          </Grid>
          <Grid item xs={12} md={6}>
            <OtherField label={"E-mail"} value={data.email} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const HubC6 = ({
  imovel,
  compradores,
  handleGoBack,
  handleAcessarParticipante,
  handleAcessarImovel,
  handleAdicionarParticipante,
  handleRemoverParticipante,
  handleEnviarAnaliseCredito,
  adding,
  deleting,
  refreshing,
  submitting,
  possuiPendencias,
  mensagens,
  sending,
  dominios,
  desenquadrado,
  handleReenquadrarOperacao
}) => {
  return (
    <Grid item container justifyContent={"center"} spacing={3}>
      <Grid item container spacing={3} direction={"column"} maxWidth={704}>
        <GoBack
          handleClick={handleGoBack}
          text={
            "Preencha todas as informações sobre o imóvel, compradores e demais participantes"
          }
        />
        <Grid item container justifyContent={"center"}>
          <Grid item>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Title as={"h5"}>
                    {compradores.length > 1 ? "Compradores" : "Comprador"}
                  </Title>
                </Grid>
                <Grid item xs={12}>
                  {compradores.map((c) => {
                    return (
                      <ItemOperacao
                        key={c.id}
                        fluxo={c.fluxo}
                        submitting={submitting}
                        refreshing={refreshing}
                        deleting={deleting}
                        adding={adding}
                        icon={c.tipoPessoa === "F" ? <Person /> : <Apartment />}
                        title={c.nome ?? "(Nome não informado)"}
                        subtitle={formatters.strings.cpfCnpj(
                          c.cpfCnpj,
                          c.tipoPessoa
                        )}
                        titleRemover={"Remover comprador"}
                        isChildren={!!c.participanteOrigem}
                        canRemove={!c.principal}
                        handleRemover={(e) =>
                          handleRemoverParticipante(e, c.id)
                        }
                        handleEditar={(e) => handleAcessarParticipante(e, c)}
                        details={
                          <ResumoComprador data={c} dominios={dominios} />
                        }
                      />
                    );
                  })}
                  {compradores.length < MAX_COMPRADORES && (
                    <CompradorContainer>
                      <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Grid item>
                          <Disclaimer>
                            Gostaria de adicionar outro comprador pra compor
                            renda?
                          </Disclaimer>
                        </Grid>
                        <Grid item width={56}>
                          <Tooltip arrow title={"Adicionar comprador"}>
                            <StyledIconButton
                              disabled={
                                submitting || deleting || refreshing || adding
                              }
                              loading={adding || refreshing}
                              loaderColor={"grey"}
                              onClick={() =>
                                handleAdicionarParticipante("CO", "F")
                              }
                            >
                              <Add />
                            </StyledIconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </CompradorContainer>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Title as={"h5"}>Imóvel</Title>
                </Grid>
                <Grid item xs={12}>
                  <ItemOperacao
                    fluxo={imovel.fluxo}
                    submitting={submitting}
                    refreshing={refreshing}
                    deleting={deleting}
                    adding={adding}
                    icon={<Home />}
                    title={
                      formatters.strings.address(imovel.endereco) ??
                      "(Endereço não informado)"
                    }
                    canRemove={false}
                    handleEditar={handleAcessarImovel}
                    details={<></>}
                  />
                </Grid>
                {mensagens.length > 0 && (
                  <Grid item xs={12}>
                    {mensagens.map((mensagem) => (
                      <AlertText key={mensagem}>{mensagem}</AlertText>
                    ))}
                  </Grid>
                )}
                <Grid item container justifyContent={"center"} mt={1}>
                  <Grid item xs={12} md={8}>
                    {
                      !!desenquadrado ? (
                        <Button
                          disabled={
                            submitting ||
                            adding ||
                            refreshing ||
                            deleting
                          }
                          loading={sending}
                          fullWidth
                          onClick={(e)=>handleReenquadrarOperacao(e)}
                        >
                          REENQUADRAR OPERAÇÃO
                        </Button>
                      ):(
                        <Button
                          disabled={
                            possuiPendencias ||
                            submitting ||
                            adding ||
                            refreshing ||
                            deleting
                          }
                          fullWidth
                          loading={sending}
                          onClick={handleEnviarAnaliseCredito}
                        >
                          ENVIAR PARA ANÁLISE DE CRÉDITO
                        </Button>
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HubC6;
