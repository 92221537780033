import { Grid, styled } from "@mui/material";
import React from "react";
import { Button, Card, Form, GoBack, Title } from "../../../../../components";
import {
  ControlledRadioGroup,
  ControlledTextField,
  CurrencyTextField,
  IntegerTextField,
  Radio,
} from "../../../../../components/inputs";
import Stepper from "../../../preenchimento/Stepper";
import ControlledAutocomplete from "../../../../../components/inputs/ControlledAutocomplete";
import { transientOptions } from "../../../../../styles/theme";
import formatters from "../../../../../utils/formatters";
import { CurrencyExchange } from "@mui/icons-material";

const DisclaimerContainer = styled("div", transientOptions)`
  border-radius: ${({ theme }) => theme.borderRadiuses.disclaimer};
  border: 1px dashed
    ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._300};
  background-color: ${({ theme, _color_ = "primary" }) =>
    theme.colors[_color_]._50};
  color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._600};
  padding: 1rem 1.5rem;
  margin: 0.75rem 0 0;
`;
const Disclaimer = styled("p", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 500;
  color: inherit;
  margin: 0;
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const OutrasInformacoesImovelFintechsForm = ({
  fluxo,
  formProps,
  handleSubmit,
  submitting,
  events,
  subTiposImovel,
  proprietarios,
  makeGoTo,
}) => {
  const quantidadeVagas = formProps.watch("quantidadeVagas");
  const imovelAlugado = formProps.watch("imovelAlugado");
  const imovelFinanciado = formProps.watch("imovelFinanciado");
  const possuiDebitos = formProps.watch("possuiDebitos");
  const subTipoImovel = formProps.watch("subTipoImovel");
  const valorImovel = formProps.watch("valorImovel");

  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={makeGoTo(100)}
          text={"Preencha as informações do imóvel"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={1} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Características do Imóvel</Title>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledAutocomplete
                      label="Tipo do Imóvel"
                      name={"subTipoImovel"}
                      domain={subTiposImovel}
                      idField={"id"}
                      labelField={"descricao"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="Valor do Imóvel"
                      name={"valorImovel"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: CurrencyTextField,
                      }}
                      inputProps={{
                        allowNegative: false,
                      }}
                    />
                  </Grid>
                  {valorImovel > 0 && subTipoImovel?.ltv?.ltv > 0 && (
                    <Grid item xs={12}>
                      <DisclaimerContainer>
                        <Disclaimer>
                          <CurrencyExchange /> Crédito Máximo estimado:{" "}
                          <b>
                            R${" "}
                            {formatters.numbers.currency(
                              (valorImovel * subTipoImovel?.ltv?.ltv) / 100
                            )}
                          </b>
                        </Disclaimer>
                      </DisclaimerContainer>
                    </Grid>
                  )}
                  <Grid item container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <ControlledTextField
                        label="Quantas vagas o imóvel tem?"
                        name={"quantidadeVagas"}
                        formProps={formProps}
                        InputProps={{
                          inputComponent: IntegerTextField,
                        }}
                        inputProps={{
                          maxLength: 3,
                          allowNegative: false,
                        }}
                      />
                    </Grid>
                    {quantidadeVagas > 0 && (
                      <Grid item xs={12} md={8}>
                        <ControlledRadioGroup
                          label="Cada vaga tem um número de matrícula própria?"
                          name={"vagaAutonoma"}
                          row
                          tooltip={
                            "Essa informação pode ser vista na matrícula do imóvel"
                          }
                          formProps={formProps}
                        >
                          <Radio value={true} label={"Sim"} />
                          <Radio value={false} label={"Não"} />
                        </ControlledRadioGroup>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledAutocomplete
                      label={"Quem tem a propriedade do imóvel?"}
                      name={"proprietarios"}
                      formProps={formProps}
                      domain={proprietarios}
                      idField={"id"}
                      labelField={"descricao"}
                      autocompleteProps={{
                        multiple: true,
                        disableCloseOnSelect: true,
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Outras informações</Title>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <ControlledRadioGroup
                        label="O imóvel está alugado?"
                        name={"imovelAlugado"}
                        row
                        formProps={formProps}
                      >
                        <Radio value={true} label={"Sim"} />
                        <Radio value={false} label={"Não"} />
                      </ControlledRadioGroup>
                    </Grid>
                    {imovelAlugado + "" === "true" && (
                      <Grid item xs={12} md={6}>
                        <ControlledTextField
                          label="Valor do Aluguel"
                          name={"valorAluguel"}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: CurrencyTextField,
                          }}
                          inputProps={{
                            allowNegative: false,
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={12}>
                      <ControlledRadioGroup
                        label="O imóvel está financiado?"
                        name={"imovelFinanciado"}
                        row
                        formProps={formProps}
                      >
                        <Radio value={true} label={"Sim"} />
                        <Radio value={false} label={"Não"} />
                      </ControlledRadioGroup>
                    </Grid>
                    {imovelFinanciado + "" === "true" && (
                      <>
                        <Grid item xs={12} md={6}>
                          <ControlledTextField
                            label="Instituição Bancária"
                            name={"bancoFinanciamento"}
                            formProps={formProps}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ControlledTextField
                            label="Saldo Devedor"
                            name={"saldoDevedorFinanciamento"}
                            formProps={formProps}
                            InputProps={{
                              inputComponent: CurrencyTextField,
                            }}
                            inputProps={{
                              allowNegative: false,
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={12}>
                      <ControlledRadioGroup
                        label="O imóvel possui débitos?"
                        name={"possuiDebitos"}
                        row
                        formProps={formProps}
                      >
                        <Radio value={true} label={"Sim"} />
                        <Radio value={false} label={"Não"} />
                      </ControlledRadioGroup>
                    </Grid>
                    {possuiDebitos + "" === "true" && (
                      <>
                        <Grid item xs={12} md={6}>
                          <ControlledTextField
                            label="Débitos de IPTU"
                            name={"valorDebitosIptu"}
                            formProps={formProps}
                            InputProps={{
                              inputComponent: CurrencyTextField,
                            }}
                            inputProps={{
                              allowNegative: false,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ControlledTextField
                            label="Débitos de Condomínio"
                            name={"valorDebitosCondominio"}
                            formProps={formProps}
                            InputProps={{
                              inputComponent: CurrencyTextField,
                            }}
                            inputProps={{
                              allowNegative: false,
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OutrasInformacoesImovelFintechsForm;
