import { CloseSharp } from "@mui/icons-material";
import { Dialog, DialogContent, Grid, IconButton, styled } from "@mui/material";
import { Button, MenuItem, Skeleton, Title } from "../../components";
import { ControlledSelect, Select } from "../../components/inputs";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    width: 100%;
  }
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;
const Text = styled("p")`
  margin: 2px;
`;

const AtribuirResponsavelDialog = ({
  handleClose,
  open,
  data,
  submitting,
  formProps,
  handleSubmit,
  loading,
}) => {
  return (
    <StyledDialog
      onClose={() => handleClose()}
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
    >
      <DialogContent>
        <CloseIconButton onClick={handleClose}>
          <CloseSharp />
        </CloseIconButton>
        <Title as={"h2"} _margin_={"0 0 1rem 0"}>
          Atribuir Responsável
        </Title>
        {!loading ? (
          <>
            <ControlledSelect
              name={"idUsuarioResponsavel"}
              formProps={formProps}
              label={"Selecione o comercial responsável dessa operação:"}
            >
              {data?.map((state) => (
                <MenuItem key={state.id} value={state.id}>
                  {state.nome}
                </MenuItem>
              ))}
            </ControlledSelect>
            <Grid container marginTop={2}>
              <Button onClick={handleSubmit} fullWidth loading={loading}>
                <Text>Salvar</Text>
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Skeleton
              variant={"rounded"}
              height={70}
              sx={{ borderRadius: 1 }}
            />
            <Skeleton
              variant={"rounded"}
              height={40}
              sx={{ borderRadius: 1, marginTop: 1 }}
            />
          </>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default AtribuirResponsavelDialog;
