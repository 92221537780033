import { createSelector } from "reselect";

export const getSimulador = (state) => state.simulador;
export const getPaginaAtual = createSelector(
  getSimulador,
  (state) => state.atual
);
export const isCarregandoLeadGeral = createSelector(
  getSimulador,
  (state) => state.gettingLead
);
export const isOperacaoFixa = createSelector(
  getSimulador,
  (state) => !!state.operacao
);
export const getSimuladoresDisponiveis = createSelector(
  getSimulador,
  (state) => state.basesDisponiveis
);
export const getSimuladorSelecionado = createSelector(
  getSimulador,
  (state) => state.basesDisponiveis.selecionada
);
export const isProcurandoCliente = createSelector(
  getSimulador,
  (state) => state.dadosCliente.searching
);
export const getDadosCliente = createSelector(
  getSimulador,
  (state) => state.dadosCliente.data
);
export const isProcurandoLead = createSelector(
  getSimulador,
  (state) => state.dadosCliente.searchingLead
);
export const getResultadoSimulador = createSelector(
  getSimulador,
  (state) => state.calculo
);
export const getDominioParceiros = createSelector(
  getSimulador,
  (state) => state.dominios.parceiros
);
export const getRegrasSelecionadas = createSelector(
  getSimulador,
  (state) => state.regrasSelecionadas
);
export const isValidandoRegrasSelecionadas = createSelector(
  getRegrasSelecionadas,
  (state) => state.validating
);
export const getRegrasSelecionadasCompletas = createSelector(
  getRegrasSelecionadas,
  (state) => {
    const { entrada, resultado } = state;

    return entrada.map((e) => {
      const r = resultado.find((a) => a.id === e.id);

      return {
        ...e,
        ...r,
      };
    })
      .sort((a, b) => a.tempoResposta > b.tempoResposta ? 1 : -1);
  }
);
export const isCriandoRegrasSelecionadas = createSelector(
  getRegrasSelecionadas,
  (state) => state.submitting
);
