import React, { useState } from "react";
import { Button, Form, Link, Skeleton } from "../../components";
import { Grid, styled } from "@mui/material";
import { transientOptions } from "../../styles/theme";
import Switch from "../../components/inputs/Switch";
import DadosEmpresaForm from "./DadosEmpresaForm";
import DadosRepresentanteForm from "./DadosRepresentanteForm";

const CenteredTitle = styled("h4", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secondary._300};
  text-align: center;
  margin: 0 0 0.5rem 0;
`;
const BackButton = styled(Button, transientOptions)`
  margin-top: 1rem;
`;
const NextButton = styled(Button, transientOptions)`
  margin-top: 0.5rem;
`;
const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary._600};
`;
const Row = styled("div")`
  margin: 0.5rem 0;
`;
const Dot = styled("div")`
  border-radius: 200px;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: ${({ theme, _active_ }) =>
    _active_ ? theme.colors.success._600 : theme.colors.grey._200};
`;

const CadastroParceiroForm = ({
  loading,
  submitting,
  currentPage,
  formProps,
  firstFormProps,
  firstHandleSubmit,
  handleSubmit,
  handleBackToFirstPage,
  handleUserTermsClick,
  handlePrivacyPolicyClick,
}) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  if (!!loading) {
    return (
      <>
        <Grid item xs={12}>
          <Skeleton
            variant={"text"}
            width={150}
            height={30}
            _border_={"4px"}
            _margin_={"0.7rem 0 1rem 0"}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton
              variant={"text"}
              width={100}
              height={24}
              _border_={"4px"}
              _margin_={"0 0 0.5rem 0"}
            />
            <Skeleton
              variant={"rounded"}
              width={"100%"}
              height={56}
              _border_={"0.75rem"}
            />
          </Grid>
          <Grid item xs={12}>
            <Skeleton
              variant={"text"}
              width={100}
              height={24}
              _border_={"4px"}
              _margin_={"0 0 0.5rem 0"}
            />
            <Skeleton
              variant={"rounded"}
              width={"100%"}
              height={56}
              _border_={"0.75rem"}
            />
          </Grid>
          <Grid item xs={12}>
            <Skeleton
              variant={"text"}
              width={100}
              height={24}
              _border_={"4px"}
              _margin_={"0 0 0.5rem 0"}
            />
            <Skeleton
              variant={"rounded"}
              width={"100%"}
              height={56}
              _border_={"0.75rem"}
            />
          </Grid>
          <Grid item xs={12}>
            <Skeleton
              variant={"text"}
              width={100}
              height={24}
              _border_={"4px"}
              _margin_={"0 0 0.5rem 0"}
            />
            <Skeleton
              variant={"rounded"}
              width={"100%"}
              height={56}
              _border_={"0.75rem"}
            />
          </Grid>
          <Grid item xs={12}>
            <Skeleton
              variant={"text"}
              width={100}
              height={24}
              _border_={"4px"}
              _margin_={"0 0 0.5rem 0"}
            />
            <Skeleton
              variant={"rounded"}
              width={"100%"}
              height={56}
              _border_={"0.75rem"}
            />
          </Grid>
          <Grid item xs={12}>
            <Skeleton
              variant={"rounded"}
              width={"100%"}
              height={36}
              _border_={"10rem"}
              _margin_={"0.5rem 0 0 0"}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Form
        onSubmit={(v) =>
          currentPage === 0 ? firstHandleSubmit(v) : handleSubmit(v)
        }
      >
        <Grid
          item
          flexDirection={"row"}
          display={"flex"}
          justifyContent={"center"}
          xs={12}
        >
          <Dots current={currentPage} />
        </Grid>
        {currentPage === 0 && (
          <>
            <Grid item xs={12}>
              <CenteredTitle>Dados da empresa</CenteredTitle>
            </Grid>
            <DadosEmpresaForm formProps={firstFormProps} isSignUp={true} />
            <Grid item xs={12} md={12}>
              <NextButton fullWidth type={"submit"}>
                PRÓXIMA PÁGINA
              </NextButton>
            </Grid>
          </>
        )}
        {currentPage === 1 && (
          <>
            <Grid item xs={12}>
              <CenteredTitle>Dados do Representante</CenteredTitle>
            </Grid>
            <DadosRepresentanteForm formProps={formProps} isSignUp={true} />
            <Grid item xs={12} md={12}>
              <Row>
                <Switch
                  value={acceptedTerms}
                  onChange={(v) => {
                    setAcceptedTerms(!acceptedTerms);
                  }}
                  label={
                    <>
                      Li e concordo com os{" "}
                      <StyledLink onClick={handleUserTermsClick}>
                        Termos de Uso
                      </StyledLink>{" "}
                      e{" "}
                      <StyledLink onClick={handlePrivacyPolicyClick}>
                        Política de Privacidade
                      </StyledLink>
                      .
                    </>
                  }
                  labelPlacement={"end"}
                  margin={"none"}
                />
              </Row>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                loading={submitting}
                disabled={!acceptedTerms}
                type={"submit"}
                fullWidth
              >
                ENVIAR
              </Button>
              <BackButton
                variant={"text"}
                fullWidth
                onClick={handleBackToFirstPage}
              >
                VOLTAR
              </BackButton>
            </Grid>
          </>
        )}
      </Form>
    </>
  );
};

const Dots = ({ current }) => {
  return Array(2)
    .fill("1")
    .map((v, i) => <Dot key={v + i} _active_={i === current} />);
};

export default CadastroParceiroForm;
