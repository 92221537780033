import React from "react";
import { Button, Card, Form, MenuItem, Title } from "../../components";
import { ControlledTextField } from "../../components/inputs";
import ControlledAutocomplete from "../../components/inputs/ControlledAutocomplete";
import {
  Drawer,
  Grid,
  Paper,
  styled,
  TableBody,
  TableContainer as MTableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { BodyTableRow, SizedTableCell, Table } from "../../components/tables";
import formatters from "../../utils/formatters";
import { transientOptions } from "../../styles/theme";
import { logos } from "../../constants/generic.constants";

const StyledDrawer = styled(Drawer, transientOptions)`
  & .MuiDrawer-paper {
    overflow-y: visible;
    border-radius: 1rem 1rem 0 0;
    left: 0;
  }

  ${({ theme, _isOpen_ }) => `${theme.breakpoints.up("sm")} {
  	& .MuiDrawer-paper {
			left: ${_isOpen_ ? theme.sizes.lateralMenu : theme.sizes.lateralMenuCollapsed};
		}
	`}
`;

const NormalText = styled("span")`
  font-weight: 300;
`;

const TableContainer = styled(MTableContainer)`
  box-shadow: none;
`;

const Logo = styled("img")`
  width: 48px;
  height: 48px;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.grey._100};
  object-fit: contain;
  display: block;
`;

const noSubmit = (e) => {
  e.preventDefault();
};

const CondicoesComerciaisTab = ({
  submitting,
  validated,
  formProps,
  handleSubmit,
  editable,
  isMenuOpen,
  isOperacao,
  parceiros,
  data,
}) => {
  // editable = false;
  const agrupadosPorProduto = data?.comissionamentos?.reduce(
    (acc, comissionamento) => {
      const produto = comissionamento.produto.nome;
      const instituicao = comissionamento.instituicao.codigo;

      if (!acc[produto]) {
        acc[produto] = {
          produto,
          instituicoes: {},
        };
      }

      if (!acc[produto].instituicoes[instituicao]) {
        acc[produto].instituicoes[instituicao] = {
          faixasDeValor: [],
        };
      }

      acc[produto].instituicoes[instituicao].faixasDeValor.push({
        valorInicio: comissionamento.valorInicio,
        valorFim: comissionamento.valorFim,
        instituicao: comissionamento.instituicao.nomeFantasia,
        comissao: comissionamento.comissao,
      });

      return acc;
    },
    {}
  );

  return (
    <Grid item xs={12}>
      <Form onSubmit={!validated ? noSubmit : handleSubmit}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            lg={6}
            mb={editable ? 7 : 0}
            marginBottom={editable ? 7 : 0}
          >
            <Card>
              <Title as={"h4"} _margin_={"0 0 1rem 0"}>
                Condições Comerciais
              </Title>
              <Grid container spacing={2} marginBottom={3}>
                <Grid item xs={12} md={6}>
                  <ControlledTextField
                    label={"Modelo Comercial"}
                    name={"modeloComercial"}
                    formProps={formProps}
                    disabled={!editable}
                    select
                  >
                    <MenuItem value={"ES"}>Corban Master</MenuItem>
                    <MenuItem value={"AF"}>Substabelecido</MenuItem>
                    <MenuItem value={"RE"}>Referral</MenuItem>
                  </ControlledTextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  {isOperacao && (
                    <ControlledAutocomplete
                      label="Indicado por"
                      name={"parceiroIndicacao"}
                      domain={parceiros}
                      idField={"codigo"}
                      labelField={"nome"}
                      formProps={formProps}
                      autocompleteProps={{
                        disabled: !editable,
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  )}
                </Grid>
              </Grid>
              {data?.comissionamentos.length > 0 && (
                <>
                  <Title as={"h4"} _margin_={"0 0 0.5rem 0"}>
                    Comissionamento
                  </Title>
                  <TableContainer component={Paper}>
                    <Table stickyHeader sx={{ margin: 0 }}>
                      <TableHead>
                        <TableRow>
                          <SizedTableCell style={{ minWidth: 100 }}>
                            Produto
                          </SizedTableCell>
                          <SizedTableCell width={52} />
                          <SizedTableCell style={{ minWidth: 150 }}>
                            Faixa de Valor
                          </SizedTableCell>
                          <SizedTableCell width={72}>Comissão</SizedTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(agrupadosPorProduto).map((produtoKey) => {
                          const agrupamentoProduto =
                            agrupadosPorProduto[produtoKey];
                          const tamanhoTotal = Object.keys(
                            agrupamentoProduto.instituicoes
                          ).reduce(
                            (total, item) =>
                              total +
                              agrupamentoProduto.instituicoes[item]
                                .faixasDeValor.length,
                            0
                          );

                          return (
                            <React.Fragment key={produtoKey}>
                              {Object.keys(agrupamentoProduto.instituicoes).map(
                                (instituicaoKey, j) => {
                                  const instituicaoObject =
                                    agrupamentoProduto.instituicoes[
                                      instituicaoKey
                                    ];

                                  return (
                                    <React.Fragment key={instituicaoKey}>
                                      {instituicaoObject.faixasDeValor.map(
                                        (faixa, index) => (
                                          <BodyTableRow
                                            key={
                                              produtoKey +
                                              instituicaoKey +
                                              index
                                            }
                                          >
                                            {j === 0 && index === 0 && (
                                              <SizedTableCell
                                                rowSpan={tamanhoTotal}
                                                style={{ minWidth: 60 }}
                                              >
                                                <NormalText>
                                                  <b>{produtoKey}</b>
                                                </NormalText>
                                              </SizedTableCell>
                                            )}
                                            {index === 0 && (
                                              <SizedTableCell
                                                rowSpan={
                                                  instituicaoObject
                                                    .faixasDeValor.length
                                                }
                                                width={52}
                                              >
                                                <Logo
                                                  src={logos[instituicaoKey]}
                                                />
                                              </SizedTableCell>
                                            )}
                                            <SizedTableCell
                                              style={{ minWidth: 60 }}
                                            >
                                              <NormalText>
                                                {faixa.valorInicio === 0 ? (
                                                  <>
                                                    Até{" "}
                                                    <b>
                                                      R$
                                                      {formatters.numbers.currency(
                                                        faixa.valorFim
                                                      )}
                                                    </b>
                                                  </>
                                                ) : !!faixa.valorFim ? (
                                                  <>
                                                    De{" "}
                                                    <b>
                                                      R$
                                                      {formatters.numbers.currency(
                                                        faixa.valorInicio
                                                      )}
                                                    </b>{" "}
                                                    até{" "}
                                                    <b>
                                                      R$
                                                      {formatters.numbers.currency(
                                                        faixa.valorFim
                                                      )}
                                                    </b>
                                                  </>
                                                ) : (
                                                  <>
                                                    Acima de{" "}
                                                    <b>
                                                      R$
                                                      {formatters.numbers.currency(
                                                        faixa.valorInicio
                                                      )}
                                                    </b>
                                                  </>
                                                )}
                                              </NormalText>
                                            </SizedTableCell>
                                            <SizedTableCell
                                              style={{ minWidth: 60 }}
                                            >
                                              <NormalText>
                                                <b>
                                                  {formatters.numbers.currency(
                                                    faixa.comissao
                                                  )}
                                                </b>
                                                %
                                              </NormalText>
                                            </SizedTableCell>
                                          </BodyTableRow>
                                        )
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </React.Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Card>
          </Grid>
          {!!editable && (
            <StyledDrawer
              _isOpen_={isMenuOpen}
              variant="permanent"
              anchor={"bottom"}
            >
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                my={1.5}
                px={1.5}
              >
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <Button fullWidth type={"submit"} loading={submitting}>
                    GRAVAR CONDIÇÕES COMERCIAIS
                  </Button>
                </Grid>
              </Grid>
            </StyledDrawer>
          )}
        </Grid>
      </Form>
    </Grid>
  );
};

export default CondicoesComerciaisTab;
