import { ToastContainer as RToastContainer } from "react-toastify";
import { styled } from "@mui/material";

const StyledContainer = styled("div")`
  & .Toastify__toast--error {
    background-color: ${({ theme }) => theme.colors.error._600};
    color: ${({ theme }) => theme.colors.grey._50};
  }
  & .Toastify__toast--info {
    background-color: ${({ theme }) => theme.colors.secondary._600};
    color: ${({ theme }) => theme.colors.grey._50};
  }
  & .Toastify__toast--warning {
    background-color: ${({ theme }) => theme.colors.primary._400};
    color: ${({ theme }) => theme.colors.grey._700};
  }
  & .Toastify__toast--success {
    background-color: ${({ theme }) => theme.colors.success._600};
    color: ${({ theme }) => theme.colors.grey._50};
  }

  & .Toastify__toast-body {
    font-weight: 500;
    font-family: ${({ theme }) => theme.fonts.family.buttons};
  }
`;

const ToastContainer = ({ className, ...props }) => (
  <StyledContainer className={className}>
    <RToastContainer {...props} />
  </StyledContainer>
);

export default ToastContainer;
