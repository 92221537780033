import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CadastroParceiroPage from "./CadastroParceiroPage";
import validators from "../../utils/validators";
import { actions } from "../../actions/parceiro.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";
import useCompleteForm from "../../hooks/useCompleteForm";
import {
  isCriandoParceiro,
  isValidandoParceiro,
} from "../../selectors/parceiro.selectors";
import { partner } from "../../utils/localStorage";

const CadastroParceiroPageConnected = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const loading = useSelector(isValidandoParceiro);

  const [firstPageValues, setFirstPageValues] = useState({
    cnpj: "",
    razaoSocial: "",
    nomeFantasia: "",
    site: "",
    telefoneComercial: "",
  });
  const submitting = useSelector(isCriandoParceiro);
  const urlLogoParceiro = partner.get()?.urlLogo;

  const [firstFormProps, firstHandleSubmit] = useCompleteForm({
    rules: () => ({
      cnpj: validators.cnpj({ required: true }),
      razaoSocial: validators.string({ required: true }),
      nomeFantasia: validators.string({ required: true }),
      site: validators.string({ required: true }),
      telefoneComercial: validators.phone({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        ...firstPageValues,
      }),
      [firstPageValues]
    ),
    handleSubmit: useCallback(
      (values) => {
        setFirstPageValues(values);
        setCurrentPage(1);
      },
      [setFirstPageValues, setCurrentPage]
    ),
  });
  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      nomeRepresentante: validators.string({ required: true }),
      cpfRepresentante: validators.cpf({ required: true }),
      emailRepresentante: validators.email({ required: true }),
      telefoneRepresentante: validators.phone({ required: true }),
    }),
    initialValues: () => ({
      nomeRepresentante: "",
      cpfRepresentante: "",
      emailRepresentante: "",
      telefoneRepresentante: "",
    }),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.criarParceiro.request({
            ...values,
            ...firstPageValues,
          })
        );
      },
      [dispatch, firstPageValues]
    ),
  });

  const handleBackToFirstPage = useCallback(() => {
    setCurrentPage(0);
  }, [setCurrentPage]);

  const handleUserTermsClick = useCallback(() => {
    dispatch(routeActions.openNewWindowWith(routes.TERMOS_USO));
  }, [dispatch]);
  const handlePrivacyPolicyClick = useCallback(() => {
    dispatch(routeActions.openNewWindowWith(routes.POLITICA_PRIVACIDADE));
  }, [dispatch]);

  return (
    <CadastroParceiroPage
      loading={loading}
      submitting={submitting}
      currentPage={currentPage}
      firstFormProps={firstFormProps}
      formProps={formProps}
      firstHandleSubmit={firstHandleSubmit}
      handleSubmit={handleSubmit}
      handleUserTermsClick={handleUserTermsClick}
      handlePrivacyPolicyClick={handlePrivacyPolicyClick}
      handleBackToFirstPage={handleBackToFirstPage}
      urlLogoParceiro={urlLogoParceiro}
    />
  );
};

export default CadastroParceiroPageConnected;
