import Footer from "./Footer";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";

const FooterConnected = ({ selected }) => {
  const dispatch = useDispatch();

  const handlePoliticaClick = useCallback(() => {
    dispatch(routeActions.redirectTo(routes.POLITICA_PRIVACIDADE));
  }, [dispatch]);
  const handleTermos = useCallback(() => {
    dispatch(routeActions.redirectTo(routes.TERMOS_USO));
  }, [dispatch]);

  return (
    <Footer
      handlePoliticaClick={handlePoliticaClick}
      handleTermos={handleTermos}
    />
  );
};

export default FooterConnected;
