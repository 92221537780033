import { useCallback } from "react";
import EsqueciSenhaForm from "./EsqueciSenhaForm";
import validators from "../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../actions/autenticacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";
import { isEsqueciSenhaSubmitting } from "../../selectors/autenticacao.selectors";
import useCompleteForm from "../../hooks/useCompleteForm";

const EsqueciSenhaFormConnected = ({ internal, type }) => {
  const dispatch = useDispatch();
  const submitting = useSelector(isEsqueciSenhaSubmitting);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      email: validators.email({ required: true }),
    }),
    initialValues: () => ({
      email: "",
    }),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.esqueciSenha.request({
            ...values,
            type,
          })
        );
      },
      [dispatch, type]
    ),
  });

  const handleClickBack = useCallback(() => {
    dispatch(routeActions.redirectTo(routes.LOGIN));
  }, [dispatch]);

  return (
    <EsqueciSenhaForm
      internal={internal}
      submitting={submitting}
      formProps={formProps}
      handleClickBack={handleClickBack}
      handleSubmit={handleSubmit}
    />
  );
};

export default EsqueciSenhaFormConnected;
