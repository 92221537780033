import { Grid } from "@mui/material";
import React from "react";
import ComentariosCardConnected from "../workflow/ComentariosCard.connected";

const ComentariosOperacaoTab = () => {
  return (
    <Grid item xs={12} lg={6}>
      <ComentariosCardConnected />
    </Grid>
  );
};

export default ComentariosOperacaoTab;
