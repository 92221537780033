import { Button, Card, Form, Title } from "../../components";
import { Drawer, Grid, styled } from "@mui/material";
import React from "react";
import DadosEmpresaForm from "./DadosEmpresaForm";
import DadosRepresentanteForm from "./DadosRepresentanteForm";
import DadosEnderecoForm from "./DadosEnderecoForm";
import { Masonry } from "@mui/lab";
import DadosBancariosForm from "./DadosBancariosForm";
import DadosAdicionaisForm from "./DadosAdicionaisForm";
import DadosCertificadosForm from "./DadosCertificadosForm";
import DialogoVisivel from "../workflow/DialogoVisivel";
import DadosAgendamentoForm from "./DadosAgendamentoForm";
import { transientOptions } from "../../styles/theme";

const StyledDrawer = styled(Drawer, transientOptions)`
  & .MuiDrawer-paper {
    overflow-y: visible;
    border-radius: 1rem 1rem 0 0;
    left: 0;
  }

  ${({ theme, _isOpen_ }) => `${theme.breakpoints.up("sm")} {
  	& .MuiDrawer-paper {
			left: ${_isOpen_ ? theme.sizes.lateralMenu : theme.sizes.lateralMenuCollapsed};
		}
	`}
`;
const EnlargedForm = styled(Form)`
  width: calc(100% + 24px);
`;
const SpacedMasonry = styled(Masonry)`
  margin-bottom: 1rem;
`;

const DetalhesParceiroPage = ({
  formProps,
  domainBancos,
  handleSubmit,
  handleZipCodeBlurred,
  submitting,
  editable,
  dialogosDisponiveis,
  isMenuOpen,
}) => {
  return (
    <Grid item xs={12}>
      <EnlargedForm onSubmit={handleSubmit}>
        <SpacedMasonry columns={{ xs: 1, md: 2, lg: 3 }} spacing={3}>
          <DialogoVisivel
            referencia={"DADOS_AGENDAMENTO"}
            dialogos={dialogosDisponiveis}
          >
            <Card>
              <Title as={"h3"} _margin_={"0 0 1rem 0"}>
                Agendamento da Entrevista
              </Title>
              <DadosAgendamentoForm
                formProps={formProps}
                forceDisabled={dialogosDisponiveis.DADOS_AGENDAMENTO === "C"}
              />
            </Card>
          </DialogoVisivel>
          <DialogoVisivel
            referencia={"DADOS_EMPRESA"}
            dialogos={dialogosDisponiveis}
          >
            <Card>
              <Title as={"h3"} _margin_={"0 0 1rem 0"}>
                Dados da Empresa
              </Title>
              <DadosEmpresaForm
                formProps={formProps}
                forceDisabled={dialogosDisponiveis.DADOS_EMPRESA === "C"}
              />
            </Card>
          </DialogoVisivel>
          <DialogoVisivel
            referencia={"DADOS_REPRESENTANTE"}
            dialogos={dialogosDisponiveis}
          >
            <Card>
              <Title as={"h3"} _margin_={"0 0 1rem 0"}>
                Dados do Representante
              </Title>
              <DadosRepresentanteForm
                formProps={formProps}
                forceDisabled={dialogosDisponiveis.DADOS_REPRESENTANTE === "C"}
              />
            </Card>
          </DialogoVisivel>
          <DialogoVisivel
            referencia={"ENDERECO_EMPRESA"}
            dialogos={dialogosDisponiveis}
          >
            <Card>
              <Title as={"h3"} _margin_={"0 0 1rem 0"}>
                Endereço da Empresa
              </Title>
              <DadosEnderecoForm
                formProps={formProps}
                handleZipCodeBlurred={handleZipCodeBlurred}
                forceDisabled={dialogosDisponiveis.ENDERECO_EMPRESA === "C"}
              />
            </Card>
          </DialogoVisivel>
          <DialogoVisivel
            referencia={"DADOS_BANCARIOS"}
            dialogos={dialogosDisponiveis}
          >
            <Card>
              <Title as={"h3"} _margin_={"0 0 1rem 0"}>
                Dados Bancários
              </Title>
              <DadosBancariosForm
                formProps={formProps}
                domainBancos={domainBancos}
                forceDisabled={dialogosDisponiveis.DADOS_BANCARIOS === "C"}
              />
            </Card>
          </DialogoVisivel>
          <DialogoVisivel
            referencia={"DADOS_EMPRESA"}
            dialogos={dialogosDisponiveis}
          >
            <Card>
              <Title as={"h3"} _margin_={"0 0 1rem 0"}>
                Dados Adicionais
              </Title>
              <DadosAdicionaisForm
                formProps={formProps}
                forceDisabled={dialogosDisponiveis.DADOS_EMPRESA === "C"}
              />
            </Card>
          </DialogoVisivel>
          <DialogoVisivel
            referencia={"CERTIFICADOS"}
            dialogos={dialogosDisponiveis}
          >
            <Card>
              <Title as={"h3"} _margin_={"0 0 1rem 0"}>
                Certificados
              </Title>
              <DadosCertificadosForm
                formProps={formProps}
                forceDisabled={dialogosDisponiveis.CERTIFICADOS === "C"}
              />
            </Card>
          </DialogoVisivel>
        </SpacedMasonry>
        {!!editable && (
          <StyledDrawer
            _isOpen_={isMenuOpen}
            variant="permanent"
            anchor={"bottom"}
          >
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              my={1.5}
              px={1.5}
            >
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Button fullWidth type={"submit"} loading={submitting}>
                  GRAVAR DADOS CADASTRAIS
                </Button>
              </Grid>
            </Grid>
          </StyledDrawer>
        )}
      </EnlargedForm>
    </Grid>
  );
};

export default DetalhesParceiroPage;
