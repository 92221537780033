import React from "react";
import TrocarSenhaForm from "./TrocarSenhaForm.connected";
import EsqueciSenhaForm from "./EsqueciSenhaForm.connected";
import { FullHeightContainer, SizedCard, Title } from "../../components";
import logo from "../../assets/logo.png";
import { Grid, styled } from "@mui/material";
import { MainLoader } from "../../components/loaders";
import { transientOptions } from "../../styles/theme";

const ScaledLogo = styled("img")`
  height: 64px;
  width: auto;
`;
const InformationBanner = styled("h4", transientOptions)`
  background-color: ${({ theme }) => theme.colors.primary._200};
  margin: ${({ theme }) => "2rem -" + theme.spacings.cards};
  padding: ${({ theme }) => "1rem " + theme.spacings.cards};
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.grey._900};
  text-align: center;
`;
const SpacedLoader = styled(MainLoader)`
  padding: 3rem 0;
`;

const PrimeiroAcessoPage = ({ loading, valid }) => {
  return (
    <FullHeightContainer maxWidth={false}>
      <Grid alignItems="center" justifyContent="center" container>
        <SizedCard _size_={"sm"}>
          <Grid
            direction={"column"}
            alignItems="center"
            justifyContent="center"
            container
          >
            {!!loading && <SpacedLoader color={"brand"} size={80} />}
            {!loading && (
              <>
                <Grid item xs={12}>
                  <ScaledLogo src={logo} />
                </Grid>
                <Title as={"h2"} _margin_={"1rem 0 2rem 0"}>
                  Redefinição de senha
                </Title>
                {!!valid && <TrocarSenhaForm />}
                {!valid && (
                  <>
                    <InformationBanner>
                      O link de redefinição de sua senha não é válido ou já foi
                      utilizado. Se necessário, solicite outro link abaixo.
                    </InformationBanner>
                    <EsqueciSenhaForm internal={true} />
                  </>
                )}
              </>
            )}
          </Grid>
        </SizedCard>
      </Grid>
    </FullHeightContainer>
  );
};

export default PrimeiroAcessoPage;
