import React from "react";
import { Button as MButton } from "@mui/material";
import { styled } from "@mui/material";
import { HorizontalLoader } from "../loaders";
import { transientOptions } from "../../styles/theme";

const StyledButton = styled(MButton, transientOptions)`
  &.MuiButton-contained {
    color: ${({ theme }) => theme.colors.grey._0};
    background-color: ${({ theme, _color_ = "primary" }) =>
      theme.colors[_color_]._500};
    box-shadow: ${({ theme, _shadow_ = "none" }) =>
      theme._shadows.buttons[_shadow_]};
  }
  &.MuiButton-contained.Mui-disabled {
    color: ${({ theme }) => theme.colors.grey._200};
    background-color: ${({ theme }) => theme.colors.grey._100};
  }
  &.MuiButton-contained:hover {
    background-color: ${({ theme, _color_ = "primary" }) =>
      theme.colors[_color_]._600};
  }
  &.MuiButton-outlined {
    color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._500};
    border-color: ${({ theme, _color_ = "primary" }) =>
      theme.colors[_color_]._500};
    box-shadow: ${({ theme, _shadow_ = "none" }) =>
      theme._shadows.buttons[_shadow_]};
  }
  &.MuiButton-outlined:hover {
    color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._600};
    border-color: ${({ theme, _color_ = "primary" }) =>
      theme.colors[_color_]._600};
    background-color: rgba(0, 0, 0, 0.04);
  }
  &.MuiButton-text {
    color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._500};
  }
  &.MuiButton-text:hover {
    color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._600};
    background-color: rgba(4, 6, 15, 0.04);
  }

  border-radius: ${({ theme }) => theme.borderRadiuses.buttons};
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.family.buttons};

  &:focus {
    outline: 0;
  }
`;

const BaseButton = React.forwardRef(
  (
    {
      children,
      loading,
      disabled,
      loaderColor = "white",
      loaderSize = 24,
      variant = "contained",
      ...otherProps
    },
    ref
  ) => (
    <StyledButton
      {...otherProps}
      ref={ref}
      disabled={disabled || !!loading}
      variant={variant}
    >
      {!!loading ? (
        <HorizontalLoader color={loaderColor} size={loaderSize} />
      ) : (
        children
      )}
    </StyledButton>
  )
);

export default BaseButton;
