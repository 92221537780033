import { actions } from "../actions/clientes.actions";
import { actions as operacaoActions } from "../actions/operacao.actions";
import { types as routes } from "../actions/rotas.actions";

const initialState = {
  resumo: {
    loading: true,
    data: null,
  },
  lista: {
    loading: true,
    totalElementos: 0,
    totalPaginas: 0,
    conteudo: [],
  },
  detalhes: {
    loading: false,
    submitting: false,
    data: null,
    initing: false,
  },
  dominios: {
    parceiros: {
      loading: false,
      data: [],
    },
    produtos: {
      loading: false,
      data: [],
    },
    instituicoesFinanceiras: {
      loading: false,
      data: [],
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.carregarResumo.REQUEST:
    case actions.carregarResumo.SUCCESS:
    case actions.carregarResumo.FAILURE:
      return {
        ...state,
        resumo: {
          ...state.resumo,
          loading: action.type === actions.carregarResumo.REQUEST,
          data:
            action.type === actions.carregarResumo.SUCCESS
              ? action.payload.response.data
              : null,
        },
      };
    case actions.obterParceiros.REQUEST:
    case actions.obterParceiros.SUCCESS:
    case actions.obterParceiros.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          parceiros: {
            ...state.dominios.parceiros,
            loading: action.type === actions.obterParceiros.REQUEST,
            data:
              action.type === actions.obterParceiros.SUCCESS
                ? action.payload.response.data.filter(
                    (p) => ["P", "A", "S"].indexOf(p.situacao) >= 0
                  )
                : [],
          },
        },
      };
    case actions.obterProdutos.REQUEST:
    case actions.obterProdutos.SUCCESS:
    case actions.obterProdutos.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          produtos: {
            ...state.dominios.produtos,
            loading: action.type === actions.obterProdutos.REQUEST,
            data:
              action.type === actions.obterProdutos.SUCCESS
                ? action.payload.response.data.toSorted((a, b) =>
                    a.nome.localeCompare(b.nome)
                  )
                : [],
          },
        },
      };
    case actions.obterInstituicoesFinanceiras.REQUEST:
    case actions.obterInstituicoesFinanceiras.SUCCESS:
    case actions.obterInstituicoesFinanceiras.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          instituicoesFinanceiras: {
            ...state.dominios.instituicoesFinanceiras,
            loading: action.type === actions.obterInstituicoesFinanceiras.REQUEST,
            data:
              action.type === actions.obterInstituicoesFinanceiras.SUCCESS
                ? action.payload.response.data.toSorted((a, b) =>
                  a.nomeFantasia.localeCompare(b.nomeFantasia)
                )
                : [],
          },
        },
      };
    case actions.obterCliente.REQUEST:
    case actions.obterCliente.SUCCESS:
    case actions.obterCliente.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          loading: action.type === actions.obterCliente.REQUEST,
          data:
            action.type === actions.obterCliente.SUCCESS
              ? action.payload.response.data
              : null,
        },
      };
    case operacaoActions.iniciarRelacionada.REQUEST:
    case operacaoActions.iniciarRelacionada.FAILURE:
    case operacaoActions.iniciarRelacionada.SUCCESS:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          initing: action.type === operacaoActions.iniciarRelacionada.REQUEST,
        },
      };
    case actions.listarClientes.REQUEST:
      return {
        ...state,
        lista: {
          ...state.lista,
          loading: true,
        },
      };
    case actions.listarClientes.FAILURE:
      return {
        ...state,
        lista: {
          ...state.lista,
          loading: false,
        },
      };
    case actions.listarClientes.SUCCESS:
      return {
        ...state,
        lista: {
          ...state.lista,
          ...action.payload.response.data,
          loading: false,
        },
      };
    case routes.CLIENTES:
      return {
        ...state,
        lista: {
          ...initialState.lista,
        },
      };
    case routes.DETALHES_CLIENTE:
      return {
        ...state,
        detalhes: {
          ...initialState.detalhes,
          loading: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
