import React from "react";
import SimuladorInternoPage from "./SimuladorInternoPage";
import { useSelector } from "react-redux";
import {
  getPaginaAtual,
  isCarregandoLeadGeral,
} from "../../selectors/simulador.selectors";

const SimuladorInternoPageConnected = () => {
  const atual = useSelector(getPaginaAtual);
  const loading = useSelector(isCarregandoLeadGeral);

  return <SimuladorInternoPage atual={atual} loading={loading} />;
};

export default SimuladorInternoPageConnected;
