import { Grid } from "@mui/material";
import React from "react";
import { Button, Card, Form, GoBack } from "../../../../components";
import ControlledAutocomplete from "../../../../components/inputs/ControlledAutocomplete";
import {
  ControlledTextField,
  CurrencyTextField,
} from "../../../../components/inputs";
import Stepper from "../Stepper";

const DadosProfissionaisInterForm = ({
  perfisRenda,
  profissoes,
  fluxo,
  events,
  formProps,
  handleSubmit,
  submitting,
  handleGoTo2,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} direction={"column"} maxWidth={704}>
        <GoBack
          handleClick={handleGoTo2}
          text={"Preencha as informações do comprador"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={2} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label={"Renda"}
                      name={"renda"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: CurrencyTextField,
                      }}
                      inputProps={{
                        allowNegative: false,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ControlledAutocomplete
                      label="Perfil de Renda"
                      name={"perfilRenda"}
                      domain={perfisRenda}
                      idField={"id"}
                      labelField={"descricao"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label="Patrimônio"
                      name={"patrimonio"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: CurrencyTextField,
                      }}
                      inputProps={{
                        allowNegative: false,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ControlledAutocomplete
                      label="Profissão"
                      name={"dadosProfissionais.profissao"}
                      domain={profissoes}
                      idField={"id"}
                      labelField={"descricao"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosProfissionaisInterForm;
