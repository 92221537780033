import React, { useCallback, useEffect } from "react";
import DadosProfissionaisInterForm from "./DadosProfissionaisInterForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import validators from "../../../../utils/validators";
import { actions } from "../../../../actions/operacao.actions";

const DadosProfissionaisInterFormConnected = ({
  handleGoTo4,
  events,
  setComprador,
  comprador,
  ...props
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { perfilRenda: perfisRenda, profissao: profissoes },
  } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const resetValues = useCallback(
    () => ({
      renda: "",
      perfilRenda: null,
      ...comprador,
      dadosProfissionais: {
        renda: "",
        patrimonio: "",
        profissao: null,
        ...comprador?.dadosProfissionais,
      },
    }),
    [comprador]
  );

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setComprador({
          ...c,
          fluxo: data.validacao[
            data.operacao.detalhe.correntista ? "correntista" : "completa"
          ]?.participantes?.find((f) => f.id === c?.id)?.etapas,
        });
      }
      handleGoTo4();
    },
    [handleGoTo4, setComprador, comprador]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      renda: validators.number({ required: true }),
      perfilRenda: validators.object({ required: true }),
      patrimonio: validators.number({ required: true }),
      dadosProfissionais: {
        profissao: validators.object({ required: true }),
      },
    }),
    initialValues: resetValues,
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-profissionais",
            participante: {
              id: comprador?.id,
              renda: values.renda,
              idPerfilRenda: values.perfilRenda?.id,
              patrimonio: values.patrimonio,
              dadosProfissionais: {
                idProfissao: values.dadosProfissionais.profissao?.id,
              },
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [callback, comprador, operacao, dispatch]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset(resetValues());
  }, [comprador, resetValues, reset]);

  return (
    <DadosProfissionaisInterForm
      {...props}
      profissoes={profissoes}
      perfisRenda={perfisRenda}
      fluxo={comprador.fluxo}
      events={events}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
    />
  );
};

export default DadosProfissionaisInterFormConnected;
