import { all } from "redux-saga/effects";
import { sagas as authSagas } from "./autenticacao.saga";
import { sagas as clienteSagas } from "./clientes.saga";
import { sagas as parceiroSagas } from "./parceiro.saga";
import { sagas as operacaoSagas } from "./operacao.saga";
import { sagas as simuladorSagas } from "./simulador.saga";
import { sagas as calculadoraSagas } from "./calculadora.saga";
import { sagas as temaSagas } from "./tema.saga";
import { sagas as workflowSagas } from "./workflow.saga";
import { sagas as documentosSagas } from "./documentos.saga";
import { sagas as apoioSagas } from "./apoio.saga";
import { sagas as rotaSagas } from "./rotas.saga";
import { sagas as usuarioSagas } from  "./usuarios.saga"

function* allSagas() {
  yield all([
    ...rotaSagas,
    ...temaSagas,
    ...authSagas,
    ...parceiroSagas,
    ...operacaoSagas,
    ...calculadoraSagas,
    ...simuladorSagas,
    ...clienteSagas,
    ...workflowSagas,
    ...documentosSagas,
    ...apoioSagas,
    ...usuarioSagas
  ]);
}

export default allSagas;
