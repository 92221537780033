import React from "react";
import {
  FirstPage,
  KeyboardArrowLeft,
  LastPage,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { styled, Tooltip, Grid } from "@mui/material";
import TableIconButton from "./TableIconButton";

const PaginationContainer = styled(Grid)`
  margin-top: 0.5em;
`;
const PagingText = styled("span")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 300;
`;

export default function TablePagination({
  page,
  loading,
  rowsPerPage,
  total,
  style,
  data = total,
  handlePaginationClick,
}) {
  const maxPages = Math.ceil(total / rowsPerPage);
  const start = page * rowsPerPage + 1;
  const end = Math.min(total, (page + 1) * rowsPerPage);

  return (
    <PaginationContainer
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      style={style}
    >
      {loading && (!data || !total)
        ? <div />
        : (!data || !total) && !loading
          ? <PagingText>Nenhum registro encontrado</PagingText>
          : <PagingText>Exibindo {start} - {end} de {total} registro(s)</PagingText>
      }

      <Grid display={"flex"}>
        <Tooltip arrow title={"Primeira página"}>
          <span>
            <TableIconButton
              disabled={loading || !total || page === 0}
              onClick={() => handlePaginationClick(0)}
            >
              <FirstPage />
            </TableIconButton>
          </span>
        </Tooltip>
        <Tooltip arrow title={"Página anterior"}>
          <span>
            <TableIconButton
              disabled={loading || !total || page === 0}
              onClick={() => handlePaginationClick(page - 1)}
            >
              <KeyboardArrowLeft />
            </TableIconButton>
          </span>
        </Tooltip>
        <Tooltip arrow title={"Próxima página"}>
          <span>
            <TableIconButton
              disabled={loading || !total || page === maxPages - 1}
              onClick={() => handlePaginationClick(page + 1)}
            >
              <KeyboardArrowRight />
            </TableIconButton>
          </span>
        </Tooltip>
        <Tooltip arrow title={"Última página"}>
          <span>
            <TableIconButton
              disabled={loading || !total || page === maxPages - 1}
              onClick={() => handlePaginationClick(maxPages - 1)}
            >
              <LastPage />
            </TableIconButton>
          </span>
        </Tooltip>
      </Grid>
    </PaginationContainer>
  );
}
