import { useCallback, useState } from "react";
import FluxoQualificacoes from "./FluxoQualificacoes";

const FluxoQualificacoesConnected = (props) => {
  const [step, setStep] = useState(0);

  const makeGoTo = useCallback(
    (s) => {
      return () => {
        setStep(s);
      };
    },
    [setStep]
  );
  const handleGoTo0 = useCallback(() => {
    setStep(0);
  }, [setStep]);
  const handleGoTo1 = useCallback(() => {
    setStep(1);
  }, [setStep]);
  const handleGoTo2 = useCallback(() => {
    setStep(2);
  }, [setStep]);
  const handleGoTo3 = useCallback(() => {
    setStep(3);
  }, [setStep]);
  const handleGoTo4 = useCallback(() => {
    setStep(4);
  }, [setStep]);

  const events = [
    handleGoTo0,
    handleGoTo1,
    handleGoTo2,
    handleGoTo3,
    handleGoTo4,
  ];

  return (
    <FluxoQualificacoes
      {...props}
      step={step}
      setStep={setStep}
      events={events}
      handleGoTo0={handleGoTo0}
      handleGoTo1={handleGoTo1}
      handleGoTo2={handleGoTo2}
      handleGoTo3={handleGoTo3}
      handleGoTo4={handleGoTo4}
      makeGoTo={makeGoTo}
      submitting={props.submitting}
      fluxo={props.data.validacao.qualificacoes}
    />
  );
};

export default FluxoQualificacoesConnected;
