import React, { useCallback, useEffect } from "react";
import CompartilhamentoDialog from "./CompartilhamentoDialog";
import { useDispatch, useSelector } from "react-redux";
import { getDialogCompartilhamento } from "../../selectors/operacao.selectors";
import { actions } from "../../actions/operacao.actions";
import { toast } from "react-toastify";

const CompartilhamentoDialogConnected = () => {
  const dispatch = useDispatch();
  const { show, data } = useSelector(getDialogCompartilhamento);

  const handleClose = useCallback(() => {
    dispatch(actions.exibirDialogCompartilhamento(false, null));
  }, [dispatch]);

  const handleCopyText = async (data) => {
    await navigator.clipboard.writeText(data).then(() => {
      toast.success("Link copiado para a área de transferência!", {autoClose: 1000});
    });
  };

  useEffect(() => {
    if (show) {
      handleCopyText(window.location.origin + "/externo/" + data);
    }
  }, [show])

  return (
    <CompartilhamentoDialog
      open={show}
      data={data}
      handleClose={handleClose}
      handleCopyText={handleCopyText}
    />
  );
};

export default CompartilhamentoDialogConnected;