import React from "react";
import { styled } from "@mui/material";
import { transientOptions } from "../styles/theme";

const StyledText = styled("p", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.grey._700};
  text-align: ${({ _align_ = "left" }) => _align_};
  margin: ${({ _margin_ = "0" }) => _margin_};
`;

const Text = (props) => <StyledText {...props} />;

export default Text;
