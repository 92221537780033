import { Grid } from "@mui/material";
import React from "react";
import { Button, Card, Form, GoBack, Title } from "../../../../../components";
import ControlledAutocomplete from "../../../../../components/inputs/ControlledAutocomplete";
import {
  ControlledTextField,
  CpfCnpjTextField,
  CurrencyTextField,
  DateTextField,
} from "../../../../../components/inputs";
import Stepper from "../../Stepper";
import UpperCaseTextField from "../../../../../components/inputs/UpperCaseTextField";

const DadosProfissionaisC6Form = ({
  perfisRenda,
  profissoes,
  fluxo,
  events,
  exibeCnpj,
  formProps,
  handlePerfilRendaChange,
  handleSubmit,
  submitting,
  makeGoTo,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} direction={"column"} maxWidth={704}>
        <GoBack
          handleClick={makeGoTo(1)}
          text={"Preencha as informações do comprador"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={1} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item width={1}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Renda Pessoal</Title>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <ControlledTextField
                      label="Renda Bruta Total Mensal"
                      name={"renda"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: CurrencyTextField,
                      }}
                      inputProps={{
                        allowNegative: false,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <ControlledAutocomplete
                      label="Tipo de Renda"
                      name={"perfilRenda"}
                      domain={perfisRenda}
                      idField={"id"}
                      labelField={"descricao"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        onChange: handlePerfilRendaChange,
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledTextField
                      label="Cargo ou Profissão"
                      name={"dadosProfissionais.cargo"}
                      inputProps={{ maxLength: 100 }}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: UpperCaseTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <ControlledTextField
                      label="Nome da Empresa"
                      name={"dadosProfissionais.nomeEmpresa"}
                      inputProps={{ maxLength: 100 }}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: UpperCaseTextField,
                      }}
                    />
                  </Grid>
                  {exibeCnpj && (
                    <Grid item xs={12} md={5}>
                      <ControlledTextField
                        label="CNPJ da Empresa"
                        name={"dadosProfissionais.cnpjEmpresa"}
                        formProps={formProps}
                        type={"cnpj"}
                        InputProps={{
                          inputComponent: CpfCnpjTextField,
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label={"Data de Início"}
                      name={"dadosProfissionais.dataAdmissao"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: DateTextField,
                      }}
                    />
                  </Grid>

                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosProfissionaisC6Form;
