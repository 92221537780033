import basicFlow from "./asyncHandler";
import { actions } from "../actions/usuarios.actions";
import { authenticatedRequest, destinies } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../actions/rotas.actions";
import { put, select } from "redux-saga/effects";
import { getRouteArguments } from "../selectors/routes.selectors";
import { toast } from "react-toastify";

const listarUsuarios = basicFlow({
  actionGenerator: actions.listarUsuarios,
  api: (values) => {
    return authenticatedRequest({
      url: "/usuarios",
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      queryParams: values,
      method: "get",
    });
  },
  preSuccess: function* () {
    yield put(actions.listarParceiros.request());
  },
});

const obterUsuario = basicFlow({
  actionGenerator: actions.obterUsuario,
  api: (data) => {
    return authenticatedRequest({
      url: `/usuario/${data.id}`,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      method: "get",
    });
  },
  preSuccess: function* ({ response }) {
    if (!response?.data) {
      return;
    }
    if (response.data.parceiro) {
      yield put(actions.obterParceiro.request({ id: response.data.parceiro }));
      yield put(actions.obterParceirosSubstabelecidosPorUsuario.request({ id: response.data.parceiro }))
    }
  },
});

const obterParceirosSubstabelecidosPorUsuario = basicFlow({
  actionGenerator: actions.obterParceirosSubstabelecidosPorUsuario,
  api: (data) => {
    return authenticatedRequest({
      url: `/parceiro/${data.id}/substabelecimentos`,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      method: "get",
    });
  }
});


const listarUsuariosPorParceiro = basicFlow({
  actionGenerator: actions.listarUsuariosPorParceiro,
  api: (id) => {
    return authenticatedRequest({
      url: `/usuarios?parceiro=${id}`,
      isResourceService: true,
      destiny: destinies.AUTH,
      method: "get",
    });
  },
});

const criarUsuario = basicFlow({
  actionGenerator: actions.criarUsuario,
  transform: (payload) => {
    return {
      ...payload,
      idParceiro: payload.parceiro,
      idTimeParceiro: payload.timeParceiro,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: "/usuario",
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      method: "post",
      body: values,
    });
  },
  postSuccess: function* ({ response }) {
    if (!response?.data) {
      return;
    }
    toast.success("Usuário salvo com sucesso!");
    yield put(
      routeActions.redirectTo(routes.DETALHES_USUARIO, { id: response.data.id })
    );
  },
});

const atualizarUsuario = basicFlow({
  actionGenerator: actions.atualizarUsuario,
  api: ({ id, ...values }) => {
    return authenticatedRequest({
      url: `/usuario/${id}`,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      method: "put",
      body: values,
    });
  },
  postSuccess: function* ({ response }) {
    if (!response?.data) {
      return;
    }
    toast.success("Informações salvas com sucesso.");
    yield put(
      routeActions.redirectTo(routes.DETALHES_USUARIO, { id: response.data.id })
    );
  },
});

const listarParceiros = basicFlow({
  actionGenerator: actions.listarParceiros,
  api: (values) => {
    return authenticatedRequest({
      url: "/dominios/parceiros",
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      queryParams: values,
      method: "get",
    });
  },
});

const obterParceiro = basicFlow({
  actionGenerator: actions.obterParceiro,
  api: (data) => {
    return authenticatedRequest({
      url: `/parceiro/${data.id}`,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
      method: "get",
    });
  },
  // preSuccess: tratarDetalheParceiro,
});

function* listaUsuariosRouteWatcher() {
  yield routeWatcher([routes.USUARIOS], function* () {
    yield put(actions.listarUsuarios.request());
  });
}

function* detalhesUsuarioRouteWatcher() {
  yield routeWatcher([routes.DETALHES_USUARIO], function* () {
    const payload = yield select(getRouteArguments);
    yield put(actions.obterUsuario.request(payload));
  });
}

function* cadastroUsuarioRouteWatcher() {
  yield routeWatcher([routes.CADASTRO_USUARIO], function* () {
    yield put(actions.listarParceiros.request());
  });
}

export const sagas = [
  listarUsuarios.watcher(),
  obterUsuario.watcher(),
  listarUsuariosPorParceiro.watcher(),
  obterParceirosSubstabelecidosPorUsuario.watcher(),
  criarUsuario.watcher(),
  atualizarUsuario.watcher(),
  listarParceiros.watcher(),
  obterParceiro.watcher(),
  listaUsuariosRouteWatcher(),
  detalhesUsuarioRouteWatcher(),
  cadastroUsuarioRouteWatcher(),
];
