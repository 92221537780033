import { takeEvery, put } from "redux-saga/effects";
import { types, actions } from "../actions/tema.actions";
import theme, { defaultOptions } from "../styles/theme";
import { pSBC } from "../utils/basic";
import jwtDecode from "jwt-decode";
import { jwt } from "../utils/localStorage";

function* themeWatcher() {
  yield takeEvery(types.APLICAR, ({ payload }) => {
    const {
      corPrimaria,
      corSecundaria,
      corHeader,
      corHeaderTexto,
      corBackground,
    } = payload;

    if (!!corPrimaria) {
      theme.colors.primary = {
        _50: pSBC(0.95, corPrimaria, false, true),
        _75: pSBC(0.85, corPrimaria, false, true),
        _100: pSBC(0.75, corPrimaria, false, true),
        _200: pSBC(0.45, corPrimaria, false, true),
        _300: pSBC(0.3, corPrimaria, false, true),
        _400: pSBC(0.15, corPrimaria, false, true),
        _500: corPrimaria,
        _600: pSBC(-0.15, corPrimaria, false, true),
        _700: pSBC(-0.35, corPrimaria, false, true),
        _800: pSBC(-0.5, corPrimaria, false, true),
        _900: pSBC(-0.75, corPrimaria, false, true),
      };
      theme._shadows.cards = {
        ...theme._shadows.cards,
        primary: `0px 4px 60px 0 ${corPrimaria}08`,
      };
    } else {
      theme.colors.primary = {
        ...defaultOptions.colors.primary,
      };
      theme._shadows.cards = {
        ...theme._shadows.cards,
        primary: defaultOptions._shadows.cards.primary,
      };
    }
    if (!!corSecundaria) {
      theme.colors.secondary = {
        _50: pSBC(0.95, corSecundaria, false, true),
        _75: pSBC(0.85, corSecundaria, false, true),
        _100: pSBC(0.75, corSecundaria, false, true),
        _200: pSBC(0.45, corSecundaria, false, true),
        _300: pSBC(0.3, corSecundaria, false, true),
        _400: pSBC(0.15, corSecundaria, false, true),
        _500: corSecundaria,
        _600: pSBC(-0.15, corSecundaria, false, true),
        _700: pSBC(-0.35, corSecundaria, false, true),
        _800: pSBC(-0.5, corSecundaria, false, true),
        _900: pSBC(-0.75, corSecundaria, false, true),
      };
      theme._shadows.cards = {
        ...theme._shadows.cards,
        secondary: `0px 4px 60px 0 ${corSecundaria}08`,
      };
    } else {
      theme.colors.secondary = {
        ...defaultOptions.colors.secondary,
      };
      theme._shadows.cards = {
        ...theme._shadows.cards,
        secondary: defaultOptions._shadows.cards.secondary,
      };
    }
    if (!!corHeader) {
      theme.colors.header = corHeader;
    } else {
      theme.colors.header = defaultOptions.colors.header;
    }
    if (!!corHeaderTexto) {
      theme.colors.headerText = corHeaderTexto;
    } else {
      theme.colors.headerText = defaultOptions.colors.headerText;
    }
    if (!!corBackground) {
      theme.colors.background = corBackground;
    } else {
      theme.colors.background = defaultOptions.colors.background;
    }

  });
}
function* resetThemeWatcher() {
  yield takeEvery(types.RESET, () => {
    theme.colors = { ...defaultOptions.colors };
    theme._shadows = { ...defaultOptions._shadows };
  });
}

function* updateThemeWatcher() {
  yield takeEvery(types.ATUALIZAR_USUARIO, function* () {
    const token = jwt.get();
    const usuario = !!token ? jwtDecode(token) : null;

    if (!!usuario?.tema) {
      yield put(actions.aplicarTema(usuario.tema));
    } else {
      yield put(actions.aplicarTema({}));
    }
  });
}

export const sagas = [
  themeWatcher(),
  resetThemeWatcher(),
  updateThemeWatcher(),
];
