import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useCompleteForm from "../../hooks/useCompleteForm";
import { isAtuandoFluxoTrabalho } from "../../selectors/workflow.selectors";
import AlterarInputPropostaDialog from "./AlterarInputPropostaDialog";
import validators from "../../utils/validators";

const AlterarInputPropostaDialogConnected = ({
  handleClose,
  dados: { open, action, type = "string", ...otherData },
}) => {
  const dispatch = useDispatch();
  const submitting = useSelector(isAtuandoFluxoTrabalho);

  const inputProposta = otherData?.contexto?.input;

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      input: validators[type]({
        required: false,
        maxLength: 50,
      }),
    }),
    initialValues: () => ({
      input: "",
    }),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          action({
            ...otherData,
            ...values,
            handleClose,
          })
        );
      },
      [dispatch, otherData, action, handleClose]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      input: inputProposta || "",
    });
  }, [reset, inputProposta]);

  return (
    <AlterarInputPropostaDialog
      handleSubmit={handleSubmit}
      open={open}
      dados={otherData}
      formProps={formProps}
      handleClose={handleClose}
      submitting={submitting}
      title={otherData.title}
      label={otherData.label}
      type={type}
    />
  );
};

export default AlterarInputPropostaDialogConnected;
