import MaterialApoioPage from "./MaterialApoioPage";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as routeActions } from "../../actions/rotas.actions";
import { getApoio } from "../../selectors/apoio.selectors";

const MaterialApoioPageConnected = () => {
  const dispatch = useDispatch();
  const { loading, data } = useSelector(getApoio);

  const handleMaterialClick = useCallback(
    ({ url }) => {
      dispatch(routeActions.openNewWindowWith(url, { target: "_blank" }));
    },
    [dispatch]
  );

  return (
    <MaterialApoioPage
      loading={loading}
      grupos={data}
      handleMaterialClick={handleMaterialClick}
    />
  );
};

export default MaterialApoioPageConnected;
