import { Grid, InputAdornment, styled } from "@mui/material";
import React from "react";
import { Button, Card, Form, GoBack, Title } from "../../../../components";
import ControlledAutocomplete from "../../../../components/inputs/ControlledAutocomplete";
import {
  ControlledRadioGroup,
  ControlledTextField,
  CpfCnpjTextField,
  DateTextField,
  PhoneTextField,
  Radio,
} from "../../../../components/inputs";
import Stepper from "../Stepper";
import { HorizontalLoader } from "../../../../components/loaders";
import UpperCaseTextField from "../../../../components/inputs/UpperCaseTextField";
import { InfoOutlined } from "@mui/icons-material";

const Disclaimer = styled(Title)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.25rem 0 0 0;
  color: ${({ theme }) => theme.colors.error._500};
`;

const DadosBasicosInterForm = ({
  fluxo,
  tipoPessoa,
  escolaridades,
  generos,
  formProps,
  cpfCnpjDisabled,
  handleCpfCnpjChange,
  searching,
  handleSubmit,
  submitting,
  events,
  handleGoTo0,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={handleGoTo0}
          text={"Preencha as informações do comprador"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={0} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Dados pessoais</Title>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label={tipoPessoa === "J" ? "CNPJ" : "CPF"}
                      name={"cpfCnpj"}
                      disabled={cpfCnpjDisabled}
                      formProps={formProps}
                      onBlur={handleCpfCnpjChange}
                      type={tipoPessoa === "F" ? "cpf" : "cnpj"}
                      InputProps={{
                        inputComponent: CpfCnpjTextField,
                        endAdornment: !!searching && (
                          <InputAdornment position="end">
                            <HorizontalLoader size={28} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ControlledTextField
                      label="Nome Completo"
                      name={"nome"}
                      inputProps={{ maxLength: 100 }}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: UpperCaseTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      name={"dataNascimento"}
                      label={"Data de Nascimento"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: DateTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ControlledAutocomplete
                      label="Escolaridade"
                      domain={escolaridades}
                      idField={"id"}
                      labelField={"descricao"}
                      name={"escolaridade"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledAutocomplete
                      label="Gênero"
                      domain={generos}
                      idField={"id"}
                      labelField={"descricao"}
                      name={"genero"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ControlledTextField
                      label="Naturalidade"
                      name={"naturalidade"}
                      inputProps={{ maxLength: 100 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Informações de contato</Title>
                    <Disclaimer as={"h5"}>
                      <InfoOutlined />
                      Preencher as informações de contato reais de seu cliente
                      aumentam as chances de ele ter o crédito aprovado. Algumas
                      instituições (Inter e C6, no momento) estão reprovando
                      clientes com informações de contato incorretas.
                    </Disclaimer>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label="Celular"
                      name="telefone"
                      formProps={formProps}
                      InputProps={{
                        inputComponent: PhoneTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ControlledTextField
                      label="E-mail"
                      name="email"
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Outras Informações</Title>
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledRadioGroup
                      label="Pessoa Politicamente Exposta?"
                      name={"ppe"}
                      tooltip={
                        "Pessoas Politicamente Expostas, também conhecidas como PEPs, são indivíduos que passam por um monitoramento especial das suas movimentações financeiras e fiscais, com objetivo de prevenir a corrupção e crimes como lavagem de dinheiro."
                      }
                      row
                      formProps={formProps}
                    >
                      <Radio value={true} label={"Sim"} />
                      <Radio value={false} label={"Não"} />
                    </ControlledRadioGroup>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledRadioGroup
                      label="US Person?"
                      name={"usPerson"}
                      tooltip={
                        "Caracterizam-se como US PERSON pessoas que possuem cidadania norte-americana, nascidas nos EUA ou com pais norte-americanos. Presença física nos Estados Unidos para fins tributários por pelo menos 31 dias durante o ano corrente ou 183 dias nos últimos 3 anos; E se a pessoa tem responsabilidade de pagar imposto nos EUA."
                      }
                      row
                      formProps={formProps}
                    >
                      <Radio value={true} label={"Sim"} />
                      <Radio value={false} label={"Não"} />
                    </ControlledRadioGroup>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledRadioGroup
                      label="Residente Fiscal no Brasil?"
                      name={"residenciaFiscalBrasil"}
                      tooltip={
                        "Um residente fiscal é alguém que possui domicílio no Brasil ou permanece no país por mais de 183 dias, consecutivos ou não, em um ano-calendário. Isso inclui tanto brasileiros quanto estrangeiros que atendam a esses critérios."
                      }
                      row
                      formProps={formProps}
                    >
                      <Radio value={true} label={"Sim"} />
                      <Radio value={false} label={"Não"} />
                    </ControlledRadioGroup>
                  </Grid>
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosBasicosInterForm;
