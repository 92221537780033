import { Drawer, Grid, InputAdornment, styled } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  IconButton,
  MenuItem,
  Text,
  Title,
  Tooltip,
} from "../../components";
import {
  ControlledSelect,
  ControlledSwitch,
  ControlledTextField,
  UrlSafeTextField,
} from "../../components/inputs";
import { CheckCircleOutline, CopyAll, HighlightOff } from "@mui/icons-material";
import { HorizontalLoader } from "../../components/loaders";
import { transientOptions } from "../../styles/theme";

const StyledDrawer = styled(Drawer, transientOptions)`
  & .MuiDrawer-paper {
    overflow-y: visible;
    border-radius: 1rem 1rem 0 0;
    left: 0;
  }

  ${({ theme, _isOpen_ }) => `${theme.breakpoints.up("sm")} {
  	& .MuiDrawer-paper {
			left: ${_isOpen_ ? theme.sizes.lateralMenu : theme.sizes.lateralMenuCollapsed};
		}
	`}
`;
const SuccessIcon = styled(CheckCircleOutline)`
  color: ${({ theme }) => theme.colors.success._600} !important;
`;
const LinkCalculadora = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.parceiro.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grey._200};
  margin-top: 0.75rem;
`;
const ShareContainer = styled("div")`
  margin-top: 1rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.primary._50};
  border-radius: 1rem;
  border: 1px dashed ${({ theme }) => theme.colors.secondary._600};
`;

const noSubmit = (e) => {
  e.preventDefault();
};

const ConfiguracoesTab = ({
  submitting,
  validating,
  validated,
  url,
  handleUrlBlur,
  formProps,
  handleSubmit,
  editable,
  isMenuOpen,
  promotores,
  isIndicacao,
  linkIndicacao,
  isCalculadoraExterna,
  linkCalculadora,
  handleCopy,
}) => {
  return (
    <Grid item xs={12}>
      <Form onSubmit={!validated ? noSubmit : handleSubmit}>
        <Grid container>
          <Grid item xs={12} lg={6} mb={editable ? 7 : 0}>
            <Card>
              <Title as={"h3"} _margin_={"0 0 1rem 0"}>
                Configurações
              </Title>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ControlledSelect
                    label={
                      "Quem devemos notificar quando tiver atualizações na proposta?"
                    }
                    name={"tipoNotificacao"}
                    formProps={formProps}
                  >
                    <MenuItem value={"RP"}>Representante do Parceiro</MenuItem>
                    <MenuItem value={"AN"}>Comercial da Proposta</MenuItem>
                    <MenuItem value={"RA"}>
                      Representante do Parceiro + Comercial da Proposta
                    </MenuItem>
                  </ControlledSelect>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ControlledTextField
                    label={"URL do Parceiro"}
                    name={"identificadorExterno"}
                    formProps={formProps}
                    disabled={!editable}
                    helperText={
                      !validated ? "URL já utilizada por outro parceiro" : null
                    }
                    error={!validated}
                    onBlur={handleUrlBlur}
                    InputProps={{
                      inputComponent: UrlSafeTextField,
                      endAdornment: !!url && (
                        <InputAdornment position="end">
                          {validating ? (
                            <HorizontalLoader color={"grey"} size={24} />
                          ) : validated ? (
                            <SuccessIcon />
                          ) : (
                            <HighlightOff />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ControlledSelect
                    label={"Comercial Responsável pelos Leads"}
                    name={"usuarioResponsavelLeads"}
                    formProps={formProps}
                  >
                    {promotores?.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.nome}
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </Grid>
                <Grid item xs={12}>
                  <ControlledSwitch
                    label="Habilitar Calculadora Externa?"
                    name={"calculadoraExterna"}
                    disabled={!url || !editable}
                    formProps={formProps}
                  />
                  {isCalculadoraExterna && (
                    <ShareContainer>
                      <Text as={"div"}>
                        Divulgue o link abaixo para gerar leads:
                      </Text>
                      <Grid container columnSpacing={2}>
                        <Grid item>
                          <LinkCalculadora>{linkCalculadora}</LinkCalculadora>
                        </Grid>
                        <Grid item>
                          <Tooltip title={"Copiar"}>
                            <IconButton
                              onClick={() => handleCopy(linkCalculadora)}
                            >
                              <CopyAll />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </ShareContainer>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <ControlledSwitch
                    label="Habilitar Link de Indicação?"
                    name={"indicacao"}
                    disabled={!url || !editable}
                    formProps={formProps}
                  />
                  {isIndicacao && (
                    <ShareContainer>
                      <Text as={"div"}>
                        Divulgue o link abaixo para indicar parceiros:
                      </Text>
                      <Grid container columnSpacing={2}>
                        <Grid item>
                          <LinkCalculadora>{linkIndicacao}</LinkCalculadora>
                        </Grid>
                        <Grid item>
                          <Tooltip title={"Copiar"}>
                            <IconButton
                              onClick={() => handleCopy(linkIndicacao)}
                            >
                              <CopyAll />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </ShareContainer>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {!!editable && (
            <StyledDrawer
              _isOpen_={isMenuOpen}
              variant="permanent"
              anchor={"bottom"}
            >
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                my={1.5}
                px={1.5}
              >
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <Button fullWidth type={"submit"} loading={submitting}>
                    GRAVAR CONFIGURAÇÕES
                  </Button>
                </Grid>
              </Grid>
            </StyledDrawer>
          )}
        </Grid>
      </Form>
    </Grid>
  );
};

export default ConfiguracoesTab;
