import ListaOperacoesPage from "./ListaOperacoesPage";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { getListaOperacoes } from "../../selectors/operacao.selectors";
import { actions } from "../../actions/operacao.actions";
import useCompleteForm from "../../hooks/useCompleteForm";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";
import { getUsuario, getUsuarioLogado } from "../../selectors/usuario.selectors";

const ListaOperacoesPageConnected = () => {
  const dispatch = useDispatch();
  const { data, loading, hasMore, paginaAtual, filtros, totalElementos } =
    useSelector(getListaOperacoes);
  const usuario = useSelector(getUsuarioLogado);
  const [showFilters, setShowFilters] = useState(false);
  const [orderBy, setOrderBy] = useState("qtd_dias_sem_atualizacao");
  const [order, setOrder] = useState("asc");

  const handleOperacaoClick = useCallback((id) => {
    dispatch(routeActions.redirectTo(routes.DETALHES_OPERACAO, { id }));
  }, [dispatch]);

  const handleOpenUltComentarioDialog = useCallback(
    (e, comentario) => {
      e.stopPropagation();
      dispatch(actions.exibirUltComentario(true, comentario));
    },
    [dispatch]
  );

  const handleSort = (property) => {
    if (!loading) {
      const direction =
        orderBy === property && order === "asc" ? "desc" : "asc";
      setOrder(direction);
      setOrderBy(property);
      handleFetchData(formProps.getValues(), property, direction);
    }
  };

  const handleFetchData = (filters, orderBy, order) => {
    dispatch(
      actions.listarOperacoes.request({
        clearData: !!filters,
        filters: filters ?? formProps.getValues(),
        page: filters ? 0 : paginaAtual,
        size: 30,
        orderBy: orderBy,
        order: order,
      })
    );
  };

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => { },
    initialValues: () => ({
      banco: [],
      nome: "",
      cpfCnpj: "",
      valorImovelMin: "",
      valorImovelMax: "",
      valorFinanciamentoMin: "",
      valorFinanciamentoMax: "",
      situacao: null,
      fase: null,
      responsavelFase: [],
      parceiro: null,
      responsavel: null,
      produto:[],
      somentePendentesMinhaAtuacao: false,
      somenteOperacoesComPendencias: false,
    }),
    handleSubmit: useCallback((values) => {
      setShowFilters(false);
      handleFetchData(values, orderBy, order);
    }, []),
  });

  useEffect(() => {
    if (loading && !data.length) {
      handleFetchData(null, orderBy, order);
    }
    dispatch(actions.obterFiltrosOperacoes.request());
  }, []);

  return (
    <ListaOperacoesPage
      loading={loading}
      data={data}
      showFilters={showFilters}
      setShowFilters={setShowFilters}
      filtros={filtros}
      exibeResponsavel={usuario.perfil != "PROMOTOR"}
      handleOperacaoClick={handleOperacaoClick}
      handleOpenUltComentarioDialog={handleOpenUltComentarioDialog}
      handleFetchData={() => handleFetchData(null, orderBy, order)}
      formProps={formProps}
      handleSubmit={handleSubmit}
      hasMore={hasMore}
      totalElementos={totalElementos}
      orderBy={orderBy}
      handleSort={handleSort}
      order={order}
    />
  );
};

export default ListaOperacoesPageConnected;
