import React, { useCallback } from "react";
import DadosBasicosItauForm from "./DadosBasicosItauForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isBuscandoPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import validators from "../../../../utils/validators";
import { actions } from "../../../../actions/operacao.actions";
import {
  removeNonDigitsFromString,
  transformToDate,
} from "../../../../utils/basic";
import { actions as parceiroActions } from "../../../../actions/parceiro.actions";
import formatters from "../../../../utils/formatters";

const DadosBasicosItauFormConnected = ({
  handleGoTo2,
  events,
  comprador,
  setComprador,
  ...props
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { nacionalidade: nacionalidades },
  } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);
  const searching = useSelector(isBuscandoPreenchimentoOperacao);

  const compradorPrincipal = operacao.participantes.find((p) => p.principal);

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setComprador({
          ...c,
          fluxo: data.validacao[
            data.operacao.detalhe.correntista ? "correntista" : "completa"
          ]?.participantes?.find((f) => f.id === c?.id)?.etapas,
        });
      }
      handleGoTo2();
    },
    [handleGoTo2, setComprador, comprador]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      cpfCnpj: validators.cpfCnpj({ required: true }),
      nome: validators.string({
        required: true,
        maxLength: 100,
        custom: {
          nomeCompleto: (value) =>
            !value ||
            (value.indexOf(" ") > 0 &&
              value.substring(value.indexOf(" ") + 1).length > 0) ||
            "Nome informado deve ser completo",
        },
      }),
      dataNascimento: validators.date({
        required: true,
        min: "01/01/1900",
        max: formatters.dates.short(new Date()),
      }),
      nacionalidade: validators.object({ required: true }),
      email: validators.email({ required: true }),
      telefone: validators.phone({ required: true }),
      endereco: {
        cep: validators.string({ required: true }),
        logradouro: validators.string({ required: true, maxLength: 100 }),
        numero: validators.string({ required: true, maxLength: 7 }),
        bairro: validators.string({ required: true, maxLength: 40 }),
        municipio: validators.string({ required: true, maxLength: 60 }),
        uf: validators.string({ required: true }),
      },
    }),
    initialValues: useCallback(
      () => ({
        cpfCnpj: "",
        nome: "",
        dataNascimento: "",
        nacionalidade: null,
        mesmoEnderecoPrincipal: false,
        email: "",
        telefone: "",
        ...comprador,
        endereco: {
          cep: "",
          logradouro: "",
          numero: "",
          complemento: "",
          bairro: "",
          municipio: "",
          uf: "",
          ...comprador?.endereco,
        },
      }),
      [comprador]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-pessoais",
            participante: {
              id: comprador?.id,
              cpfCnpj: removeNonDigitsFromString(values.cpfCnpj),
              nomeParticipante: values.nome,
              dataNascimento: transformToDate(values.dataNascimento),
              idNacionalidade: values.nacionalidade?.id,
              email: values.email,
              telefone: values.telefone,
              mesmoEnderecoPrincipal: comprador?.principal
                ? false
                : values.mesmoEnderecoPrincipal,
              endereco:
                !comprador?.principal && values.mesmoEnderecoPrincipal
                  ? {
                      cep: removeNonDigitsFromString(
                        compradorPrincipal?.endereco?.cep
                      ),
                      logradouro: compradorPrincipal?.endereco?.logradouro,
                      numeroLogradouro: compradorPrincipal?.endereco?.numero,
                      complementoLogradouro:
                        compradorPrincipal?.endereco?.complemento,
                      bairro: compradorPrincipal?.endereco?.bairro,
                      municipio: compradorPrincipal?.endereco?.municipio,
                      uf: compradorPrincipal?.endereco?.uf,
                      idTipoResidencia:
                        compradorPrincipal?.endereco?.tipoResidencia?.id,
                    }
                  : {
                      cep: removeNonDigitsFromString(values.endereco?.cep),
                      logradouro: values.endereco.logradouro,
                      numeroLogradouro: values.endereco.numero,
                      complementoLogradouro: values.endereco.complemento,
                      bairro: values.endereco.bairro,
                      municipio: values.endereco.municipio,
                      uf: values.endereco.uf,
                    },
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, comprador, operacao, callback, compradorPrincipal]
    ),
  });
  const { setValue } = formProps;

  const successZipCodeCallback = useCallback(
    ({ data }) => {
      setValue("endereco.logradouro", data?.logradouro || "", {
        shouldValidate: true,
      });
      setValue("endereco.bairro", data?.bairro || "", { shouldValidate: true });
      setValue("endereco.uf", data?.uf || "", { shouldValidate: true });
      setValue("endereco.municipio", data?.localidade || "", {
        shouldValidate: true,
      });
    },
    [setValue]
  );
  const handleZipCodeBlurred = useCallback(
    (value) => {
      if (removeNonDigitsFromString(value).length === 8) {
        dispatch(
          parceiroActions.pesquisarCep.request({
            value,
            successCallback: successZipCodeCallback,
          })
        );
      }
    },
    [dispatch, successZipCodeCallback]
  );

  const { reset, watch } = formProps;

  const mesmoEnderecoPrincipal = watch("mesmoEnderecoPrincipal");

  const successCallback = useCallback(
    (data) => {
      if (!!data) {
        const c = data.operacao.participantes.find(
          (p) => p.id === comprador.id
        );
        if (!!c) {
          setComprador({
            ...c,
            fluxo: data.validacao[
              data.operacao.detalhe.correntista ? "correntista" : "completa"
            ]?.participantes?.find((f) => f.id === c?.id)?.etapas,
          });
          reset({
            cpfCnpj: "",
            nome: "",
            dataNascimento: "",
            mesmoEnderecoPrincipal: false,
            nacionalidade: null,
            email: "",
            telefone: "",
            ...c,
            endereco: {
              cep: "",
              logradouro: "",
              numero: "",
              complemento: "",
              bairro: "",
              municipio: "",
              uf: "",
              ...c?.endereco,
            },
          });
        }
      }
    },
    [setComprador, comprador, reset]
  );

  const handleCpfCnpjChange = useCallback(
    (v, e) => {
      if (!!v && !e.target.readOnly) {
        dispatch(
          actions.atualizarParticipantePreenchimento.request({
            id: comprador.id,
            cpfCnpj: removeNonDigitsFromString(v),
            tipo: "preenchimento",
            callback: successCallback,
          })
        );
      }
    },
    [dispatch, comprador, successCallback]
  );

  const isSegundoCompradorConjuge =
    !comprador.principal &&
    !!operacao.participantes.find((p) => p.principal)?.conjuge?.compoeRenda;

  return (
    <DadosBasicosItauForm
      {...props}
      tipoPessoa={operacao.detalhe.tipoPessoa}
      nacionalidades={nacionalidades}
      principal={comprador.principal}
      mesmoEnderecoPrincipal={mesmoEnderecoPrincipal}
      isSegundoCompradorConjuge={isSegundoCompradorConjuge}
      formProps={formProps}
      handleZipCodeBlurred={handleZipCodeBlurred}
      handleCpfCnpjChange={handleCpfCnpjChange}
      searching={searching}
      cpfCnpjDisabled={!!comprador.cpfCnpj}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={comprador.fluxo}
      events={events}
    />
  );
};

export default DadosBasicosItauFormConnected;
