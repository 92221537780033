import { Grid, LinearProgress, styled } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  GoBack,
  MenuItem,
  Text,
  Title,
} from "../../../components";
import {
  ControlledRadioGroup,
  ControlledSelect,
  ControlledSwitch,
  ControlledTextField,
  CpfCnpjTextField,
  CurrencyTextField,
  DateTextField,
  IntegerTextField,
  PercentTextField,
  PhoneTextField,
  Radio,
} from "../../../components/inputs";
import { Download, ErrorOutline } from "@mui/icons-material";
import iconePdf from "../../../assets/pdf.png";
import iconeImagem from "../../../assets/imagem.png";
import iconeArquivo from "../../../assets/outros.png";
import formatters from "../../../utils/formatters";
import {
  estados,
  STATUS_DOCUMENTO,
} from "../../../constants/generic.constants";
import AdicionarDocumentoManualDialogConnected from "../../workflow/AdicionarDocumentoManualDialog.connected";

const ContainerErrors = styled(Card)`
  border: 1px dashed ${({ theme }) => theme.colors.error._700};
  background-color: ${({ theme }) => theme.colors.error._100};
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.small};
  padding: 0.5rem 0.75rem;
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const ErrorContainer = styled("div")``;

const ErrorTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.error._600};
  font-weight: 600;
`;

const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.colors.error._600};
`;

const ContainerArquivo = styled(Card)`
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey._50};

    & .MuiSvgIcon-root {
      fill: ${({ theme }) => theme.colors.primary._700};
    }
  }
`;
const NomeArquivo = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  display: block;
  color: ${({ theme }) => theme.colors.grey._400};
`;
const HorarioArquivo = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.tiny};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 800;
  display: inline-block;
  color: ${({ theme }) => theme.colors.grey._200};
`;
const TipoArquivo = styled("img")`
  width: 32px;
  height: auto;
`;

const SituacaoDocumento = styled("span")`
  padding: 0.3rem 0.5rem;
  display: inline-block;
  font-size: ${({ theme }) => theme.fonts.size.operacao.situacao};
  font-weight: 700;
  color: ${({ theme, _situacao_ }) =>
    _situacao_ === "AN"
      ? theme.colors.success._600
      : _situacao_ === "IN"
      ? theme.colors.success._600
      : _situacao_ === "AP"
      ? theme.colors.success._600
      : theme.colors.error._600};
  background-color: ${({ theme, _situacao_ }) =>
    _situacao_ === "AN"
      ? theme.colors.success._100
      : _situacao_ === "IN"
      ? theme.colors.success._100
      : _situacao_ === "AP"
      ? theme.colors.success._100
      : theme.colors.error._100};
  border-radius: 6px;
`;

const NomeDocumento = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 800;
  color: ${({ theme }) => theme.colors.secondary._700};
  margin-bottom: 0.5rem;
`;

const StyleDownloadIcon = styled(Download)`
  fill: ${({ theme }) => theme.colors.primary._500};
`;
const getTipoArquivo = (tipo) => {
  if (tipo.indexOf("pdf") >= 0) {
    return iconePdf;
  }
  if (tipo.indexOf("image") >= 0) {
    return iconeImagem;
  }
  return iconeArquivo;
};

const ValidacaoDocumentoForm = ({
  data,
  erros,
  formProps,
  handleSubmit,
  handleDownload,
  downloading,
  submitting,
  questoes,
  visible,
  updateVisible,
  handleGoBack,
  handleAddDocument,
}) => {
  return (
    <Grid item container spacing={3}>
      <Grid
        container
        columnSpacing={1}
        alignItems={"center"}
        sx={{
          padding: "1rem 0 0 1rem",
        }}
      >
        <Grid item>
          <GoBack handleClick={handleGoBack} text={data.documento.nome} />
        </Grid>
        <Grid item>
          <SituacaoDocumento _situacao_={data.status}>
            {STATUS_DOCUMENTO[data.status]}
          </SituacaoDocumento>
        </Grid>
      </Grid>
      <Grid item container xs={12} md={4} direction={"column"} gap={3}>
        {data?.arquivos.map((a) => (
          <ContainerArquivo
            key={a.arquivo.id}
            onClick={() => handleDownload(a.arquivo)}
          >
            <Grid container justifyContent={"space-between"}>
              <Grid
                item
                container
                columnSpacing={1}
                justifyContent={"space-between"}
                alignItems={"center"}
                flexWrap={"nowrap"}
              >
                <Grid item container columnSpacing={1} alignItems={"center"}>
                  <Grid item>
                    <TipoArquivo src={getTipoArquivo(a.arquivo.tipo)} />
                  </Grid>
                  <Grid
                    item
                    flexDirection={"column"}
                    onClick={() => handleDownload(a.arquivo)}
                  >
                    <NomeArquivo>{a.arquivo.nome}</NomeArquivo>
                    <HorarioArquivo>
                      {formatters.dates.simple(
                        new Date(a.arquivo.dataHoraCriacao),
                        "dd/MM/yyyy HH:mm:ss"
                      )}
                    </HorarioArquivo>
                  </Grid>
                </Grid>
                <Grid item>
                  <StyleDownloadIcon />
                </Grid>
              </Grid>
            </Grid>
            {downloading.indexOf(a.arquivo.id) >= 0 && <LinearProgress />}
          </ContainerArquivo>
        ))}
      </Grid>
      <Grid item container xs={12} md={8}>
        <Grid item container>
          <Grid item container spacing={3}>
            <Grid item container>
              <Grid item width={1}>
                <Card>
                  <Form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      {questoes.map(
                        (questao, i) =>
                          visible[i] && (
                            <Grid
                              key={questao.id}
                              item
                              xs={questao.tamanhoXs}
                              sm={questao.tamanhoSm}
                              md={questao.tamanhoMd}
                              lg={questao.tamanhoLg}
                              xl={questao.tamanhoXl}
                            >
                              <Conteudo
                                formProps={formProps}
                                questao={questao}
                                updateVisible={updateVisible}
                              />
                            </Grid>
                          )
                      )}
                      {!submitting && erros?.length > 0 && (
                        <Grid item xs={12}>
                          <ContainerErrors item>
                            <Grid item display={"flex"} alignItems={"center"}>
                              <ErrorOutline
                                color="error"
                                sx={{ fontSize: 25 }}
                              />
                            </Grid>
                            <Grid
                              item
                              display={"flex"}
                              flexDirection={"column"}
                              gap={1}
                            >
                              <ErrorTitle>
                                {erros?.length > 1
                                  ? "Encontramos algumas inconsistências no documento:"
                                  : "Encontramos uma inconsistência no documento:"}
                              </ErrorTitle>
                              {erros.map((a) => (
                                <Grid key={a.id}>
                                  <ErrorTitle>• {a.recusa}</ErrorTitle>
                                  <ErrorText>
                                    Instrução: {a.instrucao}
                                  </ErrorText>
                                </Grid>
                              ))}
                            </Grid>
                          </ContainerErrors>
                        </Grid>
                      )}

                      <Grid
                        item
                        container
                        justifyContent={"center"}
                        mt={1}
                        columnSpacing={2}
                        gap={1}
                      >
                        {erros?.length > 0 && (
                          <Grid item xs={12} md={4}>
                            <Button
                              onClick={(e) =>
                                handleAddDocument(
                                  e,
                                  data.tipoChecklist,
                                  data.aberturaManual,
                                  data.idDocumento,
                                  data.participanteReferencia
                                )
                              }
                              fullWidth
                              loading={submitting}
                            >
                              NOVO DOCUMENTO
                            </Button>
                          </Grid>
                        )}
                        <Grid item xs={12} md={4}>
                          <Button
                            type={"submit"}
                            fullWidth
                            loading={submitting}
                          >
                            {data.status === "AP" || data.status === "RE"
                              ? "CONFIRMAR"
                              : "VALIDAR"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AdicionarDocumentoManualDialogConnected />
    </Grid>
  );
};

const Conteudo = ({ questao, formProps, updateVisible, ...otherProps }) => {
  switch (questao.tipoCampo) {
    case "TI":
      return <Title as={"h2"}>{questao.label}</Title>;
    case "ST":
      return <Title as={"h5"}>{questao.label}</Title>;
    case "DS":
      return <Text as={"p"}>{questao.label}</Text>;
    case "TX":
      switch (questao.tipoDominio) {
        case "$$":
          return (
            <ControlledTextField
              {...otherProps}
              label={questao.label}
              name={questao.codigo}
              tooltip={questao.tooltip}
              formProps={formProps}
              InputProps={{
                inputComponent: CurrencyTextField,
              }}
              onChange={() => updateVisible(formProps.getValues())}
            />
          );
        case "CP":
          return (
            <ControlledTextField
              {...otherProps}
              label={questao.label}
              name={questao.codigo}
              type="both"
              tooltip={questao.tooltip}
              formProps={formProps}
              InputProps={{
                inputComponent: CpfCnpjTextField,
              }}
              onChange={() => updateVisible(formProps.getValues())}
            />
          );
        case "PE":
          return (
            <ControlledTextField
              {...otherProps}
              label={questao.label}
              name={questao.codigo}
              tooltip={questao.tooltip}
              formProps={formProps}
              InputProps={{
                inputComponent: PercentTextField,
              }}
              inputProps={{
                allowNegative: false,
              }}
              onChange={() => updateVisible(formProps.getValues())}
            />
          );
        case "PH":
          return (
            <ControlledTextField
              {...otherProps}
              label={questao.label}
              name={questao.codigo}
              tooltip={questao.tooltip}
              formProps={formProps}
              InputProps={{
                inputComponent: PhoneTextField,
              }}
              onChange={() => updateVisible(formProps.getValues())}
            />
          );
        case "IN":
          return (
            <ControlledTextField
              {...otherProps}
              label={questao.label}
              name={questao.codigo}
              tooltip={questao.tooltip}
              formProps={formProps}
              InputProps={{
                inputComponent: IntegerTextField,
              }}
              onChange={() => updateVisible(formProps.getValues())}
            />
          );
        case "DT":
          return (
            <ControlledTextField
              {...otherProps}
              label={questao.label}
              name={questao.codigo}
              tooltip={questao.tooltip}
              formProps={formProps}
              InputProps={{
                inputComponent: DateTextField,
              }}
              onChange={() => updateVisible(formProps.getValues())}
            />
          );
        default:
          return (
            <ControlledTextField
              {...otherProps}
              label={questao.label}
              name={questao.codigo}
              tooltip={questao.tooltip}
              formProps={formProps}
              onChange={() => updateVisible(formProps.getValues())}
            />
          );
      }
    case "CX":
      return (
        <ControlledSwitch
          {...otherProps}
          label={questao.label}
          name={questao.codigo}
          tooltip={questao.tooltip}
          formProps={formProps}
          onChange={() => updateVisible(formProps.getValues())}
        />
      );
    case "RB":
      return (
        <ControlledRadioGroup
          {...otherProps}
          label={questao.label}
          name={questao.codigo}
          row
          tooltip={questao.tooltip}
          formProps={formProps}
          onChange={() => updateVisible(formProps.getValues())}
        >
          {questao.opcoes.map((o) => (
            <Radio key={o.id} value={o.codigo} label={o.descricao} />
          ))}
        </ControlledRadioGroup>
      );
    case "AC":
    case "SE":
      switch (questao.tipoDominio) {
        case "UF":
          return (
            <ControlledSelect
              {...otherProps}
              key={questao.id}
              label={questao.label}
              name={questao.codigo}
              tooltip={questao.tooltip}
              formProps={formProps}
              onChange={() => updateVisible(formProps.getValues())}
            >
              {estados.map((uf, index) => (
                <MenuItem key={index} value={uf}>
                  {uf}
                </MenuItem>
              ))}
            </ControlledSelect>
          );
        default:
          return (
            <ControlledSelect
              {...otherProps}
              key={questao.id}
              label={questao.label}
              name={questao.codigo}
              tooltip={questao.tooltip}
              formProps={formProps}
              onChange={() => updateVisible(formProps.getValues())}
            >
              {questao.opcoes.map((o) => (
                <MenuItem key={o.id} value={o.codigo}>
                  {o.descricao}
                </MenuItem>
              ))}
            </ControlledSelect>
          );
      }
    default:
      return null;
  }
};

export default ValidacaoDocumentoForm;
