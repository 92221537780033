import React from "react";
import {
  ControlledTextField,
  CpfCnpjTextField,
  PhoneTextField,
} from "../../components/inputs";
import { Grid } from "@mui/material";
import validators from "../../utils/validators";

export const formValues = {
  rules: (_, tipoEdicao) => ({
    cnpj: validators.cnpj({ required: tipoEdicao === "O" }),
    razaoSocial: validators.string({ required: tipoEdicao === "O" }),
    nomeFantasia: validators.string({ required: tipoEdicao === "O" }),
    site: validators.string({ required: tipoEdicao === "O" }),
    telefoneComercial: validators.phone({ required: tipoEdicao === "O" }),
  }),
  defaultValues: (data = {}) => ({
    cnpj: "",
    razaoSocial: "",
    nomeFantasia: "",
    site: "",
    telefoneComercial: "",
    ...data,
  }),
};

const DadosEmpresaForm = ({ formProps, forceDisabled, isSignUp }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ControlledTextField
          label="CNPJ"
          disabled={!isSignUp || forceDisabled}
          name={"cnpj"}
          formProps={formProps}
          type={"cnpj"}
          InputProps={{
            inputComponent: CpfCnpjTextField,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="Razão Social"
          disabled={forceDisabled}
          name={"razaoSocial"}
          inputProps={{ maxLength: 100 }}
          formProps={formProps}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="Nome Fantasia"
          disabled={forceDisabled}
          name={"nomeFantasia"}
          inputProps={{ maxLength: 100 }}
          formProps={formProps}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="Site"
          disabled={forceDisabled}
          name={"site"}
          inputProps={{ maxLength: 100 }}
          formProps={formProps}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="Telefone Comercial"
          name="telefoneComercial"
          disabled={forceDisabled}
          formProps={formProps}
          margin={"none"}
          InputProps={{
            inputComponent: PhoneTextField,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DadosEmpresaForm;
