import React, { useCallback, useEffect, useMemo } from "react";
import DadosContatoImovelBradescoForm from "./DadosContatoImovelBradescoForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import { useFieldArray } from "react-hook-form";
import useResponsive from "../../../../../hooks/useResponsive";

const DadosContatoImovelBradescoFormConnected = ({
  handleGoTo0,
  eventsImovel,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { operacao, validacao } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);

  const imovel = useMemo(
    () => ({
      ...operacao.imovel,
      fluxo: validacao.completa.imovel,
    }),
    [operacao, validacao]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      contatosVistoria: {
        nome: validators.string({ required: true }),
        telefone: validators.phone({ required: true }),
        email: validators.email({ required: true }),
      },
    }),
    initialValues: useCallback(
      () => ({
        contatosVistoria: [],
        ...imovel,
      }),
      [imovel]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback: handleGoTo0,
            instituicao: operacao.codigoInstituicao,
            passo: "contatos-imovel",
            operacao: {
              id: operacao.id,
              imovel: {
                contatosVistoria: values.contatosVistoria,
              },
            },
          })
        );
      },
      [dispatch, operacao, handleGoTo0]
    ),
  });

  const contatos = useFieldArray({
    control: formProps.control,
    name: "contatosVistoria",
  });

  const handleAdicionarContato = useCallback(() => {
    contatos.append({ nome: "", telefone: "", email: "" });
  }, [contatos]);
  const handleRemoverContato = useCallback(
    (index) => {
      contatos.remove(index);
    },
    [contatos]
  );

  const { reset } = formProps;

  useEffect(() => {
    reset({
      contatosVistoria: [],
      ...imovel,
    });
  }, [imovel, reset]);

  const currentSize = useResponsive();
  const isMobile = currentSize === "xs" || currentSize === "sm";

  return (
    <DadosContatoImovelBradescoForm
      {...otherProps}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={imovel.fluxo}
      events={eventsImovel}
      contatos={contatos}
      handleAdicionarContato={handleAdicionarContato}
      handleRemoverContato={handleRemoverContato}
      isMobile={isMobile}
    />
  );
};

export default DadosContatoImovelBradescoFormConnected;
