import { delay, put, select, takeEvery } from "redux-saga/effects";
import basicFlow, { genericErrorHandler } from "./asyncHandler";
import { actions as operacaoActions } from "../actions/operacao.actions";
import { actions as parceiroActions } from "../actions/parceiro.actions";
import { actions, types } from "../actions/workflow.actions";
import { types as routes } from "../actions/rotas.actions";
import { authenticatedRequest, destinies } from "../utils/api";
import {
  getComentarios,
  getDocumentoManual,
} from "../selectors/workflow.selectors";
import { toast } from "react-toastify";
import {
  getCurrentRoute,
  getRouteArguments,
} from "../selectors/routes.selectors";
import { transformToDate } from "../utils/basic";

const carregarComentarios = basicFlow({
  actionGenerator: actions.carregarComentarios,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/workflow/${id}/comentarios`,
      isResourceService: true,
      destiny: destinies.WORKFLOW,
      method: "get",
    });
  },
});
const gravarComentario = basicFlow({
  actionGenerator: actions.gravarComentario,
  transform: function* ({ comentario, confidencial }) {
    const { id } = yield select(getComentarios);
    return {
      fluxoTrabalho: id,
      texto: comentario,
      confidencial: confidencial,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/workflow/${values.fluxoTrabalho}/comentario`,
      isResourceService: true,
      destiny: destinies.WORKFLOW,
      method: "post",
      body: values,
    });
  },
  postSuccess: ({ original: { callback } }) => {
    toast.success("Comentário incluído com sucesso");
    if (!!callback) {
      callback();
    }
  },
});

const atualizarComentario = basicFlow({
  actionGenerator: actions.atualizarComentario,
  transform: function* ({ id: idComentario, comentario, confidencial }) {
    const { id } = yield select(getComentarios);
    return {
      id: idComentario,
      fluxoTrabalho: id,
      texto: comentario,
      confidencial: confidencial,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/workflow/${values.fluxoTrabalho}/comentario`,
      isResourceService: true,
      destiny: destinies.WORKFLOW,
      method: "put",
      body: values,
    });
  },
  postSuccess: function* ({ original }) {
    toast.success("Comentário atualizado com sucesso");
    if (!!original.callback) {
      original.callback();
    }
    const { id } = yield select(getComentarios);
    yield put(actions.carregarComentarios.request({ id }))
  },
});

const destacarComentario = basicFlow({
  actionGenerator: actions.destacarComentario,
  transform: function* (idComentario) {
    const { id } = yield select(getComentarios);
    return {
      id: idComentario,
      fluxoTrabalho: id,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/workflow/${values.fluxoTrabalho}/comentario/${values.id}/destacar`,
      isResourceService: true,
      destiny: destinies.WORKFLOW,
      method: "post",
    });
  },
  postSuccess: function* ({ original }) {
    if (!!original.callback) {
      original.callback();
    }
  },
});

const excluirComentario = basicFlow({
  actionGenerator: actions.excluirComentario,
  transform: function* (idComentario) {
    const { id } = yield select(getComentarios);
    return {
      id: idComentario,
      fluxoTrabalho: id,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/workflow/${values.fluxoTrabalho}/comentario`,
      isResourceService: true,
      destiny: destinies.WORKFLOW,
      method: "delete",
      body: values,
    });
  },
  postSuccess: function* ({ original }) {
    toast.success("Comentário excluído com sucesso");
    if (!!original.callback) {
      original.callback();
    }
    const { id } = yield select(getComentarios);
    yield put(actions.carregarComentarios.request({ id }))
  },
});

const carregarDominiosDocumentoManual = basicFlow({
  actionGenerator: actions.carregarDominiosDocumentoManual,
  api: (values) => {
    return authenticatedRequest({
      url: `/documento-manual`,
      isResourceService: true,
      destiny: destinies.DOCUMENTO,
      queryParams: values,
      method: "get",
    });
  },
});
const gerarDocumentoOperacaoManual = basicFlow({
  actionGenerator: actions.gerarDocumentoOperacaoManual,
  transform: ({
    referencia,
    documento,
    comentario,
    checklist,
    id,
    aberturaManual,
  }) => {
    const [idParticipante, tipoReferencia] = referencia.split("|");

    return {
      operacao: id,
      documento: documento.id,
      referencia: tipoReferencia,
      participante: idParticipante,
      checklist,
      comentario,
      aberturaManual: !!aberturaManual,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/documento-operacao-manual`,
      isResourceService: true,
      destiny: destinies.DOCUMENTO,
      body: values,
      method: "post",
    });
  },
  postSuccess: ({ original: { callback } }) => {
    toast.success("Documento adicionado com sucesso");
    if (!!callback) {
      callback();
    }
  },
});

const gerarDocumentoParceiroManual = basicFlow({
  actionGenerator: actions.gerarDocumentoParceiroManual,
  transform: ({ referencia, documento, comentario, checklist, id }) => {
    const [, tipoReferencia] = referencia.split("|");

    return {
      parceiro: id,
      documento: documento.id,
      referencia: tipoReferencia,
      checklist,
      comentario,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/documento-parceiro-manual`,
      isResourceService: true,
      destiny: destinies.DOCUMENTO,
      body: values,
      method: "post",
    });
  },
  postSuccess: ({ original: { callback } }) => {
    toast.success("Documento adicionado com sucesso");
    if (!!callback) {
      callback();
    }
  },
});

const atuarFluxoTrabalho = basicFlow({
  actionGenerator: actions.atuarFluxoTrabalho,
  transform: (payload) => {
    return {
      etapaTrabalho: payload.etapa.id,
      acao: payload.acao.id,
      comentario: payload.motivo,
      confidencial: false,
      contexto: payload.contexto,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/workflow/acao`,
      method: "post",
      body: values,
      isResourceService: true,
      destiny: destinies.WORKFLOW,
    });
  },
  preSuccess: function* ({ original }) {
    const { callback } = original;
    if (!!callback) {
      yield delay(1000);
      yield callback({ ...original });
    }
  },
  postFailure: function* ({ error, original }) {
    yield genericErrorHandler({ error });
    if (!!original.handleClose) {
      original.handleClose();
    }
  },
});

const cancelarFluxoTrabalho = basicFlow({
  actionGenerator: actions.cancelarFluxoTrabalho,
  transform: (payload) => {
    return {
      id: payload.workflow,
      comentario: payload.motivo,
      contexto: payload.contexto,
    };
  },
  api: ({ id, ...values }) => {
    return authenticatedRequest({
      url: `/workflow/${id}`,
      method: "delete",
      body: values,
      isResourceService: true,
      destiny: destinies.WORKFLOW,
    });
  },
  preSuccess: function* ({ original }) {
    const { callback } = original;
    if (!!callback) {
      yield delay(1000);
      yield callback({ ...original });
    }
    if (!!original.handleClose) {
      original.handleClose();
    }
  },
  postFailure: function* ({ error, original }) {
    yield genericErrorHandler({ error });
    if (!!original.handleClose) {
      original.handleClose();
    }
  },
});

const cancelarParceiro = basicFlow({
  actionGenerator: actions.cancelarParceiro,
  transform: (payload) => {
    return {
      comentario: payload.motivo,
      idParceiro: payload.contexto.id_parceiro,
    };
  },
  api: ({ ...values }) => {
    return authenticatedRequest({
      url: `/parceiro/${values.idParceiro}/cancelar`,
      method: "post",
      body: values,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
    });
  },
  preSuccess: function* ({ original }) {
    const { callback } = original;
    if (!!callback) {
      yield delay(1000);
      yield callback({ ...original });
    }
    if (!!original.handleClose) {
      original.handleClose();
    }
  },
  postFailure: function* ({ error, original }) {
    yield genericErrorHandler({ error });
    if (!!original.handleClose) {
      original.handleClose();
    }
  },
});

const suspenderParceiro = basicFlow({
  actionGenerator: actions.suspenderParceiro,
  transform: (payload) => {
    return {
      comentario: payload.motivo,
      idParceiro: payload.contexto.id_parceiro,
    };
  },
  api: ({ ...values }) => {
    return authenticatedRequest({
      url: `/parceiro/${values.idParceiro}/suspender`,
      method: "post",
      body: values,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
    });
  },
  preSuccess: function* ({ original }) {
    const { callback } = original;
    if (!!callback) {
      yield delay(1000);
      yield callback({ ...original });
    }
    if (!!original.handleClose) {
      original.handleClose();
    }
  },
  postFailure: function* ({ error, original }) {
    yield genericErrorHandler({ error });
    if (!!original.handleClose) {
      original.handleClose();
    }
  },
});

const reativarParceiro = basicFlow({
  actionGenerator: actions.reativarParceiro,
  transform: (payload) => {
    return {
      comentario: payload.motivo,
      idParceiro: payload.contexto.id_parceiro,
    };
  },
  api: ({ ...values }) => {
    return authenticatedRequest({
      url: `/parceiro/${values.idParceiro}/reativar`,
      method: "post",
      body: values,
      isResourceService: true,
      destiny: destinies.PARCEIRO,
    });
  },
  preSuccess: function* ({ original }) {
    const { callback } = original;
    if (!!callback) {
      yield delay(1000);
      yield callback({ ...original });
    }
    if (!!original.handleClose) {
      original.handleClose();
    }
  },
  postFailure: function* ({ error, original }) {
    yield genericErrorHandler({ error });
    if (!!original.handleClose) {
      original.handleClose();
    }
  },
});

const atualizarNumeroProposta = basicFlow({
  actionGenerator: actions.atualizarNumeroProposta,
  api: (values) => {
    return authenticatedRequest({
      url: `/detalhe-operacao/${values.contexto.id_operacao}/numero-proposta`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "post",
      queryParams: {
        id: values.contexto.id_operacao,
        proposta: values.input,
      },
    });
  },
  postSuccess: function* ({ original }) {
    if (!!original.callback) {
      yield original.callback(original);
    }

    toast.success("Número da proposta atualizado.");
  },
});

const atualizarNumeroContrato = basicFlow({
  actionGenerator: actions.atualizarNumeroContrato,
  api: (values) => {
    return authenticatedRequest({
      url: `/detalhe-operacao/${values.contexto.id_operacao}/numero-contrato`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "post",
      queryParams: {
        id: values.contexto.id_operacao,
        contrato: values.input,
      },
    });
  },
  postSuccess: function* ({ original }) {
    if (!!original.callback) {
      yield original.callback(original);
    }

    toast.success("Número do contrato atualizado.");
  },
});

const atualizarDataEmissao = basicFlow({
  actionGenerator: actions.atualizarDataEmissao,
  transform: (payload) => {
    return {
      ...payload,
      input: !!payload.input ? transformToDate(payload.input) : null,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/detalhe-operacao/${values.contexto.id_operacao}/data-emissao`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "post",
      queryParams: {
        id: values.contexto.id_operacao,
        dataEmissao: values.input,
      },
    });
  },
  postSuccess: function* ({ original }) {
    if (!!original.callback) {
      yield original.callback(original);
    }

    toast.success("Data de emissão atualizada.");
  },
});


const atualizarCodigoRastreamento = basicFlow({
  actionGenerator: actions.atualizarCodigoRastreamento,
  api: (values) => {
    return authenticatedRequest({
      url: `/detalhe-operacao/${values.contexto.id_operacao}/codigo-rastreamento`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "post",
      queryParams: {
        id: values.contexto.id_operacao,
        codigoRastreamento: values.input,
      },
    });
  },
  postSuccess: function* ({ original }) {
    if (!!original.callback) {
      yield original.callback(original);
    }

    toast.success("Código Rastreamento atualizado.");
  },
});


function* exibirDocumentoManualWatcher() {
  yield takeEvery(types.EXIBIR_DOCUMENTO_MANUAL, function* ({ payload }) {
    const { show, ...values } = payload;

    if (!!show) {
      yield put(actions.carregarDominiosDocumentoManual.request(values));
    } else {
      const { novo } = yield select(getDocumentoManual);
      if (!!novo) {
        const currentRoute = yield select(getCurrentRoute);
        const payload = yield select(getRouteArguments);

        if (currentRoute === routes.DETALHES_PARCEIRO) {
          yield put(parceiroActions.obterDocumentos.request(payload));
        } else {
          yield put(operacaoActions.obterDocumentos.request(payload));
        }
      }
    }
  });
}

export const sagas = [
  atualizarNumeroProposta.watcher(),
  carregarComentarios.watcher(),
  gravarComentario.watcher(),
  atualizarComentario.watcher(),
  excluirComentario.watcher(),
  destacarComentario.watcher(),
  carregarDominiosDocumentoManual.watcher(),
  gerarDocumentoOperacaoManual.watcher(),
  gerarDocumentoParceiroManual.watcher(),
  atuarFluxoTrabalho.watcher(),
  cancelarFluxoTrabalho.watcher(),
  cancelarParceiro.watcher(),
  suspenderParceiro.watcher(),
  reativarParceiro.watcher(),
  atualizarNumeroContrato.watcher(),
  atualizarDataEmissao.watcher(),
  atualizarCodigoRastreamento.watcher(),
  exibirDocumentoManualWatcher(),
];
