import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  NAVEGAR: "@@calculadora/NAVEGAR",
  BUSCAR: "@@calculadora/BUSCAR",
  VALIDAR: "@@calculadora/VALIDAR",
  SELECIONAR: "@@calculadora/SELECIONAR",
  CALCULAR: "@@calculadora/CALCULAR",
  GRAVAR_LEAD: "@@calculadora/GRAVAR_LEAD",
  DUPLICAR_LEAD: "@@calculadora/DUPLICAR_LEAD",
  PARCEIROS: "@@calculadora/PARCEIROS",
  CANCELAR_REGRA_COMERCIAL: "@@calculadora/CANCELAR_REGRA_COMERCIAL",
};

export const actions = {
  navegar: (payload) => createSyncAction(types.NAVEGAR, payload),
  buscarCalculadoras: createAsyncAction(types.BUSCAR),
  validarParceiro: createAsyncAction(types.VALIDAR),
  selecionarCalculadora: (payload) =>
    createSyncAction(types.SELECIONAR, payload),
  calcular: createAsyncAction(types.CALCULAR),
  gravarLead: createAsyncAction(types.GRAVAR_LEAD),
  duplicarLead: createAsyncAction(types.DUPLICAR_LEAD),
  obterParceiros: createAsyncAction(types.PARCEIROS),
  cancelarRegraComercial:createAsyncAction(types.CANCELAR_REGRA_COMERCIAL),
};
