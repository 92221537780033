import { CloseSharp, ContentCopy } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment as MInputAdornment,
  Skeleton,
  styled,
} from "@mui/material";
import { Title, Tooltip } from "../../components";
import { TextField } from "../../components/inputs";

const InputAdornment = styled(MInputAdornment)`
  cursor: pointer;
`;
const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    width: 100%;
  }
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;
const CompartilhamentoDialog = ({ handleClose, open, loading, data, handleCopyText, ...props }) => {
  return (
    <StyledDialog
      onClose={() => handleClose()}
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
    >
      {open ?
        <DialogContent>
          <CloseIconButton onClick={handleClose}>
            <CloseSharp />
          </CloseIconButton>
          <Title as={"h2"} _margin_={"0 0 1rem 0"}>
            Compartilhar Operação
          </Title>
          <TextField
            value={window.location.origin + "/externo/" + data}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={() => handleCopyText(window.location.origin + "/externo/" + data)}>
                  <ContentCopy />
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        : null}
    </StyledDialog>
  );
};

export default CompartilhamentoDialog;