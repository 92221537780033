import CommonPage from "../CommonPage";
import {
  Button,
  Card,
  Title,
  ToggleButtonGroup,
  Tooltip,
} from "../../components";
import {
  Autocomplete,
  Badge,
  Grid,
  IconButton,
  InputAdornment,
  keyframes,
  Skeleton,
  styled,
  ToggleButton,
} from "@mui/material";
import formatters from "../../utils/formatters";
import {
  Cancel,
  CheckCircle,
  ChevronRight,
  Download,
  Help,
  House,
  RemoveCircle,
  Search,
  Star,
} from "@mui/icons-material";
import { TextField } from "../../components/inputs";
import React, { useCallback, useState } from "react";
import { transientOptions } from "../../styles/theme";
import { TablePagination } from "../../components/tables";
import { logos } from "../../constants/generic.constants";
import MenuItem from "../../components/MenuItem";

const SkeletonFilter = styled(Skeleton)`
  width: 80px;
  height: 60px;
  border-radius: 1rem;
  padding: 1.5rem 1rem;
`;
const SkeletonCard = styled(Skeleton)`
  width: 100%;
  height: 390px;
  border-radius: 1rem;
`;
const FilterItemContainer = styled("div", transientOptions)`
  width: 80px;
  height: 70px;
  padding: 1.3rem 1rem;
  background-color: ${({ theme }) => theme.colors.fixed.white};
  font-family: ${({ theme }) => theme.fonts.family.default};
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme, _selected_ }) =>
    _selected_ ? theme.colors.secondary._400 : theme.colors.grey._400};
  font-weight: ${({ _selected_ }) => (_selected_ ? 700 : 400)};
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, _selected_ }) =>
    _selected_ ? theme.colors.secondary._400 : theme.colors.grey._50};
  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary._600};
    color: ${({ theme }) => theme.colors.secondary._600};
    font-weight: 700;
  }
`;
const FilterItemLabel = styled("div")`
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.size.cliente.filtro.texto};
`;
const FilterItemValue = styled("div")`
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.size.cliente.filtro.quantidade};
`;
const FilterItemSubValue = styled("div")`
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.size.cliente.filtro.valor};
`;

const ClienteContainer = styled("div")`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  border-width: 1px;
  border-style: solid;
  justify-content: space-between;
  border-color: ${({ theme }) => theme.colors.grey._75};
  padding: 1rem;
  height: calc(100% - 2rem);
  font-family: ${({ theme }) => theme.fonts.family.default};

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey._100};
  }
`;
const NomeCliente = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.cliente.card.nomeCliente};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.grey._400};
  text-transform: uppercase;
  margin-bottom: 0.1rem;
`;
const CpfCnpjCliente = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.cliente.card.cpfCnpj};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grey._300};
`;
const NomeParceiro = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.cliente.card.parceiro};
  text-align: left;
  font-weight: 700;
  margin-bottom: 0.25rem;
  color: ${({ theme }) => theme.colors.secondary._600};
`;
const Instrucao = styled("div", transientOptions)`
  margin-top: 0.5rem;
  font-size: ${({ theme }) => theme.fonts.size.cliente.card.cpfCnpj};
  font-weight: 300;
  text-align: ${({ _align_ }) => _align_ ?? "left"};
  color: ${({ theme }) => theme.colors.grey._700};
`;
const Operacao = styled("div")`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.grey._75};
`;
const TipoOperacao = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.cliente.card.operacao.tipo};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey._300};
`;
const ValorOperacao = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.cliente.card.operacao.valor};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary._500};
`;
const Situacao = styled("span")`
  display: inline-block;
  font-size: ${({ theme }) => theme.fonts.size.parceiro.situacao};
  font-weight: 700;
  color: ${({ theme, _color_ }) => _color_ ?? theme.colors.success._600};
`;

const ClienteButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary._75};
  margin-top: 0.75rem;

  &.MuiButton-text:hover {
    background-color: ${({ theme }) => theme.colors.primary._100};
  }
`;
const ActionButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.primary._75};
  color: ${({ theme }) => theme.colors.primary._500};
  border-radius: 0.5rem;
  margin-left: auto;

  &:hover {
    color: ${({ theme }) => theme.colors.primary._600};
    background-color: ${({ theme }) => theme.colors.primary._100};
  }
`;
const Logo = styled("img")`
  width: 50px;
  height: 50px;
  border-radius: 0.5rem;
  object-fit: contain;
  display: block;
  margin-right: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey._75};
`;
const SeeMorePill = styled("div")`
  cursor: pointer;
  margin: 0.5rem auto 0;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.grey._100};
  font-size: ${({ theme }) => theme.fonts.size.cliente.card.cpfCnpj};
  font-weight: 300;
  text-align: ${({ _align_ }) => _align_ ?? "left"};
  color: ${({ theme }) => theme.colors.grey._800};
  padding: 0.2rem 1rem;
  width: fit-content;
`;
const DownloadButton = styled(IconButton)`
  padding: 5.7px 14px;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.grey._50};
`;
const StyledTextField = styled(TextField)`
  min-width: 260px;

  & .MuiInputBase-adornedEnd {
    padding-right: 0.6rem;
    cursor: pointer;
  }
`;

const Disclaimer = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme, _size_ = "default" }) => theme.fonts.size[_size_]};
  font-weight: 300;
  color: inherit;
  margin: 0;
  white-space: pre-wrap;
`;
const pulseGreen = keyframes`
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(21, 224, 78, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 8px rgba(21, 224, 78, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(21, 224, 78, 0);
	}
`;
const pulseRed = keyframes`
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(230, 55, 87, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 8px rgba(230, 55, 87, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(230, 55, 87, 0);
	}
`;
const pulseBlue = keyframes`
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(27, 139, 209, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 8px rgba(27, 139, 209, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(27, 139, 209, 0);
	}
`;
const StyledBadge = styled(Badge, transientOptions)`
  & .MuiBadge-badge {
		border-radius: 200px;
    right: 0.75rem;
		top: -0.25rem;
    background-color: ${({ theme, _color_ }) => theme.colors[_color_]._500};
    border: 2px solid ${({ theme }) => theme.colors.fixed.white};
    padding: 0;
		min-width: 0.75rem;
		min-height: 0.75rem;

		box-shadow: 0 0 0 0 ${({ _color_ }) =>
      _color_ === "success"
        ? "rgba(21, 224, 78, 1)"
        : _color_ === "error"
        ? "rgba(230, 55, 87, 1)"
        : "rgba(27, 139, 209, 1)"};
		transform: scale(1);
		animation: ${({ _color_ }) =>
      _color_ === "success"
        ? pulseGreen
        : _color_ === "error"
        ? pulseRed
        : pulseBlue} 2s infinite;
  }
`;
const StarBadge = ({ isVisible, imovelDefinido, children }) => {
  return isVisible ? (
    <StyledBadge
      badgeContent={" "}
      variant={"dot"}
      _color_={
        imovelDefinido === true
          ? "success"
          : imovelDefinido === false
          ? "error"
          : "info"
      }
    >
      {children}
    </StyledBadge>
  ) : (
    <> {children}</>
  );
};
const ImovelDefinido = styled(Badge)`
  color: ${({ theme }) => theme.colors.grey._200};

  .Mui-selected & {
    color: ${({ theme }) => theme.colors.grey._600};
  }
`;
const StyledImovelDefinido = styled(CheckCircle, transientOptions)`
  font-size: 1rem;
  color: ${({ theme, _color_ }) =>
    _color_ === "success"
      ? theme.colors.success._500
      : _color_ === "error"
      ? theme.colors.error._500
      : _color_ === "info"
      ? theme.colors.info._500
      : theme.colors.grey._300};
  margin-top: 0.25rem;
  margin-right: 0.25rem;
`;

const FilterItem = ({
  label,
  quantity,
  ammount,
  value,
  selected,
  handleClick,
}) => {
  return (
    <FilterItemContainer
      _selected_={selected === value}
      onClick={() => handleClick(value)}
    >
      <FilterItemLabel>{label}</FilterItemLabel>
      <Grid display={"flex"} flexDirection={"column"}>
        <FilterItemValue>
          {formatters.numbers.nFixed(quantity, 0)}
        </FilterItemValue>
        <FilterItemSubValue>
          {formatters.numbers.nFixed(ammount / 1000000, 1)}&nbsp;MM
        </FilterItemSubValue>
      </Grid>
    </FilterItemContainer>
  );
};

const Cliente = ({
  data,
  expanded,
  setExpanded,
  exibeParceiro,
  handleClienteClick,
  handleOperacaoClick,
}) => {
  const isExpanded = expanded.indexOf(data.id) >= 0;
  const addToExpanded = () => setExpanded([...expanded, data.id]);
  const removeFromExpanded = () =>
    setExpanded([...expanded.filter((f) => f !== data.id)]);

  return (
    <ClienteContainer>
      <Grid>
        {exibeParceiro && <NomeParceiro>{data.parceiro}</NomeParceiro>}
        <NomeCliente>{data.nome}</NomeCliente>
        <CpfCnpjCliente>
          {formatters.strings.cpfCnpj(data.cpfCnpj)}
        </CpfCnpjCliente>
        {data.operacoes.length === 0 && (
          <Instrucao>Nenhuma operação em andamento</Instrucao>
        )}
        {data.operacoes.length > 0 && (
          <>
            <Instrucao>Operações em andamento:</Instrucao>
            {data.operacoes
              .sort((a, b) => b.ordem - a.ordem)
              .slice(0, isExpanded ? data.operacoes.length : 4)
              .map((o, i) => (
                <Operacao key={o.id}>
                  <StarBadge
                    isVisible={o.tipoProduto !== "L"}
                    imovelDefinido={o.imovelDefinido}
                  >
                    {o.tipoProduto === "L" ? (
                      o.tipoProdutoLead === "I" ? (
                        <Logo src={logos["lead-aquisicao"]} />
                      ) : o.tipoProdutoLead === "C" ? (
                        <Logo src={logos["lead-consorcio"]} />
                      ) : o.tipoProdutoLead === "H" ? (
                        <Logo src={logos["lead-cgi"]} />
                      ) : (
                        <Logo src={logos.lead} />
                      )
                    ) : (
                      <Logo src={logos[o.codigoInstituicao]} />
                    )}
                  </StarBadge>
                  <Grid>
                    <TipoOperacao>{o.nomeProduto}</TipoOperacao>
                    {!!o.valorOperacao && (
                      <ValorOperacao>
                        R$&nbsp;{formatters.numbers.currency(o.valorOperacao)}
                      </ValorOperacao>
                    )}
                    <Situacao _color_={o.corBadge}>{o.situacao}</Situacao>
                  </Grid>
                  <ActionButton onClick={() => handleOperacaoClick(o.id)}>
                    <ChevronRight />
                  </ActionButton>
                </Operacao>
              ))}
            {!isExpanded && data.operacoes.length > 4 && (
              <SeeMorePill _align_={"center"} onClick={addToExpanded}>
                Ver + {data.operacoes.length - 4}{" "}
                {data.operacoes.length - 4 > 1 ? "operações" : "operação"}
              </SeeMorePill>
            )}
            {isExpanded && (
              <SeeMorePill _align_={"center"} onClick={removeFromExpanded}>
                Esconder {data.operacoes.length - 4}{" "}
                {data.operacoes.length - 4 > 1 ? "operações" : "operação"}
              </SeeMorePill>
            )}
          </>
        )}
      </Grid>
      <ClienteButton
        variant={"text"}
        fullWidth
        onClick={() => handleClienteClick(data.id)}
      >
        ACESSAR
      </ClienteButton>
    </ClienteContainer>
  );
};

const OPCOES = [
  {
    key: "total",
    label: "Todos os Clientes (inclui inativos)",
  },
  {
    key: "ativos",
    label: "Ativos",
  },
  {
    key: "leads",
    label: "Leads",
  },
  {
    key: "preenchimento",
    label: "Envio da Proposta",
  },
  {
    key: "negado",
    label: "Não Aprovado",
  },
  {
    key: "credito",
    label: "Análise de Crédito",
  },
  {
    key: "documentos",
    label: "Aprovado e Envio de Documentos",
  },
  {
    key: "juridico",
    label: "Avaliação e Análise Jurídica",
  },
  {
    key: "confirmacaoEmissao",
    label: "Emissão e Contrato",
  },
  {
    key: "registroLiberacao",
    label: "Registro e Liberação",
  },
  {
    key: "liberado",
    label: "Liberado",
  },
];

const ListaClientesPage = ({
  loading,
  loadingResumo,
  resumo,
  filters,
  setFilters,
  data,
  exibeParceiro,
  paging,
  page,
  parceiros,
  produtos,
  instituicoesFinanceiras,
  handlePaginationClick,
  handleClienteClick,
  handleOperacaoClick,
}) => {
  const [expanded, setExpanded] = useState([]);
  const [pesquisa, setPesquisa] = useState("");
  const [parceiro, setParceiro] = useState(null);
  const [produto, setProduto] = useState(null);
  const [imovelDefinido, setImovelDefinido] = useState("null");
  const [instituicoaFinanceiras, setInstituicoaFinanceiras] = useState(null);

  const handlePesquisa = useCallback(() => {
    setFilters({
      ...filters,
      pesquisar: pesquisa,
      page: 0,
      dirty: true,
      resumo: true,
    });
  }, [filters, setFilters, pesquisa]);

  return (
    <CommonPage selected={"clientes"}>
      <Grid container spacing={3}>
        <Grid item container>
          <Grid item>
            <Title _margin_={"0 0 1rem 0"}>Bem-vindo à Plataforma CI</Title>
            <Disclaimer>
              {
                "Aqui você encontra todos os bancos e instituições em um só lugar, para ajudar os seus clientes a terem acesso a produtos de crédito imobiliário e home equity simples e rápido.\nAcompanhe todas as etapas do processo, desde a simulação da proposta até a assinatura final do contrato, e muito mais."
              }
            </Disclaimer>
          </Grid>
        </Grid>
        <Grid item container direction={"row"} spacing={3}>
          {OPCOES.map((o) => (
            <Grid item key={o.key}>
              {!!loadingResumo ? (
                <SkeletonFilter variant={"rectangular"} />
              ) : (
                <FilterItem
                  label={o.label}
                  quantity={resumo?.[o.key] ?? 0}
                  ammount={
                    resumo?.[
                      "valor" + formatters.strings.capitalizeFirstLetter(o.key)
                    ] ?? 0
                  }
                  value={o.key}
                  selected={filters.etapa}
                  handleClick={() =>
                    setFilters({
                      ...filters,
                      etapa: o.key,
                      page: 0,
                      dirty: true,
                      resumo: false,
                    })
                  }
                />
              )}
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          {!!loading ? (
            <SkeletonCard variant={"rectangular"} />
          ) : (
            <Card>
              <Grid container justifyContent={"space-between"} spacing={2}>
                <Grid item>
                  <Title as={"h2"}>Clientes</Title>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={true}
                  container
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                >
                  <Grid item xs={12} md={"auto"}>
                    <ToggleButtonGroup
                      exclusive
                      size={"small"}
                      value={imovelDefinido}
                      onChange={(e, value) => {
                        if (!!value) {
                          setImovelDefinido(value);
                          setFilters({
                            ...filters,
                            imovelDefinido: value === "null" ? null : value,
                            page: 0,
                            dirty: true,
                            resumo: true,
                          });
                        }
                      }}
                    >
                      <ToggleButton value={"true"}>
                        <Tooltip title={"Imóvel Definido"} arrow>
                          <ImovelDefinido
                            badgeContent={
                              <StyledImovelDefinido
                                as={CheckCircle}
                                _color_={"success"}
                              />
                            }
                          >
                            <House />
                          </ImovelDefinido>
                        </Tooltip>
                      </ToggleButton>
                      <ToggleButton value={"false"}>
                        <Tooltip title={"Imóvel Não Definido"} arrow>
                          <ImovelDefinido
                            badgeContent={
                              <StyledImovelDefinido
                                as={Cancel}
                                _color_={"error"}
                              />
                            }
                          >
                            <House />
                          </ImovelDefinido>
                        </Tooltip>
                      </ToggleButton>
                      <ToggleButton value={"indefinite"}>
                        <Tooltip title={"Não Respondido"} arrow>
                          <ImovelDefinido
                            badgeContent={
                              <StyledImovelDefinido
                                as={Help}
                                _color_={"info"}
                              />
                            }
                          >
                            <House />
                          </ImovelDefinido>
                        </Tooltip>
                      </ToggleButton>
                      <ToggleButton value={"null"}>
                        <Tooltip title={"Todos"} arrow>
                          <ImovelDefinido
                            badgeContent={
                              <StyledImovelDefinido
                                as={RemoveCircle}
                                _color_={"grey"}
                              />
                            }
                          >
                            <House />
                          </ImovelDefinido>
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  {exibeParceiro && parceiros.length > 1 && (
                    <Grid item xs={12} md={"auto"}>
                      <Autocomplete
                        options={parceiros}
                        getOptionLabel={(option) =>
                          option.nome ??
                          parceiros.find((d) => d.id === option?.id)?.nome ??
                          ""
                        }
                        isOptionEqualToValue={(option, v) =>
                          option?.id === v || option?.id === v?.id
                        }
                        value={parceiro}
                        onChange={(e, v) => {
                          setParceiro(v);
                          if (filters.parceiro?.id !== v?.id) {
                            setFilters({
                              ...filters,
                              parceiro: v,
                              page: 0,
                              dirty: true,
                              resumo: true,
                            });
                          }
                        }}
                        renderOption={(params, o) => (
                          <MenuItem {...params}>{o.nome}</MenuItem>
                        )}
                        renderInput={(params) => (
                          <StyledTextField
                            {...params}
                            size={"small"}
                            placeholder={"Todos os parceiros"}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={"auto"}>
                    <Autocomplete
                      options={instituicoesFinanceiras}
                      getOptionLabel={(option) =>
                        option.nomeFantasia ??
                        instituicoesFinanceiras.find((d) => d.id === option?.id)
                          ?.nomeFantasia ??
                        ""
                      }
                      isOptionEqualToValue={(option, v) =>
                        option?.id === v || option?.id === v?.id
                      }
                      value={instituicoaFinanceiras}
                      onChange={(e, v) => {
                        setInstituicoaFinanceiras(v);
                        if (filters.instituicaoFinanceira?.id !== v?.id) {
                          setFilters({
                            ...filters,
                            instituicaoFinanceira: v,
                            page: 0,
                            dirty: true,
                            resumo: true,
                          });
                        }
                      }}
                      renderOption={(params, o) => (
                        <MenuItem {...params}>{o.nomeFantasia}</MenuItem>
                      )}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          size={"small"}
                          placeholder={"Todas as instituições"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={"auto"}>
                    <Autocomplete
                      options={produtos}
                      getOptionLabel={(option) =>
                        option.nome ??
                        produtos.find((d) => d.id === option?.id)?.nome ??
                        ""
                      }
                      isOptionEqualToValue={(option, v) =>
                        option?.id === v || option?.id === v?.id
                      }
                      value={produto}
                      onChange={(e, v) => {
                        setProduto(v);
                        if (filters.produto?.id !== v?.id) {
                          setFilters({
                            ...filters,
                            produto: v,
                            page: 0,
                            dirty: true,
                            resumo: true,
                          });
                        }
                      }}
                      renderOption={(params, o) => (
                        <MenuItem {...params}>{o.nome}</MenuItem>
                      )}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          size={"small"}
                          placeholder={"Todos os produtos"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={"auto"}>
                    <StyledTextField
                      label={null}
                      size={"small"}
                      placeholder={"Pesquisar pelo nome ou CPF/CNPJ"}
                      margin={"none"}
                      value={pesquisa}
                      onChange={(e) => setPesquisa(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handlePesquisa();
                        }
                      }}
                      onBlur={() => {
                        if (filters.pesquisar !== pesquisa) {
                          handlePesquisa();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => handlePesquisa()}
                          >
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {/*<Grid item xs={12} md={"auto"}>*/}
                  {/*  <Tooltip title={"Exportar clientes"} arrow>*/}
                  {/*    <DownloadButton>*/}
                  {/*      <Download />*/}
                  {/*    </DownloadButton>*/}
                  {/*  </Tooltip>*/}
                  {/*</Grid>*/}
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                {data.map((c) => (
                  <Grid key={c.id} item xs={12} md={6} lg={4} xl={3}>
                    <Cliente
                      data={c}
                      filters={filters}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      exibeParceiro={exibeParceiro}
                      handleClienteClick={handleClienteClick}
                      handleOperacaoClick={handleOperacaoClick}
                    />
                  </Grid>
                ))}
              </Grid>

              <TablePagination
                loading={loading}
                page={page}
                rowsPerPage={paging.tamanho}
                handlePaginationClick={handlePaginationClick}
                total={paging.total}
              />
            </Card>
          )}
        </Grid>
      </Grid>
    </CommonPage>
  );
};

export default ListaClientesPage;
