import React from "react";
import { ReactComponent as LoaderBrand } from "../../assets/loader.svg";
import { ReactComponent as LoaderWhite } from "../../assets/loader-white.svg";

const Blocks = ({
  color = "brand",
  size = "auto",
  style = {},
  ...otherProps
}) => {
  const Loader = color === "brand" ? LoaderBrand : LoaderWhite;

  style.width = size;
  style.height = size;

  return <Loader fill={color} style={style} {...otherProps} />;
};

export default Blocks;
