import { format as formatCpf } from "@fnando/cpf";
import { format as formatCnpj } from "@fnando/cnpj";
import { format as formatDate } from "date-fns";
import { removeNonDigitsFromString } from "./basic";

// numbers
const simpleNumber = (number, options) => {
  if (number !== undefined && !isNaN(number)) {
    return Number(number).toLocaleString("pt-BR", options);
  }
  return "";
};
const nFixed = (number, fixed) => {
  return simpleNumber(number, {
    minimumFractionDigits: fixed,
    maximumFractionDigits: fixed,
  });
};
const currency = (number) => {
  return nFixed(number, 2);
};
const percent = (number) => {
  return nFixed(number, 4);
};
// dates
const simple = (date, format, options) => {
  return formatDate(date, format, options);
};
const iso = (date) => {
  if (!date) return "";
  return simple(date, "yyyy-MM-dd");
};
const short = (date) => {
  if (!date) return "";
  return simple(date, "dd/MM/yyyy");
};
const hoursMinutes = (date) => {
  if (!date) return "";
  return simple(date, "HH:mm");
};
const hoursMinutesSeconds = (date) => {
  if (!date) return "";
  return simple(date, "HH:mm:ss");
};
// strings
const capitalizeFirstLetter = (text) => {
  return text.slice(0, 1).toUpperCase() + text.slice(1);
};
const zipCode = (text) => {
  const full = ("00000000000000" + text).slice(-8);
  return full.slice(0, 5) + "-" + full.slice(5);
};
const address = (address) => {
  if (!address) {
    return null;
  }

  let current = "";
  if (address.logradouro) {
    current += address.logradouro;
    if (address.numero) {
      current += " " + address.numero;
    }
    if (address.complemento) {
      current += ", " + address.complemento;
    }
  }
  if (address.bairro) {
    if (!!current) {
      current += ", ";
    }
    current += address.bairro;
  }
  if (address.municipio) {
    if (!!current) {
      current += " - ";
    }
    current += address.municipio;
  }
  if (address.uf) {
    if (!!current) {
      current += " / ";
    }
    current += address.uf;
  }
  if (address.cep) {
    if (!!current) {
      current += " - ";
    }
    current += "CEP " + zipCode(address.cep);
  }

  return !current ? null : current;
};
const cpf = (text) => {
  return formatCpf(("00000000000000" + text).slice(-11));
};
const cnpj = (text) => {
  return formatCnpj(("00000000000000" + text).slice(-14));
};
const cpfCnpj = (text, tipo) => {
  if (!text) return "";
  if (tipo === "F") {
    return cpf(text);
  }
  if (tipo === "J") {
    return cnpj(text);
  }
  if (text.length === 11) {
    return cpf(text);
  }
  if (text.length === 14) {
    return cnpj(text);
  }
  return "";
};
const phone = (value) => {
  const stripped = removeNonDigitsFromString(value);
  const size = !!stripped ? stripped.length : 0;

  if (size === 0) return "";

  if (size > 10) {
    let finalValue = "(" + stripped.slice(0, 2);
    finalValue += ") " + stripped.slice(2, 7);
    finalValue += "-" + stripped.slice(7, 11);

    return finalValue;
  } else {
    let finalValue = "(" + stripped.slice(0, 2);
    if (stripped.length > 2) {
      finalValue += ") " + stripped.slice(2, 6);
    }
    if (stripped.length > 6) {
      finalValue += "-" + stripped.slice(6);
    }
    return finalValue;
  }
};
const formatters = {
  numbers: {
    simple: simpleNumber,
    currency,
    percent,
    nFixed,
  },
  dates: {
    simple,
    short,
    iso,
    hoursMinutes,
    hoursMinutesSeconds,
  },
  strings: {
    capitalizeFirstLetter,
    cpf,
    cnpj,
    cpfCnpj,
    zipCode,
    address,
    phone,
  },
};

export default formatters;
