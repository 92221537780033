import React from "react";
import DadosBasicosCompradorSantanderFormConnected from "./comprador/DadosBasicosCompradorSantanderForm.connected";
import HubSantanderConnected from "./HubSantander.connected";
import DadosProfissionaisCompradorSantanderFormConnected from "./comprador/DadosProfissionaisCompradorSantanderForm.connected";
import DadosEstadoCivilCompradorSantanderFormConnected from "./comprador/DadosEstadoCivilCompradorSantanderForm.connected";
import DadosEnderecoCompradorSantanderFormConnected from "./comprador/DadosEnderecoCompradorSantanderForm.connected";
import DadosBasicosVendedorPfSantanderFormConnected from "./vendedor/DadosBasicosVendedorPfSantanderForm.connected";
import DadosEstadoCivilVendedorSantanderFormConnected from "./vendedor/DadosEstadoCivilVendedorSantanderForm.connected";
import DadosEnderecoVendedorPfSantanderFormConnected from "./vendedor/DadosEnderecoVendedorPfSantanderForm.connected";
import DadosBasicosVendedorPjSantanderFormConnected from "./vendedor/DadosBasicosVendedorPjSantanderForm.connected";
import DadosEnderecoVendedorPjSantanderFormConnected from "./vendedor/DadosEnderecoVendedorPjSantanderForm.connected";
import DadosEnderecoImovelSantanderFormConnected from "./imovel/DadosEnderecoImovelSantanderForm.connected";
import DocumentosTabConnected from "./DocumentosTab.connected";
import OutrasInformacoesImovelSantanderFormConnected from "./imovel/OutrasInformacoesImovelSantanderForm.connected";
import DadosContatoImovelSantanderFormConnected from "./imovel/DadosContatoImovelSantanderForm.connected";
import DadosOutrasInformacoesCompradorSantanderFormConnected from "./comprador/DadosOutrasInformacoesCompradorSantanderForm.connected";
import ContasLiberacaoVendedorSantanderFormConnected from "./ContasLiberacaoVendedorSantanderForm.connected";
import DadosFgtsSantanderFormConnected from "./comprador/DadosFgtsSantanderForm.connected";
import DadosDpsDefaultFormConnected from "../default/comprador/DadosDpsDefaultForm.connected";

import DadosSociosPjVendedorSantanderFormConnected from "./vendedor/DadosSociosPjVendedorSantanderForm.connected";
import DadosBasicosSociosSantanderFormConnected from "./vendedor/socios/DadosBasicosSociosSantanderForm.connected";
import DadosEnderecoSociosSantanderFormConnected from "./vendedor/socios/DadosEnderecoSociosSantanderForm.connected";
import DadosEstadoCivilSociosSantanderFormConnected from "./vendedor/socios/DadosEstadoCivilSociosSantanderForm.connected";

const FluxoAquisicaoSantander = (props) => {
  return (
    <>
      {props.step === 0 && <HubSantanderConnected {...props} />}
      {props.step === 1 && (
        <DadosBasicosCompradorSantanderFormConnected {...props} />
      )}
      {props.step === 2 && (
        <DadosEnderecoCompradorSantanderFormConnected {...props} />
      )}
      {props.step === 3 && (
        <DadosProfissionaisCompradorSantanderFormConnected {...props} />
      )}
      {props.step === 4 && (
        <DadosEstadoCivilCompradorSantanderFormConnected {...props} />
      )}
      {props.step === 5 && (
        <DadosOutrasInformacoesCompradorSantanderFormConnected {...props} />
      )}
      {props.step === 7 && <DadosFgtsSantanderFormConnected {...props} />}
      {props.step === 9 && <DadosDpsDefaultFormConnected {...props} />}
      {props.step === 10 && (
        <DadosBasicosVendedorPfSantanderFormConnected {...props} />
      )}
      {props.step === 11 && (
        <DadosEnderecoVendedorPfSantanderFormConnected {...props} />
      )}
      {props.step === 12 && (
        <DadosEstadoCivilVendedorSantanderFormConnected {...props} />
      )}
      {props.step === 20 && (
        <DadosBasicosVendedorPjSantanderFormConnected {...props} />
      )}
      {props.step === 21 && (
        <DadosEnderecoVendedorPjSantanderFormConnected {...props} />
      )}
      {props.step === 22 && (
        <DadosSociosPjVendedorSantanderFormConnected {...props} />
      )}
      {props.step === 60 && (
        <DadosBasicosSociosSantanderFormConnected {...props} />
      )}
      {props.step === 61 && (
        <DadosEnderecoSociosSantanderFormConnected {...props} />
      )}
      {props.step === 62 && (
        <DadosEstadoCivilSociosSantanderFormConnected {...props} />
      )}
      {props.step === 100 && (
        <DadosEnderecoImovelSantanderFormConnected {...props} />
      )}
      {props.step === 101 && (
        <OutrasInformacoesImovelSantanderFormConnected {...props} />
      )}
      {props.step === 102 && (
        <DadosContatoImovelSantanderFormConnected {...props} />
      )}
      {props.step === 998 && (
        <ContasLiberacaoVendedorSantanderFormConnected {...props} />
      )}
      {props.step === 999 && <DocumentosTabConnected {...props} />}
    </>
  );
};

export default FluxoAquisicaoSantander;
