import { combineReducers } from "redux";
import { reducer as routesReducer } from "../routes";
import { default as autenticacao } from "./autenticacao.reducer";
import { default as clientes } from "./clientes.reducer";
import { default as operacao } from "./operacao.reducer";
import { default as parceiro } from "./parceiro.reducer";
import { default as tema } from "./tema.reducer";
import { default as calculadora } from "./calculadora.reducer";
import { default as documentos } from "./documentos.reducer";
import { default as simulador } from "./simulador.reducer";
import { default as workflow } from "./workflow.reducer";
import { default as apoio } from "./apoio.reducer";
import { default as usuario } from "./usuarios.reducer"

export default combineReducers({
  location: routesReducer,
  apoio,
  autenticacao,
  clientes,
  operacao,
  parceiro,
  documentos,
  calculadora,
  simulador,
  tema,
  workflow,
  usuario
});
