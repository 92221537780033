import {useSelector} from "react-redux";
import {getValidacaoDocumento} from "../../../selectors/documentos.selectors"
import ValidacaoDocumentoPage from "./ValidacaoDocumentoPage"

const ValidacaoDocumentoPageConnected = () => {
  const { loading, documento, operacao} = useSelector(getValidacaoDocumento);

  return <ValidacaoDocumentoPage
    loading={loading}
    documento={documento}
    operacao={operacao}
  />;
};

export default ValidacaoDocumentoPageConnected;