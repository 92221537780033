import DetalhesUsuarioPage from "./DetalhesUsuarioPage";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { removeNonDigitsFromString } from "../../utils/basic";
import {
  getDetalhesParceiro,
  getDetalhesUsuario,
  isCarregandoDetalhesParceiro,
  isCarregandoDetalhesUsuario,
} from "../../selectors/usuario.selectors";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";
import { actions } from "../../actions/usuarios.actions";

const DetalhesUsuarioPageConnected = () => {
  const dispatch = useDispatch();
  const loadingUser = useSelector(isCarregandoDetalhesUsuario);
  const loadingParceiro = useSelector(isCarregandoDetalhesParceiro);
  const loading = loadingUser || loadingParceiro;
  const usuario = useSelector(getDetalhesUsuario);
  const parceiro = useSelector(getDetalhesParceiro);
  const data = { ...parceiro, ...usuario };

  const handleBack = useCallback(() => {
    dispatch(routeActions.redirectTo(routes.USUARIOS));
  }, [dispatch]);

  const handleShare = useCallback(() => {
    dispatch(
      actions.exibirDialogCompartilhamento(
        true,
        `${data.identificadorExterno}?comercial=${data?.linkCalculadora}`
      )
    );
  }, [dispatch, data]);

  return (
    <DetalhesUsuarioPage
      loading={loading}
      data={data}
      handleBack={handleBack}
      handleShare={handleShare}
    />
  );
};

export default DetalhesUsuarioPageConnected;
