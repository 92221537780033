import React, { useCallback, useEffect, useMemo } from "react";
import OutrasInformacoesImovelFintechsForm from "./OutrasInformacoesImovelFintechsForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";

const getDominioProprietarios = (operacao) => {
  const result = [];

  operacao.participantes.forEach((p) => {
    result.push({
      id: `${p.id}|false`,
      descricao: p.nome.toUpperCase(),
    });

    if (!!p.conjuge) {
      result.push({
        id: `${p.id}|true`,
        descricao: p.conjuge.nome.toUpperCase(),
      });
    }
  });

  return result;
};

const OutrasInformacoesImovelFintechsFormConnected = ({
  makeGoTo,
  eventsImovel,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    validacao,
    dominios: { subTipoImovel: subTiposImovel },
  } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const imovel = useMemo(
    () => ({
      ...operacao.imovel,
      fluxo: validacao.completa.imovel,
    }),
    [operacao, validacao]
  );

  const _subTipoImovel = subTiposImovel.find(
    (e) => e.id === imovel?.subTipoImovel?.id
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      quantidadeVagas: validators.number({ required: true }),
      valorImovel: validators.number({ required: true }),
      subTipoImovel: validators.object({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        quantidadeVagas: "",
        valorImovel: "",
        vagaAutonoma: "",
        imovelAlugado: "",
        valorAluguel: "",
        imovelFinanciado: "",
        bancoFinanciamento: "",
        saldoDevedorFinanciamento: "",
        possuiDebitos: "",
        valorDebitosIptu: "",
        valorDebitosCondominio: "",
        proprietarios: [],
        ...imovel,
        subTipoImovel: _subTipoImovel,
      }),
      [imovel, _subTipoImovel]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback: makeGoTo(0),
            instituicao: operacao.codigoInstituicao,
            passo: "outras-informacoes-imovel",
            operacao: {
              id: operacao.id,
              imovel: {
                quantidadeVagas: values.quantidadeVagas,
                possuiDepositoVagaAutonoma: values.vagaAutonoma,
                valorImovel: values.valorImovel,
                imovelAlugado: values.imovelAlugado,
                valorAluguel: values.valorAluguel,
                imovelFinanciado: values.imovelFinanciado,
                bancoFinanciamento: values.bancoFinanciamento,
                saldoDevedorFinanciamento: values.saldoDevedorFinanciamento,
                possuiDebitos: values.possuiDebitos,
                valorDebitosIptu: values.valorDebitosIptu,
                valorDebitosCondominio: values.valorDebitosCondominio,
                idSubTipoImovel: values.subTipoImovel?.id,
                proprietarios: values.proprietarios.map((p) => {
                  const [id, conjuge] = p.id.split("|");
                  return {
                    idParticipante: id,
                    conjuge,
                  };
                }),
              },
            },
          })
        );
      },
      [dispatch, operacao, makeGoTo]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      quantidadeVagas: "",
      vagaAutonoma: "",
      valorImovel: "",
      imovelAlugado: "",
      valorAluguel: "",
      imovelFinanciado: "",
      bancoFinanciamento: "",
      saldoDevedorFinanciamento: "",
      possuiDebitos: "",
      valorDebitosIptu: "",
      valorDebitosCondominio: "",
      proprietarios: [],
      ...imovel,
      subTipoImovel: _subTipoImovel,
    });
  }, [imovel, reset, _subTipoImovel]);

  return (
    <OutrasInformacoesImovelFintechsForm
      {...otherProps}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={imovel.fluxo}
      makeGoTo={makeGoTo}
      events={eventsImovel}
      proprietarios={getDominioProprietarios(operacao)}
      subTiposImovel={subTiposImovel}
    />
  );
};

export default OutrasInformacoesImovelFintechsFormConnected;
