import React from "react";
import { Button, Card, Form } from "../../components";
import { Grid, MenuItem } from "@mui/material";
import {
  ControlledTextField,
  CpfCnpjTextField,
  PhoneTextField,
} from "../../components/inputs";
import { PERFIL, TIPO } from "../../constants/usuario.constants";

const TIPOS_OPERACAO = ["P", "O"];
const TIPOS_PARCEIRO = ["P"];

const PERFIS_OPERACAO = ["OPERACAO"];
const PERFIS_PARCEIRO = [
  "PARCEIRO_MASTER",
  "FINANCEIRO",
  "OPERACIONAL",
  "LIDER",
  "PROMOTOR",
];

const CadastroUsuarioForm = ({
  submitting,
  tipo,
  tipoUsuario,
  formProps,
  handleSubmit,
  handleChangeTipo,
  handleChangeParceiro,
  parceiros,
  times,
}) => {
  return (
    <Card>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              label="Nome"
              name={"nome"}
              formProps={formProps}
              inputProps={{ maxLength: 100 }}
              disabled={submitting}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              label="E-mail"
              name={"email"}
              inputProps={{ maxLength: 100 }}
              formProps={formProps}
              disabled={submitting}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              label="CPF/CNPJ"
              name={"cpfCnpj"}
              formProps={formProps}
              disabled={submitting}
              type={"both"}
              InputProps={{
                inputComponent: CpfCnpjTextField,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              label="Celular"
              name={"telefone"}
              formProps={formProps}
              disabled={submitting}
              InputProps={{
                inputComponent: PhoneTextField,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              select
              label="Tipo"
              name={"tipo"}
              formProps={formProps}
              onChange={handleChangeTipo}
              disabled={tipoUsuario === "P" || submitting}
            >
              {tipoUsuario === "OPERACAO"
                ? TIPOS_OPERACAO.map((t) => (
                    <MenuItem key={t} value={t}>
                      {TIPO[t]}
                    </MenuItem>
                  ))
                : TIPOS_PARCEIRO.map((t) => (
                    <MenuItem key={t} value={t}>
                      {TIPO[t]}
                    </MenuItem>
                  ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              select
              label="Perfil"
              name={"perfil"}
              formProps={formProps}
              disabled={submitting || !tipo}
            >
              {tipo === "O" ? (
                PERFIS_OPERACAO.map((t) => (
                  <MenuItem key={t} value={t}>
                    {PERFIL[t]}
                  </MenuItem>
                ))
              ) : tipo === "P" ? (
                PERFIS_PARCEIRO.map((t) => (
                  <MenuItem key={t} value={t}>
                    {PERFIL[t]}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={""} />
              )}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              select
              label="Parceiro"
              name={"parceiro"}
              formProps={formProps}
              onChange={handleChangeParceiro}
              disabled={tipoUsuario === "P" || tipo !== "P" || submitting}
            >
              {parceiros.map((parceiro) => (
                <MenuItem key={parceiro.codigo} value={parceiro.codigo}>
                  {parceiro.nomeFantasia}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              select
              label="Filial"
              name={"timeParceiro"}
              formProps={formProps}
              disabled={tipo === "O" || times.length === 0 || submitting}
            >
              {times.map((time) => (
                <MenuItem key={time.id} value={time.id}>
                  {time.nome}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
        </Grid>
        <Grid item container justifyContent={"center"} mt={1}>
          <Grid item xs={12} md={4}>
            <Button loading={submitting} type={"submit"} fullWidth>
              SALVAR
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Card>
  );
};

export default CadastroUsuarioForm;
