import React from "react";
import { Dialog, DialogContent, IconButton, styled } from "@mui/material";
import { ControlledTextField } from "../../components/inputs";
import { CloseSharp } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Form, Title, Button } from "../../components";
import DateTextField from "../../components/inputs/DateTextField";
import { captureConsoleIntegration } from "@sentry/react";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    width: 100%;
  }
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const getInputProps = (type) => {
  switch (type) {
    case "date":
      return {
        inputComponent: DateTextField,
      };
    case "text":
    default:
      return {
        maxLength: 50,
      };
  }
};

const AlterarInputPropostaDialog = ({
  handleSubmit,
  submitting,
  handleClose,
  formProps,
  open,
  title = "Inserir Informação",
  label = "Campo",
  type,
}) => {
  return (
    <StyledDialog
      onClose={() => {}}
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
    >
      <DialogContent>
        {!submitting && (
          <CloseIconButton onClick={handleClose}>
            <CloseSharp />
          </CloseIconButton>
        )}
        <Title as={"h2"} _margin_={"0 0 1rem 0"}>
          {title}
        </Title>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <ControlledTextField
                formProps={formProps}
                name={"input"}
                label={label}
                InputProps={getInputProps(type)}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={8}>
              <Button loading={submitting} type={"submit"} fullWidth>
                CONFIRMAR
              </Button>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
    </StyledDialog>
  );
};

export default AlterarInputPropostaDialog;
