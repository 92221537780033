import React, { useCallback, useEffect } from "react";
import EnviarViaWhatsAppDialogDialog from "./EnviarViaWhatsAppDialogDialog";
import validators from "../../utils/validators";
import useCompleteForm from "../../hooks/useCompleteForm";
import { removeNonDigitsFromString } from "../../utils/basic";

const EnviarViaWhatsAppDialogDialogConnected = ({
  handleClose,
  dados: { open, ...otherData },
}) => {
  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      telefone: validators.phone({
        required: true,
      }),
    }),
    initialValues: () => ({
      telefone: otherData.telefone ?? "",
    }),
    handleSubmit: useCallback(
      (values) => {
        const mensagem = encodeURI(otherData.mensagem);
        window.open(
          `https://wa.me/${removeNonDigitsFromString(
            values.telefone
          )}?text=${mensagem}`,
          "_blank"
        );
        handleClose();
      },
      [otherData, handleClose]
    ),
  });

  const { setValue } = formProps;

  useEffect(() => {
    setValue("telefone", otherData.telefone ?? "", {
      shouldValidate: false,
    });
  }, [setValue, otherData]);

  return (
    <EnviarViaWhatsAppDialogDialog
      handleSubmit={handleSubmit}
      open={open}
      dados={otherData}
      formProps={formProps}
      handleClose={handleClose}
      submitting={false}
    />
  );
};

export default EnviarViaWhatsAppDialogDialogConnected;
