import React from "react";
import { styled } from "@mui/material";
import { Tooltip as MTooltip } from "@mui/material";
import { transientOptions } from "../styles/theme";

const StyledTooltip = styled(
  ({ className, ...props }) => (
    <MTooltip {...props} classes={{ popper: className }} />
  ),
  transientOptions
)`
  & .MuiTooltip-tooltip {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.small};
    font-weight: 300;
    background-color: ${({ theme }) => theme.colors.secondary._800};
    color: ${({ theme }) => theme.colors.fixed.white};
    white-space: pre-wrap;
  }
  & .MuiTooltip-arrow {
    color: ${({ theme }) => theme.colors.secondary._800};
  }
`;

const Tooltip = (props) => <StyledTooltip enterTouchDelay={1} {...props} />;

export default Tooltip;
