import {Grid, InputAdornment, styled} from "@mui/material";
import {Button, Card, Form, GoBack, MenuItem, Title} from "../../../components";
import Stepper from "./Stepper";
import {
  ControlledRadioGroup, ControlledSwitch,
  ControlledTextField,
  CpfCnpjTextField, CurrencyTextField,
  DateTextField, IntegerTextField,
  PhoneTextField, Radio
} from "../../../components/inputs";
import {HorizontalLoader} from "../../../components/loaders";
import UpperCaseTextField from "../../../components/inputs/UpperCaseTextField";
import ControlledAutocomplete from "../../../components/inputs/ControlledAutocomplete";
import {estados} from "../../../constants/generic.constants";
import {
  AirportShuttleOutlined,
  EngineeringOutlined,
  HomeOutlined,
  InfoOutlined, LocalShippingOutlined,
  Storefront,
  TerrainOutlined, TwoWheelerOutlined
} from "@mui/icons-material";
import React from "react";

const icones = {
  Residencial: HomeOutlined,
  Comercial: Storefront,
  Terreno: TerrainOutlined,
  Construção: EngineeringOutlined,
  Imóvel: HomeOutlined,
  "Veículo Leve": AirportShuttleOutlined,
  Motocicleta: TwoWheelerOutlined,
  "Veículo Pesado": LocalShippingOutlined,
};
const StyledIcon = styled("span")`
  margin-right: 0.5rem;
`;

const ReenquadrarOperacaoForm = ({
  formProps,
  recalculando,
  handleGoTo0,
  handleSubmit,
  detalhes,
  tipoProduto,
  mensagemFinanciamento,
  mensagemPrazo,
}) =>{
  return(
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={handleGoTo0}
          text={"Preencha as compos abaixo para resimular sua proposta"}
        />
        <Grid item container>
          <Grid item>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      name={"valorImovel"}
                      label={tipoProduto === "C" ? "Valor do Bem" : "Valor do Imóvel"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: CurrencyTextField,
                      }}
                      inputProps={{
                        allowNegative: false,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      name={"valorFinanciamento"}
                      label={
                        tipoProduto === "H"
                          ? "Valor do Empréstimo"
                          : tipoProduto === "C"
                            ? "Valor do Crédito"
                            : "Valor do Financiamento"
                      }
                      helperText={mensagemFinanciamento}
                      tooltip={
                        tipoProduto === "H"
                          ? "Percentual de crédito é limitado na média de 60% do valor de avaliação do imóvel. Podendo variar em algumas instuições de 30% a 50%.\n" +
                          "O valor de empréstimo será determinado de acordo a comprovação de renda e o menor Valor informado e Avaliação do imóvel (vistoria realizada durante o processo de empréstimo)."
                          : "Percentual de crédito é limitado na média até 80% do valor de avaliação do imóvel. Podendo variar em algumas instuições de 70% a 90%.\n" +
                          "O valor de financiamento será determinado de acordo a instituição e a comprovação de renda e o menor de Valor de Compra e Venda ou Avaliação do imóvel (vistoria realizada durante o processo de financiamento)."
                      }
                      formProps={formProps}
                      InputProps={{
                        inputComponent: CurrencyTextField,
                      }}
                      inputProps={{
                        allowNegative: false,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      name={"prazoDesejado"}
                      helperText={mensagemPrazo}
                      label={
                        tipoProduto === "I"
                          ? `Prazo de Financiamento (em meses)`
                          : `Prazo de Pagamento (em meses)`
                      }
                      formProps={formProps}
                      tooltip={
                        "A soma do prazo do financiamento com a idade do proponente mais velho não poderá ser superior a 80 anos e 6 meses."
                      }
                      inputProps={{
                        maxLength: 3,
                        allowNegative: false,
                      }}
                      InputProps={{
                        inputComponent: IntegerTextField,
                      }}
                    />
                  </Grid>
                  {detalhes.permiteFinanciarCustas && (
                    <Grid item xs={12}>
                      <ControlledSwitch
                        name={"financiaCustas"}
                        label={
                          "Deseja financiar as despesas de ITBI e Registro? (5% do valor do imóvel)"
                        }
                        tooltip={
                          "É possível financiar o pagamento do ITBI e Registro do Contrato, até o valor limite correspondente a 5% do Valor do imóvel.\n" +
                          "Após a assinatura do Contrato na agência, será realizado o crédito e o débito deste valor na conta corrente.\n" +
                          "O financiamento destas despesas estará condicionado às regras do produto e normas aplicáveis."
                        }
                        formProps={formProps}
                      />
                    </Grid>
                  )}
                  {detalhes.permiteFinanciarIof && (
                    <Grid item xs={12}>
                      <ControlledSwitch
                        name={"financiaIof"}
                        label={"Deseja financiar o IOF da operação?"}
                        tooltip={
                          "É possível incluir no valor do crédito os custos de IOF presentes na operação.\n" +
                          "No momento da liberação do crédito, será realizado o crédito e débito deste valor na conta corrente.\n" +
                          "O financiamento do IOF estará condicionado às regras do produto e normas aplicáveis.\n" +
                          "Se não for financiado será descontado do valor total do empréstimo reduzindo do valor solicitado do empréstimo."
                        }
                        formProps={formProps}
                      />
                    </Grid>
                  )}
                  {detalhes.permiteFinanciarTarifaEmissao && (
                    <Grid item xs={12}>
                      <ControlledSwitch
                        name={"financiaTarifaEmissao"}
                        label={"Deseja financiar a tarifa de Avaliação?"}
                        tooltip={
                          "É possível financiar a Tarifa de Avaliação da Garantia.\n" +
                          "Importante: A tarifa deverá ser paga nas hipóteses de cancelamento ou desistência desta proposta pelo cliente, desde que o serviço tenha sido prestado.\n" +
                          "Após a assinatura do contrato na agência, será realizado o crédito e débito deste valor em sua conta corrente.\n" +
                          "O financiamento destas despesas estará condicionado às regras do produto e normas aplicáveis."
                        }
                        formProps={formProps}
                      />
                    </Grid>
                  )}
                  {detalhes.permiteFinanciarTarifaRegistro && (
                    <Grid item xs={12}>
                      <ControlledSwitch
                        name={"financiaTarifaRegistro"}
                        label={
                          tipoProduto === "H"
                            ? "Deseja financiar a Tarifa de Emissão do Contrato? (5% do valor do empréstimo)"
                            : "Deseja financiar a Tarifa de Emissão do Contrato? (5% do valor do financiamento)"
                        }
                        tooltip={
                          "É possível financiar o pagamento da Emissão do Contrato, até o valor limite pode corresponder até 5% do Valor do imóvel, porém este valor na prática pode ser menos 1% e caso não ser totalmente utilizado para esta finalidade de registro poderá ser abatido no saldo devedor do cliente.\n" +
                          "O financiamento destas despesas estará condicionado às regras do produto e normas aplicáveis de cada instituições."
                        }
                        formProps={formProps}
                      />
                    </Grid>
                  )}
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} loading={recalculando} fullWidth>
                        RECALCULAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReenquadrarOperacaoForm;