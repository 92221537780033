import { Chip as MChip, styled } from "@mui/material";
import { transientOptions } from "../../styles/theme";

const AutocompleteTag = styled(MChip, transientOptions)`
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.grey._100};

  & .MuiChip-label {
    font-weight: 300;
    font-family: ${({ theme }) => theme.fonts.family.buttons};
  }
`;

export default AutocompleteTag;
