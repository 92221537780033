import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import QualificacaoCompradores from "./QualificacaoCompradores";
import { actions } from "../../../../../actions/operacao.actions";

const QualificacaoCompradoresConnected = ({
  handleGoTo2,
  events,
  data,
  submitting,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { operacaoConfirmacaoValores } = data;

  const callback = useCallback(() => {
    handleGoTo2();
  }, [handleGoTo2]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      aceitaQualificacaoCompradores: validators.object({ required: true }),
      correcaoCompradores: validators.string({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        correcaoCompradores: "",
        ...operacaoConfirmacaoValores,
        aceitaQualificacaoCompradores:
          operacaoConfirmacaoValores.aceitaQualificacaoCompradores + "",
      }),
      [operacaoConfirmacaoValores]
    ),
    handleSubmit: useCallback(
      (values) => {
        return dispatch(
          actions.enviarConfirmacaoValores.request({
            callback,
            passo: "compradores",
            instituicao: "itau",
            operacaoConfirmacaoValores: {
              idOperacao: values.idOperacao,
              aceitaQualificacaoCompradores:
                values.aceitaQualificacaoCompradores,
              correcaoCompradores:
                values.aceitaQualificacaoCompradores === "false"
                  ? values.correcaoCompradores
                  : null,
            },
          })
        );
      },
      [dispatch, callback]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      correcaoCompradores: "",
      ...operacaoConfirmacaoValores,
      aceitaQualificacaoCompradores:
        operacaoConfirmacaoValores.aceitaQualificacaoCompradores + "",
    });
  }, [operacaoConfirmacaoValores, reset]);

  return (
    <QualificacaoCompradores
      {...otherProps}
      formProps={formProps}
      handleSubmit={handleSubmit}
      events={events}
      submitting={submitting}
    />
  );
};

export default QualificacaoCompradoresConnected;
