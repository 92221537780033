import React, { useCallback, useEffect, useMemo, useState } from "react";
import OutrasInformacoesImovelSantanderForm from "./OutrasInformacoesImovelSantanderForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isBuscandoContaIq,
  isGravandoComplementoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import formatters from "../../../../../utils/formatters";

const OutrasInformacoesImovelSantanderFormConnected = ({
  handleGoTo102,
  eventsImovel,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    validacao,
    dominios: { banco: bancos },
  } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);
  const searching = useSelector(isBuscandoContaIq);
  const [desabilitarEdicaoIq, setDesabilitarEdicaoIq] = useState(true);

  const imovel = useMemo(
    () => ({
      ...operacao.imovel,
      fluxo: validacao.completa.imovel,
    }),
    [operacao, validacao]
  );
  const [parteImovel, setParteImovel] = useState(
    imovel.adquirindoParteImovel ?? false
  );

  useEffect(() => {
    if (imovel.contatoIq?.email || imovel.contatoIq?.telefone) {
      setDesabilitarEdicaoIq(false);
    }
  }, [imovel]);

  const compradores = operacao.participantes
    .filter(p => p.tipoParticipante === "CO")
    .reduce((a, c) => {
      a.push(c);
      if (c.conjuge && !operacao.participantes.filter(p => p.tipoParticipante === "CO").some(comprador => comprador.cpfCnpj === c.conjuge.cpfCnpj)) {
        a.push({ ...c.conjuge, isConjuge: true, idConjuge: c.id });
      }
      return a;
    }, [])

  const validatePercentualAquisicao = {
    checkTotal: () => {
      return formPropsPercentualAquisicao
        .getValues()
        .participantes
        .reduce((acc, v) => acc + v.percentualAquisicaoImovel, 0) !== 100
        ? "A soma dos percentuais deve ser igual a 100%"
        : true;
    },
  };
  const initialValuesParticipantes = () => ({
    participantes:
      compradores.length === 1
        ? compradores.map((p) => ({ ...p, percentualAquisicaoImovel: 100.0 }))
        : compradores,
  });

  const [formPropsPercentualAquisicao] = useCompleteForm({
    rules: () => ({
      participantes: {
        nome: validators.string({ required: true }),
        percentualAquisicaoImovel: validators.number({
          required: true,
          custom: validatePercentualAquisicao,
        }),
      },
    }),
    initialValues: initialValuesParticipantes,
  });

  const [formProps, submitForm] = useCompleteForm({
    rules: () => ({
      numeroDormitorios: validators.number({ required: true }),
      quantidadeVagas: validators.number({ required: true }),
      parteAdquirida: validators.number({ required: true }),
      valorParteAdquirida: validators.number({ required: true }),
      numeroContratoIq: validators.number({ required: true }),
      contatoIq: {
        banco: validators.object({
          required: true,
        }),
        nomeContato: validators.string({ required: true }),
        email: validators.email({ required: true }),
        telefone: validators.phone({ required: true }),
      },
    }),
    initialValues: useCallback(
      () => ({
        numeroDormitorios: "",
        quantidadeVagas: "",
        vagaAutonoma: false,
        imovelFinanciado: false,
        programaSocial: false,
        numeroContratoIq: "",
        ...(operacao.tipoProduto === "I"
          ? {
            adquirindoParteImovel: false,
            parteAdquirida: "",
            valorParteAdquirida: "",
            primeiraAquisicaoSfh: false,
          }
          : {}),
        ...(operacao.tipoProduto === "H"
          ? {
            imovelProprio: false,
          }
          : {}),
        ...imovel,
        contatoIq: {
          nomeContato: "",
          email: "",
          banco: null,
          telefone: "",
          ...imovel.contatoIq,
        },
      }),
      [imovel, operacao]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback: handleGoTo102,
            instituicao: operacao.codigoInstituicao,
            passo: "outras-informacoes-imovel",
            operacao: {
              id: operacao.id,
              imovel: {
                numeroDormitorios: values.numeroDormitorios,
                quantidadeVagas: values.quantidadeVagas,
                imovelProprio: values.imovelProprio,
                possuiDepositoVagaAutonoma: values.vagaAutonoma,
                adquirindoParteImovel: values.adquirindoParteImovel,
                programaSocial: values.programaSocial,
                parteAdquirida: !!values.adquirindoParteImovel
                  ? values.parteAdquirida
                  : null,
                valorParteAdquirida: !!values.adquirindoParteImovel
                  ? values.valorParteAdquirida
                  : null,
                imovelFinanciado: values.imovelFinanciado,
                primeiraAquisicaoSfh: values.primeiraAquisicaoSfh,
                numeroContratoIq: !!values.imovelFinanciado
                  ? values.numeroContratoIq
                  : "",
                contatoIq: !!values.imovelFinanciado
                  ? {
                    idBanco: values.contatoIq.banco.id,
                    nomeContato: values.contatoIq.nomeContato,
                    email: values.contatoIq.email,
                    telefone: values.contatoIq.telefone,
                  }
                  : null,
              },
              participantes: formPropsPercentualAquisicao
                .getValues()
                .participantes
                .filter(p => !p.isConjuge)
                .map((p) => ({
                  id: p.id,
                  percentualAquisicaoImovel: p.percentualAquisicaoImovel,
                  conjuge: !!p.conjuge ? formPropsPercentualAquisicao
                    .getValues()
                    .participantes
                    .filter(c => c.isConjuge && c.idConjuge === p.id)
                    .map(c => ({ id: c.id, percentualAquisicaoImovel: c.percentualAquisicaoImovel }))[0] : null
                })),
            },
          })
        );
      },
      [dispatch, operacao, handleGoTo102, formPropsPercentualAquisicao]
    ),
  });
  const handleChangeParteImovel = useCallback(
    ({ target: { checked } }) => {
      setParteImovel(checked);
    },
    [setParteImovel]
  );

  const { reset } = formProps;

  useEffect(() => {
    reset({
      numeroDormitorios: "",
      vagaAutonoma: false,
      imovelFinanciado: false,
      programaSocial: false,
      numeroContratoIq: "",
      ...(operacao.tipoProduto === "I"
        ? {
          adquirindoParteImovel: false,
          parteAdquirida: "",
          valorParteAdquirida: "",
          primeiraAquisicaoSfh: false,
        }
        : {}),
      ...(operacao.tipoProduto === "H"
        ? {
          imovelProprio: false,
        }
        : {}),
      ...imovel,
      contatoIq: {
        nomeContato: "",
        email: "",
        banco: null,
        telefone: "",
        ...imovel.contatoIq,
      },
    });
  }, [imovel, reset, operacao]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isPercentValid = await formPropsPercentualAquisicao.trigger();
    const isMainFormValid = await formProps.trigger();

    if (isPercentValid && isMainFormValid) {
      submitForm();
    }
  };

  const successCallback = useCallback(
    ({ data }) => {
      setDesabilitarEdicaoIq(false);
      formProps.setValue(
        "contatoIq",
        {
          ...formProps.getValues().contatoIq,
          nomeContato: data?.nomeContato || "",
          telefone: formatters.strings.phone(data?.telefone) || "",
          email: data?.email || "",
        },
        { shouldValidate: !!data }
      );
      setDesabilitarEdicaoIq(!!data);
    },
    [formProps]
  );

  const handleBancoIqChange = useCallback(
    (banco) => {
      if (!!banco && banco.codigo === 104) {
        setDesabilitarEdicaoIq(true);
        dispatch(
          actions.obterContatoIq.request({
            id: banco.id,
            successCallback,
          })
        );
      } else {
        formProps.setValue("contatoIq.nomeContato", "", {
          shouldValidate: false,
        });
        formProps.setValue("contatoIq.email", "", {
          shouldValidate: false,
        });
        formProps.setValue("contatoIq.telefone", "", {
          shouldValidate: false,
        });
      }
    },
    [dispatch, formProps, successCallback]
  );

  return (
    <OutrasInformacoesImovelSantanderForm
      {...otherProps}
      formProps={formProps}
      tipoProduto={operacao.tipoProduto}
      handleSubmit={handleSubmit}
      parteImovel={parteImovel}
      handleChangeParteImovel={handleChangeParteImovel}
      submitting={submitting}
      fluxo={imovel.fluxo}
      events={eventsImovel}
      operacao={operacao}
      formPropsPercentualAquisicao={formPropsPercentualAquisicao}
      bancos={bancos}
      desabilitarEdicaoIq={desabilitarEdicaoIq}
      searching={searching}
      handleBancoIqChange={handleBancoIqChange}
      compradores={compradores}
    />
  );
};

export default OutrasInformacoesImovelSantanderFormConnected;
