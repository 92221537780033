import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginPage from "./LoginPage";
import validators from "../../utils/validators";
import { actions } from "../../actions/autenticacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";
import { isLoginSubmitting } from "../../selectors/autenticacao.selectors";
import useCompleteForm from "../../hooks/useCompleteForm";

const LoginPageConnected = () => {
  const dispatch = useDispatch();
  const submitting = useSelector(isLoginSubmitting);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      email: validators.email({ required: true }),
      senha: validators.string({ required: true }),
    }),
    initialValues: () => ({
      email: "",
      senha: "",
    }),
    handleSubmit: useCallback(
      (values) => {
        dispatch(actions.login.request(values));
      },
      [dispatch]
    ),
  });

  const handleClickForgotPassword = useCallback(() => {
    dispatch(routeActions.redirectTo(routes.ESQUECI_SENHA));
  }, [dispatch]);

  const handleNewAccount = useCallback(() => {
    dispatch(routeActions.redirectTo(routes.CADASTRO_PARCEIRO));
  }, [dispatch]);

  return (
    <LoginPage
      submitting={submitting}
      formProps={formProps}
      handleClickForgotPassword={handleClickForgotPassword}
      handleSubmit={handleSubmit}
      handleNewAccount={handleNewAccount}
    />
  );
};

export default LoginPageConnected;
