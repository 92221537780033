import ComplementoOperacaoPage from "./ComplementoOperacaoPage";
import { useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isCarregandoComplementoOperacao,
} from "../../../selectors/operacao.selectors";

const ComplementoOperacaoPageConnected = () => {
  const loading = useSelector(isCarregandoComplementoOperacao);
  const data = useSelector(getComplementoOperacao);

  return <ComplementoOperacaoPage loading={loading} data={data} />;
};

export default ComplementoOperacaoPageConnected;
