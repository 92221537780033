import React, { useState } from "react";
import { Button, Form, Link } from "../../components";
import { ControlledTextField, CpfCnpjTextField, DateTextField, TextField } from "../../components/inputs";
import { Grid, IconButton, InputAdornment, styled } from "@mui/material";
import {
  CalendarMonth,
  LockOutlined,
  PersonOutline
} from "@mui/icons-material";



const LoginExternoForm = ({
  submitting,
  formProps,
  handleSubmit,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Grid item xs={12} md={12}>
        <ControlledTextField
          label={"CPF/CNPJ"}
          name={"cpfCnpj"}
          formProps={formProps}
          type={"both"}
          InputProps={{
            inputComponent: CpfCnpjTextField,
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutline />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <ControlledTextField
          label={"Data de Nascimento"}
          name={"dataNascimento"}
          formProps={formProps}
          InputProps={{
            inputComponent: DateTextField,
            startAdornment: (
              <InputAdornment position="start">
                <CalendarMonth />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <ControlledTextField
          label={"Código da Operação"}
          name={"codigoOperacaoExterno"}
          disabled
          formProps={formProps}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlined />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Button loading={submitting} type={"submit"} fullWidth>
          ENTRAR
        </Button>
      </Grid>
    </Form>
  );
};

export default LoginExternoForm;
