import { Button, Card, Form, Title } from "../../components";
import { Drawer, Grid, styled } from "@mui/material";
import React from "react";
import { Masonry } from "@mui/lab";
import DialogoVisivel from "../workflow/DialogoVisivel";
import DadosUsuarioForm from "./DadosUsuarioForm";
import DadosParceiroForm from "./DadosParceiroForm";
import { transientOptions } from "../../styles/theme";

const StyledDrawer = styled(Drawer, transientOptions)`
  & .MuiDrawer-paper {
    overflow-y: visible;
    border-radius: 1rem 1rem 0 0;
    left: 0;
  }

  ${({ theme, _isOpen_ }) => `${theme.breakpoints.up("sm")} {
  	& .MuiDrawer-paper {
			left: ${_isOpen_ ? theme.sizes.lateralMenu : theme.sizes.lateralMenuCollapsed};
		}
	`}
`;

const EnlargedForm = styled(Form)`
  width: calc(100% + 24px);
`;
const SpacedMasonry = styled(Masonry)`
  margin-bottom: 1rem;
`;

const DadosUsuarioTab = ({
  isOperacao,
  formProps,
  handleSubmit,
  submitting,
  editable,
  dialogosDisponiveis,
  isMenuOpen,
  times,
  dominios,
  perfil,
}) => {
  return (
    <Grid item xs={12}>
      <EnlargedForm onSubmit={handleSubmit}>
        <SpacedMasonry columns={{ xs: 1, md: 2, lg: 3 }} spacing={3}>
          <DialogoVisivel
            referencia={"DADOS_USUARIO"}
            dialogos={dialogosDisponiveis}
          >
            <Card>
              <Title as={"h3"} _margin_={"0 0 1rem 0"}>
                Dados do Usuário
              </Title>
              <DadosUsuarioForm
                isOperacao={isOperacao}
                formProps={formProps}
                dominios={dominios}
                forceDisabled={dialogosDisponiveis.DADOS_USUARIO === "C"}
                submitting={submitting}
                times={times}
                perfil={perfil}
              />
            </Card>
          </DialogoVisivel>
          <DialogoVisivel
            referencia={"DADOS_PARCEIRO"}
            dialogos={dialogosDisponiveis}
          >
            <Card>
              <Title as={"h3"} _margin_={"0 0 1rem 0"}>
                Dados do Parceiro
              </Title>
              <DadosParceiroForm
                formProps={formProps}
                forceDisabled={dialogosDisponiveis.DADOS_PARCEIRO === "C"}
              />
            </Card>
          </DialogoVisivel>
        </SpacedMasonry>
        {!!editable && (
          <StyledDrawer
            _isOpen_={isMenuOpen}
            variant="permanent"
            anchor={"bottom"}
          >
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              my={1.5}
              px={1.5}
            >
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Button fullWidth type={"submit"} loading={submitting}>
                  ATUALIZAR DADOS
                </Button>
              </Grid>
            </Grid>
          </StyledDrawer>
        )}
      </EnlargedForm>
    </Grid>
  );
};

export default DadosUsuarioTab;
