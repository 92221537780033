import { Grid, styled } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  GoBack,
  Link,
  Title,
} from "../../../../components";
import {
  AccountTextField,
  ControlledRadioGroup,
  ControlledTextField,
  IntegerTextField,
  Radio,
  TextField,
  ZipCodeTextField,
} from "../../../../components/inputs";
import Stepper from "../Stepper";
import { ArrowBack, InfoOutlined } from "@mui/icons-material";

const Help = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grey._500};
`;
const HelpLink = styled(Link)`
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 800;
  color: ${({ theme }) => theme.colors.secondary._700};
`;
const SizedControlledTextField = styled(ControlledTextField)`
  & .MuiInputBase-root {
    max-width: 140px;
  }
`;
const SizedTextField = styled(TextField)`
  & .MuiInputBase-root {
    max-width: 140px;
  }
`;

const OutrasInformacoesBradescoForm = ({
  fluxo,
  formProps,
  handleSubmit,
  submitting,
  events,
  handleOpenLink,
  handleGoTo4,
  isCorrentista,
  agenciaConveniada,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={handleGoTo4}
          text={"Preencha as informações do comprador"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={4} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Dados pessoais</Title>
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledRadioGroup
                      formProps={formProps}
                      name={"correntista"}
                      label={"É correntista do Bradesco?"}
                      row
                    >
                      <Radio value={true} label={"Sim"} />
                      <Radio value={false} label={"Não"} />
                    </ControlledRadioGroup>
                  </Grid>
                  {!!isCorrentista && (
                    <>
                      <Grid item xs={5} md={3}>
                        <ControlledTextField
                          label={"Agência"}
                          name={`agenciaConta`}
                          inputProps={{
                            maxLength: 4,
                            allowLeadingZeros: true,
                            asString: true,
                          }}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: IntegerTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={7} md={4}>
                        <ControlledTextField
                          label={"Conta"}
                          name={`numeroConta`}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: AccountTextField,
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {!isCorrentista && !agenciaConveniada && (
                    <>
                      <Grid item xs={12}>
                        <SizedControlledTextField
                          label={
                            "Informe a agência do Bradesco mais próxima de sua residência ou trabalho"
                          }
                          name={`agenciaConta`}
                          inputProps={{
                            maxLength: 4,
                            allowLeadingZeros: true,
                            asString: true,
                          }}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: IntegerTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} mt={-1}>
                        <Help>
                          Não sabe qual a agência mais próxima?{" "}
                          <HelpLink onClick={handleOpenLink}>
                            Clique aqui
                          </HelpLink>{" "}
                          e descubra!
                        </Help>
                      </Grid>
                      <Grid item xs={12}>
                        <SizedControlledTextField
                          label={"Informe o CEP utilizado na pesquisa"}
                          name={`cepAgencia`}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: ZipCodeTextField,
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {!isCorrentista && !!agenciaConveniada && (
                    <>
                      {/*<Grid item xs={12}>*/}
                      {/*  <SizedTextField*/}
                      {/*    label={*/}
                      {/*      "Informe a agência do Bradesco mais próxima de sua residência ou trabalho"*/}
                      {/*    }*/}
                      {/*    value={agenciaConveniada.codigo}*/}
                      {/*    inputProps={{*/}
                      {/*      maxLength: 4,*/}
                      {/*      allowLeadingZeros: true,*/}
                      {/*      asString: true,*/}
                      {/*      readOnly: true,*/}
                      {/*    }}*/}
                      {/*    InputProps={{*/}
                      {/*      inputComponent: IntegerTextField,*/}
                      {/*    }}*/}
                      {/*  />*/}
                      {/*</Grid>*/}
                      <Grid item container alignItems={"center"} spacing={2}>
                        <Grid item>
                          <InfoOutlined />
                        </Grid>
                        <Grid item flex={1}>
                          <Help>
                            Temos uma parceria com o banco Bradesco onde
                            utilizamos agências selecionadas próximas de sua
                            residência para nos ajudar a acelerar seu processo
                            de Crédito Imobiliário.
                          </Help>
                        </Grid>
                      </Grid>
                      <Grid item container>
                        <Grid item xs={12}>
                          <Help>
                            Agência: <b>{agenciaConveniada.codigo}</b> -{" "}
                            <b>{agenciaConveniada.nome}</b>
                          </Help>
                        </Grid>
                        <Grid item xs={12}>
                          <Help>
                            Endereço:{" "}
                            <b>{agenciaConveniada.enderecoCompleto}</b>
                          </Help>
                        </Grid>
                        <Grid item xs={12}>
                          <Help>
                            Telefone: <b>{agenciaConveniada.telefoneFixo}</b>
                          </Help>
                        </Grid>
                        <Grid item xs={12}>
                          <Help>
                            Gerente: <b>{agenciaConveniada.nomeGerente}</b>
                          </Help>
                        </Grid>
                        <Grid item xs={12}>
                          <Help>
                            E-mail: <b>{agenciaConveniada.emailGerente}</b>
                          </Help>
                        </Grid>
                        <Grid item xs={12}>
                          <Help>
                            Celular: <b>{agenciaConveniada.celularGerente}</b>
                          </Help>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OutrasInformacoesBradescoForm;
