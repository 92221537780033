import React from "react";
import { Controller } from "react-hook-form";
import { Autocomplete, styled } from "@mui/material";
import TextField from "./TextField";
import MenuItem from "../MenuItem";
import { getValueFromObject } from "../../utils/basic";
import AutocompleteTag from "./AutocompleteTag";

const AutocompleteGroupHeader = styled('div')`
  position: sticky;
  top: -8px;
  padding: 8px 16px;
  background-color: #FFFFFF;
  z-index: 1;
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme, size = "normal" }) =>
    theme.fonts.size.inputs[size].label};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secondary._500};
`;

const ControlledAutocomplete = ({
  formProps,
  validationKey,
  domain,
  idField = "id",
  labelField,
  getOptionLabel = (option) =>
    option[labelField] ??
    domain.find((d) => d[idField] === option?.[idField])?.[labelField] ??
    "",
  name,
  ignoreError = false,
  autocompleteProps = {},
  groupBy=()=>{},
  multiple = false,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    rules,
  } = formProps;
  const isError =
    getValueFromObject(errors, name) !== undefined && !ignoreError;

  return (
    <Controller
      name={name}
      control={control}
      rules={
        autocompleteProps?.disabled
          ? {}
          : getValueFromObject(rules, validationKey ?? name)
      }
      render={({ field: { onChange, onBlur, value } }) => (
        <Autocomplete
          options={domain}
          groupBy={groupBy}
          getOptionLabel={getOptionLabel}
          multiple={multiple}
          isOptionEqualToValue={(option, v) =>
            option?.[idField] === v || option?.[idField] === v?.[idField]
          }
          value={multiple ? (Array.isArray(value) ? value : []) : value}
          {...autocompleteProps}
          onChange={(e, v) => {
            onChange(v);
            if (!!autocompleteProps.onChange) {
              autocompleteProps.onChange(v);
            }
          }}
          onBlur={() => {
            onBlur();
            if (!!autocompleteProps.onBlur) {
              autocompleteProps.onBlur();
            }
          }}
          renderOption={(params, o) => (
            <MenuItem {...params} key={o.id}>{getOptionLabel(o)}</MenuItem>
          )}
          renderGroup={(params) => (
            <li key={params.key}>
              <AutocompleteGroupHeader>
                {params.group}
              </AutocompleteGroupHeader>
              {params.children}
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <AutocompleteTag
                key={option[idField]}
                label={getOptionLabel(option)}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              {...otherProps}
              value={value}
              error={isError}
              helperText={
                !ignoreError
                  ? getValueFromObject(errors, name)?.message
                  : undefined
              }
            />
          )}
        />
      )}
    />
  );
};

export default ControlledAutocomplete;
