import React, { useCallback } from "react";
import DadosBasicosSantanderForm from "./DadosBasicosSantanderForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isBuscandoPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import validators from "../../../../utils/validators";
import { actions } from "../../../../actions/operacao.actions";
import {
  removeNonDigitsFromString,
  transformToDate,
} from "../../../../utils/basic";
import formatters from "../../../../utils/formatters";

const DadosBasicosSantanderFormConnected = ({
  handleGoTo2,
  eventsNaoCorrentista,
  setComprador,
  comprador,
  ...props
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: {
      nacionalidade: nacionalidades,
      genero: generos,
      documentoPessoal: documentosPessoais,
    },
  } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);
  const searching = useSelector(isBuscandoPreenchimentoOperacao);

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setComprador({
          ...c,
          fluxo: data.validacao[
            data.operacao.detalhe.correntista ? "correntista" : "completa"
          ]?.participantes?.find((f) => f.id === c?.id)?.etapas,
        });
      }
      handleGoTo2();
    },
    [handleGoTo2, setComprador, comprador]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      cpfCnpj: validators.cpfCnpj({
        required: false,
        custom: {
          obrigatorio: (value) => {
            const c = operacao.participantes.find((p) => p.id === comprador.id);
            if (!!c.cpfCnpj) return true;
            if (value !== undefined && value !== null && value !== "") {
              return true;
            }
            return "Campo obrigatório";
          },
        },
      }),
      nome: validators.string({
        required: true,
        maxLength: 100,
        custom: {
          nomeCompleto: (value) =>
            !value ||
            (value.indexOf(" ") > 0 &&
              value.substring(value.indexOf(" ") + 1).length > 0) ||
            "Nome informado deve ser completo",
        },
      }),

      dataNascimento: validators.date({
        required: true,
        min: "01/01/1900",
        max: formatters.dates.short(new Date()),
      }),
      nacionalidade: validators.object({ required: true }),
      email: validators.email({ required: true }),
      nomeMae: validators.string({ required: true, maxLength: 100 }),
      genero: validators.object({ required: true }),
      documentoPessoal: validators.object({ required: true }),
      numeroDocumentoPessoal: validators.string({
        required: true,
        maxLength: 100,
      }),
      orgaoDocumentoPessoal: validators.string({
        required: true,
        maxLength: 100,
      }),
      ufOrgaoDocumentoPessoal: validators.string({
        required: true,
      }),
      dataExpedicaoDocumentoPessoal: validators.date({
        required: true,
        min: "01/01/1900",
        max: formatters.dates.short(new Date()),
      }),
      telefone: validators.phone({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        cpfCnpj: "",
        nome: "",
        dataNascimento: "",
        nomeMae: "",
        telefoneResidencial: "",
        nacionalidade: null,
        genero: null,
        documentoPessoal: null,
        email: "",
        telefone: "",
        numeroDocumentoPessoal: "",
        orgaoDocumentoPessoal: "",
        ufOrgaoDocumentoPessoal: "",
        dataExpedicaoDocumentoPessoal: "",
        ...comprador,
      }),
      [comprador]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-pessoais",
            participante: {
              id: comprador?.id,
              cpfCnpj: removeNonDigitsFromString(values.cpfCnpj),
              nomeParticipante: values.nome,
              dataNascimento: transformToDate(values.dataNascimento),
              nomeMae: values.nomeMae,
              telefoneResidencial: values.telefoneResidencial,
              idNacionalidade: values.nacionalidade?.id,
              idGenero: values.genero?.id,
              idDocumentoPessoal: values.documentoPessoal?.id,
              numeroDocumentoPessoal: values.numeroDocumentoPessoal,
              orgaoDocumentoPessoal: values.orgaoDocumentoPessoal,
              ufOrgaoDocumentoPessoal: values.documentoPessoal?.descricao === "RNE" ? null : values.ufOrgaoDocumentoPessoal,
              dataExpedicaoDocumentoPessoal: transformToDate(
                values.dataExpedicaoDocumentoPessoal
              ),
              email: values.email,
              telefone: values.telefone,
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, comprador, operacao, callback]
    ),
  });

  const { reset } = formProps;

  const successCallback = useCallback(
    (data) => {
      if (!!data) {
        const c = data.operacao.participantes.find(
          (p) => p.id === comprador.id
        );
        if (!!c) {
          setComprador({
            ...c,
            fluxo: data.validacao[
              data.operacao.detalhe.correntista ? "correntista" : "completa"
            ]?.participantes?.find((f) => f.id === c?.id)?.etapas,
          });
          reset({
            cpfCnpj: "",
            nome: "",
            dataNascimento: "",
            nomeMae: "",
            telefoneResidencial: "",
            nacionalidade: null,
            genero: null,
            documentoPessoal: null,
            email: "",
            telefone: "",
            numeroDocumentoPessoal: "",
            orgaoDocumentoPessoal: "",
            ufOrgaoDocumentoPessoal: "",
            dataExpedicaoDocumentoPessoal: "",
            ...c,
          });
        }
      }
    },
    [setComprador, comprador, reset]
  );

  const handleCpfCnpjChange = useCallback(
    (v) => {
      if (!!v) {
        dispatch(
          actions.atualizarParticipantePreenchimento.request({
            id: comprador.id,
            cpfCnpj: removeNonDigitsFromString(v),
            tipo: "preenchimento",
            callback: successCallback,
          })
        );
      }
    },
    [dispatch, comprador, successCallback]
  );

  const isEstrangeiro = formProps.watch("documentoPessoal")?.id === 4;

  return (
    <DadosBasicosSantanderForm
      {...props}
      tipoPessoa={operacao.detalhe.tipoPessoa}
      nacionalidades={nacionalidades}
      documentosPessoais={documentosPessoais}
      handleCpfCnpjChange={handleCpfCnpjChange}
      searching={searching}
      cpfCnpjDisabled={!!comprador.cpfCnpj}
      generos={generos}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={comprador.fluxo}
      events={eventsNaoCorrentista}
      isEstrangeiro={isEstrangeiro}
    />
  );
};

export default DadosBasicosSantanderFormConnected;
