import React from "react";
import { Chip as MChip, Grid, styled } from "@mui/material";
import { Field } from "../../components/presentation";
import { Card, Title } from "../../components";
import { FORMA_CONTATO } from "../../constants/generic.constants";

const Chip = styled(MChip)`
  margin-top: 0.25rem;
  padding: 0 0.25rem;

  & .MuiChip-label {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.small};
  }

  & + & {
    margin-left: 0.5rem;
  }
`;

const InformacoesPessoaisLead = ({ lead }) => {
  return (
    <Card>
      <Title as={"h5"} _margin_={"0 0 1rem 0"}>
        Informações de Contato
      </Title>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field label={"Nome informado"} value={lead.nomeCliente} />
        </Grid>
        {lead.formaContato && (
          <Grid item xs={12}>
            <Field
              label={"Forma preferida de contato"}
              type={"decode"}
              value={lead.formaContato ?? ""}
              decode={(formaContato) =>
                formaContato
                  .split("")
                  .map((f) => FORMA_CONTATO[f]?.descricao)
                  .map((f) => <Chip key={f} label={f} size={"small"} />)
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Field label={"Telefone informado"} value={lead.telefone} />
        </Grid>
        <Grid item xs={12}>
          <Field label={"Email informado"} value={lead.email} />
        </Grid>
        <Grid item xs={12}>
          <Field label={"Indicado por"} value={lead.indicadoPor} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default InformacoesPessoaisLead;
