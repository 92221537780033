import React from "react";
import { ControlledSwitch, ControlledTextField } from "../../components/inputs";
import { Grid } from "@mui/material";

const DadosCertificadosForm = ({ formProps, forceDisabled }) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <ControlledSwitch
          label="ANEPS"
          disabled={forceDisabled}
          name={"certificados.aneps"}
          formProps={formProps}
        />
      </Grid>
      <Grid item>
        <ControlledSwitch
          label="CA300"
          disabled={forceDisabled}
          name={"certificados.ca300"}
          formProps={formProps}
        />
      </Grid>
      <Grid item>
        <ControlledSwitch
          label="CA600"
          disabled={forceDisabled}
          name={"certificados.ca600"}
          formProps={formProps}
        />
      </Grid>
      <Grid item>
        <ControlledSwitch
          label="ANCORD"
          disabled={forceDisabled}
          name={"certificados.ancord"}
          formProps={formProps}
        />
      </Grid>
      <Grid item>
        <ControlledSwitch
          label="CRECI"
          disabled={forceDisabled}
          name={"certificados.creci"}
          formProps={formProps}
        />
      </Grid>
      <Grid item>
        <ControlledSwitch
          label="CPA10"
          disabled={forceDisabled}
          name={"certificados.cpa10"}
          formProps={formProps}
        />
      </Grid>
      <Grid item>
        <ControlledSwitch
          label="CPA20"
          disabled={forceDisabled}
          name={"certificados.cpa20"}
          formProps={formProps}
        />
      </Grid>
      <Grid item>
        <ControlledSwitch
          label="CEA"
          disabled={forceDisabled}
          name={"certificados.cea"}
          formProps={formProps}
        />
      </Grid>
      <Grid item>
        <ControlledSwitch
          label="FBB100"
          disabled={forceDisabled}
          name={"certificados.fbb100"}
          formProps={formProps}
        />
      </Grid>
      <Grid item>
        <ControlledTextField
          label="Outros"
          disabled={forceDisabled}
          name={"certificados.outros"}
          inputProps={{ maxLength: 30 }}
          formProps={formProps}
        />
      </Grid>
    </Grid>
  );
};

export default DadosCertificadosForm;
