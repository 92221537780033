import PropostasTab from "./PropostasTab";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetalhesCliente,
  isIniciandoRelacionada,
} from "../../selectors/clientes.selectors";
import {getLead} from "../../selectors/calculadora.selectors";
import { useCallback } from "react";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";
import { actions } from "../../actions/operacao.actions";
import {actions as calculadoraActions} from "../../actions/calculadora.actions";

const PropostasTabConnected = () => {
  const dispatch = useDispatch();
  const data = useSelector(getDetalhesCliente);
  const initing = useSelector(isIniciandoRelacionada);
  const {submitting} = useSelector(getLead)

  const handleInit = useCallback(
    (regra, correntista) => {
      dispatch(actions.iniciarRelacionada.request({ regra, correntista }));
    },
    [dispatch]
  );

  const handleOperation = useCallback(
    (operacao) => {
      dispatch(
        routeActions.redirectTo(routes.DETALHES_OPERACAO, { id: operacao.id })
      );
    },
    [dispatch]
  );

  const handleDuplicateLead = useCallback((values)=>{
    dispatch(
      calculadoraActions.duplicarLead.request({
        ...values
      })
    );
  },[dispatch])

  const handleNotInterested = useCallback((values)=>{
    dispatch(
      calculadoraActions.cancelarRegraComercial.request({
        ...values
      })
    );
  },[dispatch])

  return (
    <PropostasTab
      cliente={data}
      leads={data.leads}
      handleOperation={handleOperation}
      handleInit={handleInit}
      handleDuplicateLead={handleDuplicateLead}
      handleNotInterested={handleNotInterested}
      initing={initing}
      submitting={submitting}
    />
  );
};

export default PropostasTabConnected;
