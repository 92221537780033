import { Grid, styled } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  GoBack,
  Title,
  Tooltip,
  Text,
} from "../../../../../components";
import Stepper from "../../../preenchimento/Stepper";
import ItemOperacao from "../ItemOperacao";
import { Apartment, Person, Warning } from "@mui/icons-material";
import formatters from "../../../../../utils/formatters";
import { transientOptions } from "../../../../../styles/theme";

const CompradorContainer = styled("div")`
  width: calc(100% - 1rem);
  padding: 1rem 0.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.grey._50};
`;
const Disclaimer = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  text-align: center;
  display: block;
  color: ${({ theme }) => theme.colors.grey._500};
`;
const DisclaimerContainer = styled("div", transientOptions)`
  border-radius: ${({ theme }) => theme.borderRadiuses.disclaimer};
  border: 1px dashed
    ${({ theme, _color_ = "secondary" }) => theme.colors[_color_]._700};
  background-color: ${({ theme, _color_ = "primary" }) =>
    theme.colors[_color_]._100};
  color: ${({ theme, _color_ = "secondary" }) => theme.colors[_color_]._600};
  padding: 1rem 1.5rem;
  margin: 0.75rem 0 0;
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-family: ${({ theme }) => theme.fonts.family.default};
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DadosEnderecoOutrosProponentesFintechsForm = ({
  fluxo,
  submitting,
  socios,
  refreshing,
  deleting,
  adding,
  events,
  makeGoTo,
  handleAcessarSocio,
  handleRemoverSocio,
  handleAdicionarSocio,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={makeGoTo(50)}
          text={"Preencha as informações do proponente"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={2} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container justifyContent={"center"}>
          <Grid item width={1}>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Title as={"h5"}>Sócios</Title>
                </Grid>
                <Grid item xs={12}>
                  {socios.map((s) => {
                    return (
                      <ItemOperacao
                        key={s.id}
                        fluxo={s.fluxo}
                        submitting={submitting}
                        refreshing={refreshing}
                        deleting={deleting}
                        adding={adding}
                        icon={s.tipoPessoa === "F" ? <Person /> : <Apartment />}
                        title={s.nome ?? "(Nome não informado)"}
                        subtitle={formatters.strings.cpfCnpj(
                          s.cpfCnpj,
                          s.tipoPessoa
                        )}
                        isChildren={false}
                        titleRemover={"Remover sócio"}
                        canRemove={true}
                        handleRemover={(e) => handleRemoverSocio(e, s.id)}
                        handleEditar={(e) => handleAcessarSocio(e, s)}
                        details={<></>}
                      />
                    );
                  })}
                  <CompradorContainer>
                    <Grid
                      container
                      spacing={2}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      {
                        socios.length >= 2
                          ? <Grid item xs={12}>
                            <DisclaimerContainer _color_="primary">
                              <Warning /> {" "} Número máximo de sócios atingido!
                            </DisclaimerContainer>
                          </Grid>
                          : <>
                            <Grid item xs={12} sm={"auto"}>
                              <Disclaimer>
                                {socios.length === 0
                                  ? "Adicionar um sócio"
                                  : "Gostaria de adicionar outro sócio?"}
                              </Disclaimer>
                            </Grid>
                            <Grid item>
                              <Tooltip arrow title={"Adicionar sócio PF"}>
                                <Button
                                  variant={"outlined"}
                                  size={"small"}
                                  loading={adding || refreshing}
                                  loaderColor={"brand"}
                                  onClick={() => handleAdicionarSocio("F")}
                                >
                                  PF
                                </Button>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip arrow title={"Adicionar sócio PJ"}>
                                <Button
                                  variant={"outlined"}
                                  size={"small"}
                                  loading={adding || refreshing}
                                  loaderColor={"brand"}
                                  onClick={() => handleAdicionarSocio("J")}
                                >
                                  PJ
                                </Button>
                              </Tooltip>
                            </Grid>
                          </>
                      }
                    </Grid>
                  </CompradorContainer>
                </Grid>
                <Grid item container justifyContent={"center"} mt={1}>
                  <Grid item xs={12} md={6}>
                    <Button
                      onClick={makeGoTo(0)}
                      fullWidth
                      loading={submitting}
                    >
                      RETORNAR À TELA PRINCIPAL
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosEnderecoOutrosProponentesFintechsForm;
