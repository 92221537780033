import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import QualificacaoImovel from "./QualificacaoImovel";
import { actions } from "../../../../../actions/operacao.actions";

const QualificacaoImovelConnected = ({
  handleGoTo4,
  events,
  data,
  submitting,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { operacaoConfirmacaoValores } = data;

  const callback = useCallback(() => {
    handleGoTo4();
  }, [handleGoTo4]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      aceitaQualificacaoImovel: validators.object({ required: true }),
      correcaoImovel: validators.string({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        correcaoImovel: "",
        ...operacaoConfirmacaoValores,
        aceitaQualificacaoImovel:
          operacaoConfirmacaoValores.aceitaQualificacaoImovel + "",
      }),
      [operacaoConfirmacaoValores]
    ),
    handleSubmit: useCallback(
      (values) => {
        return dispatch(
          actions.enviarConfirmacaoValores.request({
            callback,
            passo: "imovel",
            instituicao: "itau",
            operacaoConfirmacaoValores: {
              idOperacao: values.idOperacao,
              aceitaQualificacaoImovel: values.aceitaQualificacaoImovel,
              correcaoImovel:
                values.aceitaQualificacaoImovel === "false"
                  ? values.correcaoImovel
                  : null,
            },
          })
        );
      },
      [dispatch, callback]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      correcaoImovel: "",
      ...operacaoConfirmacaoValores,
      aceitaQualificacaoImovel:
        operacaoConfirmacaoValores.aceitaQualificacaoImovel + "",
    });
  }, [operacaoConfirmacaoValores, reset]);

  return (
    <QualificacaoImovel
      {...otherProps}
      formProps={formProps}
      handleSubmit={handleSubmit}
      events={events}
      submitting={submitting}
    />
  );
};

export default QualificacaoImovelConnected;
