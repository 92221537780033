import { CloseSharp, Comment } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  styled,
} from "@mui/material";
import formatters from "../../utils/formatters";
import { Text, Title } from "../../components";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    width: 100%;
  }
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;
const CommentSection = styled(Timeline)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  padding: 0.5rem 1rem;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.colors.grey._50};
  border-radius: 1rem;

  & .MuiTimelineItem-root {
    min-height: max-content;
  }
  & .MuiTimelineItem-missingOppositeContent:before {
    flex: 0;
    padding: 0;
  }
  & .MuiTimelineDot-root {
    background-color: ${({ theme }) => theme.colors.grey._100};
    padding: 6px;
    box-shadow: none;
  }
  & .MuiTimelineConnector-root {
    background-color: transparent;
    border-right: 1px dashed ${({ theme }) => theme.colors.grey._200};
  }
  & .MuiTimelineContent-root {
  }
`;
const MessageIcon = styled(Comment)`
  color: ${({ theme, _tipo_ = "OP" }) =>
    _tipo_ === "OP"
      ? theme.colors.secondary._700
      : _tipo_ === "SI"
        ? theme.colors.primary._50
        : _tipo_ === "PA"
          ? theme.colors.primary._500
          : _tipo_ === "CL"
            ? theme.colors.grey._700
            : theme.colors.info._700};
  font-size: 1.2rem;
`;
const DateTimeText = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme }) => theme.colors.grey._300};
  margin: 0.25rem 0 0;
`;
const MessageReason = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme }) => theme.colors.primary._700};
  margin: 0 0 0.25rem;
`;
const MessageText = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.grey._500};
  margin: 0.25rem 0;
`;
const MessageAuthor = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 300;
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme }) => theme.colors.grey._200};
  margin: 0;
`;
const getTooltipComentario = ({ tpUsuarioUltComentario }) => {
  switch (tpUsuarioUltComentario) {
    case "OP":
      return "Comentário feito pela equipe de operações Taya";
    case "SI":
      return "Comentário feito pela plataforma Taya";
    case "PA":
      return "Comentário feito por um usuário parceiro";
    case "CL":
      return "Comentário feito por um usuário cliente";
    default:
      return "";
  }
};

const SkeletonComentary = () => {
  return (
    <Grid container spacing={2} mt={0.5}>
      <Grid item>
        <Skeleton variant={"circular"} width={36} height={36} />
      </Grid>
      <Grid item>
        <Grid container direction={"column"}>
          <Grid item>
            <Skeleton variant={"text"} width={140} height={20} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const UltComentarioDialog = ({ handleClose, open, loading, data, ...props }) => {
  return (
    <StyledDialog
      onClose={() => handleClose()}
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
    >
      {open ?
        <DialogContent>
          <CloseIconButton onClick={handleClose}>
            <CloseSharp />
          </CloseIconButton>
          <Title as={"h2"} _margin_={"0 0 1rem 0"}>
            Último Comentário
          </Title>
          {data && data.dsUltComentario
            ? <CommentSection>
              <TimelineItem>
                <TimelineSeparator>
                  <Tooltip arrow title={getTooltipComentario(data)}>
                    <TimelineDot>
                      <MessageIcon _tipo_={data.tpUsuarioUltComentario} />
                    </TimelineDot>
                  </Tooltip>
                </TimelineSeparator>
                <TimelineContent>
                  <DateTimeText>
                    {formatters.dates.simple(
                      new Date(data.dhUltComentario),
                      "dd/MM/yyyy HH:mm:ss"
                    )}
                  </DateTimeText>
                  <MessageText>{data.dsUltComentario}</MessageText>
                  <MessageAuthor>
                    {data.nmUsuarioUltComentario +
                      (!!data.autorUltComentario
                        ? ` (${data.autorUltComentario})`
                        : "")}
                  </MessageAuthor>
                </TimelineContent>
              </TimelineItem>
            </CommentSection>
            : <Text>Nenhum comentário ainda</Text>
          }
        </DialogContent>
        : null}
    </StyledDialog>
  );
};

export default UltComentarioDialog;