import TrocarSenhaPage from "./TrocarSenhaPage";
import { useSelector } from "react-redux";
import { getTrocarSenha } from "../../selectors/autenticacao.selectors";

const TrocarSenhaPageConnected = () => {
  const { loading, valid } = useSelector(getTrocarSenha);

  return <TrocarSenhaPage valid={valid} loading={loading} />;
};

export default TrocarSenhaPageConnected;
