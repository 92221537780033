import { Grid, styled } from "@mui/material";
import React from "react";
import { Button, Card, MenuItem, Form, GoBack, Title } from "../../../../../components";
import {
  ControlledRadioGroup,
  ControlledTextField,
  CpfCnpjTextField,
  CurrencyTextField,
  Radio,
} from "../../../../../components/inputs";
import Stepper from "../../Stepper";
import ControlledAutocomplete from "../../../../../components/inputs/ControlledAutocomplete";
import { transientOptions } from "../../../../../styles/theme";
import formatters from "../../../../../utils/formatters";
import { CurrencyExchange } from "@mui/icons-material";
import { estados } from "../../../../../constants/generic.constants";

const DisclaimerContainer = styled("div", transientOptions)`
  border-radius: ${({ theme }) => theme.borderRadiuses.disclaimer};
  border: 1px dashed
    ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._300};
  background-color: ${({ theme, _color_ = "primary" }) =>
    theme.colors[_color_]._50};
  color: ${({ theme, _color_ = "primary" }) => theme.colors[_color_]._600};
  padding: 1rem 1.5rem;
  margin: 0.75rem 0 0;
`;
const Disclaimer = styled("p", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 500;
  color: inherit;
  margin: 0;
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const OutrasInformacoesImovelGalleriaForm = ({
  fluxo,
  formProps,
  handleSubmit,
  submitting,
  events,
  subTiposImovel,
  makeGoTo,
}) => {
  const imovelFinanciado = formProps.watch("imovelFinanciado");
  const subTipoImovel = formProps.watch("subTipoImovel");
  const valorImovel = formProps.watch("valorImovel");
  const tipoPessoaProprietario = formProps.watch("tipoPessoaProprietario");
  const eProprietarioDoImovel = formProps.watch("eProprietarioDoImovel");

  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={makeGoTo(100)}
          text={"Preencha as informações do imóvel"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={1} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Características do Imóvel</Title>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledAutocomplete
                      label="Tipo do Imóvel"
                      name={"subTipoImovel"}
                      domain={subTiposImovel}
                      idField={"id"}
                      labelField={"descricao"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="Valor do Imóvel"
                      name={"valorImovel"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: CurrencyTextField,
                      }}
                      inputProps={{
                        allowNegative: false,
                      }}
                    />
                  </Grid>
                  {valorImovel > 0 && subTipoImovel?.ltv?.ltv > 0 && (
                    <Grid item xs={12}>
                      <DisclaimerContainer>
                        <Disclaimer>
                          <CurrencyExchange /> Crédito Máximo estimado:{" "}
                          <b>
                            R${" "}
                            {formatters.numbers.currency(
                              (valorImovel * subTipoImovel?.ltv?.ltv) / 100
                            )}
                          </b>
                        </Disclaimer>
                      </DisclaimerContainer>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Title as={"h5"}>Outras informações</Title>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <ControlledRadioGroup
                        label="O imóvel está financiado?"
                        name={"imovelFinanciado"}
                        row
                        formProps={formProps}
                      >
                        <Radio value={true} label={"Sim"} />
                        <Radio value={false} label={"Não"} />
                      </ControlledRadioGroup>
                    </Grid>
                    {imovelFinanciado + "" === "true" && (
                      <>
                        <Grid item xs={12} md={6}>
                          <ControlledTextField
                            label="Saldo Devedor"
                            name={"saldoDevedorFinanciamento"}
                            formProps={formProps}
                            InputProps={{
                              inputComponent: CurrencyTextField,
                            }}
                            inputProps={{
                              allowNegative: false,
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Sobre o Imóvel e Cartório</Title>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="N° da Matrícula"
                      name={"matricula"}
                      inputProps={{ maxLength: 8 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="N° do Cartório de Registro"
                      name={"cartorio.numero"}

                      inputProps={{ maxLength: 2 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={9} md={8}>
                    <ControlledTextField
                      label="Município"
                      name={"cartorio.municipio"}
                      inputProps={{ maxLength: 40 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={3} md={4}>
                    <ControlledTextField
                      label="UF"
                      select
                      name={"cartorio.uf"}
                      inputProps={{ maxLength: 40 }}
                      formProps={formProps}
                    >
                      {estados.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </ControlledTextField>
                  </Grid>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Sobre a propriedade</Title>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledRadioGroup
                      name={"eProprietarioDoImovel"}
                      formProps={formProps}
                      label={"É proprietário do imóvel?"}
                      row
                    >
                      <Radio value={"true"} label={"Sim"} />
                      <Radio value={"false"} label={"Não"} />
                    </ControlledRadioGroup>
                  </Grid>
                  {eProprietarioDoImovel == "false" ?
                    <>
                      <Grid item xs={12} md={4}>
                        <ControlledRadioGroup
                          name={"tipoPessoaProprietario"}
                          formProps={formProps}
                          label={"O proprietário é PF ou PJ?"}
                          row
                        >
                          <Radio value={"F"} label={"PF"} />
                          <Radio value={"J"} label={"PJ"} />
                        </ControlledRadioGroup>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label={tipoPessoaProprietario == "F" ? "CPF" : "CNPJ"}
                          name={"cpfCnpjProprietario"}
                          formProps={formProps}
                          type={tipoPessoaProprietario == "F" ? "cpf" : "cnpj"}
                          InputProps={{
                            inputComponent: CpfCnpjTextField,
                          }}
                        />
                      </Grid>
                    </>
                    : null}
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OutrasInformacoesImovelGalleriaForm;
