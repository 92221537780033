import React, { useCallback } from "react";
import EscolhaParticipanteDefaultForm from "./EscolhaParticipanteDefaultForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isAdicionandoParticipantePreenchimento,
  isAtualizandoPreenchimento,
  isEnviandoPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
  isRemovendoParticipantePreenchimento,
} from "../../../../selectors/operacao.selectors";
import { actions } from "../../../../actions/operacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../../actions/rotas.actions";
import { toast } from "react-toastify";
import { getEtapasAbertas } from "../../../../selectors/workflow.selectors";

const EscolhaParticipanteDefaultFormConnected = ({
  setComprador,
  setStep,
  handleGoTo995,
  compradores,
}) => {
  const dispatch = useDispatch();
  const { operacao, dominios, validacao } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);
  const sending = useSelector(isEnviandoPreenchimentoOperacao);
  const adding = useSelector(isAdicionandoParticipantePreenchimento);
  const deleting = useSelector(isRemovendoParticipantePreenchimento);
  const refreshing = useSelector(isAtualizandoPreenchimento);
  const etapasAbertas = useSelector(getEtapasAbertas);

  const isReenvio = etapasAbertas.find((e) => e.padrao === "RP");

  const handleAcessarParticipante = useCallback(
    (e, c) => {
      e.stopPropagation();
      setComprador(c);
      setStep(1);
    },
    [setComprador, setStep]
  );
  const handleAdicionarParticipante = useCallback(() => {
    dispatch(
      actions.adicionarParticipantePreenchimento.request({
        id: operacao.id,
        tipoParticipante: "CO",
        tipoPessoa: "F",
      })
    );
  }, [dispatch, operacao]);
  const handleRemoverParticipante = useCallback(
    (e, participante) => {
      e.stopPropagation();
      dispatch(
        actions.removerParticipantePreenchimento.request({
          id: operacao.id,
          participante,
        })
      );
    },
    [dispatch, operacao]
  );

  const possuiPendencias = compradores.reduce(
    (result, c) =>
      result ||
      c.fluxo.filter(
        (f) => f.situacao === "PARCIAL" || f.situacao === "PENDENTE"
      ).length > 0,
    false
  );

  const handleGoToSiblings = useCallback(() => {
    toast.success("Proposta enviada para o Crédito.");
    dispatch(
      routeActions.redirectTo(routes.DETALHES_CLIENTE, { id: operacao.cliente })
    );
  }, [dispatch, operacao]);

  const handleGoBack = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.DETALHES_OPERACAO, { id: operacao.id })
    );
  }, [dispatch, operacao]);

  const handleEnviarAnaliseCredito = useCallback(() => {
    dispatch(
      actions.enviarPreenchimento.request({
        id: operacao.id,
        callback: !!isReenvio ? handleGoBack : handleGoToSiblings,
      })
    );
  }, [dispatch, operacao, handleGoToSiblings, isReenvio, handleGoBack]);

  const handleReenquadrarOperacao = useCallback((e)=>{
    e.stopPropagation();
    handleGoTo995()
  },[])

  return (
    <EscolhaParticipanteDefaultForm
      compradores={compradores}
      handleAcessarParticipante={handleAcessarParticipante}
      handleEnviarAnaliseCredito={handleEnviarAnaliseCredito}
      possuiPendencias={possuiPendencias}
      submitting={submitting}
      adding={adding}
      handleAdicionarParticipante={handleAdicionarParticipante}
      deleting={deleting}
      mensagens={validacao["completa"].mensagens}
      handleRemoverParticipante={handleRemoverParticipante}
      desenquadrado={operacao?.detalhe?.desenquadrado}
      handleReenquadrarOperacao={handleReenquadrarOperacao}
      refreshing={refreshing}
      sending={sending}
      dominios={dominios}
      handleGoBack={handleGoBack}
    />
  );
};

export default EscolhaParticipanteDefaultFormConnected;
