import React from "react";
import QualificacaoValoresConnected from "./qualificacoes/QualificacaoValores.connected";

const FluxoQualificacoes = (props) => {
  return (
    <>
      {props.step === 0 && (
        <QualificacaoValoresConnected {...props} />
      )}
    </>
  );
};

export default FluxoQualificacoes;
