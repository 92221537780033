import CommonPage from "../CommonPage";
import React from "react";
import SelecionarProdutoSectionConnected from "./SelecionarProdutoSection.connected";
import ParametrosSimulacaoSectionConnected from "./ParametrosSimulacaoSection.connected";
import ResultadoSimulacaoSectionConnected from "./ResultadoSimulacaoSection.connected";
import DadosClienteSectionConnected from "./DadosClienteSection.connected";
import ValidarRegrasSectionConnected from "./ValidarRegrasSection.connected";
import { Grid, Skeleton, styled } from "@mui/material";

const SkeletonInstrucao = styled(Skeleton)`
  font-size: ${({ theme }) => theme.fonts.size.large};
`;
const SkeletonProduto = styled(Skeleton)`
  border-radius: 1rem;
  margin: 0 0.5rem 2rem;
  width: 157px;
  height: 202px;

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    width: 258px;
    height: 205px;
    margin: 0 1.5rem 2rem;
  }`}
`;
const SimuladorInternoPage = ({ atual, loading }) => {
  if (!!loading) {
    return (
      <CommonPage selected={"simulador"} display={"flex"}>
        <Grid
          container
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          rowSpacing={4}
        >
          <SkeletonInstrucao variant={"text"} width={300} />
          <Grid item display={"flex"} flexDirection={"row"}>
            <SkeletonProduto variant={"rectangular"} />
            <SkeletonProduto variant={"rectangular"} />
          </Grid>
        </Grid>
      </CommonPage>
    );
  }

  return (
    <CommonPage selected={"simulador"} display={atual === 1 ? "block" : "flex"}>
      {atual === 0 && <SelecionarProdutoSectionConnected />}
      {atual === 1 && <DadosClienteSectionConnected />}
      {atual === 2 && <ParametrosSimulacaoSectionConnected />}
      {atual === 3 && <ResultadoSimulacaoSectionConnected />}
      {atual === 4 && <ValidarRegrasSectionConnected />}
    </CommonPage>
  );
};

export default SimuladorInternoPage;
