import PrimeiroAcessoPage from "./PrimeiroAcessoPage";
import { useSelector } from "react-redux";
import { getTrocarSenha } from "../../selectors/autenticacao.selectors";

const PrimeiroAcessoPageConnected = () => {
  const { loading, valid } = useSelector(getTrocarSenha);

  return <PrimeiroAcessoPage valid={valid} loading={loading} />;
};

export default PrimeiroAcessoPageConnected;
