import React from "react";
import {
  ControlledTextField,
  DateTextField,
  DateTimeTextField,
  TimeTextField,
} from "../../components/inputs";
import { Grid } from "@mui/material";
import validators from "../../utils/validators";

export const formValues = {
  rules: (_, tipoEdicao) => ({
    dataAgendamento: validators.date({ required: tipoEdicao === "O" }),
    horaAgendamento: validators.time({ required: tipoEdicao === "O" }),
  }),
  defaultValues: (data = {}) => ({
    dataAgendamento: "",
    horaAgendamento: "",
    ...data,
  }),
};

const DadosAgendamentoForm = ({ formProps, forceDisabled }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <ControlledTextField
          label="Data da Entrevista"
          name={"dataAgendamento"}
          disabled={forceDisabled}
          formProps={formProps}
          InputProps={{
            inputComponent: DateTextField,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <ControlledTextField
          label="Horário"
          name={"horaAgendamento"}
          disabled={forceDisabled}
          formProps={formProps}
          InputProps={{
            inputComponent: TimeTextField,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DadosAgendamentoForm;
