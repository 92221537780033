import React, { useCallback, useEffect } from "react";
import DadosOutrasInformacoesCompradorDefaultForm from "./DadosOutrasInformacoesCompradorDefaultForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import { actions } from "../../../../../actions/operacao.actions";

const DadosOutrasInformacoesCompradorDefaultFormConnected = ({
  handleGoTo0,
  handleGoTo7,
  handleGoTo9,
  events,
  participante: comprador,
  setParticipante,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { banco: bancos },
  } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      if (data.operacao.tipoProduto === "I") {
        if (!!c.dps) {
          handleGoTo9();
        } else {
          handleGoTo7();
        }
      } else {
        if (!!c.dps) {
          handleGoTo9();
        } else {
          handleGoTo0();
        }
      }
    },
    [handleGoTo0, setParticipante, comprador, handleGoTo7, handleGoTo9]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      // bancoConta: validators.object({
      //   required: comprador.principal && operacao.detalhe.correntista,
      // }),
      // agenciaConta: validators.string({
      //   required: comprador.principal && operacao.detalhe.correntista,
      // }),
      // numeroConta: validators.string({
      //   required: comprador.principal && operacao.detalhe.correntista,
      // }),
    }),
    initialValues: useCallback(
      () => ({
        ...(comprador.principal
          ? {
              bancoConta: null,
              agenciaConta: "",
              numeroConta: "",
              autorizaTarifaAvaliacao: false,
              autorizaLimite: false,
              autorizaAtraso: false,
            }
          : {}),
        ...comprador,
      }),
      [operacao, comprador]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "outras-informacoes",
            participante: {
              id: comprador?.id,
              idBancoConta: comprador.principal ? values.bancoConta?.id : null,
              agenciaConta: comprador.principal ? values.agenciaConta : null,
              numeroConta: comprador.principal ? values.numeroConta : null,
              autorizaDebitoTarifaAvaliacao: comprador.principal
                ? values.autorizaTarifaAvaliacao
                : null,
              autorizaUtilizacaoLimiteConta: comprador.principal
                ? values.autorizaLimite
                : null,
              autorizaDebitoParcelasAtrasadas: comprador.principal
                ? values.autorizaAtraso
                : null,
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, comprador, operacao, callback]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      ...(comprador.principal
        ? {
            bancoConta: null,
            agenciaConta: "",
            numeroConta: "",
            autorizaTarifaAvaliacao: false,
            autorizaLimite: false,
            autorizaAtraso: false,
          }
        : {}),
      ...comprador,
    });
  }, [operacao, comprador, reset]);

  return (
    <DadosOutrasInformacoesCompradorDefaultForm
      {...otherProps}
      tipoPessoa={operacao.detalhe.tipoPessoa}
      bancos={bancos}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      comprador={comprador}
      fluxo={comprador.fluxo}
      events={events}
    />
  );
};

export default DadosOutrasInformacoesCompradorDefaultFormConnected;
