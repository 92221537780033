import { CloseSharp, SendOutlined } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  styled,
  Tooltip,
} from "@mui/material";
import { Button, Form, Title } from "../../components";
import { ControlledSwitch, ControlledTextField } from "../../components/inputs";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    width: 100%;
  }
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const EditarComentarioDialog = ({
  open,
  submitting,
  handleClose,
  handleSubmit,
  formProps,
  usuario,
}) => {
  return (
    <StyledDialog
      onClose={() => handleClose()}
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
    >
      {open ? (
        <DialogContent>
          <CloseIconButton onClick={handleClose}>
            <CloseSharp />
          </CloseIconButton>
          <Title as={"h2"} _margin_={"0 0 1rem 0"}>
            Editar Comentário
          </Title>
          <Form onSubmit={handleSubmit}>
            <Grid container alignItems={"flex-start"} spacing={2}>
              <Grid item xs={12}>
                <ControlledTextField
                  name={"comentario"}
                  formProps={formProps}
                  multiline
                  minRows={2}
                  maxRows={5}
                  placeholder="Digite um comentário..."
                />
              </Grid>
              <Grid item xs={12} sm={8} md={10} lg={8} xl={8}>
                {usuario.perfil === "OPERACAO" && (
                  <ControlledSwitch
                    formProps={formProps}
                    name="confidencial"
                    label="Confidencial"
                    labelPlacement="left"
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                lg={4}
                xl={4}
                display={"flex"}
                justifyContent={"end"}
              >
                <Tooltip arrow title={"Gravar comentário"}>
                  <Button
                    loading={submitting}
                    type={"submit"}
                    fullWidth
                    startIcon={<SendOutlined />}
                  >
                    Enviar
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
      ) : null}
    </StyledDialog>
  );
};

export default EditarComentarioDialog;
