import { actions, types } from "../actions/simulador.actions";
import { types as routes } from "../actions/rotas.actions";

const initialState = {
  atual: 0,
  gettingLead: false,
  operacao: null,
  basesDisponiveis: {
    loading: true,
    data: [],
    selecionada: null,
  },
  dadosCliente: {
    searching: false,
    data: null,
    searchingLead: false,
  },
  calculo: {
    id: null,
    entrada: null,
    submitting: false,
    resultado: null,
    downloading: [],
    recalculating: [],
  },
  regrasSelecionadas: {
    validating: false,
    entrada: [],
    resultado: [],
    submitting: false,
  },
  dominios: {
    parceiros: {
      loading: false,
      data: [],
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.buscarSimuladores.REQUEST:
    case actions.buscarSimuladores.SUCCESS:
    case actions.buscarSimuladores.FAILURE:
      return {
        ...state,
        basesDisponiveis: {
          ...state.basesDisponiveis,
          loading: action.type === actions.buscarSimuladores.REQUEST,
          data:
            action.type === actions.buscarSimuladores.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.recuperarCliente.REQUEST:
    case actions.recuperarCliente.SUCCESS:
    case actions.recuperarCliente.FAILURE:
      return {
        ...state,
        dadosCliente: {
          ...state.dadosCliente,
          searching: action.type === actions.recuperarCliente.REQUEST,
        },
      };
    case actions.comecarProposta.REQUEST:
    case actions.comecarProposta.SUCCESS:
    case actions.comecarProposta.FAILURE:
      return {
        ...state,
        regrasSelecionadas: {
          ...state.regrasSelecionadas,
          submitting: action.type === actions.comecarProposta.REQUEST,
        },
      };
    case actions.calcular.REQUEST:
    case actions.calcular.SUCCESS:
    case actions.calcular.FAILURE:
      return {
        ...state,
        calculo: {
          ...state.calculo,
          id:
            action.type === actions.calcular.SUCCESS
              ? action.payload.response.data?.entrada?.id
              : state.calculo.id,
          entrada:
            action.type === actions.calcular.REQUEST
              ? action.payload
              : state.calculo.entrada,
          submitting: action.type === actions.calcular.REQUEST,
          resultado:
            action.type === actions.calcular.SUCCESS
              ? action.payload.response.data
              : null,
        },
        regrasSelecionadas: {
          ...initialState.regrasSelecionadas,
        },
      };
    case actions.recalcular.REQUEST:
    case actions.recalcular.SUCCESS:
    case actions.recalcular.FAILURE:
      return {
        ...state,
        calculo: {
          ...state.calculo,
          recalculating:
            action.type === actions.recalcular.REQUEST
              ? [...state.calculo.recalculating, action.payload.regraComercial]
              : state.calculo.recalculating.filter(
                  (c) => c !== action.payload.original.regraComercial
                ),
          resultado: {
            ...state.calculo.resultado,
            grupos: state.calculo.resultado.grupos.map((g) => {
              const opcoes =
                action.type === actions.recalcular.SUCCESS
                  ? g.opcoes.map((c) =>
                      c.id === action.payload.response.data.id
                        ? { ...action.payload.response.data }
                        : c
                    )
                  : g.opcoes;

              return {
                ...g,
                opcoes,
              };
            }),
          },
        },
      };
    case actions.extratoSimulacao.REQUEST:
    case actions.extratoSimulacao.SUCCESS:
    case actions.extratoSimulacao.FAILURE:
      return {
        ...state,
        calculo: {
          ...state.calculo,
          downloading:
            action.type === actions.extratoSimulacao.REQUEST
              ? [...state.calculo.downloading, action.payload.regraComercial]
              : state.calculo.downloading.filter(
                  (c) => c !== action.payload.original.regraComercial
                ),
        },
      };
    case actions.validarRegrasSelecionadas.REQUEST:
    case actions.validarRegrasSelecionadas.SUCCESS:
    case actions.validarRegrasSelecionadas.FAILURE:
      return {
        ...state,
        regrasSelecionadas: {
          ...state.regrasSelecionadas,
          validating: action.type === actions.validarRegrasSelecionadas.REQUEST,
          entrada:
            action.type === actions.validarRegrasSelecionadas.REQUEST
              ? action.payload.selecionados
              : state.regrasSelecionadas.entrada,
          resultado:
            action.type === actions.validarRegrasSelecionadas.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.atualizarDadosCliente.REQUEST:
    case actions.atualizarDadosCliente.SUCCESS:
    case actions.atualizarDadosCliente.FAILURE:
    case actions.obterParceiros.REQUEST:
    case actions.obterParceiros.SUCCESS:
    case actions.obterParceiros.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          parceiros: {
            ...state.dominios.parceiros,
            loading: action.type === actions.obterParceiros.REQUEST,
            data:
              action.type === actions.obterParceiros.SUCCESS
                ? action.payload.response.data.filter(
                    (p) => ["P", "A"].indexOf(p.situacao) >= 0
                  )
                : [],
          },
        },
      };
    case actions.obterLead.REQUEST:
    case actions.obterLead.SUCCESS:
    case actions.obterLead.FAILURE:
      return {
        ...state,
        gettingLead: action.type === actions.obterLead.REQUEST,
      };
    case actions.recuperarLead.REQUEST:
    case actions.recuperarLead.SUCCESS:
    case actions.recuperarLead.FAILURE:
      return {
        ...state,
        dadosCliente: {
          ...state.dadosCliente,
          data:
            action.type === actions.recuperarLead.REQUEST
              ? action.payload
              : state.dadosCliente.data,
          searching: action.type === actions.recuperarLead.REQUEST,
        },
        calculo: {
          ...state.calculo,
          id:
            action.type === actions.recuperarLead.SUCCESS
              ? action.payload.response.data?.id
              : state.calculo.id,
          entrada:
            action.type === actions.recuperarLead.SUCCESS
              ? action.payload.response.data
              : state.calculo.entrada,
        },
      };
    case types.RESTAURAR_LEAD:
      return {
        ...state,
        atual: action.payload.atual,
        operacao: action.payload.operacao,
        basesDisponiveis: {
          ...state.basesDisponiveis,
          selecionada: action.payload.regraSelecionada,
        },
        dadosCliente: {
          ...state.dadosCliente,
          data: action.payload.dadosCliente,
        },
        calculo: {
          ...state.calculo,
          id: action.payload.lead.id,
          entrada: action.payload.lead,
        },
      };
    case types.SELECIONAR:
      return {
        ...state,
        basesDisponiveis: {
          ...state.basesDisponiveis,
          selecionada: action.payload,
        },
      };
    case types.DADOS_CLIENTE:
      return {
        ...state,
        atual: 2,
        dadosCliente: {
          ...state.dadosCliente,
          data: action.payload,
        },
      };
    case types.NAVEGAR:
      return {
        ...state,
        atual: action.payload,
        calculo: {
          ...state.calculo,
          entrada: action.payload < 1 ? null : state.calculo.entrada,
        },
      };
    case routes.SIMULADOR:
      return {
        ...initialState,
      };
    case routes.SIMULADOR_CONTINUACAO:
      return {
        ...initialState,
        gettingLead: true,
      };
    default:
      return state;
  }
};

export default reducer;
