import React, { useCallback } from "react";
import HubLibra from "./HubLibra";
import { actions } from "../../../../actions/operacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../../actions/rotas.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isAdicionandoParticipantePreenchimento,
  isAtualizandoPreenchimento,
  isEnviandoPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
  isRemovendoParticipantePreenchimento,
} from "../../../../selectors/operacao.selectors";
import { toast } from "react-toastify";
import { getEtapasAbertas } from "../../../../selectors/workflow.selectors";

const HubLibraConnected = ({
  setParticipante,
  setEvents,
  eventsCompradorPf,
  setStep,
  compradores,
}) => {
  const dispatch = useDispatch();
  const { operacao, validacao, dominios } = useSelector(
    getPreenchimentoOperacao
  );
  const refreshing = useSelector(isAtualizandoPreenchimento);
  const adding = useSelector(isAdicionandoParticipantePreenchimento);
  const deleting = useSelector(isRemovendoParticipantePreenchimento);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);
  const sending = useSelector(isEnviandoPreenchimentoOperacao);
  const etapasAbertas = useSelector(getEtapasAbertas);

  const isReenvio = etapasAbertas.find((e) => e.padrao === "RP");

  const imovel = {
    ...operacao.imovel,
    fluxo: validacao.completa.imovel,
  };

  const acessarParticipante = useCallback(
    (p) => {
      setParticipante(p);
      setStep(1);
      setEvents(eventsCompradorPf);
    },
    [setParticipante, setEvents, eventsCompradorPf, setStep]
  );
  const handleAdicionarParticipante = useCallback(
    (tipoParticipante, tipoPessoa) => {
      dispatch(
        actions.adicionarParticipantePreenchimento.request({
          id: operacao.id,
          tipoParticipante,
          tipoPessoa,
        })
      );
    },
    [dispatch, operacao]
  );
  const handleRemoverParticipante = useCallback(
    (e, participante) => {
      e.stopPropagation();
      dispatch(
        actions.removerParticipantePreenchimento.request({
          id: operacao.id,
          participante,
        })
      );
    },
    [dispatch, operacao]
  );

  const handleAcessarParticipante = useCallback(
    (e, c) => {
      e.stopPropagation();
      acessarParticipante(c);
    },
    [acessarParticipante]
  );
  const handleAcessarImovel = useCallback(
    (e) => {
      e.stopPropagation();
      setStep(100);
    },
    [setStep]
  );

  const possuiPendencias =
    validacao.completa.resumo.situacao === "PARCIAL" ||
    validacao.completa.resumo.situacao === "PENDENTE" ||
    imovel.fluxo.filter(
      (f) => f.situacao === "PARCIAL" || f.situacao === "PENDENTE"
    ).length > 0 ||
    compradores.reduce(
      (result, c) =>
        result ||
        c.fluxo.filter(
          (f) => f.situacao === "PARCIAL" || f.situacao === "PENDENTE"
        ).length > 0,
      false
    );

  const handleGoBack = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.DETALHES_OPERACAO, { id: operacao.id })
    );
  }, [dispatch, operacao]);

  const handleGoToSiblings = useCallback(() => {
    toast.success("Proposta enviada para o Crédito.");
    dispatch(
      routeActions.redirectTo(routes.DETALHES_CLIENTE, { id: operacao.cliente })
    );
  }, [dispatch, operacao]);

  const handleEnviarAnaliseCredito = useCallback(() => {
    dispatch(
      actions.enviarPreenchimento.request({
        id: operacao.id,
        callback: !!isReenvio ? handleGoBack : handleGoToSiblings,
      })
    );
  }, [dispatch, operacao, handleGoToSiblings, isReenvio, handleGoBack]);

  const handleReenquadrarOperacao = useCallback((e)=>{
    e.stopPropagation();
    setStep(995)
  },[])

  const handleGoTo0 = useCallback(() => {
    setStep(0);
  }, [setStep]);
  return (
    <HubLibra
      compradores={compradores}
      imovel={imovel}
      handleAcessarImovel={handleAcessarImovel}
      handleAcessarParticipante={handleAcessarParticipante}
      handleAdicionarParticipante={handleAdicionarParticipante}
      handleRemoverParticipante={handleRemoverParticipante}
      desenquadrado={operacao?.detalhe?.desenquadrado}
      handleReenquadrarOperacao={handleReenquadrarOperacao}
      possuiPendencias={possuiPendencias}
      mensagens={validacao["completa"].mensagens}
      operacao={operacao}
      refreshing={refreshing}
      adding={adding}
      deleting={deleting}
      dominios={dominios}
      handleGoBack={handleGoBack}
      submitting={submitting}
      sending={sending}
      handleEnviarAnaliseCredito={handleEnviarAnaliseCredito}
      handleGoTo0={handleGoTo0}
    />
  );
};

export default HubLibraConnected;
