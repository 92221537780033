import React from "react";
import DadosBasicosSantanderFormConnected from "./DadosBasicosSantanderForm.connected";
import EscolhaParticipanteSantanderFormConnected from "./EscolhaParticipanteSantanderForm.connected";
import DadosProfissionaisSantanderFormConnected from "./DadosProfissionaisSantanderForm.connected";
import DadosEstadoCivilSantanderFormConnected from "./DadosEstadoCivilSantanderForm.connected";
import DadosEnderecoSantanderFormConnected from "./DadosEnderecoSantanderForm.connected";
import DadosPessoaisCorrentistaSantanderFormConnected from "./DadosPessoaisCorrentistaSantanderForm.connected";
import DadosProfissionaisCorrentistaSantanderFormConnected from "./DadosProfissionaisCorrentistaSantanderForm.connected";
import ReenquadrarOperacaoFormConnected from "../ReenquadrarOperacaoForm.connected";
import ConfirmarNovoCalculoConnected from "../ConfirmarNovoCalculo.connected";

const FluxoAquisicaoSantander = (props) => {
  return (
    <>
      {props.step === 0 && (
        <EscolhaParticipanteSantanderFormConnected {...props} />
      )}
      {props.step === 1 && props.correntista && (
        <DadosPessoaisCorrentistaSantanderFormConnected {...props} />
      )}
      {props.step === 10 && (
        <DadosProfissionaisCorrentistaSantanderFormConnected {...props} />
      )}
      {props.step === 1 && !props.correntista && (
        <DadosBasicosSantanderFormConnected {...props} />
      )}
      {props.step === 2 && <DadosEnderecoSantanderFormConnected {...props} />}
      {props.step === 3 && (
        <DadosProfissionaisSantanderFormConnected {...props} />
      )}
      {props.step === 4 && (
        <DadosEstadoCivilSantanderFormConnected {...props} />
      )}
      {props.step === 995 && <ReenquadrarOperacaoFormConnected {...props} />}
      {props.step === 996 && <ConfirmarNovoCalculoConnected {...props} />}
    </>
  );
};

export default FluxoAquisicaoSantander;
