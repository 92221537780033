import React, { useCallback, useMemo, useState } from "react";
import ContasLiberacaoVendedorBradescoForm from "./ContasLiberacaoVendedorBradescoForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isEnviandoComplementoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import { useFieldArray } from "react-hook-form";
import { actions } from "../../../../actions/operacao.actions";
import validators from "../../../../utils/validators";
import {
  actions as routeActions,
  types as routes,
} from "../../../../actions/rotas.actions";
import OutrasInformacoesBradescoForm from "./OutrasInformacoesBradescoForm";

const OutrasInformacoesBradescoFormConnected = ({
  handleGoTo0,
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { tipoConta: tiposConta, banco: bancos },
  } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);
  const submittingDocumentos = useSelector(isEnviandoComplementoOperacao);


  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      diaPagamento: validators.number({ required: true }),
    }),
    initialValues: () => ({
      diaPagamento: null,
      aceitaRegistroEletronico: false,
      ...operacao.detalhe
    }),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback: () => handleGoTo0(),
            instituicao: operacao.codigoInstituicao,
            passo: "outras-informacoes-compradores",
            operacao: {
              id: operacao.id,
              detalhes: {
                diaPagamento: values.diaPagamento,
                aceitaRegistroEletronico: values.aceitaRegistroEletronico,
              }
            }
          })
        )
      },
      [dispatch, operacao]
    ),
  });

  const aceitaRegistroEletronico = formProps.watch("aceitaRegistroEletronico");

  return (
    <OutrasInformacoesBradescoForm
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting || submittingDocumentos}
      handleGoBack={handleGoTo0}
      aceitaRegistroEletronico={aceitaRegistroEletronico}
    />
  );
};

export default OutrasInformacoesBradescoFormConnected;
