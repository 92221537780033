export const PERFIL = {
  CADASTRO_MASTER: "Master (Em processo de cadastramento)",
  PARCEIRO_MASTER: "Master",
  LIDER: "Gerente de Filial",
  PROMOTOR: "Promotor de Vendas",
  OPERACIONAL: "Operacional",
  FINANCEIRO: "Financeiro",
  OPERACAO: "Operação",
};

export const TIPO = {
  P: "Parceiro",
  O: "Operação",
};

export const SITUACAO = {
  A: "Ativo",
  B: "Bloqueado por tentativas",
  C: "Cancelado",
  I: "Invalido",
};
