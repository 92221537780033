import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { actions as authActions } from "../actions/autenticacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../actions/rotas.actions";
import { handleApiErrors } from "../utils/api";
import { actions as workflowActions } from "../actions/workflow.actions";
import * as Sentry from "@sentry/react";

export const apiWrapper = (apiPromise) => {
  return apiPromise.then(handleApiErrors).catch((error) => {
    if (
      error.response?.data &&
      error.response?.request.responseType === "arraybuffer" &&
      error.response?.headers["content-type"] === "application/json"
    ) {
      const decoder = new TextDecoder("utf-8");
      error.response.data = JSON.parse(decoder.decode(error.response.data));
    }
    throw error;
  });
};

export const genericErrorHandler = function* ({ error }) {
  if (error.response?.status === 401) {
    toast.info("Sua sessão expirou.\nFaça o login novamente.");
    yield put(authActions.logout());
  } else if (error.response?.status === 403) {
    toast.error("Acesso negado.");
    yield put(routeActions.redirectTo(routes.LOGIN));
  } else if (error.code === "ERR_NETWORK") {
    toast.error("Servidor não está respondendo");
  } else if (error.code === "ERR_TIMEOUT") {
    toast.error("Requisição demorou muito");
  } else {
    if (error.response?.status === 400 && error.response?.data?.code === 999) {
      yield put(
        workflowActions.setDocumentosPendentes(error.response.data.context)
      );
      toast.error("Não foi possível encerrar a etapa");
    } else {
      const message =
        error.response?.data?.message || error.message || error.toString();
      toast.error(message);
    }
  }
};

const basicFlow = ({
  actionGenerator,
  transform = (p) => p,
  api,
  preSuccess = () => {},
  postSuccess = () => {},
  preFailure = () => {},
  postFailure = genericErrorHandler,
}) => {
  return {
    handler: function* (action) {
      let values, response;
      try {
        values = yield transform(action.payload);
        response = yield call(apiWrapper, api(values));
        yield preSuccess({
          original: action.payload,
          values,
          response,
        });
        yield put(
          actionGenerator.success({
            original: action.payload,
            values,
            response,
          })
        );
        yield postSuccess({
          original: action.payload,
          values,
          response,
        });
      } catch (error) {
        const abort = yield preFailure({
          original: action.payload,
          values,
          response,
          error,
        });
        if (abort === true) return;
        Sentry.captureException(error, { extra: { action, values } });
        yield put(
          actionGenerator.failure({
            original: action.payload,
            values,
            response,
            error,
          })
        );
        yield postFailure({
          original: action.payload,
          values,
          response,
          error,
        });
      }
    },
    watcher: function* () {
      yield takeEvery(actionGenerator.REQUEST, this.handler);
    },
  };
};

export default basicFlow;
