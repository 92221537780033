import { types as authTypes } from "../actions/autenticacao.actions";
import { types } from "../actions/tema.actions";

const initialState = {
  urlLogo: "",
  corPrimaria: "",
  corSecundaria: "",
  isMenuOpen:false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGOUT:
    case types.RESET:
      return {
        ...initialState,
      };
    case types.APLICAR:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_MENU:
      return {
        ...state,
        isMenuOpen:action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
