import React from "react";
import { ControlledTextField, IntegerTextField } from "../../components/inputs";
import { Grid } from "@mui/material";
import validators from "../../utils/validators";

export const formValues = {
  rules: (_, tipoEdicao) => ({
    anoFundacao: validators.number({
      required: tipoEdicao === "O",
      custom: {
        anoAntigo: (value) =>
          !value || value >= 1900 || "Ano deve ser pelo menos 1900",
        anoMaximo: (value) =>
          !value ||
          value <= new Date().getFullYear() ||
          "Ano não pode ser futuro",
      },
    }),
    ramoAtuacao: validators.string({ required: tipoEdicao === "O" }),
  }),
  defaultValues: (data = {}) => ({
    anoFundacao: "",
    ramoAtuacao: "",
    ...data,
  }),
};

const DadosEmpresaForm = ({ formProps, forceDisabled }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ControlledTextField
          label="Ano de Fundação"
          name={"anoFundacao"}
          disabled={forceDisabled}
          formProps={formProps}
          InputProps={{
            inputComponent: IntegerTextField,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="Ramo de Atuação"
          name={"ramoAtuacao"}
          disabled={forceDisabled}
          inputProps={{ maxLength: 40 }}
          formProps={formProps}
        />
      </Grid>
    </Grid>
  );
};

export default DadosEmpresaForm;
