import CommonPage from "../CommonPage";

const DashboardPage = (props) => {
  return (
    <CommonPage>
      <div>Teste</div>
      <div>Teste</div>
      <div>Teste</div>
      <div>Teste</div>
      <div>Teste</div>
    </CommonPage>
  );
};

export default DashboardPage;
