import React from "react";
import { Grid } from "@mui/material";
import { transformFromDate } from "../../utils/basic";
import { Field } from "../../components/presentation";
import { Card, Title } from "../../components";

const InformacoesFinanceirasLead = ({ lead }) => {
  return (
    <Card>
      <Title as={"h5"} _margin_={"0 0 1rem 0"}>
        Informações Financeiras
      </Title>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field label={"Produto desejado"} value={lead.produto} />
        </Grid>
        <Grid item xs={6}>
          <Field
            label={lead.tipoProduto === "C" ? "Tipo do Bem" : "Tipo do Imóvel"}
            value={lead.tipoImovel}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            label={
              lead.tipoProduto === "C" ? "Valor do Bem" : "Valor do Imóvel"
            }
            type={"currency"}
            value={lead.valorImovel}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            label={
              lead.tipoProduto === "H"
                ? "Valor do empréstimo"
                : lead.tipoProduto === "C"
                ? "Valor do crédito"
                : "Valor do financiamento"
            }
            type={"currency"}
            value={lead.valorFinanciamento}
          />
        </Grid>
        {!!lead.dataNascimento && (
          <Grid item xs={6}>
            <Field
              label={"Data de nascimento"}
              value={transformFromDate(lead.dataNascimento)}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Field label={"Prazo desejado"} value={lead.prazoDesejado} />
        </Grid>
        {lead.tipoProduto !== "C" && lead.financiaCustas && (
          <Grid item xs={12}>
            <Field
              label={"Deseja financiar as despesas de ITBI e Registro?"}
              type={"boolean"}
              value={lead.financiaCustas}
            />
          </Grid>
        )}
        {lead.tipoProduto !== "C" && lead.financiaIof && (
          <Grid item xs={12}>
            <Field
              label={"Deseja financiar o IOF da operação?"}
              type={"boolean"}
              value={lead.financiaIof}
            />
          </Grid>
        )}
        {lead.tipoProduto !== "C" && lead.financiaTarifaEmissao && (
          <Grid item xs={12}>
            <Field
              label={"Deseja financiar a tarifa de Avaliação?"}
              type={"boolean"}
              value={lead.financiaTarifaEmissao}
            />
          </Grid>
        )}
        {lead.tipoProduto !== "C" && lead.financiaTarifaRegistro && (
          <Grid item xs={12}>
            <Field
              label={"Deseja financiar a Tarifa de Emissão do Contrato?"}
              type={"boolean"}
              value={lead.financiaTarifaRegistro}
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default InformacoesFinanceirasLead;
