import React, { useCallback, useState } from "react";
import DadosEstadoCivilCompradorFintechsForm from "./DadosEstadoCivilCompradorFintechsForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import {
  removeNonDigitsFromString,
  transformToDate,
} from "../../../../../utils/basic";
import { PENSIONISTAS } from "../../../../../constants/generic.constants";

const DadosEstadoCivilCompradorFintechsFormConnected = ({
  setStep,
  eventsCompradorPf,
  setParticipante,
  participante: comprador,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: {
      qualificacao: qualificacoes,
      estadoCivil: estadosCivis,
      perfilRenda: perfisRenda,
      profissao: profissoes,
      ramoAtuacao: ramosAtuacao,
      tipoPensao: tiposPensao,
      segmentoServico: segmentosServico,
      tipoImovelAluguel: tiposImovelAluguel,
      regimeComunhao: regimesComunhao,
    },
  } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const { conjuge, uniaoEstavel } =
    estadosCivis.find((e) => e.id === comprador?.estadoCivil?.id) ?? {};
  const [exibeConjuge, setExibeConjuge] = useState(
    (comprador?.uniaoEstavel || conjuge) ?? false
  );
  const [exibeUniaoEstavel, setExibeUniaoEstavel] = useState(
    uniaoEstavel ?? false
  );

  const _perfilRenda = perfisRenda.find(
    (e) => e.id === comprador?.conjuge?.perfilRenda?.id
  );

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      setStep(0);
    },
    [setStep, setParticipante, comprador]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: (form) => ({
      estadoCivil: validators.object({ required: true }),
      conjuge: {
        nome: validators.string({
          custom: {
            requiredIf: (value) => {
              const uniaoEstavel = form.getValues("uniaoEstavel");

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== "")
                return true;
              return "Campo obrigatório";
            },
            nomeCompleto: (value) =>
              !value ||
              (value.indexOf(" ") > 0 &&
                value.substring(value.indexOf(" ") + 1).length > 0) ||
              "Nome informado deve ser completo",
          },
        }),
        cpfCnpj: validators.cpf({
          custom: {
            requiredIf: (value) => {
              const uniaoEstavel = form.getValues("uniaoEstavel");

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== "")
                return true;
              return "Campo obrigatório";
            },
          },
        }),
        dataNascimento: validators.date({
          custom: {
            requiredIf: (value) => {
              const uniaoEstavel = form.getValues("uniaoEstavel");

              if (!exibeConjuge && !uniaoEstavel) {
                return true;
              }

              if (value !== undefined && value !== null && value !== "")
                return true;
              return "Campo obrigatório";
            },
          },
        }),
      },
    }),
    initialValues: useCallback(
      () => ({
        estadoCivil: null,
        uniaoEstavel: false,
        regimeComunhao: null,
        ...comprador,
        conjuge: {
          nome: "",
          cpfCnpj: "",
          dataNascimento: "",
          email: "",
          telefone: "",
          compoeRenda: false,
          qualificacao: null,
          cargo: "",
          ramoAtuacao: null,
          tipoPensao: null,
          segmentoServico: null,
          tipoImovelAluguel: null,
          dataAdmissao: "",
          faturamentoMensal: "",
          tipoPensionista: "",
          possuiContratoServico: null,
          quantidadeImoveisLocados: "",
          ...comprador?.conjuge,
          perfilRenda: _perfilRenda ?? null,
        },
      }),
      [comprador, _perfilRenda]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-estado-civil",
            participante: {
              id: comprador?.id,
              idEstadoCivil: values.estadoCivil?.id,
              uniaoEstavel: exibeUniaoEstavel && values.uniaoEstavel,
              idRegimeComunhao: !!exibeConjuge
                ? values.regimeComunhao?.id
                : null,
              conjuge: !!exibeConjuge
                ? {
                    nome: values.conjuge.nome,
                    cpfCnpj: removeNonDigitsFromString(values.conjuge.cpfCnpj),
                    dataNascimento: transformToDate(
                      values.conjuge.dataNascimento
                    ),
                    telefone: values.conjuge.telefone,
                    email: values.conjuge.email,
                    renda: values.conjuge.renda,
                    compoeRenda: values.conjuge.compoeRenda,
                    idQualificacao: values.conjuge.qualificacao?.id,
                    idPerfilRenda: values.conjuge.perfilRenda?.id,
                    cargo: values.conjuge.cargo,
                    idRamoAtuacao: values.conjuge.ramoAtuacao?.id,
                    idTipoPensao: values.conjuge.tipoPensao?.id,
                    idSegmentoServico: values.conjuge.segmentoServico?.id,
                    tipoPensionista:
                      PENSIONISTAS[values.conjuge.tipoPensionista],
                    idTipoImovelAluguel: values.conjuge.tipoImovelAluguel?.id,
                    faturamentoMensal: values.conjuge.faturamentoMensal,
                    possuiContratoServico: values.conjuge.possuiContratoServico,
                    quantidadeImoveisLocados:
                      values.conjuge.quantidadeImoveisLocados,
                    dataAdmissao: transformToDate(values.conjuge.dataAdmissao),
                  }
                : null,
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, comprador, callback, operacao, exibeConjuge, exibeUniaoEstavel]
    ),
  });
  const { setValue, watch } = formProps;

  const handleEstadoCivilChange = useCallback(
    (v) => {
      const vaiMudar =
        !exibeConjuge && !exibeUniaoEstavel
          ? true
          : exibeUniaoEstavel ^ (v?.uniaoEstavel ?? false);

      if (vaiMudar) {
        setValue("uniaoEstavel", false);
        setExibeUniaoEstavel(v?.uniaoEstavel ?? false);
        setExibeConjuge(v?.conjuge ?? false);
      }
    },
    [
      setExibeConjuge,
      setExibeUniaoEstavel,
      exibeUniaoEstavel,
      exibeConjuge,
      setValue,
    ]
  );
  const handleUniaoEstavelChange = useCallback(
    (e) => {
      setExibeConjuge(e.target.checked);
    },
    [setExibeConjuge]
  );

  const perfilRenda = watch("conjuge.perfilRenda");
  const tipoPensionista = watch("conjuge.tipoPensionista");
  const compoeRenda = watch("conjuge.compoeRenda");

  const handleChangeTipoPensionista = useCallback(() => {
    setValue("conjuge.tipoPensao", null);
  }, [setValue]);

  return (
    <DadosEstadoCivilCompradorFintechsForm
      {...otherProps}
      estadosCivis={estadosCivis}
      perfilRenda={perfilRenda}
      tipoPensionista={tipoPensionista}
      segmentosServico={segmentosServico}
      qualificacoes={qualificacoes}
      perfisRenda={perfisRenda}
      profissoes={profissoes}
      ramosAtuacao={ramosAtuacao}
      tiposPensao={tiposPensao.filter((t) => t.tipo === tipoPensionista)}
      tiposImovelAluguel={tiposImovelAluguel}
      handleChangeTipoPensionista={handleChangeTipoPensionista}
      events={eventsCompradorPf}
      fluxo={comprador.fluxo}
      formProps={formProps}
      handleEstadoCivilChange={handleEstadoCivilChange}
      compoeRenda={compoeRenda}
      handleSubmit={handleSubmit}
      exibeConjuge={exibeConjuge}
      exibeUniaoEstavel={exibeUniaoEstavel}
      handleUniaoEstavelChange={handleUniaoEstavelChange}
      regimesComunhao={regimesComunhao}
      submitting={submitting}
    />
  );
};

export default DadosEstadoCivilCompradorFintechsFormConnected;
