import React from "react";
import { NumberFormatBase } from "react-number-format";
import { removeNonDigitsFromString } from "../../utils/basic";
import formatters from "../../utils/formatters";

const PhoneTextField = React.forwardRef(({ onChange, ...other }, ref) => {
	return (
		<NumberFormatBase
			{...other}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						value: values.formattedValue,
					},
				});
			}}
			format={formatters.strings.phone}
		/>
	);
});

export default PhoneTextField;
