import DadosLeadTab from "./DadosLeadTab";
import { useSelector } from "react-redux";
import { getDetalhesOperacao } from "../../selectors/operacao.selectors";
import { getDialogosDisponiveis } from "../../selectors/operacao.selectors";

const DadosLeadTabConnected = () => {
  const data = useSelector(getDetalhesOperacao);
  const dialogosDisponiveis = useSelector(getDialogosDisponiveis);

  const { lead } = data;

  return <DadosLeadTab lead={lead} dialogosDisponiveis={dialogosDisponiveis} />;
};

export default DadosLeadTabConnected;
