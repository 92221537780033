import { Grid } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  GoBack,
  MenuItem,
} from "../../../../../components";
import ControlledAutocomplete from "../../../../../components/inputs/ControlledAutocomplete";
import {
  ControlledRadioGroup,
  ControlledSwitch,
  ControlledTextField,
  CpfCnpjTextField,
  CurrencyTextField,
  DateTextField,
  IntegerTextField,
  PhoneTextField,
  Radio,
} from "../../../../../components/inputs";
import Stepper from "../../../preenchimento/Stepper";
import { PENSIONISTAS } from "../../../../../constants/generic.constants";
import UpperCaseTextField from "../../../../../components/inputs/UpperCaseTextField";

const DadosEstadoCivilCompradorFintechsForm = ({
  estadosCivis,
  perfilRenda,
  tipoPensionista,
  segmentosServico,
  perfisRenda,
  profissoes,
  ramosAtuacao,
  tiposPensao,
  qualificacoes,
  regimesComunhao,
  tiposImovelAluguel,
  handleChangeTipoPensionista,
  events,
  compoeRenda,
  fluxo,
  formProps,
  handleEstadoCivilChange,
  handleSubmit,
  exibeConjuge,
  exibeUniaoEstavel,
  handleUniaoEstavelChange,
  submitting,
  makeGoTo,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} direction={"column"} maxWidth={704}>
        <GoBack
          handleClick={makeGoTo(3)}
          text={"Preencha as informações do comprador"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={3} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item width={1}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item container spacing={2}>
                    <Grid item xs={12} md={5}>
                      <ControlledAutocomplete
                        label="Estado Civil"
                        name={"estadoCivil"}
                        domain={estadosCivis}
                        idField={"id"}
                        labelField={"descricao"}
                        formProps={formProps}
                        autocompleteProps={{
                          clearText: "Limpar",
                          onChange: handleEstadoCivilChange,
                          loadingText: "Carregando opções",
                          noOptionsText: "Nenhuma opção disponível",
                        }}
                      />
                    </Grid>
                    {!!exibeUniaoEstavel && (
                      <Grid item xs={12} md={7}>
                        <ControlledSwitch
                          label="Convive em união estável?"
                          name={"uniaoEstavel"}
                          onChange={handleUniaoEstavelChange}
                          formProps={formProps}
                        />
                      </Grid>
                    )}
                    {!!exibeConjuge && (
                      <Grid item xs={12} md={8}>
                        <ControlledAutocomplete
                          label="Regime de Comunhão"
                          name={"regimeComunhao"}
                          domain={regimesComunhao}
                          idField={"id"}
                          labelField={"descricao"}
                          formProps={formProps}
                          autocompleteProps={{
                            clearText: "Limpar",
                            loadingText: "Carregando opções",
                            noOptionsText: "Nenhuma opção disponível",
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {!!exibeConjuge && (
                    <>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label={"CPF do Cônjuge"}
                          name={"conjuge.cpfCnpj"}
                          formProps={formProps}
                          type={"cpf"}
                          InputProps={{
                            inputComponent: CpfCnpjTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <ControlledTextField
                          label="Nome Completo do Cônjuge"
                          name={"conjuge.nome"}
                          inputProps={{ maxLength: 100 }}
                          formProps={formProps}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label={"Data de Nascimento"}
                          name={"conjuge.dataNascimento"}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: DateTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <ControlledTextField
                          label={"E-mail"}
                          name={"conjuge.email"}
                          formProps={formProps}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label="Celular"
                          name="conjuge.telefone"
                          formProps={formProps}
                          InputProps={{
                            inputComponent: PhoneTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ControlledSwitch
                          label="Irá compor renda?"
                          name={"conjuge.compoeRenda"}
                          formProps={formProps}
                        />
                      </Grid>
                      {!compoeRenda && (
                        <Grid item xs={12} md={8}>
                          <ControlledAutocomplete
                            label="Tipo de Proponente"
                            name={"conjuge.qualificacao"}
                            domain={qualificacoes}
                            idField={"id"}
                            labelField={"descricao"}
                            formProps={formProps}
                            autocompleteProps={{
                              clearText: "Limpar",
                              loadingText: "Carregando opções",
                              noOptionsText: "Nenhuma opção disponível",
                            }}
                          />
                        </Grid>
                      )}
                      {!!compoeRenda && (
                        <>
                          <Grid item container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <ControlledAutocomplete
                                label="Perfil de Renda"
                                name={"conjuge.perfilRenda"}
                                domain={perfisRenda}
                                idField={"id"}
                                labelField={"descricao"}
                                formProps={formProps}
                                autocompleteProps={{
                                  clearText: "Limpar",
                                  loadingText: "Carregando opções",
                                  noOptionsText: "Nenhuma opção disponível",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <ControlledTextField
                                label="Renda Mensal"
                                name={"conjuge.renda"}
                                formProps={formProps}
                                InputProps={{
                                  inputComponent: CurrencyTextField,
                                }}
                                inputProps={{
                                  allowNegative: false,
                                }}
                              />
                            </Grid>
                            {perfilRenda?.tipo === "EM" && (
                              <>
                                <Grid item xs={12} md={4}>
                                  <ControlledTextField
                                    label={"Data de Abertura da Empresa"}
                                    name={"conjuge.dataAdmissao"}
                                    formProps={formProps}
                                    InputProps={{
                                      inputComponent: DateTextField,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                  <ControlledAutocomplete
                                    label="Ramo de Atuação"
                                    name={"conjuge.ramoAtuacao"}
                                    domain={ramosAtuacao}
                                    idField={"id"}
                                    labelField={"descricao"}
                                    formProps={formProps}
                                    autocompleteProps={{
                                      clearText: "Limpar",
                                      loadingText: "Carregando opções",
                                      noOptionsText: "Nenhuma opção disponível",
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <ControlledTextField
                                    label="Faturamento Mensal da Empresa"
                                    name={"conjuge.faturamentoMensal"}
                                    formProps={formProps}
                                    InputProps={{
                                      inputComponent: CurrencyTextField,
                                    }}
                                    inputProps={{
                                      allowNegative: false,
                                    }}
                                  />
                                </Grid>
                              </>
                            )}
                            {perfilRenda?.tipo === "AS" && (
                              <>
                                <Grid item xs={12} md={4}>
                                  <ControlledTextField
                                    label={"Data de Início"}
                                    name={"conjuge.dataAdmissao"}
                                    formProps={formProps}
                                    InputProps={{
                                      inputComponent: DateTextField,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                  <ControlledTextField
                                    label="Cargo ou Profissão"
                                    name={"conjuge.cargo"}
                                    inputProps={{ maxLength: 100 }}
                                    formProps={formProps}
                                    InputProps={{
                                      inputComponent: UpperCaseTextField,
                                    }}
                                  />
                                </Grid>
                              </>
                            )}
                            {perfilRenda?.tipo === "PS" && (
                              <>
                                <Grid item xs={12} md={4}>
                                  <ControlledTextField
                                    label={"Data de Início"}
                                    name={"conjuge.dataAdmissao"}
                                    formProps={formProps}
                                    InputProps={{
                                      inputComponent: DateTextField,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                  <ControlledAutocomplete
                                    label="Segmento de Atuação"
                                    name={"conjuge.segmentoServico"}
                                    domain={segmentosServico}
                                    idField={"id"}
                                    labelField={"descricao"}
                                    formProps={formProps}
                                    autocompleteProps={{
                                      clearText: "Limpar",
                                      loadingText: "Carregando opções",
                                      noOptionsText: "Nenhuma opção disponível",
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                  <ControlledRadioGroup
                                    label="Possui contrato de Prestação de Serviço?"
                                    name={"conjuge.possuiContratoServico"}
                                    row
                                    formProps={formProps}
                                  >
                                    <Radio value={true} label={"Sim"} />
                                    <Radio value={false} label={"Não"} />
                                  </ControlledRadioGroup>
                                </Grid>
                              </>
                            )}
                            {perfilRenda?.tipo === "AP" && (
                              <>
                                <Grid item xs={12} md={4}>
                                  <ControlledTextField
                                    label="Tipo"
                                    name={"conjuge.tipoPensionista"}
                                    select
                                    onChange={handleChangeTipoPensionista}
                                    formProps={formProps}
                                  >
                                    {Object.keys(PENSIONISTAS).map((k) => (
                                      <MenuItem key={k} value={k}>
                                        {PENSIONISTAS[k]}
                                      </MenuItem>
                                    ))}
                                  </ControlledTextField>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                  <ControlledAutocomplete
                                    label={
                                      tipoPensionista === "AP"
                                        ? "Aposentado"
                                        : "Pensionista"
                                    }
                                    name={"conjuge.tipoPensao"}
                                    domain={tiposPensao}
                                    idField={"id"}
                                    labelField={"descricao"}
                                    formProps={formProps}
                                    autocompleteProps={{
                                      clearText: "Limpar",
                                      loadingText: "Carregando opções",
                                      noOptionsText: "Nenhuma opção disponível",
                                    }}
                                  />
                                </Grid>
                              </>
                            )}
                            {perfilRenda?.tipo === "PL" && (
                              <>
                                <Grid item xs={12} md={4}>
                                  <ControlledTextField
                                    label={"Data de Início"}
                                    name={"conjuge.dataAdmissao"}
                                    formProps={formProps}
                                    InputProps={{
                                      inputComponent: DateTextField,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                  <ControlledAutocomplete
                                    label="Ramo de Atuação"
                                    name={"conjuge.ramoAtuacao"}
                                    domain={ramosAtuacao}
                                    idField={"id"}
                                    labelField={"descricao"}
                                    formProps={formProps}
                                    autocompleteProps={{
                                      clearText: "Limpar",
                                      loadingText: "Carregando opções",
                                      noOptionsText: "Nenhuma opção disponível",
                                    }}
                                  />
                                </Grid>
                              </>
                            )}
                            {perfilRenda?.tipo === "AL" && (
                              <>
                                <Grid item xs={12} md={6}>
                                  <ControlledAutocomplete
                                    label="Tipo de Imóveis"
                                    name={"conjuge.tipoImovelAluguel"}
                                    domain={tiposImovelAluguel}
                                    idField={"id"}
                                    labelField={"descricao"}
                                    formProps={formProps}
                                    autocompleteProps={{
                                      clearText: "Limpar",
                                      loadingText: "Carregando opções",
                                      noOptionsText: "Nenhuma opção disponível",
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <ControlledTextField
                                    label={"Qtd. de Imóveis Locados"}
                                    name={"conjuge.quantidadeImoveisLocados"}
                                    formProps={formProps}
                                    inputProps={{
                                      maxLength: 3,
                                      allowNegative: false,
                                    }}
                                    InputProps={{
                                      inputComponent: IntegerTextField,
                                    }}
                                  />
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosEstadoCivilCompradorFintechsForm;
