import { Grid } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  GoBack,
  MenuItem,
  Title,
} from "../../../../../components";
import {
  ControlledRadioGroup,
  ControlledTextField,
  CurrencyTextField,
  IntegerTextField,
  Radio,
} from "../../../../../components/inputs";
import Stepper from "../../../preenchimento/Stepper";
import ControlledAutocomplete from "../../../../../components/inputs/ControlledAutocomplete";
import {
  estados,
  TIPO_PROPRIETARIO_IMOVEL,
  TIPO_RESIDENTE_IMOVEL,
} from "../../../../../constants/generic.constants";

const OutrasInformacoesImovelC6Form = ({
  fluxo,
  formProps,
  handleSubmit,
  submitting,
  events,
  subTiposImovel,
  makeGoTo,
}) => {
  const quantidadeVagas = formProps.watch("quantidadeVagas");
  const imovelFinanciado = formProps.watch("imovelFinanciado");
  const imovelProprio = formProps.watch("imovelProprio");

  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={makeGoTo(100)}
          text={"Preencha as informações do imóvel"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={1} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Características do Imóvel</Title>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledAutocomplete
                      label="Tipo do Imóvel"
                      name={"subTipoImovel"}
                      domain={subTiposImovel}
                      idField={"id"}
                      labelField={"descricao"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="Área construída (m2)"
                      name={"areaConstruida"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: CurrencyTextField,
                      }}
                      inputProps={{
                        prefix: "",
                        allowNegative: false,
                      }}
                    />
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <ControlledTextField
                        label="Quantas vagas o imóvel tem?"
                        name={"quantidadeVagas"}
                        formProps={formProps}
                        InputProps={{
                          inputComponent: IntegerTextField,
                        }}
                        inputProps={{
                          maxLength: 3,
                          allowNegative: false,
                        }}
                      />
                    </Grid>
                    {quantidadeVagas > 0 && (
                      <Grid item xs={12} md={8}>
                        <ControlledRadioGroup
                          label="Cada vaga tem um número de matrícula própria?"
                          name={"vagaAutonoma"}
                          row
                          tooltip={
                            "Essa informação pode ser vista na matrícula do imóvel"
                          }
                          formProps={formProps}
                        >
                          <Radio value={true} label={"Sim"} />
                          <Radio value={false} label={"Não"} />
                        </ControlledRadioGroup>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledRadioGroup
                      formProps={formProps}
                      name={"imovelProprio"}
                      label={"É proprietário do imóvel?"}
                      row
                    >
                      <Radio value={"true"} label={"Sim"} />
                      <Radio value={"false"} label={"Não"} />
                    </ControlledRadioGroup>
                  </Grid>
                  {imovelProprio + "" === "false" && (
                    <Grid item xs={12} md={8}>
                      <ControlledRadioGroup
                        formProps={formProps}
                        name={"tipoProprietario"}
                        label={"Quem é proprietário do imóvel?"}
                        row
                      >
                        {Object.keys(TIPO_PROPRIETARIO_IMOVEL).map((item) => (
                          <Radio
                            key={item}
                            value={item}
                            label={TIPO_PROPRIETARIO_IMOVEL[item].label}
                          />
                        ))}
                      </ControlledRadioGroup>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <ControlledRadioGroup
                      formProps={formProps}
                      name={"tipoResidente"}
                      label={"Quem reside no imóvel?"}
                      row
                    >
                      {Object.keys(TIPO_RESIDENTE_IMOVEL).map((item) => (
                        <Radio
                          key={item}
                          value={item}
                          label={TIPO_RESIDENTE_IMOVEL[item].label}
                        />
                      ))}
                    </ControlledRadioGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Matrícula do Imóvel</Title>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="N° da Matrícula"
                      name={"matricula"}
                      inputProps={{ maxLength: 8 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="N° do Cartório de Registro"
                      name={"cartorio.numero"}
                      inputProps={{ maxLength: 2 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={9} md={8}>
                    <ControlledTextField
                      label="Município"
                      name={"cartorio.municipio"}
                      inputProps={{ maxLength: 40 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={3} md={4}>
                    <ControlledTextField
                      label="UF"
                      select
                      name={"cartorio.uf"}
                      inputProps={{ maxLength: 40 }}
                      formProps={formProps}
                    >
                      {estados.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </ControlledTextField>
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledRadioGroup
                      label="Construção está registrada na matrícula?"
                      name={"imovelAverbado"}
                      row
                      formProps={formProps}
                    >
                      <Radio value={true} label={"Sim"} />
                      <Radio value={false} label={"Não"} />
                    </ControlledRadioGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Outras informações</Title>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={12}>
                      <ControlledRadioGroup
                        label="O imóvel está financiado?"
                        name={"imovelFinanciado"}
                        row
                        formProps={formProps}
                      >
                        <Radio value={true} label={"Sim"} />
                        <Radio value={false} label={"Não"} />
                      </ControlledRadioGroup>
                    </Grid>
                    {imovelFinanciado + "" === "true" && (
                      <>
                        <Grid item xs={12} md={6}>
                          <ControlledTextField
                            label="Instituição Bancária"
                            name={"bancoFinanciamento"}
                            formProps={formProps}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ControlledTextField
                            label="Saldo Devedor"
                            name={"saldoDevedorFinanciamento"}
                            formProps={formProps}
                            InputProps={{
                              inputComponent: CurrencyTextField,
                            }}
                            inputProps={{
                              allowNegative: false,
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OutrasInformacoesImovelC6Form;
