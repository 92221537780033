import React from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Skeleton,
  styled,
} from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import { Button, Form, MenuItem, Title } from "../../components";
import {
  ControlledSwitch,
  ControlledTextField,
  CurrencyTextField,
  DateTextField,
  IntegerTextField,
  PercentTextField,
} from "../../components/inputs";
import {
  FORMA_CORRECAO,
  TIPO_AMORTIZACAO,
} from "../../constants/generic.constants";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    width: 100%;
  }
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const SkeletonForm = () => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Skeleton variant={"text"} width={190} height={24} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant={"rounded"} width={"100%"} height={56} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Skeleton variant={"text"} width={160} height={24} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant={"rounded"} width={"100%"} height={56} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Skeleton variant={"text"} width={160} height={24} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant={"rounded"} width={"100%"} height={56} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Skeleton variant={"text"} width={200} height={24} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant={"rounded"} width={"100%"} height={56} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Skeleton variant={"text"} width={140} height={24} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant={"rounded"} width={"100%"} height={56} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item container justifyContent={"center"} mt={1}>
      <Grid item xs={12} md={8}>
        <Button disabled={true} fullWidth>
          ATUALIZAR
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

const FormValidadeCredito = ({
  handleSubmit,
  tipoProduto,
  financiaCustas,
  detalhe,
  formProps,
  submitting,
  dominios,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <ControlledTextField
            label="Data de Validade do Crédito"
            name={"validadeCredito"}
            formProps={formProps}
            InputProps={{
              inputComponent: DateTextField,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ControlledTextField
            name={"valorImovel"}
            label={"Valor do Imóvel"}
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField,
            }}
            inputProps={{
              allowNegative: false,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ControlledTextField
            name={"valorPrimeiraParcela"}
            label={"Valor da 1ª Parcela"}
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField,
            }}
            inputProps={{
              allowNegative: false,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ControlledTextField
            name={"valorFinanciamento"}
            label={
              tipoProduto === "H"
                ? "Valor Total do Empréstimo"
                : "Valor Total do Financiamento"
            }
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField,
            }}
            inputProps={{
              allowNegative: false,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ControlledTextField
            name={"valorBase"}
            label={
              tipoProduto === "H"
                ? "Empréstimo Líquido"
                : "Financiamento Líquido"
            }
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField,
            }}
            inputProps={{
              readOnly: true,
              allowNegative: false,
            }}
          />
        </Grid>
        {tipoProduto === "I" && (
          <Grid item xs={12} sm={6} md={4}>
            <ControlledTextField
              name={"valorEntrada"}
              label={"Valor da Entrada"}
              formProps={formProps}
              InputProps={{
                inputComponent: CurrencyTextField,
              }}
              inputProps={{
                readOnly: true,
                allowNegative: false,
              }}
            />
          </Grid>
        )}
        <Grid item container spacing={1} minHeight={96}>
          <Grid item xs={12} sm={6} md={4}>
            <ControlledSwitch
              name={"financiaCustas"}
              label={"Financia Despesas?"}
              formProps={formProps}
            />
          </Grid>
          {!!financiaCustas && (
            <Grid item xs={12} sm={6} md={4}>
              <ControlledTextField
                name={"valorCustas"}
                label={"Despesas Financiadas"}
                formProps={formProps}
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
                inputProps={{
                  readOnly: !financiaCustas,
                  allowNegative: false,
                }}
              />
            </Grid>
          )}
        </Grid>
        {!!detalhe?.calculaIof &&
          (!!detalhe?.permiteFinanciarIof ? (
            <Grid item container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <ControlledSwitch
                  name={"financiaIof"}
                  label={"Financia IOF?"}
                  formProps={formProps}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ControlledTextField
                  name={"valorIof"}
                  label={"IOF"}
                  formProps={formProps}
                  InputProps={{
                    inputComponent: CurrencyTextField,
                  }}
                  inputProps={{
                    allowNegative: false,
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} md={4}>
              <ControlledTextField
                name={"valorIof"}
                label={"IOF"}
                formProps={formProps}
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
                inputProps={{
                  allowNegative: false,
                }}
              />
            </Grid>
          ))}
        {detalhe?.permiteFinanciarTarifaEmissao ? (
          <Grid item container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <ControlledSwitch
                name={"financiaTarifaEmissao"}
                label={"Financia Tarifa de Avaliação?"}
                formProps={formProps}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ControlledTextField
                name={"valorTarifaEmissao"}
                label={"Tarifa de Avaliação"}
                formProps={formProps}
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
                inputProps={{
                  allowNegative: false,
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} md={4}>
            <ControlledTextField
              name={"valorTarifaEmissao"}
              label={"Tarifa de Avaliação"}
              formProps={formProps}
              InputProps={{
                inputComponent: CurrencyTextField,
              }}
              inputProps={{
                allowNegative: false,
              }}
            />
          </Grid>
        )}
        {detalhe?.permiteFinanciarTarifaRegistro ? (
          <Grid item container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <ControlledSwitch
                name={"financiaTarifaRegistro"}
                label={"Financia Tarifa de Emissão de Contrato?"}
                formProps={formProps}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ControlledTextField
                name={"valorTarifaRegistro"}
                label={"Tarifa de Emissão de Contrato"}
                formProps={formProps}
                InputProps={{
                  inputComponent: CurrencyTextField,
                }}
                inputProps={{
                  allowNegative: false,
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} md={4}>
            <ControlledTextField
              name={"valorTarifaRegistro"}
              label={"Tarifa de Emissão de Contrato"}
              formProps={formProps}
              InputProps={{
                inputComponent: CurrencyTextField,
              }}
              inputProps={{
                allowNegative: false,
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <ControlledTextField
            name={"prazoDesejado"}
            label={
              tipoProduto === "H"
                ? `Prazo de Pagamento`
                : `Prazo de Financiamento`
            }
            formProps={formProps}
            inputProps={{
              maxLength: 3,
              allowNegative: false,
            }}
            InputProps={{
              inputComponent: IntegerTextField,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ControlledTextField
            name={"tipoAmortizacao"}
            label={"Tipo de Amortização"}
            formProps={formProps}
            select
            inputProps={{
              readOnly: dominios?.tipoAmortizacao?.length <= 1,
            }}
          >
            {Object.keys(TIPO_AMORTIZACAO)
              .map((t) => ({ id: t, value: TIPO_AMORTIZACAO[t] }))
              .filter((t) => dominios?.tipoAmortizacao?.indexOf(t.value) >= 0)
              .map((t) => {
                return (
                  <MenuItem key={t.id} value={t.id}>
                    {TIPO_AMORTIZACAO[t.id]}
                  </MenuItem>
                );
              })}
          </ControlledTextField>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ControlledTextField
            name={"tipoRegra"}
            label={"Correção do Contrato"}
            formProps={formProps}
            select
            inputProps={{
              readOnly: dominios?.tipoRegra?.length <= 1,
            }}
          >
            {dominios?.tipoRegra?.map((t) => (
              <MenuItem key={t} value={t}>
                {FORMA_CORRECAO[t]}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ControlledTextField
            name={"taxaJuros"}
            label={"Taxa de Juros Aprovada (a.a.)"}
            formProps={formProps}
            InputProps={{
              inputComponent: PercentTextField,
            }}
            inputProps={{
              allowNegative: false,
            }}
          />
        </Grid>
        <Grid item container justifyContent={"center"} mt={1}>
          <Grid item xs={12} sm={8} md={4}>
            <Button loading={submitting} fullWidth type={"submit"}>
              ATUALIZAR
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

const ValidadeCreditoDialog = ({ handleClose, open, loading, isEdit, ...props }) => {
  return (
    <StyledDialog
      onClose={() => {}}
      disableEscapeKeyDown
      maxWidth="md"
      open={open}
    >
      <DialogContent>
        <CloseIconButton onClick={handleClose}>
          <CloseSharp />
        </CloseIconButton>
        <Title as={"h2"} _margin_={"0 0 1rem 0"}>
          { isEdit ? "Informações da Proposta" : "Validade do Crédito" }
        </Title>
        {!!loading && <SkeletonForm />}
        {!loading && <FormValidadeCredito {...props} />}
      </DialogContent>
    </StyledDialog>
  );
};

export default ValidadeCreditoDialog;
