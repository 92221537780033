import { mock } from "./mock";
import { jwt, refreshToken } from "./localStorage";
import axios from "axios";
import store from "../store";
import { actions as themeActions } from "../actions/tema.actions";

export const destinies = {
  AUTH: "auth",
  CEP: "cep",
  UPLOAD: "upload",
  PARCEIRO: "parceiro",
  WORKFLOW: "workflow",
  SIMULADOR: "simulador",
  CLIENTE: "cliente",
  DOCUMENTO: "documento",
};
const baseAPI = {
  [destinies.AUTH]: process.env.REACT_APP_API_AUTH_BASE_URL,
  [destinies.CEP]: process.env.REACT_APP_API_CEP_BASE_URL,
  [destinies.UPLOAD]: process.env.REACT_APP_API_UPLOAD_BASE_URL,
  [destinies.PARCEIRO]: process.env.REACT_APP_API_PARCEIRO_BASE_URL,
  [destinies.WORKFLOW]: process.env.REACT_APP_API_WORKFLOW_BASE_URL,
  [destinies.SIMULADOR]: process.env.REACT_APP_API_SIMULADOR_BASE_URL,
  [destinies.CLIENTE]: process.env.REACT_APP_API_CLIENTE_BASE_URL,
  [destinies.DOCUMENTO]: process.env.REACT_APP_API_DOCUMENTO_BASE_URL,
  [destinies.UPLOAD]: process.env.REACT_APP_API_UPLOAD_BASE_URL,
};

const instance = axios.create({
  timeout: 600000,
  withCredentials: false,
});

const exchangeAccessToken = async () => {
  const response = await authenticatedRequest({
    isResourceService: false,
    method: "post",
    destiny: destinies.AUTH,
    url: "/refresh",
    body: {
      refreshToken: refreshToken.get(),
    },
  });

  if (response.error) {
    throw response.error;
  }

  await jwt.save(response.data.jwtToken);
  store.dispatch(themeActions.atualizar());
  return response.data.jwtToken;
};
const getAuthorizationHeaders = async (isResourceService) => {
  const accessToken = isResourceService
    ? await exchangeAccessToken()
    : jwt.get();
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};
export const unauthenticatedRequest = async ({
  url,
  method,
  queryParams,
  headers = {},
  body,
  isCep,
  ...other
}) => {
  return await _request({
    url,
    isCep,
    method,
    ...other,
    headers: {
      ...headers,
    },
    queryParams,
    body,
  });
};
export const authenticatedRequest = async ({
  isResourceService,
  url,
  method,
  queryParams,
  body,
  headers,
  base,
  ...other
}) => {
  return await _request({
    url,
    method,
    base,
    ...other,
    headers: {
      ...(await getAuthorizationHeaders(isResourceService)),
      ...headers,
    },
    queryParams,
    body,
  });
};
const _request = async ({
  isMock,
  url,
  destiny,
  method,
  headers = undefined,
  queryParams = {},
  body,
  isCep,
  ...other
}) => {
  if (!url.startsWith("http")) {
    url = baseAPI[destiny] + url;
  }

  Object.keys(queryParams).forEach(
    (key) =>
      queryParams.hasOwnProperty(key) &&
      (queryParams[key] === null || queryParams[key] === undefined) &&
      delete queryParams[key]
  );

  if (isMock) {
    console.log({
      url,
      method,
      queryParams,
      body,
    });
    return mock(other.mockResult, other.mockTimeout);
  } else {
    return instance.request({
      url,
      method,
      headers,
      ...other,
      params: queryParams,
      data: body,
    });
  }
};

export default instance;

export const handleApiErrors = (response) => response;
