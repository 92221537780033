import { Grid } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  GoBack,
  MenuItem,
  Title,
} from "../../../../../components";
import ControlledAutocomplete from "../../../../../components/inputs/ControlledAutocomplete";
import {
  ControlledSwitch,
  ControlledTextField,
  CpfCnpjTextField,
  DateTextField,
} from "../../../../../components/inputs";
import Stepper from "../../../preenchimento/Stepper";
import { estados } from "../../../../../constants/generic.constants";
import UpperCaseTextField from "../../../../../components/inputs/UpperCaseTextField";

const DadosEstadoCivilCompradorBradescoForm = ({
  estadosCivis,
  regimesComunhao,
  generos,
  events,
  fluxo,
  formProps,
  handleSubmit,
  exibeConjuge,
  exibeUniaoEstavel,
  submitting,
  documentosPessoais,
  isUniaoEstavel,
  handleGoTo3,
  isEstrangeiro,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} direction={"column"} maxWidth={704}>
        <GoBack
          handleClick={handleGoTo3}
          text={"Preencha as informações do comprador"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={3} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item width={1}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item container spacing={2}>
                    <Grid item container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <ControlledAutocomplete
                          label="Estado Civil"
                          name={"estadoCivil"}
                          domain={estadosCivis}
                          idField={"id"}
                          labelField={"descricao"}
                          formProps={formProps}
                          autocompleteProps={{
                            clearText: "Limpar",
                            loadingText: "Carregando opções",
                            noOptionsText: "Nenhuma opção disponível",
                          }}
                        />
                      </Grid>
                      {!!exibeUniaoEstavel && (
                        <Grid item xs={12} md={7}>
                          <ControlledSwitch
                            label="Convive em união estável?"
                            name={"uniaoEstavel"}
                            formProps={formProps}
                          />
                        </Grid>
                      )}
                    </Grid>
                    {!!exibeConjuge && (
                      <>
                        <Grid item xs={12} md={4}>
                          <ControlledTextField
                            label={"Data do Casamento"}
                            name={"dataCasamento"}
                            formProps={formProps}
                            InputProps={{
                              inputComponent: DateTextField,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <ControlledAutocomplete
                            label="Regime de Comunhão"
                            name={"regimeComunhao"}
                            domain={regimesComunhao}
                            idField={"id"}
                            labelField={"descricao"}
                            formProps={formProps}
                            autocompleteProps={{
                              clearText: "Limpar",
                              loadingText: "Carregando opções",
                              noOptionsText: "Nenhuma opção disponível",
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {!!exibeConjuge && (
                    <>
                      <Grid item xs={12}>
                        <Title as={"h4"}>Cônjuge</Title>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label={"CPF do Cônjuge"}
                          name={"conjuge.cpfCnpj"}
                          formProps={formProps}
                          type={"cpf"}
                          InputProps={{
                            inputComponent: CpfCnpjTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <ControlledTextField
                          label="Nome Completo do Cônjuge"
                          name={"conjuge.nome"}
                          inputProps={{ maxLength: 100 }}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: UpperCaseTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label={"Data de Nascimento"}
                          name={"conjuge.dataNascimento"}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: DateTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ControlledAutocomplete
                          label="Gênero do Cônjuge"
                          domain={generos}
                          idField={"id"}
                          labelField={"descricao"}
                          name={"conjuge.genero"}
                          formProps={formProps}
                          autocompleteProps={{
                            clearText: "Limpar",
                            loadingText: "Carregando opções",
                            noOptionsText: "Nenhuma opção disponível",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ControlledTextField
                          label="E-mail"
                          name="conjuge.email"
                          formProps={formProps}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <ControlledTextField
                          label="Cargo ou Profissão"
                          name={"conjuge.cargo"}
                          inputProps={{ maxLength: 100 }}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: UpperCaseTextField,
                          }}
                        />
                      </Grid>
                      {!!isUniaoEstavel && (
                        <Grid item xs={12} md={5}>
                          <ControlledAutocomplete
                            label="Estado Civil"
                            name={"conjuge.estadoCivil"}
                            domain={estadosCivis}
                            idField={"id"}
                            labelField={"descricao"}
                            formProps={formProps}
                            autocompleteProps={{
                              clearText: "Limpar",
                              loadingText: "Carregando opções",
                              noOptionsText: "Nenhuma opção disponível",
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Title as={"h5"}>Documento</Title>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ControlledAutocomplete
                          label="Documento"
                          domain={documentosPessoais}
                          idField={"id"}
                          labelField={"descricao"}
                          name={"conjuge.documentoPessoal"}
                          formProps={formProps}
                          autocompleteProps={{
                            clearText: "Limpar",
                            loadingText: "Carregando opções",
                            noOptionsText: "Nenhuma opção disponível",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ControlledTextField
                          label="Número do Documento"
                          name={"conjuge.numeroDocumentoPessoal"}
                          inputProps={{ maxLength: 40 }}
                          formProps={formProps}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label="Órgão Expedidor"
                          name={"conjuge.orgaoDocumentoPessoal"}
                          inputProps={{ maxLength: 40 }}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: UpperCaseTextField,
                          }}
                        />
                      </Grid>
                      {!isEstrangeiro && (
                        <Grid item xs={12} md={4}>
                          <ControlledTextField
                            label="Estado da Expedição"
                            name={"conjuge.ufOrgaoDocumentoPessoal"}
                            select
                            formProps={formProps}
                          >
                            {estados.map((state) => (
                              <MenuItem key={state} value={state}>
                                {state}
                              </MenuItem>
                            ))}
                          </ControlledTextField>
                        </Grid>
                      )}
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          name={"conjuge.dataExpedicaoDocumentoPessoal"}
                          label={"Data de Expedição"}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: DateTextField,
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosEstadoCivilCompradorBradescoForm;
