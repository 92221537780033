import { createSelector } from "reselect";

export const getWorkflow = (state) => state.workflow;
export const getDocumentosPendentes = createSelector(
  getWorkflow,
  (state) => state.documentos.data
);
export const getComentarios = createSelector(
  getWorkflow,
  (state) => state.comentarios
);

export const getDocumentoManual = createSelector(
  getWorkflow,
  (state) => state.documentoManual
);
export const getFluxoTrabalho = createSelector(getWorkflow, (state) => {
  return state.fluxoTrabalho?.data;
});
export const getStatusFluxoTrabalho = createSelector(
  getFluxoTrabalho,
  (state) => {
    return state?.situacao;
  }
);
export const getEtapasAbertas = createSelector(getFluxoTrabalho, (state) => {
  if (!state) {
    return [];
  }
  if (state.situacao !== "A") {
    return [];
  }
  const etapas = state.etapas || [];

  return etapas.filter((e) => e.situacao === "E");
});
export const isAtuandoFluxoTrabalho = createSelector(
  getWorkflow,
  (state) => state.fluxoTrabalho?.acting
);
export const getDialogEditarComentario = createSelector(
  getWorkflow,
  (state) => state.dialogEditarComentario
);
