import React from "react";
import CalculadoraInternaPage from "./CalculadoraInternaPage";
import { useSelector } from "react-redux";
import { getPaginaAtual } from "../../selectors/calculadora.selectors";

const CalculadoraInternaPageConnected = () => {
  const atual = useSelector(getPaginaAtual);

  return <CalculadoraInternaPage atual={atual} />;
};

export default CalculadoraInternaPageConnected;
