import React from "react";
import { NumericFormat } from "react-number-format";

const IntegerTextField = React.forwardRef(
  ({ onChange, asString = false, thousandSeparator = "", ...other }, ref) => {
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          if (!values.value || !!asString) {
            onChange(values.value);
          } else {
            onChange(values.floatValue);
          }
        }}
        thousandSeparator={thousandSeparator}
        decimalSeparator={","}
        decimalScale={0}
      />
    );
  }
);

export default IntegerTextField;
