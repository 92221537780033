import React, { useCallback, useEffect, useMemo } from "react";
import OutrasInformacoesImovelGalleriaForm from "./OutrasInformacoesImovelGalleriaForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import { removeNonDigitsFromString } from "../../../../../utils/basic";

const OutrasInformacoesImovelGalleriaFormConnected = ({
  makeGoTo,
  eventsImovel,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    validacao,
    dominios: { subTipoImovel: subTiposImovel },
  } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const imovel = useMemo(
    () => ({
      ...operacao.imovel,
      eProprietarioDoImovel: operacao.imovel.tipoPessoaProprietario != ""
        ? operacao.imovel?.cpfCnpjProprietario == operacao?.cpfCnpjCliente
          ? "true"
          : "false"
        : "",
      tipoPessoaProprietario: (operacao.imovel.tipoPessoaProprietario != ""
        ? operacao.imovel?.cpfCnpjProprietario == operacao?.cpfCnpjCliente
          ? "true"
          : "false"
        : "") == "true" ? "" : operacao.imovel.tipoPessoaProprietario,
      cpfCnpjProprietario: (operacao.imovel.tipoPessoaProprietario != ""
        ? operacao.imovel?.cpfCnpjProprietario == operacao?.cpfCnpjCliente
          ? "true"
          : "false"
        : "") == "true" ? "" : operacao.imovel.cpfCnpjProprietario,
      fluxo: validacao.completa.imovel,
    }),
    [operacao, validacao]
  );

  const _subTipoImovel = subTiposImovel.find(
    (e) => e.id === imovel?.subTipoImovel?.id
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      quantidadeVagas: validators.number({ required: true }),
      valorImovel: validators.number({ required: true }),
      subTipoImovel: validators.object({ required: true }),
      matricula: validators.string({ required: true }),
      numCartorioRegistro: validators.string({ required: true }),
      estadoCartorio: validators.string({ required: true }),
      municipioCartorio: validators.string({ required: true }),
      possuiAPropriedade: validators.string({ required: true }),
      tipoPessoaProprietario: validators.string({ required: true }),
      cpfCnpjProprietario: validators.cpfCnpj({ required: true }),
      eProprietarioDoImovel: validators.string({ required: true }),
      cartorio: {
        numero: validators.string({ required: true }),
        municipio: validators.string({ required: true }),
        uf: validators.string({ required: true })
      }
    }),
    initialValues: useCallback(
      () => ({
        quantidadeVagas: "",
        valorImovel: "",
        vagaAutonoma: "",
        imovelAlugado: "",
        valorAluguel: "",
        imovelFinanciado: "",
        bancoFinanciamento: "",
        saldoDevedorFinanciamento: "",
        possuiDebitos: "",
        valorDebitosIptu: "",
        valorDebitosCondominio: "",
        proprietarios: [],
        ...imovel,
        subTipoImovel: _subTipoImovel,
      }),
      [imovel, _subTipoImovel]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback: makeGoTo(0),
            instituicao: operacao.codigoInstituicao,
            passo: "outras-informacoes-imovel",
            operacao: {
              id: operacao.id,
              imovel: {
                quantidadeVagas: values.quantidadeVagas,
                possuiDepositoVagaAutonoma: values.vagaAutonoma,
                valorImovel: values.valorImovel,
                imovelAlugado: values.imovelAlugado,
                valorAluguel: values.valorAluguel,
                imovelFinanciado: values.imovelFinanciado,
                bancoFinanciamento: values.bancoFinanciamento,
                saldoDevedorFinanciamento: values.saldoDevedorFinanciamento,
                possuiDebitos: values.possuiDebitos,
                valorDebitosIptu: values.valorDebitosIptu,
                valorDebitosCondominio: values.valorDebitosCondominio,
                idSubTipoImovel: values.subTipoImovel?.id,
                tipoPessoaProprietario:
                  values.eProprietarioDoImovel == "false"
                    ? values.tipoPessoaProprietario == "F"
                      ? "FISICA"
                      : "JURIDICA"
                    : operacao.participantes[0].tipoPessoa == "F"
                      ? "FISICA"
                      : "JURIDICA",
                cpfCnpjProprietario: values.eProprietarioDoImovel == "false" ? removeNonDigitsFromString(values.cpfCnpjProprietario) : operacao.cpfCnpjCliente,
                matricula: values.matricula,
                cartorio: values.cartorio,
              },
            },
          })
        );
      },
      [dispatch, operacao, makeGoTo]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      quantidadeVagas: "",
      vagaAutonoma: "",
      valorImovel: "",
      imovelAlugado: "",
      valorAluguel: "",
      imovelFinanciado: "",
      bancoFinanciamento: "",
      saldoDevedorFinanciamento: "",
      possuiDebitos: "",
      valorDebitosIptu: "",
      valorDebitosCondominio: "",
      proprietarios: [],
      ...imovel,
      subTipoImovel: _subTipoImovel,
    });
  }, [imovel, reset, _subTipoImovel]);

  return (
    <OutrasInformacoesImovelGalleriaForm
      {...otherProps}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={imovel.fluxo}
      makeGoTo={makeGoTo}
      events={eventsImovel}
      subTiposImovel={subTiposImovel}
    />
  );
};

export default OutrasInformacoesImovelGalleriaFormConnected;
