import React, { useCallback } from "react";
import HubFintechs from "./HubFintechs";
import { actions } from "../../../../actions/operacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../../actions/rotas.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isAdicionandoParticipantePreenchimento,
  isAtualizandoPreenchimento,
  isGravandoPreenchimentoOperacao,
  isRemovendoParticipantePreenchimento,
} from "../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import validators from "../../../../utils/validators";

const HubFintechsConnected = ({
  setParticipante,
  setEvents,
  eventsCompradorPf,
  eventsCompradorPj,
  eventsOutrosPf,
  eventsOutrosPj,
  eventsSociosPf,
  eventsSociosPj,
  setStep,
  makeGoTo,
  compradores,
  outrosProponentes,
}) => {
  const dispatch = useDispatch();
  const { operacao, validacao, dominios } = useSelector(
    getPreenchimentoOperacao
  );
  const refreshing = useSelector(isAtualizandoPreenchimento);
  const adding = useSelector(isAdicionandoParticipantePreenchimento);
  const deleting = useSelector(isRemovendoParticipantePreenchimento);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const imovel = {
    ...operacao.imovel,
    fluxo: validacao.completa.imovel,
  };

  const acessarParticipante = useCallback(
    (p) => {
      setParticipante(p);
      if (p.tipoParticipante === "CO") {
        if (!p.participanteOrigem) {
          setEvents(
            p.tipoPessoa === "F" ? eventsCompradorPf : eventsCompradorPj
          );
          setStep(1);
        } else {
          setEvents(p.tipoPessoa === "F" ? eventsSociosPf : eventsSociosPj);
          setStep(60);
        }
      } else if (p.tipoParticipante === "OU" && !p.participanteOrigem) {
        setEvents(p.tipoPessoa === "F" ? eventsOutrosPf : eventsOutrosPj);
        setStep(50);
      } else if (p.tipoParticipante === "OU" && !!p.participanteOrigem) {
        setEvents(p.tipoPessoa === "F" ? eventsSociosPf : eventsSociosPj);
        setStep(60);
      }
    },
    [
      setParticipante,
      setStep,
      setEvents,
      eventsCompradorPf,
      eventsCompradorPj,
      eventsOutrosPf,
      eventsOutrosPj,
      eventsSociosPf,
      eventsSociosPj,
    ]
  );
  const handleAdicionarParticipante = useCallback(
    (tipoParticipante, tipoPessoa) => {
      dispatch(
        actions.adicionarParticipantePreenchimento.request({
          id: operacao.id,
          tipoParticipante,
          tipoPessoa,
        })
      );
    },
    [dispatch, operacao]
  );
  const handleRemoverParticipante = useCallback(
    (e, participante) => {
      e.stopPropagation();
      dispatch(
        actions.removerParticipantePreenchimento.request({
          id: operacao.id,
          participante,
        })
      );
    },
    [dispatch, operacao]
  );

  const handleAcessarParticipante = useCallback(
    (e, c) => {
      e.stopPropagation();
      acessarParticipante(c);
    },
    [acessarParticipante]
  );
  const handleAcessarImovel = useCallback(
    (e) => {
      e.stopPropagation();
      setStep(100);
    },
    [setStep]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      finalidades: validators.array({ required: true }),
    }),
    initialValues: () => ({
      finalidade: operacao.finalidades ?? [],
    }),
    handleSubmit: (values) => {
      dispatch(
        actions.gravarPreenchimento.request({
          callback: makeGoTo(999),
          instituicao: operacao.codigoInstituicao,
          passo: "finalidade",
          operacao: {
            id: operacao.id,
            finalidades: values.finalidade.map((f) => ({
              idFinalidadeCredito: f.id,
            })),
          },
        })
      );
    },
  });

  const possuiPendencias =
    validacao.completa.resumo.situacao === "PARCIAL" ||
    validacao.completa.resumo.situacao === "PENDENTE" ||
    imovel.fluxo.filter(
      (f) => f.situacao === "PARCIAL" || f.situacao === "PENDENTE"
    ).length > 0 ||
    compradores.reduce(
      (result, c) =>
        result ||
        c.fluxo.filter(
          (f) => f.situacao === "PARCIAL" || f.situacao === "PENDENTE"
        ).length > 0,
      false
    );

  const handleGoBack = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.DETALHES_OPERACAO, { id: operacao.id })
    );
  }, [dispatch, operacao]);

  return (
    <HubFintechs
      compradores={compradores}
      outrosProponentes={outrosProponentes}
      imovel={imovel}
      handleAcessarImovel={handleAcessarImovel}
      handleAcessarParticipante={handleAcessarParticipante}
      possuiPendencias={possuiPendencias}
      operacao={operacao}
      handleAdicionarParticipante={handleAdicionarParticipante}
      handleRemoverParticipante={handleRemoverParticipante}
      refreshing={refreshing}
      adding={adding}
      formProps={formProps}
      handleSubmit={handleSubmit}
      deleting={deleting}
      dominios={dominios}
      handleGoBack={handleGoBack}
      submitting={submitting}
    />
  );
};

export default HubFintechsConnected;
