import React from "react";
import { NumericFormat } from "react-number-format";

const CurrencyTextField = React.forwardRef(
  ({ onChange, prefix = "R$ ", ...other }, ref) => {
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          if (values.value === "") {
            onChange(values.value);
          } else {
            onChange(values.floatValue);
          }
        }}
        thousandSeparator={"."}
        decimalSeparator={","}
        decimalScale={2}
        fixedDecimalScale={true}
        prefix={prefix}
      />
    );
  }
);

export default CurrencyTextField;
