import React, { useCallback, useMemo } from "react";
import DadosSociosPjCompradorFintechsForm from "./DadosSociosPjCompradorFintechsForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isAdicionandoParticipantePreenchimento,
  isAtualizandoPreenchimento,
  isGravandoPreenchimentoOperacao,
  isRemovendoParticipantePreenchimento,
} from "../../../../../selectors/operacao.selectors";
import { actions } from "../../../../../actions/operacao.actions";

const DadosSociosPjCompradorFintechsFormConnected = ({
  setStep,
  setEvents,
  eventsCompradorPj,
  eventsSociosPf,
  eventsSociosPj,
  setParticipante,
  participante,
  compradores,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { operacao } = useSelector(getPreenchimentoOperacao);
  const refreshing = useSelector(isAtualizandoPreenchimento);
  const adding = useSelector(isAdicionandoParticipantePreenchimento);
  const deleting = useSelector(isRemovendoParticipantePreenchimento);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const socios = useMemo(
    () => compradores.filter((p) => p.participanteOrigem === participante.id),
    [compradores, participante]
  );

  const handleAdicionarSocio = useCallback(
    (tipoPessoa) => {
      dispatch(
        actions.adicionarParticipantePreenchimento.request({
          id: operacao.id,
          idParticipanteOrigem: participante.id,
          tipoParticipante: "RL",
          tipoPessoa,
        })
      );
    },
    [dispatch, operacao, participante]
  );
  const handleRemoverSocio = useCallback(
    (e, participante) => {
      e.stopPropagation();
      dispatch(
        actions.removerParticipantePreenchimento.request({
          id: operacao.id,
          participante,
        })
      );
    },
    [dispatch, operacao]
  );

  const acessarParticipante = useCallback(
    (p) => {
      setParticipante(p);
      if (p.tipoPessoa === "F") {
        setEvents(eventsSociosPf);
        setStep(60);
      } else if (p.tipoPessoa === "J") {
        setEvents(eventsSociosPj);
        setStep(60);
      }
    },
    [setParticipante, setStep, setEvents, eventsSociosPf, eventsSociosPj]
  );

  const handleAcessarSocio = useCallback(
    (e, c) => {
      e.stopPropagation();
      acessarParticipante(c);
    },
    [acessarParticipante]
  );

  return (
    <DadosSociosPjCompradorFintechsForm
      {...otherProps}
      submitting={submitting}
      fluxo={participante.fluxo}
      handleAdicionarSocio={handleAdicionarSocio}
      handleRemoverSocio={handleRemoverSocio}
      handleAcessarSocio={handleAcessarSocio}
      events={eventsCompradorPj}
      refreshing={refreshing}
      deleting={deleting}
      adding={adding}
      socios={socios}
    />
  );
};

export default DadosSociosPjCompradorFintechsFormConnected;
