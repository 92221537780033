import basicFlow, { genericErrorHandler } from "./asyncHandler";
import { call, put, select } from "redux-saga/effects";
import { actions, types } from "../actions/autenticacao.actions";
import { actions as themeActions } from "../actions/tema.actions";
import {
  actions as routeActions,
  types as routes,
} from "../actions/rotas.actions";
import {
  authenticatedRequest,
  destinies,
  unauthenticatedRequest,
} from "../utils/api";
import { jwt, refreshToken } from "../utils/localStorage";
import { toast } from "react-toastify";
import { routeWatcher } from "./rotas.saga";
import {
  getCodigoOperacaoExterno,
  getTokenValidacao,
} from "../selectors/autenticacao.selectors";
import jwtDecode from "jwt-decode";
import { getRouteQueryParams } from "../selectors/routes.selectors";
import { useSelector } from "react-redux";
import { getUsuarioLogado } from "../selectors/usuario.selectors";

const login = basicFlow({
  actionGenerator: actions.login,
  transform: ({ ref, ...values }) => {
    return values;
  },
  api: (values) => {
    return unauthenticatedRequest({
      url: "/login",
      destiny: destinies.AUTH,
      method: "post",
      body: values,
    });
  },
  postSuccess: function* ({ response }) {
    if (!!response.data.jwtToken) {
      jwt.save(response.data.jwtToken);
      yield put(themeActions.atualizar());
      refreshToken.save(response.data.refreshToken);

      const params = yield select(getRouteQueryParams);

      if (!!params?.redirect_url) {
        window.location.href = atob(params.redirect_url);
      } else {
        yield put(getPageAfterAuth(response.data));
      }
    }
  },
  postFailure: function* ({ original, error }) {
    if (original.ref) {
      original.ref.reset();
    }
    yield genericErrorHandler({ error });
  },
});

const obterOperacoesDoUsuario = basicFlow({
  actionGenerator: actions.obterOperacoesDoUsuario,
  api: (values) => {
    return authenticatedRequest({
      url: "/operacao-externa/" + values,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
  postSuccess: function* ({ response }) {
    yield put(
      routeActions.redirectTo(routes.DETALHES_OPERACAO, {
        id: response.data.id,
      })
    );
  },
  postFailure: function* ({ original, error }) {
    if (original.ref) {
      original.ref.reset();
    }
    yield genericErrorHandler({ error });
  },
});

const loginExterno = basicFlow({
  actionGenerator: actions.loginExterno,
  transform: ({ ref, ...values }) => {
    return {
      ...values,
      cpfCnpj: values.cpfCnpj.replaceAll(".", "").replaceAll("-", ""),
      dataNascimento: values.dataNascimento.split("/").reverse().join("-"),
    };
  },
  api: (values) => {
    return unauthenticatedRequest({
      url: "/login",
      destiny: destinies.AUTH,
      method: "post",
      body: {
        codigo: values.codigoOperacaoExterno,
        cpfCnpj: values.cpfCnpj,
        dataNascimento: values.dataNascimento,
      },
    });
  },
  postSuccess: function* ({ response, original }) {
    if (!!response.data.jwtToken) {
      jwt.save(response.data.jwtToken);
      refreshToken.save(response.data.refreshToken);
      yield put(
        actions.obterOperacoesDoUsuario.request(original.codigoOperacaoExterno)
      );
    }
  },
  postFailure: function* ({ original, error }) {
    if (original.ref) {
      original.ref.reset();
    }
    yield genericErrorHandler({ error });
  },
});

const esqueciSenha = basicFlow({
  actionGenerator: actions.esqueciSenha,
  transform: ({ ref, ...values }) => {
    return values;
  },
  api: (values) => {
    return unauthenticatedRequest({
      url: "/esqueci-minha-senha",
      destiny: destinies.AUTH,
      method: "post",
      body: values,
    });
  },
  postSuccess: ({ original }) => {
    if (original.ref) {
      original.ref.reset();
    }
    toast.info(
      "Enviamos um e-mail para você com as instruções para a troca de senha."
    );
  },
  postFailure: function* ({ original, error }) {
    if (original.ref) {
      original.ref.reset();
    }
    yield genericErrorHandler({ error });
  },
});

const validarTokenSenha = basicFlow({
  actionGenerator: actions.validarTokenSenha,
  transform: function* () {
    const token = yield select(getTokenValidacao);
    return { token };
  },
  api: ({ token }) => {
    return unauthenticatedRequest({
      url: `/token/${token}`,
      destiny: destinies.AUTH,
      method: "get",
    });
  },
  postFailure: () => {},
});

function* trocarSenhaRouteWatcher() {
  yield routeWatcher(
    [routes.TROCAR_SENHA, routes.PRIMEIRO_ACESSO_PARCEIRO],
    function* () {
      yield put(actions.validarTokenSenha.request());
    }
  );
}

const trocarSenha = basicFlow({
  actionGenerator: actions.trocarSenha,
  transform: function* (payload) {
    const token = yield select(getTokenValidacao);
    return { token, senha: payload.senha };
  },
  api: (values) => {
    return unauthenticatedRequest({
      url: `/trocar-senha`,
      destiny: destinies.AUTH,
      method: "post",
      body: values,
    });
  },
  postSuccess: function* ({ response, original }) {
    if (!!response.data.jwtToken) {
      jwt.save(response.data.jwtToken);
      yield put(themeActions.atualizar());
      refreshToken.save(response.data.refreshToken);
      yield put(getPageAfterAuth(response.data));
    }
  },
});

function* logoutWatcher() {
  yield routeWatcher(types.LOGOUT, function* () {
    const codigo = yield select(getCodigoOperacaoExterno);
    jwt.clear();
    yield put(themeActions.atualizar());
    refreshToken.clear();
    if (codigo) {
      yield put(routeActions.redirectTo(routes.LOGIN_EXTERNO, { id: codigo }));
    } else {
      yield put(routeActions.redirectTo(routes.LOGIN));
    }
  });
}

const getPageAfterAuth = ({ jwtToken }) => {
  const usuario = jwtDecode(jwtToken);

  switch (usuario.perfil) {
    case "OPERACAO":
      return routeActions.redirectTo(routes.CLIENTES);
    case "CADASTRO_MASTER":
      return routeActions.redirectTo(routes.DETALHES_PARCEIRO, {
        id: usuario.idParceiro,
      });
    case "PARCEIRO_MASTER":
    case "LIDER":
    case "FINANCEIRO":
    case "OPERACIONAL":
    case "PROMOTOR":
      return routeActions.redirectTo(routes.CLIENTES);
    default:
      return routeActions.redirectTo(routes.DASHBOARD);
  }
};

export const sagas = [
  login.watcher(),
  loginExterno.watcher(),
  obterOperacoesDoUsuario.watcher(),
  esqueciSenha.watcher(),
  validarTokenSenha.watcher(),
  trocarSenha.watcher(),
  trocarSenhaRouteWatcher(),
  logoutWatcher(),
];
