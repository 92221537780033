import React from "react";
import { styled } from "@mui/material";
import { transientOptions } from "../../styles/theme";
import formatters from "../../utils/formatters";

const FieldContainer = styled("div", transientOptions)`
  display: flex;
  flex-direction: column;
  visibility: ${({ _hidden_ }) => (_hidden_ ? "hidden" : "unset")};
`;
const FieldLabel = styled("span", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.grey._200};
`;
const FieldValue = styled("span", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 800;
  color: ${({ theme }) => theme.colors.grey._700};
  white-space: pre-wrap;
`;

const getContent = (type, value, suffix, domain, filter, map, decode) => {
  if (
    value === null ||
    value === undefined ||
    value === "" ||
    value?.length === 0
  ) {
    return "-";
  }

  switch (type) {
    case "boolean":
      return !!value ? "Sim" : "Não";
    case "cpf":
      return formatters.strings.cpf(value);
    case "cnpj":
      return formatters.strings.cnpj(value);
    case "cpfCnpj":
      return formatters.strings.cpfCnpj(value);
    case "zipcode":
      return formatters.strings.zipCode(value);
    case "currency":
      return `R$ ${formatters.numbers.currency(value)}`;
    case "percent":
      return `${formatters.numbers.currency(value)} %` + suffix;
    case "date":
      return formatters.dates.short(value);
    case "timestamp":
      return formatters.dates.simple(value, "dd/MM/yyyy HH:mm:ss");
    case "decode":
      return decode(value);
    case "lookup":
      return map(domain.find((x) => filter(x, value))) ?? value;
    default:
      return value + suffix;
  }
};

const Field = ({
  force = false,
  label,
  type,
  suffix = "",
  value,
  addon,
  decode = (x) => x,
  domain: { data = [], filter = () => false, map = (x) => x } = {},
  ...props
}) => {
  if (
    !force &&
    (value === null ||
      value === undefined ||
      value === "" ||
      value?.length === 0)
  ) {
    return null;
  }
  return (
    <FieldContainer {...props}>
      <FieldLabel>{label}</FieldLabel>
      <FieldValue>
        {getContent(type, value, suffix, data, filter, map, decode)}
        {addon}
      </FieldValue>
    </FieldContainer>
  );
};

export default Field;
