import { createTheme } from "@mui/material";

export const defaultOptions = {
  colors: {
    header: "#093048",
    headerText: "#ffffff",
    background: "#f1f3f5",
    fixed: {
      white: "#FFFFFF",
      offWhite: "#f1f3f5",
      black: "#000000",
    },
    primary: {
      _50: "#FAFBFF",
      _75: "#F4FAFE",
      _100: "#EDF7FD",
      _200: "#B7DEF6",
      _300: "#81C4EF",
      _400: "#4AABE8",
      _500: "#1B8BD1",
      _600: "#156CA2",
      _700: "#0E486C",
      _800: "#093048",
      _900: "#051824",
    },
    secondary: {
      _50: "#E6F3FF",
      _75: "#DBF1FF",
      _100: "#D6EFFF",
      _200: "#99D8FF",
      _300: "#59BFFF",
      _400: "#33B1FF",
      _500: "#0AA1FF",
      _600: "#007ECC",
      _700: "#00588F",
      _800: "#003F66",
      _900: "#001929",
    },
    success: {
      _50: "#eef8f1",
      _75: "#E7F8EA",
      _100: "#DAF8E3",
      _200: "#B4F8C8",
      _300: "#7CF39E",
      _400: "#44EE74",
      _500: "#15E04E",
      _600: "#10A83B",
      _700: "#0A7027",
      _800: "#074B1A",
      _900: "#03250D",
    },
    info: {
      _50: "#FAFBFF",
      _75: "#F4FAFE",
      _100: "#EDF7FD",
      _200: "#B7DEF6",
      _300: "#81C4EF",
      _400: "#4AABE8",
      _500: "#1B8BD1",
      _600: "#156CA2",
      _700: "#0E486C",
      _800: "#093048",
      _900: "#051824",
    },
    grey: {
      _0: "#FFFFFF",
      _50: "#F6F8F8",
      _75: "#F1F2F2",
      _100: "#E9EAEC",
      _150: "#CDCED0",
      _200: "#A7ABB4",
      _300: "#676D79",
      _400: "#4B4F58",
      _500: "#383B42",
      _600: "#2F3137",
      _700: "#26272C",
      _800: "#1C1E21",
      _900: "#131416",
      _1000: "#000000",
    },
    error: {
      _50: "#fdf1f2",
      _75: "#FDEFF1",
      _100: "#FDEDF0",
      _200: "#F8C9D1",
      _300: "#F4A4B3",
      _400: "#ED6E85",
      _500: "#E63757",
      _600: "#C81939",
      _700: "#911229",
      _800: "#5B0B1A",
      _900: "#36070F",
    },
    warning: {
      _50: "#FEFCEB",
      _75: "#FDF9D8",
      _100: "#FDF9D8",
      _200: "#FBF2B1",
      _300: "#F8E977",
      _400: "#F6E351",
      _500: "#F4DC2A",
      _600: "#C2A90A",
      _700: "#746606",
      _800: "#4E4404",
      _900: "#272202",
    },
    white: {
      _50: "#ffffff",
      _75: "#ffffff",
      _100: "#ffffff",
      _200: "#ffffff",
      _300: "#ffffff",
      _400: "#ffffff",
      _500: "#ffffff",
      _600: "#ffffff",
      _700: "#ffffff",
      _800: "#ffffff",
      _900: "#ffffff",
    },
  },
  _shadows: {
    buttons: {
      // primary: "4px 8px 24px 0 rgba(254, 187, 27, 0.25)",
      // secondary: "4px 8px 24px 0 rgba(37, 228, 225, 0.25)",
      grey: "4px 8px 24px 0 rgba(4, 6, 15, 0.25)",
      none: "none",
    },
    cards: {
      primary: "0px 4px 60px 0 rgba(254, 187, 27, 0.08)",
      secondary: "0px 4px 60px 0 rgba(37, 228, 225, 0.08)",
      grey: "0px 4px 60px 0 rgba(4, 6, 15, 0.08)",
      none: "none",
    },
  },
  fonts: {
    family: {
      default: "Nunito Sans",
      title: "Nunito Sans",
      buttons: "Nunito Sans",
    },
    size: {
      xlarge: "2rem",
      large: "1.3rem",
      default: "1rem",
      small: "0.8rem",
      tiny: "0.6rem",
      tables: {
        header: "0.75rem",
        content: "0.8rem",
      },
      inputs: {
        normal: {
          label: "0.9rem",
          content: "1rem",
        },
        small: {
          label: "0.7rem",
          content: "0.8rem",
        },
      },
      parceiro: {
        title: "1.5rem",
        subtitle: "1.0rem",
        situacao: "0.7rem",
        etapa: {
          title: "0.9rem",
          subtitle: "0.8rem",
          actionTitle: "0.8rem",
          action: "1rem",
        },
      },
      operacao: {
        title: "1.5rem",
        subtitle: "1.0rem",
        situacao: "0.7rem",
        etapa: {
          title: "0.9rem",
          subtitle: "0.8rem",
          actionTitle: "0.8rem",
          action: "1rem",
        },
      },
      cliente: {
        filtro: {
          texto: "0.6rem",
          quantidade: "1.2rem",
          valor: "0.7rem",
        },
        card: {
          parceiro: "0.7rem",
          nomeCliente: "1rem",
          cpfCnpj: "0.7rem",
          operacao: {
            tipo: "0.6rem",
            valor: "0.8rem",
            endereco: "0.7rem",
          },
        },
        detalhe: {
          lead: {
            campo: "0.8rem",
            valor: "0.9rem",
            icone: "1.1rem",
          },
        },
      },
    },
  },
  borderRadiuses: {
    cards: "1rem",
    imageHolders: "1.5rem",
    buttons: "200px",
    inputs: "0.75rem",
    menu: "1.5rem",
    disclaimer: "0.5rem",
  },
  sizes: {
    cards: {
      sm: "360px",
      md: "640px",
      lg: "800px",
      xl: "1024px",
    },
    header: {
      desktop: 0,
      mobile: 0,
    },
    lateralMenu: "201px",
    lateralMenuCollapsed: "55px",
  },
  spacings: {
    base: "1rem",
    form: "0.75rem",
    submitButton: "0.5rem",
    cards: "1.5rem",
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
    },
  },
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  }
};

const theme = createTheme({ ...defaultOptions });

export const transientOptions = {
  shouldForwardProp: (propName) =>
    ["as"].indexOf(propName) === -1 &&
    !propName.startsWith("_") &&
    !propName.endsWith("_"),
};

export default theme;
