import ConfirmarNovoCalculo from "./ConfirmarNovoCalculo";
import {useDispatch, useSelector} from "react-redux";
import {
  getNovoCalculo,
  getPreenchimentoOperacao,
  isAtualizandoPreenchimento
} from "../../../selectors/operacao.selectors";
import {useCallback, useState} from "react";
import {actions} from "../../../actions/operacao.actions";
import formatters from "../../../utils/formatters";

const ConfirmarNovoCalculoConnected = ({
  props,
  handleGoTo0,
  handleGoTo995
}) =>{
  const dispatch = useDispatch();

  const {data, submitting, loading:recalculating, downloading} = useSelector(getNovoCalculo);
  const {
    operacao
  } = useSelector(getPreenchimentoOperacao);
  const refreshing = useSelector(isAtualizandoPreenchimento)

  const callback = useCallback(
    () => {
      handleGoTo0();
    },
    [handleGoTo0]
  );

  const handleRecalculate = useCallback(
    (regra, opcao) => {
      dispatch(
        actions.recalcularSimulacao.request({
          entrada:regra.entrada,
          regraComercial: regra.id,
          tipoAmortizacao: regra.entrada.tipoAmortizacao,
          seguradora: regra.entrada.seguradora,
          oferta: regra.entrada.oferta,
          carencia: regra.entrada.carencia,
          ...opcao,
        })
      );
    },
    [dispatch]
  );

  const handleDownload = useCallback(
    (regra) => {
      dispatch(
        actions.extratoNovaSimulacao.request({
          entrada:regra.entrada,
          regraComercial: regra.id,
          tipoAmortizacao: regra.entrada.tipoAmortizacao,
          seguradora: regra.entrada.seguradora,
          oferta: regra.entrada.oferta,
          carencia: regra.entrada.carencia,
        })
      );
    },
    [dispatch]
  );

  const [dadosWhatsApp, setDadosWhatsApp] = useState({
    open: false,
  });
  const handleCloseWhatsApp = useCallback(() => {
    setDadosWhatsApp({
      open: false,
    });
  }, []);
  const handleOpenWhatsApp = useCallback((values) => {
    setDadosWhatsApp({
      open: true,
      ...values,
    });
  }, []);
  const handleWhatsApp = useCallback(
    (regra) => {
      const participantePrincipal = operacao.participantes.find(p=>!!p.principal);
      const mensagem = `Valor do crédito R$ ${formatters.numbers.currency(
        regra.entrada.valorTotal
      )}, primeira parcela de R$ ${formatters.numbers.currency(
        regra.primeiraParcela
      )} com uma taxa de juros de ${formatters.numbers.currency(
        regra.entrada.taxaJurosAnual
      )}% a.a.`;

      handleOpenWhatsApp({
        mensagem,
        telefone: participantePrincipal.telefone,
      });
    },
    [handleOpenWhatsApp, operacao]
  );

  const handleConfirmarSimulacao = useCallback(
    ()=>{
      dispatch(
        actions.aceitarNovosValoresOperacao.request({
          ...data.entrada,
          primeiraParcela:data.primeiraParcela,
          id:operacao.id,
          callback
        })
      );
    },
    [dispatch, data, callback]
  );

  return (
    <ConfirmarNovoCalculo
      data={data}
      submitting={submitting}
      refreshing={refreshing}
      handleGoTo995={handleGoTo995}
      tipoProduto={operacao.tipoProduto}
      detalhes={operacao.detalhe}
      handleRecalculate={handleRecalculate}
      handleDownload={handleDownload}
      recalculating={recalculating}
      downloading={downloading}
      handleWhatsApp={handleWhatsApp}
      handleCloseWhatsApp={handleCloseWhatsApp}
      handleConfirmarSimulacao={handleConfirmarSimulacao}
      dadosWhatsApp={dadosWhatsApp}
    />
  )
}

export default  ConfirmarNovoCalculoConnected;
