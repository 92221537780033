import React, { useCallback, useEffect } from "react";
import OutrasInformacoesBradescoForm from "./OutrasInformacoesBradescoForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import validators, { requiredValidation } from "../../../../utils/validators";
import { actions } from "../../../../actions/operacao.actions";
import { actions as routeActions } from "../../../../actions/rotas.actions";
import { removeNonDigitsFromString } from "../../../../utils/basic";

const OutrasInformacoesBradescoFormConnected = ({
  handleGoTo0,
  eventsPrincipal,
  eventsNaoPrincipal,
  setComprador,
  comprador,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { operacao } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const { agenciaConveniada } = comprador;

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setComprador({
          ...c,
          fluxo: data.validacao[
            data.operacao.detalhe.correntista ? "correntista" : "completa"
          ]?.participantes?.find((f) => f.id === c?.id)?.etapas,
        });
      }
      handleGoTo0();
    },
    [handleGoTo0, setComprador, comprador]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: (form) => ({
      agenciaConta: validators.string({
        custom: {
          required: (value) => {
            const isCorrentista = form.getValues("correntista");

            if (isCorrentista === "true") {
              return requiredValidation(value);
            }

            if (!agenciaConveniada) {
              return requiredValidation(value);
            }
            return true;
          },
        },
      }),
      numeroConta: validators.object({
        custom: {
          required: (value) => {
            const isCorrentista = form.getValues("correntista");

            if (isCorrentista === "true") {
              return requiredValidation(value);
            }

            return true;
          },
        },
      }),
      cepAgencia: validators.string({
        custom: {
          required: (value) => {
            const isCorrentista = form.getValues("correntista");

            if (isCorrentista !== "true") {
              if (!agenciaConveniada) {
                return requiredValidation(value);
              }
            }

            return true;
          },
        },
      }),
    }),
    initialValues: useCallback(
      () => ({
        correntista: operacao.detalhe.correntista + "",
        agenciaConta: "",
        numeroConta: "",
        cepAgencia: "",
        ...comprador,
      }),
      [comprador, operacao]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "outras-informacoes",
            participante: {
              id: comprador?.id,
              idAgenciaConveniada: agenciaConveniada?.id,
              agenciaConta:
                values.correntista === "false" && !!agenciaConveniada
                  ? agenciaConveniada.codigo
                  : values.agenciaConta,
              numeroConta:
                values.correntista === "true" ? values.numeroConta : null,
              cepAgencia:
                values.correntista === "false"
                  ? !!agenciaConveniada
                    ? agenciaConveniada.cepPesquisa
                    : removeNonDigitsFromString(values.cepAgencia)
                  : null,
            },
            operacao: {
              id: operacao.id,
              detalhes: {
                correntista: values.correntista,
              },
            },
          })
        );
      },
      [dispatch, comprador, operacao, callback]
    ),
  });

  const handleOpenLink = useCallback(() => {
    dispatch(
      routeActions.openNewWindowWith(
        "https://banco.bradesco/html/classic/atendimento/rede-de-atendimento/index.shtm"
      )
    );
  }, [dispatch]);

  const { reset, watch } = formProps;

  const isCorrentista = watch("correntista");

  useEffect(() => {
    reset({
      correntista: operacao.detalhe.correntista + "",
      agenciaConta: "",
      numeroConta: "",
      cepAgencia: "",
      ...comprador,
    });
  }, [comprador, operacao, reset]);

  return (
    <OutrasInformacoesBradescoForm
      {...otherProps}
      formProps={formProps}
      agenciaConveniada={comprador.agenciaConveniada}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={comprador.fluxo}
      events={eventsPrincipal}
      handleOpenLink={handleOpenLink}
      isCorrentista={isCorrentista === "true"}
    />
  );
};

export default OutrasInformacoesBradescoFormConnected;
