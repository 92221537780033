import CommonPage from "../CommonPage";
import { Card, Link } from "../../components";
import { Grid, Skeleton, styled, Tab, Tabs } from "@mui/material";
import React from "react";
import { transientOptions } from "../../styles/theme";
import formatters from "../../utils/formatters";
import PropostasTabConnected from "./PropostasTab.connected";
import { EmailRounded, WhatsApp } from "@mui/icons-material";

const SkeletonCard = styled(Skeleton)`
  width: 100%;
  height: 239px;
  border-radius: 1rem;
`;
const ImageContainer = styled("div")`
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadiuses.imageHolders};
  background-color: ${({ theme }) => theme.colors.primary._100};
  font-size: 68px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary._700};
`;
const NomeCliente = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.parceiro.title};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.grey._700};
`;
const CpfCnpj = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.parceiro.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grey._200};
`;
const NomeParceiro = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.parceiro.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 700;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.secondary._600};
`;
const TabContainer = styled(Tabs)`
  display: flex;
  margin: 1.5rem 0 -1.5rem;
  border-top: solid 2px ${({ theme }) => theme.colors.grey._50};
  font-size: ${({ theme }) => theme.fonts.size.operacao.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};

  & .MuiTab-root {
    letter-spacing: 0;
  }

  & .MuiTabs-indicator {
    height: 3px;
    background-color: ${({ theme }) => theme.colors.primary._400};
  }

  & .MuiTabs-scrollButtons.Mui-disabled {
    width: 0;
  }
`;
const StyledTab = styled(Tab)`
  font-size: ${({ theme }) => theme.fonts.size.parceiro.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 500;
  text-transform: none;
  padding: 0.75rem 0;
  margin-right: 1.5rem;
  overflow: visible;
  min-width: 0;
  color: ${({ theme }) => theme.colors.grey._300};

  &.Mui-selected {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary._400};
  }
`;
const StyledIcon = styled(WhatsApp)`
  margin-right: 0.25rem;
  height: 24px;
  width: 24px;
`;
const LinkTelefone = styled(Link)`
  font-size: ${({ theme }) => theme.fonts.size.operacao.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey._200};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary._300};
    font-weight: 600;
    text-decoration: underline;
  }
`;
const Email = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.operacao.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey._200};
`;

const DetalhesClientePage = ({
  loading,
  data,
  tab,
  perfil,
  handleTabChange,
  handlePhoneClick,
}) => {
  const isLoading = !!loading || !data;

  return (
    <CommonPage selected={"clientes"}>
      {isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <Grid display={"flex"} gap={"1.5rem"}>
                <ImageContainer>
                  {data.nome.slice(0, 2).toUpperCase()}
                </ImageContainer>
                <Grid display={"flex"} flexDirection={"column"}>
                  <NomeCliente>{data.nome}</NomeCliente>
                  <CpfCnpj>{formatters.strings.cpfCnpj(data.cpfCnpj)}</CpfCnpj>
                  <Grid container columnSpacing={2}>
                    <Grid item display={"flex"} alignItems={"center"}>
                      <LinkTelefone
                        onClick={() => handlePhoneClick(data.telefone)}
                      >
                        <StyledIcon as={WhatsApp} /> {data.telefone}
                      </LinkTelefone>
                    </Grid>
                    <Grid item display={"flex"} alignItems={"center"}>
                      <Email>
                        <StyledIcon as={EmailRounded} />
                        {data.email}
                      </Email>
                    </Grid>
                  </Grid>
                  <NomeParceiro>{data.parceiro}</NomeParceiro>
                  {perfil === "OPERACAO" && (
                    <Grid container columnSpacing={2}>
                      <Grid item display={"flex"} alignItems={"center"}>
                        <LinkTelefone
                          onClick={() =>
                            handlePhoneClick(data.telefoneParceiro, "")
                          }
                        >
                          <StyledIcon as={WhatsApp} /> {data.telefoneParceiro}
                        </LinkTelefone>
                      </Grid>
                      <Grid item display={"flex"} alignItems={"center"}>
                        <Email>{data.nomeContatoParceiro}</Email>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <TabContainer
                value={tab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <StyledTab value={"0"} label={"Propostas"} />
                <StyledTab value={"1"} label={"Dados Pessoais"} />
                <StyledTab value={"2"} label={"Documentos"} />
              </TabContainer>
            </Card>
          </Grid>
          {tab === "0" && <PropostasTabConnected />}
        </Grid>
      )}
    </CommonPage>
  );
};

export default DetalhesClientePage;
