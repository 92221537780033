import { Grid } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  GoBack,
  MenuItem,
  Title,
} from "../../../../../components";
import ControlledAutocomplete from "../../../../../components/inputs/ControlledAutocomplete";
import {
  ControlledTextField,
  ZipCodeTextField,
} from "../../../../../components/inputs";
import { estados } from "../../../../../constants/generic.constants";
import Stepper from "../../../preenchimento/Stepper";

const DadosEnderecoVendedorPfBradescoForm = ({
  fluxo,
  tiposResidencia,
  formProps,
  handleSubmit,
  handleZipCodeBlurred,
  submitting,
  events,
  handleGoTo10,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={handleGoTo10}
          text={"Preencha as informações do vendedor"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={1} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Endereço Residencial</Title>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label="CEP"
                      name={"endereco.cep"}
                      formProps={formProps}
                      onBlur={handleZipCodeBlurred}
                      InputProps={{
                        inputComponent: ZipCodeTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ControlledTextField
                      label="Endereço"
                      name={"endereco.logradouro"}
                      inputProps={{ maxLength: 40 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label="Número"
                      name={"endereco.numero"}
                      inputProps={{ maxLength: 40 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label="Complemento"
                      name={"endereco.complemento"}
                      inputProps={{ maxLength: 40 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label="Bairro"
                      name={"endereco.bairro"}
                      inputProps={{ maxLength: 40 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={9} md={6}>
                    <ControlledTextField
                      label="Município"
                      name={"endereco.municipio"}
                      inputProps={{ maxLength: 40 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <ControlledTextField
                      label="UF"
                      name={"endereco.uf"}
                      select
                      formProps={formProps}
                    >
                      {estados.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </ControlledTextField>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledAutocomplete
                      label="Tipo de Residência"
                      domain={tiposResidencia}
                      idField={"id"}
                      labelField={"descricao"}
                      name={"endereco.tipoResidencia"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosEnderecoVendedorPfBradescoForm;
