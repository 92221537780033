import { useMediaQuery, useTheme } from "@mui/material";

export default function useResponsive() {
  const theme = useTheme();

  const results = [
    {
      size: "xl",
      matches: useMediaQuery(theme.breakpoints.up("xl")),
    },
    {
      size: "lg",
      matches: useMediaQuery(theme.breakpoints.up("lg")),
    },
    {
      size: "md",
      matches: useMediaQuery(theme.breakpoints.up("md")),
    },
    {
      size: "sm",
      matches: useMediaQuery(theme.breakpoints.up("sm")),
    },
  ];

  for (const item of results) {
    if (item.matches) {
      return item.size;
    }
  }

  return "xs";
}
