import { createSelector } from "reselect";

export const getCalculadora = (state) => state.calculadora;

export const getParceiroExterno = createSelector(
  getCalculadora,
  (state) => state.parceiro
);
export const getPaginaAtual = createSelector(
  getCalculadora,
  (state) => state.atual
);
export const getCalculadorasDisponiveis = createSelector(
  getCalculadora,
  (state) => state.disponiveis
);
export const getCalculadoraSelecionada = createSelector(
  getCalculadora,
  (state) => state.disponiveis.selecionada
);
export const getResultadoCalculo = createSelector(
  getCalculadora,
  (state) => state.calculo
);
export const getDominioParceiros = createSelector(
  getCalculadora,
  (state) => state.dominios.parceiros
);
export const getLead = createSelector(getCalculadora, (state) => state.lead);

