import { IconButton, styled } from "@mui/material";

const TableIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.secondary._800};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary._50};
  }
`;

export default TableIconButton;
