import React from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Skeleton,
  styled,
} from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import { Button, Form, MenuItem, Title } from "../../components";
import { ControlledTextField } from "../../components/inputs";
import ControlledAutocomplete from "../../components/inputs/ControlledAutocomplete";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    width: 100%;
  }
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const SkeletonForm = () => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Skeleton variant={"text"} width={180} height={24} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant={"rounded"} width={"100%"} height={56} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Skeleton variant={"text"} width={90} height={24} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant={"rounded"} width={"100%"} height={56} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Skeleton variant={"text"} width={90} height={24} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant={"rounded"} width={"100%"} height={106} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item container justifyContent={"center"} mt={1}>
      <Grid item xs={12} md={8}>
        <Button disabled={true} fullWidth>
          ADICIONAR DOCUMENTO
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

const FormAdicionarDocumento = ({
  handleSubmit,
  documentos,
  referencias,
  formProps,
  handleReferenciaChange,
  submitting,
}) => {

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ControlledTextField
            label="Pra quem é o documento?"
            name={"referencia"}
            select
            onChange={handleReferenciaChange}
            formProps={formProps}
          >
            {referencias.map((referencia) => (
              <MenuItem key={referencia.id} value={referencia.id}>
                {referencia.nome}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item xs={12}>
          <ControlledAutocomplete
            label="Documento"
            domain={documentos}
            idField={"id"}
            labelField={"nome"}
            name={"documento"}
            formProps={formProps}
            autocompleteProps={{
              clearText: "Limpar",
              loadingText: "Carregando opções",
              noOptionsText: "Nenhuma opção disponível",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={"Comentário"}
            name={"comentario"}
            formProps={formProps}
            multiline
            minRows={3}
            maxRows={5}
          />
        </Grid>
        <Grid item container justifyContent={"center"}>
          <Grid item xs={12} md={8}>
            <Button loading={submitting} fullWidth type={"submit"}>
              ADICIONAR DOCUMENTO
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

const AdicionarDocumentoManualDialog = ({
  handleClose,
  open,
  loading,
  ...props
}) => {
  return (
    <StyledDialog
      onClose={() => {}}
      disableEscapeKeyDown
      maxWidth="sm"
      open={open}
    >
      <DialogContent>
        <CloseIconButton onClick={handleClose}>
          <CloseSharp />
        </CloseIconButton>
        <Title as={"h2"} _margin_={"0 0 1rem 0"}>
          Adicionar Documento Manual
        </Title>
        {!!loading && <SkeletonForm />}
        {!loading && <FormAdicionarDocumento {...props} />}
      </DialogContent>
    </StyledDialog>
  );
};

export default AdicionarDocumentoManualDialog;
