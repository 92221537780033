import Header from "./Header";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";
import { actions as authActions } from "../../actions/autenticacao.actions";
import { actions as temaActions } from "../../actions/tema.actions";
import { getUsuarioLogado } from "../../selectors/usuario.selectors";
import { getMenuDisposition, getTema } from "../../selectors/tema.selectors";
import { getCurrentRoute } from "../../selectors/routes.selectors";
import {
  Home,
  FormatListBulleted,
  Paid,
  Calculate,
  Business,
  AutoGraph,
  SupervisorAccount,
  LocalLibrary,
  School,
} from "@mui/icons-material";
import { Tooltip } from "../../components";

const HeaderConnected = ({ selected }) => {
  const dispatch = useDispatch();
  const usuario = useSelector(getUsuarioLogado);
  const { urlLogo } = useSelector(getTema);
  const currentRoute = useSelector(getCurrentRoute);
  const isMenuOpen = useSelector(getMenuDisposition);

  const handleLogout = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  const handleClickUsuario = useCallback(() => {
    if (usuario.perfil !== "EXTERNO") {
      dispatch(
        routeActions.redirectTo(routes.DETALHES_USUARIO, { id: usuario.id })
      );
    }
  }, [dispatch, usuario]);

  const handleClick = useCallback(
    (route, params = {}) => {
      dispatch(routeActions.redirectTo(route, params));
    },
    [dispatch, usuario]
  );

  const handleDrawerToggle = () => {
    dispatch(temaActions.atualizarMenu(!isMenuOpen));
  };

  const items = [
    {
      id: "clientes",
      label: "Início",
      profile: [
        "OPERACAO",
        "PARCEIRO_MASTER",
        "CADASTRO_MASTER",
        "PROMOTOR",
        "OPERACIONAL",
        "FINANCEIRO",
        "LIDER",
      ],
      icon: (
        <Tooltip title={"Início"} arrow placement="right">
          <Home />
        </Tooltip>
      ),
      clickEvent: () => handleClick(routes.CLIENTES),
    },
    {
      id: "operacoes",
      label: "Operações",
      profile: [
        "OPERACAO",
        "LIDER",
        "PROMOTOR",
        "PARCEIRO_MASTER",
        "OPERACIONAL",
      ],
      icon: (
        <Tooltip title={"Operações"} arrow placement="right">
          <FormatListBulleted />
        </Tooltip>
      ),
      clickEvent: () => handleClick(routes.OPERACOES),
    },
    {
      id: "simulador",
      label: "Simulador",
      profile: [
        "PARCEIRO_MASTER",
        "OPERACAO",
        "PROMOTOR",
        "OPERACIONAL",
        "LIDER",
      ],
      icon: (
        <Tooltip title={"Simulador"} arrow placement="right">
          <Paid />
        </Tooltip>
      ),
      clickEvent: () => handleClick(routes.SIMULADOR),
    },
    {
      id: "calculadora",
      label: "Calculadora",
      profile: [
        "PARCEIRO_MASTER",
        "CADASTRO_MASTER",
        "OPERACAO",
        "PROMOTOR",
        "OPERACIONAL",
        "FINANCEIRO",
        "LIDER",
      ],
      icon: (
        <Tooltip title={"Calculadora"} arrow placement="right">
          <Calculate />
        </Tooltip>
      ),
      clickEvent: () => handleClick(routes.CALCULADORA_INTERNA),
    },
    {
      id: "parceiro",
      label: "Parceiros",
      profile: ["OPERACAO"],
      icon: (
        <Tooltip title={"Parceiros"} arrow placement="right">
          <Business />
        </Tooltip>
      ),
      clickEvent: () => handleClick(routes.PARCEIROS),
    },
    {
      id: "parceiro",
      label: "Minha empresa",
      profile: ["CADASTRO_MASTER", "PARCEIRO_MASTER"],
      icon: (
        <Tooltip title={"Minha empresa"} arrow placement="right">
          <Business />
        </Tooltip>
      ),
      clickEvent: () =>
        handleClick(routes.DETALHES_PARCEIRO, { id: usuario?.idParceiro }),
    },
    {
      id: "comissoes",
      label: "Controle Financeiro",
      icon: (
        <Tooltip title={"Controle Financeiro"} arrow placement="right">
          <AutoGraph />
        </Tooltip>
      ),
      profile: ["OPERACAO", "PARCEIRO_MASTER", "FINANCEIRO"],
      clickEvent: () => handleClick(routes.CONTROLE_FINANCEIRO),
    },
    {
      id: "usuarios",
      label: "Usuários",
      icon: (
        <Tooltip title={"Usuários"} arrow placement="right">
          <SupervisorAccount />
        </Tooltip>
      ),
      profile: ["OPERACAO", "PARCEIRO_MASTER"],
      clickEvent: () => handleClick(routes.USUARIOS),
    },
    {
      id: "ajuda",
      section: "AJUDA",
      profile: [
        "OPERACAO",
        "PARCEIRO_MASTER",
        "CADASTRO_MASTER",
        "PROMOTOR",
        "OPERACIONAL",
        "FINANCEIRO",
        "LIDER",
      ],
    },
    {
      id: "materiais-apoio",
      label: "Materiais de Apoio",
      icon: <LocalLibrary />,
      clickEvent: () => handleClick(routes.MATERIAL_APOIO),
      profile: [
        "OPERACAO",
        "PARCEIRO_MASTER",
        "CADASTRO_MASTER",
        "PROMOTOR",
        "OPERACIONAL",
        "FINANCEIRO",
        "LIDER",
      ],
    },
    {
      id: "academy",
      label: "Taya Academy",
      icon: <School />,
      clickEvent: () => handleClick(routes.ACADEMY),
      profile: [
        "OPERACAO",
        "PARCEIRO_MASTER",
        "CADASTRO_MASTER",
        "PROMOTOR",
        "OPERACIONAL",
        "FINANCEIRO",
        "LIDER",
      ],
    },
  ];

  const showDrawer = () => {
    if (currentRoute === routes.CALCULADORA_EXTERNA) {
      return false;
    }
    return usuario?.id;
  };

  const getNome = () => {
    if (currentRoute !== routes.CALCULADORA_EXTERNA) {
      return usuario?.nome;
    } else {
      return null;
    }
  };

  const restrictedItems = restrictMenus(items, [], usuario?.perfil);

  return (
    <Header
      isMenuOpen={isMenuOpen}
      handleDrawerToggle={handleDrawerToggle}
      urlLogo={urlLogo}
      items={restrictedItems}
      selected={selected}
      logged={!!usuario}
      showDrawer={showDrawer(usuario)}
      nome={getNome()}
      usuario={usuario}
      handleLogout={handleLogout}
      handleClickUsuario={handleClickUsuario}
    />
  );
};

const restrictMenus = (menus, current, profile) => {
  menus.forEach((menu) => {
    if (!menu.profile) {
      if (!menu.menus) {
        current.push(menu);
      }
    } else {
      const canAccess = menu.profile.indexOf(profile) > -1;
      if (canAccess) {
        if (!menu.menus) {
          current.push(menu);
        }
      }
    }
  });
  return current;
};

export default HeaderConnected;
