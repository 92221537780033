import React, { useCallback, useEffect, useMemo } from "react";
import DadosEnderecoImovelFintechsForm from "./DadosEnderecoImovelGalleriaForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import { removeNonDigitsFromString } from "../../../../../utils/basic";
import { actions as parceiroActions } from "../../../../../actions/parceiro.actions";

const DadosEnderecoImovelGalleriaFormConnected = ({
  makeGoTo,
  eventsImovel,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { operacao, validacao } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const imovel = useMemo(
    () => ({
      ...operacao.imovel,
      fluxo: validacao.completa.imovel,
    }),
    [operacao, validacao]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      endereco: {
        cep: validators.string({ required: true }),
        logradouro: validators.string({ required: true, maxLength: 40 }),
        numero: validators.string({ required: true, maxLength: 40 }),
        bairro: validators.string({ required: true, maxLength: 40 }),
        municipio: validators.string({ required: true, maxLength: 60 }),
        uf: validators.string({ required: true }),
      }
    }),
    initialValues: useCallback(
      () => ({
        ...imovel,
        endereco: {
          cep: "",
          logradouro: "",
          numero: "",
          complemento: "",
          bairro: "",
          municipio: "",
          uf: "",
          ...imovel.endereco,
        },
      }),
      [imovel]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback: makeGoTo(101),
            instituicao: operacao.codigoInstituicao,
            passo: "dados-endereco-imovel",
            operacao: {
              id: operacao.id,
              imovel: {
                endereco: {
                  cep: removeNonDigitsFromString(values.endereco?.cep),
                  logradouro: values.endereco.logradouro,
                  numeroLogradouro: values.endereco.numero,
                  complementoLogradouro: values.endereco.complemento,
                  bairro: values.endereco.bairro,
                  municipio: values.endereco.municipio,
                  uf: values.endereco.uf,
                },
              },
            },
          })
        );
      },
      [dispatch, operacao, makeGoTo]
    ),
  });
  const { setValue } = formProps;

  const successZipCodeCallback = useCallback(
    ({ data }) => {
      setValue("endereco.logradouro", data?.logradouro || "", {
        shouldValidate: true,
      });
      setValue("endereco.bairro", data?.bairro || "", { shouldValidate: true });
      setValue("endereco.uf", data?.uf || "", { shouldValidate: true });
      setValue("endereco.municipio", data?.localidade || "", {
        shouldValidate: true,
      });
    },
    [setValue]
  );
  const handleZipCodeBlurred = useCallback(
    (value) => {
      if (removeNonDigitsFromString(value).length === 8) {
        dispatch(
          parceiroActions.pesquisarCep.request({
            value,
            successCallback: successZipCodeCallback,
          })
        );
      }
    },
    [dispatch, successZipCodeCallback]
  );

  const { reset } = formProps;

  useEffect(() => {
    reset({
      ...imovel,
      endereco: {
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        municipio: "",
        uf: "",
        ...imovel.endereco,
      },
    });
  }, [imovel, reset]);

  return (
    <DadosEnderecoImovelFintechsForm
      {...otherProps}
      formProps={formProps}
      handleZipCodeBlurred={handleZipCodeBlurred}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={imovel.fluxo}
      makeGoTo={makeGoTo}
      events={eventsImovel}
    />
  );
};

export default DadosEnderecoImovelGalleriaFormConnected;
