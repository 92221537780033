import React, { useCallback, useEffect } from "react";
import DadosProfissionaisBradescoForm from "./DadosProfissionaisBradescoForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import validators from "../../../../utils/validators";
import { actions } from "../../../../actions/operacao.actions";
import { transformToDate } from "../../../../utils/basic";
import formatters from "../../../../utils/formatters";

const DadosProfissionaisBradescoFormConnected = ({
  handleGoTo0,
  handleGoTo4,
  eventsPrincipal,
  eventsNaoPrincipal,
  setComprador,
  comprador,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { perfilRenda: perfisRenda, profissao: profissoes },
  } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const resetValues = useCallback(
    () => ({
      perfilRenda: null,
      renda: "",
      ...comprador,
      dadosProfissionais: {
        profissao: null,
        dataAdmissao: "",
        cargo: "",
        ...comprador?.dadosProfissionais,
      },
    }),
    [comprador]
  );

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setComprador({
          ...c,
          fluxo: data.validacao[
            data.operacao.detalhe.correntista ? "correntista" : "completa"
          ]?.participantes?.find((f) => f.id === c?.id)?.etapas,
        });
      }
      handleGoTo4();
    },
    [handleGoTo4, setComprador, comprador]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      renda: validators.number({ required: true, positive: true }),
      perfilRenda: validators.object({ required: true }),
      dadosProfissionais: {
        profissao: validators.object({ required: true }),
        dataAdmissao: validators.date({
          required: true,
          min: "01/01/1900",
          max: formatters.dates.short(new Date()),
        }),
        cargo: validators.string({ required: true }),
      },
    }),
    initialValues: resetValues,
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-profissionais",
            participante: {
              id: comprador?.id,
              idPerfilRenda: values.perfilRenda?.id,
              renda: values.renda,
              dadosProfissionais: {
                idProfissao: values.dadosProfissionais.profissao?.id,
                dataAdmissao: transformToDate(
                  values.dadosProfissionais.dataAdmissao
                ),
                cargo: values.dadosProfissionais.cargo,
              },
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [callback, comprador, operacao, dispatch]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset(resetValues());
  }, [perfisRenda, comprador, resetValues, reset]);

  return (
    <DadosProfissionaisBradescoForm
      {...otherProps}
      perfisRenda={perfisRenda}
      profissoes={profissoes}
      fluxo={comprador.fluxo}
      events={comprador.principal ? eventsPrincipal : eventsNaoPrincipal}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      isPrincipal={comprador.principal}
    />
  );
};

export default DadosProfissionaisBradescoFormConnected;
