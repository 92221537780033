import DetalhesClientePage from "./DetalhesClientePage";
import { useSelector } from "react-redux";
import {
  getDetalhesCliente,
  isCarregandoDetalhesCliente,
} from "../../selectors/clientes.selectors";
import { useCallback, useState } from "react";
import { removeNonDigitsFromString } from "../../utils/basic";
import { getUsuarioLogado } from "../../selectors/usuario.selectors";

const DetalhesClientePageConnected = () => {
  const loading = useSelector(isCarregandoDetalhesCliente);
  const data = useSelector(getDetalhesCliente);
  const usuario = useSelector(getUsuarioLogado);

  const [tab, setTab] = useState("0");
  const handleTabChange = useCallback(
    (event, newValue) => {
      setTab(newValue);
    },
    [setTab]
  );

  const handlePhoneClick = useCallback((phone, msg) => {
    const mensagem = encodeURI(
      msg ?? "Olá,\nSou da Taya e vou te ajudar neste processo."
    );

    window.open(
      `https://wa.me/${removeNonDigitsFromString(phone)}?text=${mensagem}`
    );
  }, []);

  return (
    <DetalhesClientePage
      loading={loading}
      data={data}
      tab={tab}
      handleTabChange={handleTabChange}
      handlePhoneClick={handlePhoneClick}
      perfil={usuario?.perfil}
    />
  );
};

export default DetalhesClientePageConnected;
