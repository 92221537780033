import CommonPage from "../CommonPage";
import { Card, GoBack } from "../../components";
import { Grid, Skeleton, styled } from "@mui/material";
import React from "react";
import CadastroUsuarioForm from "./CadastroUsuarioForm";

const ButtonContainer = styled(Grid)`
  margin-bottom: 16px;
`;

const CadastroUsuarioPage = ({ loading, handleBack, ...props }) => {
  const isLoading = !!loading || !props.data;

  return (
    <CommonPage selected={"usuarios"}>
      <Grid container justifyContent={"center"}>
        <Grid item container spacing={3} direction={"column"} maxWidth={704}>
          <Grid item container alignItems={"center"}>
            <ButtonContainer container spacing={3}>
              <GoBack handleClick={handleBack} text={"Cadastro Usuário"} />
            </ButtonContainer>
            {isLoading && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Skeleton
                          variant={"rounded"}
                          width={"50%"}
                          height={36}
                          _border_={"0.75rem"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton
                          variant={"rounded"}
                          width={"30%"}
                          height={16}
                          _border_={"0.75rem"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton
                          variant={"rounded"}
                          width={"100%"}
                          height={56}
                          _border_={"0.75rem"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton
                          variant={"rounded"}
                          width={"35%"}
                          height={16}
                          _border_={"0.75rem"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton
                          variant={"rounded"}
                          width={"100%"}
                          height={56}
                          _border_={"0.75rem"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton
                          variant={"rounded"}
                          width={"25%"}
                          height={16}
                          _border_={"0.75rem"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton
                          variant={"rounded"}
                          width={"100%"}
                          height={36}
                          _border_={"10rem"}
                          _margin_={"0.5rem 0 0 0"}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            )}
            {!isLoading && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <CadastroUsuarioForm handleBack={handleBack} {...props} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </CommonPage>
  );
};

export default CadastroUsuarioPage;
