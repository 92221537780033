import React from "react";
import { styled } from "@mui/material";
import Card from "./Card";
import { transientOptions } from "../styles/theme";

const StyledCard = styled(Card, transientOptions)`
  width: 100%;
  max-width: ${({ _size_, theme }) => theme.sizes.cards[_size_]};
`;

const SizedCard = ({ _size_, ...props }) => (
  <StyledCard _size_={_size_} {...props} />
);

export default SizedCard;
