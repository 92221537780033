import React from "react";
import QualificacaoCompradoresConnected from "./qualificacoes/QualificacaoCompradores.connected";
import QualificacaoValoresConnected from "./qualificacoes/QualificacaoValores.connected";
import QualificacaoVendedoresConnected from "./qualificacoes/QualificacaoVendedores.connected";
import QualificacaoImovelConnected from "./qualificacoes/QualificacaoImovel.connected";
import QualificacaoEnvioContratoConnected from "./qualificacoes/QualificacaoEnvioContrato.connected";

const FluxoQualificacoes = (props) => {
  return (
    <>
      {props.step === 0 && (
        <QualificacaoValoresConnected {...props} />
      )}
      {props.step === 1 && (
        <QualificacaoCompradoresConnected {...props} />
      )}
      {props.step === 2 && (
        <QualificacaoVendedoresConnected {...props} />
      )}
      {props.step === 3 && (
        <QualificacaoImovelConnected {...props} />
      )}
      {props.step === 4 && (
        <QualificacaoEnvioContratoConnected {...props} />
      )}
    </>
  );
};

export default FluxoQualificacoes;
