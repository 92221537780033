import React from "react";
import { NumericFormat } from "react-number-format";

const PercentTextField = React.forwardRef(
  ({ decimalScale = 2, suffix = " %", onChange, ...other }, ref) => {
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          if (values.value === "") {
            onChange(values.value);
          } else {
            onChange(values.floatValue);
          }
        }}
        thousandSeparator={"."}
        decimalSeparator={","}
        decimalScale={decimalScale}
        fixedDecimalScale={true}
        suffix={suffix}
      />
    );
  }
);

export default PercentTextField;
