import { Grid, styled } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  GoBack,
  IconButton,
  Title,
  Tooltip,
} from "../../../../components";
import { Add, Apartment, Home, Person } from "@mui/icons-material";
import formatters from "../../../../utils/formatters";
import { Field as OtherField } from "../../../../components/presentation";
import ControlledAutocomplete from "../../../../components/inputs/ControlledAutocomplete";
import ItemOperacao from "./ItemOperacao";

const MAX_COMPRADORES = 2;

const CompradorContainer = styled("div")`
  width: calc(100% - 1rem);
  padding: 1rem 0.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.grey._50};
`;
const Disclaimer = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  text-align: center;
  display: block;
  color: ${({ theme }) => theme.colors.grey._500};
`;
const StyledIconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.grey._100};
  color: ${({ theme }) => theme.colors.grey._700};

  &.Mui-disabled {
    background-color: ${({ theme }) => theme.colors.grey._100};
  }
  & .MuiSvgIcon-root {
    font-size: 1rem;
  }

  ${({ theme }) => `${theme.breakpoints.up("md")} {
    & .MuiSvgIcon-root {
			font-size: 1.5rem;
		}
  }`}
`;

const ResumoComprador = ({ data, dominios }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Dados Pessoais
            </Title>
          </Grid>
          <Grid item xs={6} md={3}>
            <OtherField
              label={"Data de Nascimento"}
              value={data.dataNascimento}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <OtherField
              label={"Gênero"}
              type={"lookup"}
              value={data.genero?.id}
              domain={{
                data: dominios.genero,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OtherField
              label={"Nacionalidade"}
              type={"lookup"}
              value={data.nacionalidade?.id}
              domain={{
                data: dominios.nacionalidade,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Documento
            </Title>
          </Grid>
          <Grid item xs={5} md={2}>
            <OtherField
              label={"Documento"}
              type={"lookup"}
              value={data.documentoPessoal?.id}
              domain={{
                data: dominios.documentoPessoal,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
          <Grid item xs={7} md={3}>
            <OtherField
              label={"Número do Documento"}
              value={data.numeroDocumentoPessoal}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <OtherField
              label={"Órgão Expedidor"}
              value={data.orgaoDocumentoPessoal}
            />
          </Grid>
          <Grid item xs={3} md={1}>
            <OtherField label={"Estado"} value={data.ufOrgaoDocumentoPessoal} />
          </Grid>
          <Grid item xs={5} md={3}>
            <OtherField
              label={"Data de Expedição"}
              value={data.dataExpedicaoDocumentoPessoal}
            />
          </Grid>
          <Grid item xs={12}>
            <OtherField label={"Nome da Mãe"} value={data.nomeMae} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Informações de Contato
            </Title>
          </Grid>
          <Grid item xs={6} md={3}>
            <OtherField label={"Celular"} value={data.telefone} />
          </Grid>
          <Grid item xs={6} md={3}>
            <OtherField
              label={"Telefone Residencial"}
              value={data.telefoneResidencial}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OtherField label={"E-mail"} value={data.email} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Endereço Residencial
            </Title>
          </Grid>
          <Grid item xs={4} md={2}>
            <OtherField
              label={"CEP"}
              type={"zipcode"}
              value={data.endereco?.cep}
            />
          </Grid>
          <Grid item xs={8} md={4}>
            <OtherField label={"Endereço"} value={data.endereco?.logradouro} />
          </Grid>
          <Grid item xs={3} md={1}>
            <OtherField label={"Número"} value={data.endereco?.numero} />
          </Grid>
          {data.endereco?.complemento && (
            <Grid item xs={4} md={2}>
              <OtherField
                label={"Complemento"}
                value={data.endereco?.complemento}
              />
            </Grid>
          )}
          <Grid item xs={6} md={2}>
            <OtherField label={"Município"} value={data.endereco?.municipio} />
          </Grid>
          <Grid item xs={3} md={1}>
            <OtherField label={"UF"} value={data.endereco?.uf} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Dados Profissionais
            </Title>
          </Grid>
          <Grid item xs={5} md={4}>
            <OtherField
              label={"Perfil de Renda"}
              type={"lookup"}
              value={data.perfilRenda?.id}
              domain={{
                data: dominios.perfilRenda,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
          <Grid item xs={7} md={8}>
            <OtherField
              label={"Profissão"}
              type={"lookup"}
              value={data.dadosProfissionais?.profissao?.id}
              domain={{
                data: dominios.profissao,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <OtherField
              label={"Nome da Empresa"}
              value={data.dadosProfissionais?.nomeEmpresa}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <OtherField
              label={"Data de Início"}
              value={data.dadosProfissionais?.dataAdmissao}
            />
          </Grid>
          <Grid item xs={7} md={4}>
            <OtherField
              label={"Cargo"}
              value={data.dadosProfissionais?.cargo}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Title as={"h5"} _color_={"primary"}>
              Estado Civil
            </Title>
          </Grid>
          <Grid item xs={7} md={3}>
            <OtherField
              label={"Estado Civil"}
              type={"lookup"}
              value={data.estadoCivil?.id}
              domain={{
                data: dominios.estadoCivil,
                filter: (x, v) => x.id === v,
                map: (x) => x?.descricao,
              }}
            />
          </Grid>
          {dominios.estadoCivil.find((x) => x.id === data.estadoCivil?.id)
            ?.exibeUniaoEstavel && (
            <Grid item xs={5} md={3}>
              <OtherField
                label={"União Estável"}
                type={"boolean"}
                value={data.uniaoEstavel}
              />
            </Grid>
          )}
          {data.dataCasamento && (
            <Grid item xs={5} md={3}>
              <OtherField
                label={"Data do Casamento"}
                value={data.dataCasamento}
              />
            </Grid>
          )}
          {data.regimeComunhao?.id && (
            <Grid item xs={5} md={6}>
              <OtherField
                label={"Regime de Comunhão"}
                type={"lookup"}
                value={data.regimeComunhao?.id}
                domain={{
                  data: dominios.regimeComunhao,
                  filter: (x, v) => x.id === v,
                  map: (x) => x?.descricao,
                }}
              />
            </Grid>
          )}
          {data.conjuge && (
            <>
              <Grid item xs={12}>
                <Title as={"h5"} _color_={"primary"}>
                  Cônjuge
                </Title>
              </Grid>
              <Grid item xs={5} md={4}>
                <OtherField
                  label={"Nome Completo"}
                  value={data.conjuge?.nome}
                />
              </Grid>
              <Grid item xs={5} md={3}>
                <OtherField
                  label={"CPF"}
                  type={"cpf"}
                  value={data.conjuge?.cpfCnpj}
                />
              </Grid>
              <Grid item xs={5} md={3}>
                <OtherField
                  label={"Data de Nascimento"}
                  value={data.conjuge?.dataNascimento}
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <OtherField
                  label={"Gênero"}
                  type={"lookup"}
                  value={data.conjuge?.genero?.id}
                  domain={{
                    data: dominios.genero,
                    filter: (x, v) => x.id === v,
                    map: (x) => x?.descricao,
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const HubFintechs = ({
  imovel,
  compradores,
  outrosProponentes,
  handleGoBack,
  handleAcessarParticipante,
  handleAcessarImovel,
  handleAdicionarParticipante,
  handleRemoverParticipante,
  adding,
  deleting,
  refreshing,
  submitting,
  formProps,
  handleSubmit,
  dominios,
}) => {
  return (
    <Grid item container justifyContent={"center"} spacing={3}>
      <Grid item container spacing={3} direction={"column"} maxWidth={704}>
        <GoBack
          handleClick={handleGoBack}
          text={
            "Preencha todas as informações sobre o imóvel, compradores e demais participantes"
          }
        />
        <Grid item container justifyContent={"center"}>
          <Grid item>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Title as={"h5"}>
                    {compradores.length > 1 ? "Compradores" : "Comprador"}
                  </Title>
                </Grid>
                <Grid item xs={12}>
                  {compradores.map((c) => {
                    return (
                      <ItemOperacao
                        key={c.id}
                        fluxo={c.fluxo}
                        submitting={submitting}
                        refreshing={refreshing}
                        deleting={deleting}
                        adding={adding}
                        icon={c.tipoPessoa === "F" ? <Person /> : <Apartment />}
                        title={c.nome ?? "(Nome não informado)"}
                        subtitle={formatters.strings.cpfCnpj(
                          c.cpfCnpj,
                          c.tipoPessoa
                        )}
                        titleRemover={"Remover comprador"}
                        isChildren={!!c.participanteOrigem}
                        canRemove={!c.principal}
                        handleRemover={(e) =>
                          handleRemoverParticipante(e, c.id)
                        }
                        handleEditar={(e) => handleAcessarParticipante(e, c)}
                        details={
                          <ResumoComprador data={c} dominios={dominios} />
                        }
                      />
                    );
                  })}
                  {compradores.length < MAX_COMPRADORES && (
                    <CompradorContainer>
                      <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Grid item>
                          <Disclaimer>
                            Gostaria de adicionar outro comprador pra compor
                            renda?
                          </Disclaimer>
                        </Grid>
                        <Grid item width={56}>
                          <Tooltip arrow title={"Adicionar comprador"}>
                            <StyledIconButton
                              disabled={
                                submitting || deleting || refreshing || adding
                              }
                              loading={adding || refreshing}
                              loaderColor={"grey"}
                              onClick={() =>
                                handleAdicionarParticipante("CO", "F")
                              }
                            >
                              <Add />
                            </StyledIconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </CompradorContainer>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Title as={"h5"}>Outros Proponentes</Title>
                </Grid>
                <Grid item xs={12}>
                  {outrosProponentes.map((c) => {
                    return (
                      <ItemOperacao
                        key={c.id}
                        fluxo={c.fluxo}
                        submitting={submitting}
                        refreshing={refreshing}
                        deleting={deleting}
                        adding={adding}
                        icon={c.tipoPessoa === "F" ? <Person /> : <Apartment />}
                        title={c.nome ?? "(Nome não informado)"}
                        subtitle={formatters.strings.cpfCnpj(
                          c.cpfCnpj,
                          c.tipoPessoa
                        )}
                        isChildren={!!c.participanteOrigem}
                        titleRemover={"Remover proponente"}
                        canRemove={true}
                        handleRemover={(e) =>
                          handleRemoverParticipante(e, c.id)
                        }
                        handleEditar={(e) => handleAcessarParticipante(e, c)}
                        details={
                          <ResumoComprador data={c} dominios={dominios} />
                        }
                      />
                    );
                  })}
                  <CompradorContainer>
                    <Grid
                      container
                      spacing={2}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Grid item xs={12} sm={"auto"}>
                        <Disclaimer>
                          Gostaria de adicionar outro proponente?
                        </Disclaimer>
                      </Grid>
                      <Grid item>
                        <Tooltip arrow title={"Adicionar proponente PF"}>
                          <Button
                            variant={"outlined"}
                            size={"small"}
                            loading={adding || refreshing}
                            loaderColor={"brand"}
                            onClick={() =>
                              handleAdicionarParticipante("OU", "F")
                            }
                          >
                            PF
                          </Button>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip arrow title={"Adicionar proponente PJ"}>
                          <Button
                            variant={"outlined"}
                            size={"small"}
                            loading={adding || refreshing}
                            loaderColor={"brand"}
                            onClick={() =>
                              handleAdicionarParticipante("OU", "J")
                            }
                          >
                            PJ
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </CompradorContainer>
                </Grid>
                <Grid item xs={12}>
                  <Title as={"h5"}>Imóvel</Title>
                </Grid>
                <Grid item xs={12}>
                  <ItemOperacao
                    fluxo={imovel.fluxo}
                    submitting={submitting}
                    refreshing={refreshing}
                    deleting={deleting}
                    adding={adding}
                    icon={<Home />}
                    title={
                      formatters.strings.address(imovel.endereco) ??
                      "(Endereço não informado)"
                    }
                    canRemove={false}
                    handleEditar={handleAcessarImovel}
                    details={<></>}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Title as={"h5"}>Finalidade do Crédito</Title>
                      </Grid>
                      <Grid item xs={12}>
                        <ControlledAutocomplete
                          name={"finalidade"}
                          formProps={formProps}
                          domain={dominios.finalidadeCredito}
                          idField={"id"}
                          labelField={"descricao"}
                          autocompleteProps={{
                            multiple: true,
                            disableCloseOnSelect: true,
                            clearText: "Limpar",
                            loadingText: "Carregando opções",
                            noOptionsText: "Nenhuma opção disponível",
                          }}
                        />
                      </Grid>
                      <Grid item container justifyContent={"center"} mt={1}>
                        <Grid item xs={12} md={6}>
                          <Button
                            fullWidth
                            loading={submitting}
                            type={"submit"}
                          >
                            DOCUMENTOS
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HubFintechs;
