import { createSelector } from "reselect";

export const getOperacao = (state) => state.operacao;

export const getListaOperacoes = createSelector(
  getOperacao,
  (state) => state.lista
);
export const getDialogUltComentario = createSelector(
  getOperacao,
  (state) => state.dialogUltComentario
);
export const getDialogAtribuirResponsavel = createSelector(
  getOperacao,
  (state) => state.dialogAtribuirResponsavel
);
export const getDialogCompartilhamento = createSelector(
  getOperacao,
  (state) => state.dialogCompartilhamento
);
export const isCarregandoOperacoes = createSelector(
  getListaOperacoes,
  (state) => state.loading
);
export const getConteudoOperacoes = createSelector(
  getListaOperacoes,
  (state) => state.conteudo
);

export const isCarregandoConjuge = createSelector(
  getOperacao,
  (state) => state.conjuge
);
export const isCarregandoResumo = createSelector(
  getOperacao,
  (state) => state.resumo.loading
);
export const getResumo = createSelector(
  getOperacao,
  (state) => state.resumo.data
);
export const getListaPaging = createSelector(getListaOperacoes, (state) => ({
  total: state.totalElementos,
  tamanho: state.tamanho,
}));

export const isCarregandoDetalhesOperacao = createSelector(
  getOperacao,
  (state) => state.detalhes.loading
);
export const getDetalhesOperacao = createSelector(
  getOperacao,
  (state) => state.detalhes.data
);
export const getDominiosOperacao = createSelector(
  getOperacao,
  (state) => state.detalhes.dominios
);
export const getDetalhesTab = createSelector(
  getOperacao,
  (state) => state.detalhes.tab
);
export const getDocumentos = createSelector(
  getDetalhesOperacao,
  (state) => state?.checklists
);

export const isAtualizandoDetalhesOperacao = createSelector(
  getOperacao,
  (state) => state.detalhes.submitting
);
export const getQuantidadeDocumentosPendentesTotal = createSelector(
  getDocumentos,
  (state) => getQuantidadeDocumentosPendentes(state ?? [])
);
export const getQuantidadeDocumentosObrigatoriosTotal = createSelector(
  getDocumentos,
  (state) => getQuantidadeDocumentosObrigatorios(state ?? [])
);
export const getQuantidadeDocumentosPendentes = (checklists) => {
  if (!checklists) return 0;
  return checklists.reduce((total, checklist) => {
    return (
      total +
      checklist.grupos.reduce((t1, g) => {
        return (
          t1 +
          g.itens.reduce((t2, i) => {
            return t2 + i.documentos.filter((d) => d.situacao === "PE" && !d.obrigatorio).length;
          }, 0)
        );
      }, 0)
    );
  }, 0);
};
export const getQuantidadeDocumentosObrigatorios = (checklists) => {
  if (!checklists) return 0;
  return checklists.reduce((total, checklist) => {
    return (
      total +
      checklist.grupos.reduce((t1, g) => {
        return (
          t1 +
          g.itens.reduce((t2, i) => {
            return t2 + i.documentos.filter((d) => d.situacao === "PE" && d.obrigatorio).length;
          }, 0)
        );
      }, 0)
    );
  }, 0);
};
export const isSubindoArquivos = createSelector(
  getOperacao,
  (state) => state.detalhes.uploading
);
export const isCarregandoDocumentos = createSelector(
  getOperacao,
  (state) => state.detalhes.loadingDocumentos
);
export const isBaixandoArquivos = createSelector(
  getOperacao,
  (state) => state.detalhes.downloading
);
export const isBaixandoTemplates = createSelector(
  getOperacao,
  (state) => state.detalhes.templating
);
export const isRemovendoArquivos = createSelector(
  getOperacao,
  (state) => state.detalhes.deleting
);
export const isBaixandoTodosArquivos = createSelector(
  getOperacao,
  (state) => state.detalhes.downloadingAll
);
export const getEtapasAbertas = createSelector(getDetalhesOperacao, (state) => {
  if (!state?.fluxoTrabalho) {
    return [];
  }
  if (state.fluxoTrabalho.situacao !== "A") {
    return [];
  }
  const etapas = state.fluxoTrabalho.etapas || [];

  return etapas.filter((e) => e.situacao === "E");
});

export const getDialogosWorkflow = createSelector(
  getDetalhesOperacao,
  (state) => {
    if (!state?.fluxoTrabalho || !state?.fluxoTrabalho?.dialogos) {
      return [];
    }
    if (state.fluxoTrabalho.situacao === "A") {
      return [];
    }
    return state.fluxoTrabalho.dialogos;
  }
);

export const getDialogosDisponiveis = (state) => {
  const resumo = {};

  const dialogosWorkflow = getDialogosWorkflow(state);
  const etapasAbertas = getEtapasAbertas(state);

  for (const etapa of etapasAbertas) {
    if (!!etapa.dialogosEtapa) {
      for (const dialogo of etapa.dialogosEtapa) {
        const substituir =
          !resumo[dialogo.padrao] ||
          ordemEdicao[resumo[dialogo.padrao]] < ordemEdicao[dialogo.tipoEdicao];

        if (!!substituir) {
          resumo[dialogo.padrao] = dialogo.tipoEdicao;
        }
      }
    }
  }
  for (const dialogo of dialogosWorkflow) {
    const substituir =
      !resumo[dialogo.padrao] ||
      ordemEdicao[resumo[dialogo.padrao]] < ordemEdicao[dialogo.tipoEdicao];

    if (!!substituir) {
      resumo[dialogo.padrao] = dialogo.tipoEdicao;
    }
  }

  return resumo;
};

const ordemEdicao = {
  C: 0,
  E: 1,
  O: 2,
};

export const isCarregandoPreenchimentoOperacao = createSelector(
  getOperacao,
  (state) => state.preenchimento.loading
);
export const getPreenchimentoOperacao = createSelector(
  getOperacao,
  (state) => state.preenchimento.data
);

export const getNovoCalculo = createSelector(
  getOperacao,
  (state) => state.preenchimento.novoCalculo
)

export const isGravandoPreenchimentoOperacao = createSelector(
  getOperacao,
  (state) => state.preenchimento.submitting
);
export const isBuscandoPreenchimentoOperacao = createSelector(
  getOperacao,
  (state) => state.preenchimento.searching
);
export const isEnviandoPreenchimentoOperacao = createSelector(
  getOperacao,
  (state) => state.preenchimento.sending
);
export const isAdicionandoParticipantePreenchimento = createSelector(
  getOperacao,
  (state) => state.preenchimento.adding
);
export const isRemovendoParticipantePreenchimento = createSelector(
  getOperacao,
  (state) => state.preenchimento.deleting
);
export const isAtualizandoPreenchimento = createSelector(
  getOperacao,
  (state) => state.preenchimento.refreshing
);

export const isCarregandoRelacionadas = createSelector(
  getOperacao,
  (state) => state.preenchimento.relacionadas.loading
);
export const getRelacionadas = createSelector(
  getOperacao,
  (state) => state.preenchimento.relacionadas.data
);
export const isIniciandoRelacionada = createSelector(
  getOperacao,
  (state) => state.preenchimento.relacionadas.submitting
);

export const isCarregandoDadosBancariosOperacao = createSelector(
  getOperacao,
  (state) => state.dadosBancarios.loading
);
export const getDadosBancariosOperacao = createSelector(
  getOperacao,
  (state) => state.dadosBancarios.data
);
export const isEnviandoDadosBancariosOperacao = createSelector(
  getOperacao,
  (state) => state.dadosBancarios.sending
);

export const isCarregandoComplementoOperacao = createSelector(
  getOperacao,
  (state) => state.complemento.loading
);
export const getComplementoOperacao = createSelector(
  getOperacao,
  (state) => state.complemento.data
);
export const isGravandoComplementoOperacao = createSelector(
  getOperacao,
  (state) => state.complemento.submitting
);
export const isEnviandoComplementoOperacao = createSelector(
  getOperacao,
  (state) => state.complemento.sending
);
export const isAtualizandoComplemento = createSelector(
  getOperacao,
  (state) => state.complemento.refreshing
);
export const isAdicionandoParticipanteComplemento = createSelector(
  getOperacao,
  (state) => state.complemento.adding
);
export const isRemovendoParticipanteComplemento = createSelector(
  getOperacao,
  (state) => state.complemento.deleting
);
export const isBuscandoContaIq = createSelector(
  getOperacao,
  (state) => state.complemento.searching
);
export const isCarregandoConfirmacaoValores = createSelector(
  getOperacao,
  (state) => state.confirmacao.loading
);
export const getConfirmacaoValores = createSelector(
  getOperacao,
  (state) => state.confirmacao.data
);
export const isEnviandoConfirmacaoValores = createSelector(
  getOperacao,
  (state) => state.confirmacao.submitting
);
export const getOperacaoAprovacaoMenor = createSelector(
  getOperacao,
  (state) => state.aprovacaoMenor
);

