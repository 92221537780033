import React from "react";
import { styled, Card as MCard } from "@mui/material";
import { transientOptions } from "../styles/theme";

const StyledCard = styled(MCard, transientOptions)`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadiuses.cards};
  border: ${({ theme, _border_ = "none" }) =>
    `solid 1px ${theme.colors[_border_]?._200}` ?? _border_};
  padding: ${({ theme }) => theme.spacings.cards};
  box-shadow: ${({ theme, _shadow_ = "secondary" }) =>
    theme._shadows.cards[_shadow_]};
`;

const Card = (props) => <StyledCard {...props} />;

export default Card;
