import { Chip as MChip, Grid, Stack, styled } from "@mui/material";
import React from "react";
import { Card, IconButton, Title, Tooltip } from "../../components";
import { Field } from "../../components/presentation";
import formatters from "../../utils/formatters";
import { PENSIONISTAS } from "../../constants/generic.constants";
import { transformFromDate, transformToDate } from "../../utils/basic";
import { Edit } from "@mui/icons-material";
import AlterarInputPropostaDialogConnected from "../workflow/AlterarInputPropostaDialog.connected";
import { actions } from "../../actions/workflow.actions";

const Chip = styled(MChip)`
  margin-top: 0.25rem;
  padding: 0 0.25rem;

  & .MuiChip-label {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.small};
  }

  & + & {
    margin-left: 0.5rem;
  }
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 0.5rem;
  padding: 0;
  margin-top: -0.25rem;
  color: ${({ theme }) => theme.colors.secondary._500};

  &.Mui-disabled {
    background-color: ${({ theme }) => theme.colors.grey._100};
  }
  & .MuiSvgIcon-root {
    font-size: 1rem;
  }
`;

const sortId = (id, parent) => (parent ?? id) * 10 + id;

const sortParticipantes = (p1, p2) => {
  const ordem = {
    CO: 0,
    VD: 1,
  };
  const o1 = ordem[p1.tipoParticipante] ?? 2;
  const o2 = ordem[p2.tipoParticipante] ?? 2;

  if (o1 - o2 !== 0) {
    return o1 - o2;
  }

  if (p1.tipoParticipante === "CO") {
    return p1.principal
      ? -1
      : p2.principal
        ? 1
        : (p1.nome ?? "").localeCompare(p2.nome ?? "");
  } else if (p1.tipoParticipante === "VD") {
    return (p1.nome ?? "").localeCompare(p2.nome ?? "");
  } else {
    return (
      sortId(p1.id, p1.participanteOrigem) -
      sortId(p2.id, p2.participanteOrigem)
    );
  }
};

const DadosOperacaoTab = ({
  data,
  perfil,
  dominios,
  numeroProposta,
  handleCloseNumeroProposta,
  handleOpenNumeroProposta,
}) => {
  return (
    <>
      <DetalhesProposta
        perfil={perfil}
        data={data}
        handleOpenNumeroProposta={handleOpenNumeroProposta}
      />
      <DadosProposta data={data} perfil={perfil} />
      <DadosConfirmacaoValores
        data={data.operacaoConfirmacaoValores}
        dominios={dominios}
        operacao={data}
      />
      <DadosImovel data={data.imovel} dominios={dominios} operacao={data} />
      {data.participantes.toSorted(sortParticipantes).map((p) => (
        <DadosParticipante
          key={p.id}
          data={p}
          dominios={dominios}
          operacao={data}
        />
      ))}
      <AlterarInputPropostaDialogConnected
        dados={numeroProposta}
        handleClose={handleCloseNumeroProposta}
      />
    </>
  );
};

const getNomeParticipante = (operacao, key) => {
  const [id, conjuge] = key.split("|");

  return operacao.participantes
    .filter((p) => p.id == id)
    .map((p) => (conjuge === "true" ? p.conjuge.nome : p.nome));
};

const StructuredField = ({ xs, sm, md, lg, xl, value, ...other }) => {
  if (
    !other.force &&
    (value === null ||
      value === undefined ||
      value === "" ||
      value?.length === 0)
  ) {
    return null;
  }

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Field {...other} value={value} />
    </Grid>
  );
};

const DetalhesProposta = ({ data, perfil, handleOpenNumeroProposta }) => {
  return (
    <Grid item xs={12}>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title as={"h4"}>Detalhes da Proposta</Title>
          </Grid>
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            force
            label={"Código da Proposta na Instituição Financeira"}
            value={data.detalhe.numeroProposta || "-"}
            addon={
              perfil === "OPERACAO" && !data.dataHoraEncerramento ? (
                <Tooltip arrow title={"Alterar código da proposta"}>
                  <StyledIconButton
                    loading={false}
                    size={"small"}
                    loaderColor={"grey"}
                    onClick={() =>
                      handleOpenNumeroProposta({
                        action: actions.atualizarNumeroProposta.request,
                        title: "Alterar o número da proposta",
                        label: "Número da Proposta",
                        type: "string",
                        contexto: {
                          id_operacao: data.id,
                          input: data.detalhe.numeroProposta || "",
                        },
                        callback: () => {
                          window.location.reload();
                        },
                      })
                    }
                  >
                    <Edit />
                  </StyledIconButton>
                </Tooltip>
              ) : null
            }
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            force
            label={"Número do Contrato na Instituição Financeira"}
            value={data.detalhe.numeroContrato || "-"}
            addon={
              perfil === "OPERACAO" && !data.dataHoraEncerramento ? (
                <Tooltip arrow title={"Alterar número do contrato"}>
                  <StyledIconButton
                    loading={false}
                    size={"small"}
                    loaderColor={"grey"}
                    onClick={() =>
                      handleOpenNumeroProposta({
                        action: actions.atualizarNumeroContrato.request,
                        title: "Alterar o número do contrato",
                        label: "Número do Contrato",
                        type: "string",
                        contexto: {
                          id_operacao: data.id,
                          input: data.detalhe.numeroContrato || "",
                        },
                        callback: () => {
                          window.location.reload();
                        },
                      })
                    }
                  >
                    <Edit />
                  </StyledIconButton>
                </Tooltip>
              ) : null
            }
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            force
            label={"Data de Emissão do Contrato"}
            value={transformFromDate(data.detalhe.dataEmissao)}
            addon={
              perfil === "OPERACAO" && !data.dataHoraEncerramento ? (
                <Tooltip arrow title={"Alterar data de emissão"}>
                  <StyledIconButton
                    loading={false}
                    size={"small"}
                    loaderColor={"grey"}
                    onClick={() =>
                      handleOpenNumeroProposta({
                        action: actions.atualizarDataEmissao.request,
                        title: "Alterar a data de emissão",
                        label: "Data de Emissão",
                        type: "date",
                        contexto: {
                          id_operacao: data.id,
                          input:
                            transformFromDate(data.detalhe.dataEmissao) || "",
                        },
                        callback: () => {
                          window.location.reload();
                        },
                      })
                    }
                  >
                    <Edit />
                  </StyledIconButton>
                </Tooltip>
              ) : null
            }
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            force
            label={"Código de Rastreio do Envio do Contrato"}
            value={data.detalhe.codigoRastreamento || "-"}
            addon={
              perfil === "OPERACAO" && !data.dataHoraEncerramento ? (
                <Tooltip arrow title={"Alterar código de rastreamento"}>
                  <StyledIconButton
                    loading={false}
                    size={"small"}
                    loaderColor={"grey"}
                    onClick={() =>
                      handleOpenNumeroProposta({
                        action: actions.atualizarCodigoRastreamento.request,
                        title: "Alterar o código de rastreamento",
                        label: "Código Rastreamento",
                        type: "string",
                        contexto: {
                          id_operacao: data.id,
                          input: data.detalhe.codigoRastreamento || "",
                        },
                        callback: () => {
                          window.location.reload();
                        },
                      })
                    }
                  >
                    <Edit />
                  </StyledIconButton>
                </Tooltip>
              ) : null
            }
          />
        </Grid>
      </Card>
    </Grid>
  );
};

const DadosConfirmacaoValores = ({ data, dominios, operacao }) => {
  if (!data || !data.id) {
    return null;
  }
  return (
    <Grid item xs={12}>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title as={"h4"}>Valores Confirmados</Title>
          </Grid>
          <StructuredField
            xs={12}
            lg={4}
            label={"Qualificação Valores"}
            type={"lookup"}
            force
            value={data.qualificacaoResumo}
          />
          <StructuredField
            xs={12}
            lg={4}
            label={"Aceita Qualificação Valores"}
            type={"boolean"}
            force
            value={data.aceitaQualificacaoResumo}
          />
          <StructuredField
            xs={12}
            lg={4}
            label={"Correção Valores"}
            type={"lookup"}
            force
            value={data.correcaoResumo}
          />
          <StructuredField
            xs={12}
            lg={4}
            label={"Qualificação Compradores"}
            type={"lookup"}
            force
            value={data.qualificacaoCompradores}
          />
          <StructuredField
            xs={12}
            lg={4}
            label={"Aceita Qualificação Compradores"}
            type={"boolean"}
            force
            value={data.aceitaQualificacaoCompradores}
          />
          <StructuredField
            xs={12}
            lg={4}
            label={"Correção Compradores"}
            type={"lookup"}
            force
            value={data.correcaoCompradores}
          />
          <StructuredField
            xs={12}
            lg={4}
            label={"Qualificação Vendedores"}
            type={"lookup"}
            force
            value={data.qualificacaoVendedores}
          />
          <StructuredField
            xs={12}
            lg={4}
            label={"Aceita Qualificação Vendedores"}
            type={"boolean"}
            force
            value={data.aceitaQualificacaoVendedores}
          />
          <StructuredField
            xs={12}
            lg={4}
            label={"Correção Vendedores"}
            type={"lookup"}
            force
            value={data.correcaoVendedores}
          />
          <StructuredField
            xs={12}
            lg={4}
            label={"Qualificação Imóvel"}
            type={"lookup"}
            force
            value={data.qualificacaoImovel}
          />
          <StructuredField
            xs={12}
            lg={4}
            label={"Aceita Qualificação Imóvel"}
            type={"boolean"}
            force
            value={data.aceitaQualificacaoImovel}
          />
          <StructuredField
            xs={12}
            lg={4}
            label={"Correção Imóvel"}
            type={"lookup"}
            force
            value={data.correcaoImovel}
          />
          <StructuredField
            xs={12}
            lg={4}
            label={"Qualificação Entrega"}
            type={"lookup"}
            force
            value={data.qualificacaoEntrega}
          />
          <StructuredField
            xs={12}
            lg={4}
            label={"Aceita Qualificação Entrega"}
            type={"boolean"}
            force
            value={data.aceitaQualificacaoEntrega}
          />
          <StructuredField
            xs={12}
            lg={4}
            label={"Correção Entrega"}
            type={"lookup"}
            force
            value={data.correcaoEntrega}
          />
        </Grid>
      </Card>
    </Grid>
  );
};

const DadosImovel = ({ data, dominios, operacao }) => {
  return (
    <Grid item xs={12}>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title as={"h4"}>Imóvel</Title>
          </Grid>
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Utilização do Imóvel"}
            type={"lookup"}
            value={data.tipoImovel}
            domain={{
              data: dominios?.tipoImovel,
              filter: (x, v) => x.id === v,
              map: (x) => x?.nome,
            }}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Valor do Imóvel"}
            type={"currency"}
            value={data.valorImovel}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Valor de Avaliação"}
            type={"currency"}
            value={data.valorAvaliacao}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Data do Laudo"}
            value={transformFromDate(data.dataLaudo)}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Tipo de Imóvel"}
            value={data.subTipoImovel}
            type={"lookup"}
            domain={{
              data: dominios?.subTipoImovel,
              filter: (x, v) => x.id === v,
              map: (x) => x?.descricao,
            }}
          />
          <StructuredField
            xs={12}
            xl={6}
            label={"Endereço"}
            value={formatters.strings.address(data.endereco)}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Tipo de Residência"}
            value={data.endereco?.tipoResidencia}
            type={"lookup"}
            domain={{
              data: dominios?.tipoResidencia,
              filter: (x, v) => x.id === v,
              map: (x) => x?.descricao,
            }}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Imóvel Próprio?"}
            type={"boolean"}
            value={data.imovelProprio}
          />
          <StructuredField
            xs={12}
            lg={8}
            xl={6}
            label={"Proprietários"}
            type={"decode"}
            value={data.proprietarios ?? []}
            decode={(proprietarios) =>
              proprietarios.map((p) => (
                <Chip
                  key={p.id}
                  label={getNomeParticipante(operacao, p.id)}
                  size={"small"}
                />
              ))
            }
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Quantidade de Dormitórios"}
            value={data.numeroDormitorios}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Quantidade Vagas"}
            value={data.quantidadeVagas}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Possui Depósito ou Vaga Autônoma?"}
            type={"boolean"}
            value={data.vagaAutonoma}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Primeira Aquisição no SFH?"}
            type={"boolean"}
            value={data.primeiraAquisicaoSfh}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Está adquirindo parte do imóvel?"}
            type={"boolean"}
            value={data.adquirindoParteImovel}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"% da parte à ser adquirida"}
            type={"percent"}
            value={data.parteAdquirida}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Valor de compra/venda da parte a ser adquirida"}
            type={"currency"}
            value={data.valorParteAdquirida}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Imóvel financiado?"}
            type={"boolean"}
            value={data.imovelFinanciado}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Instituição Bancária"}
            value={data.bancoFinanciamento}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Saldo Devedor"}
            type={"currency"}
            value={data.saldoDevedorFinanciamento}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Possui débitos?"}
            type={"boolean"}
            value={data.possuiDebitos}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Débitos de IPTU"}
            type={"currency"}
            value={data.valorDebitosIptu}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Débitos de Condomínio"}
            type={"currency"}
            value={data.valorDebitosCondominio}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Imóvel alugado?"}
            type={"boolean"}
            value={data.imovelAlugado}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Valor do Aluguel"}
            type={"currency"}
            value={data.valorAluguel}
          />
          <StructuredField
            xs={12}
            xl={6}
            label={"Contatos para Vistoria"}
            type={"decode"}
            value={data.contatosVistoria ?? []}
            decode={(contatos) =>
              contatos.map((c) => (
                <Chip
                  key={c.id}
                  label={`${c.nome} - ${c.telefone}`}
                  size={"small"}
                />
              ))
            }
          />
        </Grid>
      </Card>
    </Grid>
  );
};

const DadosParticipante = ({ data, dominios, operacao }) => {
  return (
    <Grid item xs={12}>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title as={"h4"}>
              {data.tipoParticipante === "CO"
                ? "Comprador"
                : data.tipoParticipante === "VD"
                  ? "Vendedor"
                  : "Outros Proponentes"}
            </Title>
          </Grid>
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"CPF/CNPJ"}
            type={"cpfCnpj"}
            value={data.cpfCnpj}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Nome"}
            value={data.nome}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"E-mail"}
            value={data.email}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Celular"}
            value={data.telefone}
          />
          {data.principal && (
            <StructuredField
              xs={12}
              lg={4}
              xl={3}
              force
              label={"É correntista?"}
              type={"boolean"}
              value={operacao.detalhe?.correntista}
            />
          )}
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Telefone Residencial"}
            value={data.telefoneResidencial}
          />
          <StructuredField
            xs={12}
            xl={6}
            label={"Endereço"}
            value={formatters.strings.address(data.endereco)}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Tipo de Residência"}
            value={data.endereco?.tipoResidencia}
            type={"lookup"}
            domain={{
              data: dominios?.tipoResidencia,
              filter: (x, v) => x.id === v,
              map: (x) => x?.descricao,
            }}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Qualificação"}
            type={"lookup"}
            value={data.qualificacao}
            domain={{
              data: dominios?.qualificacao,
              filter: (x, v) => x.id === v,
              map: (x) => x?.descricao,
            }}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Vai utilizar FGTS?"}
            type={"boolean"}
            value={data.utilizaFgts}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Possui procuração?"}
            type={"boolean"}
            value={data.possuiProcuracao}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Autoriza o débito da tarifa de avaliação?"}
            type={"boolean"}
            value={data.autorizaTarifaAvaliacao}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={
              "Autoriza a utilização do limite da conta para débito da parcela?"
            }
            type={"boolean"}
            value={data.autorizaLimite}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={
              "Autoriza o débito de parcelas em atraso em sua conta-corrente?"
            }
            type={"boolean"}
            value={data.autorizaAtraso}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Pessoa Politicamente Exposta?"}
            type={"boolean"}
            value={data.ppe}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"US Person?"}
            type={"boolean"}
            value={data.usPerson}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Residente Fiscal no Brasil?"}
            type={"boolean"}
            value={data.residenciaFiscalBrasil}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Participação Societária"}
            type={"percent"}
            value={data.participacaoSocietaria}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Banco"}
            type={"lookup"}
            value={data.bancoConta}
            domain={{
              data: dominios?.banco,
              filter: (x, v) => x.id === v,
              map: (x) => x?.nome,
            }}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"CEP da Agência"}
            type={"zipcode"}
            value={data.cepAgencia}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Agência Conta"}
            value={data.agenciaConta}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Número Conta"}
            value={data.numeroConta}
          />

          {data.tipoPessoa === "F" && (
            <DadosParticipantePf data={data} dominios={dominios} />
          )}
          {data.tipoPessoa === "J" && (
            <DadosParticipantePj data={data} dominios={dominios} />
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

const DadosParticipantePj = ({ data, dominios }) => {
  return (
    <>
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Faturamento Anual"}
        type={"currency"}
        value={data.faturamentoAnual}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Tipo de Empresa"}
        type={"lookup"}
        value={data.tipoEmpresa}
        domain={{
          data: dominios?.tipoEmpresa,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
    </>
  );
};

const DadosParticipantePf = ({ data, dominios }) => {
  return (
    <>
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Data de nascimento"}
        value={transformFromDate(data.dataNascimento)}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Naturalidade"}
        value={data.naturalidade}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Gênero"}
        type={"lookup"}
        value={data.genero}
        domain={{
          data: dominios?.genero,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Escolaridade"}
        type={"lookup"}
        value={data.escolaridade}
        domain={{
          data: dominios?.escolaridade,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Nacionalidade"}
        type={"lookup"}
        value={data.nacionalidade}
        domain={{
          data: dominios?.nacionalidade,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Documento Pessoal"}
        type={"lookup"}
        value={data.documentoPessoal}
        domain={{
          data: dominios?.documentoPessoal,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Número do Documento"}
        value={data.numeroDocumentoPessoal}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Órgão de Expedição do Documento"}
        value={data.orgaoDocumentoPessoal}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"UF de Expedição do Documento"}
        value={data.ufOrgaoDocumentoPessoal}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Data de Expedição do Documento"}
        value={transformFromDate(data.dataExpedicaoDocumentoPessoal)}
      />
      <StructuredField
        xs={12}
        lg={8}
        xl={6}
        label={"Nome da Mãe"}
        value={data.nomeMae}
      />
      <DadosProfissionais
        data={data}
        data2={data.dadosProfissionais}
        dominios={dominios}
      />
      <DadosEstadoCivil data={data} dominios={dominios} />
      {data.tipoParticipante === "VD" ? (
        <DadosContaDeLiberacao data={data} dominios={dominios} />
      ) : null}
    </>
  );
};

const DadosProfissionais = ({ data, data2 = {}, dominios }) => {
  let withValue = false;

  for (const v of ["perfilRenda", "renda", "patrimonio"]) {
    withValue |= Boolean(data[v]);
    if (withValue) break;
  }

  const data2Fields = [
    "dataAdmissao",
    "nomeEmpresa",
    "cnpjEmpresa",
    "cargo",
    "profissao",
    "faturamentoMensal",
    "tipoImovelAluguel",
    "quantidadeImoveisLocados",
    "tipoPensionista",
    "tipoPensao",
    "segmentoServico",
    "possuiContratoServico"
  ];

  if (!withValue) {
    for (const v of data2Fields) {
      withValue |= Boolean(data2[v]);
      if (withValue) break;
    }
  }

  if (!withValue) {
    return null;
  }

  return (
    <>
      <Grid item xs={12}>
        <Title as={"h5"} _margin_={"0.5rem 0 0 0"}>
          Dados Profissionais
        </Title>
      </Grid>
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Perfil de Renda"}
        type={"lookup"}
        value={data.perfilRenda}
        domain={{
          data: dominios?.perfilRenda,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Renda"}
        type={"currency"}
        value={data.renda}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Patrimônio"}
        type={"currency"}
        value={data.patrimonio}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Data de Início"}
        value={transformFromDate(data2.dataAdmissao)}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Nome da Empresa"}
        value={data2.nomeEmpresa}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"CNPJ da Empresa"}
        type={"cnpj"}
        value={data2.cnpjEmpresa}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Cargo"}
        value={data2.cargo}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Profissão"}
        type={"lookup"}
        value={data2.profissao}
        domain={{
          data: dominios?.profissao,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Faturamento Mensal"}
        type={"currency"}
        value={data2.faturamentoMensal}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Tipo de Imóvel de Aluguel"}
        type={"lookup"}
        value={data2.tipoImovelAluguel}
        domain={{
          data: dominios?.tipoImovelAluguel,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Quantidade de Imóveis Locados"}
        value={data2.quantidadeImoveisLocados}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Ramo de Atuação"}
        type={"lookup"}
        value={data2.ramoAtuacao}
        domain={{
          data: dominios?.ramoAtuacao,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Pensionista/Aposentado?"}
        value={PENSIONISTAS[data2.tipoPensionista]}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Tipo de Pensão"}
        type={"lookup"}
        value={data2.tipoPensao}
        domain={{
          data: dominios?.tipoPensao,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Segmento do Servico"}
        type={"lookup"}
        value={data2.segmentoServico}
        domain={{
          data: dominios?.segmentoServico,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Possui Contrato de Prestação de Serviço?"}
        type={"boolean"}
        value={data2.possuiContratoServico}
      />
    </>
  );
};

const DadosProposta = ({ data, perfil }) => {
  if (!data.detalhe.finalidadeCredito && !data.defesaComercial) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title as={"h4"} _margin_={"0.5rem 0 0 0"}>
              Dados da Proposta
            </Title>
          </Grid>
          <StructuredField
            xs={12}
            label={"Finalidade do Crédito"}
            value={data.detalhe.finalidadeCredito}
          />
          {perfil === "OPERACAO" ? (
            <StructuredField
              xs={12}
              force
              label={"Defesa Comercial"}
              value={data.defesaComercial}
            />
          ) : null}
        </Grid>
      </Card>
    </Grid>
  );
};

const DadosEstadoCivil = ({ data, dominios }) => {
  let withValue = false;

  for (const v of [
    "estadoCivil",
    "uniaoEstavel",
    "regimeComunhao",
    "dataCasamento",
  ]) {
    withValue |= data[v];
    if (!!withValue) {
      break;
    }
  }

  if (!withValue) {
    return null;
  }

  return (
    <>
      <Grid item xs={12}>
        <Title as={"h5"} _margin_={"0.5rem 0 0 0"}>
          Dados Estado Civil
        </Title>
      </Grid>
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Estado Civil"}
        type={"lookup"}
        value={data.estadoCivil}
        domain={{
          data: dominios?.estadoCivil,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"União estável?"}
        type={"boolean"}
        value={data.uniaoEstavel}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Regime de Comunhão"}
        type={"lookup"}
        value={data.regimeComunhao}
        domain={{
          data: dominios?.regimeComunhao,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Data do Casamento"}
        value={transformFromDate(data.dataCasamento)}
      />

      {!!data.conjuge && (
        <DadosConjuge data={data.conjuge} dominios={dominios} />
      )}
    </>
  );
};

const DadosContaDeLiberacao = ({ data, dominios }) => {
  return (
    <>
      {data.contaLiberacao ? (
        <Grid item xs={12}>
          <Title as={"h5"} _margin_={"0.5rem 0 0 0"}>
            Conta de Liberação
          </Title>
        </Grid>
      ) : null}
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        force
        label={"Banco"}
        type={"lookup"}
        value={data.contaLiberacao?.bancoConta}
        domain={{
          data: dominios?.banco,
          filter: (x, v) => x.id === v,
          map: (x) => x?.nome,
        }}
      />
      {data.contaLiberacao && (
        <StructuredField
          xs={12}
          lg={4}
          xl={3}
          force
          label={"Agência / Conta"}
          value={`${data.contaLiberacao?.agenciaConta} / ${data.contaLiberacao?.numeroConta}`}
        />
      )}
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        force
        label={"Tipo"}
        type={"lookup"}
        value={data.contaLiberacao?.tipoConta}
        domain={{
          data: dominios?.tipoConta,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
    </>
  );
};

const DadosConjuge = ({ data, dominios, participanteOrigem }) => {
  return (
    <>
      <Grid item xs={12}>
        <Title as={"h5"} _margin_={"0.5rem 0 0 0"}>
          Cônjuge
        </Title>
      </Grid>
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"CPF"}
        type={"cpf"}
        value={data.cpfCnpj}
      />
      <StructuredField xs={12} lg={4} xl={3} label={"Nome"} value={data.nome} />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"E-mail"}
        value={data.email}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Celular"}
        value={data.telefone}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Data de nascimento"}
        value={transformFromDate(data.dataNascimento)}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Gênero"}
        type={"lookup"}
        value={data.genero}
        domain={{
          data: dominios?.genero,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Nacionalidade"}
        type={"lookup"}
        value={data.nacionalidade}
        domain={{
          data: dominios?.nacionalidade,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />

      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Estado Civil"}
        type={"lookup"}
        value={data.estadoCivil}
        domain={{
          data: dominios?.estadoCivil,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Documento Pessoal"}
        type={"lookup"}
        value={data.documentoPessoal}
        domain={{
          data: dominios?.documentoPessoal,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Número do Documento"}
        value={data.numeroDocumentoPessoal}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Órgão de Expedição do Documento"}
        value={data.orgaoDocumentoPessoal}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"UF de Expedição do Documento"}
        value={data.ufOrgaoDocumentoPessoal}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Data de Expedição do Documento"}
        value={transformFromDate(data.dataExpedicaoDocumentoPessoal)}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Qualificação"}
        type={"lookup"}
        value={data.qualificacao}
        domain={{
          data: dominios?.qualificacao,
          filter: (x, v) => x.id === v,
          map: (x) => x?.descricao,
        }}
      />

      {!!participanteOrigem && (
        <>
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Compoe Quadro Societário?"}
            type={"boolean"}
            value={data.compoeQuadroSocietario}
          />
          <StructuredField
            xs={12}
            lg={4}
            xl={3}
            label={"Participação Societária"}
            type={"percent"}
            value={data.participacaoSocietaria}
          />
        </>
      )}
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Compoe Renda?"}
        type={"boolean"}
        value={data.compoeRenda}
      />
      <StructuredField
        xs={12}
        lg={4}
        xl={3}
        label={"Cônjuge vendedor?"}
        type={"boolean"}
        value={data.conjugeVendedor}
      />
      <DadosProfissionais data={data} data2={data} dominios={dominios} />
    </>
  );
};

export default DadosOperacaoTab;
