import { styled, Table as MTable } from "@mui/material";

const Table = styled(MTable)`
  margin-top: 1.5rem;

  & .MuiTableHead-root .MuiTableCell-root {
    font-size: ${({ theme }) => theme.fonts.size.tables.header};
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-weight: 900;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.grey._400};
    padding: 0.75rem 0.5rem 0.75rem;
    border-bottom: dashed 1px ${({ theme }) => theme.colors.grey._100};
  }
`;

export default Table;
