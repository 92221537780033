import React, { useCallback } from "react";
import DocumentosPendentesDialog from "./DocumentosPendentesDialog";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentosPendentes } from "../../selectors/workflow.selectors";
import { actions } from "../../actions/workflow.actions";

const DocumentosPendentesDialogConnected = () => {
  const dispatch = useDispatch();
  const documentos = useSelector(getDocumentosPendentes);

  const handleClose = useCallback(() => {
    dispatch(actions.setDocumentosPendentes([]));
  }, [dispatch]);

  return (
    <DocumentosPendentesDialog
      open={documentos.length > 0}
      data={documentos}
      handleClose={handleClose}
    />
  );
};

export default DocumentosPendentesDialogConnected;
