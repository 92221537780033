import React from "react";
import { Dialog, DialogContent, Grid, IconButton, styled } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import { Button, Form, Title } from "../../components";
import {
  ControlledTextField,
  CurrencyTextField,
  DateTextField,
} from "../../components/inputs";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    width: 100%;
  }
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const FormLaudoAvaliacao = ({
  handleSubmit,
  tipoProduto,
  formProps,
  submitting,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ControlledTextField
            label="Data do Laudo"
            name={"dataLaudo"}
            formProps={formProps}
            InputProps={{
              inputComponent: DateTextField,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name={"valorImovel"}
            label={
              tipoProduto === "H" ? "Valor do Imóvel" : "Valor do Compra/Venda"
            }
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField,
            }}
            inputProps={{
              allowNegative: false,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name={"valorAvaliacao"}
            label={`Valor de Avaliação`}
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField,
            }}
            inputProps={{
              allowNegative: false,
            }}
          />
        </Grid>
        <Grid item container justifyContent={"center"}>
          <Grid item xs={12} md={8}>
            <Button loading={submitting} fullWidth type={"submit"}>
              ATUALIZAR
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

const LaudoAvaliacaoDialog = ({ handleClose, open, ...props }) => {
  return (
    <StyledDialog
      onClose={() => {}}
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
    >
      <DialogContent>
        <CloseIconButton onClick={handleClose}>
          <CloseSharp />
        </CloseIconButton>
        <Title as={"h2"} _margin_={"0 0 1rem 0"}>
          Laudo de Avaliação
        </Title>
        <FormLaudoAvaliacao {...props} />
      </DialogContent>
    </StyledDialog>
  );
};

export default LaudoAvaliacaoDialog;
