import PreenchimentoOperacaoPage from "./PreenchimentoOperacaoPage";
import { useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isCarregandoPreenchimentoOperacao,
} from "../../../selectors/operacao.selectors";

const PreenchimentoOperacaoPageConnected = () => {
  const loading = useSelector(isCarregandoPreenchimentoOperacao);
  const data = useSelector(getPreenchimentoOperacao);

  return <PreenchimentoOperacaoPage loading={loading} data={data} />;
};

export default PreenchimentoOperacaoPageConnected;
