import DadosClienteSection from "./DadosClienteSection";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState } from "react";
import { actions } from "../../actions/simulador.actions";
import useCompleteForm from "../../hooks/useCompleteForm";
import validators, { requiredValidation } from "../../utils/validators";
import {
  getUsuarioLogado,
  isUsuarioLogado,
} from "../../selectors/usuario.selectors";
import {
  getDadosCliente,
  getDominioParceiros,
  getSimuladorSelecionado,
  isProcurandoCliente,
  isProcurandoLead,
} from "../../selectors/simulador.selectors";
import {
  parseDateUsingFormat,
  removeNonDigitsFromString,
} from "../../utils/basic";
import { differenceInYears } from "date-fns";
import { isValid as isValidCpf } from "@fnando/cpf";
import { isValid as isValidCnpj } from "@fnando/cnpj";

const DadosClienteSectionConnected = () => {
  const dispatch = useDispatch();
  const { data: parceiros } = useSelector(getDominioParceiros);
  const data = useSelector(getSimuladorSelecionado);
  const searching = useSelector(isProcurandoCliente);
  const searchingLead = useSelector(isProcurandoLead);
  const dadosCliente = useSelector(getDadosCliente);

  const possuiUsuario = useSelector(isUsuarioLogado);
  const usuario = useSelector(getUsuarioLogado);
  const exibeParceiro = possuiUsuario && !usuario.idParceiro;

  const [outroParticipante, setOutroParticipante] = useState(
    dadosCliente?.outroParticipante ?? false
  );

  const successCallback = useCallback(
    (values) => {
      dispatch(
        actions.atualizarDadosCliente.request({
          identificadorParceiro: values.identificadorParceiro,
          cpfCnpj: values.cpfCnpj,
          nomeCliente: values.nomeCliente,
          dataNascimento: values.dataNascimento,
          renda: values.renda,
          email: values.email,
          telefone: values.telefone,
        })
      );
      if (values.outroParticipante) {
        dispatch(
          actions.atualizarDadosCliente.request({
            identificadorParceiro: values.identificadorParceiro,
            cpfCnpj: values.cpfCnpj2,
            nomeCliente: values.nomeCliente2,
            dataNascimento: values.dataNascimento2,
            renda: values.renda2,
            telefone: values.telefone2,
          })
        );
      }
      dispatch(actions.armazenarDadosCliente(values));
    },
    [dispatch]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: (form) => ({
      identificadorParceiro: validators.object({ required: exibeParceiro }),
      cpfCnpj: validators.cpfCnpj({ required: true }),
      nomeCliente: validators.string({
        required: true,
        maxLength: 100,
        custom: {
          nomeCompleto: (value) =>
            !value ||
            (value.indexOf(" ") > 0 &&
              value.substring(value.indexOf(" ") + 1).length > 0) ||
            "Nome informado deve ser completo",
        },
      }),
      dataNascimento: validators.date({
        required: true,
        custom: {
          minimo18: (value) => {
            if (!value) return true;
            const date = parseDateUsingFormat(value, "dd/MM/yyyy");
            if (!!date) {
              const anos = differenceInYears(new Date(), date);
              return (
                anos >= 18 || "Calculadora indisponível para menores de 18 anos"
              );
            }
            return true;
          },
        },
      }),
      telefone: validators.phone({ required: true }),
      email: validators.email({ required: true }),
      renda: validators.number({ required: true }),
      cpfCnpj2: validators.cpfCnpj({
        custom: {
          outroParticipante: (value) => {
            const possuiOutroParticipante = form.getValues("outroParticipante");
            return !possuiOutroParticipante || requiredValidation(value);
          },
        },
      }),
      nomeCliente2: validators.string({
        maxLength: 100,
        custom: {
          outroParticipante: (value) => {
            const possuiOutroParticipante = form.getValues("outroParticipante");
            return !possuiOutroParticipante || requiredValidation(value);
          },
          nomeCompleto: (value) =>
            !value ||
            (value.indexOf(" ") > 0 &&
              value.substring(value.indexOf(" ") + 1).length > 0) ||
            "Nome informado deve ser completo",
        },
      }),
      dataNascimento2: validators.date({
        custom: {
          somentePf: (value) => {
            const possuiOutroParticipante = form.getValues("outroParticipante");
            if (
              !!possuiOutroParticipante &&
              !!data &&
              data.tipoPessoa === "F"
            ) {
              return !!value || "Campo obrigatório";
            }
            return true;
          },
          minimo18: (value) => {
            if (!value) return true;
            const date = parseDateUsingFormat(value, "dd/MM/yyyy");
            if (!!date) {
              const anos = differenceInYears(new Date(), date);
              return (
                anos >= 18 || "Calculadora indisponível para menores de 18 anos"
              );
            }
            return true;
          },
        },
      }),
      telefone2: validators.phone({
        custom: {
          outroParticipante: (value) => {
            const possuiOutroParticipante = form.getValues("outroParticipante");
            return !possuiOutroParticipante || requiredValidation(value);
          },
        },
      }),
      renda2: validators.number({
        custom: {
          outroParticipante: (value) => {
            const possuiOutroParticipante = form.getValues("outroParticipante");
            return !possuiOutroParticipante || requiredValidation(value);
          },
        },
      }),
    }),
    initialValues: () => ({
      identificadorParceiro: null,
      cpfCnpj: "",
      nomeCliente: "",
      dataNascimento: "",
      telefone: "",
      email: "",
      renda: "",
      outroParticipante: false,
      cpfCnpj2: "",
      nomeCliente2: "",
      dataNascimento2: "",
      telefone2: "",
      renda2: "",
      ...dadosCliente,
    }),
    handleSubmit: successCallback,
  });

  const changeCallbackCompleto = useCallback(
    (values) => {
      formProps.setValue("nomeCliente", values?.nomeCliente || "");
      formProps.setValue("dataNascimento", values?.dataNascimento || "");
      formProps.setValue("telefone", values?.telefone || "");
      formProps.setValue("email", values?.email || "");
      formProps.setValue("renda", values?.rendaCliente || "");
      formProps.setValue("outroParticipante", values?.compoeRenda || false);
      setOutroParticipante(values?.compoeRenda || false);

      if (values?.compoeRenda) {
        formProps.setValue("cpfCnpj2", values?.cpfCnpjComplemento || "");
        formProps.setValue(
          "nomeCliente2",
          values?.nomeClienteComplemento || ""
        );
        formProps.setValue(
          "dataNascimento2",
          values?.dataNascimentoComplemento || ""
        );
        formProps.setValue("telefone2", values?.telefoneComplemento || "");
        formProps.setValue("renda2", values?.rendaClienteComplemento || "");
      } else {
        formProps.setValue("cpfCnpj2", "");
        formProps.setValue("nomeCliente2", "");
        formProps.setValue("dataNascimento2", "");
        formProps.setValue("telefone2", "");
        formProps.setValue("renda2", "");
      }
    },
    [formProps, setOutroParticipante]
  );
  const changeCallbackSimples = useCallback(
    (values) => {
      formProps.setValue("nomeCliente", values?.nome || "");
      formProps.setValue("dataNascimento", values?.dataNascimento || "");
      formProps.setValue("telefone", values?.telefone || "");
      formProps.setValue("email", values?.email || "");
      formProps.setValue("renda", values?.renda || "");
    },
    [formProps]
  );
  const changeCallbackConjuge = useCallback(
    (values) => {
      formProps.setValue("nomeCliente2", values?.nome || "");
      formProps.setValue("dataNascimento2", values?.dataNascimento || "");
      formProps.setValue("telefone2", values?.telefone || "");
      formProps.setValue("renda2", values?.renda || "");
    },
    [formProps]
  );

  const verificarValores = useCallback(() => {
    const cpfCnpj = formProps.getValues("cpfCnpj");
    const strippedCpfCnpj = removeNonDigitsFromString(cpfCnpj);

    const validCpfCnpj =
      (!!strippedCpfCnpj &&
        strippedCpfCnpj.length === 11 &&
        isValidCpf(strippedCpfCnpj)) ||
      (strippedCpfCnpj.length === 14 && isValidCnpj(strippedCpfCnpj));

    const parceiro = exibeParceiro
      ? formProps.getValues("identificadorParceiro")?.id
      : usuario.idParceiro;

    if (!!validCpfCnpj && !!parceiro) {
      dispatch(
        actions.recuperarLead.request({
          cpfCnpj: strippedCpfCnpj,
          parceiro: parceiro,
          callback: changeCallbackCompleto,
          callbackSimples: changeCallbackSimples,
        })
      );
    }
  }, [
    dispatch,
    formProps,
    exibeParceiro,
    usuario,
    changeCallbackCompleto,
    changeCallbackSimples,
  ]);

  const handleChangeCliente = useCallback(() => {
    verificarValores();
  }, [verificarValores]);

  const handleChangeConjuge = useCallback(() => {
    const cpfCnpj = formProps.getValues("cpfCnpj2");
    const strippedCpfCnpj = removeNonDigitsFromString(cpfCnpj);

    const validCpfCnpj =
      (!!strippedCpfCnpj &&
        strippedCpfCnpj.length === 11 &&
        isValidCpf(strippedCpfCnpj)) ||
      (strippedCpfCnpj.length === 14 && isValidCnpj(strippedCpfCnpj));

    const parceiro = exibeParceiro
      ? formProps.getValues("identificadorParceiro")?.id
      : usuario.idParceiro;

    if (!!validCpfCnpj && !!parceiro) {
      dispatch(
        actions.recuperarCliente.request({
          cpfCnpj: strippedCpfCnpj,
          parceiro: parceiro,
          callback: changeCallbackConjuge,
        })
      );
    }
  }, [dispatch, formProps, usuario, changeCallbackConjuge, exibeParceiro]);

  const handleGoBack = useCallback(() => {
    dispatch(actions.navegar(0));
  }, [dispatch]);

  return (
    <DadosClienteSection
      tipoPessoa={data.tipoPessoa}
      formProps={formProps}
      handleGoBack={handleGoBack}
      handleSubmit={handleSubmit}
      exibeParceiro={exibeParceiro}
      domainParceiros={parceiros}
      searching={searching}
      submitting={searchingLead}
      handleChangeCliente={handleChangeCliente}
      handleChangeConjuge={handleChangeConjuge}
      dadosCliente={dadosCliente}
      outroParticipante={outroParticipante}
      setOutroParticipante={setOutroParticipante}
    />
  );
};

export default DadosClienteSectionConnected;
