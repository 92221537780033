import DetalhesOperacaoBlock from "./DetalhesOperacaoBlock";
import {useDispatch, useSelector} from "react-redux";
import {
  getDetalhesOperacao,
  getDominiosOperacao,
} from "../../../selectors/operacao.selectors";
import { getPerfil } from "../../../selectors/usuario.selectors";
import {useCallback} from "react";
import {actions as documentoActions} from "../../../actions/documentos.actions";

const DetalhesOperacaoBlockConnected = () => {
  const dispatch = useDispatch();
  const data = useSelector(getDetalhesOperacao);
  const dominios = useSelector(getDominiosOperacao);
  const perfil = useSelector(getPerfil);

  const handleAlterarValoresOperacao = useCallback(async ()=>{
    dispatch(
      documentoActions.exibirValidadeCredito(true, {
        id:null,
        files: null,
        operacao:data,
      })
    );
  },[dispatch,data])

  return (
    <DetalhesOperacaoBlock
      data={data}
      perfil={perfil}
      dominios={dominios}
      handleAlterarValoresOperacao={handleAlterarValoresOperacao}
    />
  );
};

export default DetalhesOperacaoBlockConnected;
