import { Grid, InputAdornment } from "@mui/material";
import React from "react";
import { Button, Card, Form, GoBack, Title } from "../../../../../../components";
import {
  ControlledTextField,
  CpfCnpjTextField,
  DateTextField,
  PercentTextField,
  PhoneTextField,
} from "../../../../../../components/inputs";
import Stepper from "../../../../preenchimento/Stepper";
import ControlledAutocomplete from "../../../../../../components/inputs/ControlledAutocomplete";
import { HorizontalLoader } from "../../../../../../components/loaders";

const DadosBasicosSociosBradescoForm = ({
  fluxo,
  tipoPessoa,
  formProps,
  handleSubmit,
  cpfCnpjDisabled,
  handleCpfCnpjChange,
  searching,
  submitting,
  events,
  makeGoTo,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={makeGoTo(0)}
          text={"Preencha as informações do proponente"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={0} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container marginBottom={12}>
          <Grid item>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>
                      {tipoPessoa === "F"
                        ? "Dados pessoais"
                        : "Dados da Empresa"}
                    </Title>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ControlledTextField
                      label={tipoPessoa === "F" ? "CPF" : "CNPJ"}
                      name={"cpfCnpj"}
                      disabled={cpfCnpjDisabled}
                      formProps={formProps}
                      onBlur={handleCpfCnpjChange}
                      type={tipoPessoa === "F" ? "cpf" : "cnpj"}
                      InputProps={{
                        inputComponent: CpfCnpjTextField,
                        endAdornment: !!searching && (
                          <InputAdornment position="end">
                            <HorizontalLoader size={28} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ControlledTextField
                      label={
                        tipoPessoa === "F" ? "Nome Completo" : "Razão Social"
                      }
                      name={"nome"}
                      inputProps={{ maxLength: 100 }}
                      formProps={formProps}
                    />
                  </Grid>
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosBasicosSociosBradescoForm;
