import DetalhesParceiroPage from "./DetalhesParceiroPage";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetalhesParceiro,
  getDialogosDisponiveis,
  isCarregandoDetalhesParceiro,
  getQuantidadeDocumentosPendentesTotal,
} from "../../selectors/parceiro.selectors";
import { useCallback, useState } from "react";
import { actions } from "../../actions/parceiro.actions";
import { DETAIL_TABS } from "../../constants/generic.constants";
import { actions as workflowActions } from "../../actions/workflow.actions";
import { getUsuarioLogado } from "../../selectors/usuario.selectors";
import { removeNonDigitsFromString } from "../../utils/basic";

const DetalhesParceiroPageConnected = () => {
  const dispatch = useDispatch();
  const loading = useSelector(isCarregandoDetalhesParceiro);
  const data = useSelector(getDetalhesParceiro);
  const dialogosDisponiveis = useSelector(getDialogosDisponiveis);
  const documentosPendentes = useSelector(
    getQuantidadeDocumentosPendentesTotal
  );
  const usuario = useSelector(getUsuarioLogado);

  const [tab, setTab] = useState("0");

  const loadCommentaries = useCallback(() => {
    dispatch(
      workflowActions.carregarComentarios.request({
        id: data?.fluxoTrabalho?.id,
      })
    );
  }, [dispatch, data]);

  const handleTabChange = useCallback(
    (event, newValue) => {
      setTab(newValue);

      if (newValue === DETAIL_TABS.COMENTARIOS) {
        loadCommentaries();
      }
    },
    [setTab, loadCommentaries]
  );

  const contexto = {
    id_parceiro: data?.id,
  };

  const handleAcaoCallback = useCallback(
    (p) => {
      dispatch(actions.refresh.request({ ...p, id: data?.id }));
    },
    [dispatch, data]
  );

  const handlePhoneClick = useCallback((phone) => {
    window.open(`https://wa.me/${removeNonDigitsFromString(phone)}`);
  }, []);

  return (
    <DetalhesParceiroPage
      tab={tab}
      handleTabChange={handleTabChange}
      loading={loading}
      data={data}
      contexto={contexto}
      handleAcaoCallback={handleAcaoCallback}
      dialogosDisponiveis={dialogosDisponiveis}
      documentosPendentes={documentosPendentes}
      usuario={usuario}
      handlePhoneClick={handlePhoneClick}
    />
  );
};

export default DetalhesParceiroPageConnected;
