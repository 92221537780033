import DadosOperacaoTab from "./DadosOperacaoTab";
import { useSelector } from "react-redux";
import {
  getDetalhesOperacao,
  getDominiosOperacao,
} from "../../selectors/operacao.selectors";
import { useCallback, useState } from "react";
import { getPerfil } from "../../selectors/usuario.selectors";

const DadosOperacaoTabConnected = () => {
  const data = useSelector(getDetalhesOperacao);
  const dominios = useSelector(getDominiosOperacao);
  const perfil = useSelector(getPerfil);

  const [numeroProposta, setNumeroProposta] = useState({
    open: false,
  });
  const handleCloseNumeroProposta = useCallback(() => {
    setNumeroProposta((v) => ({
      ...v,
      open: false,
    }));
  }, []);
  const handleOpenNumeroProposta = useCallback((values) => {
    setNumeroProposta({
      open: true,
      ...values,
    });
  }, []);

  return (
    <DadosOperacaoTab
      data={data}
      perfil={perfil}
      dominios={dominios}
      numeroProposta={numeroProposta}
      handleCloseNumeroProposta={handleCloseNumeroProposta}
      handleOpenNumeroProposta={handleOpenNumeroProposta}
    />
  );
};

export default DadosOperacaoTabConnected;
