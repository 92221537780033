import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  CRIAR: "@@usuario/CRIAR",
  ATUALIZAR: "@@usuario/ATUALIZAR",
  LISTAR: "@@usuario/LISTAR",
  OBTER: "@@usuario/OBTER",
  OBTER_PARCEIRO: "@@usuario/OBTER_PARCEIRO",
  LISTAR_PARCEIROS: "@@usuario/LISTAR_PARCEIROS",
  LISTAR_USUARIOS_POR_PARCEIRO: "@@usuario/LISTAR_USUARIOS_POR_PARCEIROS",
  EXIBIR_DIALOG_COMPARTILHAMENTO: "@@usuario/EXIBIR_DIALOG_COMPARTILHAMENTO",
  OBTER_PARCEIROS_SUBSTABELECIDOS_POR_USUARIO: "@@usuario/OBTER_PARCEIROS_SUBSTABELECIDOS_POR_USUARIO"
};

export const actions = {
  criarUsuario: createAsyncAction(types.CRIAR),
  atualizarUsuario: createAsyncAction(types.ATUALIZAR),
  listarUsuarios: createAsyncAction(types.LISTAR),
  listarUsuariosPorParceiro: createAsyncAction(types.LISTAR_USUARIOS_POR_PARCEIRO),
  obterUsuario: createAsyncAction(types.OBTER),
  obterParceirosSubstabelecidosPorUsuario: createAsyncAction(types.OBTER_PARCEIROS_SUBSTABELECIDOS_POR_USUARIO),
  obterParceiro: createAsyncAction(types.OBTER_PARCEIRO),
  listarParceiros: createAsyncAction(types.LISTAR_PARCEIROS),
  exibirDialogCompartilhamento: (show, data) => createSyncAction(types.EXIBIR_DIALOG_COMPARTILHAMENTO, { show, data })
};
