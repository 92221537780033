import basicFlow from "./asyncHandler";
import { put, select, take } from "redux-saga/effects";
import { actions } from "../actions/simulador.actions";
import {
  authenticatedRequest,
  destinies,
  unauthenticatedRequest,
} from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../actions/rotas.actions";
import formatters from "../utils/formatters";
import {
  getFileNameFromHeader,
  parseDateUsingFormat,
  transformFromDate,
  transformToDate,
  transformToDateTime,
} from "../utils/basic";
import { getUsuarioLogado } from "../selectors/usuario.selectors";
import {
  getRouteArguments,
  getRouteQueryParams,
} from "../selectors/routes.selectors";
import {
  getDadosCliente,
  getDominioParceiros,
  getRegrasSelecionadasCompletas,
  getResultadoSimulador,
  getSimuladoresDisponiveis,
  getSimuladorSelecionado,
} from "../selectors/simulador.selectors";
import { saveAs } from "file-saver";

const buscarSimuladores = basicFlow({
  actionGenerator: actions.buscarSimuladores,
  api: () => {
    return authenticatedRequest({
      url: "/simuladores",
      destiny: destinies.SIMULADOR,
      method: "get",
    });
  },
});

const calcular = basicFlow({
  actionGenerator: actions.calcular,
  transform: function* ({ callback, ...values }) {
    const usuario = yield select(getUsuarioLogado);
    const queryParams = yield select(getRouteQueryParams);
    const dadosCliente = yield select(getDadosCliente);
    const { id } = yield select(getResultadoSimulador);

    const headers = {
      "X-Identificador-Parceiro": !usuario
        ? queryParams.parceiro
        : dadosCliente.identificadorParceiro?.id,
    };

    const outrosParticipantes = !!dadosCliente.outroParticipante
      ? [
          {
            nomeCliente: dadosCliente.nomeCliente2,
            cpfCnpjCliente: dadosCliente.cpfCnpj2,
            dataNascimento: !!dadosCliente.dataNascimento2
              ? formatters.dates.iso(
                  parseDateUsingFormat(
                    dadosCliente.dataNascimento2,
                    "dd/MM/yyyy"
                  )
                )
              : null,
            telefoneCliente: dadosCliente.telefone2,
            rendaCliente: dadosCliente.renda2,
          },
        ]
      : [];
    return {
      id,
      nomeCliente: dadosCliente.nomeCliente,
      cpfCnpjCliente: dadosCliente.cpfCnpj,
      dataNascimento: !!dadosCliente.dataNascimento
        ? formatters.dates.iso(
            parseDateUsingFormat(dadosCliente.dataNascimento, "dd/MM/yyyy")
          )
        : null,
      telefoneCliente: dadosCliente.telefone,
      emailCliente: dadosCliente.email,
      rendaCliente: dadosCliente.renda,
      outrosParticipantes,
      idProdutoTipoImovel: values.tipoImovel,
      ufImovel: values.ufImovel,
      valorImovel: values.valorImovel,
      valorFinanciamento: values.valorFinanciamento,
      portabilidade: values.portabilidade ?? false,
      prazoDesejado: values.prazoDesejado,
      financiaCustas: values.financiaCustas,
      financiaIof: values.financiaIof,
      financiaTarifaEmissao: values.financiaTarifaEmissao,
      financiaTarifaRegistro: values.financiaTarifaRegistro,
      headers,
      authenticated: !!usuario,
    };
  },
  api: ({ headers, authenticated, ...values }) => {
    if (authenticated) {
      return authenticatedRequest({
        url: "/simular",
        destiny: destinies.SIMULADOR,
        headers,
        method: "post",
        body: values,
      });
    } else {
      return unauthenticatedRequest({
        url: "/simular",
        destiny: destinies.SIMULADOR,
        headers,
        method: "post",
        body: values,
      });
    }
  },
  postSuccess: (props) => {
    if (props.original.callback) {
      props.original.callback(props);
    }
  },
});

const atualizarDadosCliente = basicFlow({
  actionGenerator: actions.atualizarDadosCliente,
  transform: function* ({ ...values }) {
    const usuario = yield select(getUsuarioLogado);
    return {
      ...values,
      identificadorParceiro: values.identificadorParceiro?.codigo,
      cpfCnpj: formatters.strings.cpfCnpj(values.cpfCnpj),
      dataNascimento: transformToDate(values.dataNascimento),
      authenticated: !!usuario,
    };
  },
  api: ({ authenticated, ...values }) => {
    return authenticated
      ? authenticatedRequest({
          url: "/cliente",
          destiny: destinies.CLIENTE,
          method: "put",
          body: values,
        })
      : unauthenticatedRequest({
          url: "/cliente",
          destiny: destinies.CLIENTE,
          method: "put",
          body: values,
        });
  },
});

const recuperarCliente = basicFlow({
  actionGenerator: actions.recuperarCliente,
  transform: function* ({ callback, ...values }) {
    const usuario = yield select(getUsuarioLogado);
    if (!usuario) {
      const queryParams = yield select(getRouteQueryParams);

      return {
        ...values,
        parceiro: queryParams.parceiro,
        authenticated: false,
      };
    }
    return {
      ...values,
      authenticated: true,
    };
  },
  api: ({ authenticated, ...values }) => {
    return authenticated
      ? authenticatedRequest({
          url: "/cliente",
          destiny: destinies.CLIENTE,
          method: "get",
          queryParams: values,
        })
      : unauthenticatedRequest({
          url: "/cliente",
          destiny: destinies.CLIENTE,
          method: "get",
          queryParams: values,
        });
  },
  preSuccess: ({ response }) => {
    if (response.data?.id) {
      response.data._dataNascimento = response.data.dataNascimento;
      response.data.dataNascimento = transformFromDate(
        response.data.dataNascimento
      );
    }
  },
  postSuccess: ({ original, response }) => {
    if (response.data?.id) {
      const { callback } = original;
      callback(response.data);
    }
  },
});

const recuperarLead = basicFlow({
  actionGenerator: actions.recuperarLead,
  transform: function* ({ callback, callbackSimples, ...values }) {
    const usuario = yield select(getUsuarioLogado);
    const { produto } = yield select(getSimuladorSelecionado);

    if (!usuario) {
      const queryParams = yield select(getRouteQueryParams);

      return {
        ...values,
        parceiro: queryParams.parceiro,
        produto,
        authenticated: false,
      };
    }
    return {
      ...values,
      produto,
      authenticated: true,
    };
  },
  api: ({ authenticated, ...values }) => {
    return authenticated
      ? authenticatedRequest({
          url: "/ultimo-lead",
          destiny: destinies.CLIENTE,
          method: "get",
          queryParams: values,
        })
      : unauthenticatedRequest({
          url: "/ultimo-lead",
          destiny: destinies.CLIENTE,
          method: "get",
          queryParams: values,
        });
  },
  preSuccess: ({ response }) => {
    if (response.data?.id) {
      response.data._dataNascimento = response.data.dataNascimento;
      response.data.dataNascimento = transformFromDate(
        response.data.dataNascimento
      );
      response.data._dataNascimentoComplemento =
        response.data.dataNascimentoComplemento;
      response.data.dataNascimentoComplemento = transformFromDate(
        response.data.dataNascimentoComplemento
      );
      response.data._tipoImovel = response.data.tipoImovel;
      response.data.tipoImovel = response.data.produtoTipoImovel;
    } else {
      response.data = null;
    }
  },
  postSuccess: function* ({ original, values, response }) {
    if (response.data?.id) {
      const { callback } = original;
      callback(response.data);
    } else {
      const { callbackSimples } = original;
      yield put(
        actions.recuperarCliente.request({
          ...values,
          callback: callbackSimples,
        })
      );
    }
  },
});

const validarRegrasSelecionadas = basicFlow({
  actionGenerator: actions.validarRegrasSelecionadas,
  transform: function* (payload) {
    const usuario = yield select(getUsuarioLogado);
    const { id } = yield select(getResultadoSimulador);

    return {
      lead: id,
      regrasComerciais: payload.selecionados.map((r) => r.id),
      authenticated: !!usuario,
    };
  },
  api: ({ authenticated, ...values }) => {
    return authenticated
      ? authenticatedRequest({
          url: "/validar",
          destiny: destinies.SIMULADOR,
          method: "post",
          body: values,
        })
      : unauthenticatedRequest({
          url: "/validar",
          destiny: destinies.SIMULADOR,
          method: "post",
          body: values,
        });
  },
  postSuccess: ({ original }) => {
    const { callback } = original;
    callback();
  },
});

const obterParceiros = basicFlow({
  actionGenerator: actions.obterParceiros,
  api: () => {
    return authenticatedRequest({
      url: "/dominios/parceiros",
      destiny: destinies.PARCEIRO,
      method: "get",
    });
  },
});

const recalcular = basicFlow({
  actionGenerator: actions.recalcular,
  transform: function* (payload) {
    const usuario = yield select(getUsuarioLogado);
    const queryParams = yield select(getRouteQueryParams);

    const headers = !usuario
      ? {
          "X-Identificador-Parceiro": queryParams.parceiro,
        }
      : undefined;

    const {
      resultado: { entrada },
    } = yield select(getResultadoSimulador);

    return {
      entrada,
      ...payload,
      headers,
      authenticated: !!usuario,
    };
  },
  api: ({ headers, authenticated, ...values }) => {
    if (authenticated) {
      return authenticatedRequest({
        url: "/resimular",
        destiny: destinies.SIMULADOR,
        headers,
        method: "post",
        body: values,
      });
    } else {
      return unauthenticatedRequest({
        url: "/resimular",
        destiny: destinies.SIMULADOR,
        headers,
        method: "post",
        body: values,
      });
    }
  },
});

const extratoSimulacao = basicFlow({
  actionGenerator: actions.extratoSimulacao,
  transform: function* (payload) {
    const usuario = yield select(getUsuarioLogado);
    const queryParams = yield select(getRouteQueryParams);

    const headers = !usuario
      ? {
          "X-Identificador-Parceiro": queryParams.parceiro,
        }
      : undefined;

    const {
      resultado: { entrada },
    } = yield select(getResultadoSimulador);

    return {
      entrada,
      ...payload,
      headers,
      authenticated: !!usuario,
    };
  },
  api: ({ headers, authenticated, ...values }) => {
    if (authenticated) {
      return authenticatedRequest({
        url: "/download",
        destiny: destinies.SIMULADOR,
        responseType: "arraybuffer",
        headers,
        method: "post",
        body: values,
      });
    } else {
      return unauthenticatedRequest({
        url: "/download",
        destiny: destinies.SIMULADOR,
        responseType: "arraybuffer",
        headers,
        method: "post",
        body: values,
      });
    }
  },
  postSuccess: ({ response }) => {
    const fileName = getFileNameFromHeader(
      response.headers,
      "content-disposition"
    );

    if (!!fileName) {
      saveAs(new Blob([response.data]), fileName);
    }
  },
});

const comecarProposta = basicFlow({
  actionGenerator: actions.comecarProposta,
  transform: function* ({ id, correntista }) {
    const dadosCliente = yield select(getDadosCliente);
    const { id: lead, entrada } = yield select(getResultadoSimulador);

    const { produto, tipoPessoa } = yield select(getSimuladorSelecionado);

    const regrasSelecionadas = yield select(getRegrasSelecionadasCompletas);

    const outroParticipante = !!dadosCliente.outroParticipante
      ? {
          nomeClienteComplemento: dadosCliente.nomeCliente2,
          cpfCnpjClienteComplemento: dadosCliente.cpfCnpj2,
          dataNascimentoComplemento: !!dadosCliente.dataNascimento2
            ? formatters.dates.iso(
                parseDateUsingFormat(dadosCliente.dataNascimento2, "dd/MM/yyyy")
              )
            : null,
          telefoneClienteComplemento: dadosCliente.telefone2,
          rendaClienteComplemento: dadosCliente.renda2,
        }
      : {};

    return {
      id: lead,
      nomeCliente: dadosCliente.nomeCliente,
      cpfCnpjCliente: dadosCliente.cpfCnpj,
      dataNascimento: !!dadosCliente.dataNascimento
        ? formatters.dates.iso(
            parseDateUsingFormat(dadosCliente.dataNascimento, "dd/MM/yyyy")
          )
        : null,
      telefoneCliente: dadosCliente.telefone,
      emailCliente: dadosCliente.email,
      rendaCliente: dadosCliente.renda,
      compoeRenda: !!dadosCliente.outroParticipante,
      ...outroParticipante,
      produto,
      tipoPessoa,
      ...entrada,
      regrasSelecionadas: regrasSelecionadas.map((r, i) => {
        return {
          regra: r.id,
          seguradora: r.entrada.seguradora,
          oferta: r.entrada.oferta,
          prazoCarencia: r.entrada.carencia,
          tipoAmortizacao: r.entrada.tipoAmortizacao,
          instituicao: r.instituicao,
          valorTotal: r.entrada.valorTotal,
          valorFinanciamento: r.entrada.valorFinanciamento,
          valorCustas: r.entrada.valorCustas,
          valorIof: r.entrada.valorIof,
          valorTarifaEmissao: r.entrada.valorTarifaEmissao,
          valorTarifaRegistro: r.entrada.valorTarifaRegistro,
          financiaCustas: r.entrada.financiaCustas,
          financiaIof: r.entrada.financiaIof,
          financiaTarifaEmissao: r.entrada.financiaTarifaEmissao,
          financiaTarifaRegistro: r.entrada.financiaTarifaRegistro,
          prazoDesejado: r.entrada.prazoDesejado,
          valorPrimeiraParcela: r.primeiraParcela,
          correntista: correntista[i],
          gerarProposta: r.id === id,
        };
      }),
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: "/efetivar-lead",
      destiny: destinies.CLIENTE,
      method: "post",
      body: values,
    });
  },
  postSuccess: function* ({ response }) {
    if (!!response.data?.id) {
      yield put(
        routeActions.redirectTo(routes.PREENCHIMENTO_OPERACAO, {
          id: response.data.id,
        })
      );
    }
  },
});

function* callbackLead(data) {
  const { lead } = data;
  const { data: basesDisponiveis } = yield select(getSimuladoresDisponiveis);
  const { data: parceiros } = yield select(getDominioParceiros);

  const produtoSelecionado = basesDisponiveis.find((b) => {
    const regra = b.regras.find((r) => {
      const tipoImovel = r.tiposImovel.find((t) => t.id === lead.tipoImovel);
      return !!tipoImovel;
    });
    return !!regra;
  });
  const regraSelecionada = produtoSelecionado.regras.find((r) => {
    const tipoImovel = r.tiposImovel.find((t) => t.id === lead.tipoImovel);
    return !!tipoImovel;
  });

  const parceiro = parceiros.find((p) => p.id === data.identificadorParceiro);

  const dadosCliente = {
    cpfCnpj: data.cpfCnpjCliente,
    cpfCnpj2: lead.cpfCnpjComplemento,
    dataNascimento: lead.dataNascimento,
    dataNascimento2: lead.dataNascimentoComplemento,
    email: lead.email,
    identificadorParceiro: parceiro,
    nomeCliente: lead.nomeCliente,
    nomeCliente2: lead.nomeClienteComplemento,
    outroParticipante: lead.compoeRenda,
    renda: lead.rendaCliente,
    renda2: lead.rendaClienteComplemento,
    telefone: lead.telefone,
    telefone2: lead.telefoneComplemento,
  };

  yield put(
    actions.restaurarLead({
      atual: 1,
      operacao: data.id,
      regraSelecionada,
      dadosCliente,
      lead,
    })
  );
}

function* simuladorRouteWatcher() {
  yield routeWatcher([routes.SIMULADOR], function* () {
    yield put(actions.buscarSimuladores.request());

    const usuario = yield select(getUsuarioLogado);

    if (!!usuario && !usuario.idParceiro) {
      yield put(actions.obterParceiros.request());
    }
  });
}

function* simuladorContinuacaoRouteWatcher() {
  yield routeWatcher([routes.SIMULADOR_CONTINUACAO], function* () {
    const payload = yield select(getRouteArguments);

    yield put(actions.buscarSimuladores.request());
    yield take([
      actions.buscarSimuladores.SUCCESS,
      actions.buscarSimuladores.FAILURE,
    ]);

    const usuario = yield select(getUsuarioLogado);

    if (!!usuario && !usuario.idParceiro) {
      yield put(actions.obterParceiros.request());
      yield take([
        actions.obterParceiros.SUCCESS,
        actions.obterParceiros.FAILURE,
      ]);
    }

    yield put(
      actions.obterLead.request({ ...payload, callback: callbackLead })
    );
  });
}

const obterLead = basicFlow({
  actionGenerator: actions.obterLead,
  transform: ({ callback, ...values }) => {
    return values;
  },
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/operacao/${id}`,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
  preSuccess: ({ response }) => {
    if (response.data?.id) {
      response.data.lead._dataNascimento = response.data.lead.dataNascimento;
      response.data.lead.dataNascimento = transformFromDate(
        response.data.lead.dataNascimento
      );
      response.data.lead._dataNascimentoComplemento =
        response.data.lead.dataNascimentoComplemento;
      response.data.lead.dataNascimentoComplemento = transformFromDate(
        response.data.lead.dataNascimentoComplemento
      );
      response.data.lead._tipoImovel = response.data.lead.tipoImovel;
      response.data.lead.tipoImovel = response.data.lead.produtoTipoImovel;
    } else {
      response.data = null;
    }
  },
  postSuccess: function* ({ original, response }) {
    const { callback } = original;

    if (!!callback) {
      yield callback(response.data);
    }
  },
});

export const sagas = [
  buscarSimuladores.watcher(),
  atualizarDadosCliente.watcher(),
  obterParceiros.watcher(),
  obterLead.watcher(),
  calcular.watcher(),
  recuperarCliente.watcher(),
  recalcular.watcher(),
  extratoSimulacao.watcher(),
  validarRegrasSelecionadas.watcher(),
  recuperarLead.watcher(),
  comecarProposta.watcher(),
  simuladorRouteWatcher(),
  simuladorContinuacaoRouteWatcher(),
];
