import React from "react";
import { styled } from "@mui/material";
import { transientOptions } from "../styles/theme";

const StyledForm = styled("form", transientOptions)`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: ${({ theme }) => theme.spacings.form};
`;

const Form = (props) => <StyledForm {...props} />;

export default Form;
