import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import QualificacaoEnvioContrato from "./QualificacaoEnvioContrato";
import { actions } from "../../../../../actions/operacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../../../actions/rotas.actions";
import { getRouteArguments } from "../../../../../selectors/routes.selectors";

const QualificacaoEnvioContratoConnected = ({
  handleGoTo5,
  events,
  data,
  submitting,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { operacaoConfirmacaoValores } = data;
  const { id } = useSelector(getRouteArguments);

  const callback = useCallback(() => {
    dispatch(routeActions.redirectTo(routes.DETALHES_OPERACAO, { id }));
  }, [dispatch, id]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      aceitaQualificacaoEntrega: validators.object({ required: true }),
      correcaoEntrega: validators.string({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        correcaoEntrega: "",
        ...operacaoConfirmacaoValores,
        aceitaQualificacaoEntrega:
          operacaoConfirmacaoValores.aceitaQualificacaoEntrega + "",
      }),
      [operacaoConfirmacaoValores]
    ),
    handleSubmit: useCallback(
      (values) => {
        return dispatch(
          actions.enviarConfirmacaoValores.request({
            callback,
            passo: "envio-contrato",
            instituicao: "itau",
            operacaoConfirmacaoValores: {
              idOperacao: values.idOperacao,
              aceitaQualificacaoEntrega: values.aceitaQualificacaoEntrega,
              correcaoEntrega:
                values.aceitaQualificacaoEntrega === "false"
                  ? values.correcaoEntrega
                  : null,
            },
          })
        );
      },
      [dispatch, callback]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      correcaoEntrega: "",
      ...operacaoConfirmacaoValores,
      aceitaQualificacaoEntrega:
        operacaoConfirmacaoValores.aceitaQualificacaoEntrega + "",
    });
  }, [operacaoConfirmacaoValores, reset]);

  return (
    <QualificacaoEnvioContrato
      {...otherProps}
      formProps={formProps}
      handleSubmit={handleSubmit}
      events={events}
      submitting={submitting}
    />
  );
};

export default QualificacaoEnvioContratoConnected;
