import React, { useCallback } from "react";
import DadosBasicosCompradorC6Form from "./DadosBasicosCompradorC6Form";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isBuscandoPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import {
  removeNonDigitsFromString,
  transformToDate,
} from "../../../../../utils/basic";
import formatters from "../../../../../utils/formatters";

const DadosBasicosCompradorC6FormConnected = ({
  setStep,
  eventsCompradorPf,
  eventsCompradorPj,
  participante: comprador,
  setParticipante,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { tipoEmpresa: tiposEmpresa },
  } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);
  const searching = useSelector(isBuscandoPreenchimentoOperacao);

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      setStep(2);
    },
    [setStep, setParticipante, comprador]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      cpfCnpj: validators.cpfCnpj({
        required: false,
        custom: {
          obrigatorio: (value) => {
            const c = operacao.participantes.find((p) => p.id === comprador.id);
            if (!!c.cpfCnpj) return true;
            if (value !== undefined && value !== null && value !== "") {
              return true;
            }
            return "Campo obrigatório";
          },
        },
      }),
      nome: validators.string({
        required: true,
        maxLength: 100,
        custom: {
          nomeCompleto: (value) =>
            !value ||
            (value.indexOf(" ") > 0 &&
              value.substring(value.indexOf(" ") + 1).length > 0) ||
            "Nome informado deve ser completo",
        },
      }),
      dataNascimento: validators.date({
        required: true,
        min: "01/01/1900",
        max: formatters.dates.short(new Date()),
      }),
      email: validators.email({ required: true }),
      telefone: validators.phone({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        cpfCnpj: "",
        nome: "",
        dataNascimento: "",
        email: "",
        telefone: "",
        ...comprador,
      }),
      [comprador]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-pessoais",
            participante: {
              id: comprador?.id,
              cpfCnpj: removeNonDigitsFromString(values.cpfCnpj),
              nomeParticipante: values.nome,
              dataNascimento: transformToDate(values.dataNascimento),
              email: values.email,
              telefone: values.telefone,
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, comprador, operacao, callback]
    ),
  });

  const { reset } = formProps;

  const successCallback = useCallback(
    (data) => {
      if (!!data) {
        const c = data.operacao.participantes.find(
          (p) => p.id === comprador.id
        );
        if (!!c) {
          setParticipante({
            ...c,
            fluxo: data.validacao.completa.participantes.find(
              (f) => f.id === c?.id
            )?.etapas,
          });
          reset({
            cpfCnpj: "",
            nome: "",
            dataNascimento: "",
            email: "",
            telefone: "",
            renda: "",
            ...c,
          });
        }
      }
    },
    [setParticipante, comprador, reset]
  );

  const handleCpfCnpjChange = useCallback(
    (v) => {
      if (!!v) {
        dispatch(
          actions.atualizarParticipantePreenchimento.request({
            id: comprador.id,
            cpfCnpj: removeNonDigitsFromString(v),
            tipo: "preenchimento",
            callback: successCallback,
          })
        );
      }
    },
    [dispatch, comprador, successCallback]
  );

  return (
    <DadosBasicosCompradorC6Form
      {...otherProps}
      tipoPessoa={comprador.tipoPessoa}
      tiposEmpresa={tiposEmpresa}
      formProps={formProps}
      handleSubmit={handleSubmit}
      handleCpfCnpjChange={handleCpfCnpjChange}
      searching={searching}
      cpfCnpjDisabled={!!comprador.cpfCnpj}
      submitting={submitting}
      fluxo={comprador.fluxo}
      events={eventsCompradorPf}
    />
  );
};

export default DadosBasicosCompradorC6FormConnected;
