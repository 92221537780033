import React, { useCallback, useEffect } from "react";
import LaudoAvaliacaoDialog from "./LaudoAvaliacaoDialog";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../actions/documentos.actions";
import useCompleteForm from "../../hooks/useCompleteForm";
import validators from "../../utils/validators";
import { getLaudoAvaliacao } from "../../selectors/documentos.selectors";
import { transformFromDate } from "../../utils/basic";

const LaudoAvaliacaoDialogConnected = () => {
  const dispatch = useDispatch();
  const { show, submitting, data } = useSelector(getLaudoAvaliacao);

  const handleClose = useCallback(() => {
    dispatch(actions.exibirLaudoAvaliacao(false, null));
  }, [dispatch]);

  const initialValues = useCallback(
    () => ({
      valorAvaliacao: "",
      valorImovel: "",
      ...data?.operacao?.imovel,
      dataLaudo: transformFromDate(data?.operacao?.imovel?.dataLaudo),
    }),
    [data]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: useCallback(
      () => ({
        dataLaudo: validators.date({ required: true }),
        valorImovel: validators.number({ required: true }),
        valorAvaliacao: validators.number({ required: true }),
      }),
      []
    ),
    initialValues,
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarLaudoAvaliacao.request({
            ...data,
            ...values,
            callback: handleClose,
          })
        );
      },
      [dispatch, data, handleClose]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset(initialValues());
  }, [show, reset, initialValues]);

  return (
    <LaudoAvaliacaoDialog
      open={show}
      tipoProduto={data?.operacao?.tipoProduto}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      handleClose={handleClose}
    />
  );
};

export default LaudoAvaliacaoDialogConnected;
