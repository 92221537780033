import { Grid, InputAdornment } from "@mui/material";
import React from "react";
import { Button, Card, Form, GoBack } from "../../../../components";
import ControlledAutocomplete from "../../../../components/inputs/ControlledAutocomplete";
import {
  ControlledSwitch,
  ControlledTextField,
  CpfCnpjTextField,
  DateTextField,
  PhoneTextField,
} from "../../../../components/inputs";
import Stepper from "../Stepper";
import { HorizontalLoader } from "../../../../components/loaders";
import UpperCaseTextField from "../../../../components/inputs/UpperCaseTextField";

const DadosEstadoCivilItauForm = ({
  estadosCivis,
  nacionalidades,
  events,
  fluxo,
  formProps,
  handleEstadoCivilChange,
  handleSubmit,
  exibeConjuge,
  exibeUniaoEstavel,
  handleUniaoEstavelChange,
  handleCpfCnpjChange,
  loadingConjuge,
  submitting,
  handleGoTo2,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} direction={"column"} maxWidth={704}>
        <GoBack
          handleClick={handleGoTo2}
          text={"Preencha as informações do comprador"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={2} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item width={1}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item container spacing={2}>
                    <Grid item xs={12} md={5}>
                      <ControlledAutocomplete
                        label="Estado Civil"
                        name={"estadoCivil"}
                        domain={estadosCivis}
                        idField={"id"}
                        labelField={"descricao"}
                        formProps={formProps}
                        autocompleteProps={{
                          clearText: "Limpar",
                          onChange: handleEstadoCivilChange,
                          loadingText: "Carregando opções",
                          noOptionsText: "Nenhuma opção disponível",
                        }}
                      />
                    </Grid>
                    {!!exibeUniaoEstavel && (
                      <Grid item xs={12} md={7}>
                        <ControlledSwitch
                          label="Convive em união estável?"
                          name={"uniaoEstavel"}
                          onChange={handleUniaoEstavelChange}
                          formProps={formProps}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {!!exibeConjuge && (
                    <>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label={"CPF do Cônjuge"}
                          name={"conjuge.cpfCnpj"}
                          formProps={formProps}
                          onBlur={handleCpfCnpjChange}
                          type={"cpf"}
                          InputProps={{
                            inputComponent: CpfCnpjTextField,
                            endAdornment: !!loadingConjuge && (
                              <InputAdornment position="end">
                                <HorizontalLoader size={28} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <ControlledTextField
                          label="Nome Completo do Cônjuge"
                          name={"conjuge.nome"}
                          inputProps={{ maxLength: 100 }}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: UpperCaseTextField,
                          }}
                        />
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <ControlledTextField
                            label={"Data de Nascimento"}
                            name={"conjuge.dataNascimento"}
                            formProps={formProps}
                            InputProps={{
                              inputComponent: DateTextField,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <ControlledAutocomplete
                            label="Nacionalidade"
                            name={"conjuge.nacionalidade"}
                            domain={nacionalidades}
                            idField={"id"}
                            labelField={"descricao"}
                            formProps={formProps}
                            autocompleteProps={{
                              clearText: "Limpar",
                              loadingText: "Carregando opções",
                              noOptionsText: "Nenhuma opção disponível",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <ControlledTextField
                            label="Celular"
                            name="conjuge.telefone"
                            formProps={formProps}
                            InputProps={{
                              inputComponent: PhoneTextField,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosEstadoCivilItauForm;
