import React from "react";
import {
  AccountTextField,
  ControlledTextField,
  IntegerTextField,
} from "../../components/inputs";
import { Grid } from "@mui/material";
import ControlledAutocomplete from "../../components/inputs/ControlledAutocomplete";
import validators from "../../utils/validators";
import { Title } from "../../components";

const formatWithCodeAndDescription = (option, domain) => {
  if (!!option) {
    const domainOption = domain.find(
      (d) => d.codigo === option.codigo || d.codigo === option
    );
    if (!!domainOption) {
      return `${domainOption.codigo} - ${domainOption.nome}`;
    }
  }
  return "";
};

export const formValues = {
  rules: (_, tipoEdicao) => ({
    banco: validators.object({ required: false }),
    agencia: validators.number({ required: false }),
    conta: validators.string({ required: false }),
    chavePix: validators.string({ required: false }),
  }),
  defaultValues: (data = {}) => ({
    banco: null,
    agencia: "",
    conta: "",
    chavePix: "",
    ...data,
  }),
};

const DadosBancariosForm = ({ formProps, domainBancos, forceDisabled }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Title as={"h5"}>TED/DOC</Title>
      </Grid>
      <Grid item xs={12}>
        <ControlledAutocomplete
          label="Banco"
          domain={domainBancos}
          idField={"codigo"}
          getOptionLabel={(option) =>
            formatWithCodeAndDescription(option, domainBancos)
          }
          name={"banco"}
          formProps={formProps}
          autocompleteProps={{
            disabled: forceDisabled,
            clearText: "Limpar",
            loadingText: "Carregando opções",
            noOptionsText: "Nenhuma opção disponível",
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <ControlledTextField
          label="Agência"
          name={"agencia"}
          disabled={forceDisabled}
          inputProps={{ maxLength: 4, allowLeadingZeros: true, asString: true }}
          formProps={formProps}
          InputProps={{
            inputComponent: IntegerTextField,
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <ControlledTextField
          label="Conta e Dígito"
          name={"conta"}
          disabled={forceDisabled}
          inputProps={{ maxLength: 15 }}
          formProps={formProps}
          InputProps={{
            inputComponent: AccountTextField,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Title as={"h5"}>PIX</Title>
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="Chave PIX"
          name={"chavePix"}
          disabled={forceDisabled}
          formProps={formProps}
        />
      </Grid>
    </Grid>
  );
};

export default DadosBancariosForm;
