import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  DOCUMENTOS_PENDENTES: "@@workflow/DOCUMENTOS_PENDENTES",

  EXIBIR_DOCUMENTO_MANUAL: "@@workflow/EXIBIR_DOCUMENTO_MANUAL",
  DOMINIOS_DOCUMENTO_MANUAL: "@@workflow/DOMINIOS_DOCUMENTO_MANUAL",
  GERAR_DOCUMENTO_OPERACAO_MANUAL: "@@workflow/GERAR_DOCUMENTO_OPERACAO_MANUAL",
  GERAR_DOCUMENTO_PARCEIRO_MANUAL: "@@workflow/GERAR_DOCUMENTO_PARCEIRO_MANUAL",

  CARREGAR_COMENTARIOS: "@@workflow/CARREGAR_COMENTARIOS",
  GRAVAR_COMENTARIO: "@@workflow/GRAVAR_COMENTARIO",
  ATUALIZAR_COMENTARIO: "@@workflow/ATUALIZAR_COMENTARIO",
  EXCLUIR_COMENTARIO: "@@workflow/EXCLUIR_COMENTARIO",
  DESTACAR_COMENTARIO: "@@workflow/DESTACAR_COMENTARIO",

  ATUAR_FLUXO_TRABALHO: "@@workflow/ATUAR_FLUXO_TRABALHO",
  CANCELAR_FLUXO_TRABALHO: "@@workflow/CANCELAR_FLUXO_TRABALHO",

  ATUALIZAR_NUMERO_PROPOSTA: "@@workflow/ATUALIZAR_NUMERO_PROPOSTA",
  ATUALIZAR_NUMERO_CONTRATO: "@@workflow/ATUALIZAR_NUMERO_CONTRATO",
  ATUALIZAR_DATA_EMISSAO: "@@workflow/ATUALIZAR_DATA_EMISSAO",
  ATUALIZAR_CODIGO_RASTREAMENTO: "@@workflow/ATUALIZAR_CODIGO_RASTREAMENTO",

  CANCELAR_PARCEIRO: "@@workflow/CANCELAR_PARCEIRO",
  SUSPENDER_PARCEIRO: "@@workflow/SUSPENDER_PARCEIRO",
  REATIVAR_PARCEIRO: "@@workflow/REATIVAR_PARCEIRO",

  EXIBIR_EDITAR_COMENTARIO: "@@workflow/EXIBIR_EDITAR_COMENTARIO",
};

export const actions = {
  setDocumentosPendentes: (payload) =>
    createSyncAction(types.DOCUMENTOS_PENDENTES, payload),

  exibirDocumentoManual: (payload) =>
    createSyncAction(types.EXIBIR_DOCUMENTO_MANUAL, payload),
  carregarDominiosDocumentoManual: createAsyncAction(
    types.DOMINIOS_DOCUMENTO_MANUAL
  ),
  gerarDocumentoOperacaoManual: createAsyncAction(
    types.GERAR_DOCUMENTO_OPERACAO_MANUAL
  ),
  gerarDocumentoParceiroManual: createAsyncAction(
    types.GERAR_DOCUMENTO_PARCEIRO_MANUAL
  ),

  carregarComentarios: createAsyncAction(types.CARREGAR_COMENTARIOS),
  gravarComentario: createAsyncAction(types.GRAVAR_COMENTARIO),
  atualizarComentario: createAsyncAction(types.ATUALIZAR_COMENTARIO),
  excluirComentario: createAsyncAction(types.EXCLUIR_COMENTARIO),
  destacarComentario: createAsyncAction(types.DESTACAR_COMENTARIO),

  atuarFluxoTrabalho: createAsyncAction(types.ATUAR_FLUXO_TRABALHO),
  cancelarFluxoTrabalho: createAsyncAction(types.CANCELAR_FLUXO_TRABALHO),

  atualizarNumeroProposta: createAsyncAction(types.ATUALIZAR_NUMERO_PROPOSTA),
  atualizarNumeroContrato: createAsyncAction(types.ATUALIZAR_NUMERO_CONTRATO),
  atualizarDataEmissao: createAsyncAction(types.ATUALIZAR_DATA_EMISSAO),
  atualizarCodigoRastreamento: createAsyncAction(types.ATUALIZAR_CODIGO_RASTREAMENTO),

  exibirEditarComentario: (show, data) => createSyncAction(types.EXIBIR_EDITAR_COMENTARIO, {show, data}),

  cancelarParceiro: createAsyncAction(types.CANCELAR_PARCEIRO),
  suspenderParceiro: createAsyncAction(types.SUSPENDER_PARCEIRO),
  reativarParceiro: createAsyncAction(types.REATIVAR_PARCEIRO),
};
