import { useDispatch, useSelector } from "react-redux";
import { getDetalhesParceiro } from "../../selectors/parceiro.selectors";
import ParceirosSubstabelecidosTab from "./ParceirosSubstabelecidosTab";
import { actions as parceiroActions } from "../../actions/parceiro.actions";
import { useCallback } from "react";

const ParceirosSubstabelecidosTabConnected = () => {
  const dispatch = useDispatch();
  const data = useSelector(getDetalhesParceiro);

  const handleDialogComissionamento = useCallback(
    (show, data) => {
      dispatch(parceiroActions.exibirDialogComissionamentoParceiro(show, data));
    },
    [dispatch, data]
  );

  return (
    <ParceirosSubstabelecidosTab
      data={data}
      handleDialogComissionamento={handleDialogComissionamento}
    />
  );
};

export default ParceirosSubstabelecidosTabConnected;
