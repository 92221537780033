import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  LOGIN: "@@auth/LOGIN",
  LOGIN_EXTERNO: "@@auth/LOGIN_EXTERNO",  
  OBTER_OPERACOES_DO_USUARIO: "@@auth/OBTER_OPERACOES_DO_USUARIO",
  LOGOUT: "@@auth/LOGOUT",
  ESQUECI_SENHA: "@@auth/ESQUECI_SENHA",
  TROCAR_SENHA: "@@auth/TROCAR_SENHA",
  VALIDAR_TOKEN_SENHA: "@@auth/VALIDAR_TOKEN_SENHA",
};

export const actions = {
  login: createAsyncAction(types.LOGIN),
  loginExterno: createAsyncAction(types.LOGIN_EXTERNO),
  obterOperacoesDoUsuario: createAsyncAction(types.OBTER_OPERACOES_DO_USUARIO),
  logout: () => createSyncAction(types.LOGOUT),
  esqueciSenha: createAsyncAction(types.ESQUECI_SENHA),
  trocarSenha: createAsyncAction(types.TROCAR_SENHA),
  validarTokenSenha: createAsyncAction(types.VALIDAR_TOKEN_SENHA),
};
