import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import QualificacaoValores from "./QualificacaoValores";
import { actions } from "../../../../../actions/operacao.actions";
import { getRouteArguments } from "../../../../../selectors/routes.selectors";
import {
  actions as routeActions,
  types as routes,
} from "../../../../../actions/rotas.actions";

const QualificacaoValoresConnected = ({
  handleGoTo1,
  events,
  data,
  submitting,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { operacaoConfirmacaoValores } = data;
  const { id } = useSelector(getRouteArguments);

  const handleGoBack = useCallback(() => {
    dispatch(routeActions.redirectTo(routes.DETALHES_OPERACAO, { id }));
  }, [dispatch, id]);

  const callback = useCallback(() => {
    handleGoTo1();
  }, [handleGoTo1]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      aceitaQualificacaoResumo: validators.object({ required: true }),
      correcaoResumo: validators.string({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        correcaoResumo: "",
        ...operacaoConfirmacaoValores,
        aceitaQualificacaoResumo:
          operacaoConfirmacaoValores.aceitaQualificacaoResumo + "",
      }),
      [operacaoConfirmacaoValores]
    ),
    handleSubmit: useCallback(
      (values) => {
        return dispatch(
          actions.enviarConfirmacaoValores.request({
            callback,
            passo: "valores",
            instituicao: "itau",
            operacaoConfirmacaoValores: {
              idOperacao: values.idOperacao,
              aceitaQualificacaoResumo: values.aceitaQualificacaoResumo,
              correcaoResumo:
                values.aceitaQualificacaoResumo === "false"
                  ? values.correcaoResumo
                  : null,
            },
          })
        );
      },
      [dispatch, callback]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      correcaoResumo: "",
      ...operacaoConfirmacaoValores,
      aceitaQualificacaoResumo:
        operacaoConfirmacaoValores.aceitaQualificacaoResumo + "",
    });
  }, [operacaoConfirmacaoValores, reset]);

  return (
    <QualificacaoValores
      {...otherProps}
      formProps={formProps}
      handleSubmit={handleSubmit}
      events={events}
      submitting={submitting}
      handleGoBack={handleGoBack}
    />
  );
};

export default QualificacaoValoresConnected;
