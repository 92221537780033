import React, { useCallback, useEffect, useState } from "react";
import DadosProfissionaisLibraForm from "./DadosProfissionaisLibraForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import {
  removeNonDigitsFromString,
  transformToDate,
} from "../../../../../utils/basic";
import formatters from "../../../../../utils/formatters";

const DadosProfissionaisLibraFormConnected = ({
  setStep,
  events,
  participante: comprador,
  setParticipante,
  ...props
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { perfilRenda: perfisRenda, profissao: profissoes },
  } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const { tipo: tipoPerfilRenda } =
    perfisRenda.find((e) => e.id === comprador?.perfilRenda?.id) ?? {};
  const [exibeCnpj, setExibeCnpj] = useState(tipoPerfilRenda === "EM" ?? false);

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      setStep(3);
    },
    [setStep, setParticipante, comprador]
  );

  const resetValues = useCallback(
    () => ({
      renda: "",
      perfilRenda: null,
      ...comprador,
      dadosProfissionais: {
        cnpjEmpresa: "",
        nomeEmpresa: "",
        ...comprador?.dadosProfissionais,
      },
    }),
    [comprador]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      renda: validators.number({ required: true }),
      perfilRenda: validators.object({ required: true }),
      dadosProfissionais: {
        cnpjEmpresa: validators.cnpj({ required: exibeCnpj }),
        nomeEmpresa: validators.string({ required: true }),
      },
    }),
    initialValues: resetValues,
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-profissionais",
            participante: {
              id: comprador?.id,
              renda: values.renda,
              idPerfilRenda: values.perfilRenda?.id,
              dadosProfissionais: {
                cnpjEmpresa: !exibeCnpj
                  ? null
                  : removeNonDigitsFromString(
                      values.dadosProfissionais.cnpjEmpresa
                    ),
                nomeEmpresa: values.dadosProfissionais.nomeEmpresa,
              },
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [callback, comprador, operacao, dispatch, exibeCnpj]
    ),
  });

  const handlePerfilRendaChange = useCallback(
    (v) => {
      setExibeCnpj(v?.tipo === "EM" ?? false);
    },
    [setExibeCnpj]
  );

  const { reset } = formProps;

  useEffect(() => {
    reset(resetValues());

    const { tipo: tipoPerfilRenda } =
      perfisRenda.find((e) => e.id === comprador?.perfilRenda?.id) ?? {};
    setExibeCnpj(tipoPerfilRenda === "EM" ?? false);
  }, [perfisRenda, comprador, resetValues, reset]);

  return (
    <DadosProfissionaisLibraForm
      {...props}
      perfisRenda={perfisRenda}
      profissoes={profissoes}
      fluxo={comprador.fluxo}
      events={events}
      exibeCnpj={exibeCnpj}
      formProps={formProps}
      handlePerfilRendaChange={handlePerfilRendaChange}
      handleSubmit={handleSubmit}
      submitting={submitting}
    />
  );
};

export default DadosProfissionaisLibraFormConnected;
