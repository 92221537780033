import React from "react";
import DadosBasicosCompradorFintechsFormConnected from "./comprador/DadosBasicosCompradorFintechsForm.connected";
import HubFintechsConnected from "./HubFintechs.connected";
import DadosProfissionaisCompradorFintechsFormConnected from "./comprador/DadosProfissionaisCompradorFintechsForm.connected";
import DadosEstadoCivilCompradorFintechsFormConnected from "./comprador/DadosEstadoCivilCompradorFintechsForm.connected";
import DadosEnderecoCompradorFintechsFormConnected from "./comprador/DadosEnderecoCompradorFintechsForm.connected";
import DadosEnderecoImovelFintechsFormConnected from "./imovel/DadosEnderecoImovelFintechsForm.connected";
import OutrasInformacoesImovelFintechsFormConnected from "./imovel/OutrasInformacoesImovelFintechsForm.connected";
import DocumentosFormConnected from "./documentos/DocumentosForm.connected";
import DadosBasicosOutrosProponentesFintechsFormConnected from "./outros/DadosBasicosOutrosProponentesFintechsForm.connected";
import DadosEstadoCivilOutrosProponentesFintechsFormConnected from "./outros/DadosEstadoCivilOutrosProponentesFintechsForm.connected";
import DadosEnderecoOutrosProponentesFintechsFormConnected from "./outros/DadosEnderecoOutrosProponentesFintechsForm.connected";
import DadosBasicosSociosFintechsFormConnected from "./outros/DadosBasicosSociosFintechsForm.connected";
import DadosSociosPjOutrosProponentesFintechsFormConnected from "./outros/DadosSociosPjOutrosProponentesFintechsForm.connected";
import DadosEstadoCivilSociosFintechsFormConnected from "./outros/DadosEstadoCivilSociosFintechsForm.connected";
import DadosSociosPjCompradorFintechsFormConnected from "./comprador/DadosSociosPjCompradorFintechsForm.connected";

const FluxoFintechs = (props) => {
  return (
    <>
      {props.step === 0 && <HubFintechsConnected {...props} />}
      {props.step === 1 && (
        <DadosBasicosCompradorFintechsFormConnected {...props} />
      )}
      {props.step === 2 && (
        <DadosEnderecoCompradorFintechsFormConnected {...props} />
      )}
      {props.step === 3 && (
        <DadosProfissionaisCompradorFintechsFormConnected {...props} />
      )}
      {props.step === 4 && (
        <DadosEstadoCivilCompradorFintechsFormConnected {...props} />
      )}
      {props.step === 5 && (
        <DadosSociosPjCompradorFintechsFormConnected {...props} />
      )}

      {props.step === 50 && (
        <DadosBasicosOutrosProponentesFintechsFormConnected {...props} />
      )}
      {props.step === 51 && (
        <DadosEnderecoOutrosProponentesFintechsFormConnected {...props} />
      )}
      {props.step === 52 && (
        <DadosEstadoCivilOutrosProponentesFintechsFormConnected {...props} />
      )}
      {props.step === 53 && (
        <DadosSociosPjOutrosProponentesFintechsFormConnected {...props} />
      )}
      {props.step === 60 && (
        <DadosBasicosSociosFintechsFormConnected {...props} />
      )}
      {props.step === 61 && (
        <DadosEstadoCivilSociosFintechsFormConnected {...props} />
      )}

      {props.step === 100 && (
        <DadosEnderecoImovelFintechsFormConnected {...props} />
      )}
      {props.step === 101 && (
        <OutrasInformacoesImovelFintechsFormConnected {...props} />
      )}
      {props.step === 999 && <DocumentosFormConnected {...props} />}
    </>
  );
};

export default FluxoFintechs;
