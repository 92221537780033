import { Grid } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  GoBack,
  MenuItem,
} from "../../../../../components";
import ControlledAutocomplete from "../../../../../components/inputs/ControlledAutocomplete";
import {
  ControlledRadioGroup,
  ControlledTextField,
  CurrencyTextField,
  DateTextField,
  IntegerTextField,
  Radio,
} from "../../../../../components/inputs";
import Stepper from "../../../preenchimento/Stepper";
import { PENSIONISTAS } from "../../../../../constants/generic.constants";
import UpperCaseTextField from "../../../../../components/inputs/UpperCaseTextField";

const DadosProfissionaisCompradorFintechsForm = ({
  perfilRenda,
  perfisRenda,
  profissoes,
  segmentosServico,
  ramosAtuacao,
  tipoPensionista,
  tiposPensao,
  tiposImovelAluguel,
  fluxo,
  events,
  formProps,
  handleSubmit,
  submitting,
  handleChangeTipoPensionista,
  makeGoTo,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} direction={"column"} maxWidth={704}>
        <GoBack
          handleClick={makeGoTo(2)}
          text={"Preencha as informações do comprador"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={2} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item width={1}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <ControlledAutocomplete
                      label="Perfil de Renda"
                      name={"perfilRenda"}
                      domain={perfisRenda}
                      idField={"id"}
                      labelField={"descricao"}
                      formProps={formProps}
                      autocompleteProps={{
                        clearText: "Limpar",
                        loadingText: "Carregando opções",
                        noOptionsText: "Nenhuma opção disponível",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="Renda Mensal"
                      name={"renda"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: CurrencyTextField,
                      }}
                      inputProps={{
                        allowNegative: false,
                      }}
                    />
                  </Grid>
                  {perfilRenda?.tipo === "EM" && (
                    <>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label={"Data de Abertura da Empresa"}
                          name={"dadosProfissionais.dataAdmissao"}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: DateTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <ControlledAutocomplete
                          label="Ramo de Atuação"
                          name={"dadosProfissionais.ramoAtuacao"}
                          domain={ramosAtuacao}
                          idField={"id"}
                          labelField={"descricao"}
                          formProps={formProps}
                          autocompleteProps={{
                            clearText: "Limpar",
                            loadingText: "Carregando opções",
                            noOptionsText: "Nenhuma opção disponível",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ControlledTextField
                          label="Faturamento Mensal da Empresa"
                          name={"dadosProfissionais.faturamentoMensal"}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: CurrencyTextField,
                          }}
                          inputProps={{
                            allowNegative: false,
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {perfilRenda?.tipo === "AS" && (
                    <>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label={"Data de Início"}
                          name={"dadosProfissionais.dataAdmissao"}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: DateTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <ControlledTextField
                          label="Cargo ou Profissão"
                          name={"dadosProfissionais.cargo"}
                          inputProps={{ maxLength: 100 }}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: UpperCaseTextField,
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {perfilRenda?.tipo === "PS" && (
                    <>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label={"Data de Início"}
                          name={"dadosProfissionais.dataAdmissao"}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: DateTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <ControlledAutocomplete
                          label="Segmento de Atuação"
                          name={"dadosProfissionais.segmentoServico"}
                          domain={segmentosServico}
                          idField={"id"}
                          labelField={"descricao"}
                          formProps={formProps}
                          autocompleteProps={{
                            clearText: "Limpar",
                            loadingText: "Carregando opções",
                            noOptionsText: "Nenhuma opção disponível",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <ControlledRadioGroup
                          label="Possui contrato de Prestação de Serviço?"
                          name={"dadosProfissionais.possuiContratoServico"}
                          row
                          formProps={formProps}
                        >
                          <Radio value={true} label={"Sim"} />
                          <Radio value={false} label={"Não"} />
                        </ControlledRadioGroup>
                      </Grid>
                    </>
                  )}
                  {perfilRenda?.tipo === "AP" && (
                    <>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label="Tipo"
                          name={"dadosProfissionais.tipoPensionista"}
                          select
                          onChange={handleChangeTipoPensionista}
                          formProps={formProps}
                        >
                          {Object.keys(PENSIONISTAS).map((k) => (
                            <MenuItem key={k} value={k}>
                              {PENSIONISTAS[k]}
                            </MenuItem>
                          ))}
                        </ControlledTextField>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <ControlledAutocomplete
                          label={
                            tipoPensionista === "AP"
                              ? "Aposentado"
                              : "Pensionista"
                          }
                          name={"dadosProfissionais.tipoPensao"}
                          domain={tiposPensao}
                          idField={"id"}
                          labelField={"descricao"}
                          formProps={formProps}
                          autocompleteProps={{
                            clearText: "Limpar",
                            loadingText: "Carregando opções",
                            noOptionsText: "Nenhuma opção disponível",
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {perfilRenda?.tipo === "PL" && (
                    <>
                      <Grid item xs={12} md={4}>
                        <ControlledTextField
                          label={"Data de Início"}
                          name={"dadosProfissionais.dataAdmissao"}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: DateTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <ControlledAutocomplete
                          label="Ramo de Atuação"
                          name={"dadosProfissionais.ramoAtuacao"}
                          domain={ramosAtuacao}
                          idField={"id"}
                          labelField={"descricao"}
                          formProps={formProps}
                          autocompleteProps={{
                            clearText: "Limpar",
                            loadingText: "Carregando opções",
                            noOptionsText: "Nenhuma opção disponível",
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {perfilRenda?.tipo === "AL" && (
                    <>
                      <Grid item xs={12} md={6}>
                        <ControlledAutocomplete
                          label="Tipo de Imóveis"
                          name={"dadosProfissionais.tipoImovelAluguel"}
                          domain={tiposImovelAluguel}
                          idField={"id"}
                          labelField={"descricao"}
                          formProps={formProps}
                          autocompleteProps={{
                            clearText: "Limpar",
                            loadingText: "Carregando opções",
                            noOptionsText: "Nenhuma opção disponível",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ControlledTextField
                          label={"Qtd. de Imóveis Locados"}
                          name={"dadosProfissionais.quantidadeImoveisLocados"}
                          formProps={formProps}
                          inputProps={{
                            maxLength: 3,
                            allowNegative: false,
                          }}
                          InputProps={{
                            inputComponent: IntegerTextField,
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosProfissionaisCompradorFintechsForm;
