import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  getPreenchimentoOperacao,
  isBuscandoPreenchimentoOperacao,
  isGravandoComplementoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../../hooks/useCompleteForm";
import validators from "../../../../../../utils/validators";
import { actions } from "../../../../../../actions/operacao.actions";
import {
  removeNonDigitsFromString,
  transformToDate,
} from "../../../../../../utils/basic";
import formatters from "../../../../../../utils/formatters";
import DadosBasicosSociosBradescoForm from "./DadosBasicosSociosBradescoForm";

const DadosBasicosSociosBradescoFormConnected = ({
  setStep,
  eventsSocios,
  participante,
  setParticipante,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { qualificacao: qualificacoes, tipoEmpresa: tiposEmpresa },
  } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);
  const searching = useSelector(isBuscandoPreenchimentoOperacao);
  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find(
        (p) => p.id === participante.id
      );
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      setStep(0);
    },
    [setStep, setParticipante, participante]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      cpfCnpj: validators.cpfCnpj({
        required: false,
        custom: {
          obrigatorio: (value) => {
            const c = operacao.participantes.find(
              (p) => p.id === participante.id
            );
            if (!!c.cpfCnpj) return true;
            if (value !== undefined && value !== null && value !== "") {
              return true;
            }
            return "Campo obrigatório";
          },
        },
      }),
      nome: validators.string({
        required: true,
        maxLength: 100,
        custom: {
          nomeCompleto: (value) =>
            !value ||
            (value.indexOf(" ") > 0 &&
              value.substring(value.indexOf(" ") + 1).length > 0) ||
            "Nome informado deve ser completo",
        },
      }),
    }),
    initialValues: useCallback(
      () => ({
        cpfCnpj: "",
        nome: "",
        ...participante,
      }),
      [participante]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-pessoais-socios-vendedor-pj",
            participante: {
              id: participante?.id,
              cpfCnpj: removeNonDigitsFromString(values.cpfCnpj),
              nomeParticipante: values.nome,
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, participante, operacao, callback]
    ),
  });

  const { reset } = formProps;

  const successCallback = useCallback(
    (data) => {
      if (!!data) {
        const c = data.operacao.participantes.find(
          (p) => p.id === participante.id
        );
        if (!!c) {
          setParticipante({
            ...c,
            fluxo: data.validacao.completa.participantes.find(
              (f) => f.id === c?.id
            )?.etapas,
          });
          reset({
            cpfCnpj: "",
            nome: "",
            ...c,
          });
        }
      }
    },
    [setParticipante, participante, reset]
  );

  const handleCpfCnpjChange = useCallback(
    (v) => {
      if (!!v) {
        dispatch(
          actions.atualizarParticipantePreenchimento.request({
            id: participante.id,
            cpfCnpj: removeNonDigitsFromString(v),
            tipo: "preenchimento",
            callback: successCallback,
          })
        );
      }
    },
    [dispatch, participante, successCallback]
  );

  return (
    <DadosBasicosSociosBradescoForm
      {...otherProps}
      tipoPessoa={participante.tipoPessoa}
      tiposEmpresa={tiposEmpresa}
      qualificacoes={qualificacoes}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      fluxo={participante.fluxo}
      handleCpfCnpjChange={handleCpfCnpjChange}
      searching={searching}
      cpfCnpjDisabled={!!participante.cpfCnpj}
      events={eventsSocios}
    />
  );
};

export default DadosBasicosSociosBradescoFormConnected;
