import React from "react";
import DadosBasicosBradescoFormConnected from "./DadosBasicosBradescoForm.connected";
import EscolhaParticipanteBradescoFormConnected from "./EscolhaParticipanteBradescoForm.connected";
import DadosProfissionaisBradescoFormConnected from "./DadosProfissionaisBradescoForm.connected";
import DadosEstadoCivilBradescoFormConnected from "./DadosEstadoCivilBradescoForm.connected";
import DadosEnderecoBradescoFormConnected from "./DadosEnderecoBradescoForm.connected";
import OutrasInformacoesBradescoFormConnected from "./OutrasInformacoesBradescoForm.connected";
import ReenquadrarOperacaoFormConnected from "../ReenquadrarOperacaoForm.connected";
import ConfirmarNovoCalculoConnected from "../ConfirmarNovoCalculo.connected";

const FluxoAquisicaoBradesco = (props) => {
  return (
    <>
      {props.step === 0 && (
        <EscolhaParticipanteBradescoFormConnected {...props} />
      )}
      {props.step === 1 && <DadosBasicosBradescoFormConnected {...props} />}
      {props.step === 2 && <DadosEnderecoBradescoFormConnected {...props} />}
      {props.step === 3 && (
        <DadosProfissionaisBradescoFormConnected {...props} />
      )}
      {props.step === 4 && <DadosEstadoCivilBradescoFormConnected {...props} />}
      {props.step === 5 && (
        <OutrasInformacoesBradescoFormConnected {...props} />
      )}
      {props.step === 995 && <ReenquadrarOperacaoFormConnected {...props} />}
      {props.step === 996 && <ConfirmarNovoCalculoConnected {...props} />}
    </>
  );
};

export default FluxoAquisicaoBradesco;
