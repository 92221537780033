import React, { useCallback, useEffect } from "react";
import DadosProfissionaisCompradorFintechsForm from "./DadosProfissionaisCompradorFintechsForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import { transformToDate } from "../../../../../utils/basic";
import { PENSIONISTAS } from "../../../../../constants/generic.constants";

const DadosProfissionaisCompradorFintechsFormConnected = ({
  setStep,
  eventsCompradorPf,
  setParticipante,
  participante: comprador,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: {
      perfilRenda: perfisRenda,
      profissao: profissoes,
      ramoAtuacao: ramosAtuacao,
      tipoPensao: tiposPensao,
      segmentoServico: segmentosServico,
      tipoImovelAluguel: tiposImovelAluguel,
    },
  } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const _perfilRenda = perfisRenda.find(
    (e) => e.id === comprador?.perfilRenda?.id
  );

  const resetValues = useCallback(
    () => ({
      renda: "",
      ...comprador,
      perfilRenda: _perfilRenda ?? null,
      dadosProfissionais: {
        cargo: null,
        ramoAtuacao: null,
        tipoPensao: null,
        segmentoServico: null,
        tipoImovelAluguel: null,
        dataAdmissao: "",
        faturamentoMensal: "",
        tipoPensionista: "",
        possuiContratoServico: null,
        quantidadeImoveisLocados: "",
        ...comprador?.dadosProfissionais,
      },
    }),
    [comprador, _perfilRenda]
  );

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      setStep(4);
    },
    [setStep, setParticipante, comprador]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      perfilRenda: validators.object({ required: true }),
      dadosProfissionais: {
        cargo: validators.string({ required: true }),
        dataAdmissao: validators.date({ required: true }),
      },
    }),
    initialValues: resetValues,
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-profissionais",
            participante: {
              id: comprador?.id,
              renda: values.renda,
              idPerfilRenda: values.perfilRenda?.id,
              dadosProfissionais: {
                cargo: values.dadosProfissionais.cargo,
                idRamoAtuacao: values.dadosProfissionais.ramoAtuacao?.id,
                idTipoPensao: values.dadosProfissionais.tipoPensao?.id,
                idSegmentoServico:
                  values.dadosProfissionais.segmentoServico?.id,
                tipoPensionista:
                  PENSIONISTAS[values.dadosProfissionais.tipoPensionista],
                idTipoImovelAluguel:
                  values.dadosProfissionais.tipoImovelAluguel?.id,
                faturamentoMensal: values.dadosProfissionais.faturamentoMensal,
                possuiContratoServico:
                  values.dadosProfissionais.possuiContratoServico,
                quantidadeImoveisLocados:
                  values.dadosProfissionais.quantidadeImoveisLocados,
                dataAdmissao: transformToDate(
                  values.dadosProfissionais.dataAdmissao
                ),
              },
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [callback, comprador, operacao, dispatch]
    ),
  });

  const { reset, watch, setValue } = formProps;

  const perfilRenda = watch("perfilRenda");
  const tipoPensionista = watch("dadosProfissionais.tipoPensionista");

  const handleChangeTipoPensionista = useCallback(() => {
    setValue("dadosProfissionais.tipoPensao", null);
  }, [setValue]);

  useEffect(() => {
    reset(resetValues());
  }, [perfisRenda, comprador, resetValues, reset]);

  return (
    <DadosProfissionaisCompradorFintechsForm
      {...otherProps}
      perfilRenda={perfilRenda}
      tipoPensionista={tipoPensionista}
      segmentosServico={segmentosServico}
      perfisRenda={perfisRenda}
      profissoes={profissoes}
      ramosAtuacao={ramosAtuacao}
      tiposPensao={tiposPensao.filter((t) => t.tipo === tipoPensionista)}
      tiposImovelAluguel={tiposImovelAluguel}
      fluxo={comprador.fluxo}
      events={eventsCompradorPf}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      handleChangeTipoPensionista={handleChangeTipoPensionista}
    />
  );
};

export default DadosProfissionaisCompradorFintechsFormConnected;
