import React, { useCallback } from "react";
import { styled } from "@mui/material";

const StyledLink = styled("span")`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Link = ({ onClick, ...otherProps }) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      onClick(e);
    },
    [onClick]
  );

  return <StyledLink {...otherProps} onClick={handleClick} />;
};

export default Link;
