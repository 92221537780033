import React, { useCallback } from "react";
import UltComentarioDialog from "./UltComentarioDialog";
import { useDispatch, useSelector } from "react-redux";
import { getDialogUltComentario, getListaOperacoes } from "../../selectors/operacao.selectors";
import { actions } from "../../actions/operacao.actions";

const UltComentarioDialogConnected = () => {
  const dispatch = useDispatch();
  const { show, data } = useSelector(getDialogUltComentario);

  const handleClose = useCallback(() => {
    dispatch(actions.exibirUltComentario(false, null));
  }, [dispatch]);

  return (
    <UltComentarioDialog
      open={show}
      data={data}
      handleClose={handleClose}
    />
  );
};

export default UltComentarioDialogConnected;