import { Grid } from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  GoBack,
  MenuItem,
  Title,
} from "../../../../../components";
import {
  ControlledAutoComplete,
  ControlledSelect,
  ControlledSwitch,
  ControlledTextField,
  CurrencyTextField,
  IntegerTextField,
  PercentTextField,
  PhoneTextField,
} from "../../../../../components/inputs";
import Stepper from "../../../preenchimento/Stepper";

const formatWithCodeAndDescription = (option, domain) => {
  if (!!option) {
    const domainOption = domain.find(
      (d) => d.id === option.id || d.id === option
    );
    if (!!domainOption) {
      return `${domainOption.codigo} - ${domainOption.nome}`;
    }
  }
  return "";
};

const OutrasInformacoesImovelBradescoForm = ({
  fluxo,
  formProps,
  tipoProduto,
  handleSubmit,
  parteImovel,
  handleChangeParteImovel,
  submitting,
  events,
  operacao,
  bancos,
  handleGoTo100,
  formPropsPercentualAquisicao,
  desabilitarEdicaoIq,
  searching,
  handleBancoIqChange,
  compradores,
}) => {
  const imovelFinanciado = formProps.watch("imovelFinanciado");
  const bancoIq = formProps.watch("contatoIq.banco");
  const quantidadeVagas = formProps.watch("quantidadeVagas");
  const quantidadeBox = formProps.watch("quantidadeBox");

  const codigoBancoIq =
    bancoIq != null
      ? bancos.find((b) => b.id === bancoIq.id)?.codigo ?? null
      : null;

  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={handleGoTo100}
          text={"Preencha as informações do imóvel"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={1} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Card>
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Outras informações</Title>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="Número de Dormitórios/Suítes"
                      name={"numeroDormitorios"}
                      formProps={formProps}
                      inputProps={{ maxLength: 2, allowNegative: false }}
                      InputProps={{
                        inputComponent: IntegerTextField,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledSwitch
                      label="Possui Depósito ou Vaga Autônoma?"
                      name={"vagaAutonoma"}
                      formProps={formProps}
                      tooltip={
                        "Uma vaga autônoma é aquela que tem uma escritura e fração ideal própria. Isso faz com que ela possa ser vendida junto com imóvel Principal, nas não está diretamente ligada ao apartamento ou imóvel Principal sendo necessário apresentar Matricula a parte do imóvel Principal. Já uma vaga determinada é aquela que é parte da escritura do imóvel, mas não é autônoma."
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="Quantas vagas o imóvel tem?"
                      name={"quantidadeVagas"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: IntegerTextField,
                      }}
                      inputProps={{
                        maxLength: 3,
                        allowNegative: false,
                      }}
                    />
                  </Grid>
                  {!!quantidadeVagas && quantidadeVagas > 0 && (
                    <Grid item xs={12} md={6}>
                      <ControlledTextField
                        label="Qual o número da(s) vaga(s)?"
                        tooltip={
                          'Se a(s) vaga(s) não possuem número definido, escreva "Indeterminado"'
                        }
                        name={"numeroVagas"}
                        formProps={formProps}
                        inputProps={{
                          maxLength: 30,
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <ControlledTextField
                      label="Quantas depósitos/box o imóvel tem?"
                      name={"quantidadeBox"}
                      formProps={formProps}
                      InputProps={{
                        inputComponent: IntegerTextField,
                      }}
                      inputProps={{
                        maxLength: 3,
                        allowNegative: false,
                      }}
                    />
                  </Grid>
                  {!!quantidadeBox && quantidadeBox > 0 && (
                    <Grid item xs={12} md={6}>
                      <ControlledTextField
                        label="Qual o número do(s) depósito(s)/box(es)?"
                        tooltip={
                          'Se o(s) depósito(s)/box(es) não possuem número definido, escreva "Indeterminado"'
                        }
                        name={"numeroBox"}
                        formProps={formProps}
                        inputProps={{
                          maxLength: 30,
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <ControlledSwitch
                      label="Imóvel beneficiado por Programa Social (HIS/HMP)?"
                      name={"programaSocial"}
                      formProps={formProps}
                      tooltip={
                        "HIS e HMP são termos usados para descrever imóveis destinados a famílias com menor poder aquisitivo: \n" +
                        "- HIS\n" +
                        "Habitação de Interesse Social, voltada para famílias com renda igual ou inferior a 6 salários mínimos. \n" +
                        "- HMP\n" +
                        "Habitação de Mercado Popular, voltada para famílias com renda mensal entre 6 e 10 salários mínimos."
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ControlledSwitch
                      label="Imóvel Financiado?"
                      name={"imovelFinanciado"}
                      tooltip={
                        "O interveniente quitante é uma operação para vender um imóvel financiado, onde o comprador quitará a dívida atual com um novo financiamento realizado em outra instituição financeira. A nova instituição deve quitar as parcelas restantes do primeiro empréstimo ou financiamento, elas irão se comunicar entre-si para checar o saldo devedor remanescente."
                      }
                      formProps={formProps}
                    />
                  </Grid>
                  {imovelFinanciado && (
                    <>
                      <Grid item xs={12}>
                        <Title as={"h5"}>Interveniente Quitante</Title>
                      </Grid>
                      <Grid item xs={12}>
                        <ControlledAutoComplete
                          label="Banco onde está financiado"
                          domain={bancos}
                          disabled={searching}
                          idField={"id"}
                          getOptionLabel={(option) =>
                            formatWithCodeAndDescription(option, bancos)
                          }
                          name={"contatoIq.banco"}
                          formProps={formProps}
                          autocompleteProps={{
                            onChange: handleBancoIqChange,
                            clearText: "Limpar",
                            loadingText: "Carregando opções",
                            noOptionsText: "Nenhuma opção disponível",
                          }}
                        />
                      </Grid>
                      {codigoBancoIq !== null && codigoBancoIq !== 237 && (
                        <>
                          <Grid item xs={12} md={8}>
                            <ControlledTextField
                              label="Número do Contrato"
                              name={"numeroContratoIq"}
                              formProps={formProps}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <ControlledSelect
                              name={"diaBaseIq"}
                              formProps={formProps}
                              label={"Dia Base"}
                            >
                              {Array.from({ length: 28 }, (_, i) => i + 1)?.map(
                                (state, index) => (
                                  <MenuItem key={index} value={state}>
                                    {state}
                                  </MenuItem>
                                )
                              )}
                            </ControlledSelect>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <ControlledTextField
                              label="E-mail do Contato"
                              name={"contatoIq.email"}
                              formProps={formProps}
                              inputProps={{
                                readOnly: desabilitarEdicaoIq || searching,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <ControlledTextField
                              label="Telefone do Contato"
                              name={"contatoIq.telefone"}
                              formProps={formProps}
                              InputProps={{
                                inputComponent: PhoneTextField,
                              }}
                              inputProps={{
                                readOnly: desabilitarEdicaoIq || searching,
                              }}
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  {tipoProduto === "I" && (
                    <>
                      <Grid item xs={12} md={6}>
                        <ControlledSwitch
                          label="Primeira Aquisição no SFH?"
                          name={"primeiraAquisicaoSfh"}
                          tooltip={
                            "O desconto de primeiro imóvel, de acordo com a Lei 6015/73, oferece um benefício de 50% nas taxas de registro e escritura para pessoas que estão financiando sua primeira residência pelo Sistema Financeiro de Habitação (SFH). Para obtê-lo, o comprador deve preencher uma Declaração de Primeira Aquisição, afirmando que o imóvel é sua primeira aquisição residencial, conforme a lei. Isso torna a compra do imóvel mais acessível financeiramente. Recomenda-se verificar os procedimentos específicos junto ao banco e ao cartório responsável pelo registro do imóvel."
                          }
                          formProps={formProps}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ControlledSwitch
                          label="Está adquirindo parte do Imóvel?"
                          name={"adquirindoParteImovel"}
                          onChange={handleChangeParteImovel}
                          tooltip={
                            "Um dos cônjuges pode exercer o seu direito de preferência e adquirir a fração pertencente ao outro, passando assim a se tornar proprietário exclusivo do bem; Quando o imóvel está quitado, a pessoa pode usar o FGTS para comprar até 80% dos 50% da parte do ex-conjuge, desde que tenha sido a pessoa que perdeu o direito de morar no imóvel, portanto, quem ficou sem o imóvel após a separação. Além disso, é necessário preencher os demais requisitos do regulamento do FGTS, como não ter outro imóvel, por exemplo. Em imóveis que ainda estejam financiados, não pode usar FGTS para comprar a parte do outro. O FGTS só pode ser usado como amortização. Para comprar a outra parte, não é Zecessária a outorga conjugal (anuência). Se o bem era dos dois, o cônjuge que vai vender não deve anuir, mas manifestar vontade própria de vender sua parte."
                          }
                          formProps={formProps}
                        />
                      </Grid>
                      {parteImovel && (
                        <>
                          <Grid item xs={12} md={4}>
                            <ControlledTextField
                              label="% da Parte a ser Adquirida"
                              name={"parteAdquirida"}
                              formProps={formProps}
                              InputProps={{
                                inputComponent: PercentTextField,
                              }}
                              inputProps={{
                                allowNegative: false,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <ControlledTextField
                              label="Valor de compra/venda da Parte a ser Adquirida"
                              name={"valorParteAdquirida"}
                              formProps={formProps}
                              InputProps={{
                                inputComponent: CurrencyTextField,
                              }}
                              inputProps={{
                                allowNegative: false,
                              }}
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  {tipoProduto === "H" && (
                    <Grid item xs={12} md={6}>
                      <ControlledSwitch
                        label="Imóvel Próprio?"
                        name={"imovelProprio"}
                        formProps={formProps}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Title as={"h5"}>Percentual de Aquisição</Title>
                  </Grid>
                  {compradores.map((comprador, index) => (
                    <Grid item xs={12} key={comprador.id}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                          <ControlledTextField
                            formProps={formPropsPercentualAquisicao}
                            name={`participantes.${index}.nome`}
                            value={comprador.nome}
                            label={"Nome do Comprador"}
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <ControlledTextField
                            formProps={formPropsPercentualAquisicao}
                            name={`participantes.${index}.percentualAquisicaoImovel`}
                            validationKey={
                              "participantes.percentualAquisicaoImovel"
                            }
                            label={"% de aquisição"}
                            onChange={() =>
                              formPropsPercentualAquisicao.formState.errors &&
                              formPropsPercentualAquisicao.trigger()
                            }
                            inputProps={{
                              readOnly: compradores.length === 1,
                            }}
                            InputProps={{
                              inputComponent: PercentTextField,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button
                        onClick={handleSubmit}
                        fullWidth
                        loading={submitting}
                      >
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OutrasInformacoesImovelBradescoForm;
