import { Grid } from "@mui/material";
import React from "react";
import { Button, Card, Form, GoBack } from "../../../../../components";
import Stepper from "../../../preenchimento/Stepper";
import {
  ControlledRadioGroup,
  ControlledTextField,
  Radio,
} from "../../../../../components/inputs";

const QualificacaoCompradores = ({
  handleSubmit,
  events,
  handleGoTo0,
  fluxo,
  formProps,
  submitting,
}) => {
  const aceitaQualificacao = formProps.watch("aceitaQualificacaoCompradores");

  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack handleClick={handleGoTo0} text={"Confirme os valores abaixo"} />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={1} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item width={"100%"}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ControlledTextField
                      label="Qualificação Compradores"
                      name={"qualificacaoCompradores"}
                      multiline
                      maxRows={20}
                      formProps={formProps}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledRadioGroup
                      label="Concorda com a qualificação acima?"
                      name={"aceitaQualificacaoCompradores"}
                      formProps={formProps}
                      row
                    >
                      <Radio value={"true"} label={"Sim"} />
                      <Radio value={"false"} label={"Não"} />
                    </ControlledRadioGroup>
                  </Grid>
                  {aceitaQualificacao === "false" && (
                    <Grid item xs={12}>
                      <ControlledTextField
                        label="Descreva suas alterações"
                        name={"correcaoCompradores"}
                        formProps={formProps}
                        multiline
                        minRows={5}
                        maxRows={10}
                      />
                    </Grid>
                  )}
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QualificacaoCompradores;
