import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  RESUMO: "@@clientes/RESUMO",
  LISTAR: "@@clientes/LISTAR",
  OBTER: "@@clientes/OBTER",
  OBTER_PARCEIROS: "@@clientes/OBTER_PARCEIROS",
  OBTER_PRODUTOS: "@@clientes/OBTER_PRODUTOS",
  OBTER_INSTITUICOES_FINANCEIRAS: "@@clientes/OBTER_INSTITUICOES_FINANCEIRAS"
};

export const actions = {
  listarClientes: createAsyncAction(types.LISTAR),
  carregarResumo: createAsyncAction(types.RESUMO),
  obterCliente: createAsyncAction(types.OBTER),
  obterParceiros: createAsyncAction(types.OBTER_PARCEIROS),
  obterProdutos: createAsyncAction(types.OBTER_PRODUTOS),
  obterInstituicoesFinanceiras: createAsyncAction(types.OBTER_INSTITUICOES_FINANCEIRAS)
};
