import { actions, types } from "../actions/parceiro.actions";
import { types as routes } from "../actions/rotas.actions";

const initialState = {
  criacao: {
    loading: false,
    submitting: false,
  },
  lista: {
    loading: false,
    data: [],
  },
  detalhes: {
    loading: false,
    submitting: false,
    validating: false,
    uploading: [],
    downloading: [],
    templating: [],
    deleting: [],
    data: null,
  },
  dialogFilial: {
    show: false,
    data: null,
    submitting: false,
  },
  dominios: {
    bancos: {
      loading: false,
      data: [],
    },
    parceiros: {
      loading: false,
      data: [],
    },
    promotores: {
      data: [],
    },
  },
  dialogComissionamentoParceiro: {
    show: false,
    data: [],
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.validar.REQUEST:
    case actions.validar.SUCCESS:
    case actions.validar.FAILURE:
      return {
        ...state,
        criacao: {
          ...state.criacao,
          loading: action.type === actions.validar.REQUEST,
        },
      };
    case actions.obterPromotores.REQUEST:
    case actions.obterPromotores.SUCCESS:
    case actions.obterPromotores.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          promotores: {
            data:
              action.type === actions.obterPromotores.SUCCESS
                ? action.payload.response.data
                : [],
          },
        },
      };

    case actions.criarParceiro.REQUEST:
    case actions.criarParceiro.SUCCESS:
    case actions.criarParceiro.FAILURE:
      return {
        ...state,
        criacao: {
          ...state.criacao,
          submitting: action.type === actions.criarParceiro.REQUEST,
        },
      };
    case actions.listarParceiros.REQUEST:
    case actions.listarParceiros.SUCCESS:
    case actions.listarParceiros.FAILURE:
      return {
        ...state,
        lista: {
          ...state.lista,
          loading: action.type === actions.listarParceiros.REQUEST,
          data:
            action.type === actions.listarParceiros.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterParceiro.REQUEST:
    case actions.obterParceiro.SUCCESS:
    case actions.obterParceiro.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          loading: action.type === actions.obterParceiro.REQUEST,
          data:
            action.type === actions.obterParceiro.SUCCESS
              ? action.payload.response.data
              : null,
        },
      };
    case actions.atualizarParceiro.REQUEST:
    case actions.atualizarParceiro.SUCCESS:
    case actions.atualizarParceiro.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          submitting: action.type === actions.atualizarParceiro.REQUEST,
          data:
            action.type === actions.atualizarParceiro.SUCCESS
              ? {
                  ...state.detalhes.data,
                  ...action.payload.response.data,
                  fluxoTrabalho: state.detalhes.data.fluxoTrabalho,
                  parceirosSubstabelecidos:
                    state.detalhes.data.parceirosSubstabelecidos,
                }
              : state.detalhes.data,
        },
      };
    case actions.atualizarCondicaoComercial.REQUEST:
    case actions.atualizarCondicaoComercial.SUCCESS:
    case actions.atualizarCondicaoComercial.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          submitting:
            action.type === actions.atualizarCondicaoComercial.REQUEST,
          data:
            action.type === actions.atualizarCondicaoComercial.SUCCESS
              ? {
                  ...state.detalhes.data,
                  ...action.payload.response.data,
                  fluxoTrabalho: state.detalhes.data.fluxoTrabalho,
                  parceirosSubstabelecidos:
                    state.detalhes.data.parceirosSubstabelecidos,
                }
              : state.detalhes.data,
        },
      };
    case actions.uploadDocumento.REQUEST:
    case actions.uploadDocumento.SUCCESS:
    case actions.uploadDocumento.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          uploading:
            action.type === actions.uploadDocumento.REQUEST
              ? [...state.detalhes.uploading, action.payload.id]
              : state.detalhes.uploading.filter(
                  (c) => c !== action.payload.original.id
                ),
          data:
            action.type === actions.uploadDocumento.SUCCESS
              ? {
                  ...state.detalhes.data,
                  checklists: state.detalhes.data.checklists.map((c) => ({
                    ...c,
                    grupos: c.grupos.map((g) => ({
                      ...g,
                      itens: g.itens.map((i) => ({
                        ...i,
                        documentos: i.documentos.map((d) =>
                          d.id === action.payload.response.data.id
                            ? {
                                ...action.payload.response.data,
                              }
                            : d
                        ),
                      })),
                    })),
                  })),
                }
              : state.detalhes.data,
        },
      };
    case actions.obterDocumentos.REQUEST:
    case actions.obterDocumentos.SUCCESS:
    case actions.obterDocumentos.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          loadingDocumentos: action.type === actions.obterDocumentos.REQUEST,
          data: {
            ...state.detalhes.data,
            checklists:
              action.type === actions.obterDocumentos.SUCCESS
                ? action.payload.response.data
                : state.detalhes.data.checklists,
          },
        },
      };
    case actions.downloadDocumento.REQUEST:
    case actions.downloadDocumento.SUCCESS:
    case actions.downloadDocumento.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          downloading:
            action.type === actions.downloadDocumento.REQUEST
              ? [...state.detalhes.downloading, action.payload.id]
              : state.detalhes.downloading.filter(
                  (c) => c !== action.payload.original.id
                ),
        },
      };
    case actions.downloadTemplate.REQUEST:
    case actions.downloadTemplate.SUCCESS:
    case actions.downloadTemplate.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          templating:
            action.type === actions.downloadTemplate.REQUEST
              ? [...state.detalhes.templating, action.payload.id]
              : state.detalhes.templating.filter(
                  (c) => c !== action.payload.original.id
                ),
        },
      };
    case actions.removerArquivo.REQUEST:
    case actions.removerArquivo.SUCCESS:
    case actions.removerArquivo.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          deleting:
            action.type === actions.removerArquivo.REQUEST
              ? [...state.detalhes.deleting, action.payload.id]
              : state.detalhes.deleting.filter(
                  (c) => c !== action.payload.original.id
                ),
          data:
            action.type === actions.removerArquivo.SUCCESS
              ? {
                  ...state.detalhes.data,
                  checklists: state.detalhes.data.checklists.map((c) => ({
                    ...c,
                    grupos: c.grupos.map((g) => ({
                      ...g,
                      itens: g.itens.map((i) => ({
                        ...i,
                        documentos: i.documentos.map((d) =>
                          d.id === action.payload.response.data.id
                            ? {
                                ...action.payload.response.data,
                              }
                            : d
                        ),
                      })),
                    })),
                  })),
                }
              : state.detalhes.data,
        },
      };
    case actions.validarIdentificador.REQUEST:
    case actions.validarIdentificador.SUCCESS:
    case actions.validarIdentificador.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          validating: action.type === actions.validarIdentificador.REQUEST,
        },
      };
    case actions.refresh.REQUEST:
    case actions.refresh.SUCCESS:
    case actions.refresh.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          loading: action.type === actions.refresh.REQUEST,
          data:
            action.type === actions.refresh.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterBancos.REQUEST:
    case actions.obterBancos.SUCCESS:
    case actions.obterBancos.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          bancos: {
            ...state.dominios.bancos,
            loading: action.type === actions.obterBancos.REQUEST,
            data:
              action.type === actions.obterBancos.SUCCESS
                ? action.payload.response.data
                : [],
          },
        },
      };
    case actions.obterDominioParceiros.REQUEST:
    case actions.obterDominioParceiros.SUCCESS:
    case actions.obterDominioParceiros.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          parceiros: {
            ...state.dominios.parceiros,
            loading: action.type === actions.obterDominioParceiros.REQUEST,
            data:
              action.type === actions.obterDominioParceiros.SUCCESS
                ? action.payload.response.data.filter(
                    (p) => ["P", "A", "S"].indexOf(p.situacao) >= 0
                  )
                : [],
          },
        },
      };
    case types.EXIBIR_DIALOG_COMISSIONAMENTO_PARCEIRO:
      return {
        ...state,
        dialogComissionamentoParceiro: {
          show: action.payload.show,
          data: action.payload.data,
        },
      };
    case actions.obterTimes.REQUEST:
    case actions.obterTimes.SUCCESS:
    case actions.obterTimes.FAILURE:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          data:
            action.type === actions.obterTimes.SUCCESS
              ? {
                  ...state.detalhes.data,
                  times: action.payload.response.data,
                }
              : state.detalhes.data,
        },
      };
    case actions.atualizarTime.REQUEST:
    case actions.atualizarTime.SUCCESS:
    case actions.atualizarTime.FAILURE:
      return {
        ...state,
        dialogFilial: {
          ...state.dialogFilial,
          submitting: action.type === actions.atualizarTime.REQUEST,
        },
      };

    case actions.gravarTime.REQUEST:
    case actions.gravarTime.SUCCESS:
    case actions.gravarTime.FAILURE:
      return {
        ...state,
        dialogFilial: {
          ...state.dialogFilial,
          submitting: action.type === actions.gravarTime.REQUEST,
        },
      };

    case actions.excluirTime.REQUEST:
    case actions.excluirTime.SUCCESS:
    case actions.excluirTime.FAILURE:
      return {
        ...state,
        dialogFilial: {
          ...state.dialogFilial,
          submitting: action.type === actions.excluirTime.REQUEST,
        },
      };

    case types.EXIBIR_DIALOG_FILIAL:
      return {
        ...state,
        dialogFilial: {
          ...state.dialogFilial,
          show: action.payload.show,
          data: action.payload.data,
        },
      };
    case routes.PARCEIROS:
      return {
        ...state,
        lista: {
          ...initialState.lista,
        },
      };
    case routes.CADASTRO_PARCEIRO:
      return {
        ...state,
        criacao: {
          ...initialState.criacao,
          loading: !!action.payload?.identificador,
        },
      };
    default:
      return state;
  }
};

export default reducer;
