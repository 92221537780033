import React, { useCallback, useEffect } from "react";
import DadosEnderecoSantanderForm from "./DadosEnderecoSantanderForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPreenchimentoOperacao,
  isGravandoPreenchimentoOperacao,
} from "../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import validators from "../../../../utils/validators";
import { actions } from "../../../../actions/operacao.actions";
import { removeNonDigitsFromString } from "../../../../utils/basic";
import { actions as parceiroActions } from "../../../../actions/parceiro.actions";

const DadosEnderecoSantanderFormConnected = ({
  handleGoTo3,
  eventsNaoCorrentista,
  setComprador,
  comprador,
  ...props
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { tipoResidencia: tiposResidencia },
  } = useSelector(getPreenchimentoOperacao);
  const submitting = useSelector(isGravandoPreenchimentoOperacao);

  const compradorPrincipal = operacao.participantes.find((p) => p.principal);

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setComprador({
          ...c,
          fluxo: data.validacao[
            data.operacao.detalhe.correntista ? "correntista" : "completa"
          ]?.participantes?.find((f) => f.id === c?.id)?.etapas,
        });
      }
      handleGoTo3();
    },
    [handleGoTo3, setComprador, comprador]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      endereco: {
        cep: validators.string({ required: true }),
        logradouro: validators.string({ required: true, maxLength: 40 }),
        numero: validators.string({ required: true, maxLength: 40 }),
        bairro: validators.string({ required: true, maxLength: 40 }),
        municipio: validators.string({ required: true, maxLength: 60 }),
        uf: validators.string({ required: true }),
        tipoResidencia: validators.object({ required: true }),
      },
    }),
    initialValues: useCallback(
      () => ({
        mesmoEnderecoPrincipal: false,
        ...comprador,
        endereco: {
          cep: "",
          logradouro: "",
          numero: "",
          complemento: "",
          bairro: "",
          municipio: "",
          uf: "",
          tipoResidencia: null,
          ...comprador?.endereco,
        },
      }),
      [comprador]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarPreenchimento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-endereco",
            participante: {
              id: comprador?.id,
              mesmoEnderecoPrincipal: comprador?.principal
                ? false
                : values.mesmoEnderecoPrincipal,
              endereco:
                !comprador?.principal && values.mesmoEnderecoPrincipal
                  ? {
                      cep: removeNonDigitsFromString(
                        compradorPrincipal?.endereco?.cep
                      ),
                      logradouro: compradorPrincipal?.endereco?.logradouro,
                      numeroLogradouro: compradorPrincipal?.endereco?.numero,
                      complementoLogradouro:
                        compradorPrincipal?.endereco?.complemento,
                      bairro: compradorPrincipal?.endereco?.bairro,
                      municipio: compradorPrincipal?.endereco?.municipio,
                      uf: compradorPrincipal?.endereco?.uf,
                      idTipoResidencia:
                        compradorPrincipal?.endereco?.tipoResidencia?.id,
                    }
                  : {
                      cep: removeNonDigitsFromString(values.endereco?.cep),
                      logradouro: values.endereco.logradouro,
                      numeroLogradouro: values.endereco.numero,
                      complementoLogradouro: values.endereco.complemento,
                      bairro: values.endereco.bairro,
                      municipio: values.endereco.municipio,
                      uf: values.endereco.uf,
                      idTipoResidencia: values.endereco.tipoResidencia?.id,
                    },
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [dispatch, comprador, operacao, callback, compradorPrincipal]
    ),
  });
  const { setValue, reset, watch } = formProps;

  const successZipCodeCallback = useCallback(
    ({ data }) => {
      setValue("endereco.logradouro", data?.logradouro || "", {
        shouldValidate: true,
      });
      setValue("endereco.bairro", data?.bairro || "", { shouldValidate: true });
      setValue("endereco.uf", data?.uf || "", { shouldValidate: true });
      setValue("endereco.municipio", data?.localidade || "", {
        shouldValidate: true,
      });
    },
    [setValue]
  );
  const handleZipCodeBlurred = useCallback(
    (value) => {
      if (removeNonDigitsFromString(value).length === 8) {
        dispatch(
          parceiroActions.pesquisarCep.request({
            value,
            successCallback: successZipCodeCallback,
          })
        );
      }
    },
    [dispatch, successZipCodeCallback]
  );

  const mesmoEnderecoPrincipal = watch("mesmoEnderecoPrincipal");

  useEffect(() => {
    reset({
      mesmoEnderecoPrincipal: false,
      ...comprador,
      endereco: {
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        municipio: "",
        uf: "",
        tipoResidencia: null,
        ...comprador?.endereco,
      },
    });
  }, [comprador, reset]);

  return (
    <DadosEnderecoSantanderForm
      {...props}
      tipoPessoa={operacao.detalhe.tipoPessoa}
      tiposResidencia={tiposResidencia}
      formProps={formProps}
      handleZipCodeBlurred={handleZipCodeBlurred}
      handleSubmit={handleSubmit}
      submitting={submitting}
      principal={!!comprador?.principal}
      mesmoEnderecoPrincipal={mesmoEnderecoPrincipal}
      fluxo={comprador.fluxo}
      events={eventsNaoCorrentista}
    />
  );
};

export default DadosEnderecoSantanderFormConnected;
