import {
  Grid,
  styled,
  TableBody,
  TableCell,
  TableContainer as MTableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import {
  Button,
  Card,
  Form,
  GoBack,
  IconButton,
  Title,
} from "../../../../../components";
import {
  ControlledTextField,
  PhoneTextField,
} from "../../../../../components/inputs";
import Stepper from "../../../preenchimento/Stepper";
import {
  BodyTableRow,
  SizedTableCell,
  Table,
} from "../../../../../components/tables";
import { DeleteForever } from "@mui/icons-material";

const TableContainer = styled(MTableContainer)`
  & .MuiTable-root {
    margin-top: 0;
  }

  & .MuiTableHead-root .MuiTableCell-head {
    padding-top: 0;
  }
`;

const DadosContatoImovelSantanderForm = ({
  fluxo,
  formProps,
  handleSubmit,
  contatos,
  submitting,
  events,
  handleAdicionarContato,
  handleRemoverContato,
  handleGoTo101,
  isMobile
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={handleGoTo101}
          text={"Preencha as informações do imóvel"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={2} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Contatos para Vistoria</Title>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer>
                    <Table>
                        {!isMobile ?
                          <TableHead>
                            <TableRow>
                              <TableCell width={"30%"}>Nome</TableCell>
                              <TableCell width={"35%"}>Telefone</TableCell>
                              <TableCell width={isMobile ? "100%" : "35%"}>Email</TableCell>
                              <SizedTableCell width={56}></SizedTableCell>
                            </TableRow>
                          </TableHead>
                          : null}
                        <TableBody>
                          {contatos.fields.map((contato, index) => {
                            if (isMobile) {
                              return (
                                <BodyTableRow key={contato.id} index={index}>
                                  <TableCell colSpan={3}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={6}>
                                        <ControlledTextField
                                          label="Nome"
                                          name={`contatosVistoria.${index}.nome`}
                                          validationKey={`contatosVistoria.nome`}
                                          formProps={formProps}
                                          inputProps={{ maxLength: 100 }}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <ControlledTextField
                                          label="Telefone"
                                          name={`contatosVistoria.${index}.telefone`}
                                          validationKey={`contatosVistoria.telefone`}
                                          formProps={formProps}
                                          InputProps={{
                                            inputComponent: PhoneTextField,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={10}>
                                        <ControlledTextField
                                          label="Email"
                                          name={`contatosVistoria.${index}.email`}
                                          validationKey={`contatosVistoria.email`}
                                          formProps={formProps}
                                          inputProps={{ maxLength: 100 }}
                                        />
                                      </Grid>
                                      <Grid item xs={2} display={"flex"} alignItems={"center"} marginTop={3}>
                                        <IconButton
                                          onClick={() => handleRemoverContato(index)}
                                        >
                                          <DeleteForever />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                </BodyTableRow>
                              )
                            }
                            return (
                              <BodyTableRow key={contato.id} index={index}>
                                <TableCell>
                                  <ControlledTextField
                                    name={`contatosVistoria.${index}.nome`}
                                    validationKey={`contatosVistoria.nome`}
                                    formProps={formProps}
                                    inputProps={{ maxLength: 100 }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <ControlledTextField
                                    name={`contatosVistoria.${index}.telefone`}
                                    validationKey={`contatosVistoria.telefone`}
                                    formProps={formProps}
                                    InputProps={{
                                      inputComponent: PhoneTextField,
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <ControlledTextField
                                    name={`contatosVistoria.${index}.email`}
                                    validationKey={`contatosVistoria.email`}
                                    formProps={formProps}
                                    inputProps={{ maxLength: 100 }}
                                  />
                                </TableCell>
                                <SizedTableCell width={56}>
                                  <IconButton
                                    onClick={() => handleRemoverContato(index)}
                                  >
                                    <DeleteForever />
                                  </IconButton>
                                </SizedTableCell>
                              </BodyTableRow>
                            );
                          })}
                          {contatos.fields.length === 0 && (
                            <BodyTableRow index={0}>
                              <TableCell colSpan={3}>
                                Nenhum contato cadastrado ainda
                              </TableCell>
                            </BodyTableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item container justifyContent={"flex-end"}>
                    <Grid item xs={12} md={6}>
                      <Button
                        fullWidth
                        variant={"outlined"}
                        onClick={handleAdicionarContato}
                      >
                        ADICIONAR CONTATO
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button
                        type={"submit"}
                        fullWidth
                        disabled={contatos.fields.length === 0}
                        loading={submitting}
                      >
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosContatoImovelSantanderForm;
