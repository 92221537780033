import ConfirmacaoValoresPage from "./ConfirmacaoValoresPage";
import { useSelector } from "react-redux";
import {
  getConfirmacaoValores,
  isCarregandoConfirmacaoValores,
  isEnviandoConfirmacaoValores,
} from "../../../selectors/operacao.selectors";

const ConfirmacaoValoresPageConnected = () => {
  const loading = useSelector(isCarregandoConfirmacaoValores);
  const submitting = useSelector(isEnviandoConfirmacaoValores);
  const data = useSelector(getConfirmacaoValores);

  return (
    <ConfirmacaoValoresPage
      loading={loading}
      data={data}
      submitting={submitting}
    />
  );
};

export default ConfirmacaoValoresPageConnected;
