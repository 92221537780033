import { styled, ToggleButtonGroup as MToggleButtonGroup } from "@mui/material";

const ToggleButtonGroup = styled(MToggleButtonGroup)`
  border-radius: 0.75rem;

  & .MuiButtonBase-root {
    font-size: ${({ theme }) => theme.fonts.size.default};
    font-family: ${({ theme }) => theme.fonts.family.default};
    color: ${({ theme }) => theme.colors.grey._500};
    border: 0;
    border-radius: 0.75rem;
    font-weight: 400;
    padding: ${({ size }) => (size === "small" ? "5.7px" : "0.5rem")};
    background-color: ${({ theme }) => theme.colors.grey._50};
  }
  & .MuiButtonBase-root:first-of-type {
    padding-left: ${({ size }) => (size === "small" ? "14px" : "1rem")};
  }
  & .MuiButtonBase-root:last-of-type {
    padding-right: ${({ size }) => (size === "small" ? "14px" : "1rem")};
  }
  & .MuiButtonBase-root:not(:first-of-type) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  & .MuiButtonBase-root:not(:last-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & .MuiButtonBase-root.Mui-selected {
    color: ${({ theme }) => theme.colors.grey._600};
    font-weight: 700;
    background-color: ${({ theme }) => theme.colors.grey._100};
  }
`;

export default ToggleButtonGroup;
