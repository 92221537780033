import { Grid } from "@mui/material";
import React from "react";
import InformacoesFinanceirasLead from "./InformacoesFinanceirasLead";
import InformacoesPessoaisLead from "./InformacoesPessoaisLead";
import DialogoVisivel from "../workflow/DialogoVisivel";

const DadosLeadTab = ({ dialogosDisponiveis, lead }) => {
  return (
    <DialogoVisivel referencia={"DADOS_LEAD"} dialogos={dialogosDisponiveis}>
      <Grid item xs={12} md={6} lg={4}>
        <InformacoesPessoaisLead lead={lead} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <InformacoesFinanceirasLead lead={lead} />
      </Grid>
    </DialogoVisivel>
  );
};

export default DadosLeadTab;
