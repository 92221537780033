import React, { useCallback, useState } from "react";
import EtapasAndamento from "./EtapasAndamento";
import { useDispatch, useSelector } from "react-redux";
import {
  getEtapasAbertas,
  getFluxoTrabalho,
  getStatusFluxoTrabalho,
  isAtuandoFluxoTrabalho,
} from "../../selectors/workflow.selectors";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";
import { actions } from "../../actions/workflow.actions";
import { getUsuarioLogado } from "../../selectors/usuario.selectors";

const EtapasAndamentoConnected = ({
  idOperacao,
  parceiro,
  callback,
  contexto,
}) => {
  const dispatch = useDispatch();
  const usuario = useSelector(getUsuarioLogado);
  const etapasAbertas = useSelector(getEtapasAbertas);
  const status = useSelector(getStatusFluxoTrabalho);
  const { permiteCancelamento, id } = useSelector(getFluxoTrabalho);
  const acting = useSelector(isAtuandoFluxoTrabalho);
  const [dadosMotivo, setDadosMotivo] = useState({
    open: false,
  });
  const handleCloseMotivoAcao = useCallback(() => {
    setDadosMotivo({
      open: false,
    });
  }, []);
  const handleOpenMotivoAcao = useCallback((values) => {
    setDadosMotivo({
      open: true,
      ...values,
    });
  }, []);

  const [numeroProposta, setNumeroProposta] = useState({
    open: false,
  });
  const handleCloseNumeroProposta = useCallback(() => {
    setNumeroProposta((v) => ({
      ...v,
      open: false,
    }));
  }, []);
  const handleOpenNumeroProposta = useCallback((values) => {
    setNumeroProposta({
      open: true,
      ...values,
    });
  }, []);

  const callbackNumeroProposta = useCallback(
    (o) => {
      dispatch(
        actions.atuarFluxoTrabalho.request({
          ...o,
          callback,
        })
      );
    },
    [dispatch, callback]
  );
  const handleAcao = useCallback(
    (etapa, acao) => {
      if (!!acao.codigo) {
        switch (acao.codigo) {
          case "preenchimento-proposta":
          case "repreenchimento-proposta":
            dispatch(
              routeActions.redirectTo(routes.PREENCHIMENTO_OPERACAO, {
                id: idOperacao,
              })
            );
            break;
          case "complemento-proposta":
          case "recomplemento-proposta":
            dispatch(
              routeActions.redirectTo(routes.COMPLEMENTO_OPERACAO, {
                id: idOperacao,
              })
            );
            break;
          case "confirmacao-valores":
            dispatch(
              routeActions.redirectTo(routes.CONFIRMACAO_VALORES, {
                id: idOperacao,
              })
            );
            break;
          case "simulacao":
            dispatch(
              routeActions.redirectTo(routes.SIMULADOR_CONTINUACAO, {
                id: idOperacao,
              })
            );
            break;
          case "dados-bancarios":
            dispatch(
              routeActions.redirectTo(routes.DADOS_BANCARIOS, {
                id: idOperacao,
              })
            );
            break;
          case "numero-proposta":
            handleOpenNumeroProposta({
              etapa,
              acao,
              title: "Alterar o número da proposta",
              label: "Número da Proposta",
              action: actions.atualizarNumeroProposta.request,
              callback: callbackNumeroProposta,
              type: "string",
              contexto,
            });
            break;
          case "codigo-rastreamento":
            handleOpenNumeroProposta({
              etapa,
              acao,
              title: "Alterar o código de rastreamento",
              label: "Código Rastreamento",
              action: actions.atualizarCodigoRastreamento.request,
              callback: callbackNumeroProposta,
              type: "string",
              contexto,
            });
            break;
          case "aprovado-menor":
            dispatch(
              routeActions.redirectTo(routes.APROVACAO_MENOR, {
                id: idOperacao,
              })
            );
            break;
          default:
            if (acao.exigeComentario) {
              handleOpenMotivoAcao({
                etapa,
                acao,
                action: actions.atuarFluxoTrabalho.request,
                callback,
                initial: acao.comentarioPadraoOperacao ?? "",
                contexto,
              });
            } else {
              dispatch(
                actions.atuarFluxoTrabalho.request({
                  etapa,
                  acao,
                  callback,
                  contexto,
                })
              );
            }
            break;
        }
      } else {
        if (acao.exigeComentario) {
          handleOpenMotivoAcao({
            etapa,
            acao,
            action: actions.atuarFluxoTrabalho.request,
            callback,
            initial: acao.comentarioPadraoOperacao ?? "",
            contexto,
          });
        } else {
          dispatch(
            actions.atuarFluxoTrabalho.request({
              etapa,
              acao,
              callback,
              contexto,
            })
          );
        }
      }
    },
    [
      handleOpenNumeroProposta,
      dispatch,
      idOperacao,
      handleOpenMotivoAcao,
      contexto,
      callback,
      callbackNumeroProposta,
    ]
  );

  const handleCancelar = useCallback(() => {
    handleOpenMotivoAcao({
      workflow: id,
      action: actions.cancelarFluxoTrabalho.request,
      callback,
      contexto,
    });
  }, [id, handleOpenMotivoAcao, callback, contexto]);

  const handleCancelarParceiro = useCallback(() => {
    handleOpenMotivoAcao({
      action: actions.cancelarParceiro.request,
      callback,
      contexto,
    });
  }, [handleOpenMotivoAcao, callback, contexto]);

  const handleSuspenderParceiro = useCallback(() => {
    handleOpenMotivoAcao({
      workflow: id,
      action: actions.suspenderParceiro.request,
      callback,
      contexto,
    });
  }, [id, handleOpenMotivoAcao, callback, contexto]);

  const handleReativarParceiro = useCallback(() => {
    handleOpenMotivoAcao({
      workflow: id,
      action: actions.reativarParceiro.request,
      callback,
      contexto,
    });
  }, [id, handleOpenMotivoAcao, callback, contexto]);

  const podeCancelar =
    !!permiteCancelamento && usuario?.perfil === "OPERACAO" && status === "A";

  return (
    <EtapasAndamento
      etapasAbertas={etapasAbertas}
      handleAcao={handleAcao}
      acting={acting}
      podeCancelar={podeCancelar}
      dadosMotivo={dadosMotivo}
      handleCloseMotivoAcao={handleCloseMotivoAcao}
      handleCancelar={handleCancelar}
      numeroProposta={numeroProposta}
      handleCloseNumeroProposta={handleCloseNumeroProposta}
      parceiro={parceiro}
      isParceiro={!!parceiro}
      perfil={usuario?.perfil}
      handleCancelarParceiro={handleCancelarParceiro}
      handleSuspenderParceiro={handleSuspenderParceiro}
      handleReativarParceiro={handleReativarParceiro}
    />
  );
};

export default EtapasAndamentoConnected;
