import React from "react";
import { styled } from "@mui/material";
import { transientOptions } from "../styles/theme";

const StyledTitle = styled("h1", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-weight: 700;
  display: ${({ _display_ = "block" }) => _display_};
  text-align: ${({ _align_ = "left" }) => _align_};
  color: ${({ theme, _color_ = "secondary" }) => theme.colors[_color_]._500};
  margin: ${({ _margin_ = "0" }) => _margin_};
`;

const Title = (props) => <StyledTitle {...props} />;

export default Title;
