import { ControlledTextField } from "../../components/inputs";
import { Button, Form } from "../../components";
import {
  Check,
  LockOutlined,
  NotInterested,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

import React, { useState } from "react";
import { Grid, IconButton, InputAdornment, styled } from "@mui/material";

const Instructions = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  margin: 0.5rem 0 0;
`;
const StyledCheck = styled(Check)`
  color: ${({ theme }) => theme.colors.success._600};
  font-size: 1.5rem;
  vertical-align: middle;
  margin-top: -2px;
`;
const StyledNotInterested = styled(NotInterested)`
  color: ${({ theme }) => theme.colors.error._600};
  font-size: 1.5rem;
  vertical-align: middle;
  margin-top: -2px;
`;

const ValidationItem = ({ state, item, children }) => (
  <Instructions>
    {!!state.dirty && state.list.indexOf(item) === -1 ? (
      <StyledCheck />
    ) : (
      <StyledNotInterested />
    )}{" "}
    {children}
  </Instructions>
);

const TrocarSenhaForm = ({
  submitting,
  formProps,
  handleSubmit,
  handlePasswordChange,
}) => {
  const [state, setState] = useState({ dirty: false, list: [] });
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <ControlledTextField
          label="Nova Senha"
          name={"senha"}
          formProps={formProps}
          type={showPassword ? "text" : "password"}
          onChange={(v) => handlePasswordChange(v, setState)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <IconButton onClick={() => setShowPassword(false)}>
                    <VisibilityOff />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setShowPassword(true)}>
                    <Visibility />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="Repetir Senha"
          name={"senha2"}
          formProps={formProps}
          type={showPassword ? "text" : "password"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <IconButton onClick={() => setShowPassword(false)}>
                    <VisibilityOff />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setShowPassword(true)}>
                    <Visibility />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Instructions>
          Sua nova senha deve respeitar as seguintes regras:
        </Instructions>
        <ValidationItem state={state} item={"min"}>
          Ter no mínimo 8 caracteres
        </ValidationItem>
        {/*<ValidationItem state={state} item={"lowercase"}>*/}
        {/*  Ter ao menos uma letra minúscula*/}
        {/*</ValidationItem>*/}
        {/*<ValidationItem state={state} item={"uppercase"}>*/}
        {/*  Ter ao menos uma letra maiúscula*/}
        {/*</ValidationItem>*/}
        {/*<ValidationItem state={state} item={"digits"}>*/}
        {/*  Ter ao menos um número*/}
        {/*</ValidationItem>*/}
        {/*<ValidationItem state={state} item={"symbols"}>*/}
        {/*  Ter ao menos um caracter especial*/}
        {/*</ValidationItem>*/}
      </Grid>
      <Grid>
        <Button loading={submitting} type={"submit"} fullWidth>
          CONFIRMAR
        </Button>
      </Grid>
    </Form>
  );
};

export default TrocarSenhaForm;
