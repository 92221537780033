import basicFlow from "./asyncHandler";
import { put, select } from "redux-saga/effects";
import { actions } from "../actions/calculadora.actions";
import {
  authenticatedRequest,
  destinies,
  unauthenticatedRequest,
} from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../actions/rotas.actions";
import formatters from "../utils/formatters";
import { parseDateUsingFormat } from "../utils/basic";
import { toast } from "react-toastify";
import {
  getParceiroExterno,
  getResultadoCalculo,
} from "../selectors/calculadora.selectors";
import { getUsuarioLogado } from "../selectors/usuario.selectors";
import { getRouteArguments } from "../selectors/routes.selectors";
import { actions as temaActions } from "../actions/tema.actions";
import { actions as clienteActions } from "../actions/clientes.actions"

const buscarCalculadoras = basicFlow({
  actionGenerator: actions.buscarCalculadoras,
  api: () => {
    return authenticatedRequest({
      url: "/calculadoras",
      destiny: destinies.SIMULADOR,
      method: "get",
    });
  },
  preSuccess: function* ({ original = {}, response }) {
    const { produto } = original;

    if (!!produto) {
      const tipo =
        produto === "imobiliario"
          ? "I"
          : produto === "cgi"
          ? "H"
          : produto === "consorcio"
          ? "C"
          : "";
      if (!tipo) {
        return;
      }
      const prd = response.data.find((p) => p.tipo === tipo);
      if (!prd) {
        return;
      }
      const regra = prd.regras.find((r) => r.tipoPessoa === "F");
      if (!regra) {
        return;
      }
      yield put(actions.selecionarCalculadora(regra));
      yield put(actions.navegar(1));
    }
  },
});
const calcular = basicFlow({
  actionGenerator: actions.calcular,
  transform: function* ({ callback, ...values }) {
    const parceiroExterno = yield select(getParceiroExterno);

    const headers = !!parceiroExterno
      ? {
          "X-Identificador-Parceiro": parceiroExterno,
        }
      : undefined;

    return {
      ...values,
      dataNascimento: !!values.dataNascimento
        ? formatters.dates.iso(
            parseDateUsingFormat(values.dataNascimento, "dd/MM/yyyy")
          )
        : null,
      headers,
      authenticated: !!parceiroExterno,
    };
  },
  api: ({ headers, authenticated, ...values }) => {
    if (authenticated) {
      return authenticatedRequest({
        url: "/calcular",
        destiny: destinies.SIMULADOR,
        headers,
        method: "post",
        body: values,
      });
    } else {
      return unauthenticatedRequest({
        url: "/calcular",
        destiny: destinies.SIMULADOR,
        headers,
        method: "post",
        body: values,
      });
    }
  },
  postSuccess: ({ original }) => {
    if (original.callback) {
      original.callback();
    }
  },
});

const gravarLead = basicFlow({
  actionGenerator: actions.gravarLead,
  transform: function* ({ callback, ...values }) {
    const parceiroExterno = yield select(getParceiroExterno);

    const {
      data: { id },
    } = yield select(getResultadoCalculo);

    if (!!parceiroExterno) {
      return {
        ...values,
        calculo: id,
        formaContato: values.formaContato.map((f) => f.id).join(""),
        identificadorParceiro: parceiroExterno,
        authenticated: false,
      };
    }
    return {
      ...values,
      calculo: id,
      formaContato: values.formaContato.map((f) => f.id).join(""),
      identificadorParceiro: values.identificadorParceiro?.id,
      authenticated: true,
    };
  },
  api: ({ authenticated, ...values }) => {
    return authenticated
      ? authenticatedRequest({
          url: "/lead",
          destiny: destinies.CLIENTE,
          method: "post",
          body: values,
        })
      : unauthenticatedRequest({
          url: "/lead",
          destiny: destinies.CLIENTE,
          method: "post",
          body: values,
        });
  },
  postSuccess: function* () {
    toast.success(
      "Obrigado!\nUm consultor de crédito entrará em contato em breve."
    );
    yield put(actions.navegar(0));
  },
});

const duplicarLead = basicFlow({
  actionGenerator: actions.duplicarLead,
  api: ({ ...values }) => {
      return authenticatedRequest({
        url: "/duplicar-lead",
        isResourceService: true,
        destiny: destinies.CLIENTE,
        method: "post",
        body: values,
      })
  },
  postSuccess: function* ({response}) {
    toast.success(
      "Lead duplicado com sucesso."
    );
    yield put(
      routeActions.redirectTo(routes.SIMULADOR_CONTINUACAO, {
        id: response.data.idOperacao,
      })
    );
  },
});

const obterParceiros = basicFlow({
  actionGenerator: actions.obterParceiros,
  api: () => {
    return authenticatedRequest({
      url: "/dominios/parceiros",
      destiny: destinies.PARCEIRO,
      method: "get",
    });
  },
});

const validarParceiro = basicFlow({
  actionGenerator: actions.validarParceiro,
  api: (values) => {
    return unauthenticatedRequest({
      url: `/parceiro/query`,
      queryParams: values,
      destiny: destinies.PARCEIRO,
      method: "get",
    });
  },
  postSuccess: function* ({ response, original }) {
    if (!!response.data && response.data.situacao !== "C") {
      yield put(temaActions.aplicarTema(response.data));
      yield put(
        actions.buscarCalculadoras.request({ produto: original.produto })
      );
    } else {
      toast.error("Parceiro inválido");
      yield put(routeActions.rejectTo(routes.LOGIN));
    }
  },
});

const cancelarRegraComercial = basicFlow({
  actionGenerator: actions.cancelarRegraComercial,
  api: (values) => {
    return authenticatedRequest({
      url: `/lead-regra-selecionada/${values.id}/cancelar`,
      destiny: destinies.CLIENTE,
      method: "put"
    });
  },
  postSuccess: function* ({response, original}) {
    toast.success(
      "Lead atualizado com sucesso."
    );
    yield put(
      clienteActions.obterCliente.request({ id: original.clienteId })
    );
  },
});

function* calculadoraRouteWatcher() {
  yield routeWatcher([routes.CALCULADORA_INTERNA], function* () {
    yield put(actions.buscarCalculadoras.request());

    const usuario = yield select(getUsuarioLogado);

    if (!!usuario && !usuario.idParceiro) {
      yield put(actions.obterParceiros.request());
    }
  });
}
function* calculadoraExternaRouteWatcher() {
  yield routeWatcher([routes.CALCULADORA_EXTERNA], function* () {
    const { identificador, produto } = yield select(getRouteArguments);

    if (!identificador) {
      yield put(routeActions.rejectTo(routes.LOGIN));
    } else {
      yield put(actions.validarParceiro.request({ identificador, produto }));
    }
  });
}

export const sagas = [
  buscarCalculadoras.watcher(),
  obterParceiros.watcher(),
  calcular.watcher(),
  cancelarRegraComercial.watcher(),
  gravarLead.watcher(),
  duplicarLead.watcher(),
  validarParceiro.watcher(),
  calculadoraRouteWatcher(),
  calculadoraExternaRouteWatcher(),
];
