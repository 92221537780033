import React from "react";
import DadosBasicosCompradorBradescoFormConnected from "./comprador/DadosBasicosCompradorBradescoForm.connected";
import HubBradescoConnected from "./HubBradesco.connected";
import DadosProfissionaisCompradorBradescoFormConnected from "./comprador/DadosProfissionaisCompradorBradescoForm.connected";
import DadosEstadoCivilCompradorBradescoFormConnected from "./comprador/DadosEstadoCivilCompradorBradescoForm.connected";
import DadosEnderecoCompradorBradescoFormConnected from "./comprador/DadosEnderecoCompradorBradescoForm.connected";
import DadosBasicosVendedorPfBradescoFormConnected from "./vendedor/DadosBasicosVendedorPfBradescoForm.connected";
import DadosEstadoCivilVendedorBradescoFormConnected from "./vendedor/DadosEstadoCivilVendedorBradescoForm.connected";
import DadosEnderecoVendedorPfBradescoFormConnected from "./vendedor/DadosEnderecoVendedorPfBradescoForm.connected";
import DadosBasicosVendedorPjBradescoFormConnected from "./vendedor/DadosBasicosVendedorPjBradescoForm.connected";
import DadosEnderecoVendedorPjBradescoFormConnected from "./vendedor/DadosEnderecoVendedorPjBradescoForm.connected";
import DadosEnderecoImovelBradescoFormConnected from "./imovel/DadosEnderecoImovelBradescoForm.connected";
import DocumentosTabConnected from "./DocumentosTab.connected";
import OutrasInformacoesImovelBradescoFormConnected from "./imovel/OutrasInformacoesImovelBradescoForm.connected";
import DadosContatoImovelBradescoFormConnected from "./imovel/DadosContatoImovelBradescoForm.connected";
import DadosOutrasInformacoesCompradorBradescoFormConnected from "./comprador/DadosOutrasInformacoesCompradorBradescoForm.connected";
import ContasLiberacaoVendedorBradescoFormConnected from "./ContasLiberacaoVendedorBradescoForm.connected";
import DadosFgtsBradescoFormConnected from "./comprador/DadosFgtsBradescoForm.connected";
import OutrasInformacoesBradescoFormConnected from "./OutrasInformacoesBradescoForm.connected";
import DadosDpsDefaultFormConnected from "../default/comprador/DadosDpsDefaultForm.connected";

import DadosSociosPjVendedorBradescoFormConnected from "./vendedor/DadosSociosPjVendedorBradescoForm.connected";
import DadosBasicosSociosBradescoFormConnected from "./vendedor/socios/DadosBasicosSociosBradescoForm.connected";

const FluxoAquisicaoBradesco = (props) => {
  return (
    <>
      {props.step === 0 && <HubBradescoConnected {...props} />}
      {props.step === 1 && (
        <DadosBasicosCompradorBradescoFormConnected {...props} />
      )}
      {props.step === 2 && (
        <DadosEnderecoCompradorBradescoFormConnected {...props} />
      )}
      {props.step === 3 && (
        <DadosProfissionaisCompradorBradescoFormConnected {...props} />
      )}
      {props.step === 4 && (
        <DadosEstadoCivilCompradorBradescoFormConnected {...props} />
      )}
      {props.step === 5 && (
        <DadosOutrasInformacoesCompradorBradescoFormConnected {...props} />
      )}
      {props.step === 7 && <DadosFgtsBradescoFormConnected {...props} />}
      {props.step === 9 && <DadosDpsDefaultFormConnected {...props} />}
      {props.step === 10 && (
        <DadosBasicosVendedorPfBradescoFormConnected {...props} />
      )}
      {props.step === 11 && (
        <DadosEnderecoVendedorPfBradescoFormConnected {...props} />
      )}
      {props.step === 12 && (
        <DadosEstadoCivilVendedorBradescoFormConnected {...props} />
      )}
      {props.step === 20 && (
        <DadosBasicosVendedorPjBradescoFormConnected {...props} />
      )}
      {props.step === 21 && (
        <DadosEnderecoVendedorPjBradescoFormConnected {...props} />
      )}
      {props.step === 22 && (
        <DadosSociosPjVendedorBradescoFormConnected {...props} />
      )}
      {props.step === 60 && (
        <DadosBasicosSociosBradescoFormConnected {...props} />
      )}
      {props.step === 100 && (
        <DadosEnderecoImovelBradescoFormConnected {...props} />
      )}
      {props.step === 101 && (
        <OutrasInformacoesImovelBradescoFormConnected {...props} />
      )}
      {props.step === 102 && (
        <DadosContatoImovelBradescoFormConnected {...props} />
      )}
      {props.step === 997 && (
        <OutrasInformacoesBradescoFormConnected {...props} />
      )}
      {props.step === 998 && (
        <ContasLiberacaoVendedorBradescoFormConnected {...props} />
      )}
      {props.step === 999 && <DocumentosTabConnected {...props} />}
    </>
  );
};

export default FluxoAquisicaoBradesco;
