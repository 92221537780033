import EsqueciSenhaForm from "./EsqueciSenhaForm.connected";
import { Grid, styled } from "@mui/material";
import { Card, Title } from "../../components";
import logo from "../../assets/logo.png";
import SplitPanePage from "../SplitPanePage";

const ScaledLogo = styled("img")`
  height: 64px;
  width: auto;
`;

const EsqueciSenhaPage = (props) => {
  return (
    <SplitPanePage
      xl={4}
      photo={`https://cdn.imobiliario.desenvolvimento.taya.com.br/home_v3.jpg`}
    >
      <Grid container direction={"column"} justifyContent={"center"} px={3}>
        <Grid item>
          <Card>
            <Grid
              direction={"column"}
              alignItems="center"
              justifyContent="center"
              container
            >
              {/*<Grid item xs={12}>*/}
              {/*  <ScaledLogo src={logo} />*/}
              {/*</Grid>*/}
              <Title as={"h2"} _margin_={"1rem 0 2rem 0"}>
                Esqueceu sua senha?
              </Title>
              <EsqueciSenhaForm {...props} />
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </SplitPanePage>
  );
};

export default EsqueciSenhaPage;
