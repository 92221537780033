import React, { useCallback, useEffect } from "react";
import DadosProfissionaisCompradorBradescoForm from "./DadosProfissionaisCompradorBradescoForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import { transformToDate } from "../../../../../utils/basic";
import { useFieldArray } from "react-hook-form";

const DadosProfissionaisCompradorBradescoFormConnected = ({
  handleGoTo4,
  events,
  setParticipante,
  participante: comprador,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { perfilRenda: perfisRenda, profissao: profissoes },
  } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);

  const resetValues = useCallback(
    () => ({
      perfilRenda: null,
      ...comprador,
      dadosProfissionais: {
        nomeEmpresa: "",
        dataAdmissao: "",
        cargo: "",
        municipio: "",
        uf: "",
        ...comprador?.dadosProfissionais,
      },
    }),
    [comprador]
  );

  const callback = useCallback(
    (data) => {
      const c = data.operacao.participantes.find((p) => p.id === comprador.id);
      if (!!c) {
        setParticipante({
          ...c,
          fluxo: data.validacao.completa.participantes.find(
            (f) => f.id === c?.id
          )?.etapas,
        });
      }
      handleGoTo4();
    },
    [handleGoTo4, setParticipante, comprador]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      perfilRenda: validators.object({ required: true }),
      dadosProfissionais: {
        cargo: validators.string({ required: true }),
        nomeEmpresa: validators.string({ required: true }),
        dataAdmissao: validators.date({ required: true }),
        municipio: validators.string({ required: true }),
        uf: validators.string({ required: true })
      },
    }),
    initialValues: resetValues,
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback,
            instituicao: operacao.codigoInstituicao,
            passo: "dados-profissionais",
            participante: {
              id: comprador?.id,
              idPerfilRenda: values.perfilRenda?.id,
              dadosProfissionais: {
                cargo: values.dadosProfissionais.cargo,
                nomeEmpresa: values.dadosProfissionais.nomeEmpresa,
                dataAdmissao: transformToDate(
                  values.dadosProfissionais.dataAdmissao
                ),
                municipio: values.dadosProfissionais.municipio,
                uf: values.dadosProfissionais.uf
              },
            },
            operacao: {
              id: operacao.id,
            },
          })
        );
      },
      [callback, comprador, operacao, dispatch]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset(resetValues());
  }, [perfisRenda, comprador, resetValues, reset]);

  return (
    <DadosProfissionaisCompradorBradescoForm
      {...otherProps}
      perfisRenda={perfisRenda}
      profissoes={profissoes}
      fluxo={comprador.fluxo}
      events={events}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
    />
  );
};

export default DadosProfissionaisCompradorBradescoFormConnected;
