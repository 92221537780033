import { actions, types } from "../actions/calculadora.actions";
import { types as routes } from "../actions/rotas.actions";

const initialState = {
  atual: 0,
  parceiro: null,
  disponiveis: {
    loading: true,
    data: [],
    selecionada: null,
  },
  calculo: {
    valores: null,
    submitting: false,
    data: null,
  },
  dominios: {
    parceiros: {
      loading: false,
      data: [],
    },
  },
  lead: {
    submitting: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.buscarCalculadoras.REQUEST:
    case actions.buscarCalculadoras.SUCCESS:
    case actions.buscarCalculadoras.FAILURE:
      return {
        ...state,
        disponiveis: {
          ...state.disponiveis,
          loading: action.type === actions.buscarCalculadoras.REQUEST,
          data:
            action.type === actions.buscarCalculadoras.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.calcular.REQUEST:
    case actions.calcular.SUCCESS:
    case actions.calcular.FAILURE:
      return {
        ...state,
        calculo: {
          ...state.calculo,
          valores:
            action.type === actions.calcular.REQUEST
              ? action.payload
              : state.calculo.valores,
          submitting: action.type === actions.calcular.REQUEST,
          data:
            action.type === actions.calcular.SUCCESS
              ? action.payload.response.data
              : null,
        },
        lead: {
          ...initialState.lead,
        },
      };
    case actions.gravarLead.REQUEST:
    case actions.gravarLead.SUCCESS:
    case actions.gravarLead.FAILURE:
      return {
        ...state,
        lead: {
          ...state.lead,
          submitting: action.type === actions.gravarLead.REQUEST,
        },
      };
    case actions.duplicarLead.REQUEST:
    case actions.duplicarLead.SUCCESS:
    case actions.duplicarLead.FAILURE:
      return {
        ...state,
        lead: {
          ...state.lead,
          submitting: action.type === actions.duplicarLead.REQUEST,
        },
      };
    case actions.obterParceiros.REQUEST:
    case actions.obterParceiros.SUCCESS:
    case actions.obterParceiros.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          parceiros: {
            ...state.dominios.parceiros,
            loading: action.type === actions.obterParceiros.REQUEST,
            data:
              action.type === actions.obterParceiros.SUCCESS
                ? action.payload.response.data.filter(
                    (p) => ["P", "A"].indexOf(p.situacao) >= 0
                  )
                : [],
          },
        },
      };
    case actions.validarParceiro.SUCCESS:
      return {
        ...state,
        parceiro: action.payload.response.data.identificadorOrigem,
      };
    case types.SELECIONAR:
      return {
        ...state,
        disponiveis: {
          ...state.disponiveis,
          selecionada: action.payload,
        },
      };
    case types.NAVEGAR:
      return {
        ...state,
        atual: action.payload,
        calculo: {
          ...state.calculo,
          valores: action.payload < 1 ? null : state.calculo.valores,
        },
      };
    case routes.CALCULADORA_INTERNA:
    case routes.CALCULADORA_EXTERNA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
