import React from "react";
import { Grid, styled } from "@mui/material";
import CommonPage from "../CommonPage";
import FooterConnected from "../addons/Footer.connected";
import { FullHeightContainer } from "../../components";

const Title = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.primary._500};
  font-size: ${({ theme }) => theme.fonts.size.xlarge};
  font-weight: 800;
  margin: 2rem 0 1rem;
  text-transform: uppercase;
`;
const Content = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: ${({ theme }) => theme.colors.grey._800};
  font-size: ${({ theme }) => theme.fonts.size.default};
  margin-bottom: 2rem;
  white-space: pre-wrap;
`;
const Section = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: ${({ theme }) => theme.colors.grey._800};
  font-size: ${({ theme }) => theme.fonts.size.default};
  margin-top: 1rem;
  white-space: pre-wrap;
`;
const SectionTitle = styled("div")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.grey._800};
  font-weight: 800;
  font-size: ${({ theme }) => theme.fonts.size.default};
  margin: 0 0 1rem;
  white-space: pre-wrap;
`;

const TermosUsoPage = () => {
  return (
    <FullHeightContainer maxWidth={false} _direction_={"column"}>
      <Grid container>
        <Title>Termos e Condições de Uso</Title>
        <Content>
          <Section>
            <SectionTitle>VISÃO GERAL</SectionTitle>
            {"Esse site é operado pela TAYA TECH INTERMEDIACAO LTDA. Em todo o site, os termos “nós” e “nosso” se referem à TAYA Plataforma CI | Plataforma Digital de Crédito. \n" +
              "A TAYA Plataforma CI | Plataforma Digital de Crédito produz o conteúdo deste site, incluindo todas as informações, ferramentas e serviços disponíveis para você, o usuário, com a condição da sua aceitação de todos os termos, condições, políticas e avisos declarados aqui.\n" +
              "Ao visitar nosso site e/ou preencher um formulário em nosso site, você está utilizando os nossos Produtos e Serviços (“Linhas de Crédito”, “Produtos”, “Operações” e/ou “Serviços”). \n" +
              "Consequentemente, você concorda com os seguintes termos e condições (“Termos de Uso”, “Termos” e/ou “Termos de Serviço”), incluindo os termos e condições e políticas adicionais mencionados neste documento e/ou disponíveis por hyperlink. \n" +
              "Esses Termos de Uso se aplicam a todos os usuários do site, incluindo, sem limitação, os usuários que são navegadores, fornecedores, clientes, lojistas e/ou contribuidores de conteúdo.\n" +
              "Por favor, leia estes Termos de Uso cuidadosamente antes de acessar ou utilizar o nosso site. Ao acessar ou usar qualquer parte do site, você concorda com os Termos de Uso. \n" +
              "Se você não concorda com todos os termos e condições deste acordo, então você não pode acessar o site ou usar quaisquer serviços. Se esses Termos de Uso são considerados uma oferta, a aceitação é expressamente limitada a esses Termos de Uso.\n" +
              "Quaisquer novos recursos ou ferramentas que forem adicionados ao site atual também devem estar sujeitos aos Termos de Uso. Você pode revisar a versão mais atual dos Termos de Uso quando quiser nesta página. \n" +
              "Reservamos o direito de atualizar, alterar ou trocar qualquer parte destes Termos de Uso ao publicar atualizações e/ou alterações no nosso site. É sua responsabilidade verificar as alterações feitas nesta página periodicamente. Seu uso contínuo ou acesso ao site após a publicação de quaisquer alterações constitui aceitação de tais alterações.\n"}
          </Section>
          <Section>
            <SectionTitle>SEÇÃO 1 – TERMOS DE USO</SectionTitle>
            {"Ao concordar com os Termos de Uso, você confirma que é maior de 18 (dezoito) anos de idade e juridicamente capaz.\n" +
              "Você não deve usar nossos produtos e serviços (linhas de crédito) para qualquer fim ilegal ou não autorizado. Você também não pode, ao usufruir deste Serviço, violar quaisquer leis em vigor no Brasil.\n" +
              "Você não deve transmitir nenhum vírus ou qualquer código de natureza destrutiva.\n" +
              "Violar qualquer um dos termos tem como consequência inicial a rescisão imediata dos seus serviços, bem como a responsabilização civil e penal.\n"}
          </Section>
          <Section>
            <SectionTitle>SEÇÃO 2 – CONDIÇÕES GERAIS</SectionTitle>
            {"Reservamos o direito de recusar o serviço a qualquer pessoa por qualquer motivo a qualquer momento.\n" +
              "Você entende que o seu conteúdo (não incluindo informações de cartão de crédito), pode ser transferido sem criptografia e pode: \n" +
              "(a) ser transmitido por várias redes; e \n" +
              "(b) sofrer alterações para se adaptar e se adequar às exigências técnicas de conexão de redes ou dispositivos. \n" +
              "As informações de cartão de crédito sempre são criptografadas durante a transferência entre redes.\n" +
              "Você concorda em não reproduzir, duplicar, copiar, vender, revender ou explorar qualquer parte do site, uso do site, acesso ao site, ou qualquer contato no site através do qual o serviço é fornecido, sem nossa permissão expressa por escrito.\n" +
              "Os títulos usados neste acordo são incluídos apenas por conveniência e não limitam ou afetam os termos.\n"}
          </Section>
          <Section>
            <SectionTitle>
              SEÇÃO 3 – PRECISÃO, INTEGRIDADE E ATUALIZAÇÃO DAS INFORMAÇÕES
            </SectionTitle>
            {"Não somos responsáveis por informações disponibilizadas neste site que não sejam precisas, completas ou atuais. O material desse site é fornecido apenas para fins informativos e não deve ser usado como a única base para tomar decisões sem consultar fontes de informações primárias, mais precisas, mais completas ou mais atuais. Qualquer utilização do material desse site é por sua conta e risco.\n" +
              "Esse site pode conter certas informações históricas. As informações históricas podem não ser atuais e são fornecidas apenas para sua referência. Reservamos o direito de modificar o conteúdo desse site a qualquer momento, mas nós não temos obrigação de atualizar nenhuma informação em nosso site. Você concorda que é de sua responsabilidade monitorar alterações no nosso site.\n"}
          </Section>
          <Section>
            <SectionTitle>
              SEÇÃO 4 – MODIFICAÇÕES DO SERVIÇO E TABELAS DE COEFICIENTES
            </SectionTitle>
            {"Os coeficientes relativos às taxas de juros das nossas linhas de crédito são sujeitos a alterações sem notificação, fatores diários ou políticas das instituições financeiras parceiras. Reservamos o direito de, a qualquer momento, modificar ou descontinuar o produto ou serviço (ou qualquer parte ou conteúdo do mesmo) sem notificação em qualquer momento.\n" +
              "Não nos responsabilizamos por você ou por qualquer terceiro por qualquer modificação, alteração de preço, suspensão ou descontinuação do Serviço.\n"}
          </Section>
          <Section>
            <SectionTitle>SEÇÃO 5 – PRODUTOS OU SERVIÇOS</SectionTitle>
            {"Certos produtos ou serviços podem estar disponíveis exclusivamente online através do site. Tais produtos ou serviços podem ter disponibilidade limitada e são sujeitos a existência de margem, convênio, análise de crédito ou de perfil, de acordo com a Política de Crédito de cada Instituição Financeira parceira.\n" +
              "Fizemos todo o esforço possível da forma mais precisa sobre a transparência das informações sobre os nossos produtos e serviços e adaptamos o layout para responsivo, de modo que possa ser acessado de qualquer dispositivo. Não podemos garantir que a exibição de todas as informações em todos os tipos de dispositivos será precisa.\n" +
              "Reservamos o direito, mas não somos obrigados, a limitar as operações que intermediamos para qualquer pessoa, região geográfica, profissão, perfil ou jurisdição. Podemos exercer esse direito conforme o caso. Reservamos o direito de limitar a disponibilidade de quaisquer produtos ou serviços que oferecemos. \n" +
              "Todas as descrições de produtos ou preços de produtos são sujeitos à alteração a qualquer momento sem notificação, a nosso critério exclusivo. Reservamos o direito de descontinuar qualquer produto a qualquer momento. Qualquer oferta feita por qualquer produto ou serviço nesse site é nula onde for proibido por lei.\n" +
              "Não garantimos que a qualidade de quaisquer produtos, serviços, informações ou outros materiais obtidos por você vão atender às suas expectativas, ou que quaisquer erros no Serviço serão corrigidos.\n"}
          </Section>
          <Section>
            <SectionTitle>
              SEÇÃO 6 – PRECISÃO DE INFORMAÇÕES E CADASTRO
            </SectionTitle>
            {"Reservamos o direito de recusar qualquer envio de proposta de análise de crédito que você nos fizer.\n" +
              "Caso façamos alterações ou cancelemos uma proposta, pode ser que o notifiquemos por e-mail e/ou endereço/número de telefone fornecidos no momento que o cadastro foi feito. \n" +
              "Você concorda em fornecer suas informações de dados bancários e documentos pessoais para formalizar sua proposta conosco. Você concorda em atualizar prontamente seu cadastro e outras informações, incluindo seu e-mail, dados bancários, endereço e telefone, para que possamos completar seu cadastro na solicitação das operações e contatar você quando precisar e/ou se solicitado pelos nossos parceiros.\n" +
              "Para mais detalhes, por favor, revise nossa Política de Privacidade.\n"}
          </Section>
          <Section>
            <SectionTitle>SEÇÃO 7 – FERRAMENTAS OPCIONAIS</SectionTitle>
            {"Podemos te dar acesso a ferramentas de terceiros que não monitoramos e nem temos qualquer controle.\n" +
              "Você reconhece e concorda que nós fornecemos acesso a tais ferramentas ”como elas são” e “conforme suas políticas” sem quaisquer garantias, representações ou condições de qualquer tipo e sem qualquer endosso. Não nos responsabilizamos de forma alguma pelo seu uso de ferramentas opcionais de terceiros.\n" +
              "Qualquer uso de ferramentas opcionais oferecidas através do site é inteiramente por sua conta e risco e você deve se familiarizar e aprovar os termos das ferramentas que são fornecidas por fornecedor(es) terceiro(s).\n" +
              "Também podemos, futuramente, oferecer novos serviços e/ou recursos através do site (incluindo o lançamento de novas ferramentas e recursos). Tais recursos e/ou serviços novos também devem estar sujeitos a esses Termos de Uso.\n"}
          </Section>
          <Section>
            <SectionTitle>SEÇÃO 8 – LINKS DE TERCEIROS</SectionTitle>
            {"Certos produtos, conteúdos e serviços disponíveis pelo nosso site podem incluir materiais de terceiros.\n" +
              "Os links de terceiros neste site podem te redirecionar para sites de terceiros que não são afiliados a nós. Não nos responsabilizamos por examinar ou avaliar o conteúdo ou precisão. Não garantimos e nem temos obrigação ou responsabilidade por quaisquer materiais ou sites de terceiros, ou por quaisquer outros materiais, produtos ou serviços de terceiros.\n" +
              "Não somos responsáveis por quaisquer danos ou prejuízos relacionados com a compra ou uso de mercadorias, serviços, recursos, conteúdo, ou quaisquer outras transações feitas em conexão com quaisquer sites de terceiros. \n" +
              "Por favor, revise com cuidado as políticas e práticas de terceiros e certifique-se que você as entende antes de efetuar qualquer transação. As queixas, reclamações, preocupações ou questões relativas a produtos de terceiros devem ser direcionadas ao terceiro.\n"}
          </Section>
          <Section>
            <SectionTitle>
              SEÇÃO 9 – COMENTÁRIOS, FEEDBACK, ETC. DO USUÁRIO
            </SectionTitle>
            {"Se, a nosso pedido, você enviar certos itens específicos (por exemplo, participação em um concurso), ou sem um pedido nosso, você enviar ideias criativas, sugestões, propostas, planos, ou outros materiais, seja online, por e-mail, pelo correio, ou de outra forma (em conjunto chamados de ‘comentários’), você concorda que podemos, a qualquer momento, sem restrição, editar, copiar, publicar, distribuir, traduzir e de outra forma usar quaisquer comentários que você encaminhar para nós. \n" +
              "Não nos responsabilizamos por: \n" +
              "(1) manter quaisquer comentários em sigilo; \n" +
              "(2) indenizar por quaisquer comentários; ou \n" +
              "(3) responder quaisquer comentários.\n" +
              "Podemos, mas não temos a obrigação, de monitorar, editar ou remover conteúdo que nós determinamos a nosso próprio critério ser contra a lei, ofensivo, ameaçador, calunioso, difamatório, pornográfico, obsceno ou censurável ou que viole a propriedade intelectual de terceiros ou estes Termos de Uso.\n" +
              "Você concorda que seus comentários não violam qualquer direito de terceiros, incluindo direitos autorais, marcas registradas, privacidade, personalidade ou outro direito pessoal ou de propriedade. \n" +
              "Você concorda que os seus comentários não vão conter material difamatório, ilegal, abusivo ou obsceno. Eles também não conterão nenhum vírus de computador ou outro malware que possa afetar a operação deste site ou qualquer site a este relacionado. \n" +
              "Você não pode usar um endereço de e-mail falso, fingir ser alguém diferente de si mesmo, ou de outra forma enganar a nós ou terceiros quanto à origem de quaisquer comentários, podendo ser responsabilizado civil ou penalmente por seus atos. \n" +
              "Você é o único responsável por quaisquer comentários que você faz e pela veracidade deles. Nós não assumimos qualquer responsabilidade ou obrigação por quaisquer comentários publicados por você ou por qualquer terceiro.\n"}
          </Section>
          <Section>
            <SectionTitle>SEÇÃO 10 – INFORMAÇÕES PESSOAIS</SectionTitle>
            {
              "O envio de suas informações pessoais através do nosso site é regido pela nossa Política de privacidade. Ver nossa Política de Privacidade.\n"
            }
          </Section>
          <Section>
            <SectionTitle>
              SEÇÃO 11 – ERROS, IMPRECISÕES E OMISSÕES
            </SectionTitle>
            {"Ocasionalmente, podem haver informações no nosso site ou produtos que contenham erros tipográficos, imprecisões ou omissões que possam relacionar-se a descrições de produtos, taxas de juros, promoções, ofertas, prazo e disponibilidade. \n" +
              "Reservamos o direito de corrigir quaisquer erros, imprecisões ou omissões, e de alterar ou atualizar informações ou cancelar solicitações de serviços caso qualquer informação no site seja imprecisa, a qualquer momento e sem aviso prévio (até mesmo depois de você ter enviado a sua solicitação).\n" +
              "Não assumimos nenhuma obrigação de atualizar, alterar ou esclarecer informações no site, incluindo, sem limitação, a informações sobre taxas de juros, exceto conforme exigido por lei. \n" +
              "Nenhuma atualização específica ou data de atualização no site, deve ser utilizada para indicar que todas as informações do site tenham sido modificadas ou atualizadas.\n"}
          </Section>
          <Section>
            <SectionTitle></SectionTitle>
            {""}
          </Section>
        </Content>
      </Grid>
      <FooterConnected />
    </FullHeightContainer>
  );
};

export default TermosUsoPage;
