import { styled, Container } from "@mui/material";
import { transientOptions } from "../../styles/theme";

const StyledContainer = styled(Container, transientOptions)`
  display: flex;
  flex-direction: ${({ _direction_ = "row" }) => _direction_};
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme, _color_ }) =>
    _color_ ?? theme.colors.background};
`;

const FullHeightContainer = (props) => <StyledContainer {...props} />;

export default FullHeightContainer;
