import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import validators from "../../utils/validators";
import { actions } from "../../actions/usuarios.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";
import useCompleteForm from "../../hooks/useCompleteForm";
import {
  getListaParceirosNomeFantasia,
  getUsuarioLogado,
  isCarregandoDetalhesUsuario,
  isCarregandoListaParceiros,
  isCriandoUsuario,
} from "../../selectors/usuario.selectors";
import CadastroUsuarioPage from "./CadastroUsuarioPage";
import { formValues as formValuesDadosUsuario } from "./DadosUsuarioForm";
import { replaceNullishsWithEmpties } from "../../utils/basic";

const CadastroUsuarioPageConnected = () => {
  const dispatch = useDispatch();

  const iSubmitting = useSelector(isCriandoUsuario);
  const parceiros = useSelector(getListaParceirosNomeFantasia);
  const usuario = useSelector(getUsuarioLogado);
  const isLoadingUsuario = useSelector(isCarregandoDetalhesUsuario);
  const isLoadingParceiros = useSelector(isCarregandoListaParceiros);
  const isLoading = isLoadingParceiros || isLoadingUsuario;

  const data = useMemo(
    () => ({
      nome: "",
      email: "",
      cpfCnpj: "",
      telefone: "",
      tipo: usuario?.idParceiro ? "P" : "",
      perfil: "",
      parceiro: usuario?.idParceiro ?? "",
      timeParceiro: "",
    }),
    [usuario]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      nome: validators.string({ required: true }),
      email: validators.email({ required: true }),
      cpfCnpj: validators.cpfCnpj({ required: true }),
      telefone: validators.phone({ required: true }),
      tipo: validators.string({ required: true }),
      perfil: validators.string({ required: true }),
      parceiro: validators.string({
        required: false,
        custom: {
          requiredIdParceiro: (v, f) => {
            const tipo = f["tipo"];

            if (tipo === "O") return true;
            if (v !== undefined && v !== null && v !== "") return true;
            return "Campo obrigatório";
          },
        },
      }),
    }),
    initialValues: () => ({
      ...formValuesDadosUsuario.defaultValues(),
      ...data,
    }),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.criarUsuario.request({
            ...values,
          })
        );
      },
      [dispatch]
    ),
  });

  const { reset, defaultValues, watch, setValue } = formProps;

  useEffect(() => {
    if (!!data) {
      reset({
        ...defaultValues,
        ...replaceNullishsWithEmpties({
          value: data,
          blackList: ["banco"],
        }),
      });
    }
  }, [reset, defaultValues, usuario, data]);

  const tipo = watch("tipo");
  const idParceiro = watch("parceiro");

  const times = parceiros.find((p) => p.codigo === idParceiro)?.times ?? [];

  const handleChangeTipo = useCallback(
    (v) => {
      setValue("perfil", "");
      setValue("parceiro", "");
      setValue("timeParceiro", "");
    },
    [setValue]
  );
  const handleChangeParceiro = useCallback(
    (v) => {
      setValue("timeParceiro", "");
    },
    [setValue]
  );

  const handleBack = useCallback(() => {
    dispatch(routeActions.redirectTo(routes.USUARIOS));
  }, [dispatch]);

  return (
    <CadastroUsuarioPage
      submitting={iSubmitting}
      loading={isLoading}
      handleBack={handleBack}
      handleSubmit={handleSubmit}
      tipoUsuario={usuario.tipo}
      data={data}
      parceiros={parceiros}
      formProps={formProps}
      handleChangeTipo={handleChangeTipo}
      handleChangeParceiro={handleChangeParceiro}
      tipo={tipo}
      times={times}
    />
  );
};

export default CadastroUsuarioPageConnected;
