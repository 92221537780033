import ValidarRegrasSection from "./ValidarRegrasSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getRegrasSelecionadasCompletas,
  isCriandoRegrasSelecionadas,
} from "../../selectors/simulador.selectors";
import { useCallback, useState } from "react";
import { actions } from "../../actions/simulador.actions";

const ValidarRegrasSectionConnected = () => {
  const dispatch = useDispatch();
  const selecionadas = useSelector(getRegrasSelecionadasCompletas);
  const submitting = useSelector(isCriandoRegrasSelecionadas);

  const [correntista, setCorrentista] = useState(
    selecionadas.map((a) => false)
  );

  const handleChangeCorrentista = useCallback(
    (i) => {
      setCorrentista(correntista.map((a, ii) => (i === ii ? !a : a)));
    },
    [setCorrentista, correntista]
  );

  const handleGoBack = useCallback(() => {
    dispatch(actions.navegar(3));
  }, [dispatch]);

  const handleStart = useCallback(
    (id) => {
      dispatch(
        actions.comecarProposta.request({
          id,
          correntista,
        })
      );
    },
    [dispatch, correntista]
  );
  return (
    <ValidarRegrasSection
      selecionadas={selecionadas}
      handleGoBack={handleGoBack}
      correntista={correntista}
      handleStart={handleStart}
      submitting={submitting}
      handleChangeCorrentista={handleChangeCorrentista}
    />
  );
};

export default ValidarRegrasSectionConnected;
