import ListaUsuariosPage from "./ListaUsuariosPage";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";
import {
  getUsuarios,
  isCarregandoUsuarios,
  getUsuarioLogado,
  isCarregandoListaParceiros,
  getListaParceiros,
} from "../../selectors/usuario.selectors";

const ListaUsuariosPageConnected = () => {
  const dispatch = useDispatch();
  const loadingUsuarios = useSelector(isCarregandoUsuarios);
  const loadingParceiros = useSelector(isCarregandoListaParceiros);
  const loading = loadingUsuarios || loadingParceiros;
  const data = useSelector(getUsuarios);
  const parceiros = useSelector(getListaParceiros);

  const usuarioLogado = useSelector(getUsuarioLogado);
  const [filtered, setFiltered] = useState(data ?? []);
  const [total, setTotal] = useState(data ?? []);
  const [filters, setFilters] = useState({
    dirty: false,
    situacao: "X",
    perfil: "X",
    pesquisar: "",
    parceiro: null,
    page: 0,
  });

  const handleNewUser = useCallback(() => {
    dispatch(
      routeActions.redirectTo(routes.CADASTRO_USUARIO, { id: usuarioLogado.id })
    );
  }, [dispatch, usuarioLogado]);

  const handleClick = useCallback(
    (usuario) => {
      dispatch(
        routeActions.redirectTo(routes.DETALHES_USUARIO, { id: usuario.id })
      );
    },
    [dispatch]
  );
  const handlePaginationClick = useCallback((page) => {
    setFilters((currentValues) => ({ ...currentValues, page }));
  }, []);

  useEffect(() => {
    const f1 = data.filter((v) => {
      const { situacao, pesquisar } = filters;

      if (situacao !== "X" && situacao !== v.situacao) {
        return false;
      }

      return !(
        !!pesquisar &&
        !v.nome.toUpperCase().includes(pesquisar.toUpperCase()) &&
        !v.email.toUpperCase().includes(pesquisar.toUpperCase())
      );
    });

    const f2 = f1.filter((v) => {
      const { parceiro } = filters;

      if (!parceiro) return true;
      return v.parceiro === parceiro.codigo;
    });

    const f3 = f2.filter((v) => {
      const { perfil } = filters;

      return !(perfil !== "X" && perfil !== v.perfil);
    });

    const f4 = f3.filter((v, i) => {
      const startPage = filters.page * 10;
      const endPage = (filters.page + 1) * 10;

      return i >= startPage && i < endPage;
    });
    setTotal(f3);
    setFiltered(f4);
  }, [filters, filters.page, data, setFiltered, setTotal]);

  return (
    <ListaUsuariosPage
      isOperacao={!usuarioLogado.idParceiro}
      loading={loading}
      handlePaginationClick={handlePaginationClick}
      filters={filters}
      setFilters={setFilters}
      filtered={filtered}
      total={total}
      handleClick={handleClick}
      handleNewUser={handleNewUser}
      page={filters.page}
      parceiros={parceiros}
    />
  );
};

export default ListaUsuariosPageConnected;
