import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDetalhesOperacao, getDialogAtribuirResponsavel } from "../../selectors/operacao.selectors";
import { actions } from "../../actions/operacao.actions";
import AtribuirResponsavelDialog from "./AtribuirResponsavelDialog";
import validators from "../../utils/validators";
import useCompleteForm from "../../hooks/useCompleteForm";
import { getRouteArguments } from "../../selectors/routes.selectors";

const AtribuirResponsavelDialogConnected = () => {
  const dispatch = useDispatch();
  const { show, data, submitting, loading } = useSelector(getDialogAtribuirResponsavel);
  let props = useSelector(getRouteArguments);
  const detalhesOperacao = useSelector(getDetalhesOperacao);

  const handleClose = useCallback(() => {
    dispatch(actions.exibirDialogAtribuirResponsavel(false, null));
  }, [dispatch]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      idUsuarioResponsavel: validators.object({ required: true }),
    }),
    initialValues: () => ({
      idUsuarioResponsavel: null,
    }),
    handleSubmit: useCallback((values) => {
      dispatch(actions.atualizarResponsavel.request({ ...values, id: props.id }));
    }, [props]),
  });

  useEffect(() => {
    if (data) {
      const option = data.filter(option => option.nome == detalhesOperacao.nomeContatoParceiro)[0]?.id
      formProps.setValue("idUsuarioResponsavel", option)
    } else {
      formProps.reset();
    }
  }, [data])

  return (
    <AtribuirResponsavelDialog
      open={show}
      data={data}
      submitting={submitting}
      handleClose={handleClose}
      formProps={formProps}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  );
};

export default AtribuirResponsavelDialogConnected;