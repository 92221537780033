import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../actions/parceiro.actions";
import ComissionamentoParceiroDialog from "./ComissionamentoParceiroDialog";
import { getDialogComissionamentoParceiro } from "../../selectors/parceiro.selectors";

const ComissionamentoParceiroDialogConnected = () => {
  const dispatch = useDispatch();
  const { show, data } = useSelector(getDialogComissionamentoParceiro);

  const handleClose = useCallback(() => {
    dispatch(actions.exibirDialogComissionamentoParceiro(false, data));
  }, [dispatch, data]);

  return (
    <ComissionamentoParceiroDialog
      open={show}
      data={data}
      handleClose={handleClose}
    />
  );
};

export default ComissionamentoParceiroDialogConnected;
