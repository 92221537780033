import { useDispatch, useSelector } from "react-redux";
import {
  getDetalhesParceiro,
  getDialogosDisponiveis,
  getDominioParceiros,
  getDominioPromotores,
  isAtualizandoDetalhesParceiro,
  isValidandoIdentificador,
} from "../../selectors/parceiro.selectors";
import { useCallback, useState } from "react";
import useCompleteForm from "../../hooks/useCompleteForm";
import { actions } from "../../actions/parceiro.actions";
import validators from "../../utils/validators";
import { toast } from "react-toastify";
import { actionToPath } from "redux-first-router";
import {
  actions as routeActions,
  types as routes,
} from "../../actions/rotas.actions";
import { routePaths } from "../../routes";
import queryString from "query-string";
import { getUsuarioLogado } from "../../selectors/usuario.selectors";
import { getMenuDisposition } from "../../selectors/tema.selectors";
import ConfiguracoesTab from "./ConfiguracoesTab";

const ConfiguracoesTabConnected = () => {
  const dispatch = useDispatch();
  const submitting = useSelector(isAtualizandoDetalhesParceiro);
  const validating = useSelector(isValidandoIdentificador);
  const data = useSelector(getDetalhesParceiro);
  const parceiros = useSelector(getDominioParceiros);
  const promotores = useSelector(getDominioPromotores);
  const usuario = useSelector(getUsuarioLogado);
  const dialogosDisponiveis = useSelector(getDialogosDisponiveis);
  const isMenuOpen = useSelector(getMenuDisposition);

  const [validated, setValidated] = useState(true);
  const [url, setUrl] = useState(data.identificadorExterno ?? "");
  const [formProps, handleSubmit] = useCompleteForm({
    rules: (form) => ({
      identificadorExterno: validators.string({ required: false }),
      usuarioResponsavelLeads: validators.object({ required: true }),
      tipoNotificacao: validators.object({ required: true }),
    }),
    initialValues: () => ({
      identificadorExterno: "",
      calculadoraExterna: false,
      indicacao: false,
      usuarioResponsavelLeads: null,
      tipoNotificacao: null,
      ...data,
    }),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.atualizarCondicaoComercial.request({
            ...values,
            parceiroIndicacao: values.parceiroIndicacao?.codigo,
          })
        );
      },
      [dispatch]
    ),
  });

  const { setValue } = formProps;

  const handleUrlBlur = useCallback(
    (v) => {
      setUrl(v);
      if (!!v) {
        dispatch(
          actions.validarIdentificador.request({
            id: data.id,
            identificador: v,
            callback: setValidated,
          })
        );
      } else {
        setValue("indicacao", false);
        setValue("calculadoraExterna", false);
      }
    },
    [dispatch, data, setUrl, setValidated, setValue]
  );

  const isIndicacao = formProps.watch("indicacao");
  const isCalculadoraExterna = formProps.watch("calculadoraExterna");

  const pathCalculadora = !url
    ? ""
    : actionToPath(
        routeActions.redirectTo(routes.CALCULADORA_EXTERNA, {
          identificador: url,
        }),
        routePaths,
        queryString
      );

  const linkCalculadora =
    window.location.protocol + "//" + window.location.host + pathCalculadora;

  const pathIndicacao = !url
    ? ""
    : actionToPath(
        routeActions.redirectTo(routes.CADASTRO_PARCEIRO, {
          identificador: url,
        }),
        routePaths,
        queryString
      );

  const linkIndicacao =
    window.location.protocol + "//" + window.location.host + pathIndicacao;

  const handleCopy = useCallback((link) => {
    navigator.clipboard.writeText(link).then(() => toast.info("Link copiado"));
  }, []);

  const isOperacao = usuario.perfil === "OPERACAO";
  const editable =
    (dialogosDisponiveis["DADOS_COMERCIAIS"] === "E" ||
      dialogosDisponiveis["DADOS_COMERCIAIS"] === "O") &&
    isOperacao;

  return (
    <ConfiguracoesTab
      submitting={submitting}
      formProps={formProps}
      handleSubmit={handleSubmit}
      validating={validating}
      url={url}
      validated={validated}
      editable={editable}
      isMenuOpen={isMenuOpen}
      isOperacao={isOperacao}
      handleUrlBlur={handleUrlBlur}
      parceiros={parceiros}
      promotores={promotores}
      isIndicacao={isIndicacao}
      isCalculadoraExterna={isCalculadoraExterna}
      linkCalculadora={linkCalculadora}
      linkIndicacao={linkIndicacao}
      handleCopy={handleCopy}
    />
  );
};

export default ConfiguracoesTabConnected;
