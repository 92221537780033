import SelecionarProdutoSection from "./SelecionarProdutoSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getCalculadorasDisponiveis,
  getCalculadoraSelecionada,
} from "../../selectors/calculadora.selectors";
import { useCallback, useState } from "react";
import { actions } from "../../actions/calculadora.actions";

const SelecionarProdutoSectionConnected = () => {
  const dispatch = useDispatch();
  const { loading, data } = useSelector(getCalculadorasDisponiveis);
  const selecionada = useSelector(getCalculadoraSelecionada);

  const defaultProduct = !selecionada
    ? null
    : data.find((p) => !!p.regras.find((g) => g.id === selecionada.id));

  const [selectedProduct, setSelectedProduct] = useState(defaultProduct);
  const [selectedGroup, setSelectedGroup] = useState(selecionada);

  const handleClickProduto = useCallback(
    (p) => {
      if (!selectedProduct) {
        setSelectedProduct(p);
        if (p.regras.length === 1) {
          setSelectedGroup(p.regras[0]);
        }
      } else {
        if (selectedProduct.id !== p.id) {
          setSelectedProduct(p);
          if (p.regras.length === 1) {
            setSelectedGroup(p.regras[0]);
          } else {
            setSelectedGroup(null);
          }
        }
      }
    },
    [selectedProduct, setSelectedProduct, setSelectedGroup]
  );

  const handleNext = useCallback(
    (grupo) => {
      dispatch(actions.selecionarCalculadora(grupo));
      dispatch(actions.navegar(1));
    },
    [dispatch]
  );

  return (
    <SelecionarProdutoSection
      produtos={data}
      loading={loading}
      handleNext={handleNext}
      selectedProduct={selectedProduct}
      handleClickProduto={handleClickProduto}
      selectedGroup={selectedGroup}
      setSelectedGroup={setSelectedGroup}
    />
  );
};

export default SelecionarProdutoSectionConnected;
