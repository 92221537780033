import {
  Grid,
  Paper,
  styled,
  TableBody,
  TableContainer as MTableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { BodyTableRow, SizedTableCell, Table } from "../../components/tables";
import React from "react";
import { Card, Form, IconButton, Title, Tooltip } from "../../components";
import formatters from "../../utils/formatters";
import { SITUACAO } from "../../constants/parceiro.constants";
import { AttachMoney } from "@mui/icons-material";

const NormalText = styled("span")`
  font-weight: 300;
`;

const TableContainer = styled(MTableContainer)`
  box-shadow: none;
`;

const ParceirosSubstabelecidosTab = ({ data, handleDialogComissionamento }) => {
  return (
    <Grid item xs={12}>
      <Form onSubmit={null}>
        <Grid container>
          <Grid item xs={12} md={12} xl={6}>
            <Card>
              <Title as={"h3"} _margin_={"0 0 1rem 0"}>
                Parceiros Substabelecidos
              </Title>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, margin: 0 }}>
                  <TableHead>
                    <TableRow>
                      <SizedTableCell width={135}>CNPJ</SizedTableCell>
                      <SizedTableCell style={{ minWidth: 60 }}>
                        Nome
                      </SizedTableCell>
                      <SizedTableCell style={{ minWidth: 60 }}>
                        Representante
                      </SizedTableCell>
                      <SizedTableCell style={{ minWidth: 60 }}>
                        Situação
                      </SizedTableCell>
                      <SizedTableCell width={50} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.parceirosSubstabelecidos.map((parceiro) => (
                      <BodyTableRow key={parceiro.id}>
                        <SizedTableCell style={{ minWidth: 60 }}>
                          <NormalText>
                            {formatters.strings.cnpj(parceiro.cnpj)}
                          </NormalText>
                        </SizedTableCell>
                        <SizedTableCell style={{ minWidth: 60 }}>
                          <NormalText>{parceiro.nomeFantasia}</NormalText>
                        </SizedTableCell>
                        <SizedTableCell style={{ minWidth: 60 }}>
                          <NormalText>{parceiro.nomeRepresentante}</NormalText>
                        </SizedTableCell>
                        <SizedTableCell style={{ minWidth: 60 }}>
                          <NormalText>{SITUACAO[parceiro.situacao]}</NormalText>
                        </SizedTableCell>
                        <SizedTableCell width={50}>
                          <IconButton
                            onClick={() =>
                              handleDialogComissionamento(true, parceiro)
                            }
                            disabled={parceiro.comissionamentos.length === 0}
                          >
                            <Tooltip arrow title={"Tabela de Comissionamento"}>
                              <AttachMoney />
                            </Tooltip>
                          </IconButton>
                        </SizedTableCell>
                      </BodyTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </Grid>
  );
};

export default ParceirosSubstabelecidosTab;
