import { Grid, styled } from "@mui/material";
import React from "react";
import { Button, Card, Form, GoBack, Title } from "../../../../../components";
import ControlledAutocomplete from "../../../../../components/inputs/ControlledAutocomplete";
import {
  AccountTextField,
  ControlledSwitch,
  ControlledTextField,
  IntegerTextField,
} from "../../../../../components/inputs";
import Stepper from "../../../preenchimento/Stepper";
import { transientOptions } from "../../../../../styles/theme";

const formatWithCodeAndDescription = (option, domain) => {
  if (!!option) {
    const domainOption = domain.find(
      (d) => d.id === option.id || d.id === option
    );
    if (!!domainOption) {
      return `${domainOption.codigo} - ${domainOption.nome}`;
    }
  }
  return "";
};

const StyledWarning = styled("h1", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-weight: 700;
  display: ${({ _display_ = "block" }) => _display_};
  text-align: ${({ _align_ = "left" }) => _align_};
  color: ${({ theme, _color_ = "error" }) => theme.colors[_color_]._500};
  margin: ${({ _margin_ = "0" }) => _margin_};
  white-space: pre;
`;

const DadosOutrasInformacoesCompradorDefaultForm = ({
  fluxo,
  comprador,
  bancos,
  formProps,
  handleSubmit,
  submitting,
  events,
  handleGoTo4,
}) => {
  //TODO Migrar Procuração e FGTS pro 1o bloco
  // Mudar nome do 5 bloco pra "Conta corrente"
  // Colocar instrução no topo do bloco e tirar obrigatoriedade
  // Fase Conta corrente
  // Abrir apenas se não tiver conta
  // Obrigar preenchimento do bloco Conta Corrente
  // Mensagem nessa tela **O preenchimento dos dados bancários serão obrigatórios posteriormente**
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={704}>
        <GoBack
          handleClick={handleGoTo4}
          text={"Preencha as informações do comprador"}
        />
        <Grid item container alignItems={"center"}>
          <Grid item flex={1}>
            <Stepper current={4} events={events} fluxo={fluxo} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {comprador.principal && (
                    <>
                      <Grid item xs={12}>
                        <StyledWarning as={"h5"}>
                          {
                            "O preenchimento dos dados bancários pode ser feito posteriormente.\nCaso você já tenha uma conta aberta, pode preenchê-la agora."
                          }
                        </StyledWarning>
                      </Grid>
                      <Grid item xs={12}>
                        <ControlledAutocomplete
                          label="Banco"
                          domain={bancos}
                          idField={"id"}
                          getOptionLabel={(option) =>
                            formatWithCodeAndDescription(option, bancos)
                          }
                          name={"bancoConta"}
                          formProps={formProps}
                          autocompleteProps={{
                            clearText: "Limpar",
                            loadingText: "Carregando opções",
                            noOptionsText: "Nenhuma opção disponível",
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <ControlledTextField
                          label="Agência"
                          name={"agenciaConta"}
                          inputProps={{
                            maxLength: 4,
                            allowLeadingZeros: true,
                            asString: true,
                          }}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: IntegerTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={8} md={4}>
                        <ControlledTextField
                          label="Conta e Dígito"
                          name={"numeroConta"}
                          inputProps={{ maxLength: 15 }}
                          formProps={formProps}
                          InputProps={{
                            inputComponent: AccountTextField,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ControlledSwitch
                          name={"autorizaTarifaAvaliacao"}
                          label={"Autoriza o débito da tarifa de avaliação?"}
                          tooltip={
                            "Reforce com o (s) participante (s) que após a conclusão da prestação do serviço de avaliação do imóvel, a Tarifa de Avaliação de Garantia deverá ser paga, caso não haja isenção. O pagamento dessa tarifa é devido e não está vinculado a assinatura do contrato, inclusive deverá ser pago nas hipóteses de cancelamento ou desistência desta proposta pelo cliente, desde que o serviço tenha sido prestado. Com este aceite você declara que o seu cliente autoriza o Credor a realizar o débito do valor dessa tarifa na conta corrente indicada nesta proposta. Em casos de empréstimo, há a opção de financiar o valor da tarifa de avaliação do imóvel (caso a operação seja contratada), exceto portabilidade de empréstimo.\n" +
                            "\n" +
                            "Atenção:\n" +
                            "a) as condições desta operação serão validadas no momento da confirmação de valores, através de contato telefônico ou e-mail da Instituição;\n" +
                            "b) para a liberação dos recursos da operação será necessário a entrega da via física do contrato registrado e matrícula do imóvel atualizada;\n" +
                            "c) a liberação dos recursos da operação ocorrerá no prazo de até 10 (cinco) dias úteis após a comprovação do registro da garantia a favor do Credor.\n" +
                            "d) o(s) participante(s) poderão obter, gratuita e eletronicamente, a Certidão Negativa de Débitos Trabalhistas (CNDT), nos termos do artigo 642-A, da Consolidação das Leis do Trabalho (CLT), disponível em www.tsj.jus.br."
                          }
                          formProps={formProps}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ControlledSwitch
                          name={"autorizaLimite"}
                          label={
                            "Autoriza a utilização do limite da conta para débito da parcela?"
                          }
                          tooltip={
                            "Ao aceitar essa opção, caso não haja saldo suficiente quando realizarmos o débito da parcela em sua conta-corrente, debitaremos o valor do limite da conta (se tiver limite disponível no valor da parcela). Isso facilita para que as parcelas não fiquem em atraso."
                          }
                          formProps={formProps}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ControlledSwitch
                          name={"autorizaAtraso"}
                          label={
                            "Autoriza o débito de parcelas em atraso em sua conta-corrente?"
                          }
                          tooltip={
                            "Ao aceitar essa opção, caso não haja saldo suficiente para realizar o débito da parcela na conta-corrente cadastrada no dia de vencimento, faremos novas tentativas de débitos, para que a parcela deixe de estar em atraso."
                          }
                          formProps={formProps}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button type={"submit"} fullWidth loading={submitting}>
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosOutrasInformacoesCompradorDefaultForm;
