import React from "react";
import DadosBasicosCompradorItauFormConnected from "./comprador/DadosBasicosCompradorItauForm.connected";
import HubItauConnected from "./HubItau.connected";
import DadosProfissionaisCompradorItauFormConnected from "./comprador/DadosProfissionaisCompradorItauForm.connected";
import DadosEstadoCivilCompradorItauFormConnected from "./comprador/DadosEstadoCivilCompradorItauForm.connected";
import DadosEnderecoCompradorItauFormConnected from "./comprador/DadosEnderecoCompradorItauForm.connected";
import DadosBasicosVendedorPfItauFormConnected from "./vendedor/DadosBasicosVendedorPfItauForm.connected";
import DadosEstadoCivilVendedorItauFormConnected from "./vendedor/DadosEstadoCivilVendedorItauForm.connected";
import DadosEnderecoVendedorPfItauFormConnected from "./vendedor/DadosEnderecoVendedorPfItauForm.connected";
import DadosBasicosVendedorPjItauFormConnected from "./vendedor/DadosBasicosVendedorPjItauForm.connected";
import DadosEnderecoVendedorPjItauFormConnected from "./vendedor/DadosEnderecoVendedorPjItauForm.connected";
import DadosEnderecoImovelItauFormConnected from "./imovel/DadosEnderecoImovelItauForm.connected";
import DocumentosTabConnected from "./DocumentosTab.connected";
import OutrasInformacoesImovelItauFormConnected from "./imovel/OutrasInformacoesImovelItauForm.connected";
import DadosContatoImovelItauFormConnected from "./imovel/DadosContatoImovelItauForm.connected";
import DadosOutrasInformacoesCompradorItauFormConnected from "./comprador/DadosOutrasInformacoesCompradorItauForm.connected";
import ContasLiberacaoVendedorItauFormConnected from "./ContasLiberacaoVendedorItauForm.connected";
import DadosFgtsItauFormConnected from "./comprador/DadosFgtsItauForm.connected";
import DadosDpsDefaultFormConnected from "../default/comprador/DadosDpsDefaultForm.connected";

import DadosSociosPjVendedorItauFormConnected from "./vendedor/DadosSociosPjVendedorItauForm.connected";
import DadosBasicosSociosItauFormConnected from "./vendedor/socios/DadosBasicosSociosItauForm.connected";

const FluxoAquisicaoItau = (props) => {
  return (
    <>
      {props.step === 0 && <HubItauConnected {...props} />}
      {props.step === 1 && (
        <DadosBasicosCompradorItauFormConnected {...props} />
      )}
      {props.step === 2 && (
        <DadosEnderecoCompradorItauFormConnected {...props} />
      )}
      {props.step === 3 && (
        <DadosProfissionaisCompradorItauFormConnected {...props} />
      )}
      {props.step === 4 && (
        <DadosEstadoCivilCompradorItauFormConnected {...props} />
      )}
      {props.step === 5 && (
        <DadosOutrasInformacoesCompradorItauFormConnected {...props} />
      )}
      {props.step === 7 && <DadosFgtsItauFormConnected {...props} />}
      {props.step === 9 && <DadosDpsDefaultFormConnected {...props} />}
      {props.step === 10 && (
        <DadosBasicosVendedorPfItauFormConnected {...props} />
      )}
      {props.step === 11 && (
        <DadosEnderecoVendedorPfItauFormConnected {...props} />
      )}
      {props.step === 12 && (
        <DadosEstadoCivilVendedorItauFormConnected {...props} />
      )}
      {props.step === 20 && (
        <DadosBasicosVendedorPjItauFormConnected {...props} />
      )}
      {props.step === 21 && (
        <DadosEnderecoVendedorPjItauFormConnected {...props} />
      )}
      {props.step === 22 && (
        <DadosSociosPjVendedorItauFormConnected {...props} />
      )}
      {props.step === 60 && <DadosBasicosSociosItauFormConnected {...props} />}
      {props.step === 100 && (
        <DadosEnderecoImovelItauFormConnected {...props} />
      )}
      {props.step === 101 && (
        <OutrasInformacoesImovelItauFormConnected {...props} />
      )}
      {props.step === 102 && <DadosContatoImovelItauFormConnected {...props} />}
      {props.step === 998 && (
        <ContasLiberacaoVendedorItauFormConnected {...props} />
      )}
      {props.step === 999 && <DocumentosTabConnected {...props} />}
    </>
  );
};

export default FluxoAquisicaoItau;
