import React from "react";
import { NumberFormatBase } from "react-number-format";
import { urlSafeString } from "../../utils/basic";

const formatter = urlSafeString({ trimWhitespace: false });
const formatUrl = (value) => {
  return formatter.generate(value);
};
const identity = (v) => v;

const UrlSafeTextField = React.forwardRef(({ onChange, ...other }, ref) => {
  return (
    <NumberFormatBase
      {...other}
      getInputRef={ref}
      removeFormatting={identity}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.formattedValue,
          },
        });
      }}
      format={formatUrl}
    />
  );
});

export default UrlSafeTextField;
