import CommonPage from "../../CommonPage";
import { Grid, Skeleton, styled } from "@mui/material";
import React from "react";
import FluxoAquisicaoItauConnected from "./itau/FluxoAquisicaoItau.connected";
import FluxoAquisicaoSantanderConnected from "./santander/FluxoAquisicaoSantander.connected";
import OperationHeaderConnected from "../../../components/presentation/OperationHeader.connected";
import FluxoAquisicaoInterConnected from "./inter/FluxoAquisicaoInter.connected";
import FluxoAquisicaoBradescoConnected from "./bradesco/FluxoAquisicaoBradesco.connected";
import FluxoAquisicaoGalleriaConnected from "./galleria/FluxoAquisicaoGalleria.connected";
import FluxoDefaultConnected from "./default/FluxoDefault.connected";
import FluxoFintechsConnected from "./fintechs/FluxoFintechs.connected";
import FluxoC6Connected from "./c6/FluxoC6.connected";
import FluxoLibraConnected from "./libra/FluxoLibra.connected";

const SkeletonCard = styled(Skeleton)`
  width: 100%;
  height: 239px;
  border-radius: 1rem;
`;

const EscolherFluxoPreenchimento = ({ instituicao, tipoProduto }) => {
  switch (instituicao) {
    case "itau":
      return <FluxoAquisicaoItauConnected />;
    case "santander":
      return <FluxoAquisicaoSantanderConnected />;
    case "inter":
      return <FluxoAquisicaoInterConnected />;
    case "bradesco":
      return <FluxoAquisicaoBradescoConnected />;
    case "galleria":
      return <FluxoAquisicaoGalleriaConnected />;
    case "c6":
      return <FluxoC6Connected />;
    case "libra":
      return <FluxoLibraConnected />;
    case "cashme":
    case "creditas":
    case "bari":
      return <FluxoFintechsConnected />;
    default:
      return <FluxoDefaultConnected />;
  }
};

const PreenchimentoOperacaoPage = ({ loading, data }) => {
  const isLoading = !!loading || !data;

  return (
    <CommonPage selected={"clientes"}>
      {isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OperationHeaderConnected data={data.operacao} timeline detalhes />
          </Grid>
          <EscolherFluxoPreenchimento
            instituicao={data.operacao.codigoInstituicao}
            tipoProduto={data.operacao.tipoProduto}
          />
        </Grid>
      )}
    </CommonPage>
  );
};

export default PreenchimentoOperacaoPage;
