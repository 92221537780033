import {
  Grid,
  styled,
} from "@mui/material";
import React from "react";
import { Button, Card, Form, GoBack, MenuItem, Title } from "../../../../components";
import { ControlledSelect, ControlledSwitch } from "../../../../components/inputs";
import { transientOptions } from "../../../../styles/theme";
import { InfoOutlined } from "@mui/icons-material";

const DisclaimerContainer = styled("div", transientOptions)`
  border-radius: ${({ theme }) => theme.borderRadiuses.disclaimer};
  border: 1px dashed
    ${({ theme, _color_ = "secondary" }) => theme.colors[_color_]._700};
  background-color: ${({ theme, _color_ = "primary" }) =>
    theme.colors[_color_]._100};
  color: ${({ theme, _color_ = "secondary" }) => theme.colors[_color_]._600};
  padding: 1rem 1.5rem;
  margin: 0.75rem 0 0;
`;
const Disclaimer = styled("p", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 500;
  color: inherit;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const OutrasInformacoesBradescoForm = ({
  formProps,
  handleSubmit,
  submitting,
  handleGoBack,
  aceitaRegistroEletronico,
}) => {
  return (
    <Grid item container justifyContent={"center"} mb={4}>
      <Grid item container spacing={3} maxWidth={880}>
        <GoBack
          handleClick={handleGoBack}
          text={"Preencha as outras informações antes de prosseguir"}
        />
        <Grid item container spacing={3} marginBottom={8}>
          <Grid item container justifyContent={"center"}>
            <Grid item xs={12}>
              <Card>
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Title as={"h5"}>Outras Informações</Title>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ControlledSelect
                        name={"diaPagamento"}
                        formProps={formProps}
                        label={"Dia de vencimento das prestações:"}
                        tooltip={"Escolha um dia de 1 a 28 para o vencimento das prestações do contrato."}
                      >
                        {Array.from({ length: 28 }, (_, i) => i + 1)?.map((state, index) => (
                          <MenuItem key={index} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </ControlledSelect>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <ControlledSwitch
                        name={"aceitaRegistroEletronico"}
                        formProps={formProps}
                        label={"Os compradores aceitam registro eletrônico? (Assinatura Digital)"}
                        tooltip={"O(s) Comprador(es) aceita(m) a opção de realizar(em) a(s) assinatura(s) e o registro do contrato em formato digital no Serviço de Registro de Imóveis, nos termos da MP 2.200-2/2001, art. 10, § 1o.* Sim Não * Optando por esse formato, o(s) Comprador(es) e Vendedor(es) precisarão preencher o formulário 4840-1217 - Declaração das Partes - Aceite Registro Eletrônico (Assinatura Digital). Opção não disponível para operações com “Interveniente Quitante” (quando outro banco tem que conceder quitação no contrato)."}
                      />
                    </Grid>
                    {aceitaRegistroEletronico &&
                      <Grid item xs={12}>
                        <DisclaimerContainer _color_={"primary"}>
                          <Disclaimer>
                            <InfoOutlined />
                            A opção "SIM" para Aceite de Registro Eletrônico (Assinatura Digital) foi selecionada no formulário. Essa opção deve ser escolhida apenas se as partes estiverem fora do país ou impossibilitadas de comparecer à agência para assinatura física do contrato. Caso não se enquadre nessas condições, por favor, ajuste a opção para "NÃO", caso se enquadre, preencha o formulário Aceite de Registro Eletrônico (Assinatura Digital) modelo disponível na plataforma.
                          </Disclaimer>
                        </DisclaimerContainer>
                      </Grid>
                    }
                    <Grid item container justifyContent={"center"} mt={1}>
                      <Grid item xs={12} md={4}>
                        <Button
                          type={"submit"}
                          fullWidth
                          loading={submitting}
                        >
                          CONTINUAR
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OutrasInformacoesBradescoForm;
