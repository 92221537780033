import FluxoAquisicaoGalleria from "./FluxoAquisicaoGalleria";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getPreenchimentoOperacao } from "../../../../selectors/operacao.selectors";

const FluxoAquisicaoGalleriaConnected = () => {
  const [step, setStep] = useState(0);
  const [participante, setParticipante] = useState(null);
  const [events, setEvents] = useState(null);

  const {
    operacao: { participantes },
    validacao,
  } = useSelector(getPreenchimentoOperacao);

  const sortId = (id, parent) => (parent ?? id) * 10 + id;

  const compradores = useMemo(
    () =>
      participantes
        .filter((p) => p.tipoParticipante === "CO")
        .map((c) => ({
          ...c,
          fluxo: validacao.completa.participantes?.find((f) => f.id === c?.id)
            ?.etapas,
        }))
        .sort((c1, c2) =>
          c1.principal
            ? -1
            : c2.principal
            ? 1
            : (c1.nome || "").localeCompare(c2.nome || "")
        ),
    [participantes, validacao]
  );
  const outrosProponentes = useMemo(
    () =>
      participantes
        .filter(
          (p) => p.tipoParticipante !== "CO" && p.tipoParticipante !== "VD"
        )
        .map((c) => ({
          ...c,
          fluxo: validacao.completa.participantes?.find((f) => f.id === c?.id)
            ?.etapas,
        }))
        .sort(
          (c1, c2) =>
            sortId(c1.id, c1.participanteOrigem) -
            sortId(c2.id, c2.participanteOrigem)
        ),
    [participantes, validacao]
  );

  const makeGoTo = useCallback(
    (s) => {
      return () => {
        setStep(s);
      };
    },
    [setStep]
  );

  const eventsCompradorPf = [
    makeGoTo(1),
    makeGoTo(2),
    makeGoTo(3),
    makeGoTo(4),
  ];
  const eventsCompradorPj = [makeGoTo(1), makeGoTo(2), makeGoTo(5)];
  const eventsOutrosPf = [makeGoTo(50), makeGoTo(51), makeGoTo(52)];
  const eventsOutrosPj = [makeGoTo(50), makeGoTo(51), makeGoTo(53)];
  const eventsImovel = [makeGoTo(100), makeGoTo(101), makeGoTo(102)];
  const eventsSociosPf = [makeGoTo(60), makeGoTo(51), makeGoTo(61)];
  const eventsSociosPj = [makeGoTo(60), makeGoTo(51)];

  return (
    <FluxoAquisicaoGalleria
      step={step}
      setStep={setStep}
      makeGoTo={makeGoTo}
      participante={participante}
      setParticipante={setParticipante}
      compradores={compradores}
      outrosProponentes={outrosProponentes}
      events={events}
      setEvents={setEvents}
      eventsCompradorPf={eventsCompradorPf}
      eventsCompradorPj={eventsCompradorPj}
      eventsOutrosPf={eventsOutrosPf}
      eventsOutrosPj={eventsOutrosPj}
      eventsSociosPf={eventsSociosPf}
      eventsSociosPj={eventsSociosPj}
      eventsImovel={eventsImovel}
    />
  );
};

export default FluxoAquisicaoGalleriaConnected;
