import { FullHeightContainer } from "../components";
import { styled } from "@mui/material";
import HeaderConnected from "./addons/Header.connected";
import { transientOptions } from "../styles/theme";

const Content = styled("div", transientOptions)`
  margin-top: ${({ _showHeader_ }) =>
    _showHeader_ ? "calc(64px + 1.5rem)" : "0"};
  width: 100%;
  display: ${({ _display_ = "block" }) => _display_};
  margin-bottom: 1.5rem;
`;
const CommonPage = ({ children, selected, showHeader = true, display }) => {
  return (
    <>
      <FullHeightContainer maxWidth={false}>
        {showHeader && <HeaderConnected selected={selected} />}
        <Content _display_={display} _showHeader_={showHeader}>
          {children}
        </Content>
      </FullHeightContainer>
    </>
  );
};

export default CommonPage;
