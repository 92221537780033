import React from "react";
import {
  ControlledTextField,
  CpfCnpjTextField,
  PhoneTextField,
  RgTextField,
} from "../../components/inputs";
import { Grid } from "@mui/material";
import validators from "../../utils/validators";

export const formValues = {
  rules: (_, tipoEdicao) => ({
    nomeRepresentante: validators.string({ required: tipoEdicao === "O" }),
    cpfRepresentante: validators.cpf({ required: tipoEdicao === "O" }),
    rgRepresentante: validators.string({ required: tipoEdicao === "O" }),
    emailRepresentante: validators.email({ required: tipoEdicao === "O" }),
    telefoneRepresentante: validators.phone({ required: tipoEdicao === "O" }),
  }),
  defaultValues: (data = {}) => ({
    nomeRepresentante: "",
    cpfRepresentante: "",
    rgRepresentante: "",
    emailRepresentante: "",
    telefoneRepresentante: "",
    ...data,
  }),
};

const DadosRepresentanteForm = ({ formProps, forceDisabled, isSignUp }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ControlledTextField
          label="Nome do Representante"
          name="nomeRepresentante"
          disabled={forceDisabled}
          formProps={formProps}
          inputProps={{ maxLength: 100 }}
          margin={"none"}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="CPF do Representante"
          name="cpfRepresentante"
          disabled={forceDisabled}
          formProps={formProps}
          margin={"none"}
          type={"cpf"}
          InputProps={{
            inputComponent: CpfCnpjTextField,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="RG do Representante"
          name="rgRepresentante"
          disabled={forceDisabled}
          formProps={formProps}
          margin={"none"}
          inputProps={{ maxLength: 20 }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="WhatsApp para Contato"
          name="telefoneRepresentante"
          disabled={forceDisabled}
          formProps={formProps}
          margin={"none"}
          InputProps={{
            inputComponent: PhoneTextField,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="E-mail para Contato"
          name="emailRepresentante"
          disabled={!isSignUp || forceDisabled}
          formProps={formProps}
          inputProps={{ maxLength: 100 }}
          margin={"none"}
        />
      </Grid>
    </Grid>
  );
};

export default DadosRepresentanteForm;
