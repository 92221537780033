import React from "react";
import { ReactComponent as LoaderBrand } from "../../assets/loader-horizontal.svg";
import { ReactComponent as LoaderWhite } from "../../assets/loader-horizontal-white.svg";
import { ReactComponent as Loadergrey } from "../../assets/loader-horizontal-grey.svg";
const Ticks = ({
  color = "brand",
  size = "auto",
  style = {},
  ...otherProps
}) => {
  const Loader =
    color === "brand"
      ? LoaderBrand
      : color === "grey"
      ? Loadergrey
      : LoaderWhite;

  style.width = size;
  style.height = size;

  return <Loader fill={color} style={style} {...otherProps} />;
};

export default Ticks;
