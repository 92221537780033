import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import QualificacaoVendedores from "./QualificacaoVendedores";
import { actions } from "../../../../../actions/operacao.actions";

const QualificacaoVendedoresConnected = ({
  handleGoTo3,
  events,
  data,
  submitting,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { operacaoConfirmacaoValores } = data;

  const callback = useCallback(() => {
    handleGoTo3();
  }, [handleGoTo3]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      aceitaQualificacaoVendedores: validators.object({ required: true }),
      correcaoVendedores: validators.string({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        correcaoVendedores: "",
        ...operacaoConfirmacaoValores,
        aceitaQualificacaoVendedores:
          operacaoConfirmacaoValores.aceitaQualificacaoVendedores + "",
      }),
      [operacaoConfirmacaoValores]
    ),
    handleSubmit: useCallback(
      (values) => {
        return dispatch(
          actions.enviarConfirmacaoValores.request({
            callback,
            passo: "vendedores",
            instituicao: "itau",
            operacaoConfirmacaoValores: {
              idOperacao: values.idOperacao,
              aceitaQualificacaoVendedores: values.aceitaQualificacaoVendedores,
              correcaoVendedores:
                values.aceitaQualificacaoVendedores === "false"
                  ? values.correcaoVendedores
                  : null,
            },
          })
        );
      },
      [dispatch, callback]
    ),
  });

  const { reset } = formProps;

  useEffect(() => {
    reset({
      correcaoVendedores: "",
      ...operacaoConfirmacaoValores,
      aceitaQualificacaoVendedores:
        operacaoConfirmacaoValores.aceitaQualificacaoVendedores + "",
    });
  }, [operacaoConfirmacaoValores, reset]);

  return (
    <QualificacaoVendedores
      {...otherProps}
      formProps={formProps}
      handleSubmit={handleSubmit}
      events={events}
      submitting={submitting}
    />
  );
};

export default QualificacaoVendedoresConnected;
