import React from "react";
import { ControlledTextField, ZipCodeTextField } from "../../components/inputs";
import { Grid, MenuItem as MMenuItem, styled } from "@mui/material";
import { estados } from "../../constants/generic.constants";
import validators from "../../utils/validators";

const MenuItem = styled(MMenuItem)`
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-family: ${({ theme }) => theme.fonts.family.default};
`;

export const formValues = {
  rules: (_, tipoEdicao) => ({
    cep: validators.string({ required: tipoEdicao === "O" }),
    logradouro: validators.string({ required: tipoEdicao === "O" }),
    numeroLogradouro: validators.string({ required: tipoEdicao === "O" }),
    complementoLogradouro: validators.string({ required: false }),
    bairro: validators.string({ required: tipoEdicao === "O" }),
    municipio: validators.string({ required: tipoEdicao === "O" }),
    uf: validators.string({ required: tipoEdicao === "O" }),
  }),
  defaultValues: (data = {}) => ({
    cep: "",
    logradouro: "",
    numeroLogradouro: "",
    complementoLogradouro: "",
    bairro: "",
    municipio: "",
    uf: "",
    ...data,
  }),
};

const DadosEnderecoForm = ({
  formProps,
  handleZipCodeBlurred,
  forceDisabled,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ControlledTextField
          label="CEP"
          name={"cep"}
          disabled={forceDisabled}
          formProps={formProps}
          onBlur={handleZipCodeBlurred}
          InputProps={{
            inputComponent: ZipCodeTextField,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="Endereço"
          name={"logradouro"}
          disabled={forceDisabled}
          inputProps={{ maxLength: 40 }}
          formProps={formProps}
        />
      </Grid>
      <Grid item xs={5}>
        <ControlledTextField
          label="Número"
          name={"numeroLogradouro"}
          disabled={forceDisabled}
          inputProps={{ maxLength: 7 }}
          formProps={formProps}
        />
      </Grid>
      <Grid item xs={7}>
        <ControlledTextField
          label="Complemento"
          name={"complementoLogradouro"}
          disabled={forceDisabled}
          inputProps={{ maxLength: 40 }}
          formProps={formProps}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          label="Bairro"
          name={"bairro"}
          disabled={forceDisabled}
          inputProps={{ maxLength: 40 }}
          formProps={formProps}
        />
      </Grid>
      <Grid item xs={9}>
        <ControlledTextField
          label="Município"
          name={"municipio"}
          disabled={forceDisabled}
          inputProps={{ maxLength: 40 }}
          formProps={formProps}
        />
      </Grid>
      <Grid item xs={3}>
        <ControlledTextField
          label="UF"
          disabled={forceDisabled}
          name={"uf"}
          select
          formProps={formProps}
        >
          {estados.map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
    </Grid>
  );
};

export default DadosEnderecoForm;
