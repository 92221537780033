import React, { useCallback, useMemo } from "react";
import DadosSociosPjVendedorBradescoForm from "./DadosSociosPjVendedorBradescoForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isAdicionandoParticipanteComplemento,
  isAtualizandoComplemento,
  isGravandoComplementoOperacao,
  isRemovendoParticipanteComplemento,
} from "../../../../../selectors/operacao.selectors";
import { actions } from "../../../../../actions/operacao.actions";

const DadosSociosPjVendedorBradescoFormConnected = ({
  setStep,
  setEvents,
  eventsVendedorPj,
  eventsSocios,
  setParticipante,
  participante,
  compradores,
  socios,
  makeGoTo,
  handleGoTo60,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { operacao } = useSelector(getComplementoOperacao);
  const refreshing = useSelector(isAtualizandoComplemento);
  const adding = useSelector(isAdicionandoParticipanteComplemento);
  const deleting = useSelector(isRemovendoParticipanteComplemento);
  const submitting = useSelector(isGravandoComplementoOperacao);

  const sociosParticipante = useMemo(
    () => socios.filter((p) => p.participanteOrigem === participante.id).sort((a, b) => a.id - b.id),
    [dispatch, operacao, participante, socios]
  );

  const handleAdicionarSocio = useCallback(
    (tipoPessoa) => {
      dispatch(
        actions.adicionarParticipanteComplemento.request({
          id: operacao.id,
          idParticipanteOrigem: participante.id,
          tipoParticipante: "RL",
          tipoPessoa,
        })
      );
    },
    [dispatch, operacao, participante]
  );
  const handleRemoverSocio = useCallback(
    (e, participante, tipoParticipante) => {
      e.stopPropagation();
      dispatch(
        actions.removerParticipanteComplemento.request({
          id: operacao.id,
          tipoParticipante,
          participante,
        })
      );
    },
    [dispatch, operacao]
  );

  const acessarParticipante = useCallback(
    (p) => {
      setParticipante(p);
      setEvents(eventsSocios);
      handleGoTo60();
    },
    [setParticipante, setStep, setEvents, eventsSocios]
  );

  const handleAcessarSocio = useCallback(
    (e, c) => {
      e.stopPropagation();
      acessarParticipante(c);
    },
    [acessarParticipante]
  );

  return (
    <DadosSociosPjVendedorBradescoForm
      {...otherProps}
      submitting={submitting}
      fluxo={participante.fluxo}
      handleAdicionarSocio={handleAdicionarSocio}
      handleRemoverSocio={handleRemoverSocio}
      handleAcessarSocio={handleAcessarSocio}
      events={eventsVendedorPj}
      refreshing={refreshing}
      deleting={deleting}
      adding={adding}
      socios={sociosParticipante}
      makeGoTo={makeGoTo}
    />
  );
};

export default DadosSociosPjVendedorBradescoFormConnected;
