import { useCallback } from "react";
import TrocarSenhaForm from "./TrocarSenhaForm";
import validators from "../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../actions/autenticacao.actions";
import { isTrocarSenhaSubmitting } from "../../selectors/autenticacao.selectors";
import PasswordValidator from "password-validator";
import useCompleteForm from "../../hooks/useCompleteForm";

const TrocarSenhaFormConnected = () => {
  const dispatch = useDispatch();
  const submitting = useSelector(isTrocarSenhaSubmitting);

  // prettier-ignore
  const schema = new PasswordValidator()
		.is().min(8);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: (form) => ({
      senha: validators.string({
        required: true,
        custom: {
          rules: (value) => {
            return (
              schema.validate(value) ||
              "A senha informada não obedece a todas as regras descritas abaixo"
            );
          },
        },
      }),
      senha2: validators.string({
        required: true,
        custom: {
          same: (value) => {
            const { senha2 } = form.getValues();
            return value === senha2 || "As senhas devem ser iguais";
          },
        },
      }),
    }),
    initialValues: () => ({
      senha: "",
      senha2: "",
    }),
    handleSubmit: useCallback(
      (values) => {
        dispatch(actions.trocarSenha.request(values));
      },
      [dispatch]
    ),
  });

  const handlePasswordChange = useCallback(
    (evt, setList) => {
      setList({
        dirty: true,
        list: schema.validate(evt.target?.value, { list: true }),
      });
    },
    [schema]
  );

  return (
    <TrocarSenhaForm
      formProps={formProps}
      submitting={submitting}
      handleSubmit={handleSubmit}
      handlePasswordChange={handlePasswordChange}
    />
  );
};

export default TrocarSenhaFormConnected;
