import DadosUsuarioTab from "./DadosUsuarioTab";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetalhesParceiro,
  getDetalhesUsuario,
  getDominios,
  isAtualizandoUsuario,
} from "../../selectors/usuario.selectors";
import useCompleteForm from "../../hooks/useCompleteForm";
import { useCallback, useEffect, useMemo } from "react";
import { replaceNullishsWithEmpties, urlSafeString } from "../../utils/basic";
import { actions } from "../../actions/usuarios.actions";
import { formValues as formValuesDadosUsuario } from "./DadosUsuarioForm";
import { formValues as formValuesDadosParceiro } from "./DadosParceiroForm";
import { getMenuDisposition } from "../../selectors/tema.selectors";

const DadosUsuarioTabConnected = () => {
  const dispatch = useDispatch();
  const usuario = useSelector(getDetalhesUsuario);
  const parceiro = useSelector(getDetalhesParceiro);
  const dominios = useSelector(getDominios);
  const isMenuOpen = useSelector(getMenuDisposition);
  const data = useMemo(() => {
    return {
      id: usuario.id,
      nome: usuario?.nome,
      email: usuario?.email,
      situacao: usuario?.situacao,
      tipo: usuario?.tipo,
      perfil: usuario?.perfil,
      cpfCnpj: usuario?.cpfCnpj,
      telefone: usuario?.telefone,
      cnpj: parceiro?.cnpj,
      razaoSocial: parceiro?.razaoSocial,
      nomeFantasia: parceiro?.nomeFantasia,
      site: parceiro?.site,
      telefoneComercial: parceiro?.telefoneComercial,
      timeParceiro: usuario?.idTimeParceiro ?? "",
      parceirosSubstabelecidos: usuario?.parceirosSubstabelecidos ? usuario?.parceirosSubstabelecidos?.map(p => p.id) : []
    };
  }, [usuario, parceiro]);

  const submitting = useSelector(isAtualizandoUsuario);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: (form) => ({
      ...formValuesDadosUsuario.rules(form, "O"),
    }),
    initialValues: () => ({
      ...formValuesDadosUsuario.defaultValues(),
      ...formValuesDadosParceiro.defaultValues(),
      ...data,
    }),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.atualizarUsuario.request(
            {
              ...values,
              idTimeParceiro: values.timeParceiro,
              linkCalculadora: urlSafeString().generate(values.nome),
            },
            { id: usuario.id }
          )
        );
      },
      [dispatch, usuario]
    ),
  });
  const { reset, defaultValues, watch } = formProps;

  useEffect(() => {
    if (!!data) {
      reset({
        ...defaultValues,
        ...replaceNullishsWithEmpties({
          value: data,
          blackList: ["banco"],
        }),
      });
    }
  }, [reset, defaultValues, data]);

  const perfil = watch("perfil");

  return (
    <DadosUsuarioTab
      isOperacao={!usuario.parceiro}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      editable={true}
      isMenuOpen={isMenuOpen}
      dialogosDisponiveis={{
        DADOS_USUARIO: "O",
        DADOS_PARCEIRO: parceiro?.id ? "C" : null,
      }}
      times={parceiro?.times ?? []}
      dominios={dominios}
      perfil={perfil}
    />
  );
};

export default DadosUsuarioTabConnected;
