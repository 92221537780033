import CommonPage from "../CommonPage";
import { Card, Link } from "../../components";
import { Badge, Grid, Skeleton, styled, Tab, Tabs } from "@mui/material";
import { SITUACAO } from "../../constants/parceiro.constants";
import React from "react";
import formatters from "../../utils/formatters";
import DadosCadastraisParceiroTabConnected from "./DadosCadastraisParceiroTab.connected";
import { DETAIL_TABS } from "../../constants/generic.constants";
import ComentariosParceiroTab from "./ComentariosParceiroTab";
import EtapasAndamentoConnected from "../../components/workflow/EtapasAndamento.connected";
import CondicoesComerciaisTabConnected from "./CondicoesComerciaisTab.connected";
import DocumentosTabConnected from "./DocumentosTab.connected";
import ConfiguracoesTabConnected from "./ConfiguracoesTab.connected";
import ParceirosSubstabelecidosTabConnected from "./ParceirosSubstabelecidosTab.connected";
import { WhatsApp } from "@mui/icons-material";
import FiliaisTabConnected from "./FiliaisTab.connected";

const SituacaoParceiroContainer = styled("span")`
  margin-left: 1rem;
  padding: 0.3rem 0.5rem;
  display: inline-block;
  font-size: ${({ theme }) => theme.fonts.size.parceiro.situacao};
  font-weight: 700;
  color: ${({ theme, _situacao_ }) =>
    _situacao_ === "A"
      ? theme.colors.success._600
      : _situacao_ === "C"
      ? theme.colors.error._600
      : _situacao_ === "S"
      ? theme.colors.grey._600
      : theme.colors.secondary._600};
  background-color: ${({ theme, _situacao_ }) =>
    _situacao_ === "A"
      ? theme.colors.success._100
      : _situacao_ === "C"
      ? theme.colors.error._100
      : _situacao_ === "S"
      ? theme.colors.grey._100
      : theme.colors.secondary._100};
  border-radius: 6px;
`;
const SkeletonCard = styled(Skeleton)`
  width: 100%;
  height: 239px;
  border-radius: 1rem;
`;
const ImageContainer = styled("div")`
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadiuses.imageHolders};
  background-color: ${({ theme }) => theme.colors.primary._100};
  font-size: 68px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary._700};
`;
const NomeFantasia = styled("span")`
  font-size: ${({ theme }) => theme.fonts.size.parceiro.title};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.grey._700};
`;
const RazaoSocial = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.parceiro.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey._200};
`;
const Cnpj = styled("div")`
  font-size: ${({ theme }) => theme.fonts.size.parceiro.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grey._200};
`;

const TabContainer = styled(Tabs)`
  display: flex;
  margin: 1.5rem 0 -1.5rem;
  border-top: solid 2px ${({ theme }) => theme.colors.grey._50};
  font-size: ${({ theme }) => theme.fonts.size.parceiro.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};

  & .MuiTab-root {
    letter-spacing: 0;
  }

  & .MuiTabs-indicator {
    height: 3px;
    background-color: ${({ theme }) => theme.colors.primary._400};
  }

  & .MuiTabs-scrollButtons.Mui-disabled {
    width: 0;
  }
`;
const StyledTab = styled(Tab)`
  font-size: ${({ theme }) => theme.fonts.size.parceiro.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 500;
  text-transform: none;
  padding: 0.75rem 0;
  margin-right: 1.5rem;
  overflow: visible;
  min-width: unset;
  color: ${({ theme }) => theme.colors.grey._300};

  &.Mui-selected {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary._400};
  }
`;
const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    margin-right: -4px;
  }
`;
const StyledIcon = styled(WhatsApp)`
  margin-right: 0.25rem;
  height: 24px;
  width: 24px;
`;
const LinkTelefone = styled(Link)`
  font-size: ${({ theme }) => theme.fonts.size.operacao.subtitle};
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-weight: 400;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey._200};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary._300};
    font-weight: 600;
    text-decoration: underline;
  }
`;

const DetalhesParceiroPage = ({
  tab,
  handleTabChange,
  loading,
  data,
  handleAcaoCallback,
  contexto,
  dialogosDisponiveis,
  documentosPendentes,
  usuario,
  handlePhoneClick,
}) => {
  const isLoading = !!loading || !data;

  return (
    <CommonPage selected={"parceiro"}>
      {isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <Grid display={"flex"} gap={"1.5rem"}>
                <ImageContainer>
                  {data.nomeFantasia?.slice(0, 2).toUpperCase()}
                </ImageContainer>
                <Grid display={"flex"} flexDirection={"column"}>
                  <Grid display={"flex"} alignItems={"center"}>
                    <NomeFantasia>{data.nomeFantasia}</NomeFantasia>
                    <SituacaoParceiroContainer _situacao_={data.situacao}>
                      {SITUACAO[data.situacao]}
                    </SituacaoParceiroContainer>
                  </Grid>
                  <RazaoSocial>{data.razaoSocial}</RazaoSocial>
                  <Cnpj>{formatters.strings.cnpj(data.cnpj)}</Cnpj>
                  <Grid
                    item
                    display={"flex"}
                    alignItems={"center"}
                    justifySelf={"end"}
                  >
                    <LinkTelefone
                      onClick={() =>
                        handlePhoneClick(data.telefoneRepresentante)
                      }
                    >
                      <StyledIcon as={WhatsApp} /> {data.telefoneRepresentante}{" "}
                      - {data.nomeRepresentante}
                    </LinkTelefone>
                  </Grid>
                </Grid>
              </Grid>
              <EtapasAndamentoConnected
                contexto={contexto}
                parceiro={data}
                callback={handleAcaoCallback}
              />
              <TabContainer
                value={tab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <StyledTab value={"0"} label={"Dados Cadastrais"} />
                <StyledTab
                  value={DETAIL_TABS.DOCUMENTOS}
                  label={
                    <StyledBadge
                      variant="dot"
                      badgeContent={documentosPendentes}
                      color="error"
                    >
                      Documentos
                    </StyledBadge>
                  }
                />
                {!!dialogosDisponiveis["DADOS_COMERCIAIS"] && (
                  <StyledTab value={"2"} label={"Condições Comerciais"} />
                )}
                {data.modeloComercial === "ES" &&
                  data?.parceirosSubstabelecidos?.length > 0 && (
                    <StyledTab
                      value={"3"}
                      label={"Parceiros Substabelecidos"}
                    />
                  )}
                <StyledTab value={"4"} label={"Filiais"} />
                {usuario.perfil === "OPERACAO" && (
                  <StyledTab
                    value={DETAIL_TABS.CONFIGURACOES}
                    label={"Configurações"}
                  />
                )}
                <StyledTab
                  value={DETAIL_TABS.COMENTARIOS}
                  label={"Comentários"}
                />
              </TabContainer>
            </Card>
          </Grid>
          {tab === "0" && <DadosCadastraisParceiroTabConnected />}
          {tab === DETAIL_TABS.DOCUMENTOS && <DocumentosTabConnected />}
          {tab === "2" && <CondicoesComerciaisTabConnected />}
          {tab === DETAIL_TABS.COMENTARIOS && <ComentariosParceiroTab />}
          {tab === "3" && <ParceirosSubstabelecidosTabConnected />}
          {tab === DETAIL_TABS.CONFIGURACOES && <ConfiguracoesTabConnected />}
          {tab === "4" && <FiliaisTabConnected />}
        </Grid>
      )}
    </CommonPage>
  );
};

export default DetalhesParceiroPage;
