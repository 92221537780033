import CommonPage from "../../CommonPage";
import {Grid, Skeleton, styled} from "@mui/material";
import OperationHeaderConnected from "../../../components/presentation/OperationHeader.connected";
import React from "react";
import ValidacaoDocumentoFormConnected from "./ValidacaoDocumentoForm.connected";

const SkeletonCard = styled(Skeleton)`
  width: 100%;
  height: 239px;
  border-radius: 1rem;
`;

const ValidacaoDocumentoPage = ({loading, documento, operacao}) => {
  const isLoading =  !!loading || !documento || !operacao;

  return (
    <CommonPage selected={"clientes"}>
      {isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SkeletonCard variant={"rectangular"} />
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OperationHeaderConnected
              data={operacao}
              timeline={false}
              detalhes={false}
            />
          </Grid>
          <ValidacaoDocumentoFormConnected />
        </Grid>
      )}
    </CommonPage>
  );
}

export default ValidacaoDocumentoPage